export const UPDATE_PROMOTION_SCHEME = {
  $cardVersionId: 'ID!',
  $linkText: 'String',
  $linkUrl: 'String',
  $linkUrlShow: 'Boolean',
  $title: 'String',
  $showSectionHeader: 'Boolean',
  $promotionImageBase64: 'String',
  $promotionImageName: 'String',
};
