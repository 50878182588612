import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Select, FormControl, TextField, Button, Dialog, DialogActions, DialogContent, InputLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useStyles } from './styles';
import { DatePicker } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import { CustomDate } from 'modules/dashboard/components/Dashboard/consts';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

export interface IDateRangeSelectProps {
  value?: any;
  label: string;
  options: any;
  exClass?: any;
  defaultValue?: any;
  disabled?: boolean;
  onChange?: (value: any, name: any, event?: any) => void;
  name?: string;
  InputProps?: any;
  lastElement?: JSX.Element;
}

export const CssDateSelectField = withStyles({
  root: {
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiFilledInput-underline:before': {
      borderBottomColor: 'red',
    },
  },
})(Select);

// set minDate to September 2019.
const minDate = moment([2019, 8]);
// set maxDate to current day.
const maxDate = moment();

const CUSTOM_RANGE_LABEL = "Custom range";

export const DateRangeSelect: React.FC<IDateRangeSelectProps> = ({
  value,
  label,
  options,
  onChange,
  exClass,
  name,
  disabled,
  defaultValue,
  InputProps,
  lastElement,
}) => {
  const classes = useStyles();
  const buttonValue = value === 'custom' ? CUSTOM_RANGE_LABEL : "Last " + value;
  const [open, setOpen] = useState<boolean>(false);
  const [customFromDate, fromDateSetter] = useState<CustomDate>(moment().subtract(6, 'month'));
  const [customToDate, toDateSetter] = useState<CustomDate>(moment());
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [customRangeSelected, setCustomRangeSelected] = useState<boolean>(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const setFromDate = (date: Moment) => {
    setErrorMessage("");

    if (customToDate) {
      const validRange = date.diff(customToDate, 'days') < 0;
      validRange ? setCustomRangeSelected(true) : setErrorMessage("Invalid date range");
    }

    fromDateSetter(date);
  };

  const setToDate = (date: Moment) => {
    setErrorMessage("");

    if (customFromDate) {
      const validRange = date.diff(customFromDate, 'days') > 0;
      validRange ? setCustomRangeSelected(true) : setErrorMessage("Invalid date range");
    }

    toDateSetter(date);
  };

  const selectTimePeriod = (name: string, value: any) => {
    if (value !== 'custom') {
      const action = { type: value };

      setCustomRangeSelected(false);
      setOpen(false);
      if (onChange) {
        onChange(action, name);
      }
    }
  };

  const canSelectCustomPeriod = useMemo(() => {
    return onChange && customToDate && customFromDate && !errorMessage;
  }, [onChange, customToDate, customFromDate, errorMessage]);

  const selectCustomTimePeriod = useCallback(() => {
    if (onChange && customToDate && customFromDate && canSelectCustomPeriod) {
      const type = 'custom';
      const action = {
        type,
        customFromDate,
        customToDate,
      };

      setOpen(false);
      onChange(action, type);
    };
  }, [canSelectCustomPeriod, onChange, customToDate, customFromDate]);

  useEffect(() => {
    if (customRangeSelected && canSelectCustomPeriod) {
      selectCustomTimePeriod();
    };
  }, [customRangeSelected, canSelectCustomPeriod, selectCustomTimePeriod]);

  const onCustomRangeClick = () => {
    setCustomRangeSelected(true);
    canSelectCustomPeriod ? selectCustomTimePeriod() : setErrorMessage("Invalid date range");
  };

  const dateInputLabel = (date: CustomDate, invalidLabel: string): string => {
    return date ? date.format("Do MMMM YYYY") : invalidLabel;
  };

  return (
    <div className={clsx(classes.container, classes.formControl, exClass)}>
      <InputLabel className={clsx(classes.label, exClass)} classes={{ shrink: classes.labelShrink }} htmlFor={`${name}`}>
        Time period
      </InputLabel>
      <TextField
        value={buttonValue}
        onClick={handleOpen}
        InputProps={{
          readOnly: true,
          classes: { root: classes.input },
        }}
        classes={{ root: classes.textField}}
      >
        Time period
      </TextField>
      <ArrowDropDownIcon className={classes.inputIcon}/>
      <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose} classes={{ paper: classes.dialogPaper }}>
        <DialogContent className={classes.dialogContent}>
          <form className={classes.dialogContainer}>
            {options.map((el: any) => {
              return (
                <FormControl key={el.label} className={classes.formControl}>
                  <Button
                    variant="contained"
                    value={el.value}
                    key={el.label}
                    id={el.value}
                    className={clsx(classes.li, exClass, buttonValue === el.label && '_selected')}
                    onClick={() => selectTimePeriod(el.label, el.value)}
                  >
                    {el.label}
                  </Button>
                </FormControl>
              );
            })}
            <hr className={classes.hr}/>
            <FormControl className={classes.formControl}>
              <div
                className={clsx(classes.li, exClass, buttonValue === CUSTOM_RANGE_LABEL && '_selected')}
                onClick={onCustomRangeClick}
              >
                { CUSTOM_RANGE_LABEL }
              </div>
              <div className={classes.datePickerContainer}>
                <DatePicker
                  value={customFromDate}
                  label={"From date"}
                  variant="inline"
                  onChange={(date) => date && setFromDate(date)}
                  minDate={minDate}
                  maxDate={maxDate}
                  autoOk
                  labelFunc={dateInputLabel}
                  error={!!errorMessage}
                  className={classes.datePicker}
                />
                <span className={classes.datePickerSeparator}>&nbsp;-&nbsp;</span>
                <DatePicker
                  value={customToDate}
                  label={"To date"}
                  variant="inline"
                  onChange={(date) => date && setToDate(date)}
                  minDate={minDate}
                  maxDate={maxDate}
                  autoOk
                  labelFunc={dateInputLabel}
                  error={!!errorMessage}
                  className={classes.datePicker}
                />
              </div>
              <div className={classes.errorMessage}>{errorMessage}</div>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
