import { makeStyles } from '@material-ui/core';
import { E_PREVIEW_TYPE } from 'modules/editor/constants/enums';

const previewStyles = () => ({
  [`&.${E_PREVIEW_TYPE.AS_MOBILE}`]: {
    margin: '0 0',
  },
  [`&.${E_PREVIEW_TYPE.AS_DESKTOP}`]: {
    margin: '0 23px',
  },
  [`&.${E_PREVIEW_TYPE.AS_TABLET}`]: {
    margin: '0 13px',
  },
  [`&.${E_PREVIEW_TYPE.AS_MOBILE_EMULATOR}`]: {
    margin: '0 0px',
  },
  [`&.${E_PREVIEW_TYPE.MOBILE_DEVICE}`]: {
    margin: '0 0px',
  },
});

export const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '105px',
    height: '105px',
    position: 'absolute',
    top: '-30px',
    left: 'calc(50% - 52.5px)',
    backgroundColor: 'gray',
    border: '2px solid white',
    borderRadius: '13px',
    overflow: 'hidden',
    boxShadow: '0 4px 17px 0 #BBBBE2',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    minHeight: '100%',
    minWidth: '100%',
    objectFit: 'cover',
  },
  name: {
    paddingBottom: 8,
    paddingTop: 15,
    lineHeight: 1,
    wordBreak: 'break-word',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '30px',
    color: '#000000',
  },
  qualifications: {
    color: 'rgba(1,61,31,0.5)',
    wordBreak: 'break-word',
    fontSize: '20px',
    textAlign: 'center',
    marginTop: theme.spacing(-1),
    padding: theme.spacing(1, 0),
  },
  job: {
    color: 'rgba(1,61,31,0.5)',
    wordBreak: 'break-word',
    fontSize: '16px',
    textAlign: 'center',
    marginTop: theme.spacing(-1),
    paddingTop: theme.spacing(1),
  },
  actionsContainer: {
    [theme.breakpoints.down(360)]: {
      flexWrap: 'wrap',
    },
  },
  buttonWrapper: {
    [theme.breakpoints.down(360)]: {
      "&:nth-child(n+5)": {
        marginTop: theme.spacing(2),
      },
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      margin: '0 0px',
      ...previewStyles(),
    },
    [theme.breakpoints.between('sm', 'md')]: {
      margin: '0 13px',
      ...previewStyles(),
    },
    [theme.breakpoints.between('md', 'lg')]: {
      margin: '0 23px',
      ...previewStyles(),
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      margin: '0 33px',
      ...previewStyles(),
    },
  },
  second: {
    backgroundColor: '#FCFCFC',
    padding: '0',
    paddingBottom: '15px',
    marginLeft: '-10px',
    marginRight: '-10px',
    scrollMarginBottom: '-10px',
    borderTop: '1px solid #E3E9EC',
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
  },
  social: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2, 4),
    paddingBottom: 24,
    display: 'flex',
    justifyContent: 'center',
  },
  text: {
    left: 'calc(50% - 50px)',
    top: '-20px',
    color: '#000000',
    fontSize: '12px',
    width: '100px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  share: {
    left: 'calc(50%)',
    transform: 'translateX(-50%)',
  },
}));
