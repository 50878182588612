import React, { CSSProperties } from 'react';

interface IPreviewLinkProps {
  display?: boolean;
  disable?: boolean;
  url?: string;
  children?: string;
  style: CSSProperties;
  className: string;
}

export const PreviewLink: React.FC<IPreviewLinkProps> = ({ display, disable, url, children, style, className }) => {
  return (
    <>
      {display && children ? (
        <div className={className}>
          <a
            style={style}
            href={url}
            target="_blank"
            rel="noopener noreferrer nofollow"
            onClick={(e) => disable && e.preventDefault()}
          >
            {children}
          </a>
        </div>
      ) : null}
    </>
  );
};
