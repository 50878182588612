import './style.css';
import 'core-js';
import 'intl';
import 'raf';
import 'typeface-roboto';
import 'unfetch/polyfill';

import ProfileLoader from 'modules/auth/components/ProfileLoader';
import GeneralLoader from 'modules/core/components/GeneralLoader';
import { GA_TRACKING_ID } from 'modules/core/constants/config';
import React from 'react';
import ReactDOM from 'react-dom';
import HttpsRedirect from 'react-https-redirect';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from 'store/store';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { ApolloProvider } from '@apollo/client';
import { apoloClient } from 'modules/core/constants/client';

import GAListener from './GAListener';
import AppRoutes from './routes/';
import * as serviceWorker from './serviceWorker';

const app = (
  <HttpsRedirect>
    <Provider store={store}>
      <ApolloProvider client={apoloClient}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <BrowserRouter>
            <GAListener trackingId={GA_TRACKING_ID}>
              <>
                <GeneralLoader />
                <ProfileLoader />
                <AppRoutes />
              </>
            </GAListener>
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </ApolloProvider>
    </Provider>
  </HttpsRedirect>
);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();
