import gql from 'graphql-tag';

interface ICardVersionFieldOpts {
  vCard?: boolean;
  icon512?: boolean;
}

const VCARD_FIELDS = `
  vCardImageBase64
  vCardImageContentType
`;

const defaultCardVersionFieldOpts = {
  icon512: true,
  vCard: false,
};

export const cardVersionFields = (opts: ICardVersionFieldOpts = defaultCardVersionFieldOpts) => {
  const vCardFields = opts.vCard ? VCARD_FIELDS : "";
  // const icon512 = opts.icon512 ? "iconUrl512" : "";

  return gql`
    fragment CardVersionFields on CardVersion {
      aboutSection {
        id
        title
        aboutSectionLinks {
          id
          fileUrl
          linkText
          linkUrl
        }
        description
        websiteUrl
        websiteUrlShow
      }
      avatarUrl
      blogUrl
      blogUrlShow
      businessName
      card {
        id
        urlSlug
        gdprPopupEnabled
        currentProcessingAction
        account {
          id
          canChangeUrl
          canPublishCard
          subscribed
          type
          user {
            id
            email
            firstName
          }
        }
      }
      cardStyle {
        id
        backgroundHexColour
        backgroundImageShow
        backgroundImageUrl
        bodyFont
        bodyFontHexColour
        bodyFontStyle
        businessNameHexColour
        overlayHexColour
        overlayShow
        primaryLinksHexColour
        secondaryIconsHexColour
        titleFont
        titleFontHexColour
        taglineHexColour
        titleFontStyle
      }
      contactDetailsHeaderShow
      currentForCardId
      draft
      email
      facebookUrl
      facebookUrlShow
      fullName
      gallerySection {
        gallerySectionImages {
          description
          galleryImageUrl
          galleryImageUrlOriginal
          galleryImageUrlThumbnail
          linkUrl
          id
        }
        id
        showSectionHeader
        title
      }
      iconUrl16
      iconUrl32
      iconUrl512
      id
      instagramUrl
      instagramUrlShow
      isProcessing
      jobTitle
      linkedInUrl
      linkedInUrlShow
      locationSection {
        city
        country
        display12h
        id
        openingTimes {
          fromDay
          id
          toDay
          fromHour
          toHour
        }
        lat
        lng
        postcode
        state
        streetAddress
        streetAddress2
      }
      logoUrl
      mobilePhone
      newsSection {
        linkText
        linkUrl
        linkUrlShow
        newsImageUrl
        showSectionHeader
        title
      }
      pinterestUrl
      pinterestUrlShow
      podcastUrl
      podcastUrlShow
      promotionSection {
        linkText
        linkUrl
        linkUrlShow
        promotionImageUrl
        showSectionHeader
        title
      }
      qualifications
      secondPhoneNumber
      snapchatUrl
      snapchatUrlShow
      tagLine
      throughReseller
      twitterUrl
      twitterUrlShow

      ${vCardFields}

      videoSection {
        linkUrl
        showSectionHeader
        title
      }
      whatsappUrl
      whatsappUrlShow
      youtubeUrl
      youtubeUrlShow
    }
  `;
};

export const SUBSCRIPTION_FRAGMENT = gql`
  fragment SubscriptionFields on Subscription {
    account {
      id
      type
      subscribed
      accountName
      accountDesc
    }
    amount
    autoRenew
    currency
    currentPeriodEnd
    currentPeriodStart
    description
    id
    invoiceId
    slotNumber
    status
    subscriptionId
    planId
  }
`;

export const COUPON_FRAGMENT = gql`
  fragment CouponFields on Coupon {
    amountOff
    created
    currency
    duration
    durationInMonths
    id
    livemode
    maxRedemptions
    metadata {
      interval
      reseller
    }
    name
    object
    percentOff
    redeemBy
    timesRedeemed
    valid
  }
`;

// NOTE: this fragment represents the true stripe object without the coupon and vat attributes, unlike the listPlans query
export const PLAN_FRAGMENT = gql`
  fragment PlanFields on Plan {
    active
    aggregateUsage
    amount
    amountDecimal
    billingScheme
    created
    currency
    id
    interval
    intervalCount
    livemode
    metadata {
      poa
      upToMax
    }
    nickname
    object
    product
    tiers {
      flatAmount
      flatAmountDecimal
      unitAmount
      unitAmountDecimal
      upTo
    }
    tiersMode
    trialPeriodDays
    usageType
  }
`;

export const PRICE_FRAGMENT = gql`
  fragment PriceFields on Price {
    id
    object
    active
    billingScheme
    created
    currency
    livemode
    lookupKey
    metadata {
      poa
      upToMax
    }
    nickname
    product
    recurring
    tiersMode
    transformQuantity
    type
    unitAmount
    unitAmountDecimal
  }
`;
