import React from 'react';
import { useStyles } from './styles';
import { SocialIcon } from 'react-social-icons';

interface IIconSocial {
  network: string;
  color?: string;
  styles?: any;
}

const IconSocial: React.FC<IIconSocial> = ({ network, color = 'white', styles }) => {
  const classes = useStyles();
  const currentStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: 35,
    height: 35,
  };
  return (
    <div className={classes.wrap}>
      <SocialIcon network={network} bgColor="transparent" fgColor={color} style={styles ? styles : currentStyles} />
    </div>
  );
};

export default IconSocial;
