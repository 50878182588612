import { findCurrency } from 'currency-formatter';
import React, { useMemo } from 'react';
import { dollarString } from 'modules/core/constants/currency';

interface IAmount {
  id?: string;
  amount: number;
  ccy: string;
  hideDollarPrefix?: boolean;
}

const Amount: React.FC<IAmount> = ({ amount, ccy, hideDollarPrefix, id = Date.now().toString(16) }) => {
  const symbol = useMemo(() => {
    return (ccy.toUpperCase() === 'USD' ? dollarString(!hideDollarPrefix) : findCurrency(ccy.toUpperCase()).symbol)
  }, [ccy, hideDollarPrefix]);

  const negative = amount < 0 ? "-" : "";
  const numberAm = amount / 100; // Amount returned as cent count!
  const [units, cents] = numberAm.toFixed(2).replace(/-/, "").split('.');
  const formattedAmount = `${negative}${symbol}${units}.${cents}`

  return (
    <span id={`amount_${id}`}>
      {formattedAmount}
    </span>
  );
};

export default Amount;
