import { IShareForm } from 'modules/core/interfaces';
import { Reducer } from 'react';

export const initalContactDetailsState: IShareForm = {
  referrerName: '',
  name: '',
  email: '',
  phone: '',
  message: '',
};

export type ContactDetailsField = keyof IShareForm;

interface IActions {
  type: ContactDetailsField | 'reset';
  value?: string;
}

export const contactDetailsReducer: Reducer<IShareForm, IActions> = (state, action) => {
  const { type, value = '' } = action;
  switch (type) {
    case 'referrerName':
      return {
        ...state,
        referrerName: value,
      };
    case 'name':
      return {
        ...state,
        name: value,
      };
    case 'email':
      return {
        ...state,
        email: value,
      };
    case 'phone':
      return {
        ...state,
        phone: value,
      };
    case 'message':
      return {
        ...state,
        message: value,
      };
    case 'reset':
      return initalContactDetailsState;
    default:
      return state;
  }
};
