import React, { useState, useCallback } from 'react';
import { Box } from '@material-ui/core';
import { limeade } from 'themes/colors';
import { clearStyles } from './styles';
import clsx from 'clsx';

interface IClearProps {
  id?: string;
  ico?: JSX.Element;
  children: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const Clear: React.FC<IClearProps> = ({ children, onCancel, onConfirm, ico, id = Date.now().toString(16) }) => {
  const [isQuestion, set] = useState(true);
  const classes = clearStyles({});

  const confirm = useCallback(() => {
    set(true);
    if (onConfirm) {
      onConfirm();
    }
  }, [onConfirm, set]);

  const cancel = useCallback(() => {
    set(true);
    if (onCancel) {
      onCancel();
    }
  }, [onCancel, set]);

  return (
    <Box className={classes.root}>
      {isQuestion ? (
        <Box
          id={id}
          display="flex"
          alignItems="center"
          className={classes.pointer}
          onClick={() => set(false)}
          color={limeade}
        >
          <Box className={classes.uppercase}>{children}</Box>
          <Box className={classes.icon}>{ico}</Box>
        </Box>
      ) : (
        <Box display="flex">
          <Box className={classes.uppercase}>{`${children}?`}</Box>
          <Box display="flex" mx={3}>
            <Box id={`confirm_${id}`} className={clsx(classes.confirmBtn, classes.pointer)} onClick={() => confirm()}>
              Yes
            </Box>
            <Box id={`cancel_${id}`} className={clsx(classes.cancelBtn, classes.pointer)} onClick={() => cancel()}>
              No
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Clear;
