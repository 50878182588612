import React, { FC, useState } from 'react';
import { Box } from '@material-ui/core';
import { isDisabled } from 'modules/editor/components/CardSectionEditors/hooks';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { AddingBlock } from '../../../AddingBlock';

interface IProps {
  onAdd: () => void;
  isAdd: boolean;
}

export const AddItem: FC<IProps> = (props) => {
  const { onAdd, isAdd } = props;
  const fields = useTypedSelector((state) => state.editorSteps.aboutFields);
  const [isAddError, setIsAddError] = useState<boolean>(false);

  const addNewItem = () => {
    if (isAdd) {
      setIsAddError(true);
      setTimeout(() => setIsAddError(false), 2000);
    } else {
      onAdd();
    }
  };

  const info = isAddError ? <Box color="#f44336">Please fill in the previous slot</Box> : <Box height="20px" />;
  const isHidding = isDisabled(fields, 'linkText') && isDisabled(fields, 'linkUrl');

  return (
    <>
      {info}
      {isHidding ? null : <AddingBlock action={addNewItem} id="about-link" name="link" />}
    </>
  );
};
