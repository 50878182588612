import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const desktopPickerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    popover: {
      marginLeft: '10px',
      marginTop: '70px',
    },
    paper: {
      overflow: 'visible',
      borderRadius: '2px',
      '&:before': {
        content: `''`,
        display: 'block',
        width: '10px',
        height: '10px',
        position: 'absolute',
        background: '#3C4046',
        top: '69px',
        left: '-5px',
        transform: 'rotate(45deg)',
        borderLeft: '1px solid #51555A',
        borderBottom: '1px solid #51555A',
      },
    },
  })
);

export const pickerStyles = makeStyles((theme: Theme) =>
  createStyles({
    headTitle: {
      color: '#c6c8ca',
      fontSize: 12,
      textTransform: 'uppercase',
    },
    subtitle: {
      color: '#919598',
      fontSize: 12,
      letterSpacing: 0.1,
      lineHeight: theme.typography.pxToRem(17),
      fontWeight: 300,
      fontFamily: 'F37 Ginger Light',
    },

    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',

      '& .MuiInput-root': {
        height: '100%',
      },
    },
    test: {
      width: 70,
      height: 25,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 10,
      fontWeight: 300,
      fontFamily: 'F37 Ginger Light',
      '&:first-child': {
        borderRadius: '4px 4px 0 0',
      },
      '&:nth-child(10)': {
        borderRadius: '0 0 4px 4px ',
      },
    },
    colorConatainer: {
      display: 'flex',
      flexDirection: 'row',
      height: 258,
      overflowY: 'hidden',
      overflowX: 'scroll',
    },
    col: {
      margin: theme.spacing(1),
      borderRadius: 4,
    },
    inputWrapper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      margin: theme.spacing(1, 0),
      alignItems: 'flex-end',
      maxWidth: 200,
      width: '100%',
      '& .MuiFormControl-root': {
        width: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    input: {
      position: 'relative',
      width: 'calc(100% - 35px)',
      paddingRight: 0,
      [theme.breakpoints.down('sm')]: {
        paddingRight: 20,
      },
      '& .MuiInput-underline': {
        fontSize: 14,
        letterSpacing: 0.1,
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: 400,
      },
      '& .MuiInput-underline:before': {
        borderBottom: '1px solid rgba(255,255,255,0.2)',
      },
    },
    inputButton: {
      minWidth: 'unset',
      width: 35,
      height: 35,
      backgroundColor: '#eee',
      outline: 'none',
      boxShadow: '0px 1px 3px rgba(0,0,0,0.2)',
      border: '1px solid #cecece',
      padding: 0,
      '&:disabled': {
        border: '1px solid #51555a',
      },
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    pickButton: {
      position: 'absolute',
      top: -4,
      right: 0,
      minWidth: 'unset',
      width: 28,
      borderRadius: 2,
      height: 28,
      backgroundColor: '#eee',
      outline: 'none',
      boxShadow: '0px 1px 3px rgba(0,0,0,0.2)',
      border: '1px solid #cecece',
      padding: 0,
      '&:disabled': {
        border: '1px solid #51555a',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    mainColorWrapper: {
      display: 'flex',
      overflowX: 'scroll',
      width: '100%',
    },
    BigColors: {
      display: 'flex',
    },
    mainColors: {
      width: 80,
      height: 80,
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 4,
      border: '1px solid #9da1a3',
      margin: theme.spacing(1),
      justifyContent: 'space-between',
      padding: theme.spacing(1),
      boxSizing: 'border-box',
      fontSize: 12,
      boxShadow: '0px 0 13px rgba(0,0,0,.5)',
    },
    anim: {
      transition: '.4s',
      transform: 'rotate(180deg)',
      color: '#000',
    },
    chevron: {
      transition: '.4s',
      transform: 'rotate(0deg)',
      color: '#000',
    },
    resent: {
      display: 'flex',
      marginTop: 16,
    },
    color: {
      width: 16,
      height: 16,
      borderRadius: '50%',
      margin: '0 5px',
    },
  })
);
