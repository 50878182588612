import { E_ACCOUNT_TYPE } from 'modules/auth/constants/enums';
import { ID } from 'modules/core/types';
import { IAccount } from 'modules/auth/interfaces/auth';

export class Account implements IAccount {
  public id: ID = 0;
  public subscribed: boolean = false;
  public type: E_ACCOUNT_TYPE = E_ACCOUNT_TYPE.Unknown;
  public accountName: string = '';
  public accountDesc: string = '';

  constructor(data?: Partial<IAccount>) {
    if (data) {
      this.id = data.id || 0;
      this.subscribed = !!data.subscribed;
      this.type = data.type || E_ACCOUNT_TYPE.Unknown;
      this.accountName = data.accountName || '';
      this.accountDesc = data.accountDesc || '';
    }
  }
}
