import React, { FC } from 'react';
import { Box, Fab, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import {
  fabStyle,
  extendedIconStyle,
  addingBlock,
  textStyle,
} from 'modules/editor/components/CardSectionEditors/styles';

interface IProps {
  action: () => void;
  id: string;
  name: string;
}

export const AddingBlock: FC<IProps> = (props) => {
  const { action, id, name } = props;
  const { addingBlock: addingBlockStyle, fab, extendedIcon, heading } = useStyles();

  return (
    <Box className={addingBlockStyle} onClick={action} id={`${id}-add-item`}>
      <Fab aria-label="add" className={fab}>
        <Add className={extendedIcon} />
      </Fab>
      <Typography className={heading}>Add another {name}</Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  addingBlock,
  fab: fabStyle,
  extendedIcon: extendedIconStyle,
  heading: textStyle(theme),
}));
