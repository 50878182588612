import React, { useEffect, useState } from 'react';
import { reqZendeskAuth } from 'modules/auth/api/resellerAuth';
import { useLocation, useHistory } from 'react-router';
import { Container } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Wait as Spinner } from 'modules/billing/components/Billing/SpinnerScreen'
import useStylesPage from 'modules/public/pages/main/pageStyles';
import clsx from 'clsx';
import { SIGNIN_ROUTE } from 'routes/routes';

export const ResellerZendeskSso: React.FC = () => {
  const { search } = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const pageStyles = useStylesPage();

  const [loading, setLoading] = useState<boolean>(true);
  const [message, setMessage] = useState<string>("Test string to say in the box");

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const returnTo = searchParams.get('return_to');
    // const brandId = searchParams.get('brand_id');
    // const localeId = searchParams.get('locale_id');
    // const timestamp = searchParams.get('timestamp');

    reqZendeskAuth({ 'return_to': returnTo })
      .then((res) => {
        const redirectUrl = res.data.url;
        window.location.assign(redirectUrl);
      })
      .catch((e) => {
        setLoading(false)
        setMessage("Unauthorised. You need a valid reseller subscription to continue.")
        setTimeout(() => history.push(SIGNIN_ROUTE.path), 3000)
      })
  }, [search, history]);

  return (
    <div className={clsx(pageStyles.page, '_padding-sm-mobile')}>
      <Container className={classes.wrapper}>
        <div className={classes.element}>
          <Spinner wait={loading}>
            <div className={classes.elementContent}>{message}</div>
          </Spinner>
        </div>
      </Container>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    elementContent: {
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    element: {
      display: 'flex',
      maxWidth: 360,
      minWidth: 360,
      height: 450,
      background: 'white',
      padding: '23px 29px 30px 30px',
      boxShadow: '0 10px 40px 0 rgba(0,0,0,0.5)',
      borderRadius: 4,
      justifyContent: 'center',
      overflow: 'hidden',
      '& >:first-child': {
        height: '100%',
      },
      '&._auto-height': {
        height: 'auto',
        minHeight: 450,
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
    },
  })
);
