import { Box, Typography, FormControlLabel } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import VideocamIcon from '@material-ui/icons/Videocam';
import { InputField, GreenCheckbox } from 'modules/core/components/fields';
import { Validation } from 'modules/core/types';
import { updateVideo } from 'modules/editor/api/card-mutations';
import { ICardVersion } from 'modules/editor/graphql/gql';
import { IVideoAtributes } from 'modules/editor/graphql/mutations';
import { VideoAtributes } from 'modules/editor/models/atributes/video-atributes';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IReduxState } from 'store';
import Clear from 'modules/editor/components/CardSectionEditors/Clear';
import { clearSection, isDisabled, updateField } from './hooks';

interface IVideo {
  updateVideo: (videoAtr: VideoAtributes) => void;
  card: ICardVersion;
  classes: any;
  fields: string[];
}

export const Video = connect(
  (state: IReduxState) => ({ card: state.currentVersion.currentVer, fields: state.editorSteps.videoFields }),
  { updateVideo }
)((props: IVideo) => {
  const { updateVideo: saveChanges, card, classes, fields } = props;
  const [video, setVideo] = useState<VideoAtributes>(new VideoAtributes(card));
  const [errors, setErrors] = useState<Validation<IVideoAtributes>>({});

  useEffect(() => {
    video.cardVersionId = card.id;
    setVideo(video.nextState(video));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card.id]);

  const handleBlur = useCallback(
    (field: keyof IVideoAtributes) => {
      if (!errors[field]) {
        saveChanges(video);
      }
    },
    [saveChanges, errors, video]
  );

  const updateShowSectionHeader = useCallback(() => {
    video.showSectionHeader = !video.showSectionHeader;
    const atr = video.nextState(video);
    setVideo(atr);
    saveChanges(video);
  }, [setVideo, saveChanges, video]);

  return (
    <>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography className={classes.sectionHeader}>Video</Typography>
        {fields.length === 2 ? null : (
          <Box className={classes.clearBlock}>
            <Clear
              id="video_editor_clear"
              ico={<ClearIcon fontSize="inherit" />}
              onConfirm={() => clearSection(video, saveChanges, setVideo)}
            >
              Clear section
            </Clear>
          </Box>
        )}
      </Box>
      <div className={classes.fieldBlock}>
        <Typography className={classes.heading}>Youtube Video link</Typography>
        <div className={classes.inputWrapper0}>
          <InputField
            name="video-linkUrl"
            type="text"
            placeholder="Enter YouTube URL"
            disabled={isDisabled(fields, 'linkUrl')}
            onChange={(e) =>
              updateField({
                model: video,
                prop: 'linkUrl',
                setState: setVideo,
                val: e.target.value,
                errors,
                setErrors,
              })
            }
            error={!!errors.linkUrl}
            onBlur={() => handleBlur('linkUrl')}
            value={video.linkUrl as string}
            variant="filled"
            InputProps={{
              startAdornment: (
                <div className={classes.icon2}>
                  <div className={isDisabled(fields, 'linkUrl') ? classes.disabledIcon : undefined}>
                    <VideocamIcon />
                  </div>
                </div>
              ),
            }}
          />
        </div>
      </div>
      <div className={classes.fieldBlock}>
        <Typography className={classes.heading}>Title</Typography>
        <InputField
          name="video-title"
          type="text"
          label="Enter title"
          disabled={isDisabled(fields, 'title')}
          onChange={(e) => updateField({ model: video, prop: 'title', setState: setVideo, val: e.target.value })}
          onBlur={() => saveChanges(video)}
          value={video.title as string}
          variant="filled"
        />
        <FormControlLabel
          className={classes.checkbox}
          control={
            <GreenCheckbox
              name="video-showSectionHeader"
              checked={video.showSectionHeader}
              disabled={isDisabled(fields, 'showSectionHeader')}
              value={video.showSectionHeader}
              onChange={updateShowSectionHeader}
            />
          }
          label="Show section heading"
        />
      </div>
    </>
  );
});
