import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const sliderStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '98%',
    },
    setTime: {
      display: 'flex',
      fontSize: 12,
      padding: 0,
    },
  })
);
