import { IInviteUser } from 'modules/users/interfaces/assigned-users';
import { createSymbiote, Symbiotes } from 'redux-symbiote';
import {
  IReferrals,
  IResellerNetwork,
  IPayoutBatch,
  IResellerCommissionBreakdown,
} from 'modules/dashboard/graphql/gql';
import { Referrals } from 'modules/dashboard/models/referral';
import { ResellerNetwork } from 'modules/dashboard/models/reseller-network';
import { ResellerCommissionBreakdown } from 'modules/dashboard/models/reseller-commission-breakdown';

export interface IResellerState {
  referralData: IReferrals;
  resellerNetwork: IResellerNetwork;
  commissionBreakdown: IResellerCommissionBreakdown;
  payoutBatches: IPayoutBatch[];
  pending: boolean;
  actionPending: boolean;
  errors: string[];
};

const initialState: IResellerState = {
  referralData: new Referrals(),
  resellerNetwork: new ResellerNetwork(),
  commissionBreakdown: new ResellerCommissionBreakdown(),
  payoutBatches: [],
  pending: false,
  actionPending: false,
  errors: [],
};

export interface IReferralPayload {
  user: IInviteUser;
  status?: string;
  cardId?: number;
}

export interface IResellerActions extends Symbiotes<IResellerState> {
  inviteReseller: (state: IResellerState, payload: IReferralPayload) => IResellerState;
  retailReferral: (state: IResellerState, payload: IReferralPayload) => IResellerState;
  setErrors: (state: IResellerState, errors?: string[]) => IResellerState;
  setPending: (state: IResellerState, pending?: boolean) => IResellerState;
  setActionPending: (state: IResellerState, actionPending?: boolean) => IResellerState;
  setReferrals: (state: IResellerState, referralData: IReferrals) => IResellerState;
  setResellerNetworkStats: (state: IResellerState, networkStats: Partial<IResellerNetwork>) => IResellerState;
  setPayoutBatches: (state: IResellerState, payoutBatches: IPayoutBatch[]) => IResellerState;
  setNetworkResellers: (state: IResellerState, networkStats: IResellerNetwork) => IResellerState;
  setCommissionBreakdown: (state: IResellerState, commissionBreakdown: IResellerCommissionBreakdown) => IResellerState;
}

const symbiotes: IResellerActions = {
  inviteReseller: (state, payload: IReferralPayload) => ({
    ...state,
    referralData: {
      ...state.referralData,
      referrals: [
        {
          email: payload.user.email || "",
          fullName: payload.user.fullName,
          mobilePhone: payload.user.mobilePhone || "",
          status: 'sent',
          outreachType: 'Reseller',
        },
        ...state.referralData.referrals,
      ],
      countForPage: state.referralData.countForPage + 1,
      totalReferralCount: state.referralData.totalReferralCount + 1,
    }
  }),

  retailReferral: (state, payload: IReferralPayload) => ({
    ...state,
    referralData: {
      ...state.referralData,
      referrals: [
        {
          email: payload.user.email || "",
          fullName: payload.user.fullName,
          mobilePhone: payload.user.mobilePhone || "",
          status: 'sent',
          outreachType: 'Business Card',
        },
        ...state.referralData.referrals,
      ],
      countForPage: state.referralData.countForPage + 1,
      totalReferralCount: state.referralData.totalReferralCount + 1,
    }
  }),

  setErrors: (state, errors: string[] = []) => {
    return { ...state, errors };
  },

  setPending: (state, pending = false) => ({ ...state, pending }),

  setActionPending: (state, actionPending = false) => ({
    ...state,
    actionPending,
  }),

  setReferrals: (state, referralData: IReferrals) => ({
    ...state,
    referralData: referralData,
  }),

  setResellerNetworkStats: (state, networkStats: Partial<IResellerNetwork>) => ({
    ...state,
    resellerNetwork: {
      ...state.resellerNetwork,
      memberCount: networkStats.memberCount || 0,
      levels: networkStats.levels || 1,
    },
  }),

  setPayoutBatches: (state, payoutBatches: IPayoutBatch[]) => ({
    ...state,
    payoutBatches: payoutBatches,
  }),

  setNetworkResellers: (state, network: IResellerNetwork) => ({
    ...state,
    resellerNetwork: {
      ...network,
    },
  }),

  setCommissionBreakdown: (state, commissionBreakdown: IResellerCommissionBreakdown) => ({
    ...state,
    commissionBreakdown: {
      ...commissionBreakdown,
    },
  }),
};

export const { actions, reducer } = createSymbiote<IResellerState, IResellerActions>(initialState, symbiotes);
