import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { lightGreen, darkHeader, darkGrey5, initialDarkGray, white, skyblue } from 'themes/colors';

const DRAWLER_WIDTH = 400;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
    },
    drawer: {},
    quote: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 40,
    },
    quoteText: {
      display: 'table',
      position: 'relative',
      fontSize: 14,
      textAlign: 'center',
      alignItems: 'center',
      marginBottom: 40,
      lineHeight: theme.typography.pxToRem(20),
      verticalAlign: 'middle',
      opacity: 1,
      color: 'rgba(255,255,255, .7)',
      '& sup': {
        alignItems: 'flex-end',
        position: 'absolute',
        top: -14,
        left: '50%',
        fontFamily: 'Noto Sans Javanese',
        fontSize: 60,
        color: 'white',
        opacity: 1,
        transform: 'translate(-50%)',
      },
      '& sub': {
        position: 'absolute',
        bottom: -55,
        left: '50%',
        fontFamily: 'Noto Sans Javanese',
        fontSize: 60,
        color: 'white',
        opacity: 1,
        transform: 'translate(-50%)',
      },
    },
    author: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justyfyContent: 'center',
      marginTop: 20,
    },
    authorImg: {
      width: 62,
      height: 62,
      borderRadius: '50%',
      border: '2px solid #FFFFFF',
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.1)',
      overflow: 'hidden',
      marginBottom: 5,
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    authorDescription: {
      display: 'flex',
      flexDirection: 'column',
    },
    nameAuthor: {
      color: '#D1E4A5',
      fontWeight: 300,
      fontFamily: 'F37 Ginger',
      fontSize: 14,
      textAlign: 'center',
      lineHeight: theme.typography.pxToRem(20),
    },
    position: {
      color: 'white',
      fontWeight: 300,
      fontFamily: 'F37 Ginger Light',
      fontSize: 14,
      textAlign: 'center',
      lineHeight: theme.typography.pxToRem(20),
    },
    wltHead: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
      '& h4': {
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(23),
        letterSpacing: 0.27,
        textAlign: 'center',
        marginTop: 25,
        marginBottom: 25,
        [theme.breakpoints.down('xs')]: {
          marginTop: 10,
        },
      },
      '& img': {
        width: 266,
        height: 116,
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
    },
    wltSection: {
      display: 'flex',
      flexDirection: 'column',
      justyfeContent: 'center',
      alignItems: 'center',
      width: '100%',
      color: white,
      paddingTop: 82,
      paddingLeft: 24,
      paddingRight: 24,
      marginTop: 35,
      marginBottom: 45,
      borderTop: '1px solid rgba(255,255,255,0.15)',
      '&.hidden': {
        display: 'none',
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: 35,
      },
    },
    appBar: {
      position: 'relative',
      background: 'transparent',
      color: white,
      display: 'flex',
      width: '100%',
      maxWidth: 1400,
      paddingLeft: 20,
      paddingRight: 0,
      margin: '0 auto',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      boxShadow: 'none',
    },
    link: {
      color: white,
      padding: theme.spacing(4, 0.25),
      margin: theme.spacing(0, 1.75),
      fontSize: 16,
      alignItems: 'center',
      display: 'flex',
      // justifyContent: 'flex-start',
      textTransform: 'capitalize',
      textAlign: 'center',
      opacity: 0.8,
      transition: '0.3s',
      whiteSpace: 'nowrap',

      '&._bold': {
        fontFamily: 'F37 Ginger',
        fontWeight: 700,
      },
      '&._name': {
        paddingRight: 7,

        '&:hover': {
          textDecoration: 'none',
        },
      },
      '&._active': {
        opacity: 1,
        borderBottom: `3px solid ${lightGreen}`,
        marginBottom: -3,
      },
      '&:first-child': {
        // paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: theme.spacing(1.2),
      },

      '&:hover': {
        opacity: 1,
        textDecoration: 'none',
      },
    },
    linkName: {
      padding: theme.spacing(0, 1),
      marginTop: 2,
    },
    headerBarTextContainer: {
      padding: theme.spacing(2, 0, "2px"),
      marginTop: -8,
      minHeight: 'unset',
      display: 'flex',
    },
    toolbar: {
      display: 'flex',
      FlexDirection: 'row',
      width: 'fit-content',
    },
    links: {
      display: 'flex',
      width: '100%',
      letterSpacing: 1.2,
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    headerLogo: {
      '& ._logoContainer': {
        display: 'flex',
        alignItems: 'center',
      },
      '& ._logo': {
        marginRight: 20,
        width: 56,
        height: 56,
        objectFit: 'contain',
        fill: '#76B741',
      },
      '& ._name': {
        fontSize: 30,
        letterSpacing: 1,
        lineHeight: theme.typography.pxToRem(43),
        color: white,
      },
      '& ._green': {
        color: lightGreen,
      },
    },
    headerWrap: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 110,
      width: '100%',

      '&._not-found': {
        '&> :last-child': {
          display: 'none',
        },
      },
      '&._dark': {
        background: darkHeader,
        paddingBottom: 0,
        minHeight: 150,
        '&> :first-child': {
          // padding: '0 5%',
        },

        '& header': {
          paddingLeft: 62,
          maxWidth: 1408,
        },

        '& $headerRow': {
          borderBottom: 'none',
        },
      },
    },
    headerInner: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },

    // NOTE: Many differences between light and dark header layouts are handled here
    headerRow: {
      padding: theme.spacing(0, 2),
      borderBottom: `1px solid ${skyblue}33`,

      '& ._darkLayoutLogo': {
        display: 'none',
      },

      '&._dark_secondHeaderRow': {
        display: 'none',
      },

      '&._dark': {
        // padding: '0 5%',
        '&:last-child': {
          padding: theme.spacing(0, 2, 5),
          // padding: '0 5% 0 1.5%',
        },

        '&._dark_secondHeaderRow': {
          display: 'block',
        },

        '& ._darkHeaderRowInfo': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: 1408,
          width: '100%',
          margin: '0 auto',
        },

        '& ._darkLayoutLogo': {
          display: 'block',
          height: 32,
          width: 32,
          fill: 'white',
        },

        '& ._divider': {
          marginLeft: 18,
          marginRight: 14,
          width: 1,
          height: 60,
          background: '#555555',
        },

        '& $headerLogo': {
          display: 'none',
        },

        '& $toolbar': {
          width: '100%',
          justifyContent: 'start',
        },

        '& $header': {
          paddingLeft: theme.spacing(6),
          paddingRight: theme.spacing(3),
        },

        '& $link': {
          fontSize: 12,
          textTransform: 'uppercase',
          padding: theme.spacing(2, 0.25),

          '&._active': {
            opacity: 1,
            borderBottom: 0,
            marginBottom: 0,
          },
        },
      },
    },

    previewMobileButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '7px auto 0 auto',
      width: 50,
      height: 50,
      borderRadius: '50%',
      background: 'white',
      marginBottom: 10,
      '& svg': {
        fill: '#76B83F',
      },
    },
    previewMobile: {
      padding: '5px 18px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRight: '1px #4E5156 solid',
      '&:last-child': {
        borderRight: 'none',
      },
    },
    actionContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    previewMobileText: {
      textTransform: 'uppercase',
      marginBottom: '0',
      marginTop: 10,
      color: 'white',
      opacity: 0.2,
      fontSize: 10,
      fontWeight: 700,
      lineHeight: theme.typography.pxToRem(14),
    },
    closeMenuButton: {
      marginRight: 'auto',
      marginLeft: 0,
    },
    drawerPaper: {
      width: DRAWLER_WIDTH,
      maxWidth: '72%',
      backgroundColor: '#222222',
      overflowY: 'visible',
    },
    wrapper: {
      overflowY: 'auto',
    },
    hide: {
      display: 'none',
    },
    drawerHeader: {
      position: 'absolute',
      left: theme.spacing(-8),
      top: 0,
      '& svg': {
        fill: 'white',
        width: 40,
        height: 40,
      },
    },
    menuItem: {
      fontSize: 14,
      color: white,
      fontWeight: 700,
      textTransform: 'capitalize',

      '&._green': {
        color: lightGreen,
      },
      padding: theme.spacing(2, 3),

      '&._small': {
        fontWeight: 500,
        padding: theme.spacing(1.5, 3),
        color: '#A9A9A9',
        textTransform: 'unset',
      },
      '&.MuiListItem-divider': {
        // borderBottom: "1px solid #909FB433",
      },
    },
    menu: {
      color: 'white',

      '& svg': {
        fill: 'white',
      },
    },
    mobileMenu: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: 9,
      paddingBottom: 4,

      '& .mobile-menu__menu-button': {
        padding: theme.spacing(2, 2),
        margin: theme.spacing(-0.75, -2),
      },

      '& .mobile-menu__dropdown-button': {
        padding: theme.spacing(1.5),
        margin: theme.spacing(-1),
      },

      // '& .mobile-menu__dropdown-button:hover': {
      //   backgroundColor: "initial",
      // },
    },
    logoMobile: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 3,

      '& svg': {
        fill: 'white',
        width: 24,
        height: 24,
      },
    },
    mobileTitle: {
      color: 'white',
      fontFamily: 'F37 Ginger',
      fontWeight: 700,
      fontSize: 20,
      lineHeight: theme.typography.pxToRem(29),
      padding: theme.spacing(0, 1.5),
    },
    divider: {
      background: '#ffff',
      opacity: 0.2,
      height: 1,
    },
    button: {
      minWidth: 48,
      paddingLeft: 0,
      paddingRight: 0,
    },
    textWrap: {
      display: 'flex',
      alignItems: 'center',
    },
    preHeaderContainer: {
      backgroundColor: 'black',
      width: "100%",
      color: initialDarkGray,
      padding: theme.spacing(0, 2)
    },
    preHeaderRow: {
      height: theme.spacing(5),
      paddingLeft: 1,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      display: 'flex',
      flexDirection: 'row',
      maxWidth: 1408,
      width: '100%',
      margin: '0 auto',
      alignItems: 'center',

      '& .divider': {
        height: 20,
        width: 1,
        background: darkGrey5,
        marginLeft: 21,
        marginRight: 15,
      },
    },
    profileMenu: {
      '&.isPublic': {
        marginTop: -4,
      }
    },

    [theme.breakpoints.down('md')]: {
      headerWrap: {
        minHeight: 50,

        '&._dark': {
          boxShadow: '0 10px 20px 0 rgba(0,0,0,0.5)',
          minHeight: 50,

          '& header': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },

      headerRow: {
        borderBottom: 'none',
        '&._dark': {
          '&:last-child': {
            padding: theme.spacing(0, 2, 0.5),
          },
        },
      },
    },

    [theme.breakpoints.down('sm')]: {
      mobileTitle: {
        display: 'none',
      },

      mobileMenu: {
        '& .mobile-menu__menu-button': {
          padding: theme.spacing(2, 2),
          margin: theme.spacing(-1.5, -2),
        },

        '& .mobile-menu__dropdown-button': {
          padding: theme.spacing(1.5),
          margin: theme.spacing(-2),
        },
      },
    },
  })
);
