import { Location, LocationListener, UnregisterCallback } from 'history';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router';

const sendPageView: LocationListener = (location: Location): void => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
  // console.log("GA|Pageview Sent: ", location.pathname);
};

interface IProps {
  children: JSX.Element;
  trackingId: string;
}

const isLocalHost = (hostname: string) =>
  !!(
    hostname === 'localhost' ||
    hostname === '[::1]' ||
    hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );

const GAListener = ({ children, trackingId }: IProps): JSX.Element => {
  const history = useHistory();
  useEffect((): UnregisterCallback | void => {
    if (trackingId && !isLocalHost(window.location.hostname)) {
      ReactGA.initialize(trackingId);
      sendPageView(history.location, 'REPLACE');
      return history.listen(sendPageView);
    }
  }, [history, trackingId]);

  return children;
};

export default GAListener;
