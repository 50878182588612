import React, { lazy, Suspense } from 'react';
import { Box, Fab, Typography, Hidden, makeStyles } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { AssignedUsers } from 'modules/users/components/UsersPage/AssignedUsers';
import { MenuButton } from 'modules/core/components/buttons/PublishButton/MenuButton';
import Header from 'modules/core/components/Header';
import InviteUsers from 'modules/users/components/UsersPage/InviteUsers';
import ContentWithSidemenu from 'layouts/ContentWithSidemenu';
import { useHistory } from 'react-router';
import { useDefaultManifest } from 'modules/core/utils';
import { If, Then, Else } from 'react-if';
import { useNeedBuy } from 'hooks/useNeedBuy';

const Footer = lazy(() => import('modules/core/components/Footer'));

export const UsersPage: React.FC = () => {
  useDefaultManifest();
  const classes = useStyles();
  const history = useHistory();
  const needBuy = useNeedBuy();

  const buyMore = () => {
    history.push('/buy-more');
  };

  const buyNow = () => {
    history.push('/buy');
  };

  return (
    <div className="App Site">
      <div className="App-header _white">
        <Header exClass="_dark" isPublic={false}>
          <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h4">Manage users</Typography>
            <If condition={needBuy}>
              <Then>
                <MenuButton onClick={buyNow}>Buy now</MenuButton>
              </Then>
              <Else>
                <MenuButton onClick={buyMore}>Buy more</MenuButton>
              </Else>
            </If>
          </Box>
        </Header>
      </div>
      <div className="Site-content">
        <main>
          <ContentWithSidemenu
            menuItems={[
              { letter: '1', title: 'Assigned users', component: AssignedUsers, id: 'assigned_user_page' },
              { letter: '2', title: 'Invite users', component: InviteUsers, id: 'invite_user_page' },
            ]}
          />
          <Hidden mdUp>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Fab onClick={buyMore} classes={{ root: classes.fabButton }}>
                <ShoppingCartIcon color="secondary" />
              </Fab>
              <Typography color="secondary" classes={{ root: classes.fabText }}>
                BUY MORE
              </Typography>
            </Box>
          </Hidden>
        </main>
      </div>
      <Suspense fallback={null}>
        <Footer exClass="_dark" isWltHidden={true} />
      </Suspense>
    </div>
  );
};

const useStyles = makeStyles({
  fabButton: {
    width: 50,
    height: 50,
  },
  fabText: {
    fontSize: 12,
    lineHeight: '17px',
    marginTop: 4,
  },
});
