import React, { useCallback, useEffect, useState, useMemo } from 'react';
import Cookies from 'js-cookie';
import { ShareCardData } from 'modules/editor/models/share-data';
import { getShareDialogData } from 'modules/editor/api/card-query';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { useSearchParams } from '../hooks/useSearchParams';
import { EmailDialog } from 'modules/editor/containers/Card/GdprDialog/EmailDialog';
import { CallDialog } from './CallDialog';
import { InitialDialog } from './InitialDialog';
import { EnterContactDetailsDialog } from './EnterContactDetailsDialog';
import { useLocation } from 'react-router';
import { SetState } from 'types/helpers';

interface IProps {
  cardId: number;
  isLoading: boolean;
  setOpen: SetState<boolean>;
  canShowForCard: boolean;
  gdprPopupEnabled: boolean;
}

export type GdprType = 'email' | 'call' | '';

export interface IGdprState {
  type: GdprType;
  error: boolean;
}

const initialState: IGdprState = {
  type: '',
  error: false,
};

export const GdprDialog: React.FC<IProps> = (props) => {
  const { cardId, isLoading, setOpen, canShowForCard, gdprPopupEnabled } = props;

  const profile = useTypedSelector((state) => state.auth.profile);
  const { id: currentAccountId } = profile;

  const [shareData, setShareData] = useState<ShareCardData | null>(null);
  const [values, setValues] = useState<IGdprState>(initialState);
  const { type } = values;

  const { search } = useLocation();
  const { id: cardShareId } = useSearchParams();

  const isNotShown = Cookies.get(`Gopaperless_gdpr_${cardId}_${currentAccountId}`) !== 'shown';

  const canShow = useMemo(() => {
    return isNotShown && canShowForCard && !isLoading;
  }, [isNotShown, isLoading, canShowForCard]);

  const showShareCardDialog = useMemo(() => {
    return canShow && shareData && shareData.id && !shareData.contactRequestCreated;
  }, [canShow, shareData]);

  const showInitialVisitDialog = canShow;

  const creationType = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    return !!searchParams.get('qr') ? 'qr' : 'default';
  }, [search]);

  const getDialogData = useCallback(async () => {
    if (cardShareId) {
      try {
        const data = await getShareDialogData(cardShareId);
        setShareData(data);
        return data;
      } catch (error) {
        console.warn(error);
      }
    }
  }, [cardShareId]);

  useEffect(() => {
    getDialogData();
  }, [getDialogData]);

  const markAsShown = () => {
    if (!values.error) {
      Cookies.set(`Gopaperless_gdpr_${cardId}_${currentAccountId}`, 'shown', { expires: 365 });
    }
  };

  useEffect(() => {
    if (values.error) {
      return setOpen(true);
    }

    showShareCardDialog || showInitialVisitDialog ? setOpen(true) : setOpen(false);
  }, [setOpen, showShareCardDialog, showInitialVisitDialog, values.error]);

  /**
   * set initial state
   */
  function onClose(): void {
    if (!values.error) {
      setShareData(null);
      setOpen(false);
    }
    setValues(initialState);
  }

  if (isNotShown) {
    if (values && values.type) {
      if (!values.error) {
        markAsShown();
      }

      switch (type) {
        case 'email':
          return <EmailDialog values={values} onClose={onClose} />;
        case 'call':
          return <CallDialog values={values} onClose={onClose} />;
      }
    } else if (shareData && showShareCardDialog) {
      return <InitialDialog shareData={shareData} cardId={cardId} setValues={setValues} onClose={markAsShown} />;
    } else if (showInitialVisitDialog && gdprPopupEnabled) {
      return (
        <EnterContactDetailsDialog
          cardId={cardId}
          setValues={setValues}
          onClose={markAsShown}
          creationType={creationType}
        />
      );
    }
  }
  return null;
};
