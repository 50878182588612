import { IPlan, IPlanMetadata, IPlanTiers, ICoupon, ICouponMetadata, IPrice } from 'modules/billing/graphql/gql';

export class CouponMetadata implements ICouponMetadata {
  public interval?: number;
  public reseller?: boolean = false;

  constructor(meta?: ICouponMetadata) {
    if (meta) {
      this.interval = meta.interval;
      this.reseller = meta.reseller;
    }
  }
}

export class Coupon implements ICoupon {
  public amountOff?: number;
  public created!: number;
  public currency?: string;
  public duration!: string;
  public durationInMonths?: number;
  public id!: string;
  public livemode!: boolean;
  public maxRedemptions?: number;
  public metadata?: CouponMetadata = new CouponMetadata();
  public name?: string;
  public object!: string;
  public percentOff?: string;
  public redeemBy?: number;
  public timesRedeemed?: number;
  public valid: boolean = false;

  constructor(coupon?: ICoupon) {
    if (coupon) {
      this.amountOff = coupon.amountOff;
      this.created = coupon.created;
      this.currency = coupon.currency;
      this.duration = coupon.duration;
      this.durationInMonths = coupon.durationInMonths;
      this.id = coupon.id;
      this.livemode = coupon.livemode;
      this.maxRedemptions = coupon.maxRedemptions;
      this.metadata = new CouponMetadata(coupon.metadata);
      this.name = coupon.name;
      this.object = coupon.object;
      this.percentOff = coupon.percentOff || "0";
      this.redeemBy = coupon.redeemBy;
      this.valid = coupon.valid;
    }
  }
}

export class PlanMetadata implements IPlanMetadata {
  public poa?: boolean = false;
  public upToMax?: number;

  constructor(meta?: IPlanMetadata) {
    if (meta) {
      this.poa = meta.poa;
      this.upToMax = meta.upToMax;
    }
  }
}

export class PlanTiers implements IPlanTiers {
  public flatAmount?: number;
  public flatAmountDecimal?: string;
  public unitAmount?: number;
  public unitAmountDecimal?: string;
  public upTo?: number;

  constructor(planTiers: IPlanTiers) {
    if (planTiers) {
      this.flatAmount = planTiers.flatAmount;
      this.flatAmountDecimal = planTiers.flatAmountDecimal;
      this.unitAmount = planTiers.unitAmount;
      this.unitAmountDecimal = planTiers.unitAmountDecimal;
      this.upTo = planTiers.upTo;
    }
  }
}

export class Plan implements IPlan {
  public active: boolean = false;
  public aggregateUsage?: string;
  public amount?: number;
  public amountDecimal?: string;
  public billingScheme?: string;
  public coupon: Coupon = new Coupon();
  public created!: number;
  public currency!: string;
  public id!: string;
  public interval!: 'year' | 'month';
  public intervalCount: number = 1;
  public livemode: boolean = false;
  public metadata = new PlanMetadata();
  public nickname?: string;
  public object: string = 'plan';
  public product!: string;
  public tiers: IPlanTiers[] = [];
  public tiersMode?: string;
  public trialPeriodDays?: number;
  public usageType!: string;
  public vat = true;

  constructor(plan?: IPlan) {
    if (plan) {
      this.active = plan.active;
      this.aggregateUsage = plan.aggregateUsage;
      this.amount = plan.amount;
      this.amountDecimal = plan.amountDecimal;
      this.billingScheme = plan.billingScheme;
      this.coupon = new Coupon(plan.coupon);
      this.created = plan.created;
      this.currency = plan.currency;
      this.id = plan.id;
      this.interval = plan.interval;
      this.intervalCount = plan.intervalCount;
      this.livemode = plan.livemode;
      this.metadata = new PlanMetadata(plan.metadata);
      this.nickname = plan.nickname;
      this.object = plan.object;
      this.product = plan.product;
      this.tiers = plan.tiers ? plan.tiers.map((t) => new PlanTiers(t)) : [];
      this.tiersMode = plan.tiersMode;
      this.trialPeriodDays = plan.trialPeriodDays;
      this.usageType = plan.usageType;
      this.vat = plan.vat;
    }
  }
}

export class Price implements IPrice {
  public active: boolean = false;
  public billingScheme?: string;
  public created!: number;
  public currency!: string;
  public id!: string;
  public livemode: boolean = false;
  public lookupKey?: string;
  public metadata = new PlanMetadata();
  public nickname?: string;
  public object: string = 'plan';
  public product!: string;
  public recurring?: string;
  public tiers?: IPlanTiers[] = [];
  public tiersMode?: string;
  public transformQuantity?: string;
  public type!: string;
  public unitAmount!: number;
  public unitAmountDecimal!: number;

  constructor(price?: IPrice) {
    if (price) {
      this.active = price.active;
      this.billingScheme = price.billingScheme;
      this.created = price.created;
      this.currency = price.currency;
      this.id = price.id;
      this.livemode = price.livemode;
      this.lookupKey = price.lookupKey;
      this.metadata = new PlanMetadata(price.metadata);
      this.nickname = price.nickname;
      this.object = price.object;
      this.product = price.product;
      this.recurring = price.recurring;
      this.tiers = price.tiers ? price.tiers.map((t) => new PlanTiers(t)) : [];
      this.tiersMode = price.tiersMode;
      this.transformQuantity = price.transformQuantity;
      this.type = price.type;
      this.unitAmount = price.unitAmount;
      this.unitAmountDecimal = price.unitAmountDecimal;
    }
  }
}
