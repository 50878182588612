import { makeStyles } from '@material-ui/core';
import { clearBlockStyle, fieldBlockStyle, headingStyle, sectionHeaderStyle } from '../../styles';

export const useStyles = makeStyles((theme) => ({
  clearBlock: clearBlockStyle(theme),
  fieldBlock: fieldBlockStyle,
  fieldsBlock: { marginBottom: 55 },
  heading: headingStyle(theme),
  sectionHeader: sectionHeaderStyle(theme),
  subheading: {
    color: '#919598',
    fontSize: 12,
    fontWeight: 300,
    fontFamily: 'F37 Ginger Light',
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(1),
  },
}));
