import { ID } from 'modules/core/types';

export interface ISlot {
  id: ID;
  status: string;
  subscriptionId: ID;
  accountId: ID;
}

export interface IInviteUser {
  email: string;
  fullName: string;
  mobilePhone?: string;
  message?: string;
}

export interface ISlotUser extends IInviteUser {
  slot: ISlot;
}

export interface IAssignedUsers {
  assignedUsers: ISlotUser[];
  occupiedSlots: number;
  totalSlots: number;
  unoccupiedSlots: number;
}

export enum E_USER_STATUS {
  ACTIVE = 'active',
  DISABLED = 'disabled',
  INVITED = 'invite_send',
}

export enum E_PANEL_STATE {
  NONE,
  DELETING,
  REASSIGNING,
}

export const USER_STATUSES: { [key: string]: string } = {
  [E_USER_STATUS.ACTIVE]: 'Active',
  [E_USER_STATUS.DISABLED]: 'Disabled',
  [E_USER_STATUS.INVITED]: 'Invite sent',
};

export interface IInvitePayload {
  user: IInviteUser;
  status: string;
}
