import AuthorizationDialog from 'modules/auth/components/Authorization/AuthorizationDialog';
import CssGreenButton from 'modules/core/components/buttons/roundGreenButton';
import React, { FormEvent, useCallback } from 'react';
import { InputField } from 'modules/core/components/fields/InputField';
import { useStyles } from './styles';
import { IUserForm } from 'modules/auth/interfaces/auth';

interface IStepProps {
  bottom: any;
  onSubmit: (data: Partial<IUserForm>) => void;
  filledValues: any;
  isNextHidden: any;
  onChange: any;
}

const SignupStep1: React.FC<IStepProps> = ({ onSubmit, bottom, filledValues, isNextHidden, onChange }) => {
  const classes = useStyles();

  const handleChange = useCallback(
    (prop: keyof IUserForm) => (event: React.ChangeEvent<HTMLInputElement>) => {
      let val = event.target.value;
      if (val) {
        val = val[0].toUpperCase() + val.slice(1);
      }
      onChange(prop, val);
    },
    [onChange]
  );

  const handleContinue = useCallback(
    (evt: FormEvent) => {
      evt.preventDefault();
      if (filledValues.firstName && filledValues.lastName && onSubmit) {
        onSubmit(filledValues);
      }
    },
    [filledValues, onSubmit]
  );

  return (
    <AuthorizationDialog
      title="Let's get started"
      subtitle="What’s your name?"
      dlgBody={
        <form noValidate onSubmit={handleContinue} className={classes.form}>
          <div className={classes.textField}>
            <InputField
              classes={{ root: classes.textField }}
              variant="filled"
              type="text"
              label="First name"
              name="sign_up_firstName"
              value={filledValues.firstName}
              onChange={handleChange('firstName')}
            />
          </div>
          <div className={classes.textField}>
            <InputField
              classes={{ root: classes.textField }}
              variant="filled"
              type="text"
              label="Last name"
              name="sign_up_lastName"
              value={filledValues.lastName}
              onChange={handleChange('lastName')}
            />
          </div>
        </form>
      }
      footer={
        <CssGreenButton
          disabled={isNextHidden}
          id="sign_up_countinue_step_1"
          className={classes.button}
          onClick={handleContinue}
        >
          Continue
        </CssGreenButton>
      }
      bottom={bottom}
    />
  );
};

export default SignupStep1;
