import { IStylingAtributes } from 'modules/editor/graphql/mutations';
import { ICardVersion } from 'modules/editor/graphql/gql';
import { IModel } from 'modules/core/interfaces/model';
import { clearObject } from 'modules/core/utils';

export class StylingAtributes implements IModel<IStylingAtributes> {
  public bodyFont?: string = '';
  public bodyFontHexColour?: string = '';
  public bodyFontStyle?: string = 'Regular';
  public businessName?: string = '';
  public primaryLinksHexColour?: string = '';
  public secondaryIconsHexColour?: string = '';
  public tagLine?: string = '';
  public taglineHexColour?: string = '';
  public titleFont?: string = '';
  public titleFontHexColour?: string = '';
  public titleFontStyle?: string = 'Regular';
  public cardVersionId!: number;
  public cardStyleId!: number;
  public businessNameHexColour?: string = '';
  [props: string]: any;

  constructor(atributes?: ICardVersion) {
    if (atributes) {
      const { cardStyle, businessName, tagLine, id: cardVersionId } = atributes;
      this.tagLine = tagLine || '';
      this.cardVersionId = cardVersionId;
      this.businessName = businessName || '';

      if (cardStyle) {
        const {
          bodyFont,
          bodyFontHexColour,
          bodyFontStyle,
          id: cardStyleId,
          primaryLinksHexColour,
          secondaryIconsHexColour,
          titleFont,
          titleFontHexColour,
          titleFontStyle,
          taglineHexColour,
          businessNameHexColour,
        } = cardStyle;

        this.bodyFont = bodyFont || '';
        this.bodyFontHexColour = bodyFontHexColour || '';
        this.bodyFontStyle = bodyFontStyle || 'Regular';
        this.primaryLinksHexColour = primaryLinksHexColour || '';
        this.taglineHexColour = taglineHexColour || '';
        this.businessNameHexColour = businessNameHexColour || '';
        this.secondaryIconsHexColour = secondaryIconsHexColour || '';
        this.titleFont = titleFont || '';
        this.titleFontHexColour = titleFontHexColour || '';
        this.titleFontStyle = titleFontStyle || 'Regular';
        this.cardStyleId = cardStyleId;
      }
    }
  }

  public asState(): IStylingAtributes {
    return {
      bodyFont: this.bodyFont,
      bodyFontHexColour: this.bodyFontHexColour,
      bodyFontStyle: this.bodyFontStyle,
      businessName: this.businessName,
      cardStyleId: this.cardStyleId,
      cardVersionId: this.cardVersionId,
      primaryLinksHexColour: this.primaryLinksHexColour,
      secondaryIconsHexColour: this.secondaryIconsHexColour,
      tagLine: this.tagLine,
      titleFont: this.titleFont,
      titleFontHexColour: this.titleFontHexColour,
      taglineHexColour: this.taglineHexColour,
      titleFontStyle: this.titleFontStyle,
      businessNameHexColour: this.businessNameHexColour,
    };
  }

  public nextState(state: StylingAtributes) {
    const atr = new StylingAtributes();
    Object.keys(atr).forEach((key) => (atr[key] = state[key]));
    return atr;
  }

  public clear(nullable: boolean, feilds?: string[]) {
    const atr = new StylingAtributes();
    if (feilds) {
      clearObject(atr, nullable);
      feilds.forEach((key) => (atr[key] = this[key]));
      atr.cardStyleId = this.cardStyleId;
      atr.cardVersionId = this.cardVersionId;
      atr.schema = this.schema;
      return atr;
    } else {
      clearObject(atr, nullable);
      atr.cardStyleId = this.cardStyleId;
      atr.cardVersionId = this.cardVersionId;
      atr.schema = this.schema;
      return atr;
    }
  }

  public toJSON(): IStylingAtributes {
    return {
      bodyFont: this.bodyFont,
      bodyFontHexColour: this.bodyFontHexColour,
      bodyFontStyle: this.bodyFontStyle,
      businessName: this.businessName || null,
      businessNameHexColour: this.businessNameHexColour,
      cardStyleId: this.cardStyleId,
      cardVersionId: this.cardVersionId,
      primaryLinksHexColour: this.primaryLinksHexColour,
      secondaryIconsHexColour: this.secondaryIconsHexColour,
      taglineHexColour: this.taglineHexColour,
      tagLine: this.tagLine || null,
      titleFont: this.titleFont,
      titleFontHexColour: this.titleFontHexColour,
      titleFontStyle: this.titleFontStyle,
    };
  }

  public validate(path: string) {
    return this.schema.validateSyncAt(path, this.asState());
  }
}
