import React from 'react';
import { useStyles } from './useStyles';

interface IProps {
  tagLine?: string;
  logoUrl?: string;
  businessName?: string;
  tagStyle?: any;
  businessStyle?: any;
  // icon?: string;
  // website?: string;
}

export const Header: React.FC<IProps> = (props) => {
  const { tagLine, logoUrl, businessName, tagStyle, businessStyle } = props;
  const { wrapper, logo, nameClass, tagClass } = useStyles();

  return (
    <header className={wrapper}>
      {/* feature postponed by client
      <Hidden lgUp>
        <Icon icon={icon} website={website} />
      </Hidden> */}
      {logoUrl ? (
        <img src={logoUrl} alt="Logo" className={logo} />
      ) : (
        <div className={nameClass} style={businessStyle}>
          {businessName}
        </div>
      )}
      <div className={tagClass} style={tagStyle}>
        {tagLine}
      </div>
    </header>
  );
};
