import React, { lazy, Suspense } from 'react';
import { ICardView } from 'modules/editor/interfaces';
import LandingPage from 'modules/core/containers/LandingPage'
import CssGreenButton from 'modules/core/components/buttons/roundGreenButton';
import { BUY_ROUTE, SIGNUP_ROUTE } from 'routes/routes';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { getCurrency } from 'modules/core/api/currency';
import { IReduxState } from 'store';
import { connect } from 'react-redux';
import { QRCodeSection } from 'modules/editor/components/CardSectionsView';
import { useStyles as useRetailCardStyles } from 'modules/editor/containers/Card/RetailCard/useStyles';
import { E_PREVIEW_TYPE } from 'modules/editor/constants/enums';
import { ContactDetailsHeader } from 'modules/editor/components/ContactDetailsHeader';
import { Link } from '@material-ui/core';
import { embeddedResellerNutshellVideoUrl, embeddedReplicatingBrandVideoUrl } from 'modules/public/pages/learning-center/consts';

import attenborough from "assets/images/attenborough.jpg";
import icondata from "assets/images/resellerCard/icon-data.svg";
import iconeye from "assets/images/resellerCard/icon-eye.svg";
import iconleaf from "assets/images/resellerCard/icon-leaf.svg";
import iconmap from "assets/images/resellerCard/icon-map.svg";
import iconpalette from "assets/images/resellerCard/icon-palette.svg";
import iconpeople from "assets/images/resellerCard/icon-people.svg";
import benefits1 from "assets/images/resellerCard/jane-churchyard-benefits-1.jpg";
import benefits2 from "assets/images/resellerCard/jane-churchyard-benefits-2.jpg";
import benefits3 from "assets/images/resellerCard/jane-churchyard-benefits-3.jpg";
import benefits4 from "assets/images/resellerCard/jane-churchyard-benefits-4.jpg";
import benefits5 from "assets/images/resellerCard/jane-churchyard-benefits-5.jpg";
import benefits6 from "assets/images/resellerCard/jane-churchyard-benefits-6.jpg";
import janechurchyard from "assets/images/resellerCard/jane-churchyard.jpg";
import janechurchyardscreengrab10 from "assets/images/resellerCard/jane-churchyard-screengrab-10.jpg";
import janechurchyardscreengrab11 from "assets/images/resellerCard/jane-churchyard-screengrab-11.jpg";
import janechurchyardscreengrab12 from "assets/images/resellerCard/jane-churchyard-screengrab-12.jpg";
import janechurchyardscreengrab5 from "assets/images/resellerCard/jane-churchyard-screengrab-5.jpg";
import janechurchyardscreengrab8 from "assets/images/resellerCard/jane-churchyard-screengrab-8.jpg";
import janechurchyardscreengrab9 from "assets/images/resellerCard/jane-churchyard-screengrab-9.jpg";
import thirdoff from "assets/images/resellerCard/third-off.png";
import why1 from "assets/images/resellerCard/why-1.png";
import why2 from "assets/images/resellerCard/why-2.png";
import why3 from "assets/images/resellerCard/why-3.png";
import why4 from "assets/images/resellerCard/why-4.png";
import why5 from "assets/images/resellerCard/why-5.png";
import why6 from "assets/images/resellerCard/why-6.png";
import why7 from "assets/images/resellerCard/why-7.png";
import worldlandtrust from "assets/images/resellerLandingPage/world-land-trust.jpg";
import worldlandtrustsvg from "assets/images/worldTrust.svg";

const Footer = lazy(() => import('modules/editor/components/CardSectionsView/Footer'));

interface IState {
  currency: string;
  getCurrency: () => void;
}

const mapState = (state: IReduxState) => {
  return {
    currency: state.general.currency,
  };
};

const ResellerCard: React.FC<ICardView & IState> = (props) => {
  const { isPreview = false, previewType = E_PREVIEW_TYPE.NONE, cardVer, currency } = props;
  const { card, contactDetailsHeaderShow } = cardVer;
  const isAuthenticated = useTypedSelector((state) => state.auth.isAuthenticated);
  const vat = ["£", "€"].includes(currency) ? "+ VAT" : "";
  const classes = useRetailCardStyles();

  const actionPath = (() => {
    if (previewType === E_PREVIEW_TYPE.NONE) {
      return isAuthenticated ? BUY_ROUTE.path : SIGNUP_ROUTE.path;
    } else {
      return "";
    }
  })();

  const action = (
    <>
      <div style={{ marginTop: 40 }}></div>
      <CssGreenButton id="reseller-card-cta">
        <Link href={actionPath} target="_blank" style={{ color: 'white' }}>Try for free now</Link>
      </CssGreenButton>
    </>
  )

  return (
    <>
      <LandingPage previewType={previewType}>
        <div className="landing-page-body">
          {
            contactDetailsHeaderShow && (
              <ContactDetailsHeader
                cardVer={cardVer}
                isPreview={isPreview}
                previewType={previewType}
              />
            )
          }
          <div className="hero block-1">
            <div className="header">
              <h1><span>Supercharge</span> your future with a competitive advantage</h1>
              <p>Discover the digital business card that helps you connect, engage and grow</p>
              {action}
            </div>
            <div className="benefits center">
              <ul className="info">
                <li className="benefit benefit-1 active" data-screen={1}>
                  <div className="padding">
                    <span className="icon" style={{backgroundImage: `url(${iconpalette})` }} />
                    <h2>Mirror brand identity</h2>
                    <p>Unlike other providers, our flexible platform enables you to replicate styling and branding</p>
                    <span className="line"><span className="dot" /></span>
                  </div>
                </li>
                <li className="benefit benefit-2" data-screen={2}>
                  <div className="padding">
                    <span className="icon" style={{ backgroundImage: `url(${icondata})`  }} />
                    <h2>Build high-value database</h2>
                    <p>We track re-shares of your card, giving you contact details of engaged leads to convert to customers</p>
                    <span className="line"><span className="dot" /></span>
                  </div>
                </li>
                <li className="benefit benefit-3" data-screen={3}>
                  <div className="padding">
                    <span className="icon" style={{backgroundImage: `url(${iconeye})` }} />
                    <h2>Link pictures to products</h2>
                    <p>The interactive Gallery hyperlinks images of products, services &amp; work directly to your website</p>
                    <span className="line"><span className="dot" /></span>
                  </div>
                </li>
                <li className="benefit benefit-4" data-screen={4}>
                  <div className="padding">
                    <span className="icon" style={{backgroundImage: `url(${iconmap})` }} />
                    <h2>Pinpoint location &amp; hours</h2>
                    <p>Add your address plus a Google Maps link &amp; include trading hours to keep customers informed</p>
                    <span className="line"><span className="dot" /></span>
                  </div>
                </li>
                <li className="benefit benefit-5" data-screen={5}>
                  <div className="padding">
                    <span className="icon" style={{backgroundImage: `url(${iconpeople})` }} />
                    <h2>Increase customer interaction</h2>
                    <p>Use interactive offers, news and video sections on your card to drive traffic to your business</p>
                    <span className="line"><span className="dot" /></span>
                  </div>
                </li>
                <li className="benefit benefit-6" data-screen={6}>
                  <div className="padding">
                    <span className="icon" style={{backgroundImage: `url(${iconleaf})` }} />
                    <h2>Boost green credentials</h2>
                    <p>For every card you buy, partner charity World Land Trust plants a tree in a devastated area</p>
                    <span className="line"><span className="dot" /></span>
                  </div>
                </li>
              </ul>
              <div className="gallery">
                <ul className="speed-1 featured-1">
                  <li className="screen-1">
                    <picture>
                      <source type="image/jpg" srcSet={benefits1} />
                      <picture>
                        <source type="image/jpg" srcSet={benefits1} />
                        <img src={benefits1} alt="Mirror brand identity" />
                      </picture>
                    </picture>
                  </li>
                  <li className="screen-2">
                    <picture>
                      <source type="image/jpg" srcSet={benefits2} />
                      <picture>
                        <source type="image/jpg" srcSet={benefits2} />
                        <img src={benefits2} alt="Build high-value database" />
                      </picture>
                    </picture>
                  </li>
                  <li className="screen-3">
                    <picture>
                      <source type="image/jpg" srcSet={benefits3} />
                      <picture>
                        <source type="image/jpg" srcSet={benefits3} />
                        <img src={benefits3} alt="Link pictures to products" />
                      </picture>
                    </picture>
                  </li>
                  <li className="screen-4">
                    <picture>
                      <source type="image/jpg" srcSet={benefits4} />
                      <picture>
                        <source type="image/jpg" srcSet={benefits4} />
                        <img src={benefits4} alt="Pinpoint location &amp; hours" />
                      </picture>
                    </picture>
                  </li>
                  <li className="screen-5">
                    <picture>
                      <source type="image/jpg" srcSet={benefits5} />
                      <picture>
                        <source type="image/jpg" srcSet={benefits5} />
                        <img src={benefits5} alt="Increase customer interaction" />
                      </picture>
                    </picture>
                  </li>
                  <li className="screen-6">
                    <picture>
                      <source type="image/jpg" srcSet={benefits6} />
                      <picture>
                        <source type="image/jpg" srcSet={benefits6} />
                        <img src={benefits6} alt="Boost green credentials" />
                      </picture>
                    </picture>
                  </li>
                </ul>
              </div>
            </div>
            <a href="#more" className="more">Learn more</a>
            <span className="background" />
          </div>
          <div id="more" className="center">
            <div className="block block-2 text-right">
              <div className="video">
                <a href={embeddedResellerNutshellVideoUrl}>
                  <span>Go Paperless in a nutshell</span>
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="300px" height="300px" viewBox="0 0 300 300" enableBackground="new 0 0 300 300" xmlSpace="preserve" className="svg replaced-svg">
                    <path fill="#ffffff" d="M121.6,213.9l85.2-63.9l-85.2-63.9V213.9z M150,8C71.616,8,8,71.616,8,150s63.616,142,142,142  s142-63.615,142-142C292,71.616,228.385,8,150,8z M150,263.6c-62.623,0-113.6-50.978-113.6-113.6C36.4,87.377,87.377,36.4,150,36.4  c62.621,0,113.6,50.978,113.6,113.6S212.622,263.6,150,263.6z" />
                  </svg>
                </a>
              </div>
              <div className="text">
                <h2>Revolutionise the way you interact</h2>
                <p>Traditional business cards are outdated, unsustainable and ineffective. We've used the latest technology to create a GDPR-compliant digital alternative that doubles as a marketing platform for your brand – for businesses spanning sectors from entertainment to manufacturing and hospitality to charities.</p>
                <p>With <span className="nowrap">Go Paperless</span>, you'll engage and impress your target audience – showcasing your brand as a market leader while growing your database organically, strengthening customer relations, driving traffic to your business and reducing your carbon footprint.</p>
                <p>Simple to create, update and share by text or email, recipients save your card to their mobile phone – putting your brand logo directly onto their home screen. From there, they can connect and interact with you using the channel of their choice.</p>
                {action}
              </div>
            </div>

            <div className="block block-3 text-left">
              <div className="phones">
                <div className="register">
                  <picture>
                    <source type="image/jpg" srcSet={thirdoff} />
                    <picture>
                      <source type="image/jpg" srcSet={thirdoff} />
                      <img src={thirdoff} alt="About the offer" />
                    </picture>
                  </picture>
                </div>
              </div>
              <div className="text">
                <h2>Don't miss our introductory 1/3 discount – Get your card for just {currency}99.97</h2>
                <p>Normally retailing at {currency}149.97 {vat}, <span className="nowrap">Go Paperless</span> digital business cards offer fantastic value – and deliver savings on everything from print runs to storage and transport costs. Unlike with traditional cards, they're free to update whenever details change – or reassign when team members move on.</p>
                <p>And for a limited time, we're offering a huge one third discount per card – instead of {currency}149.97 {vat} per year, pay <strong>just {currency}99.97</strong> {vat} for your card.</p>
                <p>Either way, designing your very own branded digital business card is totally free – we'll never take payment until you're completely happy with every aspect of your card. To start creating yours today, just click on the button below. And once you're ready to buy, your 1/3 discount will be automatically applied to your cart.</p>
                {action}
              </div>
            </div>

            <div className="block block-4 text-right">
              <div className="phones">
                <div className="register">
                  <picture>
                    <source type="image/jpg" srcSet={worldlandtrust} />
                    <picture>
                      <source type="image/jpg" srcSet={worldlandtrust} />
                      <img src={worldlandtrust} alt="About the offer" />
                    </picture>
                  </picture>
                </div>
              </div>
              <div className="text">
                <h2>For every card you buy, we'll plant a tree</h2>
                <p>The climate crisis is engulfing the planet – we're passionate about saving it. Making a greener planet is the driving force behind <span className="nowrap">Go Paperless</span>. That's why we're delighted to partner with World Land Trust, who perform a critical role in reversing deforestation in some of the world's most threatened areas.</p>
                <p>And when you buy a <span className="nowrap">Go Paperless</span> card, you're helping too – because for every card you buy, we'll plant a tree in association with World Land Trust.</p>
                {action}
              </div>
            </div>

            <div className="block block-5 align-center text-left">
              <div className="screengrab">
                <div className="register">
                  <picture>
                    <source type="image/jpg" srcSet={janechurchyardscreengrab5} />
                    <picture>
                      <source type="image/jpg" srcSet={janechurchyardscreengrab5} />
                      <img src={janechurchyardscreengrab5} alt="About the offer" />
                    </picture>
                  </picture>
                </div>
              </div>
              <div className="text">
                <h2>Drive traffic, track interaction</h2>
                <p>Would you like to promote your entire digital footprint from one central location, updating your card for free whenever you have something new to share? The market-leading functionality of the <span className="nowrap">Go Paperless</span> card gives you the power to drive traffic to your website, social channels, latest offers, news and more – while the <span className="nowrap">Go Paperless</span> dashboard tracks contacts, card shares, preferred channels of communication and even the date and time of a contact’s last interaction with your card.</p>
                {action}
              </div>
            </div>

            <div className="block block-6 text-right">
              <div className="video">
                <a href="https://www.youtube.com/embed/Q3dOD44aIQc?autoplay=1&loop=1&playlist=Q3dOD44aIQc">
                  <span>Showcasing Go Paperless dashboard features</span>
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="300px" height="300px" viewBox="0 0 300 300" enableBackground="new 0 0 300 300" xmlSpace="preserve" className="svg replaced-svg">
                    <path fill="#ffffff" d="M121.6,213.9l85.2-63.9l-85.2-63.9V213.9z M150,8C71.616,8,8,71.616,8,150s63.616,142,142,142  s142-63.615,142-142C292,71.616,228.385,8,150,8z M150,263.6c-62.623,0-113.6-50.978-113.6-113.6C36.4,87.377,87.377,36.4,150,36.4  c62.621,0,113.6,50.978,113.6,113.6S212.622,263.6,150,263.6z" />
                  </svg>
                </a>
              </div>
              <div className="text">
                <h2>Target engaged leads effectively</h2>
                <p>We're the world's only provider that tracks re-shares, which means every time one of your contacts forwards your card, the <span className="nowrap">Go Paperless</span> dashboard gives you the recipient's details.</p>
                <p>As a result, you'll grow your network organically, filling your database with contacts who have a genuine interest in your business. From there, you have the power to target engaged leads and convert them to customers.</p>
                {action}
              </div>
            </div>

            <div className="block block-7 text-left">
              <div className="video">
                <a href={embeddedReplicatingBrandVideoUrl}>
                  <span>How to recreate your brand on Go Paperless</span>
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="300px" height="300px" viewBox="0 0 300 300" enableBackground="new 0 0 300 300" xmlSpace="preserve" className="svg replaced-svg">
                    <path fill="#ffffff" d="M121.6,213.9l85.2-63.9l-85.2-63.9V213.9z M150,8C71.616,8,8,71.616,8,150s63.616,142,142,142  s142-63.615,142-142C292,71.616,228.385,8,150,8z M150,263.6c-62.623,0-113.6-50.978-113.6-113.6C36.4,87.377,87.377,36.4,150,36.4  c62.621,0,113.6,50.978,113.6,113.6S212.622,263.6,150,263.6z" />
                  </svg>
                </a>
              </div>
              <div className="text">
                <h2>Replicate your brand identity</h2>
                <p>All digital business card providers let you add your logo – but the majority lack the technology to mirror your styling and branding. We're different. The Branding and Styling sections of our Editor deliver the flexibility to recreate your business identity, right down to subtle shifts in colour and font styles. Slick and modern, your digital business card will not only dazzle your contacts but enhance your reputation.</p>
                {action}
              </div>
            </div>

            <div className="block block-8 align-center text-right">
              <div className="screengrab">
                <div className="register">
                  <picture>
                    <source type="image/jpg" srcSet={janechurchyardscreengrab8} />
                    <picture>
                      <source type="image/jpg" srcSet={janechurchyardscreengrab8} />
                      <img src={janechurchyardscreengrab8} alt="About the offer" />
                    </picture>
                  </picture>
                </div>
              </div>
              <div className="text">
                <h2>Boost campaign success</h2>
                <p>Looking for tactics to boost the success of limited-time offers or regular promotions? Simply use the Promotion section of the Editor to add all the details of your latest campaign – including an alluring title and eye-catching image – and drive traffic to the relevant webpage or social channel.</p>
                {action}
              </div>
            </div>

            <div className="block block-9 align-center text-left">
              <div className="screengrab">
                <div className="register">
                  <picture>
                    <source type="image/jpg" srcSet={janechurchyardscreengrab9} />
                    <picture>
                      <source type="image/jpg" srcSet={janechurchyardscreengrab9} />
                      <img src={janechurchyardscreengrab9} alt="About the offer" />
                    </picture>
                  </picture>
                </div>
              </div>
              <div className="text">
                <h2>Keep contacts up-to-date</h2>
                <p>Been shortlisted for an industry award? Just appointed a new CEO? Expanding your team? Published an exciting new blog or article? Then use the News section of your digital business card to let your network know all about your latest goings on – showing your entire network just how dynamic your business is.</p>
                {action}
              </div>
            </div>

            <div className="block block-10 align-center text-right">
              <div className="screengrab">
                <div className="register">
                  <picture>
                    <source type="image/jpg" srcSet={janechurchyardscreengrab10} />
                    <picture>
                      <source type="image/jpg" srcSet={janechurchyardscreengrab10} />
                      <img src={janechurchyardscreengrab10} alt="About the offer" />
                    </picture>
                  </picture>
                </div>
              </div>
              <div className="text">
                <h2>Demand attention with a video</h2>
                <p>Corporate videos. Footage of you nailing that conference speech. Product explainers. Gifs. The possibilities are endless when it comes to videos. That’s why we decided that directing your network to a video of your choice was a must-have feature of <span className="nowrap">Go Paperless</span>. And, of course, you can update your video for free whenever you like to keep your contacts engaged.</p>
                {action}
              </div>
            </div>

            <div className="block block-11 align-center text-left">
              <div className="screengrab">
                <div className="register">
                  <picture>
                    <source type="image/jpg" srcSet={janechurchyardscreengrab11} />
                    <picture>
                      <source type="image/jpg" srcSet={janechurchyardscreengrab11} />
                      <img src={janechurchyardscreengrab11} alt="About the offer" />
                    </picture>
                  </picture>
                </div>
              </div>
              <div className="text">
                <h2>Hyperlink images to product and service pages</h2>
                <p>There’s nothing like a photograph to show off your latest work or promote your latest products or services. After all, a picture tells a thousand stories. With our interactive Gallery, you can add as many images as you like, hyperlinking them directly to the relevant page on your website – complementing brochures or product catalogues or even removing the need to publish them altogether.</p>
                {action}
              </div>
            </div>

            <div className="block block-12 align-center text-right">
              <div className="screengrab">
                <div className="register">
                  <picture>
                    <source type="image/jpg" srcSet={janechurchyardscreengrab12} />
                    <picture>
                      <source type="image/jpg" srcSet={janechurchyardscreengrab12} />
                      <img src={janechurchyardscreengrab12} alt="About the offer" />
                    </picture>
                  </picture>
                </div>
              </div>
              <div className="text">
                <h2>Add your location and trading hours</h2>
                <p>With <span className="nowrap">Go Paperless</span>, you can add your address and opening hours to your card to let your network know where you are and when they can reach you. We’re the only provider that includes your location on Google Maps.</p>
                {action}
              </div>
            </div>

            <hr className='hr' />

            <div className="grid block-13">
              <h2>Why <span className="nowrap">Go Paperless</span>?</h2>
              <div className="intro">
                <p className="bold">Have you ever wondered if your business card offers value for money or a return on your investment?</p>
                <p>200 billion business cards are printed every year* – yet 88% are discarded within a week. Not only are traditional business cards unsustainable - they don't empower you to keep pace with the 21st Century digital revolution or the dramatic surge of contactless marketing spearheaded by events of 2020.</p>
                <p>We've used the latest technology to create a digital business card that doubles as a marketing platform for your brand.</p>
                <p className="bold">Put simply, <span className="nowrap">Go Paperless</span> delivers value for money.</p>
              </div>
              <ul>
                <li>
                  <picture>
                    <source type="image/png" srcSet={why1} />
                    <picture>
                      <source type="image/png" srcSet={why1} />
                      <img src={why1} alt="No more forgetting your cards" />
                    </picture>
                  </picture>
                  <h3>Like to banish that sinking feeling when you realise you've forgotten your business cards?</h3>
                  <p>Share your branded digital business card by text or email – wherever you are, at any time</p>
                </li>
                <li>
                  <picture>
                    <source type="image/png" srcSet={why2} />
                    <picture>
                      <source type="image/png" srcSet={why2} />
                      <img src={why2} alt="No more print runs" />
                    </picture>
                  </picture>
                  <h3>Fed up with endless print runs – whenever a team member moves on, changes offices or gets promoted?</h3>
                  <p>Update your cards for free, instantly, whenever you like – and reassign cards when team members move on</p>
                </li>
                <li>
                  <picture>
                    <source type="image/png" srcSet={why3} />
                    <picture>
                      <source type="image/png" srcSet={why3} />
                      <img src={why3} alt="Cost-effective networking solution" />
                    </picture>
                  </picture>
                  <h3>Looking for cost-effective strategies to keep your network up-to-date with all your latest news?</h3>
                  <p>Your digital business card is a contactless marketing platform – with interactive sections to drive traffic</p>
                </li>
                <li>
                  <picture>
                    <source type="image/png" srcSet={why4} />
                    <picture>
                      <source type="image/png" srcSet={why4} />
                      <img src={why4} alt="Track re-shares of your card" />
                    </picture>
                  </picture>
                  <h3>Want to monitor the impact of your business card – and find out which clients actively share your details?</h3>
                  <p>We're the only provider to track re-shares – telling you which clients are sharing your card and who with</p>
                </li>
                <li>
                  <picture>
                    <source type="image/png" srcSet={why5} />
                    <picture>
                      <source type="image/png" srcSet={why5} />
                      <img src={why5} alt="Reach your audience" />
                    </picture>
                  </picture>
                  <h3>Tired of forking out on social marketing that doesn't reach every member of your network?</h3>
                  <p>Updates to your card reach your entire network & deliver an additional SEO avenue that boosts your Google ranking</p>
                </li>
                <li>
                  <picture>
                    <source type="image/png" srcSet={why6} />
                    <picture>
                      <source type="image/png" srcSet={why6} />
                      <img src={why6} alt="GDPR-compliant" />
                    </picture>
                  </picture>
                  <h3>Want to free yourself from the headache of worrying about breaching GDPR?</h3>
                  <p>Enjoy integrated GDPR compliance – plus recipients elect to be contacted by phone or email</p>
                </li>
                <li>
                  <picture>
                    <source type="image/png" srcSet={why7} />
                    <picture>
                      <source type="image/png" srcSet={why7} />
                      <img src={why7} alt="Green credentials" />
                    </picture>
                  </picture>
                  <h3>Looking for ways to boost your green potentials and supercharge your CSR policy?</h3>
                  <p>For every card you buy, World Land Trust plants a tree</p>
                </li>
              </ul>
              <p className="source">* Source : Forest Facts, American Forests</p>
            </div>

            <hr className='hr' />

            <div className="testimonial block-14">
              <h2>What you say</h2>
              <blockquote>
                <p>As a creative with an eye for detail, I’m always on the lookout for marketing tools that meet my high standards when it comes to visuals. Not only has the <span className="nowrap">Go Paperless</span> platform has enabled me to replicate my branding, styling and colour palette, conveying exactly the right visual message to all my contacts. The Gallery section showcases images of work, hyperlinking them back to the relevant section of my website Thanks to my digital business card, I’ve grown my network and increased my workload. Exactly the result I was hoping for. </p>
                <span className="rating"></span>
                <footer>
                  <picture>
                    <source type="image/jpg" srcSet={janechurchyard} />
                    <picture>
                      <source type="image/jpg" srcSet={janechurchyard} />
                      <img src={janechurchyard} alt="Bodil McCaskill" />
                    </picture>
                  </picture>
                  <p><span>Jane Churchyard</span> Graphic Designer</p>
                </footer>
              </blockquote>
            </div>

            <hr className='hr' />

            <div className="block block-15 text-left">
              <div className="banner">
                <div className="banner-shadow register">
                  <picture>
                    <source type="image/png" srcSet={thirdoff} />
                    <picture>
                      <source type="image/png" srcSet={thirdoff} />
                      <img src={thirdoff} alt="One third off every digital business card you buy plus bigger discounts for 50+ cards" />
                    </picture>
                  </picture>
                </div>
              </div>
              <div className="text">
                <h2>Don't miss your chance to grab your card for just {currency}99.97</h2>
                <p><span className="nowrap">Go Paperless</span> digital business cards cost {currency}149.97 {vat} per year – but we're running an exclusive introductory offer, giving you the chance to claim yours with 1/3 off. Get yours today and pay <strong>just {currency}99.97</strong> {vat} per year.</p>
                <p>Don't forget, there's no more hanging around for time-consuming print runs – this price includes unlimited free updates as well as reassignments when team members move on, helping you save time and money as well as the planet.</p>
                <p>And remember, it's totally free to use the <span className="nowrap">Go Paperless</span> platform to create your very own branded digital business card – we'll never take payment until you're totally happy with every aspect of it. Just click the link below to get started and we'll automatically your 1/3 discount once you're ready to buy.</p>
                {action}
              </div>
            </div>
          </div>
          <hr className='hr' />
          <div className="world-land-trust block-16">
            <div className="intro">
              <img src={worldlandtrustsvg} alt="World Land Trust" className="logo" />
              <h2>Proud supporters of World Land Trust</h2>
              <p>We're incredibly proud of our partnership with the inspirational World Land Trust. For every card you buy, they'll plant a tree in an area that's been lost to deforestation. Which means that – when you choose <span className="nowrap">Go Paperless</span> – you're helping to reforest vital habitats with native species.</p>
            </div>
            <blockquote>
              <p>The money that is given to the World Land Trust, in my estimation, has more effect on the wild world than almost anything I can think of.</p>
              <footer>
                <picture>
                  <source type="image/jpg" srcSet={attenborough} />
                  <picture>
                    <source type="image/jpg" srcSet={attenborough} />
                    <img src={attenborough} alt="Sir David Attenborough" />
                  </picture>
                </picture>
                <p><span>Sir David Attenborough</span> Patron</p>
              </footer>
            </blockquote>
          </div>
          <div className="lightbox"><div className="placeholder"><div className="modal"></div></div><button className="close">Close</button></div>
        </div>
        <div className={classes.footerSection} style={{ width: '100%' }}>
          <QRCodeSection classes={classes} slug={card.urlSlug as string} />
          {isPreview ? <div className="mobilePreviewScrollPadding" /> : <Suspense fallback={null}><Footer /></Suspense>}
        </div>
      </LandingPage>
    </>
  );
};

export default connect(
  mapState,
  {
    getCurrency,
  }
)(ResellerCard);
