import { Box, Drawer, IconButton, List, ListItem } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import clsx from 'clsx';
import { useStyles } from 'modules/core/components/Header/styles';
import React from 'react';
import { When } from 'react-if';
import { FOOTER_ITEMS } from 'routes/routes';
import SignLinks from '../signLinks';
import { useHistory } from 'react-router';
import { UserDrawerName } from './UserDrawerName';
import { useMenuData } from 'modules/core/hooks/useMenuData';
import { E_ACCOUNT_TYPE } from 'modules/auth/constants/enums';

interface IMobileBarProps {
  open: boolean;
  isAuthenticated?: boolean;
  profileType?: E_ACCOUNT_TYPE;
  handleDrawerClose: () => void;
  logout: () => void;
}

export const Bar: React.FC<IMobileBarProps> = (props) => {
  const { open, isAuthenticated, handleDrawerClose, profileType, logout } = props;
  const { menuItems } = useMenuData();
  const classes = useStyles();
  const history = useHistory();
  const navigateTo = (path: string) => history.push(path);

  return (
    <nav className={classes.drawer}>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        className={classes.drawer}
        anchor="right"
        open={open}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.wrapper}>
          <When condition={open}>
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose}>
                <Close />
              </IconButton>
            </div>
          </When>
          <List component="nav">
            { !!isAuthenticated && <UserDrawerName /> }

            {menuItems.map((item) => (
              <ListItem
                key={item.path}
                button={true}
                divider={true}
                className={classes.menuItem}
                onClick={() => navigateTo(item.path)}
              >
                <Box mr="10px">{item.icon}</Box>
                {item.title}
              </ListItem>
            ))}
            <When condition={!!isAuthenticated}>
              <ListItem className={classes.menuItem} button={true} divider={true} onClick={logout}>
                <Box mr="10px" />
                Log out
              </ListItem>
            </When>
          </List>
          <List>
            {FOOTER_ITEMS.map((item) => (
            // {FOOTER_ITEMS.map(({ title, path }) => (
              (item.resellerOnly && profileType !== E_ACCOUNT_TYPE.Reseller
                ? null
                : (
                  <ListItem
                    key={item.path}
                    button={true}
                    className={clsx(classes.menuItem, '_small')}
                    onClick={() => navigateTo(item.path)}
                  >
                    <Box mr="10px" />
                    {item.title}
                  </ListItem>
                )
              )
            ))}
          </List>

          { !isAuthenticated && <SignLinks theme={'_dark'} /> }
        </div>
      </Drawer>
    </nav>
  );
};
