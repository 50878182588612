import { createStore, combineReducers, applyMiddleware } from 'redux';
import root from 'store';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

const DEBUG = process.env.NODE_ENV !== 'production';
const middleware = applyMiddleware(thunk);

const store = createStore(combineReducers(root), DEBUG ? composeWithDevTools(middleware) : middleware);

export default store;
