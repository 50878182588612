import React, { useState } from 'react';
import clsx from 'clsx';
import { If, Then } from 'react-if';
import { useStyles } from './styles';
import { Link } from 'modules/core/components/Link';
import CssGreenButton from 'modules/core/components/buttons/roundGreenButton';
import { PRIVACY_ROUTE } from 'routes/routes';
import Cookies from 'js-cookie';

interface ICookieConsent {
  exClass?: string;
}

const CookieConsent: React.FC<ICookieConsent> = (props) => {
  const { exClass } = props;
  const classes = useStyles();
  const cookieKey = 'remember-cookie-snackbar';

  const [ visible, setVisible ] = useState(Cookies.get(cookieKey) === undefined);

  const handleCookieClick = () => {
    setVisible(false);
    Cookies.set(cookieKey, "", {
      expires: 365
    });
  }

  return (<If condition={visible}>
    <Then>
      <div className={clsx(classes.cookieConsentWrap, exClass)}>
        <p>
          We use cookies to give you the best possible experience on our website. 
          
          Please refer to our
          
          <Link to={PRIVACY_ROUTE.path} className={classes.greenLink}>
            {PRIVACY_ROUTE.subtitle}
          </Link>

          for guidance on managing cookies and for additional details about the cookies we use.
        </p>

        <CssGreenButton
          className={classes.button}
          onClick={() => handleCookieClick()}
        >
          ACCEPT ALL COOKIES
        </CssGreenButton>
      </div>
    </Then>
  </If>);
};

export default CookieConsent;
