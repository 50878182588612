import { INewsSection } from 'modules/editor/graphql/gql';

export class NewsSection implements INewsSection {
  public linkText?: string;
  public linkUrl?: string;
  public linkUrlShow?: boolean;
  public newsImageUrl?: string;
  public title?: string;
  public showSectionHeader?: boolean;

  constructor(section?: INewsSection) {
    if (section) {
      this.linkText = section.linkText;
      this.linkUrl = section.linkUrl;
      this.linkUrlShow = section.linkUrlShow;
      this.newsImageUrl = section.newsImageUrl;
      this.title = section.title;
      this.showSectionHeader = section.showSectionHeader;
    }
  }
}
