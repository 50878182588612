import React, { useState } from 'react';
import { AppBar, Hidden, Link, Toolbar, Typography, Box } from '@material-ui/core';
import clsx from 'clsx';
import { CardMenu } from 'modules/core/components/Header/MobileMenu';
import { Bar } from 'modules/core/components/Header/Bar';
import RoutedLink from 'modules/core/components/RoutedLink';
import { Else, If, Then } from 'react-if';
import { useStyles } from './styles';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { useMenuData } from 'modules/core/hooks/useMenuData';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { actions as cardActions } from 'modules/editor/reducers/cardReducer';
import { actions as stepActions } from 'modules/editor/reducers/stepsReducer';
import { actions } from 'modules/auth/reducers/authReducer';
import ProfileMenu from 'modules/core/components/Header/ProfileMenu';
import SignLinks from './signLinks';
import { useUserName } from 'modules/core/hooks/useUserName';
import { HOME_ROUTE } from 'routes/routes';
import { ReactComponent as LogoSvg } from 'assets/svg/logo.svg';

interface IMenu {
  activePath: string;
  isPublic: boolean;
  title?: string;
  exClass?: string;
}

export const HeaderMenu: React.FC<IMenu> = (props) => {
  const { activePath, isPublic, title: pageTitle, exClass } = props;
  const { pending, isAuthenticated, profile } = useTypedSelector((state) => state.auth);
  const userName = useUserName();
  const { menuItems } = useMenuData();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const { signout } = actions;

  const logout = () => {
    if (signout) {
      dispatch(signout());
      dispatch(cardActions.clearCardState());
      dispatch(stepActions.clear);
      history.push('/');
    }
  };

  return (
    <Box className={clsx(classes.headerRow, exClass)} id='HeaderMenu_headerRow'>
      <AppBar position="fixed" className={classes.appBar}>
        <Hidden mdDown={true} implementation="css">
          <div className={classes.links} id="head">
            <div className={clsx(classes.headerLogo)}>
              <Link
                id={`menu_logo_link`}
                component={RoutedLink}
                to={HOME_ROUTE.path}
                className={clsx('')}
              >
                <div className={"_logoContainer"}>
                  <LogoSvg id="gopaperless_logo" className="_logo" />
                  <Typography variant="h3" className="_name _green">go</Typography>
                  <Typography variant="h3" className="_name">paperless</Typography>
                </div>
              </Link>
            </div>
            <Toolbar className={clsx(classes.toolbar, classes.headerBarTextContainer)}>
              {menuItems.map((item) => (
                <Link
                  id={`menu_link_to_${item.path}`}
                  key={item.path}
                  component={RoutedLink}
                  to={item.path}
                  className={clsx(classes.link, activePath === item.path ? '_active' : '')}
                  target={item.target}
                >
                  {item.title}
                </Link>
              ))}
            </Toolbar>
            <If condition={isPublic}>
              <div className={classes.headerBarTextContainer}>
                <If condition={isAuthenticated}>
                  <Then>
                    <ProfileMenu
                      userName={userName}
                      isAuthenticated={isAuthenticated}
                      signout={logout}
                      pending={pending}
                      isPublic={isPublic}
                    />
                  </Then>
                  <Else>
                    <SignLinks />
                  </Else>
                </If>
              </div>
            </If>
          </div>
        </Hidden>
        <CardMenu open={open} pageTitle={pageTitle} handleDrawerOpen={() => setOpen(true)} isPublic={isPublic} />
      </AppBar>
      <Bar handleDrawerClose={() => setOpen(false)} open={open} isAuthenticated={isAuthenticated} profileType={profile.type} logout={logout} />
    </Box>
  );
};
