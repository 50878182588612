import { SvgIcon } from '@material-ui/core';
import { ReactComponent as OfferIcon } from 'assets/svg/offer.svg';
import { constructLink } from 'modules/core/utils';
import CardWrapper from 'modules/editor/components/CardWrapper';
import { LinkImage } from 'modules/editor/components/LinkImage';
import { PreviewLink } from 'modules/editor/components/PreviewLink';
import { IPromotionSection } from 'modules/editor/graphql/gql';
import React, { CSSProperties, useMemo, RefObject } from 'react';
import { When } from 'react-if';
import { E_CARD_STEPS } from 'modules/editor/constants/enums';
import { useScroll } from 'modules/editor/components/CardSectionsView/hooks/useScroll';

interface IPromotionProps {
  section: IPromotionSection;
  display: boolean;
  classes: Record<string, string>;
  bodyStyle: CSSProperties;
  titleStyle: CSSProperties;
  iconStyle: CSSProperties;
  linkStyle: CSSProperties;
  isPreview?: boolean;
  wrapperRef: RefObject<HTMLDivElement>;
}
/**
 * Promotion block view for card. Display image and link for promotion.
 * @param props IPromotionProps
 */
export const PromotionSection: React.FC<IPromotionProps> = ({
  classes,
  section,
  display,
  bodyStyle,
  linkStyle,
  iconStyle,
  titleStyle,
  isPreview,
  wrapperRef,
}) => {
  const ref = useScroll(E_CARD_STEPS.PROMOTION, wrapperRef, display);
  const link = useMemo(() => (section.linkUrl ? constructLink(section.linkUrl as string) : undefined), [
    section.linkUrl,
  ]);
  const firstLine = section.showSectionHeader ? <span>Latest Offer</span> : undefined;

  return (
    <When condition={display}>
      <CardWrapper
        {...section}
        ref={ref}
        bodyStyle={bodyStyle}
        titleStyle={titleStyle}
        firstLine={firstLine}
        icon={<SvgIcon children={<OfferIcon />} htmlColor={'#ffffff'} className="section-icon" style={iconStyle} />}
      >
        <LinkImage
          disabled={isPreview}
          imageAlt="Promotion"
          image={section.promotionImageUrl}
          url={link}
          className={classes.image}
        />
        <PreviewLink
          style={linkStyle}
          url={link}
          className={classes.findMore}
          disable={isPreview}
          display={section.linkText && link ? true : false}
        >
          {section.linkText}
        </PreviewLink>
      </CardWrapper>
    </When>
  );
};
