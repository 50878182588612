import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStylesPage = makeStyles((theme: Theme) =>
  createStyles({
    page: {
      marginBottom: 100,
      paddingTop: 145,
      [theme.breakpoints.down('md')]: {
        paddingTop: 176,
      },
      '&._padding-md': {
        paddingTop: 88,
      },

      '&._padding-sm': {
        paddingTop: 63,
        [theme.breakpoints.down('md')]: {
          paddingTop: 0,
          marginBottom: 40,
        },
      },
      '&._padding-sm-mobile': {
        paddingTop: 140,
        [theme.breakpoints.down('md')]: {
          paddingTop: 37,
        },
      },
      '&._padding-sm-mobile-extended-body': {
        paddingTop: 37,
      },
      '&._margin_bottom_0': {
        marginBottom: 0,
      },
    },
    pageTitleSmall: {
      color: 'white',
      fontFamily: 'F37 Ginger',
      fontWeight: 400,
      fontSize: 20,
      opacity: 0.7,
      lineHeight: theme.typography.pxToRem(29),
      '&:not(:last-child)': {
        marginBottom: theme.spacing(4),
      },
      '& span': {
        fontSize: 60,
        lineHeight: '70px',
        color: 'white',
      },
      [theme.breakpoints.down('sm')]: {
        opacity: 1,
      },
      '&._impact': {
        display: 'table',
        position: 'relative',
        marginTop: 75,
        verticalAlign: 'middle',
        opacity: 1,
        color: 'rgba(255,255,255, .7)',
        '& sup': {
          alignItems: 'flex-end',
          position: 'absolute',
          top: '-10px',
          left: 0,
          fontFamily: 'Noto Sans Javanese',
          fontSize: 120,
          color: 'white',
          opacity: 1,
          [theme.breakpoints.down('sm')]: {
            fontSize: 60,
          },
        },
        '& span': {
          display: 'table',
          verticalAlign: 'bottom',
          width: 'fit-content',
          height: 'fit-content',
          alignItems: 'flex-end',
          // '&:first-child': {
          fontFamily: 'Noto Sans Javanese',
          fontSize: 60,
          color: 'white',
          opacity: 1,
        },
        '& sub': {
          position: 'absolute',
          bottom: '-70px',
          left: '40%',
          fontFamily: 'Noto Sans Javanese',
          fontSize: 120,
          color: 'white',
          opacity: 1,
          [theme.breakpoints.down('sm')]: {
            left: 'unset',
            fontSize: 60,
            bottom: '-50px',
            right: 5,
          },
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: 16,
          marginTop: 88,
          lineHeight: theme.typography.pxToRem(23),
        },
      },
    },
    pageTitle: {
      fontSize: 44,
      lineHeight: 1.25,
      color: 'white',
      marginBottom: theme.spacing(4),
      '&._sm': {
        [theme.breakpoints.down('md')]: {
          fontSize: 28,
          lineHeight: '40px',
          letterSpacing: 0.47,
        },
      },
      '&:last-child': {
        marginBottom: 0,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 30,
        lineHeight: '40px',
      },
    },
    pageSubtitle: {
      fontSize: 24,
      letterSpacing: 0.1,
      lineHeight: 1.1,
      color: 'white',
      marginBottom: theme.spacing(8),
      fontWeight: 700,

      '&._sm': {
        [theme.breakpoints.down('md')]: {
        },
      },
    },
    pageDescription: {
      fontSize: 20,
      color: 'white',
      opacity: 0.7,
      marginBottom: 52,
      paddingRight: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        fontSize: 16,
        lineHeight: '23px',
        marginBottom: 77,
      },
    },
    keyPoints: {
      margin: theme.spacing(5, 0),

      '& li': {
        fontWeight: 500,
        fontSize: 18,
        letterSpacing: 0.2,
      }
    },
    ctaRow: {
      display: 'flex',
      justifyContent: 'left',

      '& > *': {
        marginRight: theme.spacing(3),
      },
    },

    [theme.breakpoints.down('md')]: {
      ctaRow: {
        width: '100%',
        justifyContent: 'center',
        marginTop: theme.spacing(3),
      },
    },

    [theme.breakpoints.down('sm')]: {
      ctaRow: {
        width: 'auto',
        minWidth: 250,
        flexDirection: 'column-reverse',
        // marginTop: theme.spacing(-2),

        '& > *': {
          margin: theme.spacing(1, 'auto'),
        },
      },
    },

    [theme.breakpoints.down('xs')]: {
    },
  })
);

export default useStylesPage;
