import React, { useCallback, useState } from 'react';
import copy from 'clipboard-copy';
import { AttachFile } from '@material-ui/icons';
import { clipboardStyles as useStyles } from './styles';
import clsx from 'clsx';

export function useClipboard() {
  const TIME = 2000;
  const [successCopy, setSuccessCopy] = useState(false);
  const [errCopy, setErrorCopy] = useState(false);

  const onSuccessCopy = useCallback(() => {
    setSuccessCopy(true);
    setTimeout(() => setSuccessCopy(false), TIME);
  }, [setSuccessCopy]);

  const onErrorCopy = useCallback(() => {
    setErrorCopy(true);
    setTimeout(() => setErrorCopy(false), TIME);
  }, [setErrorCopy]);

  return {
    success: successCopy,
    error: errCopy,
    onSuccessCopy,
    onErrorCopy,
  };
}

interface IClipboardProps {
  id?: string;
  url: string;
  className?: string;
  onSuccess?: () => void;
  onError?: () => void;
  hideIcon?: boolean;
}

export const Clipboard: React.FC<IClipboardProps> = ({ url, children, className, onError, onSuccess, id, hideIcon }) => {
  const classes = useStyles();
  const copyToClipboard = useCallback(async () => {
    try {
      await copy(url);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (onError) {
        onError();
      }
    }
  }, [url, onError, onSuccess]);

  return (
    <div id={id} className={clsx(classes.clipboard, className)} onClick={copyToClipboard}>
      { !hideIcon && <AttachFile className={classes.copy} /> }
      {children}
    </div>
  );
};
