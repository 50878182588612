import React from 'react';
import { injectStripe } from 'react-stripe-elements';
import { PayData } from './index'

interface IProps {
  disabled?: boolean;
  ctaText?: string;
  next: (pm: string) => void;
  err: (useRedirect: boolean, error?: any) => void;
}

export const PayDataStripeInjected = injectStripe<IProps>((props) => {
  const { next, err, stripe, disabled, ctaText } = props;

  return (
    <>
      <PayData disabled={disabled} next={next} err={err} ctaText={ctaText} stripe={stripe} />
    </>
  );
});
