import React, { forwardRef } from 'react';
import DialogWrapper, { IDialogRef } from 'modules/core/containers/DialogWrapper';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from '../../../RoundButton/styles';

interface IProps {
  icon: JSX.Element;
  text: string;
  id: string;
}

export const Default = forwardRef<IDialogRef, IProps>((props, ref) => {
  const { icon, text, id } = props;
  const classes = useStyles();

  return (
    <DialogWrapper dialogId={id} ref={ref}>
      <div className={classes.card}>
        <Box className={clsx(classes.iconWrapper, classes.biggerIcon)}>{icon}</Box>
        <span className={classes.biggerText}>{text}</span>
      </div>
    </DialogWrapper>
  );
});
