import React, { useCallback, useMemo } from 'react';
import { TextField, makeStyles, useTheme, fade, InputBaseComponentProps } from '@material-ui/core';
import { ReactStripeElements, CardElement } from 'react-stripe-elements';
import { IPayDataState } from '../';

export interface IStripeTextField {
  InputLabelProps?: any;
  label?: string;
  required?: boolean;
  labelErrorMessage: string;
  fullWidth?: boolean;
  disabled?: boolean;
  error: boolean;
  setValidation: React.Dispatch<React.SetStateAction<IPayDataState>>;
}

export const StripeInput: React.FC<IStripeTextField> = (props) => {
  const { InputLabelProps, fullWidth = true, label = '', labelErrorMessage, error, disabled, setValidation } = props;
  const classes = useStyles();
  const theme = useTheme();

  const stripeStyle = useMemo(
    () => ({
      base: {
        color: theme.palette.text.primary,
        fontSize: `1rem`,
        '::placeholder': {
          color: fade(theme.palette.text.primary, 0.7),
        },
      },
      invalid: {
        color: '#eb1c26',
      },
    }),
    [theme]
  );

  const onChange = useCallback(
    (event: ReactStripeElements.ElementChangeResponse): void => {
      const { complete, error: newError, empty } = event;
      const cardError = newError && newError.message ? newError.message : '';
      setValidation((prevState) => ({ ...prevState, cardError, empty, complete }));
    },
    [setValidation]
  );

  const inputComponent = useCallback(
    (inputProps: InputBaseComponentProps) => (
      <CardElement style={stripeStyle} className={inputProps.className} onChange={onChange} />
    ),
    [stripeStyle, onChange]
  );

  return (
    <TextField
      fullWidth={fullWidth}
      className={classes.textField}
      label={label}
      helperText={labelErrorMessage}
      error={error}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true,
      }}
      InputProps={{
        inputComponent,
      }}
      disabled={disabled}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  textField: {
    fontFamily: 'F37 Ginger Light',
    '& .Mui-error:after': {
      borderBottomColor: '#f44336 !important',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: '2px solid rgba(255,255,255,0.4)',
    },
    '& .MuiFilledInput-underline:before ': {
      borderBottom: '1px solid rgba(255,255,255,0.4)',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#013D1F',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid #749D83',
    },
    '& .MuiInput-underline:after': {
      borderBottom: '2px solid #749D83',
    },
    '& .MuiInputBase-input': {
      fontSize: theme.typography.pxToRem(19),
      padding: '28px 10px 6px',
      background: 'rgba(241,244,245,0.5)',
    },
    '& .MuiInputAdornment-positionEnd': {
      marginRight: '16px',
    },
    '& .MuiInputLabel-shrink': {
      '&.Mui-error': {
        color: '#f44336',
      },
      transform: 'translate(10px, 26px) scale(0.75)',
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: theme.typography.pxToRem(24),
      fontFamily: 'F37 Ginger Light',
      color: '#013D1F',
      zIndex: 1,
    },
    '& .MuiFormHelperText-root': {
      margin: '8px 14px 0',
    },
  },
}));
