import gql from 'graphql-tag';
import { IAssignedUsers, IInviteUser, E_USER_STATUS } from 'modules/users/interfaces/assigned-users';
import { actions } from 'modules/users/reducers/assigned-users';
import { Dispatch } from 'redux';
import { ID } from 'modules/core/types';
import { apoloClient } from 'modules/core/constants/client';
import { actions as authActions } from 'modules/auth/reducers/authReducer';
import { Invitation } from 'modules/auth/models/invitation';
import { ResellerForm } from 'modules/auth/models/signup';

export function getAssignedUsers() {
  return async (dispatch: Dispatch) => {
    dispatch(actions.setPending(true));
    try {
      const {
        data: { manageUsers },
      } = await apoloClient.query<{ manageUsers: IAssignedUsers }>({
        query: gql`
          query {
            manageUsers {
              assignedUsers {
                email
                fullName
                mobilePhone
                slot {
                  id
                  status
                  subscriptionId
                  accountId
                }
              }
              occupiedSlots
              totalSlots
              unoccupiedSlots
            }
          }
        `,
      });
      dispatch(actions.setUsers(manageUsers));
    } catch (e) {
      console.warn(e);
      dispatch(actions.setErrors([e.message]));
    }
    dispatch(actions.setPending(false));
  };
}

export function toggleSlotState(id: ID) {
  return async (dispatch: Dispatch) => {
    dispatch(actions.setActionPending(true));
    try {
      const { errors, data } = await apoloClient.mutate({
        variables: { id },
        mutation: gql`
          mutation ChangeSlotActivity($id: ID!) {
            changeSlotActivity(attributes: { id: $id }) {
              errors
              slot {
                status
              }
            }
          }
        `,
      });
      console.warn(data, errors);
      if (errors) {
        dispatch(actions.setErrors(errors.map((err) => err.message)));
      } else {
        dispatch(actions.setSlotStatus({ slotId: id, status: data.changeSlotActivity.slot.status }));
      }
    } catch (e) {
      console.warn(e);
      dispatch(actions.setErrors([e.message]));
    }
    dispatch(actions.setActionPending(false));
  };
}

export function removeAccount(slotId: ID) {
  return async (dispatch: Dispatch) => {
    dispatch(actions.setActionPending(true));
    try {
      const { errors, data } = await apoloClient.mutate({
        variables: { slotId },
        mutation: gql`
          mutation DeleteAccount($slotId: ID!) {
            deleteAccount(slotId: $slotId) {
              errors
              status
            }
          }
        `,
      });
      console.warn(data, errors);
      if (errors) {
        dispatch(actions.setErrors(errors.map((err) => err.message)));
      } else {
        dispatch(actions.removeAccount(slotId));
      }
    } catch (e) {
      console.warn(e);
      dispatch(actions.setErrors([e.message]));
    }
    dispatch(actions.setActionPending());
  };
}

export function inviteUser(user: IInviteUser) {
  return async (dispatch: Dispatch) => {
    let isUserInvited = false;
    dispatch(actions.setActionPending(true));
    try {
      const { errors, data } = await apoloClient.mutate({
        variables: { ...user },
        mutation: gql`
          mutation InviteUser($fullName: String!, $email: String!, $mobilePhone: String) {
            inviteUser(attributes: { fullName: $fullName, email: $email, mobilePhone: $mobilePhone }) {
              errors
              status
            }
          }
        `,
      });
      console.warn(data, errors);
      if (errors || (data && data.inviteUser.errors && data.inviteUser.errors.length)) {
        dispatch(actions.setErrors(data.inviteUser.errors));
      } else {
        dispatch(actions.inviteUser({ status: data.inviteUser.status, user }));
        isUserInvited = true;
      }
    } catch (e) {
      console.warn(e);
      dispatch(actions.setErrors([e.message]));
    }
    dispatch(actions.setActionPending());
    return isUserInvited;
  };
}

export function reassignUser(slotId: ID, invitedUser: IInviteUser) {
  return async (dispatch: Dispatch) => {
    dispatch(actions.setActionPending(true));
    try {
      const { errors, data } = await apoloClient.mutate({
        variables: { slotId, invitedUser },
        mutation: gql`
          mutation ReassignCardUser($slotId: ID!, $invitedUser: InviteUserAttributes!) {
            reassignCardUser(attributes: { slotId: $slotId, invitedUser: $invitedUser }) {
              errors
              status
            }
          }
        `,
      });
      console.warn(data, errors);
      if (errors) {
        dispatch(actions.setErrors(errors.map((err) => err.message)));
      } else {
        dispatch(actions.reassignUser({ status: E_USER_STATUS.INVITED, user: invitedUser, slotId }));
      }
    } catch (e) {
      console.warn(e);
      dispatch(actions.setErrors([e.message]));
    }
    dispatch(actions.setActionPending());
  };
}

export function acceptInvite(invitationId: ID) {
  return async (dispatch: Dispatch): Promise<boolean> => {
    let result = false;
    dispatch(actions.setActionPending(true));
    try {
      const { errors, data } = await apoloClient.mutate({
        variables: { invitationId },
        mutation: gql`
          mutation AcceptUserInvitation($invitationId: ID!) {
            acceptUserInvitation(invitationId: $invitationId) {
              errors
              status
              auth
            }
          }
        `,
      });
      if (errors) {
        dispatch(actions.setErrors(errors.map((err) => err.message)));
      } else {
        if (data && data.acceptUserInvitation && data.acceptUserInvitation.auth) {
          const token = data.acceptUserInvitation.auth ? (data.acceptUserInvitation.auth as string) : null;
          dispatch(authActions.setAuthToken(token as string));
          result = true;
        } else {
          dispatch(actions.setErrors(['The invitation has already been accepted, please log in.']));
        }
      }
    } catch (e) {
      console.warn(e);
      dispatch(actions.setErrors([e.message]));
    }
    dispatch(actions.setActionPending());
    return result;
  };
}

export function acceptResellerInvite(invitationId: ID) {
  return async (dispatch: Dispatch): Promise<Boolean> => {
    let result = false;
    dispatch(actions.setActionPending(true));
    try {
      const { errors, data } = await apoloClient.mutate({
        variables: { invitationId },
        mutation: gql`
          mutation AcceptResellerInvitation($invitationId: ID!) {
            acceptResellerInvitation(invitationId: $invitationId) {
              errors
              status
              invitation {
                id
                firstName
                lastName
                fullName
                inviteToken
                email
                mobilePhone
                status
                user {
                  id
                  email
                  mobilePhone
                  firstName
                  lastName
                }
              }
            }
          }
        `,
      });
      if (errors) {
        dispatch(actions.setErrors(errors.map((err) => err.message)));
      } else {
        if (data && data.acceptResellerInvitation && data.acceptResellerInvitation.invitation) {
          let invitation = new Invitation(data.acceptResellerInvitation.invitation);

          dispatch(authActions.setInvitation(invitation));
          // set resellerSignup.userValues wiht invitation values
          const initialUserValues = new ResellerForm(invitation.initialUserValues())
          dispatch(authActions.setInitialResellerSignupUserValues(initialUserValues));
          // debugger
          result = true;
        } else {
          dispatch(actions.setErrors(data.acceptResellerInvitation.errors));
        }
      }
    } catch (e) {
      console.warn(e);
      dispatch(actions.setErrors([e.message]));
    }
    dispatch(actions.setActionPending());
    return result;
  };
}
