import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useShareCardStyles } from '../styles';

interface IProps {
  cardUrl: string;
};

const ShareCardByEmail: React.FC<IProps> = ({ cardUrl }) => {
  const classes = useShareCardStyles();
  const fullCardUrl = `${window.location.host}/${cardUrl}`;

  return (
    <>
      <Typography id={`share_dialog_title`} align="center" className={classes.subtitle}>{fullCardUrl}</Typography>

      <p className={classes.bodyText}>Copy and paste the link above into the messaging app of your choice - remembering to include a personal message.</p>
      <p className={classes.bodyText}>Then share with your contact.</p>
    </>
  );
};

export default ShareCardByEmail;
