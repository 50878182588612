import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  link: {
    color: '#fff',
  },
  imgDescr: {
    position: 'absolute',
    color: '#fff',
    left: '20px',
    bottom: '88px',
    maxWidth: 'calc(100% - 85px)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    padding: '.5rem',
    whiteSpace: 'nowrap',
  },
  descrConatiner: {
    '& >span, a': {
      marginRight: '.3rem',
    },
  },
  gradient: {
    position: 'absolute',
    bottom: 73,
    width: '100%',
    height: 70,
    background: `linear-gradient(0deg, #000, #000000b0, transparent)`,
  },
  gradientFull: {
    position: 'absolute',
    background: `linear-gradient(0deg, #000, #000000b0, transparent)`,
    width: '100%',
    height: 250,
    bottom: 0,
  },
  desription: {
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    maxWidth: 'calc(100% - 85px)',
    '& a, span': {
      marginLeft: '.3rem',
    },
    '&.full': {
      position: 'absolute',
      top: '87%',
      [theme.breakpoints.between('sm', 'md')]: {
        bottom: 20,
      },
      [theme.breakpoints.between('xs', 'sm')]: {
        top: '93%',
        flexWrap: 'unset',
        maxWidth: '90%',
        height: 20,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        paddingTop: 8,
        display: 'block',
        '& >span': {
          marginLeft: 0,
        },
        '& >a': {
          marginLeft: 0,
        },
      },
    },
  },
}));
