import { makeStyles } from '@material-ui/core/styles';
import { initialDarkGray } from 'themes/colors';

const useStyles = makeStyles({
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 0,
    fontSize: 16,
    lineHeight: 'inherit',
  },
  link: {
    textTransform: 'uppercase',
    textDecoration: 'none',
  },
  text: {
    color: initialDarkGray,
    fontSize: '12px',
  },
  iconWrapper: {
    boxShadow: '0 6px 18px 0 rgba(0,0,0,0.25)',
    width: '50px',
    height: '50px',
    color: 'white',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.Mui-disabled': {
      color: 'white',
    },
    '&.sm': {
      width: '30px',
      height: '30px',
      padding: 0,
      backgroundColor: 'white',
      boxShadow: 'none',
      color: '#000000',
    },
    '& .icon': {
      height: '0.8em',
      width: '0.8em',
    },
  },
  card: {
    position: 'relative',
    padding: 30,
    textAlign: 'center',
    color: '#333333',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  biggerIcon: {
    width: '100px',
    height: '100px',
    marginBottom: '30px',
    backgroundColor: 'rgba(1,61,31,1)',

    '& .icon': {
      fontSize: '60px',
      color: 'white',
    },
  },
  biggerText: {
    textDecoration: 'none',
    color: 'rgba(1,61,31,1)',
    fontSize: '30px',
    fontWeight: 'bold',
  },
  linkButton: {
    width: '100%',
  },
});

export default useStyles;
