import React, { RefObject } from 'react';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as Email } from 'assets/svg/email.svg';
import { ReactComponent as Phone } from 'assets/svg/phone.svg';
import { ReactComponent as Sms } from 'assets/svg/sms.svg';
import { ReactComponent as Save } from 'assets/svg/save.svg';
import { isMobile as getMobile } from 'modules/core/utils';
import { ICardVersion } from 'modules/editor/graphql/gql';
import { Default } from 'modules/editor/components/CardSectionsView/MainInfo/Actions/useButtons/Default';
import SelectPhoneNumberDialog from 'modules/core/components/dialogs/SelectPhoneNumberDialog';
// import { SaveDialog } from 'modules/core/components/dialogs/SaveDialog';
import { IDialogRef } from 'modules/core/containers/DialogWrapper';
import { ILinkProps } from 'modules/core/components/Link';
import { Redo } from '@material-ui/icons';
import { ShareCardDialog } from 'modules/core/components/dialogs/ShareCardDialog';
import { createVCard } from 'modules/core/utils/vCard';
import { setSaveModalAsShown } from 'modules/public/pages/CardPage/SaveInvite/useStoreVisit';

interface IConfig {
  title: string;
  icon: JSX.Element;
  id: string;
}

const SAVE_BTN: IConfig = {
  title: 'save',
  icon: <SvgIcon children={<Save />} htmlColor={'#ffffff'} className="icon" />,
  id: 'card_save_button',
};

const SMS_BTN: IConfig = {
  title: 'text',
  icon: <SvgIcon children={<Sms />} htmlColor={'#ffffff'} className="icon" />,
  id: 'card_sms_button',
};

const PHONE_BTN: IConfig = {
  title: 'phone',
  icon: <SvgIcon children={<Phone />} htmlColor={'#ffffff'} className="icon" />,
  id: 'card_phone_button',
};

const EMAIL_BTN: IConfig = {
  title: 'email',
  icon: <SvgIcon children={<Email />} htmlColor={'#ffffff'} className="icon" />,
  id: 'card_email_button',
};

const SHARE_BTN: IConfig = {
  title: 'share',
  icon: <Redo className="icon" />,
  id: 'reseller_card_share_button'
}

export type ICardModal = (ref: RefObject<IDialogRef>) => JSX.Element;

export type CardUrl = Omit<ILinkProps, 'className'>;

export interface IActionValue extends IConfig {
  url?: CardUrl;
  modal?: ICardModal;
  onClick?: () => void;
}

/**
 * Returning value of useButtons
 */
type Value = IActionValue[];

const isMobile = getMobile();

/**
 * Get email result
 */
type IGetEmail = (email?: string) => Value;

/**
 * Takes Value and returns function that takes email and returns Value
 * @param value
 */
function getEmail(value: Value): IGetEmail {
  return (email) => {
    if (email) {
      const emailValue: IActionValue = { ...EMAIL_BTN, url: { to: `mailto:${email}`, noRoute: true } };
      return [...value, emailValue];
    }
    return value;
  };
}

/**
 * Get phones result
 */
type IGetPrones = (cardId: number, phone?: string, secondPhone?: string) => IGetEmail;

/**
 * Returns function that takes phones end returns function to get email
 */
function getPhones(value: Value): IGetPrones {
  return (cardId, phone, secondPhone) => {
    const phone1 = phone || '';
    const phone2 = secondPhone || '';
    const phoneNumbers: (string)[] = [phone1, phone2].filter((x) => !!x);
    const anyPhone: string | undefined = phoneNumbers[0];

    if (!!anyPhone) {
      // set sms
      const sms: IActionValue = isMobile
        ? { ...SMS_BTN, url: { to: `sms:${anyPhone}`, noRoute: true } }
        : { ...SMS_BTN, modal: (ref) => <SelectPhoneNumberDialog icon={PHONE_BTN.icon} type="text" textItems={phoneNumbers} ref={ref} /> };
      const smsValue: Value = [...value, sms];

      // set phone choose and return
      if (phone && secondPhone) {
        const secondPhoneValue: IActionValue = {
          ...PHONE_BTN,
          modal: (ref) => <SelectPhoneNumberDialog icon={PHONE_BTN.icon} type="call" textItems={phoneNumbers} ref={ref} />,
        };
        return getEmail([...smsValue, secondPhoneValue]);
      }

      // return one phone and return
      const onePhone: IActionValue = isMobile
        ? { ...PHONE_BTN, url: { to: `tel:${anyPhone}`, noRoute: true } }
        : {
            ...PHONE_BTN,
            modal: (ref) => <Default icon={PHONE_BTN.icon} id={PHONE_BTN.id} text={anyPhone} ref={ref} />,
          };
      const onePhoneValue: Value = [...value, sms, onePhone];
      return getEmail(onePhoneValue);
    }
    return getEmail(value);
  };
}

/**
 * Creates save button and return function to get phone buttons
 * @param url
 */
function getMobileValue(cardVer: ICardVersion): IGetPrones {
  const vCard = createVCard(cardVer);

  if (vCard) {
    const name = cardVer.fullName ? cardVer.fullName.replace(/ /g, '-') : 'GoPaperlessContact';
    const downloadName = `${name}.vcf`;
    const url  = { to: vCard, noRoute: true, downloadName }

    const onClick = () => setSaveModalAsShown(cardVer.id);

    const saveBtn: IActionValue = { ...SAVE_BTN, url, onClick };

    return getPhones([saveBtn]);
  }

  return getPhones([]);
}

/**
 * Creates save button and return function to get phone buttons
 */
function getDesktopValue(cardVer: ICardVersion): IGetPrones {
  // const saveBtn: IActionValue = { ...SAVE_BTN, modal: (ref) => <SaveDialog icon={SAVE_BTN.icon} ref={ref} /> };
  // return getPhones([saveBtn]);

  return getMobileValue(cardVer);
}

function getShare(btns: Value, cardVer: ICardVersion, currentForCardId?: number | null): Value {
  const cardUrl = cardVer.card.urlSlug as string;
  const modal: ICardModal | undefined = currentForCardId ? (ref) => <ShareCardDialog cardId={currentForCardId} cardUrl={cardUrl} ref={ref} /> : undefined;
  const shareBtn: IActionValue = { ...SHARE_BTN, modal: modal };
  return btns.concat(shareBtn);
}

interface IUseButtonsParams {
  cardVer: ICardVersion;
  email?: string;
  mobilePhone?: string;
  secondPhoneNumber?: string;
  includeShareBtn?: boolean;
  currentForCardId?: number | null;
}

/**
 * Returns main control buttons for users card
 * @param card
 * @param email
 * @param mobilePhone
 * @param secondPhoneNumber
 * @param includeShareBtn
 * @param currentForCardId
 */
// export function useButtons(card: Partial<ICardVersion>, email?: string, phone?: string, secondPhone?: string, includeShareBtn?: boolean): Value {
export function useButtons(params: IUseButtonsParams): Value {
  const { cardVer, email, mobilePhone, secondPhoneNumber, includeShareBtn, currentForCardId } = params;
  const { id } = cardVer;
  let contactAndSaveBtns: Value = [];

  if (id) {
    if (isMobile) {
      contactAndSaveBtns = getMobileValue(cardVer)(id, mobilePhone, secondPhoneNumber)(email);
    } else {
      contactAndSaveBtns = getDesktopValue(cardVer)(id, mobilePhone, secondPhoneNumber)(email);
    }

    if (includeShareBtn) {
      contactAndSaveBtns = getShare(contactAndSaveBtns, cardVer, currentForCardId)
    }
  }

  return contactAndSaveBtns;
}
