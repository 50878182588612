import { IPersonalDetailAtributes } from 'modules/editor/graphql/mutations';
import { ICardVersion } from 'modules/editor/graphql/gql';
import { IModel } from 'modules/core/interfaces/model';
import { clearObject } from 'modules/core/utils';
import { ObjectSchema, object, string } from 'yup';
import { UNVALID_EMAIL, REQUIRED_FIELD, INCORECT_PHONE } from 'modules/editor/constants/messages';
import { validatePhone } from 'modules/core/utils/validate';

export class PersonalDetailsAtributes implements IModel<IPersonalDetailAtributes> {
  public cardVersionId!: number;
  public emailAddress?: string = '';
  public fullName?: string = '';
  public jobTitle?: string = '';
  public qualifications?: string = '';
  public mobileNumber?: string = '';
  public secondPhoneNumber?: string = '';
  public profilePhotoBase64?: string;
  public profilePhotoName?: string;
  public avatarCropX?: number;
  public avatarCropY?: number;
  public avatarCropH?: number;
  public avatarCropW?: number;
  public cardUrl?: string = '';
  public cardUrlFull: string = '';
  public contactDetailsHeaderShow?: boolean = false;
  [props: string]: any;

  private schema: ObjectSchema<Partial<IPersonalDetailAtributes>> = object().shape({
    emailAddress: string().email(UNVALID_EMAIL).required(REQUIRED_FIELD),
    mobileNumber: string()
      .nullable(true)
      .min(11, INCORECT_PHONE)
      .test('mobileNumber', INCORECT_PHONE, (value: string) => validatePhone(value, true)),
    secondPhoneNumber: string()
      .min(11, INCORECT_PHONE)
      .test('secondPhoneNumber', INCORECT_PHONE, (value: string) => validatePhone(value, true)),
    fullName: string().required(REQUIRED_FIELD),
    cardUrl: string().test(
      'cardUrl',
      `Please don't use '/' symbol in card url`,
      (value: string) => !/.*\/.*/.test(value)
    ),
  });

  constructor(card?: ICardVersion) {
    if (card) {
      this.cardVersionId = card.id;
      this.emailAddress = card.email || '';
      this.fullName = card.fullName || '';
      this.jobTitle = card.jobTitle || '';
      this.qualifications = card.qualifications || '';
      this.mobileNumber = card.mobilePhone || '';
      this.secondPhoneNumber = card.secondPhoneNumber || '';
      this.cardUrl = card.card.urlSlug || '';
      this.contactDetailsHeaderShow = card.contactDetailsHeaderShow || false;
    }
    this.cardUrlFull = `${window.location.host}/${this.cardUrl}`;
  }

  public asState(): IPersonalDetailAtributes {
    const state: any = {};
    Object.keys(this).forEach((key) => (state[key] = this[key]));
    return state;
  }

  public nextState(state: PersonalDetailsAtributes) {
    const atr = new PersonalDetailsAtributes();
    Object.keys(atr).forEach((key) => (atr[key] = state[key]));
    return atr;
  }

  public clear(nullable: boolean, feilds?: string[]) {
    const atr = new PersonalDetailsAtributes();
    if (feilds) {
      clearObject(atr, nullable);
      feilds.forEach((key) => (atr[key] = this[key]));
      atr.cardVersionId = this.cardVersionId;
      return atr;
    } else {
      clearObject(atr, nullable);
      atr.cardVersionId = this.cardVersionId;
      return atr;
    }
  }

  public toJSON(): IPersonalDetailAtributes {
    return {
      emailAddress: this.emailAddress || null,
      mobileNumber: this.mobileNumber === '+' ? null : this.mobileNumber,
      secondPhoneNumber: this.secondPhoneNumber === '+' ? null : this.secondPhoneNumber,
      cardVersionId: this.cardVersionId,
      fullName: this.fullName || null,
      jobTitle: this.jobTitle || null,
      qualifications: this.qualifications || null,
      profilePhotoBase64: this.profilePhotoBase64,
      profilePhotoName: this.profilePhotoName,
      cardUrl: this.cardUrl,
      contactDetailsHeaderShow: this.contactDetailsHeaderShow,
    };
  }

  public validate(path: string) {
    return this.schema.validateSyncAt(path, this.asState());
  }
}
