import { makeStyles } from '@material-ui/core';
import {
  headingStyle,
  sectionHeaderStyle,
  clearBlockStyle,
  inputWrapperStyle,
  iconWrap3Style,
  fieldBlockStyle,
} from 'modules/editor/components/CardSectionEditors/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    [theme.breakpoints.up('md')]: {
      paddingBottom: 64,
    },
  },
  heading: headingStyle(theme),
  sectionHeader: sectionHeaderStyle(theme),
  clearBlock: clearBlockStyle(theme),
  inputWrapper: inputWrapperStyle,
  icon3: iconWrap3Style(theme),
  fieldBlock: fieldBlockStyle,
}));
