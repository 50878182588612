export const UPDATE_BRANDING_SCHEME = {
  $cardVersionId: 'ID!',
  $cardStyleId: 'ID!',
  $backgroundHexColour: 'String',
  $backgroundImageShow: 'Boolean',
  $backgroundImageBase64: 'String',
  $backgroundImageName: 'String',
  $overlayShow: 'Boolean',
  $overlayHexColour: 'String',
  $logoBase64: 'String',
  $logoName: 'String',
  $iconBase64: 'String',
  $iconName: 'String',
  $logoCropX: 'Int',
  $logoCropY: 'Int',
  $logoCropH: 'Int',
  $logoCropW: 'Int',
  $iconCropX: 'Int',
  $iconCropY: 'Int',
  $iconCropH: 'Int',
  $iconCropW: 'Int',
};
