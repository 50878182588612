import React, { useState, useEffect, useRef } from 'react';
import lottie, { AnimationItem } from 'lottie-web';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import enterView from 'enter-view';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import {
  // darkGreen,
  white,
} from 'themes/colors';

export interface IAnimationContainer {
  id: string;
  data: any; // Lottie Animation JSON Data
  index?: number;
  classes?: any;
  containerHeight?: string | number;
  title?: string;
  subtitle?: string;
  beginVisible?: boolean;
  finishVisible?: boolean;
  children?: JSX.Element;
  onDisappear?: (progress: number) => void;
  onAppear?: (progress: number) => void;
  fadeSpeed?: number;
  playByScroll?: boolean;
  exClass?: string;
  preserveAspectRatio?: string;
  enterViewPointForAnimation?: number;
  startAnimationOffset?: number;
}

// Notes

// https://pudding.cool/process/scrollytelling-sticky/
// https://css-tricks.com/lets-make-one-of-those-fancy-scrolling-animations-used-on-apple-product-pages/
// https://github.com/airbnb/lottie-web
// https://github.com/russellgoldenberg/enter-view
// https://css-tricks.com/styling-based-on-scroll-position/

const stickyTopOffset = 90;
const stickyTopOffsetMob = 40;

const AnimationContainer: React.FC<IAnimationContainer> = ({
  id,
  data,
  index,
  classes = {},
  title,
  subtitle,
  containerHeight = "200vh",
  beginVisible,
  finishVisible,
  children,
  onDisappear,
  onAppear,
  fadeSpeed = 2,
  playByScroll = false,
  exClass,
  preserveAspectRatio = "xMidYMid meet",
  enterViewPointForAnimation = 0.75,
  // enter at bottom of viewport
  // offset: 1, // enter at top of viewport
  startAnimationOffset = 0,
}) => {
  const baseClasses = useStyles();

  const idSelector = '#' + id;
  const idContainer = id + '-container';
  const idContainerSelector = '#' + idContainer;
  // const animMaxHeight = window.innerHeight - 120 - 200
  // const innerContainerStyles = { backgroundColor: `#${(index * 2).toString().repeat(6)}` };
  const innerContainerStyles = {};

  const ref = useRef<HTMLDivElement>(null);
  const [animationItem, setAnimationItem] = useState<AnimationItem | undefined>();

  const playOnEnter = !playByScroll;
  const playAnimation = () => animationItem && animationItem.play();

  // for playByScroll behaviour
  const totalFrames = Math.floor(data.op - data.ip);
  const updateAnimationFrame = (newFrame: number) => animationItem && animationItem.goToAndStop(newFrame, true);

  useEffect(() => {
    if (ref.current && !animationItem) {
      //console.log("loading animation!: " + index, id);

      const anim = lottie.loadAnimation({
        container: ref.current, // the dom element that will contain the animation
        renderer: 'svg',
        loop: false,
        // autoplay: true,
        // autoplay: index === 0,
        autoplay: false,
        animationData: data, // the path to the animation json
        rendererSettings: {
          progressiveLoad: false, // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.
          preserveAspectRatio,
        },
      });

      setAnimationItem(anim);
    }
  }, [id, data, animationItem, index, preserveAspectRatio]);

  enterView({
    selector: idSelector,
    enter: function (el: any) {
      el.classList.add('entered');
      onAppear && onAppear(1);

      if (playOnEnter) {
        playAnimation();
      }
    },
    exit: function (el: any) {
      el.classList.remove('entered');
      onDisappear && onDisappear(0);
    },
    progress: function (el: any, progress: number) { },
    offset: enterViewPointForAnimation,
    // once: true, // trigger just once
  });

  enterView({
    selector: idContainerSelector,
    enter: function (el: any) {
      el.classList.add('entered');
    },
    exit: function (el: any) {
      el.classList.remove('entered');
    },
    progress: function (el: any, progressOrig: number) {
      // for some reason the top animation started at 50% of it's total frames, let's correct that,
      // also make sure the animation is played in full by multiplying the progress to the ratio between progressOrig and the offset difference issue:
      let progress = progressOrig;
      if(id === "lp1"){ 
        let progressOffsetFix = 0.5;
        progress = (progressOrig - progressOffsetFix) * (progressOrig/progressOffsetFix);
        if(progress < 0){
          progress = 0; // just in case we fall before the first frame.
        }
      }
      
      if (!beginVisible) {
        el.style.opacity = progress * fadeSpeed;

        if (el.previousElementSibling) {
          el.previousElementSibling.style.opacity = 1 - progress
        }
      }

      if (playByScroll) {
        const frameIndex = Math.ceil(progress * totalFrames);
        requestAnimationFrame(() => updateAnimationFrame(frameIndex));
      }
    },
    offset: startAnimationOffset,
    // once: true, // trigger just once
  });

  return (
    <div
      id={idContainer}
      className={clsx(baseClasses.container, classes.container, exClass)}
      style={{ opacity: beginVisible ? 1 : 0, height: containerHeight }}
    >
      <div className={clsx(baseClasses.innerContainerWithHeight, classes.innerContainer)} style={innerContainerStyles}>
        <div className={clsx('_sticky')}>
          <div className={clsx(baseClasses.contentContainer, exClass, 'contentWrapper')}>
            <div className={clsx(baseClasses.titlesContainer, exClass)}>
              {title && <Typography variant="h2" className={clsx(baseClasses.header, classes.title)}>{title}</Typography>}
              {subtitle && <Typography variant="h4" className={clsx(baseClasses.subtitle, classes.subtitle)}>{subtitle}</Typography>}
            </div>

            <div id={id} className={clsx(baseClasses.animation, classes.animation, exClass)} ref={ref}></div>

            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnimationContainer;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '200vh',
      width: '100%',
      padding: 40,
      opacity: 0,
      position: 'relative',

      // border: '2px solid cyan',
      // backgroundColor: 'aquamarine',

      '&._whyYoullLove': {
        '&:nth-child(even) .contentWrapper': {
          [theme.breakpoints.up('lg')]: {
            flexDirection: 'row-reverse',
          }
        },
      },
    },
    animationWrapper: {
    },
    titlesContainer: {
      '&._landingSection': {
        '& h2': {
          [theme.breakpoints.down('md')]: {
            paddingTop: 0,
            marginBottom: 0,
          },
          [theme.breakpoints.down('xs')]: {
            paddingTop: 50,
            marginBottom: -50,
          },
          '@media (max-width: 414px) and (max-height: 736px)': { /* iPhone 8 plus */
            paddingTop: 20,
            marginBottom: -20,
          },
          '@media (max-width: 375px) and (max-height: 667px)': { /* iPhone 6/7/8 and SE2020 */
            paddingTop: 10,
            marginBottom: -10,
          }
        }
      },
      '&._whyYoullLove': {
        marginBottom: -30,
        '& h2': {
          fontSize: 40,
          marginTop: 40,
          [theme.breakpoints.down('xs')]: {
            fontSize: 28,
            marginTop: 25,
          }
        }
      }
    },
    contentContainer: {
      '&._whyYoullLove': {
        height: `100vh`,
        margin: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        overflow: 'hidden',
        alignItems: 'center',
        padding: theme.spacing(4, 2, 0),
        paddingBottom: 0,
        paddingTop: 0,
        '& svg': {
          height: 'auto !important',
        },
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          '& svg': {
            height: '70vh !important',
          }
        },
        [theme.breakpoints.down('xs')]: {
          justifyContent: 'end',
          paddingBottom: 30,
          '@media (max-height: 667px)': { /* iPhone 6/7/8 */
            '& svg': {
              height: '64vh !important',
            }
          },
        },
        '@media (max-height: 650px)': {
          '& svg': {
            height: '90v !important',
          }
        }
      },

      '&._resellerHomepageSection': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    innerContainerWithHeight: {
      position: 'relative',
      height: '100%',

      // position: 'sticky',
      // height: '100vh',
      // top: 0,

      '& ._sticky': {
        position: 'sticky',
        top: stickyTopOffset,
      }
    },
    animation: {
      height: `calc(100vh - 64px - ${stickyTopOffset}px)`,
      width: 'auto',
      // maxHeight: 800,
      // height: 'calc(100% - 64px)',

      // backgroundColor: '#a52a2a88',

      '&._landingSection': {
        margin: theme.spacing(0, -10, 0, -3),
        '@media (max-height: 650px)': {
          paddingLeft: 0,
          paddingRight: 0,
        }
      },

      '&._whyYoullLove': {
        padding: 30,
        maxWidth: 796,
        height: '100vh',
        width: '100%',
        margin: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '@media (max-height: 650px)': {
          maxWidth: 356,
          marginTop: 8,
          marginLeft: 13,
        },
        [theme.breakpoints.down('md')]: {
          maxWidth: 546,
          marginTop: 18,
          marginLeft: 0,
        },
        [theme.breakpoints.down('xs')]: {
          maxWidth: '100%',
          marginTop: 0,
          marginLeft: 0,
          marginRight: 0,
        }
      },

      '&._resellerHomepageSection': {
        height: 'auto',
        width: '50%',
      },

      '@media (max-height: 650px)': {
        height: `calc(100vh - 64px - 60px)`,
      }
    },
    header: {
      color: white,
      width: '100%',
      fontSize: 30,
      fontWeight: 700,
      margin: theme.spacing(0, 0, 3, 0),
      lineHeight: theme.typography.pxToRem(40),
      textAlign: 'center',
    },
    subtitle: {
      color: white,
      width: '100%',
      textAlign: 'center',
      marginBottom: theme.spacing(1),
      fontSize: 30,
      // lineHeight: "40px",
      fontFamily: 'F37 Ginger Light',
      fontWeight: 500,

      '@media (max-height: 650px)': {
        fontSize: '20px',
        marginBottom: 0,
      }
    },

    [theme.breakpoints.down('md')]: {
      container: {
        justifyContent: 'start',

        '&._whyYoullLove': {
          padding: theme.spacing(5, 0, 0),
        },
      },

      animation: {
        margin: theme.spacing(0),
        height: `calc(100vh - 64px - ${stickyTopOffset}px)`,

        '&._landingSection': {
          margin: theme.spacing(0, -5),
        },

        '&._whyYoullLove': {
          padding: theme.spacing(0),
        },
      },
      subtitle: {
        padding: theme.spacing(0, 2),
        marginBottom: theme.spacing(2),
        fontSize: 20,
        // lineHeight: "21px",
        textAlign: 'center',
      },
    },
    [theme.breakpoints.down('sm')]: {
      container: {
        '&._resellerHomepageSection': {
          padding: theme.spacing(1),
        },
      },
      innerContainerWithHeight: {
        '& ._sticky': {
          top: stickyTopOffsetMob,
        }
      },
      animation: {
        margin: theme.spacing(0, -4),
        // height: `calc(100vh - 64px - ${stickyTopOffset}px)`,
        height: `calc(100vh - 64px - ${stickyTopOffsetMob}px)`,

        '&._resellerHomepageSection': {
          height: 'auto',
          width: '80%',
        },
      },
    },
    [theme.breakpoints.down('xs')]: {
      header: {
        fontSize: 24,
        lineHeight: theme.typography.pxToRem(30),
      },
    },
  })
);
