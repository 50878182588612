import Hidden from '@material-ui/core/Hidden';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SuccessContent from 'modules/core/components/dialogs/SuccessContent';
import CircularIndeterminate from 'modules/core/components/progress';
import DialogWrapper from 'modules/core/containers/DialogWrapper';
import { IFieldDescriptor } from 'modules/core/interfaces/table';
import { ISlotUser, USER_STATUSES } from 'modules/users/interfaces/assigned-users';
import { getAssignedUsers } from 'modules/users/thunks/assigned-users';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { IReduxState } from 'store';
import { AssignedUser } from 'modules/users/components/UsersPage/AssignedUsers/AssignedUser';
import { AssignedUsersTable } from 'modules/users/components/UsersPage/AssignedUsers/AssignedUsersTable';
import { styles } from 'modules/users/components/UsersPage/AssignedUsers/styles';

interface IState {
  assignedUsers: ISlotUser[];
  occupiedSlots: number;
  pending: boolean;
  errors: string[];
}
interface IReducers {
  getUsers: () => Promise<void>;
}

function generateFields({ classes }: WithStyles<typeof styles>): Array<IFieldDescriptor<ISlotUser>> {
  return [
    { key: 'name', title: 'Name', getData: (item) => item.fullName },
    { key: 'email', title: 'Email', getData: (item) => item.email },
    {
      key: 'status',
      title: 'Status',
      getData: (item) => USER_STATUSES[item.slot.status],
      cssClass: classes.statusCell,
    },
    { key: 'expand', title: '', isAction: true },
  ];
}

const AssignedUsersComponent: React.FC<WithStyles<typeof styles> & IState & IReducers> = ({
  classes,
  getUsers,
  assignedUsers,
  occupiedSlots,
  pending,
}) => {
  const ref = useRef<any>();
  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const onShowDeleteDialog = useCallback(() => ref.current.show(), []);

  const fields = useMemo(() => generateFields({ classes }), [classes]);

  return (
    <div className={classes.root}>
      <Hidden smDown>
        <Typography variant="h5">Assigned users</Typography>
      </Hidden>
      {pending ? (
        <CircularIndeterminate />
      ) : (
        <>
          <Typography variant="subtitle2" color="textSecondary" className={classes.subtitle}>
            {occupiedSlots} users assigned
          </Typography>
          <Hidden smDown>
            <AssignedUsersTable onShowDeleteDialog={onShowDeleteDialog} users={assignedUsers!} fields={fields} />
          </Hidden>
          <Hidden mdUp>
            {assignedUsers!.map((user, idx) => (
              <AssignedUser
                key={idx}
                user={user}
                index={idx}
                fields={fields}
                onShowDeleteDialog={onShowDeleteDialog}
                variant="card"
              />
            ))}
          </Hidden>
        </>
      )}
      <DialogWrapper ref={ref}>
        <SuccessContent message="User successfully deleted" />
      </DialogWrapper>
    </div>
  );
};

const mapStore = ({ users: { pending, errors, assignedUsers, occupiedSlots } }: IReduxState) => ({
  pending,
  errors,
  assignedUsers,
  occupiedSlots,
});

const mapReducers = {
  getUsers: getAssignedUsers,
};

export const AssignedUsers = connect(mapStore, mapReducers)(withStyles(styles)(AssignedUsersComponent));
