import { createMuiTheme } from '@material-ui/core/styles';
import { darkGreen, green, lightGreen } from './colors';

const greenTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: darkGreen,
    },
    background: {
      default: darkGreen,
    },
    text: {
      primary: darkGreen,
      secondary: lightGreen,
    },
  },

  typography: {
    fontFamily: 'F37 Ginger',
    h3: {
      fontFamily: 'F37 Ginger',
      fontWeight: 700,
    },
    h2: {
      fontFamily: 'F37 Ginger',
      fontWeight: 700,
    },
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 400,
    },
    subtitle1: {
      fontFamily: 'F37 Ginger Light',
      fontWeight: 400,
      color: green,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          // overflowX: 'hidden',
        },
        '.App': {
          position: 'relative',
          // overflow: 'hidden',
        },
        '.App-header': {
          position: 'relative',
          width: '100%',

          '@media (max-width: 1279.95px)': {
            position: 'relative',
            top: 0,
            zIndex: 10,
          },

          '&._page_Home': {
            position: 'absolute',
          },
        },
        '.Site': {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          // overflow: 'hidden',
        },
        '.Site-content': {
          flex: '1 0 auto',
          width: '100%',
        },
        '.Site-content:after': {
          content: `' '`,
          display: 'none',
          marginTop: 40,
          height: 0,
          visibility: 'hidden',
          '@media (max-width: 1023px)': {
            display: 'block',
          },
        },
        '.main._page_Home': {
          marginTop: -96,
        },
        html: {
          width: '100%',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: darkGreen,
        fontFamily: 'F37 Ginger Light',
        '&.Mui-focused': {
          color: green,
        },
      },
      shrink: {
        color: green,
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: 'F37 Ginger Light',
        color: darkGreen,
      },
      input: {
        borderColor: green,
      },
    },
    MuiFilledInput: {
      root: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        backgroundColor: 'rgba(241,244,245,0.5)',
        '&:hover': {
          backgroundColor: 'rgba(241,244,245,1)',
        },
        '&.Mui-focused': {
          backgroundColor: 'rgba(241,244,245,0.8)',
        },
      },
      underline: {
        borderBottomColor: green,
        '&:before': {
          borderBottomColor: green,
        },
        '&:hover:before': {
          borderBottomColor: green,
        },
        '&:after': {
          borderBottomColor: green,
        },
      },
    },
    MuiFab: {
      sizeSmall: {
        width: 30,
        height: 30,
        minHeight: 30,
      },
    },
    MuiMobileStepper: {
      root: {
        background: 'transparent',
      },
      dot: {
        cursor: 'pointer',
        width: '10px',
        height: '10px',
        backgroundColor: '#6f7173',
      },
      dotActive: {
        cursor: 'pointer',
        width: '10px',
        height: '10px',
        backgroundColor: '#fff',
      },
    },
  },
});

export default greenTheme;
