
import React, { useCallback, useEffect, useState, lazy, Suspense } from 'react';
import { Container, CssBaseline, Hidden, Link, MuiThemeProvider, Typography, Divider } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import clsx from 'clsx';
import { cancelSubscription, renewSubscription } from 'modules/billing/api/subscription-mutation';
import { Subscription } from 'modules/billing/models/subscription';
import { RadioButtonGroup } from 'modules/core/components/fields';
import Header from 'modules/core/components/Header';
import CircularIndeterminate from 'modules/core/components/progress';
import { useDefaultManifest } from 'modules/core/utils';
import { getUserSubscriptionStatistics } from 'modules/dashboard/api/getCardStatictics';
import useContainerStyles from 'modules/public/pages/main/containerStyles';
import { Else, If, Then } from 'react-if';
import darkTheme from 'themes/darkTheme';
import useStyles from './styles';

const Footer = lazy(() => import('modules/core/components/Footer'));

export const BillingPage: React.FC = () => {
  const classes = useStyles();
  const containerClasses = useContainerStyles();
  const [rows, setRows] = useState<Subscription[]>([]);
  useDefaultManifest();

  useEffect(() => {
    getUserSubscriptionStatistics().then(setRows);
  }, []);

  const handleSelect = useCallback(
    async (value: 'Off' | 'On', sub: Subscription) => {
      try {
        sub.renewStatus = value;
        setRows(rows.map((s) => s.nextState()));
        if (value === 'Off') {
          cancelSubscription(sub.id);
        } else {
          renewSubscription(sub.id);
        }
      } catch {
        sub.renewStatus = value === 'Off' ? 'On' : 'Off';
        setRows(rows.map((s) => s.nextState()));
      }
    },
    [setRows, rows]
  );

  return (
    <MuiThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className="App Site">
        <div className="App-header _white">
          <Header exClass="_dark" isPublic={false} />
        </div>
        <div className="Site-content">
          <div className="main">
            <main className={classes.root}>
              <div className={clsx(classes.page, '_padding-md')}>
                <Container className={containerClasses.container}>
                  <div className={classes.titleWrap}>
                    <Typography variant="h3" className={classes.title}>
                      Your purchases
                    </Typography>
                    <Typography className={classes.subtitle}>VIEW YOUR ORDER HISTORY AND INVOICES</Typography>
                  </div>
                </Container>
                <Container className={containerClasses.container}>
                  <If condition={!rows.length}>
                    <Then>
                      <CircularIndeterminate />
                    </Then>
                    <Else>
                      <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table"
                      >
                        <TableHead>
                          <TableRow className={classes.tableRowHead}>
                            <TableCell align={'left'} className={classes.cellHead}>
                              <Typography className={classes.tableHead}>Invoice</Typography>
                            </TableCell>
                            <TableCell align={'left'} className={classes.cellHead}>
                              <Typography className={classes.tableHead}>Card</Typography>
                            </TableCell>
                            <Hidden mdDown>
                              <TableCell className={clsx(classes.cellHead, 'empty')} />
                              <TableCell className={clsx(classes.cellHead, 'empty')} />
                              <TableCell className={clsx(classes.cellHead, 'empty')} />
                            </Hidden>
                            <TableCell align={'left'} className={classes.cellHead}>
                              <Typography className={classes.tableHead}>Type</Typography>
                            </TableCell>
                            <TableCell align={'left'} className={classes.cellHead}>
                              <Typography className={classes.tableHead}>Status</Typography>
                            </TableCell>
                            <TableCell align={'left'} className={classes.cellHead}>
                              <Typography className={classes.tableHead}>Start</Typography>
                            </TableCell>
                            <TableCell align={'left'} className={classes.cellHead}>
                              <Typography className={classes.tableHead}>Expiry</Typography>
                            </TableCell>
                            <TableCell align={'left'} className={classes.cellHead}>
                              <Typography className={classes.tableHead}>Item</Typography>
                            </TableCell>
                            <TableCell align={'left'} className={classes.cellHead}>
                              <Typography className={classes.tableHead}>Total</Typography>
                            </TableCell>
                            <TableCell align={'left'} className={classes.cellHead}>
                              <Typography className={classes.tableHead}>Auto renew</Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <TableRow tabIndex={-1} key={row.reactUUID} className={classes.tableRow}>
                              <TableCell className={clsx(classes.cell, '_green')} align="left">
                                <Link target="_blank" rel="noopener" href={row.hostedInvoiceUrl}>
                                  {row.invoiceNumber}
                                </Link>
                              </TableCell>
                              <TableCell className={classes.cell} align="left">
                                {row.account.accountName}
                              </TableCell>
                              <Hidden mdDown>
                                <TableCell className={clsx(classes.cell, '_empty')} />
                                <TableCell className={clsx(classes.cell, '_empty')} />
                                <TableCell className={clsx(classes.cell, '_empty')} />
                              </Hidden>
                              <TableCell className={classes.cell} align="left">
                                {row.account.accountDesc}
                              </TableCell>
                              <TableCell className={classes.cell} align="left">
                                {row.statusFormatted}
                              </TableCell>
                              <TableCell className={classes.cell} align="left">
                                {row.start}
                              </TableCell>
                              <TableCell className={classes.cell} align="left">
                                {row.expiry}
                              </TableCell>
                              <TableCell className={classes.cell} align="left">
                                {row.description}
                              </TableCell>
                              <TableCell className={classes.cell} align="left">
                                {row.formatAmount}
                              </TableCell>
                              <TableCell className={clsx(classes.cell, '_radio')} align="left">
                                <RadioButtonGroup
                                  label={['Off', 'On']}
                                  value={row.renewStatus}
                                  name={row.id as string}
                                  onSelect={(value: 'Off' | 'On') => handleSelect(value, row)}
                                  exClass={'light'}
                                />
                              </TableCell>

                              <TableCell className={classes.mobileContent}>
                                <Typography className={clsx(classes.mobileText, '_green')}>{row.invoiceId}</Typography>
                                <Typography className={clsx(classes.mobileText, '_total')}>
                                  {row.formatAmount}
                                </Typography>
                                <Typography className={clsx(classes.accountName)}>{row.account.accountName}</Typography>
                                <Typography className={clsx(classes.accountDesc)}>Type: {row.account.accountDesc}</Typography>
                                <Divider className={classes.hr} />
                                <Typography className={clsx(classes.mobileText, '_period')}>
                                  {row.start} - {row.expiry}
                                </Typography>
                                <Typography className={clsx(classes.mobileText, '_period')}>{row.description}</Typography>
                                <Typography className={clsx(classes.mobileText, '_period')}>{row.statusFormatted}</Typography>
                                <div className={clsx(classes.mobileContent, '_radio')}>
                                  <Typography className={clsx(classes.mobileText, '_radio')}>Auto renew</Typography>
                                  <RadioButtonGroup
                                    label={['Off', 'On']}
                                    value={row.renewStatus}
                                    name={row.id as string}
                                    onSelect={(value: 'Off' | 'On') => handleSelect(value, row)}
                                    exClass={'light'}
                                  />
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Else>
                  </If>
                </Container>
              </div>
            </main>
          </div>
        </div>
        <Suspense fallback={null}>
          <Footer exClass="_dark" isWltHidden={true} />
        </Suspense>
      </div>
    </MuiThemeProvider>
  );
};
