import { Box } from '@material-ui/core';
import { useCurrencySymbol } from 'modules/core/utils/currency';
import React from 'react';
import { Else, If, Then, When } from 'react-if';

interface IAmount {
  id?: string;
  amount: number;
  ccy: string;
  inline?: boolean;
  poa?: boolean;
  vatDisplay?: boolean;
  negative?: boolean;
}

const Amount: React.FC<IAmount> = ({ amount, poa, vatDisplay, ccy, inline, negative, id = Date.now().toString(16) }) => {
  const symbol = useCurrencySymbol(ccy);
  const negate = negative || false;

  if (poa) {
    return <span>POA</span>;
  } else {
    const numberAm = amount / 100; // Amount returned as cent count!
    if (isNaN(numberAm)) {
      return (
        <span>
          {symbol}0<sup>.00</sup>
        </span>
      );
    } else {
      const [n, d] = numberAm.toFixed(2).split('.');
      return (
        <Box display={inline ? '' : 'block'} component="span">
          <Box component="span">
            <If condition={negate}>
              <span id={`amount_negate_${id}`}>-</span>
            </If>
            <span id={`amount_symbol_${id}`}>{symbol}</span>
            <span id={`amount_int_${id}`}>{n}</span>
            <If condition={!!inline}>
              <Then>
                <span id={`amount_dec_${id}`}>.{d}</span>
              </Then>
              <Else>
                <sup id={`amount_dec_${id}`} style={{ margin: 0 }}>
                  .{d}
                </sup>
              </Else>
            </If>
          </Box>
          <When condition={!!vatDisplay}>
            <Box id={`amount_vat_${id}`} component="span" mt={1} display="block" fontSize="0.8rem" fontWeight="lighter" style={{ marginTop: 4 }}>
              + VAT
            </Box>
          </When>
        </Box>
      );
    }
  }
};

export default Amount;
