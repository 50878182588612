import { SvgIcon } from '@material-ui/core';
import { ReactComponent as VideoIcon } from 'assets/svg/video.svg';
import clsx from 'clsx';
import CardWrapper from 'modules/editor/components/CardWrapper';
import { IVideoSection } from 'modules/editor/graphql/gql';
import React, { CSSProperties, RefObject } from 'react';
import { When } from 'react-if';
import ReactPlayer from 'react-player';
import { useScroll } from 'modules/editor/components/CardSectionsView/hooks/useScroll';
import { E_CARD_STEPS } from 'modules/editor/constants/enums';

interface IVideoProps {
  section: IVideoSection;
  classes: Record<string, string>;
  display: boolean;
  bodyStyle: CSSProperties;
  titleStyle: CSSProperties;
  iconStyle: CSSProperties;
  isPreview?: boolean;
  wrapperRef: RefObject<HTMLDivElement>;
}

/**
 * Video section block view for card. Display YouTube video.
 * @param props IVideoProps
 */
export const VideoSection: React.FC<IVideoProps> = ({
  section,
  classes,
  display,
  bodyStyle,
  titleStyle,
  iconStyle,
  isPreview,
  wrapperRef,
}) => {
  const ref = useScroll(E_CARD_STEPS.VIDEO, wrapperRef, display);
  const firstLine = section.showSectionHeader ? <span>Watch</span> : undefined;

  return (
    <When condition={display}>
      <CardWrapper
        {...section}
        ref={ref}
        bodyStyle={bodyStyle}
        titleStyle={titleStyle}
        firstLine={firstLine}
        icon={<SvgIcon children={<VideoIcon />} htmlColor={'#ffffff'} className="section-icon" style={iconStyle} />}
      >
        <When condition={!!section.linkUrl}>
          <div className={clsx(classes.wide, 'video-wrap', isPreview && classes.widePreview)}>
            <ReactPlayer url={section.linkUrl} />
          </div>
        </When>
      </CardWrapper>
    </When>
  );
};
