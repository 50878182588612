import { Typography, Box } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { InputField, SelectField, RadioButtonGroup as RadioGroup } from 'modules/core/components/fields';
import Slider from 'modules/core/components/Slider';
import { WEEK, TIME_FORMAT_VARIANTS } from 'modules/editor/constants/options';
import { ICardVersion, ILocationSection } from 'modules/editor/graphql/gql';
import { LocationAtributes, LocationOpeningTime } from 'modules/editor/models/atributes/location-atributes';
import { updateLocation } from 'modules/editor/api/card-mutations';
import { IReduxState } from 'store';
import ClearFields from 'modules/editor/components/CardSectionEditors/Clear';
import { clearSection, isDisabled } from './hooks';
import { OverlayProgress } from 'modules/core/components/progress/OverlayProgress';
import { AddingBlock } from './AddingBlock';
import countryList from 'react-select-country-list';
import clsx from 'clsx';

interface ILocation {
  updateLocation: (locationAtr: LocationAtributes) => Promise<ILocationSection | undefined>;
  card: ICardVersion;
  classes: any;
  fields: string[];
}

export const Location = connect(
  (state: IReduxState) => ({ card: state.currentVersion.currentVer, fields: state.editorSteps.locationFields }),
  { updateLocation }
)((props: ILocation) => {
  const { updateLocation: saveData, card, classes, fields } = props;

  const [location, setLocation] = useState<LocationAtributes>(new LocationAtributes(card));
  const [addErr, setAddErr] = useState(false);
  const [overlay, setOverlay] = useState(false);

  useEffect(() => {
    setLocation(new LocationAtributes(card));
  }, [card]);

  const deleteItem = useCallback(
    async (index: number) => {
      const item = location.openingTimesAttributes[index];
      item._destroy = true;
      const l = location.nextState(location);
      setLocation(l);
      saveData(l);
      location.openingTimesAttributes = location.openingTimesAttributes.filter((ot) => ot !== item);
      setLocation(location.nextState(location));
    },
    [setLocation, saveData, location]
  );

  const fieldChange = useCallback(
    (val: any, field: string) => {
      const loc = location as any;
      loc[field] = val;
      setLocation(location.nextState(loc));
    },
    [location, setLocation]
  );

  const updateAndSave = useCallback(
    async (val: any, prop: string, ) => {
      fieldChange(val, prop);
      try {
        await saveData(location);
      } catch (error) {
        console.warn(error);
      }
    },
    [fieldChange, saveData, location]
  );

  const saveAndUpdateID = useCallback(
    async (indx: number, opTime: LocationOpeningTime) => {
      const newLocAtr = location.nextState(location);
      setLocation(newLocAtr);
      try {
        const res = await saveData(newLocAtr);
        if (res) {
          opTime.id = res.openingTimes[indx].id;
          setLocation(location.nextState(location));
        }
      } catch (error) {
        console.warn(error);
      }
    },
    [location, setLocation, saveData]
  );

  const changeOpTime = useCallback(
    async (val: any, field: string, indx: number) => {
      const opTime = location.openingTimesAttributes[indx] as any;
      opTime[field] = val;
      if (!opTime.id) {
        setOverlay(true);
      }
      await saveAndUpdateID(indx, opTime);
      setOverlay(false);
    },
    [location, saveAndUpdateID]
  );

  const addOpeningTime = useCallback(() => {
    if (location.openingTimesAttributes.length - card.locationSection.openingTimes.length < 1) {
      location.openingTimesAttributes.push(new LocationOpeningTime());
      setLocation(location.nextState(location));
    } else {
      setAddErr(true);
      setTimeout(() => setAddErr(false), 2000);
    }
  }, [location, setLocation, setAddErr, card]);

  const changeTime = useCallback(
    async (val: [number, number], indx: number) => {
      const [from, to] = val;
      const opTime = location.openingTimesAttributes[indx];
      opTime.fromHour = Number(from);
      opTime.toHour = Number(to);
      setOverlay(true);
      await saveAndUpdateID(indx, opTime);
      setOverlay(false);
    },
    [location, saveAndUpdateID, setOverlay]
  );

  const COUNTRIES = countryList()
    .getData()
    .map((x: any) => {
      x.value = x.label;
      return x;
    });

  return (
    <>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography className={classes.sectionHeader}>Location</Typography>
        {fields.length === 9 ? null : (
          <Box className={classes.clearBlock}>
            <ClearFields
              id="location_editor_clear"
              ico={<Clear fontSize="inherit" />}
              onConfirm={() => clearSection(location, saveData, setLocation)}
            >
              Clear section
            </ClearFields>
          </Box>
        )}
      </Box>
      <Box className={classes.fieldBlockSm}>
        <Typography className={classes.heading}>Street address</Typography>
        <InputField
          name="location-streetAddress"
          type="text"
          label="Enter street address"
          disabled={isDisabled(fields, 'streetAddress')}
          onChange={(e) => fieldChange(e.target.value, 'streetAddress')}
          value={location.streetAddress as string}
          variant="filled"
          onBlur={() => saveData(location)}
        />
      </Box>
      <Box className={classes.fieldBlockSm}>
        <InputField
          name="location-streetAddress2"
          type="text"
          label="Enter street address"
          disabled={isDisabled(fields, 'streetAddress2')}
          onChange={(e) => fieldChange(e.target.value, 'streetAddress2')}
          value={location.streetAddress2 as string}
          variant="filled"
          onBlur={() => saveData(location)}
        />
      </Box>
      <Box className={classes.fieldBlockSm}>
        <Typography className={classes.heading}>City</Typography>
        <InputField
          name="location-city"
          type="text"
          label="Enter city"
          disabled={isDisabled(fields, 'city')}
          onChange={(e) => fieldChange(e.target.value, 'city')}
          value={location.city as string}
          variant="filled"
          onBlur={() => saveData(location)}
        />
      </Box>
      <Box className={classes.fieldBlockSm}>
        <Typography className={classes.heading}>County or state</Typography>
        <InputField
          name="location-state"
          type="text"
          label="Enter county or state"
          disabled={isDisabled(fields, 'state')}
          onChange={(e) => fieldChange(e.target.value, 'state')}
          value={location.state as string}
          variant="filled"
          onBlur={() => saveData(location)}
        />
      </Box>
      <Box className={classes.fieldBlockSm}>
        <Typography className={classes.heading}>Country</Typography>
        <SelectField
          exClass="_editor"
          name="location-country"
          value={location.country as string}
          onChange={(country: string) => updateAndSave(country, 'country')}
          label="Enter country"
          options={COUNTRIES}
          disabled={isDisabled(fields, 'country')}
        />
      </Box>
      <div className={classes.zipCode}>
        <Typography className={classes.heading}>Zip / Postcode</Typography>
        <InputField
          name="location-postcode"
          type="text"
          label="Enter code"
          disabled={isDisabled(fields, 'postcode')}
          onChange={(e) => fieldChange(e.target.value, 'postcode')}
          value={location.postcode as string}
          variant="filled"
          onBlur={() => saveData(location)}
        />
      </div>

      <Box mb={2}>
        <Typography className={clsx(classes.sectionHeader, '_displaySm')}>Business hours</Typography>
      </Box>

      <Typography className={classes.heading}>Time format</Typography>
      <Box mt={0} mb={3}>
        <RadioGroup
          name="location-display12h"
          disabled={isDisabled(fields, 'display12h')}
          value={location.display12h ? "12-hour" : "24-hour" }
          onSelect={(val: string) => updateAndSave(val === "12-hour" ? true : false, 'display12h')}
          label={TIME_FORMAT_VARIANTS}
          exContainerClass="noVerticalMargin"
        />
      </Box>

      {location.openingTimesAttributes.map((el, indx) => (
        <div id={`editor_location_item_${indx}`} className={classes.item} key={el._reactUID}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography className={classes.heading}>
                Opening time {indx + 1 < 10 ? `0${indx + 1}` : `${indx + 1}`}
              </Typography>
            </Box>
            <Box className={classes.clearWrap} onClick={() => deleteItem(indx)} id={`location-delete-item-${indx}`}>
              <Box className={classes.clear}>
                <Clear fontSize="inherit" color="inherit" />
              </Box>
            </Box>
          </Box>
          <Box mb={2}>
            <div className={classes.days}>
              <SelectField
                value={el.fromDay}
                disabled={isDisabled(fields, 'fromDay')}
                onChange={(val: string) => changeOpTime(val, 'fromDay', indx)}
                label="from"
                options={WEEK}
                name={`location-fromDay-${indx}`}
              />
              <span className={classes.line} />
              <SelectField
                value={el.toDay}
                disabled={isDisabled(fields, 'toDay')}
                onChange={(val: string) => changeOpTime(val, 'toDay', indx)}
                label="to"
                options={WEEK}
                name={`location-toDay-${indx}`}
              />
            </div>
          </Box>
          <Slider
            id={`location-timepicker-${Date.now()}`}
            disabled={isDisabled(fields, 'fromHour')}
            values={[el.fromHour as number, el.toHour as number]}
            onChange={(e) => changeTime(e, indx)}
            display12h={location.display12h}
          />
        </div>
      ))}
      {addErr && <Box color="#f44336">Please fill in the previous slot</Box>}
      {isDisabled(fields, 'fromDay') && isDisabled(fields, 'toDay') && isDisabled(fields, 'fromHour') ? null : (
        <AddingBlock action={addOpeningTime} id="location" name="time" />
      )}
      <OverlayProgress isProgress={overlay} />
    </>
  );
});
