import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    exploreMore: {
      [theme.breakpoints.down('xs')]: {
        maxWidth: 'none',
        width: '100%',
      },
    },
    root: {},
    Link: {
      color: 'white',
      textDecoration: 'none!important',
    },
    hr: {
      maxWidth: 1230,
      width: '100%',
      height: 1,
      background: ' rgba(255,255,255,0.25)',
      margin: '100px auto',
      '&._marginLg': {
        margin: '120px auto 100px auto',
        [theme.breakpoints.down('md')]: {
          background: 'transparent',
          margin: '60px auto',
        },
      },
      '&:last-child': {
        marginBottom: 0,
        [theme.breakpoints.down('md')]: {
          margin: '60px auto',
        },
      },
      [theme.breakpoints.down('md')]: {
        background: 'transparent',
        margin: '31px auto',
      },
    },
    sliderWrapSmall: {
      maxWidth: '50%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',

      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
      },

      '& .slick-slider': {
        maxWidth: 470,
        marginTop: 20,
        marginRight: 20,

        [theme.breakpoints.down('md')]: {
          marginTop: 54,
        },
      },
      '& .slick-list': {
        width: '80%',
      },
    },
    sliderWrapLarge: {
      maxWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '& h3': {
        marginBottom: 32,

        [theme.breakpoints.down('sm')]: {
          marginBottom: 51,
        },
      },
      '& .slick-slider .slick-arrow': {
        top: -82,
        right: 0,
        left: 'unset',
      },
      '& .slick-slider .slick-prev': {
        right: 80,
      },
      [theme.breakpoints.down('sm')]: {
        '& .slick-dots': {
          top: -30,
          left: 0,
          textAlign: 'left',
          transform: 'none',
          height: 'fit-content',

          '& button': {
            paddingLeft: 0,
            paddingRight: 0,
            width: 8,
          },

          '& li': {
            width: 8,
            height: 8,
            marginLeft: theme.spacing(2),

            '&:first-child': {
              marginLeft: 0,
            },
          },
        },
        '& .slick-dots li button': {
          '&:before': {
            color: 'white',
            width: 8,
            height: 8,
            background: 'white',
            content: `' '`,
            borderRadius: '50%',
          },
        },
      },

      '& .slick-slider .slick-list': {
        width: '68%',

        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
    },
    sliderWrap: {},
    sliderLinks: {
      width: '100%',
      marginTop: 90,
      display: 'flex',
      justifyContent: 'space-between',

      [theme.breakpoints.down('md')]: {
        marginTop: 33,
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',

        '&> *': {
          marginBottom: 25,

          '&:last-child': {
            marginBottom: 0,
          },
        },
      },
    },
    imgWrap: {
      maxWidth: 400,
      width: '100%',
      marginBottom: 159,

      '& img': {
        height: '100%',
        objectFit: 'cover',
        width: '100%',
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      },
      [theme.breakpoints.down('md')]: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -2,
      },
    },
    mobilePhone: {
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: -1,
      width: '100%',
    },
    titleMd: {
      fontSize: 50,
      lineHeight: '70px',
      color: 'white',
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
        lineHeight: '30px',
      },
    },
    advantages: {
      display: 'flex',
      flexDirection: 'column',
      color: 'white',
      width: 'calc(25% - 33px)',

      [theme.breakpoints.down('md')]: {
        width: 'calc(50% - 33px)',
        marginBottom: theme.spacing(3),
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        // maxWidth: 295,
        marginRight: 0,
        marginBottom: theme.spacing(3),
      },

      '&:not(:last-child)': {
        marginRight: 33,
      },
      '&:focus': {
        outline: 'none!important',
      },
    },
    advantagesWrap: {
      display: 'flex',
      marginTop: 42,
      flexWrap: 'wrap',
      width: '100%',

      '& .slick-slider': {
        maxWidth: '100%',
      },

      '& .slick-dots': {
        top: '100%',
        bottom: '0',
        left: '0',
        right: '0',
        marginTop: 24,
        paddingLeft: 12,
        textAlign: 'center',
      },

      [theme.breakpoints.down('sm')]: {
        display: 'block',
        position: 'relative' /* not always necessary */,
        marginTop: 71,

        '& .slick-slide': {
          marginRight: 0,
        },
      },
    },
    advantagesTitle: {
      marginBottom: 18,
      fontSize: 20,
    },
    advantagesIcon: {
      width: 100,
      height: 100,
      background: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      marginBottom: 31,
      [theme.breakpoints.down('sm')]: {
        margin: 'auto auto 31px',
      },

      '& svg': {
        fill: '#013D1F',
        width: 40,
        height: 40,
      },
    },
    columnText: {
      fontSize: 14,
      lineHeight: theme.typography.pxToRem(26),
      opacity: 0.7,
      paddingRight: theme.spacing(1.3),
      color: 'white',
      '&:not(:last-child)': {
        marginBottom: theme.spacing(3),
      },
      '&:focus': {
        outline: 'none!important',
      },
    },
    columnTextNumbers: {
      fontSize: 20,
      lineHeight: theme.typography.pxToRem(29),
      paddingRight: theme.spacing(2),
      '&:last-of-type': {
        marginBottom: 68,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
        lineHeight: theme.typography.pxToRem(24),
      },
      '&:focus': {
        outline: 'none!important',
      },
    },
    imgComplect: {
      position: 'relative',
      display: 'flex!important',
      width: `235px!important`,
      height: 453,
      borderRadius: 40,
      overflow: 'hidden',

      '& >:first-child': {
        position: 'relative',
        width: '100%',
        objectFit: 'contain',
        zIndex: 2,
      },
      '& >:last-child': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        top: 0,
        left: 1,
        zIndex: 1,
      },
    },
    animationContainerWrapper: {
      position: 'relative',
      width: '100%',
      // paddingBottom: 200,
      marginBottom: 200,
      marginTop: 100,
    },
    customStickyHomeNavbar: {
      position: 'sticky',
      top: 0,
      // marginBottom: 'calc(100vh + 110px)',
      marginBottom: '-110px',
      zIndex: 3,
    },
    stickyGreenImgBackground: {
      position: 'sticky',
      top: 0,
    },

    [theme.breakpoints.down('md')]: {
      animationContainerWrapper: {
        marginBottom: 40,
      },
    },
    [theme.breakpoints.down('md')]: {
      animationContainerWrapper: {
        marginBottom: 20,
      },
    },
  })
);

export default useStyles;
