import React from 'react';
import { useStyles } from 'modules/editor/components/CardSectionsView/MainInfo/useStyles';
import { ReactComponent as AvatarPlaceholder } from 'assets/svg/avatar-placeholder.svg';

interface IProps {
  url?: string;
  avatarClass?: any;
  circular?: boolean;
  border?: boolean;
}

export const Avatar: React.FC<IProps> = ({ url, avatarClass, circular, border }) => {
  const classes = useStyles();
  const style = {
    borderRadius: circular ? '50%' : '',
    border: border ? "2px solid white" : '',
  };

  return (
    <div className={avatarClass}>
      { url ? (
        <img id="card_avatar" src={url} alt="Avatar" className={classes.image} style={style}/>
      ) : (
        <AvatarPlaceholder className={classes.image} style={style} />
      )}
    </div>
  );
};
