import React, { FC } from 'react';
import { Snackbar, Portal } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

interface IProps {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  severity: 'success' | 'info' | 'warning' | 'error';
  text: string;
  timeout?: number;
}

export const Notification: FC<IProps> = (props) => {
  const { id, isOpen, onClose, severity, text, timeout = 5000 } = props;

  return (
    <Portal>
      <Snackbar autoHideDuration={timeout} key={id} open={isOpen} onClose={onClose}>
        <Alert onClose={onClose} severity={severity}>
          {text}
        </Alert>
      </Snackbar>
    </Portal>
  );
};

export const Toast = Notification;

export default Notification;
