import React, { useState, useMemo, useCallback, useReducer, ChangeEvent, FC } from 'react';
import DialogWrapper from 'modules/core/containers/DialogWrapper';
import { useStyles } from 'modules/editor/containers/Card/GdprDialog/useStyles';
import { IGdprState, GdprType } from '..';
import { useDialogRef } from '../hooks/useDialogRef';
import { createContactRequestWithRawData } from 'modules/core/api/contact-request-mutations';
import Cookies from 'js-cookie';
import { useTypedSelector } from 'hooks/useTypedSelector';
import CssGreenButton from 'modules/core/components/buttons/roundGreenButton';
import { InputField, MobileField, InputValidState } from 'modules/core/components/fields';
import { validateEmail } from 'modules/core/utils/validate';
import {
  contactDetailsReducer as reducer,
  initalContactDetailsState as initialState,
  ContactDetailsField as Field,
} from 'modules/core/reducers/contactDetailsReducer';
import { GdprOptInMessage } from 'modules/editor/containers/Card/GdprDialog/GdprOptInMessage';

interface IProps {
  cardId: number;
  setValues: React.Dispatch<React.SetStateAction<IGdprState>>;
  onClose?: (evt: any, reason?: any) => void;
  creationType?: string;
}

export const EnterContactDetailsDialog: FC<IProps> = ({ creationType = "default", ...restProps }) => {
  const { cardId, setValues, onClose } = restProps;
  const ref = useDialogRef();
  const classes = useStyles();
  const profile = useTypedSelector((state) => state.auth.profile);
  const { id: accountId } = profile;
  const [isPhoneValid, setIsPhoneValid] = useState<InputValidState>('empty');
  const [values, dispatch] = useReducer(reducer, initialState);
  const { name, email, phone } = values;

  const onChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: Field) => {
    const {
      target: { value },
    } = event;
    dispatch({ type, value });
  }

  const sendAndShow = useCallback(
    async (type: GdprType) => {
      try {
        await createContactRequestWithRawData({
          cardVersionId: cardId,
          preference: type,
          creationType,
          fullName: values.name,
          email: values.email,
          mobilePhone: values.phone,
        });
        setValues({ type, error: false });
      } catch (error) {
        console.error("Contact Details Dialog Error: ", error);
        Cookies.remove(`Gopaperless_gdpr_${cardId}_${accountId}`);
        setValues({ type, error: true });
      }
    },
    [setValues, cardId, accountId, values, creationType]
  );

  const actions = useMemo(() => {
    const validName = values.name.length >= 2;
    const enablePhoneAct = validName && isPhoneValid === 'valid';
    const enableEmailAct = validName && validateEmail(values.email);

    const emailAct = {
      name: 'Email',
      key: 'email',
      action: () => sendAndShow('email'),
      id: 'gdpr_req_by_email',
      disabled: !enableEmailAct,
    };
    const phoneAct = {
      name: 'Phone',
      key: 'phone',
      action: () => sendAndShow('call'),
      id: 'gdpr_req_by_phone',
      disabled: !enablePhoneAct,
    };
    const acts = [emailAct, phoneAct];

    return acts.map((a) => (
      <CssGreenButton classes={{ root: classes.actionButton }} key={a.key} id={a.id} onClick={a.action} disabled={a.disabled}>
        {a.name}
      </CssGreenButton>
    ));
  }, [sendAndShow, classes, values.name, values.email, isPhoneValid]);

  return (
    <DialogWrapper
      dialogId="gdpr"
      ref={ref}
      titleText="Thanks for viewing!"
      subtitleText="This digital business card is powered by Go Paperless."
      actions={actions}
      titleClasses={{ title: classes.title, subtitle: classes.subtitle }}
      actionsClasses={{ root: classes.actionsRoot }}
      onClose={onClose}
    >
      <GdprOptInMessage actions={actions} enteringDetails >
        <form noValidate id="share_form">
          <div className={classes.textField}>
            <InputField
              InputProps={{ className: classes.fullWidth }}
              variant="filled"
              type="text"
              label="Recipient name"
              value={name}
              name="share_form_name"
              onChange={(event) => onChange(event, 'name')}
            />
          </div>
          <div className={classes.textField}>
            <InputField
              variant="filled"
              type="text"
              label="Email"
              value={email}
              name="share_form_email"
              onChange={(event) => onChange(event, 'email')}
            />
          </div>
          <div className={classes.textField}>
            <MobileField
              inputProps={{
                label: 'Mobile number',
                variant: 'filled',
                value: phone,
                id: 'share_form_phone',
              }}
              onChange={(value) => dispatch({ type: 'phone', value })}
              getValid={setIsPhoneValid}
            />
          </div>
        </form>
      </GdprOptInMessage>
    </DialogWrapper>
  );
};
