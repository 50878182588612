import gql from 'graphql-tag';
import { apoloClient } from 'modules/core/constants/client';
import { axiosGet, axiosPost } from 'modules/core/utils/axios';
import { IInvitation, IResellerForm } from 'modules/auth/interfaces/auth';

export const reqAuthApi = axiosGet('/users/sign_in');
export const authenticateApi = axiosPost('/users/sign_in');
export const signupApi = axiosPost('/users');
export const reqSignupConfirmationApi = axiosPost('/users/confirmation', {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});
export const confirmSignupApi = axiosGet('/users/confirmation');

export const profileQuery = gql`
  query {
    account {
      id
      user {
        id
        email
        mobilePhone
        firstName
        lastName
        customerId
        countryCode
        throughReseller
        eligibleForDiscountUntil
        timeZone
        subscribeGdprNews
        hasBeenSubscribed
        currentAccount {
          id
          type
          subscribed
          accountName
          accountDesc
        }
        accounts {
          id
          type
          subscribed
          accountName
          accountDesc
        }
      }
      activeSubscriptions {
        status
      }
      canPublishCard
      subscribed
      type
      resellerId
      canChangeUrl
      accountName
      accountDesc
      hasBeenSubscribed
      canEditAboutSection
      canEditOfferSection
      canEditVideoSection
      canEditGallerySection
      canEditLocationSection
      canEditPromotionSection
    }
  }
`;

export async function updateUserTimeZone(timeZone: string) {
  try {
    await apoloClient.mutate<{ timeZone: string }>({
      variables: { timeZone },
      mutation: gql`
        mutation UpdateUserTimeZone($timeZone: String!) {
          updateUserTimeZone(attributes: { timeZone: $timeZone }) {
            errors
          }
        }
      `,
    });
  } catch (err) {
    throw err;
  }
}

export async function updateUserThroughCampaign(throughCampaign: string) {
  try {
    await apoloClient.mutate<{ throughCampaign: string }>({
      variables: { throughCampaign },
      mutation: gql`
        mutation UpdateUserThroughCampaign($throughCampaign: String!) {
          updateUserThroughCampaign(attributes: { throughCampaign: $throughCampaign }) {
            errors
          }
        }
      `,
    });
  } catch (err) {
    throw err;
  }
}

export async function switchAccountApi(accountId: number) {
  try {
    await apoloClient.mutate<{ accountId: number }>({
      variables: { accountId },
      mutation: gql`
        mutation SwitchAccount($accountId: ID!) {
          switchAccount(accountId: $accountId) {
            errors
          }
        }
      `,
    });
  } catch (err) {
    throw err;
  }
}

export async function createAccountApi() {
  try {
    const { data } = await apoloClient.mutate({
      variables: {},
      mutation: gql`
        mutation CreateAccount {
          createAccount {
            errors
          }
        }
      `,
    });
    if (data && data.createAccount && data.createAccount.errors.length) {
      throw data.createAccount.errors;
    }
    return data;
  } catch (err) {
    throw err;
  }
}

// TODO: refactor account gql query into account fragment
export async function createResellerApi(invitation: IInvitation, userAttributes: IResellerForm) {
  try {
    const { data } = await apoloClient.mutate({
      variables: {
        invitationId: invitation.inviteToken,
        firstName: userAttributes.firstName,
        lastName: userAttributes.lastName,
        email: userAttributes.email,
        mobilePhone: userAttributes.mobilePhone,
        countryCode: userAttributes.country,
        acceptedNdaOn: userAttributes.accepted_nda_on,
        acceptedPrivacyTermsOn: userAttributes.accepted_privacy_terms_on,
        acceptedResellerAgreementOn: userAttributes.accepted_reseller_agreement_on,
        subscribeGdprNews: userAttributes.subscribe_gdpr_news,
      },
      mutation: gql`
        mutation createReseller(
          $invitationId: ID!
          $firstName: String!
          $lastName: String!
          $email: String!
          $mobilePhone: String
          $countryCode: String!
          $acceptedNdaOn: Boolean
          $acceptedPrivacyTermsOn: Boolean!
          $acceptedResellerAgreementOn: Boolean!
          $subscribeGdprNews: Boolean!
        ) {
          createReseller(
            attributes: {
              invitationId: $invitationId
              userAttributes: {
                firstName: $firstName
                lastName: $lastName
                email: $email
                mobilePhone: $mobilePhone
                countryCode: $countryCode
                acceptedNdaOn: $acceptedNdaOn
                acceptedPrivacyTermsOn: $acceptedPrivacyTermsOn
                acceptedResellerAgreementOn: $acceptedResellerAgreementOn
                subscribeGdprNews: $subscribeGdprNews
              }
            }
          ) {
            account {
              id
              user {
                id
                email
                mobilePhone
                firstName
                lastName
                customerId
                countryCode
                throughReseller
                eligibleForDiscountUntil
                timeZone
                subscribeGdprNews
                hasBeenSubscribed
                currentAccount {
                  id
                  type
                  subscribed
                  accountName
                  accountDesc
                }
                accounts {
                  id
                  type
                  subscribed
                  accountName
                  accountDesc
                }
              }
              activeSubscriptions {
                status
              }
              canPublishCard
              subscribed
              type
              resellerId
              canChangeUrl
              accountName
              accountDesc
              hasBeenSubscribed
            }
            auth
            status
            errors
          }
        }
      `,
    });
    if (data && data.createAccount && data.createAccount.errors.length) {
      throw data.createAccount.errors;
    }
    return data;
  } catch (err) {
    throw err;
  }
}
