import { makeStyles, Theme } from '@material-ui/core';
import { brightGrey } from 'themes/colors';

const radioButtonStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: 24,
    marginLeft: -8,
    marginBottom: theme.spacing(0.8),
    '& .MuiFormControlLabel-root': {
      justifyContent: 'flex-start',
      marginLeft: '0',
    },
    '&.noVerticalMargin': {
      marginTop: '0',
      marginBottom: '0',
    },
  },
  radioButton: {
    '&:not(:last-child)::after': {
      content: '""',
      display: 'block',
      height: '80%',
      width: '1px',
      background: brightGrey,
    },
    '& .MuiIconButton-root': {
      padding: '4px',
    },
    '& .MuiFormControlLabel-label': {
      color: 'white',
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: theme.typography.pxToRem(20),
      paddingRight: '24px',
    },
    '& .MuiRadio-colorSecondary.Mui-checked ': {
      color: '#76B83F',
    },
    '& .MuiRadio-root': {
      color: 'rgba(255,255,255,0.2)',
    },
    '& .MuiIconButton-colorSecondary:hover': {
      backgroundColor: 'rgba(118, 184, 63, 0.2)',
    },
    '&.light > span': {
      fontFamily: 'F37 Ginger Light',
    },
    '&.light': {
      marginRight: 8,
    },
  },
}));

export default radioButtonStyles;
