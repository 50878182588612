import { ILocationSection, ILocationSectionOpeningTime } from 'modules/editor/graphql/gql';
import { Weekdays } from 'modules/core/types';

export class LocationSection implements ILocationSection {
  public city?: string;
  public country?: string;
  public id?: number;
  public display12h?: boolean;
  public openingTimes: ILocationSectionOpeningTime[] = [];
  public postcode?: string;
  public state?: string;
  public streetAddress?: string;
  public streetAddress2?: string;
  public lat?: string | null;
  public lng?: string | null;

  constructor(section?: ILocationSection) {
    if (section) {
      this.city = section.city;
      this.country = section.country;
      this.id = section.id;
      this.display12h = section.display12h || false;
      this.openingTimes = section.openingTimes
        ? section.openingTimes.map((ot) => new LocationSectionOpeningTime(ot))
        : [];
      this.postcode = section.postcode;
      this.state = section.state;
      this.streetAddress = section.streetAddress;
      this.streetAddress2 = section.streetAddress2;
      if (!this.city && !this.country && !this.postcode && !this.state && !this.streetAddress && !this.streetAddress2) {
        this.lat = null;
        this.lng = null;
      } else {
        this.lat = section.lat;
        this.lng = section.lng;
      }
    }
  }
}

export class LocationSectionOpeningTime implements ILocationSectionOpeningTime {
  public id: number;
  public fromDay: Weekdays;
  public toDay: Weekdays;
  public fromHour: string | number;
  public toHour: string | number;

  constructor(ot: ILocationSectionOpeningTime) {
    this.id = ot.id;
    this.fromDay = ot.fromDay;
    this.toDay = ot.toDay;
    this.fromHour = Number(ot.fromHour) || 0;
    this.toHour = Number(ot.toHour) || 24;
  }
}
