import { RefObject, useRef, useEffect } from 'react';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { E_CARD_STEPS } from 'modules/editor/constants/enums';

export function useScroll(
  section: E_CARD_STEPS,
  containerRef: RefObject<HTMLDivElement>,
  display: boolean
): RefObject<HTMLDivElement> {
  const { step } = useTypedSelector((state) => state.editorSteps.currentStep);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (step === section) {
      // gets scroll out of direct event loop, otherwise it doesn't work everywhere
      Promise.resolve().then(() => {
        if (ref.current && containerRef.current) {
          // for location need to scroll to the bottom
          const top =
            section === E_CARD_STEPS.LOCATION ? containerRef.current.scrollHeight : ref.current.offsetTop - 20;
          containerRef.current.scrollTo({ top, behavior: 'smooth' });
        }
      });
    }
  }, [step, section, containerRef, display]);

  return ref;
}
