import Hidden from '@material-ui/core/Hidden';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import AccordionWrapper, { useIconStyles } from 'modules/core/components/Accordion';
import React, { useState } from 'react';
import { cwsStyles } from './styles';
import { Remove, Add } from '@material-ui/icons';
import SidemenuWrapper, { ISidemenuItem } from 'modules/core/components/SidemenuWrapper';
import { ID } from 'modules/core/types';

interface IProps {
  menuItems: ISidemenuItem[];
  optionalItems?: ISidemenuItem[];
}

const ContentWithSidemenu: React.FC<IProps & WithStyles<typeof cwsStyles>> = ({
  classes,
  menuItems,
  optionalItems,
}) => {
  const [expanded, setExpanded] = useState<ID | boolean>(false);
  const icoClasses = useIconStyles();
  return (
    <div className={classes.root}>
      <Hidden smDown>
        <SidemenuWrapper items={menuItems} optionalItems={optionalItems} />
      </Hidden>
      <Hidden mdUp>
        {menuItems.map((item, idx) => (
          <AccordionWrapper
            OpenedIcon={<Remove className={icoClasses.minusIcoGray} />}
            ClosedIcon={<Add />}
            key={idx}
            num={item.letter}
            title={item.title}
            exClass={classes.accordeonPanel}
            expanded={expanded === item.letter}
            onChange={setExpanded}
          >
            <item.component />
          </AccordionWrapper>
        ))}
      </Hidden>
    </div>
  );
};

export default withStyles(cwsStyles)(ContentWithSidemenu);
