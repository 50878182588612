import { withStyles } from '@material-ui/styles';
import { TextField } from '@material-ui/core';

export const CssTextField = withStyles({
  root: {
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid rgba(255,255,255,0.4)',
    },
  },
})(TextField);
