import { ICardVersion, ICard } from 'modules/editor/graphql/gql';
import { AboutSection } from './sections/about-section';
import { GallerySection } from './sections/gallery-section';
import { CardStyleSection } from './sections/card-style';
import { VideoSection } from './sections/video-section';
import { NewsSection } from './sections/news-section';
import { LocationSection } from './sections/location-section';
import { PromotionSection } from './sections/promo-section';

export class CardVersion implements ICardVersion {
  public avatarUrl?: string;
  public businessName?: string;
  public contactDetailsHeaderShow?: boolean;
  public email?: string;
  public facebookUrl?: string;
  public facebookUrlShow: boolean = false;
  public instagramUrl?: string;
  public instagramUrlShow: boolean = false;
  public pinterestUrl?: string;
  public pinterestUrlShow: boolean = false;
  public fullName?: string;
  public iconUrl16?: string;
  public iconUrl32?: string;
  public iconUrl512?: string;
  public isProcessing?: boolean;
  public vCardImageBase64?: string;
  public vCardImageContentType?: string;
  public id!: number;
  public currentForCardId!: number;
  public draft: boolean = false;
  public throughReseller?: string;
  public jobTitle?: string;
  public qualifications?: string;
  public linkedInUrl?: string;
  public linkedInUrlShow: boolean = false;
  public logoUrl?: string;
  public mobilePhone?: string;
  public secondPhoneNumber?: string;
  public publishedTime?: Date;
  public tagLine?: string;
  public twitterUrl?: string;
  public twitterUrlShow: boolean = false;
  public whatsappUrl?: string;
  public whatsappUrlShow: boolean = false;
  public blogUrl?: string;
  public blogUrlShow: boolean = false;
  public podcastUrl?: string;
  public podcastUrlShow: boolean = false;
  public snapchatUrl?: string;
  public snapchatUrlShow: boolean = false;
  public youtubeUrl?: string;
  public youtubeUrlShow: boolean = false;
  public card: Partial<ICard> = { account: {} };
  public aboutSection: AboutSection;
  public gallerySection: GallerySection;
  public cardStyle: CardStyleSection;
  public videoSection: VideoSection;
  public newsSection: NewsSection;
  public locationSection: LocationSection;
  public promotionSection: PromotionSection;

  constructor(card?: Partial<ICardVersion>) {
    if (card) {
      this.avatarUrl = card.avatarUrl;
      this.businessName = card.businessName;
      this.contactDetailsHeaderShow = card.contactDetailsHeaderShow || false;
      this.email = card.email;
      this.facebookUrl = card.facebookUrl;
      this.facebookUrlShow = card.facebookUrlShow || false;
      this.twitterUrl = card.twitterUrl;
      this.twitterUrlShow = card.twitterUrlShow || false;
      this.instagramUrl = card.instagramUrl;
      this.instagramUrlShow = card.instagramUrlShow || false;
      this.fullName = card.fullName;
      this.iconUrl16 = card.iconUrl16;
      this.iconUrl32 = card.iconUrl32;
      this.iconUrl512 = card.iconUrl512;
      this.isProcessing = card.isProcessing;
      this.vCardImageBase64 = card.vCardImageBase64;
      this.vCardImageContentType = card.vCardImageContentType;
      this.id = card.id as number;
      this.currentForCardId = card.currentForCardId as number;
      this.draft = card.draft || false;
      this.throughReseller = card.throughReseller;
      this.jobTitle = card.jobTitle;
      this.qualifications = card.qualifications;
      this.linkedInUrl = card.linkedInUrl;
      this.linkedInUrlShow = card.linkedInUrlShow || false;
      this.logoUrl = card.logoUrl;
      this.mobilePhone = card.mobilePhone;
      this.secondPhoneNumber = card.secondPhoneNumber;
      this.publishedTime = card.publishedTime ? new Date(card.publishedTime as string) : undefined;
      this.tagLine = card.tagLine;
      this.twitterUrl = card.twitterUrl;
      this.twitterUrlShow = card.twitterUrlShow || false;
      this.whatsappUrl = card.whatsappUrl;
      this.whatsappUrlShow = card.whatsappUrlShow || false;
      this.blogUrl = card.blogUrl;
      this.blogUrlShow = card.blogUrlShow || false;
      this.podcastUrl = card.podcastUrl;
      this.podcastUrlShow = card.podcastUrlShow || false;
      this.snapchatUrl = card.snapchatUrl;
      this.snapchatUrlShow = card.snapchatUrlShow || false;
      this.youtubeUrl = card.youtubeUrl;
      this.youtubeUrlShow = card.youtubeUrlShow || false;
      this.pinterestUrl = card.pinterestUrl;
      this.pinterestUrlShow = card.pinterestUrlShow || false;
      this.card = card.card
        ? card.card
        : {
            account: {},
          };
      this.aboutSection = new AboutSection(card.aboutSection);
      this.gallerySection = new GallerySection(card.gallerySection);
      this.cardStyle = new CardStyleSection(card.cardStyle);
      this.videoSection = new VideoSection(card.videoSection);
      this.newsSection = new NewsSection(card.newsSection);
      this.locationSection = new LocationSection(card.locationSection);
      this.promotionSection = new PromotionSection(card.promotionSection);
    } else {
      this.aboutSection = new AboutSection();
      this.gallerySection = new GallerySection();
      this.cardStyle = new CardStyleSection();
      this.videoSection = new VideoSection();
      this.newsSection = new NewsSection();
      this.card = {
        account: {},
      };
      this.locationSection = new LocationSection();
      this.promotionSection = new PromotionSection();
    }
  }
}
