import { Box, Typography, InputAdornment } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { IAccountProfile } from 'modules/auth/interfaces/auth';
import { InputField, UploadImage } from 'modules/core/components/fields';
import { OverlayProgress } from 'modules/core/components/progress/OverlayProgress';
import { Validation } from 'modules/core/types';
import { IFileReadResult } from 'modules/core/utils';
import { updatePersonalDetails } from 'modules/editor/api/card-mutations';
import { ICardVersion } from 'modules/editor/graphql/gql';
import { IPersonalDetailAtributes } from 'modules/editor/graphql/mutations';
import { PersonalDetailsAtributes } from 'modules/editor/models/atributes/personal-detail-atributes';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { IReduxState } from 'store';
import Clear from 'modules/editor/components/CardSectionEditors/Clear';
import { clearSection, isDisabled, updateField } from './hooks';
import { MobileField } from 'modules/core/components/fields/MobileField';
import { Clipboard, useClipboard } from 'modules/core/components/CopyCardURL';
import { E_ACCOUNT_TYPE } from 'modules/auth/constants/enums';

interface IPersonalDetails {
  updatePersonalDetails: (personalDetailsAtr: IPersonalDetailAtributes, fields: string[]) => Promise<void>;
  card: ICardVersion;
  profile: Partial<IAccountProfile>;
  classes?: any;
  fields: string[];
}

const cardUrlClearValue = (value?: string) => {
  if (!value) {
    return '';
  }
  return value.replace(/[^0-9a-zA-Z-]/gi, '').toLowerCase();
};

export const PersonalDetails = connect(
  (state: IReduxState) => ({
    card: state.currentVersion.currentVer,
    profile: state.auth.profile,
    fields: state.editorSteps.personalFields,
  }),
  { updatePersonalDetails }
)((props: IPersonalDetails) => {
  const { card, classes, updatePersonalDetails: saveChanges, profile, fields } = props;
  const reqFields = useMemo(
    () => [
      'emailAddress',
      'fullName',
      'jobTitle',
      'qualifications',
      'mobileNumber',
      'secondPhoneNumber',
      'profilePhotoUrl',
      'cardUrl',
      'urlSlug',
      'contactDetailsHeaderShow',
    ],
    []
  );
  const [overlay, setOverlay] = useState(false);

  const [persDetails, setPersDetails] = useState<PersonalDetailsAtributes>(
    new PersonalDetailsAtributes({
      ...card,
      email: card.email || profile.email, // TODO::This is values always will display for user, even he deleted it
      fullName: card.fullName || `${profile.firstName} ${profile.lastName}`,
      // mobilePhone: card.mobilePhone, // This field is optional. it should be set for the initial card version by the backend if profile.mobilePhone is present
    })
  );
  const [errors, setErrors] = useState<Validation<IPersonalDetailAtributes>>({});
  const { success: successCopy, error: errorCopy, onSuccessCopy, onErrorCopy } = useClipboard();

  useEffect(() => {
    persDetails.cardVersionId = card.id;
    if (!profile.canChangeUrl) {
      persDetails.cardUrl = card.card ? card.card.urlSlug : persDetails.cardUrlFull;
      persDetails.cardUrlFull = card.card ? `${window.location.host}/${persDetails.cardUrl}` : persDetails.cardUrlFull;
    }
    setPersDetails(persDetails.nextState(new PersonalDetailsAtributes(card)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card.id, card.fullName, card.email, card.mobilePhone, card.secondPhoneNumber, card.card.urlSlug, card.contactDetailsHeaderShow]);

  const saveField = useCallback(
    async (field: keyof IPersonalDetailAtributes) => {
      if (!errors[field]) {
        try {
          await saveChanges(
            {
              cardVersionId: persDetails.cardVersionId,
              [field]: persDetails[field] || null,
            },
            reqFields
          );
          if (errors[field]) {
            setErrors({ ...errors, [field]: undefined });
          }
        } catch (error) {
          if (error.graphQLErrors && error.graphQLErrors.length) {
            setErrors({ ...errors, [field]: error.graphQLErrors[0].message });
          }
        }
      }
    },
    [saveChanges, setErrors, errors, persDetails, reqFields]
  );

  const onClear = useCallback(() => {
    const data: IPersonalDetailAtributes = {
      ...persDetails.clear(true, fields).toJSON(),
      cardUrl: profile.canChangeUrl ? null : card.card.urlSlug,
    };

    const cleared = persDetails.clear(false, [
      profile.canChangeUrl ? '' : 'cardUrl',
      profile.canChangeUrl ? '' : 'cardUrlFull',
    ]);
    setPersDetails(cleared);

    saveChanges(data, reqFields);
    setErrors({});
  }, [persDetails, saveChanges, reqFields, fields, card.card.urlSlug, profile.canChangeUrl]);

  const clearPhone = useCallback((field: "mobileNumber" | "secondPhoneNumber") => {
    persDetails[field] = undefined;

    setPersDetails(persDetails);
    saveChanges({
      cardVersionId: persDetails.cardVersionId,
      [field]: persDetails[field] || null,
    }, reqFields);
  }, [saveChanges, setPersDetails, persDetails, reqFields]);

  const uploadProfilePhoto = useCallback(
    async (res: IFileReadResult, cropParams?: any) => {
      persDetails.profilePhotoBase64 = res.base64data;
      persDetails.profilePhotoName = res.filename;
      const atributes = persDetails.nextState(persDetails);
      setPersDetails(atributes);
      const additionalParams = cropParams ? { ...cropParams } : {};
      setOverlay(true);
      try {
        await saveChanges(
          {
            cardVersionId: atributes.cardVersionId,
            profilePhotoBase64: atributes.profilePhotoBase64,
            profilePhotoName: atributes.profilePhotoName,
            ...additionalParams,
          },
          reqFields
        );
        persDetails.profilePhotoBase64 = undefined;
        persDetails.profilePhotoName = undefined;
        setPersDetails(atributes.nextState(atributes));
      } catch (error) {
        console.warn(error);
      }
      setOverlay(false);
    },
    [setPersDetails, saveChanges, persDetails, reqFields]
  );

  const clearImg = useCallback(async () => {
    persDetails.profilePhotoBase64 = undefined;
    persDetails.profilePhotoName = undefined;
    const atributes = persDetails.nextState(persDetails);
    setPersDetails(atributes);
    try {
      await saveChanges(
        {
          cardVersionId: atributes.cardVersionId,
          profilePhotoBase64: null,
          profilePhotoName: null,
        },
        reqFields
      );
      persDetails.profilePhotoBase64 = undefined;
      persDetails.profilePhotoName = undefined;
      setPersDetails(atributes.nextState(atributes));
    } catch (error) {
      console.warn(error);
    }
  }, [setPersDetails, saveChanges, persDetails, reqFields]);

  return (
    <>
      <Box display="flex" alignItems="center" mb={1}>
        <Typography className={classes.sectionHeader}>Personal details</Typography>
        {fields.length === 7 ? null : (
          <Box className={classes.clearBlock}>
            <Clear
              id="personal_editor_clear"
              ico={<ClearIcon fontSize="inherit" />}
              onConfirm={() => clearSection(persDetails, onClear, setPersDetails)}
            >
              Clear section
            </Clear>
          </Box>
        )}
      </Box>
      <Box className={classes.fieldBlock}>
        <Box pb={4}>
          <UploadImage
            name="personal-details-profilePhoto-upload"
            imgUrl={card.avatarUrl}
            disabled={isDisabled(fields, 'profilePhotoName')}
            size="small"
            title="Profile Photo"
            firstDescription={'Used on your '+(profile.type !== E_ACCOUNT_TYPE.Reseller ? 'card and messaging' : 'details display')}
            secondDescription="Minimum 256px width and height"
            uploadImg={(res) => uploadProfilePhoto(res)}
            alowClear={true}
            onClear={() => clearImg()}
            crop
            skipCropForGif
          />
        </Box>
        <div className={classes.fieldBlockSm}>
          <Typography className={classes.heading}>Full name</Typography>
          <InputField
            name="personal-details-fullName"
            type="text"
            label="Enter your name"
            disabled={isDisabled(fields, 'fullName')}
            error={!!errors.fullName}
            onChange={(e) => {
              updateField({
                model: persDetails,
                prop: 'fullName',
                val: e.target.value,
                setState: setPersDetails,
                errors,
                setErrors,
              });
            }}
            onBlur={() => saveField('fullName')}
            value={persDetails.fullName as string}
            variant="filled"
          />
        </div>
        {isDisabled(fields, 'qualifications') ? null : (
          <div className={classes.fieldBlockSm}>
            <Typography className={classes.heading}>Qualifications</Typography>
            <InputField
              name="personal-details-qualifications"
              type="text"
              label="Enter your qualifications"
              disabled={isDisabled(fields, 'qualifications')}
              onChange={(e) =>
                updateField({ model: persDetails, prop: 'qualifications', val: e.target.value, setState: setPersDetails })
              }
              onBlur={() => saveField('qualifications')}
              value={persDetails.qualifications as string}
              variant="filled"
            />
          </div>
        )}
        {isDisabled(fields, 'jobTitle') ? null : (
          <div className={classes.fieldBlockSm}>
            <Typography className={classes.heading}>Job title</Typography>
            <InputField
              name="personal-details-jobTitle"
              type="text"
              label="Enter your title"
              disabled={isDisabled(fields, 'jobTitle')}
              onChange={(e) =>
                updateField({ model: persDetails, prop: 'jobTitle', val: e.target.value, setState: setPersDetails })
              }
              onBlur={() => saveField('jobTitle')}
              value={persDetails.jobTitle as string}
              variant="filled"
            />
          </div>
        )}
        <div className={classes.fieldBlockSm}>
          <Typography className={classes.heading}>Email address</Typography>
          <InputField
            name="personal-details-emailAddress"
            type="email"
            label="Enter your email"
            disabled={isDisabled(fields, 'emailAddress')}
            error={!!errors.emailAddress}
            onChange={(e) =>
              updateField({
                model: persDetails,
                prop: 'emailAddress',
                val: e.target.value,
                setState: setPersDetails,
                errors,
                setErrors,
              })
            }
            onBlur={() => saveField('emailAddress')}
            value={persDetails.emailAddress as string}
            variant="filled"
          />
        </div>
        <div className={classes.fieldBlockSm}>
          <Typography className={classes.heading}>Mobile number</Typography>
          <MobileField
            exClass="dark"
            inputProps={{
              label: 'Enter your mobile number',
              variant: 'filled',
              value: persDetails.mobileNumber,
              error: !!errors.mobileNumber,
              helperText: errors.mobileNumber,
              disabled: isDisabled(fields, 'mobileNumber'),
              id: `personal-details-mobileNumber`,
            }}
            onChange={(phone?: string) =>
              updateField({
                model: persDetails,
                prop: 'mobileNumber',
                val: phone,
                setState: setPersDetails,
                setErrors,
                errors,
              })
            }
            onBlur={() => saveField('mobileNumber')}
            disabled={isDisabled(fields, 'mobileNumber')}
            clear={() => clearPhone('mobileNumber')}
          />
        </div>
        <div className={classes.fieldBlockSm}>
          <Typography className={classes.heading}>Alternative number</Typography>
          <MobileField
            exClass="dark"
            inputProps={{
              label: 'Enter an alternative number',
              variant: 'filled',
              value: persDetails.secondPhoneNumber,
              error: !!errors.secondPhoneNumber,
              helperText: errors.secondPhoneNumber,
              disabled: isDisabled(fields, 'secondPhoneNumber'),
              id: `personal-details-secondPhoneNumber`,
            }}
            onChange={(phone?: string) =>
              updateField({
                model: persDetails,
                prop: 'secondPhoneNumber',
                val: phone,
                setState: setPersDetails,
                setErrors,
                errors,
              })
            }
            onBlur={() => saveField('secondPhoneNumber')}
            disabled={isDisabled(fields, 'secondPhoneNumber')}
            clear={() => clearPhone('secondPhoneNumber')}
          />
        </div>
      </Box>
      <Box mb={2}>
        {profile.type !== E_ACCOUNT_TYPE.Reseller && <Typography className={classes.sectionHeader}>Card URL</Typography>} 
        {profile.type === E_ACCOUNT_TYPE.Reseller && <Typography className={classes.sectionHeader}>Referral URL</Typography>} 
      </Box>
      <Box className={classes.fieldBlock}>
        {profile.type !== E_ACCOUNT_TYPE.Reseller && <Typography className={classes.heading}>Can't be changed once card is published</Typography>}
        {profile.type === E_ACCOUNT_TYPE.Reseller && <Typography className={classes.heading}>Can't be changed once published</Typography>}
        <InputField
          name="personal-details-cardUrl"
          label="Edit URL"
          disabled={isDisabled(fields, 'cardUrl') || !profile.canChangeUrl}
          onChange={(e) =>
            updateField({
              model: persDetails,
              prop: 'cardUrl',
              setState: setPersDetails,
              val: cardUrlClearValue(e.target.value as string),
              errors,
              setErrors,
            })
          }
          exClass="_text-adorment"
          value={profile.canChangeUrl ? (persDetails.cardUrl as string) : persDetails.cardUrlFull}
          variant="filled"
          onBlur={() => saveField('cardUrl')}
          error={!!errors.cardUrl}
          helperText={errors.cardUrl}
          InputProps={{
            startAdornment: profile.canChangeUrl ? (
              <InputAdornment color="#fff" position="start">
                <span>{`${window.location.host}/`}</span>
              </InputAdornment>
            ) : null,
          }}
        />
        {profile.canChangeUrl ? null : (
          <Box display="flex" alignItems="center">
            <Clipboard
              id="personal-details-clipboard-url"
              url={`${window.location.host}/${card.card.urlSlug}` as string}
              onError={onErrorCopy}
              onSuccess={onSuccessCopy}
              className={classes.clipboardWrapper}
            >
              <Box component="span" className={classes.clipboard}>
                Copy to clipboard
              </Box>
            </Clipboard>
            {successCopy ? <Box color="#7ABC42">Copied!</Box> : null}
            {errorCopy ? <Box color="#f44336">Error!</Box> : null}
          </Box>
        )}
      </Box>
      <OverlayProgress isProgress={overlay} />
    </>
  );
});
