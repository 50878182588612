import countryList from 'react-select-country-list';

const FIRST = ['gb', 'us', 'au', 'nz', 'ca', 'es', 'fr', 'de', 'ru'];

export const countries = () => {
  const COUNTRIES = countryList().getData();

  FIRST.forEach((elem: any, i: any) => {
    const res = COUNTRIES.find((el: any) => el.value.toLowerCase() === elem);
    if (res) {
      const ind = COUNTRIES.indexOf(res);
      COUNTRIES.splice(ind, 1);
      COUNTRIES.splice(i, 0, res);
    }
  })

  return COUNTRIES;
};
