import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import NumberIteration from 'modules/core/components/NumberItaration';
import React, { useCallback, useState, useEffect, ReactNode } from 'react';
import { accordionStyles } from './styles';
import { ID } from 'modules/core/types';

export { useIconStyles } from './styles';

interface IAccordionWrapperProps {
  OpenedIcon: ReactNode;
  ClosedIcon: ReactNode;
  expanded?: boolean;
  title: string;
  num: ID;
  children: ReactNode;
  disabled?: boolean;
  className?: string;
  exClass?: any;
  onChange?: (numberId: ID | false) => void;
}

const AccordionWrapper: React.FC<IAccordionWrapperProps> = ({
  OpenedIcon,
  ClosedIcon,
  children,
  num,
  title,
  exClass,
  expanded,
  onChange,
  disabled,
  className,
}) => {
  const classes = accordionStyles();
  const [isOpen, togglePanel] = useState(expanded || false);

  useEffect(() => togglePanel(expanded || false), [expanded]);

  const handlePanelChange = useCallback(
    (evt: React.ChangeEvent<{}>, isExpanded: boolean) => {
      togglePanel(isExpanded);
      if (onChange) {
        onChange(isExpanded ? num : false);
      }
    },
    [onChange, num]
  );
  return (
    <div className={clsx(classes.root, className, exClass)}>
      <ExpansionPanel expanded={isOpen} disabled={disabled} onChange={handlePanelChange}>
        <ExpansionPanelSummary
          className={clsx(isOpen ? classes.expansionActive : classes.expansionCurrent, exClass)}
          expandIcon={isOpen ? OpenedIcon : ClosedIcon}
          aria-controls="panel1a-content"
        >
          <NumberIteration numbers={num as number} />
          <Typography className={clsx(classes.heading, exClass)}>{title}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={clsx(classes.details, exClass)} color="primary">
          {children}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

export default AccordionWrapper;
