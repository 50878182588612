import React, { useEffect, useState } from 'react';
import useStyles from 'modules/dashboard/components/Dashboard/styles';
import { Box, Typography } from '@material-ui/core';
import { IPayoutBatch } from 'modules/dashboard/graphql/gql';
import { getCommissionHistory } from 'modules/dashboard/api/resellerDashboardApi';
import CircularIndeterminate from 'modules/core/components/progress';
import Amount from 'modules/dashboard/components/ResellerDashboard/amount';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

const PayoutSummary: React.FC = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [batches, setBatches] = useState<IPayoutBatch[]>([]);

  useEffect(() => {
    setLoading(true);
    getCommissionHistory()
      .then((data) => {
        setBatches(data);
      })
      .finally(() => {
        setLoading(false);
      })
  }, []);

  const slidesPerView = (() => {
    switch (true) {
      case window.innerWidth < 400:
        return 2;
      case window.innerWidth < 640:
        return 3;
      case window.innerWidth < 960:
        return 4;
      case window.innerWidth < 1200:
        return 5;
      case window.innerWidth >= 1200:
        return 6;
      default:
        return 2;
    }
  })();

  const slides = batches.length && batches.map((batch: IPayoutBatch, index) => (
    <SwiperSlide key={index}>
      <Box className={classes.payoutBatch} >
        <Typography className={classes.payoutBatchDate}>{batch.payoutDate()}</Typography>
        <p className={classes.payoutBatchAmount}>
          <Amount  amount={batch.amount} ccy={batch.currency} />
        </p>
      </Box>
    </SwiperSlide>
  ))

  if (!loading && !batches.length) {
    return null;
  } else {
    return (
      <div id="payoutBatches" className={classes.payoutBatches}>
        { loading ? (
          <CircularIndeterminate />
        ) : (
          <div className={classes.payoutBatchesSwipeContainer}>
            <Swiper
              updateOnWindowResize
              centeredSlides={true}
              spaceBetween={30}
              slidesPerView={slidesPerView}
              dir="rtl"
            >
              {slides}
            </Swiper>
          </div>
        )}
      </div>
    );
  }
};

export default PayoutSummary;
