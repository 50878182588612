import React, { ChangeEvent } from 'react';
import Typography from '@material-ui/core/Typography';
import { InputField } from 'modules/core/components/fields';
import { IShareForm } from 'modules/core/interfaces';
import { ContactDetailsField as Field } from 'modules/core/reducers/contactDetailsReducer';
import { useStyles, useShareCardStyles } from '../styles';
import { useTypedSelector } from 'hooks/useTypedSelector';

interface IProps {
  values: IShareForm;
  onChange: (event: ChangeEvent<any>, type: Field) => void
};

const ShareCardByEmail: React.FC<IProps> = ({ values, onChange }) => {
  const { fullWidth, textField } = useStyles();
  const classes = useShareCardStyles();

  const { referrerName, name, email, message } = values;
  const isAuth = useTypedSelector((state) => state.auth.isAuthenticated);

  return (
    <>
      <Typography id={`share_dialog_title`} align="center" className={classes.subtitle}>Enter recipient details below</Typography>

        <form noValidate id="share_form">
          {!isAuth && (
            <div className={textField}>
              <InputField
                InputProps={{ className: fullWidth }}
                variant="filled"
                type="text"
                label="Your name"
                value={referrerName}
                name="share_form_referrer"
                onChange={(event) => onChange(event, 'referrerName')}
              />
            </div>
          )}
          <div className={textField}>
            <InputField
              InputProps={{ className: fullWidth }}
              variant="filled"
              type="text"
              label="Recipient name"
              value={name}
              name="share_form_name"
              onChange={(event) => onChange(event, 'name')}
            />
          </div>
          <div className={textField}>
            <InputField
              variant="filled"
              type="text"
              label="Email"
              value={email}
              name="share_form_email"
              onChange={(event) => onChange(event, 'email')}
            />
          </div>
          {/*<div className={textField}>
            <MobileField
              inputProps={{
                label: 'Mobile number',
                variant: 'filled',
                value: phone,
                id: 'share_form_phone',
              }}
              onChange={(value) => dispatch({ type: 'phone', value: value || '' })}
             getValid/>
          </div>*/}
          <div className={textField}>
            <InputField
              variant="filled"
              type="text"
              label="Enter optional message"
              value={message}
              name="share_form_message"
              onChange={(event) => onChange(event, 'message')}
            />
          </div>
        </form>
    </>
  );
};

export default ShareCardByEmail;
