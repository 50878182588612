import { useTypedSelector } from 'hooks/useTypedSelector';
import { useMemo } from 'react';

export function useIsMine(): boolean {
  const isMine = useTypedSelector((state): boolean => {
    const { account } = state.cardVersionReducer.publicVersion.card;
    if (account) {
    	// TODO: make sure is mine returns true for any of the user's accounts
    	// 
      // return state.auth.profile.user.accounts.include?(account);
      // 
      return state.auth.profile.id === account.id;
    }
    return false;
  });
  return useMemo(() => isMine, [isMine]);
}
