export interface IAppLocalStore {
  traning_is_finish?: boolean;
  [key: string]: any;
}

export class LocalStorageHelper {
  public static STORE_KEY = 'app_store';

  public static setData(data: IAppLocalStore) {
    localStorage.setItem(LocalStorageHelper.STORE_KEY, JSON.stringify(data));
  }

  public static getData(): IAppLocalStore {
    const store = localStorage.getItem(LocalStorageHelper.STORE_KEY);
    if (store) {
      return JSON.parse(store) as IAppLocalStore;
    } else {
      return {};
    }
  }

  public static updateData(prop: keyof IAppLocalStore, value: boolean | undefined) {
    const data = LocalStorageHelper.getData();
    data[prop] = value;
    LocalStorageHelper.setData(data);
  }
  public static getValue(prop: keyof IAppLocalStore) {
    const data = LocalStorageHelper.getData();
    return data[prop];
  }

  public static clear(): void {
    localStorage.removeItem(LocalStorageHelper.STORE_KEY);
  }
}
