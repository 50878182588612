import { IAboutSectionLinkAtributes, IAboutSectionAtributes } from 'modules/editor/graphql/mutations';
import { ICardVersion, IAboutSectionLink } from 'modules/editor/graphql/gql';
import { IModel } from 'modules/core/interfaces/model';
import { clearObject } from 'modules/core/utils';
import { ObjectSchema, object, string } from 'yup';
import { URL_INVALID } from 'modules/editor/constants/messages';
import { URL_REGEXP } from 'modules/core/constants';

export class AboutAtributes implements IAboutSectionAtributes, IModel<IAboutSectionAtributes> {
  public cardVersionId!: number;
  public description?: string = '';
  public title?: string = '';
  public websiteUrl?: string = '';
  public websiteUrlShow?: boolean = false;
  public id?: number;
  public aboutSectionLinksAttributes: AboutSectionLinkAtributes[] = [];
  [props: string]: any;

  constructor(card?: ICardVersion) {
    if (card) {
      this.cardVersionId = card.id;
      this.description = card.aboutSection.description || '';
      this.title = card.aboutSection.title || '';
      this.websiteUrl = card.aboutSection.websiteUrl || '';
      this.websiteUrlShow = card.aboutSection.websiteUrlShow;
      this.id = card.id;
      this.aboutSectionLinksAttributes = card.aboutSection.aboutSectionLinks.map(
        (atributes) => new AboutSectionLinkAtributes(atributes)
      );
    }
  }

  public nextState(state: AboutAtributes): AboutAtributes {
    const atr = new AboutAtributes();
    atr.cardVersionId = state.cardVersionId;
    atr.description = state.description;
    atr.title = state.title;
    atr.websiteUrl = state.websiteUrl;
    atr.websiteUrlShow = state.websiteUrlShow;
    atr.id = state.id;
    atr.aboutSectionLinksAttributes = state.aboutSectionLinksAttributes.map((aba) => aba.nextState(aba));
    return atr;
  }

  public asState(): IAboutSectionAtributes {
    return {
      cardVersionId: this.cardVersionId,
      description: this.description,
      title: this.title,
      websiteUrl: this.websiteUrl,
      websiteUrlShow: this.websiteUrlShow,
      id: this.id,
      aboutSectionLinksAttributes: this.aboutSectionLinksAttributes.map((asa) => asa.asState()),
    };
  }

  public clear(nullable: boolean) {
    const atr = new AboutAtributes();
    clearObject(atr, nullable);
    atr.id = this.id;
    atr.cardVersionId = this.cardVersionId;
    atr.schema = this.schema;
    if (nullable) {
      this.aboutSectionLinksAttributes.forEach((link) => (link._destroy = true));
      atr.aboutSectionLinksAttributes = this.aboutSectionLinksAttributes;
    } else {
      atr.aboutSectionLinksAttributes = [];
    }
    return atr;
  }

  public toJSON(): IAboutSectionAtributes {
    return {
      cardVersionId: this.cardVersionId,
      id: this.id,
      description: this.description || null,
      title: this.title || null,
      websiteUrl: this.websiteUrl || null,
      websiteUrlShow: this.websiteUrlShow,
      aboutSectionLinksAttributes: this.aboutSectionLinksAttributes.map((a) => a.toJSON()),
    };
  }
}

export class AboutSectionLinkAtributes implements IAboutSectionLinkAtributes, IModel<IAboutSectionLinkAtributes> {
  public id?: number;
  public linkText?: string = '';
  public linkUrl?: string = '';
  public fileBase64?: string;
  public fileName?: string;

  public displayLink?: string = '';
  public fileUrl: string = '';
  // tslint:disable-next-line: variable-name
  public _destroy?: boolean;
  [props: string]: any;

  private schema: ObjectSchema<Partial<IAboutSectionLinkAtributes>> = object().shape({
    linkUrl: string().matches(URL_REGEXP, { excludeEmptyString: false, message: URL_INVALID }),
    linkText: string(),
  });

  constructor(atributes?: IAboutSectionLink) {
    if (atributes) {
      const { fileUrl, id, linkText, linkUrl } = atributes;
      this.linkUrl = linkUrl || '';
      this.linkText = linkText || '';
      this.id = id;

      this.fileUrl = fileUrl;
      this.displayLink = linkUrl || this.makeFileName(atributes);
    }
  }

  public setDisplayLink() {
    this.displayLink = this.linkUrl || this.fileUrl;
  }

  public nextState(state: AboutSectionLinkAtributes): AboutSectionLinkAtributes {
    const atr = new AboutSectionLinkAtributes();
    atr._destroy = state._destroy;
    atr.fileBase64 = state.fileBase64;
    atr.fileName = state.fileName;
    atr.id = state.id;
    atr.linkText = state.linkText;
    atr.linkUrl = state.linkUrl;

    atr.displayLink = state.displayLink;
    atr.fileUrl = state.fileUrl;
    return atr;
  }

  public asState(): IAboutSectionLinkAtributes {
    return {
      id: this.id,
      linkText: this.linkText,
      linkUrl: this.linkUrl,
      fileBase64: this.fileBase64,
      fileName: this.fileName,
      _destroy: this._destroy,
    };
  }

  public clear(nullable: boolean) {
    const atr = new AboutSectionLinkAtributes();
    clearObject(atr, nullable);
    atr.id = this.id;
    return atr;
  }

  public toJSON(): IAboutSectionLinkAtributes {
    const json: IAboutSectionLinkAtributes = {
      _destroy: this._destroy,
      fileBase64: this.fileBase64,
      fileName: this.fileName,
      linkText: this.linkText || null,
      linkUrl: this.linkUrl,
      id: this.id,
    };
    if (this.fileUrl === this.linkUrl) {
      json.linkUrl = undefined;
    } else if (this.linkUrl && this.fileUrl && this.linkUrl !== this.fileUrl) {
      json.fileBase64 = null;
      json.fileName = null;
    }
    if (this.fileName || !this.linkUrl) {
      json.linkUrl = null;
    }
    if (this.fileUrl && !this.linkUrl) {
      json.linkUrl = undefined;
    }
    this.linkText = this.linkUrl && !this.linkText ? undefined : this.linkText;
    return json;
  }

  private makeFileName(atr: IAboutSectionLink | IAboutSectionLinkAtributes): string | undefined {
    const parts = atr.fileUrl ? atr.fileUrl.split('/') : undefined;
    return parts ? parts[parts.length - 1] : atr.fileUrl || '';
  }
}
