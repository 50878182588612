import { FontStyleOption } from 'modules/core/types';

export const STYLE_OPTIONS: Array<{ label: string; value: string }> = [
   // Main GPL font

  {
    label: "Ginger",
    value: "F37 Ginger, sans-serif",
  },

  // Web-safe fonts

  {
    label: "Arial",
    value: "Arial, sans-serif",
  },
  {
    label: "Courier New",
    value: "Courier New, monospace",
  },
  {
    label: "Times New Roman",
    value: "Times New Roman, serif",
  },

  // Google fonts

  {
    label: "Abril FatFace",
    value: "Abril FatFace, sans-serif",
  },
  {
    label: "Alata",
    value: "Alata, sans-serif",
  },
  {
    label: "Alegreya",
    value: "Alegreya, serif",
  },
  {
    label: "Alegreya Sans",
    value: "Alegreya Sans, sans-serif",
  },
  {
    label: "Anonymous Pro",
    value: "Anonymous Pro, sans-serif",
  },
  {
    label: "Archivo Narrow",
    value: "Archivo Narrow, sans-serif",
  },
  {
    label: "B612",
    value: "B612, sans-serif",
  },
  {
    label: "BioRhyme",
    value: "BioRhyme, serif",
  },
  {
    label: "Bitter",
    value: "Bitter, serif",
  },
  {
    label: "Cabin",
    value: "Cabin, sans-serif",
  },
  {
    label: "Cairo",
    value: "Cairo, sans-serif",
  },
  {
    label: "Cardo",
    value: "Cardo, serif",
  },
  {
    label: "Chivo",
    value: "Chivo, sans-serif",
  },
  {
    label: "Concert One",
    value: "Concert One, sans-serif",
  },
  {
    label: "Cormorant",
    value: "Cormorant, serif",
  },
  {
    label: "Crimson Text",
    value: "Crimson Text, serif",
  },
  {
    label: "Eczar",
    value: "Eczar, serif",
  },
  {
    label: "Fira Sans",
    value: "Fira Sans, sans-serif",
  },
  {
    label: "Fjalla One",
    value: "Fjalla One, sans-serif",
  },
  {
    label: "Frank Ruhl Libre",
    value: "Frank Ruhl Libre, serif",
  },
  {
    label: "Gentium Basic",
    value: "Gentium Basic, serif",
  },
  {
    label: "Ibarra Real Nova",
    value: "Ibarra Real Nova, serif",
  },
  {
    label: "IBM Plex Sans",
    value: "IBM Plex Sans, sans-serif",
  },
  {
    label: "Inconsolata",
    value: "Inconsolata, monospace",
  },
  {
    label: "Inknut Antiqua",
    value: "Inknut Antiqua, serif",
  },
  {
    label: "Inter",
    value: "Inter, sans-serif",
  },
  {
    label: "Josefin Sans",
    value: "Josefin Sans, sans-serif",
  },
  {
    label: "Josefin Slab",
    value: "Josefin Slab, serif",
  },
  {
    label: "Karla",
    value: "Karla, sans-serif",
  },
  {
    label: "Lato",
    value: "Lato, sans-serif",
  },
  {
    label: "Libre Baskerville",
    value: "Libre Baskerville, serif",
  },
  {
    label: "Libre Franklin",
    value: "Libre Franklin, sans-serif",
  },
  {
    label: "Lora",
    value: "Lora, serif",
  },
  {
    label: "Merriweather",
    value: "Merriweather, serif",
  },
  {
    label: "Montserrat",
    value: "Montserrat, sans-serif",
  },
  {
    label: "Muli",
    value: "Muli, sans-serif",
  },
  {
    label: "Neuton",
    value: "Neuton, serif",
  },
  {
    label: "Nuito Sans",
    value: "Nuito Sans, sans-serif",
  },
  {
    label: "Nunito",
    value: "Nunito, sans-serif",
  },
  {
    label: "Old Standard TT",
    value: "Old Standard TT, serif",
  },
  {
    label: "Open Sans",
    value: "Open Sans, sans-serif",
  },
  {
    label: "Overpass",
    value: "Overpass, sans-serif",
  },
  {
    label: "Oxygen",
    value: "Oxygen, sans-serif",
  },
  {
    label: "Playfair Display",
    value: "Playfair Display, serif",
  },
  {
    label: "Poppins",
    value: "Poppins, sans-serif",
  },
  {
    label: "Proza Libre",
    value: "Proza Libre, sans-serif",
  },
  {
    label: "PT Sans",
    value: "PT Sans, sans-serif",
  },
  {
    label: "PT Serif",
    value: "PT Serif, serif",
  },
  {
    label: "Rajdhani",
    value: "Rajdhani, sans-serif",
  },
  {
    label: "Rakkas",
    value: "Rakkas, sans-serif",
  },
  {
    label: "Raleway",
    value: "Raleway, sans-serif",
  },
  {
    label: "Roboto",
    value: "Roboto, sans-serif",
  },
  {
    label: "Roboto Slab",
    value: "Roboto Slab, serif",
  },
  {
    label: "Rubik",
    value: "Rubik, sans-serif",
  },
  {
    label: "Source Sans Pro",
    value: "Source Sans Pro, sans-serif",
  },
  {
    label: "Source Serif Pro",
    value: "Source Serif Pro, serif",
  },
  {
    label: "Space Grotesk",
    value: "Space Grotesk, sans-serif",
  },
  {
    label: "Space Mono",
    value: "Space Mono, monospace",
  },
  {
    label: "Spectral",
    value: "Spectral, serif",
  },
  {
    label: "Titillium Web",
    value: "Titillium Web, sans-serif",
  },
  {
    label: "Ubuntu",
    value: "Ubuntu, sans-serif",
  },
  {
    label: "Varela",
    value: "Varela, sans-serif",
  },
  {
    label: "Varela Round",
    value: "Varela Round, sans-serif",
  },
  {
    label: "Vollkorn",
    value: "Vollkorn, serif",
  },
  {
    label: "Work Sans",
    value: "Work Sans, sans-serif",
  },
  {
    label: "Yatra One",
    value: "Yatra One, sans-serif",
  },
  {
    label: "Zilla Slab",
    value: "Zilla Slab, serif",
  },
];

export const WEEK = [
  {
    label: 'Monday',
    value: 'Monday',
  },
  {
    label: 'Tuesday',
    value: 'Tuesday',
  },
  {
    label: 'Wednesday',
    value: 'Wednesday',
  },
  {
    label: 'Thursday',
    value: 'Thursday',
  },
  {
    label: 'Friday',
    value: 'Friday',
  },
  {
    label: 'Saturday',
    value: 'Saturday',
  },
  {
    label: 'Sunday',
    value: 'Sunday',
  },
];

export const NOT_ALLOWED_EXT = ['\\.bat', '\\.bin', '\\.cmd', '\\.com', '\\.js', '\\.exe', '\\.hta', '\\.vb', '\\.wsf'];

export const FONT_VARIANTS: FontStyleOption[] = ['Bold', 'Normal'];

export const TIME_FORMAT_VARIANTS = ['12-hour', '24-hour'];
