import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import useStylesContainer from 'modules/public/pages/main/containerStyles';
import useStyles from 'modules/public/pages/main/styles';
import { Container, Hidden } from '@material-ui/core';
import clsx from 'clsx';
import { VideoDialog } from 'modules/core/components/dialogs/CreateVideo';
import ScrollDown from 'modules/public/components/ScrollDown';
import AnimationContainer, {
  IAnimationContainer,
} from 'modules/core/components/AnimationContainer';
import LandingText from './LandingText';
import CtaButtons from './CtaButtons';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import ImgsBackground from 'modules/public/pages/main/imgsBackground';
import Header from 'modules/core/components/Header';
import ProgressSpinner from 'modules/core/components/progress';
import Cookies from 'js-cookie';

interface IProps {
  isAuth: boolean;
}

// function sleep(ms: number) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }

export const LandingSectionContent: IAnimationContainer[] = [
  {
    id: "lp1",
    data: {},
    title: "One digital card. Infinite opportunities.",
    beginVisible: true,
  },
  {
    id: "lp2",
    data: {},
    title: "Contactless marketing platform",
  },
  {
    id: "lp3",
    data: {},
    title: "Business card",
  },
  {
    id: "lp4",
    data: {},
    title: "Digital CV",
  },
  {
    id: "lp5",
    data: {},
    title: "Website alternative",
    finishVisible: true,
  },
];

const animationContainerVh = 100 * 2;

const Animations: React.FC<any> = ({ classes, onAppear, onDisappear, isAuth, animationContainerClasses, animations, handleOpenPopover }) => {
  return (
    <>
      <div className={classes.animationContainerWrapper} style={{ height: `${animations.length * animationContainerVh}vh` }}>
        {animations.map((animationProps, index, array) => animationProps.loading ? <div className={animationContainerClasses.progressSpinnerWrapper}><ProgressSpinner /></div> : (
          <div className={animationContainerClasses.fadeInItem}>
            <AnimationContainer
              {...animationProps}
              key={index}
              index={index}
              playByScroll
              exClass="_landingSection"
              classes={animationContainerClasses}
              onDisappear={(progress: number) => onDisappear(index, progress)}
              onAppear={(progress: number) => onAppear(index, progress)}
            >
              {index === array.length - 1 ? <CtaButtons isAuth={isAuth} exClass="_lgUpHide" handleOpenPopover={handleOpenPopover} /> : undefined}
            </AnimationContainer>
          </div>
        ))}
      </div>
    </>
  );
};

const LandingSection: React.FC<IProps> = ({ isAuth }) => {
  const classes = useStyles();
  const containerClasses = useStylesContainer();
  const animationContainerClasses = useAnimationContainerStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenPopover = (event: any) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);

  const containerRef = useRef<HTMLDivElement>(null);

  const [currentPoint, setCurrentPoint] = useState<number>(0);
  const [opaqueText, setOpaqueText] = useState<boolean>(true);
  const textOpacity = opaqueText ? 1 : 0;

  // const headerContainerMarginStyles = useMemo(() => ({ marginBottom: "100vh", paddingBottom: 64 }), []);
  const headerContainerMarginStyles = useMemo(() => ({ transition: '0.3s', opacity: 1 }), []);
  const [headerContainerStyles, setHeaderContainerStyles] = useState<any>({});
  const [animations, setAnimations] = useState<any[]>(LandingSectionContent.map(l => ({ loading: true })));

  const throughReseller = Cookies.get('throughReseller') || '';
  
  const onDisappear = useCallback((index: number, progress: number) => {
    //console.log(`onDisappear was called for lp${index + 1}: ` + progress);

    index === 1 && setOpaqueText(true);
    index === 4 && setHeaderContainerStyles(headerContainerMarginStyles)
    setCurrentPoint(index === 0 ? 0 : index - 1)
  }, [setOpaqueText, headerContainerMarginStyles])

  const onAppear = useCallback((index: number, progress: number) => {
    //console.log(`onAppear was called for lp${index + 1}: ` + progress);

    index === 1 && setOpaqueText(false);
    index === 4 && setHeaderContainerStyles({ ...headerContainerMarginStyles, opacity: 0 })
    setCurrentPoint(index)
  }, [setOpaqueText, headerContainerMarginStyles])

  useEffect(() => {
    async function loadViews() {
      //await sleep(1000); // wait a moment before the animations download
      for (let i = 0; i < LandingSectionContent.length; i++) {
        const data = await import(`assets/animations/lp${i + 1}.json`);
        setAnimations(animations => {
          const animationsNew = [...animations];
          animationsNew[i] = {
            ...LandingSectionContent[i],
            data,
          };
          return animationsNew;
        });
      }
    }
    loadViews();
  }, []);


  return (
    <div className="pos-r">
      <VideoDialog youtubeUrl={throughReseller ? 'https://youtu.be/WqwAXJeAB5I' : 'https://youtu.be/OspozTPhqxQ' /* ONE_DIGITAL_CARD_INFINITE_OPPORTUNITIES.src */} open={open} anchorEl={anchorEl} onClose={handleClose} />

      <div
        className={classes.stickyGreenImgBackground}
      // style={headerContainerStyles}
      >
        <ImgsBackground />
      </div>

      <div
        className={clsx("App-header", `_page_Home_sticky`, classes.customStickyHomeNavbar)}
        style={headerContainerStyles}
      >
        <Header isPublic={true} />
      </div>

      <Container className={clsx(containerClasses.container, '_mobile-carusel', '_sticky-scroll', '_mdDownHide')} ref={containerRef}>
        <div className={clsx(containerClasses.containerNumbers)}>
          <div className={clsx(containerClasses.containerContent, '_home')}>
            <LandingText
              textOpacity={textOpacity}
              currentPoint={currentPoint}
              isAuth={isAuth}
              handleOpenPopover={handleOpenPopover}
            />
          </div>
        </div>

        <Hidden mdDown>
          <Animations {...{ classes, onAppear, onDisappear, isAuth, animationContainerClasses, animations, handleOpenPopover }} />
        </Hidden>
        <ScrollDown className={"_white _homepageLanding"} />
      </Container>

      <Hidden lgUp>
        <Animations {...{ classes, onAppear, onDisappear, isAuth, animationContainerClasses, animations, handleOpenPopover }} />
      </Hidden>
    </div>
  );
};

export default LandingSection;

const useAnimationContainerStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
    },
    title: {
      display: 'none',
    },
    subtitle: {
      display: 'none',
    },

    [theme.breakpoints.down('md')]: {
      title: {
        display: 'block',
      },
      subtitle: {
        display: 'block',
      },
    },
    fadeInItem: {
      animation: `$fadeIn 3000ms ${theme.transitions.easing.easeInOut}`
    },
    "@keyframes fadeIn": {
      "0%": {
        opacity: 0,
      },
      "100%": {
        opacity: 1,
      }
    },
    progressSpinnerWrapper: {
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        color: '#76B83F',
      }
    }
  })
);
