import React, { useEffect, useState, Suspense } from 'react';
import { NotFoundPage } from './NotFound';
import { AccountProfile } from 'modules/auth/models/account-profile';
import { useHistory, Route } from 'react-router';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import { IReduxState } from 'store';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import {
  ABOUT_ROUTE,
  HOW_IT_WORKS_ROUTE,
  IMPACT_ROUTE,
  BILLING_ROUTE,
  BUSINESS_ROUTE,
  BUY_ROUTE,
  CARD_ROUTE,
  CONTACT_ROUTE,
  DASHBOARD_ROUTE,
  EDITOR_ROUTE,
  FAQ_ROUTE,
  HOME_ROUTE,
  IAppRoute,
  INVITE_CONFIRM_ROUTE,
  LEARNING_ROUTE,
  PRICING_ROUTE,
  PRIVACY_ROUTE,
  SIGNIN_AUTH_ROUTE,
  LANDING_ROUTE,
  SIGNIN_ROUTE,
  SIGNUP_CONFIRM_ROUTE,
  SIGNUP_ROUTE,
  TERMS_ROUTE,
  RESELLER_AGREEMENT_ROUTE,
  USERS_ROUTE,
  BUY_MORE_ROUTE,
  PREVIEW_DESKTOP_DEVICE,
  PREVIEW_TABLET_DEVICE,
  PREVIEW_MOBILE_DEVICE,
  PREVIEW_ROUTE,
  SUCCESS_PUBLISH,
  INVITE_CONFIRM_NOT_FOUND_ROUTE,
  RESELLER_SIGN_UP,
  RESELLER_INVITE_CONFIRM_ROUTE,
  RESELLER_INITIAL_PURCHASE_ROUTE,
  RESELLER_RESOURCE_CENTRE_URL,
  RESELLER_REFERRAL_MANAGER,
  RESELLER_ZENDESK_SSO,
  RESELLER_STARTER_PACK,
  RESELLER_GENEALOGY_ROUTE,
  RESELLER_COMMISSION_HISTORY_ROUTE,
  RESELLER_NETWORK_ROUTE,
  BLOG_ROUTE,
} from './routes';
import SaveCardPage from 'modules/editor/containers/SaveCardPage';
import PublicLayout from 'layouts/PublicLayout';

const editorRoutes: IAppRoute[] = [EDITOR_ROUTE];

export const ROUTES: IAppRoute[] = [
  HOME_ROUTE,
  LANDING_ROUTE,
  HOW_IT_WORKS_ROUTE,
  ABOUT_ROUTE,
  IMPACT_ROUTE,
  PRICING_ROUTE,
  BUSINESS_ROUTE,
  FAQ_ROUTE,
  TERMS_ROUTE,
  PRIVACY_ROUTE,
  RESELLER_AGREEMENT_ROUTE,
  CONTACT_ROUTE,
  BUY_ROUTE,
  LEARNING_ROUTE,
  BUY_MORE_ROUTE,
  DASHBOARD_ROUTE,
  RESELLER_NETWORK_ROUTE,
  RESELLER_GENEALOGY_ROUTE,
  RESELLER_REFERRAL_MANAGER,
  ...editorRoutes,
  RESELLER_STARTER_PACK,
  RESELLER_RESOURCE_CENTRE_URL,
  RESELLER_COMMISSION_HISTORY_ROUTE,
  BILLING_ROUTE,
  USERS_ROUTE,
  SIGNIN_AUTH_ROUTE,
  SIGNIN_ROUTE,
  SIGNUP_CONFIRM_ROUTE,
  INVITE_CONFIRM_ROUTE,
  SIGNUP_ROUTE,
  RESELLER_SIGN_UP,
  RESELLER_INVITE_CONFIRM_ROUTE,
  RESELLER_INITIAL_PURCHASE_ROUTE,
  RESELLER_ZENDESK_SSO,

  PREVIEW_ROUTE,
  PREVIEW_DESKTOP_DEVICE,
  PREVIEW_TABLET_DEVICE,
  PREVIEW_MOBILE_DEVICE,
  SUCCESS_PUBLISH,
  INVITE_CONFIRM_NOT_FOUND_ROUTE,
  BLOG_ROUTE,

  // wildcard routes below...
  CARD_ROUTE,
];

const mapState = ({ auth: { profile, pending } }: IReduxState) => ({ profile, pending });

interface IState {
  profile: AccountProfile;
  pending: boolean;
}

const AppRoutes: React.FC<IState> = ({ profile, pending }) => {
  const history = useHistory();
  // const routes = useMemo(
  //   () =>
  //     ROUTES.filter(
  //       (route) => pending || (typeof route.profileFilter === 'function' ? route.profileFilter(profile) : true)
  //     ),
  //   [profile, pending]
  // );
  const routes = ROUTES;

  const [firstRenderComplete, setFirstRenderComplete] = useState<boolean>(false);

  // Need to ensure that profile has a chance to load before we start taking actions to limit user's profile privileges
  useEffect(() => {
    if (!firstRenderComplete) {
      setFirstRenderComplete(true);
    };
  }, [firstRenderComplete]);

  useEffect(() => {
    if (history.action !== 'POP') {
      window.scrollTo(0, 0);
    }
  }, [history.action, history.location.pathname]);

  return (
    <Switch>
      {routes.map((route) =>
        route.isProtected ? (
          <ProtectedRoute
            key={route.path}
            path={route.path}
            exact={route.exact}
            isCustomLayout={route.isCustom}
            isProfileFiltered={pending || (firstRenderComplete && typeof route.profileFilter === 'function' ? route.profileFilter(profile) : true)}
            authFailureRedirectPath={route.authFailureRedirectPath}
          >
            <Suspense fallback={null}>
              <route.component />
            </Suspense>
          </ProtectedRoute>
        ) : (
          <PublicRoute
            key={route.path}
            path={route.path}
            exact={route.exact}
            isCustomLayout={route.isCustom}
            title={route.title}
            customNavbar={route.customNavbar}
            customBackground={route.customBackground}
          >
            <Suspense fallback={null}>
              <route.component />
            </Suspense>
          </PublicRoute>
        )
      )}
      <PublicLayout isFooter={false}>
        <Route path="/:id/save-card" exact={true} component={SaveCardPage} subtitle="Save Card" />
      </PublicLayout>
      <PublicRoute path="/">
        <NotFoundPage />
      </PublicRoute>
    </Switch>
  );
};
export default connect(mapState)(AppRoutes);
