import { Box } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import PinterestIcon from '@material-ui/icons/Pinterest';
import { ReactComponent as BlogIcon } from 'assets/svg/blog.svg';
import { ReactComponent as PodcastIcon } from 'assets/svg/podcast.svg';
import { GreenCheckbox, InputField, MobileField } from 'modules/core/components/fields';
import IconSocial from 'modules/core/components/fields/IconSocial/IconSocial';
import { Validation } from 'modules/core/types';
import { updateSocialLinks } from 'modules/editor/api/card-mutations';
import { ICardVersion } from 'modules/editor/graphql/gql';
import { ISocialLinksAttributes } from 'modules/editor/graphql/mutations';
import { SocialLinksAtributes } from 'modules/editor/models/atributes/social-links-atributes';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IReduxState } from 'store';
import Clear from 'modules/editor/components/CardSectionEditors/Clear';
import { checkErrors, clearSection, isDisabled, updateField } from './hooks';

interface ISocialLinks {
  updateSocialLinks: (socLinksAtr: SocialLinksAtributes) => void;
  card: ICardVersion;
  classes?: any;
  fields: string[];
}

export const SocialLinks = connect(
  (state: IReduxState) => ({ card: state.currentVersion.currentVer, fields: state.editorSteps.socialFields }),
  { updateSocialLinks }
)((props: ISocialLinks) => {
  const { card, classes, updateSocialLinks: saveChanges, fields } = props;

  const [socailLinks, setSocialLinks] = useState<SocialLinksAtributes>(new SocialLinksAtributes(card));
  const [selectedLinks, setSelectedLinks] = useState(0);
  const [errors, setErrors] = useState<Validation<ISocialLinksAttributes>>({});

  useEffect(() => {
    socailLinks.cardVersionId = card.id;
    setSocialLinks(socailLinks.nextState(socailLinks));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card.id]);

  const handleBlur = useCallback(
    (field: keyof ISocialLinksAttributes) => {
      if (!errors[field]) {
        saveChanges(socailLinks);
      }
      // I sincerely hate next 3 strings
      if (field === 'whatsappUrl' && socailLinks.whatsappUrl === '+') {
        saveChanges(socailLinks);
        setErrors({ ...errors, whatsappUrl: undefined });
      }
    },
    [saveChanges, errors, socailLinks]
  );

  useEffect(() => {
    let count = 0;
    for (const key in socailLinks) {
      if (key.toString().includes('UrlShow') && socailLinks[key] === true) {
        count++;
      }
    }
    setSelectedLinks(count);
  }, [socailLinks]);

  const changeCheckbox = useCallback(
    (field: string) => {
      if (!socailLinks[field] && selectedLinks < 5) {
        socailLinks[field] = true;
        setSelectedLinks((prevState) => ++prevState);
      } else {
        socailLinks[field] = false;
        setSelectedLinks((prevState) => --prevState);
      }
      const atributes = socailLinks.nextState(socailLinks);
      setSocialLinks(atributes);
      saveChanges(atributes);
    },
    [setSocialLinks, saveChanges, socailLinks, setSelectedLinks, selectedLinks]
  );

  const toggleCheckboxAuto = useCallback(
    (field: string, value: any, errorField: boolean) => {
      if (value && selectedLinks < 5 && !errorField) {
        socailLinks[field] = true;
      } else {
        socailLinks[field] = false;
      }
    },
    [socailLinks, selectedLinks]
  );

  return (
    <>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography className={classes.sectionHeader}>Social links</Typography>
        {fields.length === 10 ? null : (
          <Box className={classes.clearBlock}>
            <Clear
              id="social_editor_clear"
              ico={<ClearIcon fontSize="inherit" />}
              onConfirm={() => clearSection(socailLinks, saveChanges, setSocialLinks)}
            >
              Clear section
            </Clear>
          </Box>
        )}
      </Box>
      <div className={classes.inputWrapperSocial}>
        <Typography className={classes.heading}>Linked in</Typography>
        <InputField
          name="social-links-linkedInUrl"
          type="text"
          placeholder="Enter URL"
          disabled={isDisabled(fields, 'linkedInUrl')}
          onChange={(e) => {
            const err = checkErrors({
              model: socailLinks,
              prop: 'linkedInUrl',
              val: e.target.value,
              setState: setSocialLinks,
            });
            toggleCheckboxAuto('linkedInUrlShow', e.target.value, err);
            updateField({
              model: socailLinks,
              prop: 'linkedInUrl',
              val: e.target.value,
              setState: setSocialLinks,
              setErrors,
              errors,
            });
          }}
          value={socailLinks.linkedInUrl as string}
          variant="filled"
          onBlur={() => handleBlur('linkedInUrl')}
          error={!!errors.linkedInUrl}
          InputProps={{
            startAdornment: (
              <div className={classes.icon}>
                <IconSocial network="linkedin" color={isDisabled(fields, 'linkedInUrl') ? '#9fa2a5' : undefined} />
              </div>
            ),
          }}
        />
        <FormControlLabel
          className={classes.checkbox}
          control={
            <GreenCheckbox
              name="social-links-linkedInUrlShow"
              checked={socailLinks.linkedInUrlShow && !errors.linkedInUrl}
              disabled={
                isDisabled(fields, 'linkedInUrlShow') ||
                !card.linkedInUrl ||
                (selectedLinks >= 5 && !socailLinks.linkedInUrlShow) ||
                !!errors.linkedInUrl
              }
              onChange={() => changeCheckbox('linkedInUrlShow')}
              value="linkedIn"
            />
          }
          label="Show"
        />
      </div>
      <div className={classes.inputWrapperSocial}>
        <Typography className={classes.heading}>Facebook</Typography>
        <InputField
          name="social-links-facebookUrl"
          type="text"
          placeholder="Enter URL"
          disabled={isDisabled(fields, 'facebookUrl')}
          onChange={(e) => {
            const err = checkErrors({
              model: socailLinks,
              prop: 'facebookUrl',
              val: e.target.value,
              setState: setSocialLinks,
            });
            toggleCheckboxAuto('facebookUrlShow', e.target.value, err);
            updateField({
              model: socailLinks,
              prop: 'facebookUrl',
              val: e.target.value,
              setState: setSocialLinks,
              setErrors,
              errors,
            });
          }}
          value={socailLinks.facebookUrl as string}
          variant="filled"
          onBlur={() => handleBlur('facebookUrl')}
          error={!!errors.facebookUrl}
          InputProps={{
            startAdornment: (
              <div className={classes.icon}>
                <IconSocial network="facebook" color={isDisabled(fields, 'facebookUrl') ? '#9fa2a5' : undefined} />
              </div>
            ),
          }}
        />
        <FormControlLabel
          className={classes.checkbox}
          control={
            <GreenCheckbox
              name="social-links-facebookUrlShow"
              disabled={
                isDisabled(fields, 'facebookUrlShow') ||
                !card.facebookUrl ||
                (selectedLinks >= 5 && !socailLinks.facebookUrlShow) ||
                !!errors.facebookUrl
              }
              checked={socailLinks.facebookUrlShow && !errors.facebookUrl}
              onChange={() => changeCheckbox('facebookUrlShow')}
              value="facebook"
            />
          }
          label="Show"
        />
      </div>
      <div className={classes.inputWrapperSocial}>
        <Typography className={classes.heading}>Twitter</Typography>
        <InputField
          name="social-links-twitterUrl"
          type="text"
          placeholder="Enter URL"
          disabled={isDisabled(fields, 'twitterUrl')}
          onChange={(e) => {
            const err = checkErrors({
              model: socailLinks,
              prop: 'twitterUrl',
              val: e.target.value,
              setState: setSocialLinks,
            });
            toggleCheckboxAuto('twitterUrlShow', e.target.value, err);
            updateField({
              model: socailLinks,
              prop: 'twitterUrl',
              val: e.target.value,
              setState: setSocialLinks,
              setErrors,
              errors,
            });
          }}
          value={socailLinks.twitterUrl as string}
          variant="filled"
          onBlur={() => handleBlur('twitterUrl')}
          error={!!errors.twitterUrl}
          InputProps={{
            startAdornment: (
              <div className={classes.icon}>
                <IconSocial network="twitter" color={isDisabled(fields, 'twitterUrl') ? '#9fa2a5' : undefined} />
              </div>
            ),
          }}
        />
        <FormControlLabel
          className={classes.checkbox}
          control={
            <GreenCheckbox
              name="social-links-twitterUrlShow"
              disabled={
                isDisabled(fields, 'twitterUrlShow') ||
                !card.twitterUrl ||
                (selectedLinks >= 5 && !socailLinks.twitterUrlShow) ||
                !!errors.twitterUrl
              }
              checked={socailLinks.twitterUrlShow && !errors.twitterUrl}
              onChange={() => changeCheckbox('twitterUrlShow')}
              value="twitter"
            />
          }
          label="Show"
        />
      </div>
      <div className={classes.inputWrapperSocial}>
        <Typography className={classes.heading}>Youtube</Typography>
        <InputField
          name="social-links-youtubeUrl"
          type="text"
          placeholder="Enter URL"
          disabled={isDisabled(fields, 'youtubeUrl')}
          onChange={(e) => {
            const err = checkErrors({
              model: socailLinks,
              prop: 'youtubeUrl',
              val: e.target.value,
              setState: setSocialLinks,
            });
            toggleCheckboxAuto('youtubeUrlShow', e.target.value, err);
            updateField({
              model: socailLinks,
              prop: 'youtubeUrl',
              val: e.target.value,
              setState: setSocialLinks,
              setErrors,
              errors,
            });
          }}
          value={socailLinks.youtubeUrl as string}
          variant="filled"
          onBlur={() => handleBlur('youtubeUrl')}
          error={!!errors.youtubeUrl}
          InputProps={{
            startAdornment: (
              <div className={classes.icon}>
                <IconSocial network="youtube" color={isDisabled(fields, 'youtubeUrl') ? '#9fa2a5' : undefined} />
              </div>
            ),
          }}
        />
        <FormControlLabel
          className={classes.checkbox}
          control={
            <GreenCheckbox
              name="social-links-youtubeUrlShow"
              disabled={
                isDisabled(fields, 'youtubeUrlShow') ||
                !card.youtubeUrl ||
                (selectedLinks >= 5 && !socailLinks.youtubeUrlShow) ||
                !!errors.youtubeUrl
              }
              checked={socailLinks.youtubeUrlShow && !errors.youtubeUrl}
              onChange={() => changeCheckbox('youtubeUrlShow')}
              value="youtube"
            />
          }
          label="Show"
        />
      </div>
      <div className={classes.inputWrapperSocial}>
        <Typography className={classes.heading}>Whatsapp</Typography>
        <MobileField
          exClass="dark"
          disabled={isDisabled(fields, 'whatsappUrl')}
          inputProps={{
            value: socailLinks.whatsappUrl as string,
            variant: 'filled',
            name: 'social-links-whatsappUrl',
            error: !!errors.whatsappUrl,
            helperText: errors.whatsappUrl,
            disabled: isDisabled(fields, 'whatsappUrl'),
            InputProps: {
              startAdornment: (
                <div className={classes.icon}>
                  <IconSocial network="whatsapp" color={isDisabled(fields, 'whatsappUrl') ? '#9fa2a5' : undefined} />
                </div>
              ),
            },
          }}
          onChange={(e) => {
            const err = checkErrors({
              model: socailLinks,
              prop: 'whatsappUrl',
              val: e,
              setState: setSocialLinks,
            });
            toggleCheckboxAuto('whatsappUrlShow', e, err);
            updateField({
              model: socailLinks,
              prop: 'whatsappUrl',
              val: e,
              setState: setSocialLinks,
              errors,
              setErrors,
            });
          }}
          onBlur={() => handleBlur('whatsappUrl')}
        />
        <FormControlLabel
          className={classes.checkbox}
          control={
            <GreenCheckbox
              name="social-links-whatsappUrlShow"
              disabled={
                isDisabled(fields, 'whatsappUrlShow') ||
                !card.whatsappUrl ||
                (selectedLinks >= 5 && !socailLinks.whatsappUrlShow) ||
                !!errors.whatsappUrl
              }
              checked={socailLinks.whatsappUrlShow && !errors.whatsappUrl}
              onChange={() => changeCheckbox('whatsappUrlShow')}
              value="whatsapp"
            />
          }
          label="Show"
        />
      </div>
      <div className={classes.inputWrapperSocial}>
        <Typography className={classes.heading}>Instagram</Typography>
        <InputField
          name="social-links-instagramUrl"
          type="text"
          placeholder="Enter URL"
          disabled={isDisabled(fields, 'instagramUrl')}
          onChange={(e) => {
            const err = checkErrors({
              model: socailLinks,
              prop: 'instagramUrl',
              val: e.target.value,
              setState: setSocialLinks,
            });
            toggleCheckboxAuto('instagramUrlShow', e.target.value, err);
            updateField({
              model: socailLinks,
              prop: 'instagramUrl',
              val: e.target.value,
              setState: setSocialLinks,
              errors,
              setErrors,
            });
          }}
          error={!!errors.instagramUrl}
          value={socailLinks.instagramUrl as string}
          variant="filled"
          onBlur={() => handleBlur('instagramUrl')}
          InputProps={{
            startAdornment: (
              <div className={classes.icon}>
                <IconSocial network="instagram" color={isDisabled(fields, 'instagramUrl') ? '#9fa2a5' : undefined} />
              </div>
            ),
          }}
        />
        <FormControlLabel
          className={classes.checkbox}
          control={
            <GreenCheckbox
              name="social-links-instagramUrlShow"
              disabled={
                isDisabled(fields, 'instagramUrlShow') ||
                !card.instagramUrl ||
                (selectedLinks >= 5 && !socailLinks.instagramUrlShow) ||
                !!errors.instagramUrl
              }
              checked={socailLinks.instagramUrlShow && !errors.instagramUrl}
              onChange={() => changeCheckbox('instagramUrlShow')}
              value="instagram"
            />
          }
          label="Show"
        />
      </div>
      <div className={classes.inputWrapperSocial}>
        <Typography className={classes.heading}>Snapchat</Typography>
        <InputField
          name="social-links-snapchatUrl"
          type="text"
          placeholder="Enter URL"
          disabled={isDisabled(fields, 'snapchatUrl')}
          onChange={(e) => {
            const err = checkErrors({
              model: socailLinks,
              prop: 'snapchatUrl',
              val: e.target.value,
              setState: setSocialLinks,
            });
            toggleCheckboxAuto('snapchatUrlShow', e.target.value, err);
            updateField({
              model: socailLinks,
              prop: 'snapchatUrl',
              val: e.target.value,
              setState: setSocialLinks,
              errors,
              setErrors,
            });
          }}
          error={!!errors.snapchatUrl}
          value={socailLinks.snapchatUrl as string}
          variant="filled"
          onBlur={() => handleBlur('snapchatUrl')}
          InputProps={{
            startAdornment: (
              <div className={classes.icon}>
                <IconSocial network="snapchat" color={isDisabled(fields, 'snapchatUrl') ? '#9fa2a5' : undefined} />
              </div>
            ),
          }}
        />
        <FormControlLabel
          className={classes.checkbox}
          control={
            <GreenCheckbox
              name="social-links-snapchatUrlShow"
              disabled={
                isDisabled(fields, 'snapchatUrlShow') ||
                !card.snapchatUrl ||
                (selectedLinks >= 5 && !socailLinks.snapchatUrlShow) ||
                !!errors.snapchatUrl
              }
              checked={socailLinks.snapchatUrlShow && !errors.snapchatUrl}
              onChange={() => changeCheckbox('snapchatUrlShow')}
              value="snapchat"
            />
          }
          label="Show"
        />
      </div>
      <div className={classes.inputWrapperSocial}>
        <Typography className={classes.heading}>Blog</Typography>
        <InputField
          name="social-links-blogUrl"
          type="text"
          placeholder="Enter URL"
          disabled={isDisabled(fields, 'blogUrl')}
          onChange={(e) => {
            const err = checkErrors({
              model: socailLinks,
              prop: 'blogUrl',
              val: e.target.value,
              setState: setSocialLinks,
            });
            toggleCheckboxAuto('blogUrlShow', e.target.value, err);
            updateField({
              model: socailLinks,
              prop: 'blogUrl',
              val: e.target.value,
              setState: setSocialLinks,
              errors,
              setErrors,
            });
          }}
          error={!!errors.blogUrl}
          value={socailLinks.blogUrl as string}
          variant="filled"
          onBlur={() => handleBlur('blogUrl')}
          InputProps={{
            startAdornment: (
              <div className={classes.icon}>
                <SvgIcon
                  children={<BlogIcon />}
                  htmlColor={isDisabled(fields, 'blogUrlShow') ? '#9fa2a5' : undefined}
                />
              </div>
            ),
          }}
        />
        <FormControlLabel
          className={classes.checkbox}
          control={
            <GreenCheckbox
              name="social-links-blogUrlShowShow"
              disabled={
                isDisabled(fields, 'blogUrlShowShow') ||
                !card.blogUrl ||
                (selectedLinks >= 5 && !socailLinks.blogUrlShow) ||
                !!errors.blogUrl
              }
              checked={socailLinks.blogUrlShow && !errors.blogUrl}
              onChange={() => changeCheckbox('blogUrlShow')}
              value="blog"
            />
          }
          label="Show"
        />
      </div>
      <div className={classes.inputWrapperSocial}>
        <Typography className={classes.heading}>Podcast</Typography>
        <InputField
          name="social-links-podcastUrl"
          type="text"
          placeholder="Enter URL"
          disabled={isDisabled(fields, 'podcastUrl')}
          onChange={(e) => {
            const err = checkErrors({
              model: socailLinks,
              prop: 'podcastUrl',
              val: e.target.value,
              setState: setSocialLinks,
            });
            toggleCheckboxAuto('podcastUrlShow', e.target.value, err);
            updateField({
              model: socailLinks,
              prop: 'podcastUrl',
              val: e.target.value,
              setState: setSocialLinks,
              errors,
              setErrors,
            });
          }}
          error={!!errors.podcastUrl}
          value={socailLinks.podcastUrl as string}
          variant="filled"
          onBlur={() => handleBlur('podcastUrl')}
          InputProps={{
            startAdornment: (
              <div className={classes.icon}>
                <SvgIcon
                  children={<PodcastIcon />}
                  htmlColor={isDisabled(fields, 'podcastUrlShow') ? '#9fa2a5' : undefined}
                />
              </div>
            ),
          }}
        />
        <FormControlLabel
          className={classes.checkbox}
          control={
            <GreenCheckbox
              name="social-links-podcastUrlShow"
              disabled={
                isDisabled(fields, 'podcastUrlShow') ||
                !card.podcastUrl ||
                (selectedLinks >= 5 && !socailLinks.podcastUrlShow) ||
                !!errors.podcastUrl
              }
              checked={socailLinks.podcastUrlShow && !errors.podcastUrl}
              onChange={() => changeCheckbox('podcastUrlShow')}
              value="podcast"
            />
          }
          label="Show"
        />
      </div>
      <div className={classes.inputWrapperSocial}>
        <Typography className={classes.heading}>Pinterest</Typography>
        <InputField
          name="social-links-pinterestUrl"
          type="text"
          placeholder="Enter URL"
          disabled={isDisabled(fields, 'pinterestUrl')}
          onChange={(e) => {
            const err = checkErrors({
              model: socailLinks,
              prop: 'pinterestUrl',
              val: e.target.value,
              setState: setSocialLinks,
            });
            toggleCheckboxAuto('pinterestUrlShow', e.target.value, err);
            updateField({
              model: socailLinks,
              prop: 'pinterestUrl',
              val: e.target.value,
              setState: setSocialLinks,
              errors,
              setErrors,
            });
          }}
          error={!!errors.pinterestUrl}
          value={socailLinks.pinterestUrl as string}
          variant="filled"
          onBlur={() => handleBlur('pinterestUrl')}
          InputProps={{
            startAdornment: (
              <div className={classes.icon}>
                <SvgIcon
                  children={<PinterestIcon />}
                  htmlColor={isDisabled(fields, 'pinterestUrlShow') ? '#9fa2a5' : undefined}
                />
              </div>
            ),
          }}
        />
        <FormControlLabel
          className={classes.checkbox}
          control={
            <GreenCheckbox
              name="social-links-pinterestUrlShow"
              disabled={
                isDisabled(fields, 'pinterestUrlShow') ||
                !card.pinterestUrl ||
                (selectedLinks >= 5 && !socailLinks.pinterestUrlShow) ||
                !!errors.pinterestUrl
              }
              checked={socailLinks.pinterestUrlShow && !errors.pinterestUrl}
              onChange={() => changeCheckbox('pinterestUrlShow')}
              value="pinterest"
            />
          }
          label="Show"
        />
      </div>
    </>
  );
});
