import { createMuiTheme } from '@material-ui/core';
import { lightGreen, limeade } from './colors';

export const drawerWidth = 300;

const darkTheme = createMuiTheme({
  typography: {
    fontFamily: 'F37 Ginger',
  },
  palette: {
    type: 'dark',
    primary: {
      light: '#9da1a3',
      main: '#9e9e9e',
      dark: '#9e9e9e',
    },
    secondary: {
      light: '#F00',
      main: limeade,
      dark: '#00f',
    },
    background: {
      default: '#252B31',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          overflowX: 'hidden',
        },
        '.App': {
          position: 'relative',
        },
        '.App-header._white': {
          '& .logo': {
            fill: 'white',
          },
        },
        '.Site': {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        },
        '.Site-content': {
          flex: '1 0 auto',
          width: '100%',
        },
        html: {
          width: '100%',
        },
      },
    },
    MuiFab: {
      secondary: {
        '&:hover': {
          backgroundColor: lightGreen,
        },
      },
    },
    MuiMenu: {
      paper: {
        backgroundColor: '#3B4046',
        border: '1px solid rgba(255,255,255,0.15)',
      },
    },
    MuiCheckbox: {
      root: {
        '&.Mui-checked .MuiIconButton-label': {
          backgroundColor: '#21262C',
        },
        '& .MuiIconButton-label': {
          backgroundColor: '#21262C',
          width: 22,
          height: 22,
          borderRadius: 5,
          margin: 6,
        },
      },
    },
    MuiMobileStepper: {
      root: {
        background: 'transparent',
      },
      dot: {
        cursor: 'pointer',
        width: '10px',
        height: '10px',
        backgroundColor: '#6f7173',
      },
      dotActive: {
        cursor: 'pointer',
        width: '10px',
        height: '10px',
        backgroundColor: '#fff',
      },
    },
  },
});

export default darkTheme;
