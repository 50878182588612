import { Subscription } from 'modules/billing/models/subscription';

export interface ICreateSubscriptionResponse {
  success: boolean;
  scaSecret?: string;
  subscription?: Subscription;
}

interface ICreateSubscriptionResponseArgs {
  scaSecret?: string;
  subscription?: Subscription;
}

export function createSubscriptionResponse({ scaSecret, subscription }: ICreateSubscriptionResponseArgs) {
  return {
    success: subscription && !scaSecret ? true : false,
    scaSecret: scaSecret,
    subscription: subscription,
  };
};

export interface ICreateResellerSubscriptionResponse extends ICreateSubscriptionResponse {
  resellerSubscription?: Subscription;
  retailSubscription?: Subscription;
}

interface ICreateResellerSubscriptionResponseArgs extends ICreateSubscriptionResponseArgs {
  scaSecret?: string;
  resellerSubscription?: Subscription;
  retailSubscription?: Subscription;
}

export function createResellerSubscriptionResponse(
  { scaSecret, resellerSubscription, retailSubscription }: ICreateResellerSubscriptionResponseArgs
) {
  return {
    success: resellerSubscription && !scaSecret ? true : false,
    scaSecret: scaSecret,
    resellerSubscription: resellerSubscription,
    retailSubscription: retailSubscription,
  };
};
