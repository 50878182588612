import { apoloClient } from 'modules/core/constants/client';
import gql from 'graphql-tag';

export const cancelSubscription = async (id: any) => {
  try {
    // eslint-disable-next-line
    const res = await apoloClient.mutate({
      variables: { id },
      mutation: gql`
        mutation cancelSubscription($id: ID!) {
          cancelSubscription(attributes: { id: $id }) {
            errors
            subscription {
              amount
              autoRenew
              currency
              currentPeriodEnd
              currentPeriodStart
              description
              id
              invoiceId
              slotNumber
              status
              subscriptionId
              user {
                customerId
                email
                firstName
                id
                lastName
                mobilePhone
              }
            }
          }
        }
      `,
    });
    return res;
  } catch (error) {
    console.warn(error);
    throw error;
  }
};

export const renewSubscription = async (id: any) => {
  try {
    // eslint-disable-next-line
    const res = await apoloClient.mutate({
      variables: { id },
      mutation: gql`
        mutation renewSubscription($id: ID!) {
          renewSubscription(attributes: { id: $id }) {
            errors
            subscription {
              amount
              autoRenew
              currency
              currentPeriodEnd
              currentPeriodStart
              description
              id
              invoiceId
              slotNumber
              status
              subscriptionId
              user {
                customerId
                email
                firstName
                id
                lastName
                mobilePhone
              }
            }
          }
        }
      `,
    });
    return res;
  } catch (error) {
    console.warn(error);
    throw error;
  }
};
