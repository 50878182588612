import AuthorizationDialog from 'modules/auth/components/Authorization/AuthorizationDialog';
import ErrorMessages from 'modules/core/components/ErrorMessages';
import React, { useEffect, useMemo } from 'react';
import { useLocation, useHistory, Redirect } from 'react-router';
import { HOME_ROUTE, EDITOR_ROUTE, SIGNIN_ROUTE } from 'routes/routes';
import { acceptInvite } from 'modules/users/thunks/assigned-users';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { useThunkDispatch } from 'hooks/useThunkDispatch';

export const InviteConfirmation: React.FC = () => {
  const errors = useTypedSelector((state) => state.users.errors);
  const isAuth = useTypedSelector((state) => state.auth.isAuthenticated);
  const thunkDispatch = useThunkDispatch();
  const { search } = useLocation();
  const history = useHistory();

  const invitationId = useMemo(() => {
    const query = new URLSearchParams(search);
    return query.get('invitation_id');
  }, [search]);

  useEffect(() => {
    if (!isAuth) {
      if (invitationId) {
        thunkDispatch(acceptInvite(invitationId))
          .then((success) =>
            success ? history.push(EDITOR_ROUTE.path) : setTimeout(() => history.push(SIGNIN_ROUTE.path), 3000)
          )
          .catch(console.warn);
      } else {
        history.push(HOME_ROUTE.path);
      }
    }
  }, [invitationId, history, thunkDispatch, isAuth]);

  return isAuth ? (
    <Redirect to="/invite_user/not_found" />
  ) : (
    <AuthorizationDialog
      title="Access your account"
      subtitle="Checking authorization..."
      dlgBody={<ErrorMessages messages={errors} />}
    />
  );
};
