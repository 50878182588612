import React from 'react';
import CssGreenButton from 'modules/core/components/buttons/roundGreenButton';
import PlayButton from 'modules/core/components/buttons/PlayButton';
import { DASHBOARD_ROUTE, SIGNUP_ROUTE } from 'routes/routes';
import clsx from 'clsx';
import useStylesPage from 'modules/public/pages/main/pageStyles';

export interface ICtaButtons {
  isAuth: boolean;
  handleOpenPopover: (event: any) => void;
  exClass?: string;
  style?: any;
}

const CtaButtons: React.FC<ICtaButtons> = ({ isAuth, exClass, style, handleOpenPopover }) => {
  const pageStyles = useStylesPage();

  return (
    <div className={clsx(pageStyles.ctaRow, exClass)} style={style}>
      <CssGreenButton href={isAuth ? DASHBOARD_ROUTE.path : SIGNUP_ROUTE.path} className="_outlinedButton">
        {isAuth ? "To the dashboard" : "Try for free"}
      </CssGreenButton>
      <PlayButton onClick={handleOpenPopover}>Watch the video</PlayButton>
    </div>
  );
};

export default CtaButtons;
