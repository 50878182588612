import { withStyles } from '@material-ui/styles';
import React from 'react';
import Menu from '@material-ui/core/Menu';

export default withStyles({
  paper: {
    maxWidth: 425,
    width: '100%',
    top: '50px!important',
    border: '1px solid rgba(255,255,255,0.15)',
    boxShadow: '0 10px 35px 0 rgba(0,0,0,0.1)',
    overflow: 'hidden',
    '& .MuiList-root': {
      overflow: 'hidden',
      maxWidth: 425,
      height: '100%',
    },
  },
})((props: any) => <Menu {...props} />);
