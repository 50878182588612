import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';
import { IReduxState } from 'store';

import { useStyles } from './styles';

export interface IAuthorizationDialogProps {
  centered?: boolean;
  title?: string;
  subtitle?: string;
  titleLogo?: any;
  dlgBody: any;
  footer?: any;
  bottom?: any;
  exClass?: any;
}

interface IStore {
  errorMessage: string | null;
}

const AuthorizationDialog: React.FC<IStore & IAuthorizationDialogProps> = ({
  title,
  subtitle,
  titleLogo: DlgLogo,
  dlgBody,
  footer: DlgFooter,
  bottom: DlgBottom,
  errorMessage,
  centered,
  exClass,
}) => {
  const classes = useStyles();
  return (
    <Container className={classes.wrapper}>
      <Paper className={classes.paper}>
        {DlgLogo && (
          <Box display="flex" justifyContent="center">
            {DlgLogo}
          </Box>
        )}
        <Typography variant="h5" align={centered ? 'center' : 'inherit'}>
          {title}
        </Typography>
        <Typography variant="subtitle1" align={centered ? 'center' : 'inherit'}>
          {subtitle}
        </Typography>
        <Box display="flex" alignItems="center" flexDirection="column" className={clsx(classes.body, exClass)}>
          {dlgBody}
          {errorMessage && (
            <Typography variant="caption" color="error" align="center">
              {errorMessage}
            </Typography>
          )}
        </Box>
        {DlgFooter && (
          <Box textAlign="center" className={classes.footer}>
            {DlgFooter}
          </Box>
        )}
      </Paper>
      {DlgBottom && <Box>{DlgBottom}</Box>}
    </Container>
  );
};
const mapState = (state: IReduxState) => ({
  errorMessage: state.auth.errorMessage,
});

export default connect(mapState)(AuthorizationDialog);
