import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import clsx from 'clsx';
import { useStyles } from './styles';

export interface ISimpleSelectProps {
  value?: any;
  label: string;
  options: any;
  exClass?: any;
  defaultValue?: any;
  disabled?: boolean;
  onChange?: (value: any, name: any, event?: any) => void;
  name?: string;
  InputProps?: any;
  itemStyle?: (value: any) => any;
};

export const CssSelectField = withStyles({
  root: {
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiFilledInput-underline:before': {
      borderBottomColor: 'red',
    },
  },
})(Select);

export const SelectField: React.FC<ISimpleSelectProps> = ({
  value,
  label,
  options,
  onChange,
  exClass,
  name,
  disabled,
  defaultValue,
  InputProps,
  itemStyle,
}) => {
  const classes = useStyles();
  const [innerValue, setInnerValue] = useState(defaultValue === undefined ? '' : defaultValue);

  function handleChange(event: React.ChangeEvent<{ name?: string; value: any }>) {
    setInnerValue(event.target.value);
    if (onChange) {
      onChange(event.target.value, event.target.name, event);
    }
  }

  return (
    <FormControl className={clsx(classes.formControl, exClass)}>
      <InputLabel className={clsx(classes.label, exClass)} classes={{ shrink: classes.labelShrink }} htmlFor={`${name}`}>
        {label}
      </InputLabel>
      <CssSelectField
        color="primary"
        variant="filled"
        disabled={disabled}
        value={value === undefined ? innerValue : value}
        onChange={handleChange}
        className={clsx(classes.select, exClass)}
        inputProps={{
          name: `${name}`,
        }}
        {...InputProps}
      >
        {options.map((el: any) => {
          return (
            <MenuItem
              value={el.value}
              key={el.label}
              id={el.value}
              style={itemStyle && itemStyle(el)}
              className={clsx(classes.li, exClass)}
            >
              {el.label}
            </MenuItem>
          );
        })}
      </CssSelectField>
    </FormControl>
  );
};
