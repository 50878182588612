import React, { useMemo } from 'react';
import { actions } from 'modules/auth/reducers/authReducer';
import { INotFoundProps } from 'modules/core/components/NotFound';
import { Box, Button } from '@material-ui/core';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';

export function useSigninProps(): INotFoundProps {
  const email = useTypedSelector((state) => state.auth.profile.email);
  const dispatch = useDispatch();
  const { wrapper, button } = useStyles();

  return useMemo<INotFoundProps>(
    () => ({
      heading: 'You are already signed in!',
      subHeading: `You must sign out of your account ${email} in order to continue`,
      action: (
        <Box className={wrapper}>
          <Button className={button} onClick={() => dispatch(actions.signout())}>
            Sign out and continue
          </Button>
        </Box>
      ),
    }),
    [button, wrapper, email, dispatch]
  );
}

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    marginBottom: 16,
  },
});
