export const MOMENT_DAY_FIRST_FORMAT = 'Do MMM YYYY';
export const MOMENT_YEAR_FIRST_FORMAT = 'YYYY-MM-DD';
export const MOMENT_SHORT_FORMAT = 'DD/MM/YYYY';
export const MOMENT_DAY_MONTH_FORMAT = 'D/M';
export const MOMENT_DAY_FORMAT = 'DD';
export const MOMENT_MONTH_YEAR_LONG_FORMAT = 'MMMM YYYY';
export const MOMENT_MONTH_YEAR_SHORT_FORMAT = 'MMM YY';

type TimeVal = number | string;
type MeridiemVal = "am" | "pm" | undefined;

export type ConvertedTimeVal = [TimeVal, MeridiemVal];

export const convert24hTo12h = (val: number): ConvertedTimeVal => {
  switch (true) {
    case val === 0:
    case val === 24:
      return [0, "am"]
    case val === 12:
    case val > 12 && val < 13:
      return [val, "pm"]
    case val < 12:
      return [val, "am"]
    case val > 12:
      return [val - 12, "pm"]
    default:
      return [val, undefined]
  };
};

export const formatHalfHourMins = (value: TimeVal) => {
  const val = parseFloat(value as string);
  const modulo = val % 1;
  return modulo ? `${Math.floor(val)}:30` : `${val}:00`;
}

interface IFormatOpeningHourArgs {
  display12h?: boolean | null;
}

export const formatOpeningHour = (val: number, args?: IFormatOpeningHourArgs) => {
  let value = [val, undefined] as ConvertedTimeVal;

  if (args && args.display12h) {
    value = convert24hTo12h(val);
  }

  value[0] = formatHalfHourMins(value[0]);

  return value;
}

export const formattedOpeningHour = (val: number, args?: IFormatOpeningHourArgs) => {
  return formatOpeningHour(val, args).join("");
}
