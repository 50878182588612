import { ServerError } from '@apollo/client/link/utils';
import {
  authenticateApi,
  confirmSignupApi,
  profileQuery,
  reqAuthApi,
  reqSignupConfirmationApi,
  signupApi,
  switchAccountApi,
  createAccountApi,
  createResellerApi,
} from 'modules/auth/api/auth';
import { IAccountProfile, IAccount, IUserForm, IInvitation, IResellerForm } from 'modules/auth/interfaces/auth';
import { AccountProfile } from 'modules/auth/models/account-profile';
import { actions } from 'modules/auth/reducers/authReducer';
import { ApolloClientFactory } from 'modules/core/constants/client';
import { axiosErrors } from 'modules/core/utils/axios';
import { Dispatch, AnyAction } from 'redux';
import { Action } from 'redux-symbiote/types';
import { ThunkDispatch, ThunkAction } from 'redux-thunk';
import { IReduxState } from 'store';
import { AxiosResponse } from 'axios';
import { setAccountProfile, identifyWithSIB } from 'modules/auth/utils';
import { setEditorWalkthroughAsFinished } from 'modules/editor/utils';

const apollo = (dispatch: ThunkDispatch<{}, {}, Action<any>>) => {
  return ApolloClientFactory((args) => {
    const err: ServerError = args.networkError as ServerError;

    if (err && err.statusCode === 401) {
      dispatch(actions.setPending(false));
      dispatch(actions.signout());
    };
  });
};

export const getProfile = () => {
  return async (dispatch: ThunkDispatch<{}, {}, Action<any>>) => {
    dispatch(actions.setLoadingProfile(true));
    dispatch(actions.setPending(true));

    try {
      const { data } = await apollo(dispatch).query<{ account: IAccountProfile }>({ query: profileQuery });

      if (data) {
        await setAccountProfile(dispatch, data.account);
        identifyWithSIB(data.account);
      }
    } catch (err) {
      console.warn(err);
    } finally {
      dispatch(actions.setPending(false));
      dispatch(actions.setLoadingProfile(false));
    }
  };
};

export const reloadProfile = () => {
  return async (dispatch: ThunkDispatch<{}, {}, Action<any>>) => {
    dispatch(actions.setLoadingProfile(true));
    dispatch(actions.setPending(true));

    try {
      const { data } = await apollo(dispatch).query<{ account: IAccountProfile }>({ query: profileQuery });

      if (data) {
        dispatch(actions.setProfile(new AccountProfile(data.account)));
      }
    } catch (err) {
      console.warn(err);
    }

    dispatch(actions.setPending(false));
    dispatch(actions.setLoadingProfile(false));
  };
};

export const reqEmailAuth = (email: string) => async (dispatch: Dispatch<any>) => {
  dispatch(actions.setPending(true));

  return reqAuthApi({ 'user[email]': email })
    .then(() => {
      dispatch(actions.requestEmail(true));
    })
    .catch((e) => {
      dispatch(actions.requestEmail(false));
      dispatch(actions.setErrorMessage(axiosErrors(e)));
      throw e;
    })
    .finally(() => dispatch(actions.setPending(false)));
};

export const reqMobileCode = (mobilePhone: string) => async (dispatch: Dispatch<any>) => {
  dispatch(actions.setPending(true));

  return reqAuthApi({ 'user[mobile_phone]': mobilePhone })
    .then(() => {
      dispatch(actions.requestCode(true));
    })
    .catch((e) => {
      dispatch(actions.requestCode(false));
      dispatch(actions.setErrorMessage(axiosErrors(e)));
      throw e;
    })
    .finally(() => dispatch(actions.setPending(false)));
};

const authenticate = (body: any) => (dispatch: Dispatch<any>) => {
  dispatch(actions.setPending(true));

  return authenticateApi(body)
    .then(async (res) => {
      dispatch(actions.setAuthToken(res.headers.authorization));
      await getProfile()(dispatch);
    })
    .catch((e) => {
      dispatch(actions.setErrorMessage(axiosErrors(e)));
      throw e;
    })
    .finally(() => {
      dispatch(actions.setPending(false))
    });
};

export const authenticateByEmail = (email: string, token: string, throughReseller?: string) =>
  authenticate({ user: { email, authenticate_email_token: token, through_reseller: throughReseller } });

export const authenticateByPhone = (phone: string, code: string, throughReseller?: string) =>
  authenticate({ user: { mobile_phone: phone, authenticate_phone_code: code, through_reseller: throughReseller } });

export function signupUser(
  form: IUserForm
): ThunkAction<Promise<AxiosResponse<any> | undefined>, IReduxState, null, AnyAction> {
  return (dispatch: Dispatch) => {
    try {
      dispatch(actions.setPending(true));
      const { company_name, firstName, lastName, email, mobilePhone, country } = form;
      return signupApi({
        company_name,
        user: {
          first_name: firstName,
          last_name: lastName,
          email,
          mobile_phone: mobilePhone,
          country_code: country,
          through_reseller: form.through_reseller,
          subscribe_gdpr_news: form.subscribe_gdpr_news,
          through_campaign: form.through_campaign,
          share_card_id: form.share_card_id,
        },
      });
    } catch (error) {
      dispatch(actions.setErrorMessage(axiosErrors(error)));
      throw error;
    } finally {
      dispatch(actions.setPending(false));
    }
  };
}

export const confirmSignup = (token: string) => (dispatch: Dispatch<any>) => {
  dispatch(actions.setPending(true));

  return confirmSignupApi({ confirmation_token: token })
    .then((res) => {
      dispatch(actions.setAuthToken(res.headers.authorization));
      return getProfile()(dispatch);
    })
    .catch((e) => {
      dispatch(actions.setErrorMessage(axiosErrors(e)));
      throw e;
    })
    .finally(() => dispatch(actions.setPending(false)));
};

export function reqSignupEmail(
  email: string
): ThunkAction<Promise<AxiosResponse<any> | undefined>, IReduxState, null, AnyAction> {
  return (dispatch: Dispatch) => {
    try {
      dispatch(actions.setPending(true));
      return reqSignupConfirmationApi({}, { params: { email } });
    } catch (error) {
      dispatch(actions.setErrorMessage(axiosErrors(error)));
      throw error;
    } finally {
      dispatch(actions.setPending(false));
    }
  };
}

export function switchAccount(accountId: number, currentAccount: IAccount) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setPending(true));
      return await switchAccountApi(accountId)
        .then(() => setEditorWalkthroughAsFinished(currentAccount.type));
    } catch (error) {
      dispatch(actions.setErrorMessage(axiosErrors(error)));
      throw error;
    } finally {
      dispatch(actions.setPending(false));
    }
  };
}

export function createAccount(currentAccount: IAccount) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setPending(true));
      const res = await createAccountApi()
        .then(() => setEditorWalkthroughAsFinished(currentAccount.type));
      return res;
    } catch (error) {
      dispatch(actions.setErrorMessage(axiosErrors(error)));
      throw error;
    } finally {
      dispatch(actions.setPending(false));
    }
  };
}

export function signupReseller(
  invitation: IInvitation, form: IResellerForm
): ThunkAction<Promise<AxiosResponse<any> | undefined>, IReduxState, null, AnyAction> {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setPending(true));
      const data = await createResellerApi(invitation, form);

      if (data && data.createReseller) {
        const token = data.createReseller.auth ? (data.createReseller.auth as string) : null;
        if (token) {
          dispatch(actions.setAuthToken(token as string));
        }

        if (data.createReseller.account) {
          dispatch(actions.setLoadingProfile(true));
          setAccountProfile(dispatch, data.createReseller.account)
          dispatch(actions.setLoadingProfile(false));
        } else if (data.createReseller.errors && data.createReseller.errors.length > 0) {
          throw new Error(data.createReseller.errors.join('. '))
        } else {
          throw new Error('Account profile data is blank or invalid')
        }
      }

      return data
    } catch (error) {
      dispatch(actions.setErrorMessage(axiosErrors(error)));
      throw error;
    } finally {
      dispatch(actions.setPending(false));
    }
  };
}
