import { createSymbiote, Symbiotes } from 'redux-symbiote';
import { IL10nData } from '../api/currency';

export interface IGeneralState {
  currency: string;
  vat: boolean;
  country_code: string;
}

const initialState: IGeneralState = {
  currency: '£',
  vat: true,
  country_code: 'gb',
};

export interface IGeneralActions extends Symbiotes<IGeneralState> {
  setL10nData: (state: IGeneralState, currency: IL10nData) => IGeneralState;
}

export const { actions, reducer } = createSymbiote<IGeneralState, IGeneralActions>(initialState, {
  setL10nData: (state, l10n: IL10nData) => ({ ...state, ...l10n }),
});
