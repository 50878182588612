import { useTypedSelector } from 'hooks/useTypedSelector';
import { SIGNIN_ROUTE } from 'routes/routes';
import { useMemo } from 'react';

export function useUserName(): string {
  const { profile, isAuthenticated } = useTypedSelector((state) => state.auth);
  return useMemo(() => {
    if (isAuthenticated) {
      if (profile) {
        return profile.getUserName();
      }
    } else {
      const { subtitle = '' } = SIGNIN_ROUTE;
      return subtitle;
    }
    return '';
  }, [isAuthenticated, profile]);
}
