import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { limeade, darkGreen, lightGreen, green } from 'themes/colors';

export const useStyles = makeStyles({
  textField: {
    marginBottom: 50,
  },
  fullWidth: {
    width: '100%',
  },
  paper: {
    maxWidth: 700,
    height: 640,

    '&._shareCardDialog': {
      maxWidth: 540,
    },
  },
  sharedIcon: {
    fontSize: 120,
    lineHeight: 1,
    color: darkGreen,
  },
  title: {
    fontSize: 30,
    lineHeight: 4 / 3,
  },
});

export const useCardProcessingDialogStyles = makeStyles({
  container: {
    padding: 20,
    textAlign: 'center',
  },
  title: {
    fontSize: '20px',
    marginBottom: 32,
  },
  message: {
    marginTop: 32,
  },
  errorMessage: {
    marginTop: 8,
    marginBottom: 8,
  },
  refreshButton: {
    marginTop: 16,
  },
  dialogPaper: {
    width: "100%",
    transition: "height 0.5s",
  },
  contactLink: {
    textDecoration: 'underline',
  },
});


export const PublishWarningDialogStyles = makeStyles({
  title: {
    textAlign: 'center',
    fontSize: '20px',
  },
  actionsRoot: {
    justifyContent: 'space-between',
    marginTop: -24,
  },
  heading6: {
    marginBottom: 20,
  },
  text: {
    '& p': {
      lineHeight: 1.2,
      fontSize: '16px',
      paddingBottom: '20px',
      color: 'rgba(1,61,31,0.5)',
    },
  },
});

export const helpModal = makeStyles((theme: Theme) =>
  createStyles({
    helpWindow: {
      width: '540px',
      [theme.breakpoints.between('xs', 'sm')]: {
        width: '315px',
      },
    },
    titleDialog: {
      backgroundColor: limeade,
      transition: 'background-color .5s',
      '&:hover': {
        backgroundColor: '#8CA994',
        transition: 'background-color .5s',
      },
    },
    head: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    title: {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 'bold',
    },
    content: {
      textAlign: 'center',
      color: '#8CA994',
    },
    contentBlock: {
      marginBottom: 10,
    },
    tip: {
      textTransform: 'uppercase',
      fontSize: '.7rem',
      fontWeight: 'bold',
    },
    watch: {
      textTransform: 'uppercase',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
      marginTop: theme.spacing(2),
    },
    steps: {
      position: 'absolute',
      bottom: '-60px',
    },
    helpDialog: {
      '& div[role=dialog]': {
        overflowY: 'visible',
      },
    },
    stepper: {
      '& div [class*="-dot-"]': {
        border: `2px solid ${limeade}`,
        width: 12,
        height: 12,
        boxSizing: 'border-box',
        backgroundColor: 'transparent',
      },
      '& div [class*="-dotActive-"]': {
        backgroundColor: limeade,
      },
    },
    helpContainer: {
      fontSize: '7.5rem',
      textAlign: 'center',
      color: '#8CA994',
      height: 120,
      marginBottom: '1rem',
    },
    stepIcon: {
      color: limeade,
    },
  })
);

export const videoModal = makeStyles((theme: Theme) =>
  createStyles({
    closeBtn: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: 25,
      height: 25,
      padding: 0,
      zIndex: 100,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'white',
      '&:hover': {
        background: 'rgba(255,255,255, 0.85)',
      },
      '& .MuiIconButton-label': {
        width: 25,
        height: 25,
        alignItems: 'center',
        justifyContent: 'center',
      },
      '& svg': {
        width: 20,
        height: 20,
        fill: '#013D1F',
        borderRadius: '50%',
      },
    },
    videoWrapper: {
      position: 'relative',
      overflow: 'auto',
      padding: 20,
      '& .MuiPaper-root-280': {
        maxWidth: 'unset!important',
        minWidth: 630,
        [theme.breakpoints.down('md')]: {
          minWidth: 'unset',
          width: '100vh',
          height: '100vh',
        },
      },
    },
    paper: {
      position: 'fixed',
      minWidth: 630,
      top: '50%!important',
      left: '50%!important',
      transform: 'translate(-50%, -50%)!important',
      [theme.breakpoints.down('xs')]: {
        minWidth: 'unset',
        width: '100%',
      },
    },
  })
);

export const useSelectPhoneNumberDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconWrapper: {
      boxShadow: '0 10px 20px 0 rgba(0,0,0,0.25)',
      width: '50px',
      height: '50px',
      color: 'white',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&.Mui-disabled': {
        color: 'white',
      },
      '&.sm': {
        width: '30px',
        height: '30px',
        padding: 0,
        backgroundColor: 'white',
        border: '1px solid #000000',
        boxShadow: 'none',
        color: '#000000',
      },
    },
    card: {
      position: 'relative',
      padding: 30,
      textAlign: 'center',
      color: '#333333',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    biggerIcon: {
      width: '100px',
      height: '100px',
      marginBottom: '30px',
      backgroundColor: 'rgba(1,61,31,1)',

      '& .icon': {
        fontSize: '60px',
        color: 'white',
      },

      [theme.breakpoints.down(360)]: {
        width: '80px',
        height: '80px',

        '& .icon': {
          fontSize: '50px',
        },
      },
    },
    fullWidth: {
      width: '100%',
    },
    paper: {
      maxWidth: 700,
      height: 640,
    },
    sub: {
      marginBottom: theme.spacing(10),
    },
    phoneType: {
      fontSize: '12px',
    },
    phoneNumber: {
      color: darkGreen,
      fontSize: '20px',
      fontWeight: 'bold',
      letterSpacing: 1.4,
      marginBottom: theme.spacing(3),
    },
    callButton: {
      justifyContent: 'space-between',
      maxWidth: 'unset',
      marginBottom: theme.spacing(2.5),
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),

      '& .phone-type': {
        fontWeight: 'bold',
        marginRight: theme.spacing(2),
        [theme.breakpoints.down(360)]: {
          fontSize: 12,
        },
      },
      '& .phone-number': {
        fontWeight: 'normal',
        [theme.breakpoints.down(360)]: {
          fontSize: 12,
        },
      },
    },
    actionsRoot: {
      justifyContent: 'center',
      flexWrap: 'wrap',

      '& > :not(:first-child)': {
        marginLeft: 0,
      },
    },
  })
);

export const useShareCardStyles = makeStyles({
  title: {
    fontSize: 24,
    lineHeight: 1.5,
    textAlign: 'center',
    marginBottom: 16,
  },
  subtitle: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 16,
  },
  bodyText: {
    fontSize: 16,
    textAlign: 'center',
    fontFamily: 'F37 Ginger Light',
    color: green,
    maxWidth: 320,
    margin: '0 auto 16px',
  },
  formSwitcher: {
    display: 'flex',
    width: '100%',
    marginTop: 32,
    marginBottom: 32,
    borderBottom: '1px solid rgba(0,0,0,0.2)',
  },
  formSwitcherSeparator: {
    display: 'inline-flex',
    width: '1px',
    color: 'black',
    opacity: 0.2,
    height: 16,
    borderRight: '1px solid black',
  },
  formSwitcherOption: {
    display: 'inline-flex',
    width: '100%',
    textAlign: 'center',
    textTransform: 'uppercase',
    minHeight: 32,
    cursor: 'pointer',
    borderColor: lightGreen,
    transition: 'background-color .25s ease, color .25s ease, border-color .25s ease',

    '&._active': {
      borderBottom: `3px ${lightGreen} solid`,
      color: lightGreen,
    },
  },
  formSwitcherText: {
    width: "100%",
    textAlign: 'center',
  },
});
