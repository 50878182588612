import {
  ApolloClient,
  DefaultOptions,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ErrorLink, onError } from '@apollo/client/link/error';
import gql from 'graphql-tag';
import { loadAuthToken, loadVisitorKey, encodedAuthToken } from 'modules/core/utils/api';
import { HOST_URL } from './config';
import { createConsumer } from '@rails/actioncable';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';

const httpLink = createHttpLink({ uri: `${HOST_URL}/graphql`, credentials: 'include' });

const authLink = setContext((_, { headers }) => {
  const token = loadAuthToken() || "";
  const visitorKey = loadVisitorKey();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token,
      visitorKey,
    },
  };
});

const hasSubscriptionOperation = ({ query: { definitions } }: { query: any }) => {
  return definitions.some(
    ({ kind, operation }: any) => kind === 'OperationDefinition' && operation === 'subscription'
  )
};

const getCableUrl = () => {
  const origin = HOST_URL || "";
  const wsUrl = origin.replace("http", "ws");
  const token = encodedAuthToken();

  return `${wsUrl}/cable?token=${token}`;
};

const cable = createConsumer(getCableUrl);

// connectionParams are available server-side in the channel, not the connection
//
const websocketsLink = new ActionCableLink({
  cable,
  // connectionParams: {
  //   authToken: loadAuthToken(),
  // },
});

// if hasSubscriptionOperation returns true for a request, it returns new ActionCableLink, else httpLink
const httpOrWebsocketsLink = ApolloLink.split(
  hasSubscriptionOperation,
  websocketsLink,
  httpLink
);

const apolloCache = new InMemoryCache();
const defaultOptions: DefaultOptions = {
  query: {
    fetchPolicy: 'network-only',
  },
};

const errorLink = onError(function () {
  console.log("[ApolloClient]: error", arguments);
});

export const apoloClient = new ApolloClient({
  cache: apolloCache,
  link: ApolloLink.from([errorLink, authLink, httpOrWebsocketsLink]),
  defaultOptions,
});

export const ApolloClientFactory = (errorHandler?: ErrorLink.ErrorHandler) => {
  let links = [authLink, httpOrWebsocketsLink];

  if (errorHandler) {
    links = [onError(errorHandler), ...links];
  }

  return new ApolloClient({
    cache: apolloCache,
    link: ApolloLink.from(links),
    defaultOptions,
  });
};

export const QUERY_TEXT = gql`
  query {
    card {
      id
      urlSlug
    }
  }
`;
