import React, { CSSProperties } from 'react';
import { Box } from '@material-ui/core';
import { E_PREVIEW_TYPE } from 'modules/editor/constants/enums';
import { useStyles } from 'modules/editor/components/CardSectionsView/MainInfo/useStyles';
import { Names } from './Names';
import { ICardVersion } from 'modules/editor/graphql/gql';
import { Actions } from './Actions';
import { Socials } from 'modules/editor/components/CardSectionsView/MainInfo/Socials';
import { ShareButton } from 'modules/editor/components/CardSectionsView/MainInfo/ShareButton';

interface INameProps {
  iconStyle: CSSProperties;
  titleStyle: CSSProperties;
  bodyStyle: CSSProperties;
  isPreview?: boolean;
  previewType: E_PREVIEW_TYPE;
  cardVer: ICardVersion;
}

/**
 * Main section view for card. Display user avatar, user name, social and relation buttons and other
 */
export const MainInfo: React.FC<INameProps> = (props) => {
  const { iconStyle, titleStyle, bodyStyle, isPreview, previewType, cardVer } = props;
  const classes = useStyles();

  const disabled = !!isPreview;
  return (
    <Box>
      <Names titleStyle={titleStyle} bodyStyle={bodyStyle} cardVer={cardVer} />
      <Actions cardVer={cardVer} disabled={disabled} previewType={previewType} />
      <div className={classes.second}>
        <Socials cardVer={cardVer} disabled={disabled} iconStyle={iconStyle} />
        <ShareButton cardVer={cardVer} disabled={disabled} />
      </div>
    </Box>
  );
};
