import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Divider } from '@material-ui/core';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import { checkEmail } from 'modules/auth/api/validation';
import AuthPinSent from 'modules/auth/components/Authorization/AuthPinSent';
import SignupStep1 from 'modules/auth/components/Authorization/SignupStep1';
import SignupStep2 from 'modules/auth/components/Authorization/SignupStep2';
import SignupStep3 from 'modules/auth/components/Authorization/SignupStep3';
import SignupStepper from 'modules/auth/components/Authorization/SignupStepper';
import { actions } from 'modules/auth/reducers/authReducer';
import { reqSignupEmail, signupUser } from 'modules/auth/thunks/auth';
import { useDefaultManifest } from 'modules/core/utils';
import { validateEmail } from 'modules/core/utils/validate';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { useStyles } from './useStyles';
import { useRedirectToEditor } from 'hooks/useRedirectToEditor';

export const SignUp: React.FC = () => {
  const userForm = useTypedSelector((state) => state.auth.signup.values);
  const errors = useTypedSelector((state) => state.auth.signup.errors);
  const [step, setStep] = useState<number>(0);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isHidden, setHidden] = useState(true);
  const [isPrevHidden, setIsPrevHidden] = useState(true);

  useDefaultManifest();
  useRedirectToEditor();

  const setSignupUserValues = useCallback(
    (prop: string, value: any): void => {
      dispatch(actions.setSignupUserValues(prop, value));
    },
    [dispatch]
  );

  const setSignupErrors = useCallback(
    (prop: string, value: any): void => {
      dispatch(actions.setSignupErrors(prop, value));
    },
    [dispatch]
  );

  useEffect(() => {
    const throughReseller = Cookies.get('throughReseller');
    setSignupUserValues('through_reseller', throughReseller || null);
    dispatch(actions.setErrorMessage(null));
  }, [setSignupUserValues, dispatch]);

  useEffect(() => {
    const throughCampaign = Cookies.get('throughCampaign');
    setSignupUserValues('through_campaign', throughCampaign || null);
    dispatch(actions.setErrorMessage(null));
  }, [setSignupUserValues, dispatch]);

  useEffect(() => {
    const shareCardId = Cookies.get('shareCardId');
    setSignupUserValues('share_card_id', shareCardId || null);
    dispatch(actions.setErrorMessage(null));
  }, [setSignupUserValues, dispatch]);

  useEffect(() => {
    if (step > 0) {
      setIsPrevHidden(false);
    } else {
      setIsPrevHidden(true);
    }
  }, [step, setIsPrevHidden]);

  const reqEmail = useCallback(async () => {
    const { email } = userForm;
    try {
      await dispatch(reqSignupEmail(email));
    } catch (e) {
      console.warn(e);
    }
  }, [userForm, dispatch]);

  const handleChange = (prop: string, value: any) => {
    setSignupUserValues(prop, value);
  };

  const checkVisibility = useCallback(() => {
    let canGo = false;
    switch (step) {
      case 0:
        userForm.firstName.length >= 2 && userForm.lastName.length >= 2 ? (canGo = true) : (canGo = false);
        break;
      case 1:
        validateEmail(userForm.email) && userForm.country.length > 0 && !errors.email && !errors.mobilePhone
          ? (canGo = true)
          : (canGo = false);
        break;
      case 2:
        canGo = true;
        break;
      default:
        canGo = false;
        break;
    }
    setHidden(!canGo);
  }, [userForm, errors, step]);

  const handleContinue = useCallback(
    async (e: any) => {
      let targetStep = step + 1;
      let targetAction = 'next';
      try {
        targetStep = parseInt(e.currentTarget.getAttribute('data-step'), 10);
        targetAction = e.currentTarget.getAttribute('data-action');
      } catch (e) {
        console.warn(e);
      }

      switch (targetAction) {
        case 'prev':
          setStep(targetStep);
          break;
        case 'next':
          if (!isHidden) {
            switch (targetStep) {
              case 1:
                setStep(targetStep);
                break;
              case 2:
                if (userForm.email) {
                  const asyncF = async () => {
                    const emailAvailable = await checkEmail(userForm.email).then((response) => {
                      if (response && response.data) {
                        const { available, message } = response.data;
                        if (!available) {
                          setSignupErrors('email', message);
                        } else {
                          setSignupErrors('email', '');
                        }
                        return available;
                      }
                    });
                    if (emailAvailable) {
                      setStep(2);
                    } else {
                      setStep(1);
                    }
                  };
                  asyncF();
                }
                break;
              case 3:
                try {
                  await dispatch(signupUser(userForm));
                  setStep(targetStep);
                } catch (e) {
                  console.warn(e);
                }
                break;
            }
          }
          break;
        default:
          break;
      }
    },
    [step, dispatch, userForm, isHidden, setSignupErrors]
  );

  const stepBottom = useMemo(() => {
    return (
      <SignupStepper
        steps={3}
        handleClick={handleContinue}
        step={step}
        isNextHidden={isHidden}
        isPrevHidden={isPrevHidden}
      />
    );
  }, [step, handleContinue, isHidden, isPrevHidden]);

  useLayoutEffect(() => {
    checkVisibility();
  });

  switch (step) {
    case 0:
      return (
        <>
          <SignupStep1
            onSubmit={handleContinue}
            filledValues={{ firstName: userForm.firstName, lastName: userForm.lastName }}
            bottom={stepBottom}
            isNextHidden={isHidden}
            onChange={handleChange}
          />
          <Divider className={clsx(classes.hr, '_marginLg')} />
        </>
      );
    case 1:
      return (
        <>
          <SignupStep2
            filledValues={{ email: userForm.email, country: userForm.country }}
            onSubmit={handleContinue}
            bottom={stepBottom}
            isNextHidden={isHidden}
            onChange={handleChange}
            setErrors={setSignupErrors}
            errors={errors}
          />
          <Divider className={clsx(classes.hr, '_marginLg')} />
        </>
      );
    case 2:
      return (
        <>
          <SignupStep3
            filledValues={{ company_name: userForm.company_name, subscribe_gdpr_news: userForm.subscribe_gdpr_news }}
            onSubmit={handleContinue}
            bottom={stepBottom}
            onChange={handleChange}
          />
          <Divider className={clsx(classes.hr, '_marginLg')} />
        </>
      );
    case 3:
      return (
        <>
          <AuthPinSent resendEmail={reqEmail} email={userForm.email} isAfterRegister={true} />
          <Divider className={clsx(classes.hr, '_marginLg')} />
        </>
      );
    default:
      return null;
  }
};
