import { IGallerySection, IGalleryImage } from 'modules/editor/graphql/gql';

export class GallerySection implements IGallerySection {
  public id?: number;
  public title?: string;
  public gallerySectionImages: GallerySectionImage[] = [];
  public showSectionHeader?: boolean;

  constructor(section?: IGallerySection) {
    if (section) {
      this.id = section.id;
      this.title = section.title;
      this.showSectionHeader = section.showSectionHeader;
      this.gallerySectionImages = section.gallerySectionImages
        ? section.gallerySectionImages.map((img) => new GallerySectionImage(img))
        : [];
    }
  }
}

export class GallerySectionImage implements IGalleryImage {
  public id!: number;
  public galleryImageUrl!: string;
  public galleryImageUrlOriginal!: string;
  public galleryImageUrlThumbnail!: string;
  public description!: string;
  public linkUrl!: string;

  constructor(image: IGalleryImage) {
    this.id = image.id;
    this.galleryImageUrl = image.galleryImageUrl;
    this.galleryImageUrlOriginal = image.galleryImageUrlOriginal;
    this.galleryImageUrlThumbnail = image.galleryImageUrlThumbnail;
    this.description = image.description || '';
    this.linkUrl = image.linkUrl || '';
  }
}
