import React, { useState, Dispatch, SetStateAction } from 'react';
import { useStyles } from 'modules/editor/components/CardSectionEditors/About/useStyles';
import { Typography } from '@material-ui/core';
import { LinkItem } from './LinkItem';
import { AddItem } from './AddItem';
import { AboutAtributes, AboutSectionLinkAtributes } from 'modules/editor/models';
import { IFileReadResult } from 'modules/core/utils';

interface IProps {
  about: AboutAtributes;
  setOverlay: (value: boolean) => void;
  save: (state: AboutAtributes) => Promise<void>;
  setAbout: Dispatch<SetStateAction<AboutAtributes>>;
}

export const Links: React.FC<IProps> = (props) => {
  const { about, setOverlay, save, setAbout } = props;
  const { fieldBlock, sectionHeader } = useStyles();
  // new link state
  const [newItem, setNewItem] = useState<AboutSectionLinkAtributes | null>(
    about.aboutSectionLinksAttributes.length ? null : new AboutSectionLinkAtributes()
  );

  // acepts callback that will imperativly mutate new state
  function updateState(dangerousMutations: (state: AboutAtributes) => void): void {
    setAbout((oldState) => {
      const newState = oldState.nextState(oldState);
      dangerousMutations(newState);
      return newState;
    });
  }

  function updateNewItem(dangerousMutations: (state: AboutSectionLinkAtributes) => void): void {
    setNewItem((oldState) => {
      if (oldState) {
        const newState = oldState.nextState(oldState);
        dangerousMutations(newState);
        return newState;
      }
      return oldState;
    });
  }

  // returns link from passed state with passed id
  const getLink = (state: AboutAtributes, id?: number): AboutSectionLinkAtributes | undefined =>
    state.aboutSectionLinksAttributes.find((item) => item.id === id);

  function onAddNewItem(): void {
    setNewItem(new AboutSectionLinkAtributes());
  }

  function onDeleteNewItem(): void {
    setNewItem(null);
  }

  async function onDelete(id?: number): Promise<void> {
    const { aboutSectionLinksAttributes: oldLinks } = about;
    const newLinks = oldLinks.map<AboutSectionLinkAtributes>((item) => {
      if (item.id === id) {
        item._destroy = true;
        return item;
      }
      return item;
    });
    const newState = about.nextState(about);
    newState.aboutSectionLinksAttributes = newLinks;
    await save(newState);
  }

  function changeUrl(value: string, id?: number): void {
    updateState((state) => {
      const link = getLink(state, id);
      if (link) {
        link.linkUrl = value;
        link.displayLink = value;
      }
    });
  }

  function changeNewItemUrl(value: string): void {
    updateNewItem((state) => {
      state.linkUrl = value;
      state.displayLink = value;
    });
  }

  function onChangeText(linkText: string = '', id?: number): void {
    updateState((state) => {
      const link = getLink(state, id);
      if (link) {
        link.linkText = linkText;
      }
    });
  }

  function onChangeNewItemText(value: string = ''): void {
    updateNewItem((state) => {
      state.linkText = value;
    });
  }

  function processFile(res: IFileReadResult, link: AboutSectionLinkAtributes): void {
    const { base64data, filename } = res;
    link.fileBase64 = base64data;
    link.fileName = filename;
    link.displayLink = filename;
    link.linkUrl = undefined;
  }

  async function saveWithOverlay(newState: AboutAtributes): Promise<void> {
    setOverlay(true);
    await save(newState);
    setOverlay(false);
  }

  async function uploadFile(res: IFileReadResult, id?: number): Promise<void> {
    const newState = about.nextState(about);
    const link = getLink(newState, id);
    if (link) {
      processFile(res, link);
      await saveWithOverlay(newState);
    }
  }

  function addNewItemToState(link: AboutSectionLinkAtributes): AboutAtributes {
    const newState = about.nextState(about);
    const links = newState.aboutSectionLinksAttributes;
    newState.aboutSectionLinksAttributes = [...links, link];
    return newState;
  }

  async function uploadNewItemFile(res: IFileReadResult): Promise<void> {
    if (newItem) {
      const newStateNewItem = newItem.nextState(newItem);
      processFile(res, newStateNewItem);
      const newState = addNewItemToState(newStateNewItem);
      await saveWithOverlay(newState);
      onDeleteNewItem();
    }
  }

  function getFileError(fileName: string, id?: number): void {
    updateState((state) => {
      const currentLink = getLink(state, id);
      if (currentLink) {
        currentLink.fileName = fileName;
        currentLink.displayLink = fileName;
      }
    });
  }

  function getNewFileError(fileName: string): void {
    updateNewItem((state) => {
      state.fileName = fileName;
      state.displayLink = fileName;
    });
  }

  function onFocus(id?: number): void {
    updateState((state) => {
      const link = getLink(state, id);
      if (link && link.fileUrl) {
        link.setDisplayLink();
      }
    });
  }

  function onNewItemFocus(): void {
    updateNewItem((state) => {
      if (state.fileUrl) {
        state.setDisplayLink();
      }
    });
  }

  function saveCurrent(): void {
    save(about);
  }

  async function saveWithNewItem(): Promise<void> {
    if (newItem) {
      const newState = addNewItemToState(newItem);
      await save(newState);
      onDeleteNewItem();
    }
  }

  return (
    <>
      <div className={fieldBlock}>
        <Typography className={sectionHeader}>Links</Typography>
        {about.aboutSectionLinksAttributes.map((el, index: number) => {
          const count = index + 1;
          const { id } = el;
          return (
            <LinkItem
              data={el}
              count={count}
              key={id}
              onDelete={() => onDelete(id)}
              getFileError={(error) => getFileError(error, id)}
              onFocus={() => onFocus(id)}
              uploadFile={(fileName) => uploadFile(fileName, id)}
              changeUrl={(value) => changeUrl(value, id)}
              onChangeText={(value) => onChangeText(value, id)}
              save={saveCurrent}
            />
          );
        })}
        {newItem && (
          <LinkItem
            data={newItem}
            count={about.aboutSectionLinksAttributes.length + 1}
            getFileError={getNewFileError}
            onDelete={onDeleteNewItem}
            onFocus={onNewItemFocus}
            uploadFile={uploadNewItemFile}
            changeUrl={changeNewItemUrl}
            onChangeText={onChangeNewItemText}
            save={saveWithNewItem}
          />
        )}
      </div>
      <AddItem onAdd={onAddNewItem} isAdd={!!newItem} />
    </>
  );
};
