import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    color: 'white',
    overflow: 'hidden',
  },
  nameClass: {
    width: '100%',
    fontSize: '30px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tagClass: {
    width: '100%',
    fontSize: '20px',
    textAlign: 'center',
    marginTop: 8,
  },
  logo: {
    width: '100%',
    height: '100px',
    objectFit: 'contain',
  },
  iconClass: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
});
