import React, { forwardRef } from 'react';
import ReactCropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

interface IProps {
  src: string;
}

/**
 * Configures react-cropper component
 */
export const Cropper = forwardRef<ReactCropper, IProps>((props, ref) => {
  const { src } = props;

  return (
    <ReactCropper
      src={src}
      ref={ref}
      style={{ maxWidth: 'calc(100vw - 30px)', maxHeight: 'calc(100vh - 120px)' }}
      autoCropArea={0.8}
      background={false}
      guides={false}
      minCropBoxHeight={100}
      minCropBoxWidth={400}
      initialAspectRatio={1}
    />
  );
});
