import { ID } from 'modules/core/types';
import { IShareCardData } from '../graphql/gql';

export class ShareCardData implements IShareCardData {
  public id!: ID;
  public email = '';
  public emailBtn = true;
  public mobilePhone = '';
  public callBtn = false;
  public name = '';
  public contactRequestCreated = false;

  constructor(shareData?: IShareCardData) {
    if (shareData) {
      this.id = shareData.id;
      this.email = shareData.email;
      this.emailBtn = shareData.emailBtn;
      this.mobilePhone = shareData.mobilePhone;
      this.callBtn = shareData.callBtn;
      this.name = shareData.name;
      this.contactRequestCreated = shareData.contactRequestCreated;
    }
  }
}
