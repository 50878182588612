import React, { CSSProperties } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box } from '@material-ui/core';
import { isNumber } from 'util';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: 'fit-content',
    zIndex: 3,
    '& svg': {
      fill: 'white',
    },
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default function CircularIndeterminate(props: {
  size?: number;
  my?: number;
  color?: 'inherit' | 'primary' | 'secondary';
  style?: CSSProperties;
}) {
  const { size, my, color, style } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root} my={isNumber(my) ? my : 2} mx="auto" style={style}>
      <CircularProgress size={size} color={color} />
    </Box>
  );
}
