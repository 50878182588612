import { IPromotionAtributes } from 'modules/editor/graphql/mutations';
import { ICardVersion } from 'modules/editor/graphql/gql';
import { IModel } from 'modules/core/interfaces/model';
import { clearObject } from 'modules/core/utils';
import { URL_INVALID } from 'modules/editor/constants/messages';
import { ObjectSchema, object, string } from 'yup';
import { URL_REGEXP } from 'modules/core/constants';

export class PromotionAtributes implements IModel<IPromotionAtributes> {
  public cardVersionId!: number;
  public linkText?: string = '';
  public linkUrl?: string = '';
  public linkUrlShow?: boolean = false;
  public title?: string = '';
  public showSectionHeader?: boolean = true;
  public promotionImageBase64?: string;
  public promotionImageName?: string;
  [props: string]: any;

  private schema: ObjectSchema<Partial<IPromotionAtributes>> = object().shape({
    linkUrl: string().matches(URL_REGEXP, { excludeEmptyString: false, message: URL_INVALID }),
  });

  constructor(card?: ICardVersion) {
    if (card) {
      this.cardVersionId = card.id;
      this.linkText = card.promotionSection.linkText || '';
      this.linkUrl = card.promotionSection.linkUrl || '';
      this.linkUrlShow = card.promotionSection.linkUrlShow;
      this.title = card.promotionSection.title || '';
      // ensure that default value for showSectionHeader is true. Necessary for when the section does not yet exist in db.
      this.showSectionHeader = card.promotionSection.showSectionHeader === false ? false : true;
    }
  }

  public asState() {
    const state: any = {};
    Object.keys(this).forEach((key) => (state[key] = this[key]));
    return state;
  }

  public nextState(state: PromotionAtributes) {
    const atr = new PromotionAtributes();
    Object.keys(atr).forEach((key) => (atr[key] = state[key]));
    return atr;
  }

  public clear(nullable: boolean) {
    const atr = new PromotionAtributes();
    clearObject(atr, nullable);
    atr.cardVersionId = this.cardVersionId;
    atr.schema = object().shape({
      linkUrl: string().matches(URL_REGEXP, URL_INVALID),
    });
    return atr;
  }

  public toJSON(): IPromotionAtributes {
    return {
      cardVersionId: this.cardVersionId,
      linkText: this.linkText ? this.linkText : null,
      linkUrl: this.linkUrl ? this.linkUrl : null,
      linkUrlShow: this.linkUrlShow,
      title: this.title ? this.title : null,
      showSectionHeader: this.showSectionHeader ? this.showSectionHeader : true,
      promotionImageBase64: this.promotionImageBase64,
      promotionImageName: this.promotionImageName,
    };
  }

  public validate(path: string) {
    return this.schema.validateSyncAt(path, this.asState());
  }
}
