import { Hidden, IconButton, Link, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { ReactComponent as LogoSvg } from 'assets/svg/logo.svg';
import clsx from 'clsx';
import React from 'react';
import { Else, If, Then } from 'react-if';
import { HOME_ROUTE, LANDING_ROUTE } from 'routes/routes';
import { useStyles } from './styles';
import { CardToolbar } from '../CardToolbar';

interface IMobileMenu {
  isPublic: boolean;
  pageTitle?: string;
  open: boolean;
  handleDrawerOpen: () => void;
}

export const CardMenu: React.FC<IMobileMenu> = ({ open, isPublic, pageTitle, handleDrawerOpen }) => {
  const classes = useStyles();
  const setMobilePageTitle = (pTitle?: string) => {
    switch (pTitle) {
      case LANDING_ROUTE.subtitle:
        return `1/3 off ${LANDING_ROUTE.subtitle}`;
      case HOME_ROUTE.subtitle:
      case '':
      case null:
        return 'Go Paperless';
      default:
        return pTitle;
    }
  };
  return (
    <Hidden lgUp implementation="css">
      <div className={classes.mobileMenu}>
        <div className={classes.logoMobile}>
          <Link href={HOME_ROUTE.path}>
            <LogoSvg />
          </Link>
        </div>
        <If condition={isPublic}>
          <Then>
            <Hidden mdUp>
              <Typography variant="h3" className={classes.mobileTitle}>
                {setMobilePageTitle(pageTitle)}
              </Typography>
            </Hidden>
          </Then>
          <Else>
            <div>
              <Hidden lgUp>
                <CardToolbar />
              </Hidden>
              <Hidden lgDown>
                <Typography variant="h3" className={classes.mobileTitle}>
                  Go Paperless
                </Typography>
              </Hidden>
            </div>
          </Else>
        </If>
        <IconButton
          aria-label="open drawer"
          // edge="end"
          onClick={handleDrawerOpen}
          className={clsx("mobile-menu__menu-button", open && classes.hide && classes.menu)}
        >
          <MenuIcon className={classes.menu} />
        </IconButton>
      </div>
    </Hidden>
  );
};
