import React, { lazy, Suspense } from 'react';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Header from 'modules/core/components/Header';
import ImgsBackground from 'modules/public/pages/main/imgsBackground';
import greenTheme from 'themes/greenTheme';
import { useStyles } from './styles';
import clsx from 'clsx';
import CookieConsent from './components/CookieConsent';

const Footer = lazy(() => import('modules/core/components/Footer'));

interface IProps {
  isFooter?: boolean;
  title?: string;
  customNavbar?: boolean;
  customBackground?: boolean;
}

const PublicLayout: React.FC<IProps> = (props) => {
  const { children, isFooter = true, title, customNavbar = false, customBackground = false } = props;
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={greenTheme}>
      <CssBaseline />
      <div className="App Site">
        <div className="Site-content">
          { !customBackground && <ImgsBackground /> }

          { !customNavbar && (
            <div className={clsx("App-header", `_page_${title}`)}>
              <Header isPublic={true} />
            </div>
          )}

          <div className={clsx("main", `_page_${title}`)}>
            <main className={classes.root}>{children}</main>
          </div>
        </div>
        {isFooter && <Suspense fallback={null}><Footer /></Suspense>}

        <CookieConsent />
      </div>
    </MuiThemeProvider>
  );
};

export default PublicLayout;
