import { Box, ButtonBase } from '@material-ui/core';
import clsx from 'clsx';
import React, { CSSProperties, useRef } from 'react';
import useStyles from './styles';
import { IDialogRef } from 'modules/core/containers/DialogWrapper';
import { Link } from 'modules/core/components/Link';
import { IActionValue } from 'modules/editor/components/CardSectionsView/MainInfo/Actions/useButtons';

interface IRoundButtonProps extends Partial<Omit<IActionValue, 'id'>> {
  size?: 'sm' | 'lg';
  className?: string;
  buttonStyle?: CSSProperties;
  bodyStyle?: CSSProperties;
  disabled?: boolean;
  // isDownload?: boolean;
}

export const RoundButton: React.FC<IRoundButtonProps> = (props) => {
  const { url, title, icon, buttonStyle, bodyStyle, size = 'lg', disabled, modal, onClick } = props;
  const classes = useStyles();
  const ref = useRef<IDialogRef>(null);

  function onClickHandler(): void {
    if (onClick) {
      onClick();
    }

    if (modal) {
      if (ref.current) {
        ref.current.show();
      }
    }
  }

  const content = (
    <>
      <Box className={clsx(classes.iconWrapper, 'MainInfo-iconWrapper', size)} style={buttonStyle}>
        {icon}
      </Box>
      {title && (
        <Box textAlign="center" mt={1} display="flex" justifyContent="center">
          <span className={clsx(classes.link, classes.text, 'MainInfo-actionText')} style={bodyStyle}>
            {title}
          </span>
        </Box>
      )}
    </>
  );

  if (disabled) {
    return <Box className={clsx(classes.buttonWrapper, 'MainInfo-buttonWrapper')}>{content}</Box>;
  }
  return (
    <>
      {url ? (
        <Link {...url} className={clsx(classes.buttonWrapper, 'MainInfo-buttonWrapper')} onClick={onClickHandler}>
          {content}
        </Link>
      ) : (
        <ButtonBase className={clsx(classes.buttonWrapper, 'MainInfo-buttonWrapper')} onClick={onClickHandler}>
          {content}
        </ButtonBase>
      )}
      {modal && modal(ref)}
    </>
  );
};
