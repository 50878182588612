import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { gray4 } from 'themes/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchField: {
      backgroundColor: gray4,
      minWidth: 270,
      maxWidth: 345,
      height: 40,
      borderRadius: 20,
      padding: theme.spacing(0, 2),

      '& input': {
        fontSize: 14,
        fontFamily: 'F37 Ginger Light',
        fontWeight: 300,
        letterSpacing: 0.1,
        color: '#FFFFFF99',
        opacity: 1,

        '&::placeholder': {
          fontSize: 14,
          fontFamily: 'F37 Ginger Light',
          fontWeight: 300,
          letterSpacing: 0.1,
          color: '#FFFFFF99',
          opacity: 1,
        },
      },
      '& input:before': {
        content: "",
      },
    },
    root: {
      padding: 6,
    },
    glassIcon: {
      position: 'absolute',
      right: -10,
      top: 19,
    },
    iconButtonRoot: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  })
);
