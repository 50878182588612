import React from 'react';
import Progress from '.';
import { Dialog, Box, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiPaper-root': {
      background: '#77777724',
    },
  },
}));

interface IOverlayProgress {
  isProgress: boolean;
}
/**
 * Spinner overlapping screen
 * @param props IOverlayProgress
 */
export const OverlayProgress: React.FC<IOverlayProgress> = ({ isProgress }) => {
  const classes = useStyles();
  return (
    <Dialog fullScreen open={isProgress} className={classes.root}>
      <Box height="100%" display="flex" alignItems="center">
        <Progress />
      </Box>
    </Dialog>
  );
};
