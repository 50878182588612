import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(0),
    },
    textField: {
      width: '100%',
      zIndex: 0,
      '& textarea': {
        '&::placeholder': {
          color: 'white',
          fontSize: '14px',
          fontWeight: '300',
          fontFamily: 'F37 Ginger Light',
        },
      },
      '& input': {
        '&::placeholder': {
          color: 'white',
          fontSize: '14px',
          fontWeight: '300',
          fontFamily: 'F37 Ginger Light',
        },
      },
      '& .MuiFilledInput-underline:before': {
        borderBottomColor: 'rgba(255,255,255,0.4)',
      },
      '& .MuiFormLabel-root': {
        fontWeight: 300,
        fontFamily: 'F37 Ginger Light',
        fontSize: 14,
        lineHeight: theme.typography.pxToRem(24),
      },
      '&._white': {
        marginBottom: 31,
        '&> :last-child:after': {
          borderBottomColor: '#749D83 ',
        },
        '&> :last-child::before': {
          borderBottomColor: 'rgba(255,255,255,0.4)',
        },
        '& label': {
          color: '#013D1F',
          fontSize: 14,
          lineHeight: theme.typography.pxToRem(24),
          fontWeight: 300,
          fontFamily: 'F37 Ginger Light',
        },
        '& label.Mui-focused': {
          color: '#013D1F',
        },
        '& input': {
          '&::placeholder': {
            fontFamily: 'F37 Ginger Light',
            fontWeight: 300,
            color: '#013D1F',
            opacity: 1,
          },
        },
        '& .MuiFilledInput-root': {
          backgroundColor: 'rgba(241,244,245,0.5);',
        },
        '& .MuiFormLabel-root': {
          '&.Mui-error': {
            color: '#f44336 !important',
          },
          fontSize: 14,
          fontWeight: 300,
          fontFamily: 'F37 Ginger Light',
          color: '#013D1F',
        },
      },
      '&._buy': {
        '&._white': {
          '&> :last-child::before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
          },
          marginBottom: 14,
        },
      },
      '& .Mui-disabled': {
        '& input': {
          '&::placeholder': {
            color: '#9fa2a5',
          },
        },
        '& textarea': {
          '&::placeholder': {
            color: '#9fa2a5',
          },
        },
      },
      '&._uppercase': {
        '& input': {
          textTransform: 'uppercase',
          '&::placeholder': {
            textTransform: 'uppercase',
          },
        },
      },
      '&._green': {
        '&> :last-child:after': {
          borderBottomColor: 'rgba(255,255,255,0.4)',
        },
        '&> :last-child::before': {
          borderBottomColor: 'rgba(255,255,255,0.4)',
        },
        '&> :last-child': {
          borderRadius: 0,
          background: 'rgba(255,255,255,0.1)',

          '& input': {
            color: 'white',
          },
        },
        '& textarea': {
          color: 'white',
          minHeight: 168,
          lineHeight: theme.typography.pxToRem(24),
        },
        '& label': {
          fontFamily: 'F37 Ginger Light',
          color: 'white',
          fontSize: 14,
          lineHeight: theme.typography.pxToRem(24),
          fontWeight: 200,
        },
        '& label.Mui-focused': {
          color: 'white',
        },
        '& input': {
          '&::placeholder': {
            color: 'white',
            opacity: 1,
          },
        },
        '& .MuiFormLabel-root': {
          fontSize: 14,
          fontWeight: 300,
          fontFamily: 'F37 Ginger Light',
          color: 'white',
        },
      },
      '&._text-adorment': {
        '& .MuiInputAdornment-root': {
          marginRight: '1px',
        },
      },
    },
  })
);

/*
        '& .Mui-disabled': {
          '& input::placeholder': {
            color: '#9fa2a5',
          },
        },
*/
