import { ISignup, IUserForm, IResellerSignup, IResellerForm } from 'modules/auth/interfaces/auth';

export class Signup implements ISignup {
  public errors: any;
  public values: IUserForm;

  constructor() {
    this.errors = {};
    this.values = new UserForm();
  }
}

export class UserForm implements IUserForm {
  public firstName: string = '';
  public country: string = '';
  public lastName: string = '';
  public email: string = '';
  public mobilePhone?: string = '';
  // tslint:disable
  public subscribe_gdpr_news = false;
  public company_name?: string = '';
  public through_reseller?: string = '';
}

export class ResellerSignup implements IResellerSignup {
  public errors: any;
  public userValues: IResellerForm;

  constructor(data?: Partial<IResellerSignup>) {
    let userValues;

    if (data) {
      userValues = data.userValues;
    }

    this.errors = {};
    this.userValues = userValues || new ResellerForm();
  }
}

export class ResellerForm implements IResellerForm {
  public firstName: string = '';
  public country: string = '';
  public lastName: string = '';
  public email: string = '';
  public mobilePhone?: string = '';
  public accepted_nda_on: boolean = false;
  public accepted_privacy_terms_on: boolean = false;
  public accepted_reseller_agreement_on: boolean = false;
  public subscribe_gdpr_news: boolean = false;

  constructor(data?: Partial<IResellerForm>) {
    if (data) {
      this.firstName = data.firstName || "";
      this.lastName = data.lastName || "";
      this.email = data.email || "";
      this.mobilePhone = data.mobilePhone || "";
      this.country = data.country || "";
      this.accepted_nda_on = data.accepted_nda_on || false;
      this.accepted_privacy_terms_on = data.accepted_privacy_terms_on || false;
      this.accepted_reseller_agreement_on = data.accepted_reseller_agreement_on || false;
      this.subscribe_gdpr_news = data.subscribe_gdpr_news || false;
    }
  }
}
