import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: 15,
    },
    noPadding: {
      margin: 0,
      padding: 0,
    },
    title: {
      fontSize: 30,
      lineHeight: 1.5,
    },
    subtitle: {},
    closeButton: {
      position: 'absolute',
      right: 15,
      top: 15,
      zIndex: 5,
      color: 'rgba(1,61,31,0.5)',
      cursor: 'pointer',
    },
  });

export interface IDialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  dialogId?: string;
  children: React.ReactNode;
  subtitle?: React.ReactNode;
  subtitle2?: React.ReactNode;
  onClose: (evt: any) => void;
  hideCloseIcon?: boolean;
}

const DialogTitle = withStyles(styles)((props: IDialogTitleProps) => {
  const { children, classes, onClose, subtitle, subtitle2, id, dialogId = '', hideCloseIcon = false, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={!children && !subtitle && !subtitle2 ? classes.noPadding : classes.root} {...other}>
      {children && (
        <Typography id={`${dialogId}_dialog_title`} variant="h5" className={classes.title}>
          {children}
        </Typography>
      )}
      {subtitle && (
        <Typography id={`${dialogId}_dialog_subtitle`} variant="subtitle1" className={classes.subtitle}>
          {subtitle}
        </Typography>
      )}
      {subtitle2 && (
        <Typography id={`${dialogId}_dialog_subtitle`} variant="subtitle1" className={classes.subtitle}>
          {subtitle2}
        </Typography>
      )}
      {onClose && !hideCloseIcon && (
        <CloseIcon
          id={`${dialogId}_dialog_close_button`}
          aria-label="close"
          onClick={onClose}
          className={classes.closeButton}
        />
      )}
    </MuiDialogTitle>
  );
});

export default DialogTitle;
