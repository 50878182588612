import { Theme, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 50,
    maxWidth: 400,
    [theme.breakpoints.between('xs', 'sm')]: {
      maxWidth: '100%',
    },
  },
  submitButton: {
    border: '3px solid #e6e6e6',
    boxShadow: '0 10px 20px 0 rgb(0 0 0 / 25%)',
    [theme.breakpoints.between('xs', 'sm')]: {
      maxWidth: '100%',
    },
  },
  field: {
    width: '100%',
  },
  fieldBlock: {
    width: '100%',
    display: 'flex',
    'flex-direction': 'column',
    justifyContent: 'flex-start',
    marginBottom: 55,
    '& .MuiFormControl-root': {
      '&:first-of-type': {
        marginBottom: 32,
      },
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
    'text-transform': 'uppercase',
    marginBottom: 12,
    cursor: 'pointer',
  },
}));
