import { WithStyles, withStyles } from '@material-ui/styles';
import Divider from 'modules/core/components/Divider';
import React, { useCallback, useState } from 'react';

import SidemenuItem from './SidemenuItem';
import { styles } from './styles';

export interface ISidemenuItem {
  id?: string;
  letter: string;
  title: string;
  component: any;
}

interface IProps {
  items: ISidemenuItem[];
  optionalItems?: ISidemenuItem[];
}

const SidemenuWrapper: React.FC<WithStyles<typeof styles> & IProps> = ({ classes, items, optionalItems, children }) => {
  const [activeItem, setActiveItem] = useState<ISidemenuItem>(items[0]);

  const handler = useCallback((item: number) => setActiveItem(items[item]), [setActiveItem, items]);

  return (
    <div className={classes.root}>
      <div className={classes.menu}>
        {items.map((item, idx) => (
          <SidemenuItem key={idx} item={item} isActive={item.letter === activeItem.letter} onClick={setActiveItem} />
        ))}
        {optionalItems && optionalItems.length ? (
          <>
            <Divider text="OPTIONAL" type="left" />
            {optionalItems.map((item, idx) => (
              <SidemenuItem
                key={idx}
                item={item}
                isActive={item.letter === activeItem.letter}
                onClick={setActiveItem}
              />
            ))}
          </>
        ) : null}
      </div>
      <div className={classes.content}>
        <activeItem.component setActiveItem={handler} />
      </div>
    </div>
  );
};

export default withStyles(styles)(SidemenuWrapper);
