import { IPublicCardRS, reducer as cardVersionReducer } from 'modules/editor/reducers/cardVersionReducer';
import { IAssignedUsersState, reducer as users } from 'modules/users/reducers/assigned-users';
import { IAuthState, reducer as auth } from 'modules/auth/reducers/authReducer';
import { ICardVersionRS, reducer as cardReducer } from 'modules/editor/reducers/cardReducer';
import { IGeneralState, reducer as generalReducer } from 'modules/core/reducers/generalReducer';
import { ICardStepsRS, reducer as editorSteps } from 'modules/editor/reducers/stepsReducer';
import { IResellerState, reducer as reseller } from 'modules/dashboard/reducers/resellerReducer';

export interface IReduxState {
  editorSteps: ICardStepsRS;
  auth: IAuthState;
  cardVersionReducer: IPublicCardRS;
  currentVersion: ICardVersionRS;
  users: IAssignedUsersState;
  general: IGeneralState;
  reseller:  IResellerState;
}

const root = {
  editorSteps,
  auth,
  cardVersionReducer,
  currentVersion: cardReducer,
  users,
  general: generalReducer,
  reseller,
};

export default root as any;
