import React, { forwardRef } from 'react';
import { useSelectPhoneNumberDialogStyles as useStyles } from './styles';
import DialogWrapper, { IDialogRef } from 'modules/core/containers/DialogWrapper';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { isMobile } from 'modules/core/utils';
import CssGreenButton from 'modules/core/components/buttons/roundGreenButton';

interface IProps {
  icon: JSX.Element;
  textItems: string[];
  type: "call" | "text";
}

const SelectPhoneNumberDialog = forwardRef<IDialogRef, IProps>((props, ref) => {
  const { icon, textItems, type } = props;
  const classes = useStyles();
  const [phone, phone2] = textItems;
  const headerText = type === 'text' ? "Text me" : "Phone me";

  return (
    <DialogWrapper
      dialogId="call"
      ref={ref}
      dialogClasses={{ paper: clsx(classes.fullWidth, classes.paper) }}
      actionsClasses={{ root: classes.actionsRoot }}
      actions={[
        isMobile() ? (
          <CssGreenButton id="phone_number_btn" className={classes.callButton} href={`tel:${phone}`} key="phone">
            <div className="phone-type">MOBILE</div>
            <div className="phone-number">{phone}</div>
          </CssGreenButton>
        ) : null,
        isMobile() && phone2 ? (
          <CssGreenButton id="phone_number_btn" className={classes.callButton} href={`tel:${phone2}`} key="phone2">
            <div className="phone-type">ALTERNATIVE</div>
            <div className="phone-number">{phone2}</div>
          </CssGreenButton>
        ) : null,
      ]}
    >
      <div className={classes.card}>
        <Box className={clsx(classes.iconWrapper, classes.biggerIcon)}>{icon}</Box>
        <Typography variant="h5">{headerText}</Typography>
        <Typography variant="subtitle1" className={classes.sub}>
          Please select which number to {type}
        </Typography>
        {isMobile() ? null : (
          <>
            <Typography variant="subtitle1" className={classes.phoneType}>
              MOBILE
            </Typography>
            <Typography className={classes.phoneNumber}>{phone}</Typography>
            {phone2 ? (
              <>
                <Typography variant="subtitle1" className={classes.phoneType}>
                  ALTERNATIVE
                </Typography>
                <Typography className={classes.phoneNumber}>{phone2}</Typography>
              </>
            ) : null}
          </>
        )}
      </div>
    </DialogWrapper>
  );
});

export default SelectPhoneNumberDialog;
