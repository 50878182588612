import React from 'react';
import Typography from '@material-ui/core/Typography';

interface IProps {
  messages: string[];
  align?: 'center' | 'left' | 'right' | 'justify';
}

const ErrorMessages: React.FC<IProps> = ({ messages, align }) => {
  return (
    <>
      {messages && messages.length
        ? messages.map((item, idx) => (
            <Typography key={idx} color="error" variant="subtitle1" align={align}>
              {item}
            </Typography>
          ))
        : null}
    </>
  );
};

export default ErrorMessages;
