import gql from 'graphql-tag';
import { ShareCardData } from 'modules/editor/models/share-data';
import { apoloClient } from 'modules/core/constants/client';
import { ID } from 'modules/core/types';
import { ICardVersion, IShareCardData } from 'modules/editor/graphql/gql';
import { CardVersion } from 'modules/editor/models/card-version';
import { actions } from 'modules/editor/reducers/cardReducer';
import { actions as publicCardActions } from 'modules/editor/reducers/cardVersionReducer';
import { Dispatch } from 'redux';
import { NULL_PAYLOAD } from '../constants/messages';
import { cardVersionFields } from 'modules/editor/api/fragments';

export function getCardId() {
  return async (dispatch: Dispatch) => {
    try {
      const res = await apoloClient.query<{card: { id: ID }}>({
        query: gql`
          query {
            card {
              id
            }
          }
        `,
      });
      // TODO add call dispatch
      if (res.data) {
        return res.data.card.id as number;
      } else {
        throw new Error(NULL_PAYLOAD);
      }
    } catch (error) {
      console.warn(error);
      throw error;
    }
  };
}

export async function getShareDialogData(id: ID) {
  try {
    const res = await apoloClient.query<{ shareCardData: IShareCardData }, { id: ID }>({
      variables: { id },
      query: gql`
        query shareCardData($id: String!) {
          shareCardData(id: $id) {
            id
            callBtn
            emailBtn
            email
            mobilePhone
            name
            contactRequestCreated
          }
        }
      `,
    });
    if (res.data) {
      return new ShareCardData(res.data.shareCardData);
    } else {
      throw new Error(NULL_PAYLOAD);
    }
  } catch (error) {
    throw error;
  }
}

export const getCardByUrl = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await apoloClient.query<{ cardByUrl: ICardVersion }>({
        variables: { cardUrl: id },
        query: gql`
          query cardByUrl($cardUrl: String!) {
            cardByUrl(cardUrl: $cardUrl) {
              ...CardVersionFields
            }
          }
          ${cardVersionFields({ vCard: true })}
        `,
      });
      if (res.data.cardByUrl) {
        const { locationSection } = res.data.cardByUrl;
        if (locationSection && locationSection.openingTimes) {
          res.data.cardByUrl.locationSection.openingTimes.reverse();
        }

        dispatch(publicCardActions.setPublicVersion(new CardVersion(res.data.cardByUrl)));

      } else {
        throw new Error('Card not found');
      }
    } catch (error) {
      console.warn(error);
      throw error;
    }
  };
};

export function getCardVersions() {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await apoloClient.query<{ cardVersions: ICardVersion[] }>({
        query: gql`
          query {
            cardVersions {
              id
              publishedTime
            }
          }
        `,
      });
      if (data) {
        dispatch(
          actions.setVersions(
            data.cardVersions.map((v) => ({
              ...v,
              publishedTime: v.publishedTime ? new Date(v.publishedTime.toString().replace(/-/g, '/')) : undefined,
            }))
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  };
}

export function getCurrentVersion() {
  return async (dispatch: Dispatch) => {
    try {
      const res = await apoloClient.query<{ currentCardVersion: ICardVersion }>({
        query: gql`
          query {
            currentCardVersion {
              ...CardVersionFields
            }
          }
          ${cardVersionFields()}
        `,
      });
      if (res.data.currentCardVersion) {
        const { locationSection } = res.data.currentCardVersion;

        if (locationSection && locationSection.openingTimes) {
          res.data.currentCardVersion.locationSection.openingTimes.reverse();
        }

        const cardVersion = new CardVersion(res.data.currentCardVersion);
        dispatch(actions.setCardVersion(cardVersion));

        return cardVersion;
      } else {
        throw new Error('Card not found');
      }
    } catch (error) {
      console.warn(error);
      return new CardVersion();
    }
  };
}

export function currentVersionIds() {
  return async (dispatch: Dispatch) => {
    try {
      const res = await apoloClient.query<{ currentCardVersion: ICardVersion }>({
        query: gql`
          query {
            currentCardVersion {
              id
              currentForCardId
              draft
              aboutSection {
                id
                aboutSectionLinks {
                  id
                }
              }
              cardStyle {
                id
              }
              gallerySection {
                id
                gallerySectionImages {
                  id
                }
              }
              locationSection {
                id
                openingTimes {
                  id
                }
              }
            }
          }
        `,
      });
      if (res && res.data) {
        dispatch(actions.setCardVersionId(new CardVersion(res.data.currentCardVersion)));
      }
    } catch (error) {
      console.warn(error);
    }
  };
}

export async function queryByText(queryText: any) {
  try {
    const res = await apoloClient.query({
      query: queryText,
    });
    return res.data;
  } catch (error) {
    console.warn(error);
  }
}
