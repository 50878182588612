import AuthorizationDialog from 'modules/auth/components/Authorization/AuthorizationDialog';
import { confirmSignup } from 'modules/auth/thunks/auth';
import CircularIndeterminate from 'modules/core/components/progress';
import { useDefaultManifest } from 'modules/core/utils';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { EDITOR_ROUTE, HOME_ROUTE } from 'routes/routes';

interface IReducers {
  confirm: (token: string) => Promise<any>;
  loadProfile: () => Promise<void>;
}

const SignupConfirmation: React.FC<RouteComponentProps & IReducers> = ({
  history,
  confirm,
  loadProfile,
  location: { search },
}) => {
  useEffect(() => {
    const query = new URLSearchParams(search);
    const token = query.get('token');
    if (token) {
      confirm(token)
        .then(() => {
          history.push(EDITOR_ROUTE.path);
        })
        // tslint:disable-next-line: no-empty
        .catch(() => {});
    } else {
      history.push(HOME_ROUTE.path);
    }
  }, [search, history, confirm, loadProfile]);
  useDefaultManifest();

  return <AuthorizationDialog centered exClass={'_auth'} dlgBody={<CircularIndeterminate />} />;
};

const mapReducers = {
  confirm: confirmSignup,
};

export default connect(
  null,
  mapReducers
)(withRouter(SignupConfirmation));
