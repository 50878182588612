import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import React from 'react';
import { useStyles } from './styles';

const ErrorContent: React.FC = () => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="100%">
      <CancelIcon color="primary" className={classes.sharedIcon} />
      <Typography variant="h5" className={classes.title}>
        Error!
      </Typography>
      <Typography variant="subtitle1" align="center" display="block">
        This authentication token is no longer valid. Please try <Link href="/signin">signing in</Link> again.
      </Typography>
    </Box>
  );
};

export default ErrorContent;
