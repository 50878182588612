import React from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './styles';

type Props = TextFieldProps & {
  exClass?: string;
};

export const InputField: React.FC<Props> = (props) => {
  const { exClass, ...restProps } = props;
  const classes = useStyles();

  return <TextField className={clsx(classes.margin, classes.textField, exClass)} {...restProps} />;
};
