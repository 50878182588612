import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
      color: 'white',
      fontFamily: 'F37 Ginger',
    },
    titleWrap: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 52,
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center !important',
        justifyContent: 'center !important',
        width: '100% !important',
        marginBottom: '25px !important',
      },
    },
    page: {
      paddingTop: 145,
      marginBottom: 100,
      [theme.breakpoints.down('md')]: {
        paddingTop: '176px !important',
      },
      '&._padding-md': {
        paddingTop: 88,
        [theme.breakpoints.down('md')]: {
          paddingTop: '45px !important',
        },
      },

      '&._padding-sm': {
        paddingTop: 63,
        [theme.breakpoints.down('md')]: {
          paddingTop: '176px !important',
        },
      },
      '&._padding-sm-mobile': {
        paddingTop: 140,
        [theme.breakpoints.down('md')]: {
          paddingTop: '37px !important',
        },
      },
    },
    table: {
      borderBottom: '1px solid #4D5156',
    },
    cell: {
      fontSize: 14,
      fontFamily: 'F37 Ginger Light',
      lineHeight: theme.typography.pxToRem(24),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderBottom: '1px solid #4D5156',
      [theme.breakpoints.down('sm')]: {
        borderBottom: 'none !important',
        display: 'none !important',
        paddingBottom: '0px !important',
        paddingTop: '10px !important',
        '&:last-child': {
          borderBottom: 'none !important',
        },
      },
      '&:first-child': {
        paddingLeft: 0,
        justifyContent: 'flex-end',
      },
      '&:last-child': {
        paddingRight: 0,
        width: 176,
      },
      '&._radio': {
        [theme.breakpoints.up('lg')]: {
          minWidth: 200,
        },
        '&> :first-child': {
          maxWidth: 200,
          margin: 0,
          '&> :first-child': {
            [theme.breakpoints.down('md')]: {
              flexWrap: 'nowrap !important',
            },
          },
        },
        '& label': {
          '&:last-child': {
            marginRight: 0,
          },
          '&:first-child': {
            '& span': {
              '&:last-child': {
                paddingRight: 15,
              },
            },
          },
        },
      },
      '&._green': {
        '& a': {
          color: '#76B741',
        },
      },
    },
    tableRow: {
      borderBottom: '1px solid #4D5156',
      '&:last-child': {
        borderBottom: 0,
      },
      [theme.breakpoints.down('sm')]: {
        display: 'flex !important',
        width: '100%',
        flexDirection: 'column',
        border: '1px solid #50555A',
        borderRadius: 4,
        backgroundColor: '#2A3136',
        boxShadow: '0 5px 10px 0 rgba(0,0,0,0.25)',
        padding: '10px 16px 10px 14px',

        '&:not(:last-child)': {
          marginBottom: 15,
        },
      },
    },
    cellHead: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: 0,
      borderBottom: 0,
      '&._empty': {
        [theme.breakpoints.down('sm')]: {
          display: 'none !important',
        },
      },
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 0,
      },
      '& p': {
        fontSize: 12,
        lineHeight: theme.typography.pxToRem(17),
        letterSpacing: 0.67,
        opacity: 0.5,
      },
    },
    tableRowHead: {
      [theme.breakpoints.down('sm')]: {
        display: 'none !important',
      },
    },
    mobileContent: {
      position: 'relative',
      padding: '0 !important',
      borderBottom: '0 !important',
      [theme.breakpoints.up('md')]: {
        display: 'none !important',
      },
      '& p': {
        paddingBottom: 10,
      },
      '&._radio': {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 10,
        marginTop: 5,
        '&> :last-child': {
          maxWidth: 200,
          margin: 0,
        },
        '& label': {
          '&:last-child': {
            marginRight: 0,
          },
          '&:first-child': {
            '& span': {
              '&:last-child': {
                paddingRight: 15,
              },
            },
          },
        },
      },
    },
    mobileText: {
      fontSize: 14,
      lineHeight: theme.typography.pxToRem(24),
      '&._radio': {
        opacity: 0.5,
        paddingBottom: 0,
      },
      '&._green': {
        color: '#76B741',
        fontWeight: 700,
        [theme.breakpoints.down(400)]: {
          maxWidth: 200,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
      '&._total': {
        position: 'absolute',
        right: 0,
        fontWeight: 700,
        top: 0,
      },
      '&._period': {
        fontFamily: 'F37 Ginger Light',
        fontSize: 12,
        paddingBottom: 0,
        marginBottom: 4,
      },
    },
    tableHead: {},
    title: {
      fontSize: 20,
      lineHeight: theme.typography.pxToRem(29),
      letterSpacing: 0.67,
      [theme.breakpoints.down('sm')]: {
        display: 'none !important',
      },
    },
    subtitle: {
      fontSize: 12,
      lineHeight: theme.typography.pxToRem(17),
      letterSpacing: 0.1,
      textTransform: 'uppercase',
      opacity: 0.8,
    },
    accountName: {
      fontFamily: 'F37 Ginger Light',
      fontSize: 16,
      letterSpacing: 0.1,
      paddingBottom: '0 !important',
      marginBottom: 2,
    },
    accountDesc: {
      fontFamily: 'F37 Ginger Light',
      fontSize: 10,
      textTransform: 'uppercase',
      letterSpacing: 0.1,
      paddingBottom: '0 !important',
    },
    hr: {
      margin: theme.spacing(2, 0, 1),
    },
  })
);

export default useStyles;
