import { apoloClient } from 'modules/core/constants/client';
import gql from 'graphql-tag';
import {
  IPlan,
  ISubscription,
  IPreviewSubscriptionSummary,
  IPrepareInitialResellerSubscriptionAttributes,
  ICreateInitialResellerSubscriptionResponse,
} from 'modules/billing/graphql/gql';
import { Plan } from 'modules/billing/models/plan';
import {
  ISubscriptionAtributes,
  IPreveiwSubscriptionSummaryAttributes,
  IInitialResellerSubscriptionAttributes,
} from 'modules/billing/graphql/mutations';
import { SUBSCRIPTION_FRAGMENT, COUPON_FRAGMENT, PLAN_FRAGMENT, PRICE_FRAGMENT } from 'modules/editor/api/fragments';
import { Subscription } from 'modules/billing/models/subscription';
import {
  createSubscriptionResponse,
  createResellerSubscriptionResponse,
} from 'modules/billing/utils';
import { ID } from 'modules/core/types';

export async function getPlans() {
  try {
    const res = await apoloClient.query<{ listPlans: IPlan[] }>({
      query: gql`
        query {
          listPlans {
            active
            aggregateUsage
            amount
            amountDecimal
            billingScheme
            created
            coupon {
              ...CouponFields
            }
            currency
            id
            interval
            intervalCount
            livemode
            metadata {
              poa
              upToMax
            }
            nickname
            object
            product
            tiers {
              flatAmount
              flatAmountDecimal
              unitAmount
              unitAmountDecimal
              upTo
            }
            tiersMode
            trialPeriodDays
            usageType
            vat
          }
        }
        ${COUPON_FRAGMENT}
      `,
    });
    return res.data.listPlans.map((p) => new Plan(p));
  } catch (error) {
    console.warn(error);
    return [];
  }
}

export async function createSubscription(atr: ISubscriptionAtributes) {
  try {
    const { data } = await apoloClient.mutate<{
      createSubscription: { subscription: ISubscription; errors: string[], scaSecret: string };
    }>({
      variables: atr,
      mutation: gql`
        mutation CreateSubscription($paymentMethodId: String, $planId: String!, $quantity: Int!, $couponId: String) {
          createSubscription(
            attributes: { paymentMethodId: $paymentMethodId, planId: $planId, quantity: $quantity, couponId: $couponId }
          ) {
            errors
            scaSecret
            subscription {
              ...SubscriptionFields
            }
          }
        }
        ${SUBSCRIPTION_FRAGMENT}
      `,
    });
    if (data) {
      if (data.createSubscription.subscription) {
        const subscription = new Subscription(data.createSubscription.subscription);

        if (data.createSubscription.scaSecret) {
          return createSubscriptionResponse({ scaSecret: data.createSubscription.scaSecret, subscription });
        }

        return createSubscriptionResponse({ subscription });
      } else if (data.createSubscription.errors) {
        throw data.createSubscription.errors;
      }
    }
  } catch (error) {
    console.warn(error);
    throw error;
  }
}

export async function userSubscriptions() {
  try {
    const { data } = await apoloClient.query<{ userSubscriptions: ISubscription[] }>({
      query: gql`
        query {
          userSubscriptions {
            ...SubscriptionFields
          }
        }
        ${SUBSCRIPTION_FRAGMENT}
      `,
    });
    const subscriptions = data.userSubscriptions.map((sub) => new Subscription(sub));
    return subscriptions;
  } catch (error) {
    console.warn(error);
    return [];
  }
}

export async function getSummaryInfo(art: IPreveiwSubscriptionSummaryAttributes) {
  try {
    const { data } = await apoloClient.query<{ previewSubscriptionSummary: IPreviewSubscriptionSummary }>({
      variables: art,
      query: gql`
        query GetSummaryInfo($paymentMethodId: String, $planId: String!, $quantity: Int!, $couponId: String) {
          previewSubscriptionSummary(
            attributes: { paymentMethodId: $paymentMethodId, planId: $planId, quantity: $quantity, couponId: $couponId }
          ) {
            couponId
            currency
            paymentMethodId
            planId
            quantity
            subtotal
            total
            vatAmount
            vatPercentage
          }
        }
      `,
    });
    return data.previewSubscriptionSummary;
  } catch (error) {
    throw error;
  }
}

export async function prepareInitialResellerSubscription(lifetime: boolean) {
  try {
    const { data } = await apoloClient.query<{ prepareInitialResellerSubscription: IPrepareInitialResellerSubscriptionAttributes }>({
      variables: {
        lifetime,
      },
      query: gql`
        query ($lifetime: Boolean){
          prepareInitialResellerSubscription(
            lifetime: $lifetime
          ) {
            currency
            discountAmount
            subtotal
            vatPercentage
            vatAmount
            total
            resellerPlan {
              ...PlanFields
            }
            resellerStarterPack {
              ...PriceFields
            }
            retailPlan {
              ...PlanFields
            }
            resellerCoupon {
              ...CouponFields
            }
            retailCoupon {
              ...CouponFields
            }
          }
        }
        ${COUPON_FRAGMENT}
        ${PLAN_FRAGMENT}
        ${PRICE_FRAGMENT}
      `,
    });
    return data.prepareInitialResellerSubscription;
  } catch (error) {
    throw error;
  }
}

export async function createInitialResellerSubscription(atr: IInitialResellerSubscriptionAttributes) {
  try {
    const { data } = await apoloClient.mutate<{
      createInitialResellerSubscription: ICreateInitialResellerSubscriptionResponse
    }>({
      variables: atr,
      mutation: gql`
        mutation CreateInitialResellerSubscription(
          $paymentMethodId: String,
          $resellerPlanId: String!,
          $starterPackPriceId: String!,
          $resellerCouponId: String
        ) {
          createInitialResellerSubscription(
            attributes: {
              paymentMethodId: $paymentMethodId,
              resellerPlanId: $resellerPlanId,
              starterPackPriceId: $starterPackPriceId,
              resellerCouponId: $resellerCouponId
            }
          ) {
            errors
            scaSecret
            resellerSubscription {
              ...SubscriptionFields
            }
          }
        }
        ${SUBSCRIPTION_FRAGMENT}
      `,
    });

    if (data && data.createInitialResellerSubscription && data.createInitialResellerSubscription.resellerSubscription) {
      const resellerSubscription = new Subscription(data.createInitialResellerSubscription.resellerSubscription);
      const retailSubscription = new Subscription(data.createInitialResellerSubscription.retailSubscription);
      const { scaSecret } = data.createInitialResellerSubscription;

      return createResellerSubscriptionResponse({ resellerSubscription, retailSubscription, scaSecret });
    } else if (data && data.createInitialResellerSubscription.errors) {
      throw data.createInitialResellerSubscription.errors;
    }
  } catch (error) {
    console.warn(error);
    throw error;
  }
}

export async function handleInitialSubscriptionFail(subscriptionId: ID, retailSubscriptionId?: ID) {
   try {
    const { data } = await apoloClient.mutate<{
      handleInitialSubscriptionFail: {
        status: string;
        errors?: string[];
      };
    }>({
      variables: { subscriptionId, retailSubscriptionId },
      mutation: gql`
        mutation HandleInitialSubscriptionFail($subscriptionId: ID!, $retailSubscriptionId: ID) {
          handleInitialSubscriptionFail(subscriptionId: $subscriptionId, retailSubscriptionId: $retailSubscriptionId) {
            status
            errors
          }
        }
      `,
    });
    if (data && data.handleInitialSubscriptionFail.errors && data.handleInitialSubscriptionFail.errors.length) {
      throw data.handleInitialSubscriptionFail.errors;
    }
  } catch (error) {
    console.warn(error);
    throw error[0];
  }
};

export async function updateSubscriptionStatus(subscriptionId: ID) {
   try {
    const { data } = await apoloClient.mutate<{
      updateSubscriptionStatus: {
        subscription?: ISubscription;
        status: string;
        errors: string[];
      };
    }>({
      variables: { subscriptionId },
      mutation: gql`
        mutation UpdateSubscriptionStatus($subscriptionId: ID!) {
          updateSubscriptionStatus(subscriptionId: $subscriptionId) {
            subscription {
             ...SubscriptionFields
            }
            status
            errors
          }
        }
        ${SUBSCRIPTION_FRAGMENT}
      `,
    });

    if (data && data.updateSubscriptionStatus.subscription) {
      return new Subscription(data.updateSubscriptionStatus.subscription)
    } else if (data && data.updateSubscriptionStatus.errors) {
      throw data.updateSubscriptionStatus.errors;
    }
  } catch (error) {
    console.warn(error);
    throw error;
  }
};
