import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectorAdoment: {
      width: '70px',
      height: '56px',
      marginLeft: '-12px',
      marginTop: '0 !important',
      borderRight: '1px solid #749D83',
    },
    countrySelector: {
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
    wrapper: {
      '& label': {
        marginLeft: '66px',
      },
    },
    button: {
      borderRadius: 0,
    },
  })
);
