export const PHONE_COUNTRIES = undefined; // ['ua', 'ru', 'us', 'gb'];
export const PREFERRED_COUNTRIES = ['gb', 'us', 'ru', 'ca', 'fr', 'brg', 'es', 'au', 'nz']; // ['ua', 'ru', 'us', 'gb'];

export const GA_TRACKING_ID = 'UA-154758199-1';
export const MAP_API_KEY = 'AIzaSyAit1MqZ64E3c5TBtu5FjIOj2pDaYmk1M8';

export const STRIPE_SECRET_KEY = process.env.REACT_APP_STRIPE_SECRET_KEY;

export const HOST_URL = process.env.REACT_APP_HOST_URL;

export const CDN_URL = process.env.REACT_APP_CDN_URL;

export const SALES_CAMPAIGN_DOMAIN = 'news.gpdigitalcards.com';
