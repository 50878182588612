import React, { createRef, useEffect } from 'react';
import DialogWrapper from 'modules/core/containers/DialogWrapper';
import WarnContent from 'modules/core/components/dialogs/WarnContent';
import { useCardProcessingDialogStyles } from './styles'

interface ICardProcessingErrorDialog {
  show: boolean;
  onClose: () => void;
  dialogId?: string;
  errorMessage?: string;
}

export const CardProcessingErrorDialog: React.FC<ICardProcessingErrorDialog> = ({ dialogId, show, onClose, errorMessage }) => {
  const classes = useCardProcessingDialogStyles();
  const ref = createRef<any>();
  const message = errorMessage || "An error occurred while processing your card! Please contact customer service if issues continue."

  useEffect(() => (show ? ref.current.show() : ref.current.hide()), [show, ref]);

  return (
    <DialogWrapper ref={ref} onClose={onClose} dialogId={dialogId} dialogClasses={{ paper: classes.dialogPaper }}>
      <WarnContent>
        {message}
      </WarnContent>
    </DialogWrapper>
  );
};

export default CardProcessingErrorDialog;
