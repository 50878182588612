import React from 'react';
import { Box, Button, createStyles, Fab, makeStyles, Theme, withStyles } from '@material-ui/core';
import { blueCharcoal2, limeade, tuna } from 'themes/colors';
import { FabProps } from '@material-ui/core/Fab';
import clsx from 'clsx';
import { boldBorder } from 'themes/borders';

export const MenuButton = withStyles({
  root: {
    background: limeade,
    color: 'white',
    width: 195,
    borderRadius: 25,
    fontFamily: 'F37 Ginger',
    fontWeight: 700,
    fontSize: 14,
    padding: '15px 8px',
    textTransform: 'uppercase',
    letterSpacing: 1,
    lineHeight: '13px',
    '&:hover': {
      background: '#81c944',
    },
    '&:disabled': {
      background: blueCharcoal2,
      color: tuna,
    },
    border: boldBorder,
  },
})(Button);

export const FabButton = withStyles({
  root: {
    display: 'flex',
    background: 'white',
    width: '56px',
    height: '56px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    '&:disabled': {
      background: '#2A3136',
    },
  },
})(Fab);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btn: {
      background: '#4E5156',
      border: '2px #fff solid',
      '&.active': {
        background: '#fff',
        border: 'none',
      },
      '&:disabled': {
        border: '2px #30373C solid',
      },
    },
    buttonIco: {
      display: 'flex',
      width: '30px',
      height: '30px',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      color: '#fff',
      '&.disabled': {
        background: '#5F6468',
        color: '#30373C',
      },
      '&.active': {
        color: 'white',
        background: limeade,
      },
    },
  })
);

interface IMobBarButtonProps {
  isActive?: boolean;
}

export const FabPreviewButton: React.FC<FabProps & IMobBarButtonProps> = ({
  children,
  isActive,
  disabled,
  ...props
}) => {
  const classes = useStyles();
  return (
    <FabButton disabled={disabled} className={clsx(classes.btn, isActive && 'active')} {...props}>
      <Box className={clsx(classes.buttonIco, isActive && 'active', disabled && 'disabled')}>{children}</Box>
    </FabButton>
  );
};
