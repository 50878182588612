import React, { useMemo, useCallback, FC } from 'react';
import DialogWrapper from 'modules/core/containers/DialogWrapper';
import { useStyles } from 'modules/editor/containers/Card/GdprDialog/useStyles';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { IShareCardData } from 'modules/editor/graphql/gql';
import { useSearchParams } from '../../hooks/useSearchParams';
import CssGreenButton from 'modules/core/components/buttons/roundGreenButton';
import { ID } from 'modules/core/types';
import { createContactRequest } from 'modules/core/api/contact-request-mutations';
import { IGdprState, GdprType } from '..';
import Cookies from 'js-cookie';
import { useDialogRef } from '../hooks/useDialogRef';
import { GdprOptInMessage } from 'modules/editor/containers/Card/GdprDialog/GdprOptInMessage';

interface IProps {
  shareData: IShareCardData;
  cardId: number;
  setValues: React.Dispatch<React.SetStateAction<IGdprState>>;
  onClose?: (evt: any, reason?: any) => void;
}

export const InitialDialog: FC<IProps> = (props) => {
  const { shareData, cardId, setValues, onClose } = props;

  const isAuthenticated = useTypedSelector((state) => state.auth.isAuthenticated);
  const profile = useTypedSelector((state) => state.auth.profile);
  const { id: accountId } = profile;
  const { id } = useSearchParams();
  const ref = useDialogRef();
  const classes = useStyles();

  const userName = useMemo(() => {
    if (isAuthenticated) {
      const uName = profile.getUserName();
      if (uName) {
        return uName;
      }
    }
    if (shareData.name) {
      return shareData.name.replace('+', ' ');
    }
    return '';
  }, [profile, isAuthenticated, shareData]);

  const sendAndShow = useCallback(
    async (type: GdprType, shareCardId: ID) => {
      try {
        await createContactRequest({cardVersionId: cardId, preference: type, shareCardId});
        setValues({ type, error: false });
      } catch {
        Cookies.remove(`Gopaperless_gdpr_${cardId}_${accountId}`);
        setValues({ type, error: true });
      }
    },
    [setValues, cardId, accountId]
  );

  const actions = useMemo(() => {
    const shareCardId = Number(shareData.id);
    const emailAct = {
      name: 'Email',
      key: 'email',
      action: () => sendAndShow('email', shareCardId),
      id: 'gdpr_req_by_email',
    };
    const phoneAct = {
      name: 'Phone',
      key: 'phone',
      action: () => sendAndShow('call', shareCardId),
      id: 'gdpr_req_by_phone',
    };
    const acts = [];
    if (id && !isAuthenticated) {
      if (shareData.emailBtn) {
        acts.push(emailAct);
      }
      if (shareData.callBtn) {
        acts.push(phoneAct);
      }
    } else {
      acts.push(emailAct);
      if (profile.mobilePhone) {
        acts.push(phoneAct);
      }
    }

    if (acts.length === 1) {
      acts[0].name = "I'm in!"
    }

    return acts.map((a) => (
      <CssGreenButton classes={{ root: classes.actionButton }} key={a.key} id={a.id} onClick={a.action}>
        {a.name}
      </CssGreenButton>
    ));
  }, [profile, sendAndShow, classes, isAuthenticated, shareData, id]);

  return (
    <DialogWrapper
      dialogId="gdpr"
      ref={ref}
      titleText={`Hi ${userName}`}
      subtitleText="Thanks for viewing my card."
      subtitle2Text="This digital business card is powered by Go Paperless."
      actions={actions}
      titleClasses={{ title: classes.title, subtitle: classes.subtitle }}
      actionsClasses={{ root: classes.actionsRoot }}
      onClose={onClose}
    >
      <GdprOptInMessage actions={actions} />
    </DialogWrapper>
  );
};
