import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minHeight: '100%',
    fontFamily: 'F37 Ginger',
    maxWidth: '100vw',
    maxHeight: '100vh',
    overflow: 'auto',
    '&.preview': {
      overflowY: 'overlay',
      '&::-webkit-scrollbar': {
        width: '0 !important',
      },
      scrollbarWidth: 'none',

      '&._mobile': {
        '& .video-wrap': {
          '&>div': {
            height: '220px !important',
          },
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      overflowY: 'overlay',
      '&::-webkit-scrollbar': {
        width: '0 !important',
      },
      scrollbarWidth: 'none',
    },
  },
  contentWrapper: {
    maxWidth: '730px',
    paddingTop: '60px',
  },
  textField: {
    maxWidth: 400,
    width: '100%',
  },
  card: {
    flex: '1 0 auto',
  },
  cardFlexWrap: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  findMore: {
    color: '#000000',
    fontSize: '14px',
    marginTop: '16px',
    padding: '14px',
    borderTop: '1px solid #E3E9EC',
    margin: '-14px',
    '& a': {
      color: '#000000',
      textDecoration: 'none',
    },
  },
  wide: {
    marginLeft: '-15px',
    marginRight: '-15px',
    width: '100%',
    '&>div': {
      width: 'calc(100% + 30px) !important',
      [theme.breakpoints.between('xs', 'sm')]: {
        maxHeight: '314px !important',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        maxHeight: '378px !important',
      },
      [theme.breakpoints.between('lg', 'xl')]: {
        maxHeight: '450px !important',
      },
    },
  },
  wider: {
    marginLeft: '-15px',
    marginRight: '-15px',
    marginBottom: '-15px',
    marginTop: '30px',
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
    width: 'auto',
    backgroundColor: '#FCFCFC',
  },
  imageLoad: {
    display: 'none',
  },
  userCard: {
    marginTop: '50px',
    marginBottom: '30px',
    paddingTop: '60px',
    alignItems: 'center',
    color: 'rgba(1,61,31,0.5)',
  },
  image: {
    width: 'calc(100% + 30px)',
    maxHeight: '100%',
    marginLeft: '-15px',
    marginRight: '-15px',
  },
  aboutLink: {
    display: 'flex',
    position: 'relative',
    padding: '14px',
    borderTop: '1px solid #E3E9EC',
    fontSize: '14px',

    '& a': {
      color: '#000000',
      textDecoration: 'none',
      maxWidth: 'calc(100% - 10px)',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  aboutFile: {
    position: 'absolute',
    right: '10px',
    bottom: '10px',
    maxWidth: 'unset!important',
  },
  noPaddingBottom: {
    paddingBottom: 0,
  },
  title: {
    fontSize: '10px',
    textTransform: 'uppercase',
    color: 'rgba(1,61,31,0.5)',
    textDecoration: 'none',
  },
  hoursTitle: {
    textTransform: 'uppercase',
  },
  descriptionBlock: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
  },
  view: {
    height: 'inherit',
  },
  previewView: {
    height: '100%',
  },
  address: {
    paddingTop: 16,
  },
  qrCodeContainer: {
    position: 'relative',
    textAlign: 'center',
    padding: theme.spacing(7, 0)
  },
  qrCode: {
    marginTop: 16,
    borderRadius: 6,
  },
  qrCodeCta: {
    fontSize: 10,
    textTransform: 'uppercase',
    letterSpacing: '0.1',
    fontWeight: 'bold',
  },
  footerSection: {
  },
}));
