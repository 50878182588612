// import Favicon192 from 'assets/favicon/android-chrome-192x192.png';
import Favicon16 from 'assets/favicon/favicon-16x16.png';
import Favicon32 from 'assets/favicon/favicon-32x32.png';
import { useUpdateHeadTag } from 'modules/core/utils';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import uuid from 'uuid';
import { useManifest } from 'modules/editor/containers/Card/functions';

interface ICardHead {
  fullName: string;
  icon512?: string;
  icon32?: string;
  icon16?: string;
  card_url: string;
}

export const CardHead: React.FC<ICardHead> = ({ fullName, icon512, icon16, icon32, card_url }) => {
  const title = fullName || 'Go Paperless';

  const manifestURL = useManifest(fullName, card_url, icon512);
  const linkUpdater = useUpdateHeadTag('link');
  const metaUpdater = useUpdateHeadTag('meta');

  useEffect(() => {
    if (icon512) {
      // // TODO: Remove me!!!
      console.log("icon512: ", icon512);

      linkUpdater({ rel: 'icon', sizes: '32x32', type: 'image/x-icon' }, { href: icon32 ? `${icon32}?${uuid()}` : Favicon32 });
      linkUpdater({ rel: 'icon', sizes: '16x16', type: 'image/x-icon' }, { href: icon16 ? `${icon16}?${uuid()}` : Favicon16 });

      linkUpdater({ rel: 'manifest' }, { href: manifestURL });
    }
  }, [icon16, icon32, icon512, linkUpdater, manifestURL, fullName, metaUpdater, card_url, title]);
  return (
    <Helmet>
      <link id="favicon" rel="icon" href={`${icon512}?${uuid()}`} type="image/x-icon"/>
      <title>{fullName || 'Go Paperless'}</title>
    </Helmet>
  );
};
