import { useParams, useLocation } from 'react-router';
import { useMemo } from 'react';

interface IUseParams {
  name: string;
  id: string;
}

export function useSearchParams(): IUseParams {
  const { id: name } = useParams<{ id: string }>();
  const { search } = useLocation<{ id: string }>();
  const params = useMemo<URLSearchParams>(() => new URLSearchParams(search), [search]);
  const id = params.get('id');
  return useMemo<IUseParams>(
    () => ({
      name,
      id: id || '',
    }),
    [name, id]
  );
}
