import React, { useEffect, useState } from 'react';
import { Box, Typography, Container } from '@material-ui/core';
import { Clear, LanguageRounded } from '@material-ui/icons';
import { InputField } from 'modules/core/components/fields/InputField';
import ClearFields from 'modules/editor/components/CardSectionEditors/Clear';
import { clearSection, isDisabled, updateField } from '../hooks';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { useThunkDispatch } from 'hooks/useThunkDispatch';
import { useStyles } from './useStyles';
import { Links } from './Links';
import { updateAbout } from 'modules/editor/api/card-mutations';
import { AboutAtributes } from 'modules/editor/models';
import { OverlayProgress } from 'modules/core/components/progress/OverlayProgress';

export const About = () => {
  const card = useTypedSelector((state) => state.currentVersion.currentVer);
  const fields = useTypedSelector((state) => state.editorSteps.aboutFields);
  const classes = useStyles();
  const [about, setAbout] = useState<AboutAtributes>(new AboutAtributes(card));
  const [overlay, setOverlay] = useState(false);
  const [errors, setErrors] = useState<{ websiteUrl?: string }>({});
  const thunkDispatch = useThunkDispatch();

  const save = (aboutSec: AboutAtributes) => thunkDispatch(updateAbout(aboutSec));

  useEffect(() => {
    setAbout(new AboutAtributes(card));
  }, [card]);

  const onBlur = (): void => {
    save(about);
  };

  const changeField = (val: any, field: string) => {
    const aAbout = about as any;
    aAbout[field] = val;
    // Replace 3 (or more) linebreaks in description
    if (field === 'description') {
      // TODO: to model
      const description = val as string;
      const EOL = description.match(/\r\n/gm) ? '\r\n' : '\n';
      const regExp = new RegExp('(' + EOL + '){3,}', 'gm');
      aAbout[field] = description.replace(regExp, EOL + EOL);
    }
    const newAbout = about.nextState(about);
    setAbout(newAbout);
  };

  return (
    <Container className={classes.wrapper}>
      <Box display="flex" alignItems="center" mb={1}>
        <Typography className={classes.sectionHeader}>About</Typography>
        {fields.length === 6 ? null : (
          <Box className={classes.clearBlock}>
            <ClearFields
              id="about-editor-clear"
              ico={<Clear fontSize="inherit" />}
              onConfirm={() => clearSection(about, save, setAbout)}
            >
              Clear section
            </ClearFields>
          </Box>
        )}
      </Box>
      <div className={classes.inputWrapper}>
        <Typography className={classes.heading}>Website address</Typography>
        <InputField
          name="about-websiteUrl"
          type="text"
          placeholder="Enter URL"
          disabled={isDisabled(fields, 'websiteUrl')}
          onChange={(e) =>
            updateField({
              errors,
              setErrors,
              setState: setAbout,
              model: about,
              val: e.target.value,
              prop: 'websiteUrl',
            })
          }
          error={!!errors.websiteUrl}
          value={about.websiteUrl}
          variant="filled"
          onBlur={() => !errors.websiteUrl && onBlur()}
          InputProps={{
            startAdornment: (
              <div className={classes.icon3}>
                <LanguageRounded />
              </div>
            ),
          }}
        />
      </div>
      <div className={classes.fieldBlock}>
        <Typography className={classes.heading}>Title</Typography>
        <InputField
          name="about-title"
          type="text"
          label="Enter title"
          onChange={(e) => changeField(e.target.value, 'title')}
          onBlur={onBlur}
          disabled={isDisabled(fields, 'title')}
          value={about.title as string}
          variant="filled"
        />
      </div>
      <div className={classes.fieldBlock}>
        <Typography className={classes.heading}>Description</Typography>
        <InputField
          name="about-description"
          multiline={true}
          rows={7}
          type="text"
          placeholder="Enter description"
          onChange={(e) => changeField(e.target.value, 'description')}
          onBlur={onBlur}
          disabled={isDisabled(fields, 'description')}
          value={about.description as string}
          variant="filled"
        />
      </div>
      <Links about={about} setOverlay={setOverlay} save={save} setAbout={setAbout} />
      <OverlayProgress isProgress={overlay} />
    </Container>
  );
};
