import React, { CSSProperties, useState, RefObject } from 'react';
import { SvgIcon, makeStyles } from '@material-ui/core';
import { ReactComponent as GalleryIcon } from 'assets/svg/gallery.svg';
import CardWrapper from 'modules/editor/components/CardWrapper';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { Description } from './Description';
import { E_CARD_STEPS } from 'modules/editor/constants/enums';
import { useScroll } from 'modules/editor/components/CardSectionsView/hooks/useScroll';

interface IGalleryProps {
  display: boolean;
  bodyStyle: CSSProperties;
  titleStyle: CSSProperties;
  iconStyle: CSSProperties;
  section: IGallerySection;
  wrapperRef: RefObject<HTMLDivElement>;
}

export const GallerySectionView: React.FC<IGalleryProps> = (props) => {
  const { display, bodyStyle, titleStyle, iconStyle, section, wrapperRef } = props;
  const { gallerySectionImages: images, title, showSectionHeader } = section;
  const [index, setIndex] = useState<number>(0);
  const classes = useStyles();
  const ref = useScroll(E_CARD_STEPS.GALLERY, wrapperRef, display);
  const firstLine = showSectionHeader ? <span>Gallery</span> : undefined;

  const galleryImages: ReactImageGalleryItem[] = images.map<ReactImageGalleryItem>((img) => {
    const { galleryImageUrlOriginal: original, galleryImageUrlThumbnail: thumbnail } = img;
    return { original, thumbnail };
  });

  const description = images[index] && images[index].description;
  const linkUrl = images[index] && images[index].linkUrl;

  return display ? (
    <CardWrapper
      ref={ref}
      bodyStyle={bodyStyle}
      titleStyle={titleStyle}
      firstLine={firstLine}
      icon={<SvgIcon children={<GalleryIcon />} htmlColor={'#ffffff'} className="section-icon" style={iconStyle} />}
      title={title}
    >
      <div className={classes.gallery}>
        <ImageGallery items={galleryImages} showNav={false} showPlayButton={false} onSlide={setIndex} />
        {description && <Description value={description} url={linkUrl} />}
      </div>
    </CardWrapper>
  ) : null;
};

const useStyles = makeStyles((theme) => ({
  gallery: {
    position: 'relative',
    '&.preview': {
      '& .image-gallery-content': {
        '&:not(.fullscreen)': {
          '& .image-gallery-image': {
            display: 'flex',
            height: 500,
          },
          '& .image-gallery-slide': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 500,
          },
        },
      },
    },
    '& .image-gallery-content': {
      '& .image-gallery-thumbnail': {
        width: '62px',
        border: '1px solid #E6E9EC',
        borderRadius: '4px',
        '&.active': {
          width: '64px',
          border: '2px solid white',
        },
        '& img': {
          width: '60px',
          height: '60px',
          objectFit: 'cover',
          borderRadius: '2px',
        },
      },
      '& .image-gallery-slide': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxHeight: 500,
      },
      '& .image-gallery-thumbnail + .image-gallery-thumbnail': {
        marginLeft: 13,
      },
      '& .image-gallery-image': {
        width: '100%',
        display: 'flex',
        height: 500,
        '& img': {
          objectFit: 'contain',
          height: 500,
        },
      },
      '&:not(.fullscreen)': {
        '& .image-gallery-thumbnail': {
          borderColor: 'rgba(1, 61, 31, 1)',
          '&.active': {
            borderColor: 'rgba(1, 61, 31, 1)',
          },
        },
        '& .image-gallery-slide-wrapper': {
          '& >span': {
            display: 'none',
          },
        },
      },
      '&.fullscreen': {
        top: '50%',
        transform: 'translate(0, -50%)',
      },
    },
  },
}));
