import { useMemo } from 'react';

type Result = 'windows' | 'mac';
/**
 * returns user's os
 * only supports windows and mac
 */
export function usePlatform(): Result {
  return useMemo(() => {
    const { platform, appVersion } = navigator;
    // platform is prefereble
    const rawValue = platform || appVersion;
    if (rawValue.includes('Mac')) {
      return 'mac';
    }
    return 'windows';
  }, []);
}
