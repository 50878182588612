import React from 'react';
import { FormControlLabel, Typography, Radio } from '@material-ui/core';
import { IPlan } from 'modules/billing/graphql/gql';
import Amount from './Amount';
import { useStyles } from './styles';

const PlanItem: React.FC<{ plan: IPlan; name: string }> = ({ plan, name }) => {
  const classes = useStyles();
  return (
    <div key={plan.id} className={classes.radioWrap}>
      <FormControlLabel
        value={plan.id}
        name={name}
        control={<Radio />}
        label={plan.nickname}
        className={classes.radioButton}
      />
      <Typography className={classes.radioTextLarge}>
        <Amount ccy={plan.currency} amount={plan.amount as number} poa={plan.metadata.poa} vatDisplay={plan.vat} />
      </Typography>
      <Typography className={classes.radioTextSmall}>each {plan.interval}</Typography>
    </div>
  );
};

export default PlanItem;
