import React, { ReactNode, FC } from 'react';
import { Container, Typography, MuiThemeProvider } from '@material-ui/core';
import { ReactComponent as LogoSvg } from 'assets/svg/logo.svg';
import clsx from 'clsx';
import ImgsBackground from 'modules/public/pages/main/imgsBackground';
import { useStyles } from './useStyles';
import darkTheme from 'themes/darkTheme';

export interface INotFoundProps {
  heading: ReactNode;
  subHeading: ReactNode;
  action: JSX.Element;
}

export const NotFound: FC<INotFoundProps> = (props) => {
  const { heading, subHeading, action } = props;
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={darkTheme}>
      <div className={classes.wrap}>
        <ImgsBackground exClass="_not-found" />
        <Container className={classes.root}>
          <div className={classes.logo}>
            <LogoSvg className="logo" />
            <div className={classes.logoText}>
              <Typography variant="h3" className={classes.coloredName}>
                go
              </Typography>
              <Typography variant="h3" className={classes.name}>
                paperless
              </Typography>
            </div>
            <Typography className={clsx(classes.subtitle, '_logo')}>A greener way to connect and grow</Typography>
          </div>
          <Typography className={classes.title} variant="h4">
            {heading}
          </Typography>
          <Typography className={classes.subtitle}>{subHeading}</Typography>
          <br />
          <br />
          {action}
        </Container>
      </div>
    </MuiThemeProvider>
  );
};
