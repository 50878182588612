import { LocalStorageHelper } from 'helpers/localstore';
import { walkthroughStoreKey } from 'modules/editor/constants';
import { E_ACCOUNT_TYPE } from 'modules/auth/constants/enums';

export const setEditorWalkthroughAsFinished = (type?: E_ACCOUNT_TYPE) => {
  return LocalStorageHelper.updateData(`${walkthroughStoreKey}${type ? "_" + type : ""}`, true)
};

export const isEditorWalkthroughFinished = (type: E_ACCOUNT_TYPE) => {
  return LocalStorageHelper.getValue(walkthroughStoreKey) || LocalStorageHelper.getValue(`${walkthroughStoreKey}_${type}`);
};
