import gql from 'graphql-tag';
import { apoloClient } from 'modules/core/constants/client';
import { ISendTextAttributes, IShareAtributes } from 'modules/core/graphql/mutation';
import { restoreArrayOrder } from 'modules/core/utils';
import { buildGqlAttributes, buildGqlRequest, buildGqlScheme } from 'modules/core/utils/apollo';
import {
  IAboutSection,
  IGallerySection,
  ILocationSection,
  INewsSection,
  IPromotionSection,
  IVideoSection,
} from 'modules/editor/graphql/gql';
import {
  IBrandingSectionAtributes,
  INewsAtributes,
  IPersonalDetailAtributes,
  IPromotionAtributes,
  IStylingAtributes,
} from 'modules/editor/graphql/mutations';
import {
  IBrandingSection,
  IPersonalDetailsSection,
  ISocialLinksSection,
  IStylingSection,
} from 'modules/editor/graphql/results';
import {
  UPDATE_BRANDING_SCHEME,
  UPDATE_NEWS_SCHEME,
  UPDATE_PERSONAL_DETAILS_SCHEME,
  UPDATE_PROMOTION_SCHEME,
  UPDATE_STYLING_SCHEME,
} from 'modules/editor/graphql/schemes';
import { AboutAtributes } from 'modules/editor/models/atributes/about-section-link';
import { GalleryAtributes } from 'modules/editor/models/atributes/gallery-atributes';
import { LocationAtributes } from 'modules/editor/models/atributes/location-atributes';
import { SocialLinksAtributes } from 'modules/editor/models/atributes/social-links-atributes';
import { VideoAtributes } from 'modules/editor/models/atributes/video-atributes';
import { AboutSection } from 'modules/editor/models/sections/about-section';
import { GallerySection } from 'modules/editor/models/sections/gallery-section';
import { LocationSection } from 'modules/editor/models/sections/location-section';
import { NewsSection } from 'modules/editor/models/sections/news-section';
import { PromotionSection } from 'modules/editor/models/sections/promo-section';
import { VideoSection } from 'modules/editor/models/sections/video-section';
import { actions } from 'modules/editor/reducers/cardReducer';
import { Dispatch } from 'redux';
import { NULL_PAYLOAD } from '../constants/messages';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IReduxState } from 'store';

export function startCardPublish(cardVersionId: number) {
  return async (dispatch: Dispatch) => {
    try {
      // eslint-disable-next-line
      const res = await apoloClient.mutate({
        variables: { cardVersionId },
        mutation: gql`
          mutation PublishCard($cardVersionId: ID!) {
            publishCard(attributes: { cardVersionId: $cardVersionId }) {
              errors
              status
            }
          }
        `,
      });

      if (res.data.publishCard.errors.length) {
        console.warn(res.data.publishCard.errors);
        throw res.data.publishCard.errors[0];
      }
    } catch (error) {
      console.warn(error);
      throw error;
    }
  };
}

export const shareCard = (attributes: IShareAtributes) =>
  apoloClient.mutate({
    variables: { ...attributes },
    mutation: gql`
      mutation ShareCard(
        $cardId: ID!,
        $name: String!,
        $email: String,
        $phone: String,
        $referrerName: String,
        $message: String
      ) {
        shareCard(
          attributes: {
            cardId: $cardId,
            name: $name,
            email: $email,
            phone: $phone,
            referrerName: $referrerName,
            message: $message,
          }
        ) {
          errors
          status
        }
      }
    `,
  });

export const sendText = async (attributes: ISendTextAttributes) => {
  try {
    await apoloClient.mutate({
      variables: { ...attributes },
      mutation: gql`
        mutation SendMeText($cardId: ID!, $name: String!, $mobilePhone: String!, $message: String!) {
          sendMeText(attributes: { cardId: $cardId, name: $name, mobilePhone: $mobilePhone, message: $message }) {
            errors
            status
          }
        }
      `,
    });
  } catch (error) {
    console.warn(error);
  }
};

export function updateAbout(aboutSec: AboutAtributes): ThunkAction<Promise<void>, IReduxState, null, AnyAction> {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await apoloClient.mutate<{
        updateAboutCard: {
          aboutSection: IAboutSection;
          draft: boolean;
        };
      }>({
        variables: aboutSec.toJSON(),
        mutation: gql`
          mutation UpdateAbout(
            $cardVersionId: ID!
            $id: ID!
            $websiteUrl: String
            $websiteUrlShow: Boolean
            $title: String
            $description: String
            $aboutSectionLinksAttributes: [AboutSectionLinkAttributes!]
          ) {
            updateAboutCard(
              attributes: {
                cardVersionId: $cardVersionId
                description: $description
                id: $id
                title: $title
                websiteUrl: $websiteUrl
                websiteUrlShow: $websiteUrlShow
                aboutSectionLinksAttributes: $aboutSectionLinksAttributes
              }
            ) {
              aboutSection {
                aboutSectionLinks {
                  id
                  fileUrl
                  linkText
                  linkUrl
                }
                description
                id
                title
                websiteUrl
                websiteUrlShow
              }
              draft
            }
          }
        `,
      });
      if (data && data.updateAboutCard) {
        const aboutSection = new AboutSection(data.updateAboutCard.aboutSection);
        dispatch(actions.setAboutSection(aboutSection, data.updateAboutCard.draft));
      } else {
        throw new Error(NULL_PAYLOAD);
      }
    } catch (error) {
      console.warn(error);
    }
  };
}

export function updateBranding(brandingData: IBrandingSectionAtributes, sectionFields: string[]) {
  return async (dispatch: Dispatch) => {
    const scheme = buildGqlScheme(UPDATE_BRANDING_SCHEME, brandingData);
    const attributes = buildGqlAttributes(brandingData);
    const request = buildGqlRequest(sectionFields);
    try {
      const { data } = await apoloClient.mutate<{
        updateBrandingCard: { brandingSection: IBrandingSection; draft: boolean };
      }>({
        variables: brandingData,
        mutation: gql`
          mutation UpdateBranding( ${scheme} ) {
            updateBrandingCard( attributes: {${attributes}} ) {
              brandingSection { ${request} },
              draft
            }
          }
        `,
      });
      if (data) {
        dispatch(actions.setBrandingSection(data.updateBrandingCard.brandingSection, data.updateBrandingCard.draft));
      }
    } catch (error) {
      console.warn(error);
    }
  };
}

export function updateGallery(galleryAtr: GalleryAtributes, withoutImg?: boolean) {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await apoloClient.mutate<{
        updateGalleryCard: {
          gallerySection: IGallerySection;
          draft: boolean;
        };
      }>({
        variables: withoutImg ? galleryAtr.withoutImages() : galleryAtr.toJSON(),
        mutation: gql`
          mutation UpdateGallery(
            $cardVersionId: ID!
            $id: ID
            $title: String
            $showSectionHeader: Boolean
            $gallerySectionImagesAttributes: [GalleryImageAttributes!]
          ) {
            updateGalleryCard(
              attributes: {
                cardVersionId: $cardVersionId
                title: $title
                showSectionHeader: $showSectionHeader
                id: $id
                gallerySectionImagesAttributes: $gallerySectionImagesAttributes
              }
            ) {
              gallerySection {
                id
                title
                showSectionHeader
                gallerySectionImages {
                  id
                  description
                  galleryImageUrl
                  galleryImageUrlOriginal
                  galleryImageUrlThumbnail
                  linkUrl
                }
              }
              draft
            }
          }
        `,
      });
      if (data && data.updateGalleryCard) {
        const restored = restoreArrayOrder(
          galleryAtr.gallerySectionImagesAttributes,
          data.updateGalleryCard.gallerySection.gallerySectionImages,
          'id'
        );
        const restoredObj: IGallerySection = {
          ...data.updateGalleryCard.gallerySection,
          gallerySectionImages: restored,
        };
        dispatch(actions.setGallerySection(new GallerySection(restoredObj), data.updateGalleryCard.draft));
        return restoredObj;
      } else {
        throw new Error(NULL_PAYLOAD);
      }
    } catch (error) {
      console.warn(error);
      throw error;
    }
  };
}

export function updateLocation(locationAtr: LocationAtributes) {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await apoloClient.mutate<{
        updateLocationCard: {
          locationSection: ILocationSection;
          draft: boolean;
        };
      }>({
        variables: locationAtr.toJSON(),
        mutation: gql`
          mutation UpdateLocation(
            $cardVersionId: ID!
            $id: ID
            $streetAddress: String
            $streetAddress2: String
            $city: String
            $state: String
            $country: String
            $postcode: String
            $display12h: Boolean
            $openingTimesAttributes: [LocationOpeningTimeAttributes!]
          ) {
            updateLocationCard(
              attributes: {
                cardVersionId: $cardVersionId
                id: $id
                streetAddress: $streetAddress
                streetAddress2: $streetAddress2
                city: $city
                state: $state
                country: $country
                postcode: $postcode
                display12h: $display12h
                openingTimesAttributes: $openingTimesAttributes
              }
            ) {
              locationSection {
                city
                country
                id
                postcode
                display12h
                state
                streetAddress
                streetAddress2
                lat
                lng
                openingTimes {
                  id
                  fromDay
                  toDay
                  fromHour
                  toHour
                }
              }
              draft
            }
          }
        `,
      });
      if (data) {
        const { city, country, postcode, state, streetAddress, streetAddress2 } = data.updateLocationCard.locationSection;
        if (!city && !country && !postcode && !state && !streetAddress && !streetAddress2) {
          data.updateLocationCard.locationSection.lat = null;
          data.updateLocationCard.locationSection.lng = null;
        }
        const restored = restoreArrayOrder(
          locationAtr.openingTimesAttributes,
          data.updateLocationCard.locationSection.openingTimes,
          'id'
        );
        const restoredObj: ILocationSection = {
          ...data.updateLocationCard.locationSection,
          openingTimes: restored,
        };
        dispatch(actions.setLocationSection(new LocationSection(restoredObj), data.updateLocationCard.draft));
        return restoredObj;
      }
    } catch (error) {
      throw error;
    }
  };
}

export function updateNews(newsAtr: INewsAtributes, sectionFields: string[]) {
  return async (dispatch: Dispatch) => {
    try {
      const scheme = buildGqlScheme(UPDATE_NEWS_SCHEME, newsAtr);
      const attributes = buildGqlAttributes(newsAtr);
      const request = buildGqlRequest(sectionFields);
      const { data } = await apoloClient.mutate<{
        updateNewsCard: {
          newsSection: INewsSection;
          draft: boolean;
        };
      }>({
        variables: newsAtr,
        mutation: gql`
          mutation UpdateNews( ${scheme} ) {
            updateNewsCard(
              attributes: { ${attributes}}
            ) {
              newsSection { ${request} },
              draft
            }
          }
        `,
      });
      if (data) {
        const newsSection = new NewsSection(data.updateNewsCard.newsSection);
        dispatch(actions.setNewsSection(newsSection, data.updateNewsCard.draft));
        return newsSection;
      }
    } catch (error) {
      console.warn(error);
    }
  };
}

export function updatePersonalDetails(personalDetailsAtr: IPersonalDetailAtributes, sectionFields: string[]) {
  return async (dispatch: Dispatch) => {
    const scheme = buildGqlScheme(UPDATE_PERSONAL_DETAILS_SCHEME, personalDetailsAtr);
    const attributes = buildGqlAttributes(personalDetailsAtr);
    const request = buildGqlRequest(sectionFields);

    try {
      const { data } = await apoloClient.mutate<{
        updatePersonalDetailsCard: {
          personalDetailsSection: IPersonalDetailsSection;
          errors: string[];
          draft: boolean;
        };
      }>({
        variables: personalDetailsAtr,
        mutation: gql`
          mutation UpdatePersonalDetails( ${scheme} ) {
            updatePersonalDetailsCard(
              attributes: { ${attributes} }
            ) {
              personalDetailsSection { ${request} },
              draft
            }
          }
        `,
      });
      if (data && data.updatePersonalDetailsCard && data.updatePersonalDetailsCard.personalDetailsSection) {
        dispatch(
          actions.setPersonalSection(
            data.updatePersonalDetailsCard.personalDetailsSection,
            data.updatePersonalDetailsCard.draft
          )
        );
      }
      if (data && data.updatePersonalDetailsCard.errors) {
        throw data.updatePersonalDetailsCard.errors;
      }
    } catch (error) {
      console.warn(error);
      throw error;
    }
  };
}

export function updatePromotionCard(atr: IPromotionAtributes, fields: string[]) {
  return async (dispatch: Dispatch) => {
    try {
      const scheme = buildGqlScheme(UPDATE_PROMOTION_SCHEME, atr);
      const attributes = buildGqlAttributes(atr);
      const request = buildGqlRequest(fields);
      const { data } = await apoloClient.mutate<{
        updatePromotionCard: {
          promotionSection: IPromotionSection;
          draft: boolean;
        };
      }>({
        variables: atr,
        mutation: gql`
          mutation UpdatePromotionCard( ${scheme} ) {
            updatePromotionCard(
              attributes: { ${attributes} }
            ) {
              promotionSection { ${request} },
              draft
            }
          }
        `,
      });
      if (data) {
        const promoSection = new PromotionSection(data.updatePromotionCard.promotionSection);
        dispatch(actions.setPromotionSection(promoSection, data.updatePromotionCard.draft));
        return promoSection;
      }
    } catch (error) {
      console.warn(error);
    }
  };
}

export function updateSocialLinks(socLinksAtr: SocialLinksAtributes) {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await apoloClient.mutate<{
        updateSocialLinksCard: {
          socialLinksSection: ISocialLinksSection;
          draft: boolean;
        };
      }>({
        variables: socLinksAtr.toJSON(),
        mutation: gql`
          mutation UpdateSocialLinks(
            $cardVersionId: ID!
            $linkedInUrl: String
            $linkedInUrlShow: Boolean
            $facebookUrl: String
            $facebookUrlShow: Boolean
            $twitterUrl: String
            $twitterUrlShow: Boolean
            $youtubeUrl: String
            $youtubeUrlShow: Boolean
            $whatsappUrl: String
            $whatsappUrlShow: Boolean
            $podcastUrl: String
            $podcastUrlShow: Boolean
            $blogUrl: String
            $blogUrlShow: Boolean
            $snapchatUrl: String
            $snapchatUrlShow: Boolean
            $instagramUrl: String
            $instagramUrlShow: Boolean
            $pinterestUrl: String
            $pinterestUrlShow: Boolean
          ) {
            updateSocialLinksCard(
              attributes: {
                cardVersionId: $cardVersionId
                linkedInUrl: $linkedInUrl
                linkedInUrlShow: $linkedInUrlShow
                facebookUrl: $facebookUrl
                facebookUrlShow: $facebookUrlShow
                twitterUrl: $twitterUrl
                twitterUrlShow: $twitterUrlShow
                youtubeUrl: $youtubeUrl
                youtubeUrlShow: $youtubeUrlShow
                whatsappUrl: $whatsappUrl
                whatsappUrlShow: $whatsappUrlShow
                podcastUrl: $podcastUrl
                podcastUrlShow: $podcastUrlShow
                blogUrl: $blogUrl
                blogUrlShow: $blogUrlShow
                snapchatUrl: $snapchatUrl
                snapchatUrlShow: $snapchatUrlShow
                instagramUrl: $instagramUrl
                instagramUrlShow: $instagramUrlShow
                pinterestUrl: $pinterestUrl
                pinterestUrlShow: $pinterestUrlShow
              }
            ) {
              socialLinksSection {
                cardVersionId
                facebookUrl
                facebookUrlShow
                linkedInUrl
                linkedInUrlShow
                twitterUrl
                twitterUrlShow
                whatsappUrl
                whatsappUrlShow
                youtubeUrl
                youtubeUrlShow
                podcastUrl
                podcastUrlShow
                blogUrl
                blogUrlShow
                snapchatUrl
                snapchatUrlShow
                instagramUrl
                instagramUrlShow
                pinterestUrl
                pinterestUrlShow
              }
              draft
            }
          }
        `,
      });
      if (data) {
        dispatch(
          actions.setSocialSection(data.updateSocialLinksCard.socialLinksSection, data.updateSocialLinksCard.draft)
        );
      }
    } catch (error) {
      console.warn(error);
    }
  };
}

export function updateStyling(stylingAtr: IStylingAtributes, sectionFields: string[]) {
  return async (dispatch: Dispatch) => {
    const scheme = buildGqlScheme(UPDATE_STYLING_SCHEME, stylingAtr);
    const attributes = buildGqlAttributes(stylingAtr);
    const request = buildGqlRequest(sectionFields);
    try {
      const { data } = await apoloClient.mutate<{
        updateStylingCard: { stylingSection: IStylingSection; draft: boolean };
        errors: any[];
      }>({
        variables: stylingAtr,
        mutation: gql`
          mutation UpdateStyling(${scheme}) {
            updateStylingCard(
              attributes: { ${attributes} }
            ) {
              stylingSection {
                ${request}
              },
              draft
            }
          }
        `,
      });
      if (data && data.updateStylingCard && data.updateStylingCard.stylingSection) {
        dispatch(actions.setStylingSection(data.updateStylingCard.stylingSection, data.updateStylingCard.draft));
      } else if (data && data.errors) {
        throw data.errors[0].message;
      }
    } catch (error) {
      console.warn(error, 'catch');
      throw error;
    }
  };
}

export function updateVideo(videoAtr: VideoAtributes) {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await apoloClient.mutate<{
        updateVideoCard: {
          videoSection: IVideoSection;
          draft: boolean;
        };
      }>({
        variables: videoAtr.toJSON(),
        mutation: gql`
          mutation UpdateVideo(
            $cardVersionId: ID!,
            $title: String,
            $showSectionHeader: Boolean,
            $linkUrl: String
          ) {
            updateVideoCard(
              attributes: {
                cardVersionId: $cardVersionId,
                title: $title,
                showSectionHeader: $showSectionHeader,
                linkUrl: $linkUrl
              }
            ) {
              videoSection {
                linkUrl
                title
                showSectionHeader
              }
              draft
            }
          }
        `,
      });
      if (data && data.updateVideoCard.videoSection) {
        dispatch(
          actions.setVideoSection(new VideoSection(data.updateVideoCard.videoSection), data.updateVideoCard.draft)
        );
      } else {
        throw new Error(NULL_PAYLOAD);
      }
    } catch (error) {
      console.warn(error);
    }
  };
}

export function startSwitchCardVersion(cardVersionId: number) {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await apoloClient.mutate<{
        selectVersionCard: {
          errors: string[];
          status: string;
        };
      }>({
        variables: { cardVersionId },
        mutation: gql`
          mutation SelectCardVersion($cardVersionId: ID!) {
            selectVersionCard(attributes: { cardVersionId: $cardVersionId }) {
              errors
              status
            }
          }
        `,
      });

      if (data && data.selectVersionCard.errors.length) {
        console.warn(data.selectVersionCard.errors);
        throw data.selectVersionCard.errors[0];
      }
    } catch (error) {
      console.warn(error);
      throw error;
    };
  };
}
