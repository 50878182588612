import React, { useRef } from 'react';
import { ReactComponent as LogoSvg } from 'assets/svg/logo.svg';
import { Cropper } from './Cropper';
import { makeStyles } from '@material-ui/core';
import { Controls } from 'modules/core/components/fields/UploadImage/CropOverlay/Controls';
import ReactCropper from 'react-cropper';

interface IState {
  onClose: () => void;
  src: string;
  onSave: (src: string) => void;
}

export const CropOverlay: React.FC<IState> = (props) => {
  const { onClose, src, onSave } = props;
  const classes = useStyles();
  const cropperRef = useRef<ReactCropper>(null);

  function onCrop(): void {
    const { current } = cropperRef;
    if (current) {
      const newDataUrl = current.getCroppedCanvas().toDataURL();
      onSave(newDataUrl);
    }
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.logoWrapper}>
          <LogoSvg id="cropp-logo" className={classes.logo} />
          <span className={classes.divider} />
          <span className={classes.logoText}>Crop image</span>
        </div>
        <Controls onClose={onClose} onCrop={onCrop} />
      </div>
      <div className={classes.cropWrapper}>
        <Cropper src={src} ref={cropperRef} />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999,
    backgroundColor: '#000',
    color: '#fff',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100px',
    padding: '0 15px',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#252B31',
      flexDirection: 'column',
      alignItems: 'stretch',
      padding: '15px',
      paddingBottom: 30,
      height: '115px',
    },
  },
  cropWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  logoWrapper: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  logo: {
    fill: '#fff',
    width: '30px',
    height: '30px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  divider: {
    width: '1px',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    margin: '0 15px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logoText: {
    verticalAlign: 'middle',
    lineHeight: '30px',
    fontSize: '24px',
    fontWeight: 600,
    letterSpacing: '1px',
  },
}));
