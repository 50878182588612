import React, { CSSProperties, RefObject } from 'react';
import { ILocationSection, ILocationSectionOpeningTime } from 'modules/editor/graphql/gql';
import CardWrapper from 'modules/editor/components/CardWrapper';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as LocationIcon } from 'assets/svg/location.svg';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { Box } from '@material-ui/core';
import { MAP_API_KEY } from 'modules/core/constants/config';
import { shortWeekday } from 'modules/core/utils';
import { isNumber } from 'util';
import { When } from 'react-if';
import { useScroll } from 'modules/editor/components/CardSectionsView/hooks/useScroll';
import { E_CARD_STEPS } from 'modules/editor/constants/enums';
import { formattedOpeningHour } from 'modules/core/utils/dateTime';

interface ILocationProps {
  section: ILocationSection;
  display: boolean;
  classes: Record<string, string>;
  bodyStyle: CSSProperties;
  titleStyle: CSSProperties;
  iconStyle: CSSProperties;
  linkStyle: CSSProperties;
  isPreview?: boolean;
  wrapperRef: RefObject<HTMLDivElement>;
}

const MyMapComponent = withScriptjs<any>(
  withGoogleMap((props: { lat: string; lng: string }) => {
    const latNumber = Number(props.lat);
    const lngNumber = Number(props.lng);
    return (
      <GoogleMap
        defaultZoom={17}
        defaultCenter={{ lat: latNumber || 51.509865, lng: lngNumber || -0.118092 }}
        center={{ lat: latNumber || 51.509865, lng: lngNumber || -0.118092 }}
      >
        <Marker position={{ lat: latNumber || 51.509865, lng: lngNumber || -0.118092 }} />
      </GoogleMap>
    );
  })
);

/**
 * Location section view for card. Display google map and business hours.
 * @param props ILocationProps
 */
export const LocationSection: React.FC<ILocationProps> = ({
  section,
  display,
  classes,
  bodyStyle,
  iconStyle,
  linkStyle,
  titleStyle,
  isPreview,
  wrapperRef,
}) => {
  const ref = useScroll(E_CARD_STEPS.LOCATION, wrapperRef, display);

  const generateTime = (timeSec: ILocationSectionOpeningTime) => {
    const { fromHour, toHour, fromDay, toDay } = timeSec;
    const { display12h } = section;

    if (fromHour || toHour) {
      const formattedFrom = fromHour && formattedOpeningHour(fromHour as number, { display12h });
      const formattedTo = toHour && formattedOpeningHour(toHour as number, { display12h });

      return `${fromDay || toDay ? ', ' : ''}${formattedFrom}${
        isNumber(fromHour) && isNumber(toHour) ? ' - ' : ''
      }${formattedTo}`;
    } else {
      return '';
    };
  };

  return (
    <When condition={display}>
      <CardWrapper
        {...section}
        ref={ref}
        bodyStyle={bodyStyle}
        titleStyle={titleStyle}
        firstLine={<span>Our location</span>}
        icon={<SvgIcon children={<LocationIcon />} htmlColor={'#ffffff'} className="section-icon" style={iconStyle} />}
      >
        <Box>
          {section.lat && section.lng ? (
            <MyMapComponent
              lat={section.lat}
              lng={section.lng}
              googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${MAP_API_KEY}`}
              loadingElement={<div style={{ height: `400px` }} />}
              containerElement={<div style={{ height: `400px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          ) : null}
        </Box>

        <Box className={classes.address} style={bodyStyle}>
          <Box>{section.streetAddress}</Box>
          <Box>{section.streetAddress2}</Box>
          <Box>{section.city}</Box>
          <Box>{section.state}</Box>
          <Box>{section.country}</Box>
          <Box>{section.postcode}</Box>
        </Box>
        <When condition={!!section.openingTimes.length}>
          <Box className={classes.hours} fontSize="0.8rem">
            <Box className={classes.hoursTitle} mt={2} style={bodyStyle}>
              Business hours
            </Box>
            {section.openingTimes.map((t) => (
              <Box style={bodyStyle} fontWeight="bold !important" key={t.id}>
                {`${shortWeekday(t.fromDay)}${t.fromDay && t.toDay ? ' - ' : ''}${shortWeekday(t.toDay)}${
                  t.fromHour || t.toHour ? generateTime(t) : ''
                }`}
              </Box>
            ))}
          </Box>
        </When>
        <When condition={!!(section.lat && section.lng)}>
          <div className={classes.findMore}>
            <a
              style={linkStyle}
              href={`https://maps.google.com/?q=${section.lat},${section.lng}`}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Open in map
            </a>
          </div>
        </When>
      </CardWrapper>
    </When>
  );
};
