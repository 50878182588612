import { ID } from 'modules/core/types';
import { IPayoutBatch } from 'modules/dashboard/graphql/gql';
import moment from 'moment';

export class PayoutBatch implements IPayoutBatch {
  public id: ID = 0;
  public currency!: string;
  public amount!: number;
  public directCommission!: number;
  public networkCommission!: number;
  public paymentReference!: string;
  public paidAt!: string;
  public paidAtMonth!: string;
  public paidAtYear!: string;

  constructor(data?: IPayoutBatch) {
    if (data) {
      this.id = data.id as string;
      this.currency = data.currency;
      this.amount = data.amount;
      this.directCommission = data.directCommission;
      this.networkCommission = data.networkCommission;
      this.paymentReference = data.paymentReference;
      this.paidAt = data.paidAt;
      this.paidAtMonth = data.paidAtMonth;
      this.paidAtYear = data.paidAtYear;
    }
  }

  public payoutDate(): string {
    return moment(`${this.paidAtMonth} ${this.paidAtYear}`, "M YYYY").format("MMMM YYYY");
  }
}



