import { IResellerCommissionBreakdown, IResellerPayout } from 'modules/dashboard/graphql/gql';
import { ResellerPayout } from 'modules/dashboard/models/reseller-payout';

export class ResellerCommissionBreakdown implements IResellerCommissionBreakdown {
  public resellerPayouts: IResellerPayout[] = [];
  public totalPayoutCount: number = 0;
  public pages: number = 0;
  public page: number = 0;
  public countForPage: number = 0;

  constructor(data?: IResellerCommissionBreakdown) {
    if (data) {
      this.resellerPayouts = data.resellerPayouts.map((rp) => new ResellerPayout(rp));
      this.totalPayoutCount = data.totalPayoutCount;
      this.pages = data.pages;
      this.page = data.page;
      this.countForPage = data.countForPage;
    }
  }
}
