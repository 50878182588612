import React from "react";
import { PieChart } from "react-minimal-pie-chart";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const usePieBulletStyles = makeStyles((theme: Theme) =>
  createStyles({
    pieBulletWrap: {
      width: '20px',
      height: '20px',
      border: '2px solid #F1F8F0',
      display: 'inline-block',
      marginRight: '40px',
      marginLeft: '4px',
      borderRadius: '50%',
      padding: 2,
      '& svg': {
        marginTop: 0,
        display: 'block'
      },
      [theme.breakpoints.down('xs')]: {
        marginRight: '20px',
      }
    },
    pieBulletWrapOuter: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      marginBottom: 13,
      color: 'white',
    },
    active: {
      color: '#74BA35',
      '& i': {
        border: '2px solid #74BA35',
      }
    }
  })
);
interface IPieBullet {
  slices: number;
  selected: number;
  colorFull?: string;
  colorEmpty?: string;
};



const PieBullet: React.FC<IPieBullet> = ({ slices = 1, selected = 0, colorFull = '#999999', colorEmpty = 'transparent' }) => {
  const [selectedBullet, setSelectedBullet] = React.useState(selected)

  React.useEffect(() => {
    setSelectedBullet(selected)
  }, [selected])

  const pie = [...new Array(slices)].map((s, i) => {
    return {
      title: 1,
      value: 100 / slices,
      color: selectedBullet >= i ? colorFull : colorEmpty
    };
  });
  return <PieChart data={pie} startAngle={-90} />;
};

export default PieBullet;