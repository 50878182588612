import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useFooterStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      position: 'relative',
      borderTop: '1px rgba(255,255,255,0.2) solid',
      alignItems: 'center',
      display: 'flex',
      paddingTop: 60,
      justifyContent: 'center',
      flexDirection: 'column',
      color: 'white',
      fontFamily: 'F37 Ginger Light',
      '&.preview': {
        borderTop: 'none',
      },
      // [theme.breakpoints.between('xs', 'sm')]: {
      //   borderTop: 'none',
      // },
    },

    urls: {
      display: 'flex',
      justifyContent: 'space-evenly',
      margin: '24px 10% 50px',
      width: '100%',
      [theme.breakpoints.between('xs', 'sm')]: {
        display: 'none',
      },
      '&.hide': {
        display: 'none',
      },
    },

    link: {
      color: '#FFF',
      fontSize: '12px',
      textDecoration: 'none',
    },

    copyright: {
      textAlign: 'center',
      fontSize: '10px',
      fontWeight: 'bold',
    },

    button: {
      backgroundColor: 'rgba(255,255,255,0.1)',
      cursor: 'pointer',
      boxShadow: '0 10px 20px 0 rgba(0,0,0,0.25)',
      textTransform: 'uppercase',
      padding: '20px 79px',
      fontSize: '14px',
      marginTop: '30px',
      marginBottom: '70px',
      borderRadius: '30px',
      zIndex: 4,
    },
    logo: {
      width: '30px',
      height: '30px',
      fill: 'white',
    },
    darken: {
      position: 'relative',
      display: 'block',
      width: '100%',
      '&:first-child': {
        marginTop: 90,
        [theme.breakpoints.between('xs', 'sm')]: {
          marginTop: 0,
        },
      },
      '&::before': {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: 0,
        backgroundColor: 'rgba(0,0,0,0.4)',
        content: `' '`,
      },
    },
  })
);
