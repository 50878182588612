import React, { useMemo } from 'react';
import { LinkedIn, Facebook, Twitter, YouTube, WhatsApp, Instagram, Pinterest } from '@material-ui/icons';
import { constructLink } from 'modules/core/utils';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as Snapchat } from 'assets/svg/snapchat.svg';
import { ReactComponent as Blog } from 'assets/svg/blog.svg';
import { ReactComponent as Podcast } from 'assets/svg/podcast.svg';
import { ICardVersion } from 'modules/editor/graphql/gql';
import { CardUrl } from 'modules/editor/components/CardSectionsView/MainInfo/Actions/useButtons';

interface IButtonBase {
  icon: JSX.Element;
  url?: string;
  id: string;
}

interface IButtonData extends IButtonBase {
  show: boolean;
}

interface IResultButton extends Omit<IButtonBase, 'url'> {
  url: CardUrl;
}

export function useSocialButtons(cardVer: ICardVersion): IResultButton[] {
  const {
    linkedInUrl,
    linkedInUrlShow,
    facebookUrl,
    facebookUrlShow,
    twitterUrl,
    twitterUrlShow,
    youtubeUrl,
    youtubeUrlShow,
    whatsappUrl,
    whatsappUrlShow,
    instagramUrl,
    instagramUrlShow,
    pinterestUrl,
    pinterestUrlShow,
    snapchatUrl,
    snapchatUrlShow,
    blogUrl,
    blogUrlShow,
    podcastUrl,
    podcastUrlShow,
  } = cardVer;
  return useMemo(
    (() => {
      const buttonsData: IButtonData[] = [
        {
          icon: <LinkedIn className="icon" />,
          url: linkedInUrl,
          show: linkedInUrlShow,
          id: 'card_linkedin_button',
        },
        {
          icon: <Facebook className="icon" />,
          url: facebookUrl,
          show: facebookUrlShow,
          id: 'card_facebook_button',
        },
        {
          icon: <Twitter className="icon" />,
          url: twitterUrl,
          show: twitterUrlShow,
          id: 'card_twitter_button',
        },
        {
          icon: <YouTube className="icon" />,
          url: youtubeUrl,
          show: youtubeUrlShow,
          id: 'card_youtube_button',
        },
        {
          icon: <WhatsApp className="icon" />,
          url: `https://api.whatsapp.com/send?phone=${whatsappUrl && whatsappUrl.replace(/(\+)|(-)|(\()|(\))|(\s)/g, '')}`,
          show: whatsappUrlShow,
          id: 'card_whatsapp_button',
        },
        {
          icon: <Instagram className="icon" />,
          url: instagramUrl,
          show: instagramUrlShow,
          id: 'card_instagram_button',
        },
        {
          icon: <Pinterest className="icon" />,
          url: pinterestUrl,
          show: pinterestUrlShow,
          id: 'card_pinterest_button',
        },
        {
          icon: <SvgIcon children={<Snapchat />} className="icon" style={{ width: 'inherit', height: 'inherit' }} />,
          url: snapchatUrl,
          show: snapchatUrlShow,
          id: 'card_snapchat_button',
        },
        {
          icon: <SvgIcon children={<Blog />} className="icon" />,
          url: blogUrl,
          show: blogUrlShow,
          id: 'card_blog_button',
        },
        {
          icon: <SvgIcon children={<Podcast />} className="icon" />,
          url: podcastUrl,
          show: podcastUrlShow,
          id: 'card_podcast_button',
        },
      ];

      return buttonsData
        .filter((button) => {
          const { show, url } = button;
          return show && url;
        })
        .map((button) => {
          const { show, url: urlString = '', ...rest } = button;
          const url: CardUrl = {
            to: constructLink(urlString),
            noRoute: true,
            target: '_blank',
          };
          return { ...rest, url };
        })
    }),
    [
      blogUrl,
      blogUrlShow,
      facebookUrl,
      facebookUrlShow,
      instagramUrl,
      instagramUrlShow,
      linkedInUrl,
      linkedInUrlShow,
      pinterestUrl,
      pinterestUrlShow,
      podcastUrl,
      podcastUrlShow,
      snapchatUrl,
      snapchatUrlShow,
      twitterUrl,
      twitterUrlShow,
      whatsappUrl,
      whatsappUrlShow,
      youtubeUrl,
      youtubeUrlShow,
    ]
  );
}
