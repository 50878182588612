import React from 'react';
import { ListItem, Box } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from 'modules/core/components/Header/styles';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { EDITOR_ROUTE, SIGNIN_ROUTE } from 'routes/routes';
import { useHistory } from 'react-router';
import { useUserName } from 'modules/core/hooks/useUserName';

export const UserDrawerName: React.FC = () => {
  const { pending, isAuthenticated } = useTypedSelector((state) => state.auth);
  const classes = useStyles();
  const history = useHistory();
  const userName = useUserName();

  if (pending) {
    return (
      <ListItem button={true} divider={true} className={clsx(classes.menuItem, '_green')}>
        <Box mr="10px" />
        {userName}
      </ListItem>
    );
  }
  return (
    <ListItem
      button={true}
      divider={true}
      className={clsx(classes.menuItem, '_green')}
      onClick={() => history.push(isAuthenticated ? EDITOR_ROUTE.path : SIGNIN_ROUTE.path)}
    >
      <Box mr="10px" />
      {userName}
    </ListItem>
  );
};
