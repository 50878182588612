import { IModel } from 'modules/core/interfaces/model';
import { ValidationError } from 'yup';

export const clearSection = (model: IModel<any>, save: (atr: any) => any, setState: (atr: any) => any): void => {
  setState(model.clear(false));
  save(model.clear(true));
};

interface IUpdateAndSave<T> {
  model: IModel<T>;
  prop: string;
  val: any;
  save: (atr: any) => any;
  setState: (atr: any) => any;
}

export const updateAndSave = <T>(params: IUpdateAndSave<T>) => {
  const { model, prop, save, setState, val } = params;
  model[prop] = val;
  const atr = model.nextState(model as any);
  setState(atr);
  save(atr);
};

interface IUpdateField<T> {
  model: IModel<T>;
  prop: string;
  val: any;
  errors?: any;
  setState: (atr: any) => any;
  setErrors?: (art: any) => void;
}

export const updateField = <T>(params: IUpdateField<T>) => {
  const { model, val, setState, prop, setErrors, errors } = params;
  const atr = model.nextState(model as any);
  atr[prop] = val;
  if (atr.validate) {
    try {
      atr.validate(prop);
      if (setErrors) {
        setErrors({ ...errors, [prop]: undefined });
      }
    } catch (error) {
      const err: ValidationError = error;
      if (setErrors) {
        setErrors({ ...errors, [prop]: err.message });
      }
    }
  }
  setState(atr);
};

export const checkErrors = <T>(params: IUpdateField<T>) => {
  const { model, val, prop } = params;
  model[prop] = val;
  if (model.validate) {
    try {
      model.validate(prop);
    } catch (error) {
      return true;
    }
  }
  return false;
};

export const isDisabled = (fields: string[], field: string) => {
  return fields.findIndex((f) => f === field) !== -1;
};
