import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IReduxState } from 'store';
import { getCurrency } from 'modules/core/api/currency';

interface IState {
  currency: string;
  getCurrency: () => void;
}

const mapState = (state: IReduxState) => {
  return {
    currency: state.general.currency,
  };
};

const GeneralLoader: React.FC<IState> = ({ currency, getCurrency: getCurr }) => {
  useEffect(() => {
    getCurr();
  }, [getCurr]);

  return null;
};

export default connect(
  mapState,
  {
    getCurrency,
  }
)(GeneralLoader);
