import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      background: '#22272c',
      borderRadius: 2,
      color: '#ffffff',
      display: 'flex',
      fontWeight: 600,
      justifyContent: 'center',
      marginRight: 7,
      padding: '2px 3px 0px',
    },
  })
);

interface INumberIterationProps {
  numbers: number;
}

const NumberIteration: React.FC<INumberIterationProps> = ({ numbers }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <span>
        {numbers < 10 ? '0' : null}
        {numbers}
      </span>
    </div>
  );
};

export default NumberIteration;
