import { ICardStepsRS } from 'modules/editor/reducers/stepsReducer';

export const MEMBER_DISABLED_FIELDS: Partial<ICardStepsRS> = {
  aboutFields: ['websiteUrl', 'websiteUrlShow', 'title', 'description', 'linkUrl', 'linkText'],
  brandingFields: [
    'backgroundHexColour',
    'showBackground',
    'backgroundImage',
    'overlayShow',
    'overlayHexColour',
    'logo',
    'icon',
  ],
  galleryFields: ['title', 'description', 'linkUrl', 'galleryImageName'],
  locationFields: ['city', 'country', 'postcode', 'state', 'streetAddress', 'streetAddress2', 'fromDay', 'toDay', 'fromHour', 'toHour', 'display12h'],
  newsFields: ['linkText', 'linkUrl', 'linkUrlShow', 'title', 'newsImageName'],
  personalFields: ['contactDetailsHeaderShow'],
  promotionFields: ['linkText', 'linkUrl', 'linkUrlShow', 'title', 'promotionImageName'],
  socialFields: [],
  stylingFields: [
    'bodyFont',
    'bodyFontHexColour',
    'bodyFontStyle',
    'businessName',
    'primaryLinksHexColour',
    'secondaryIconsHexColour',
    'tagLine',
    'titleFont',
    'titleFontHexColour',
    'titleFontStyle',
    'taglineHexColour',
  ],
  videoFields: ['linkUrl', 'title'],
};

export const RESALER_DISABLED_FIELDS: Partial<ICardStepsRS> = {
  aboutFields: ['websiteUrl', 'websiteUrlShow', 'title', 'description', 'linkUrl', 'linkText'],
  brandingFields: [
    'backgroundHexColour',
    'showBackground',
    'backgroundImage',
    'overlayShow',
    'overlayHexColour',
    'logo',
    'icon',
  ],
  galleryFields: ['title', 'description', 'linkUrl', 'galleryImageName'],
  locationFields: ['city', 'country', 'postcode', 'state', 'streetAddress', 'streetAddres2s', 'fromDay', 'toDay', 'fromHour', 'toHour', 'display12h'],
  newsFields: ['linkText', 'linkUrl', 'linkUrlShow', 'title', 'newsImageName'],
  personalFields: ['jobTitle', 'qualifications'],
  promotionFields: ['linkText', 'linkUrl', 'linkUrlShow', 'title', 'promotionImageName'],
  socialFields: [
    'facebookUrl',
    'facebookUrlShow',
    'linkedInUrl',
    'linkedInUrlShow',
    'twitterUrl',
    'twitterUrlShow',
    'whatsappUrl',
    'whatsappUrlShow',
    'youtubeUrl',
    'youtubeUrlShow',
    'podcastUrl',
    'podcastUrlShow',
    'blogUrl',
    'blogUrlShow',
    'snapchatUrl',
    'snapchatUrlShow',
    'instagramUrl',
    'instagramUrlShow',
    'pinterestUrl',
    'pinterestUrlShow',
  ],
  stylingFields: [
    'bodyFont',
    'bodyFontHexColour',
    'bodyFontStyle',
    'businessName',
    'primaryLinksHexColour',
    'secondaryIconsHexColour',
    'tagLine',
    'titleFont',
    'titleFontHexColour',
    'titleFontStyle',
    'taglineHexColour',
  ],
  videoFields: ['linkUrl', 'title'],
};

export const OWNER_DISABLED_FIELDS: Partial<ICardStepsRS> = {
  aboutFields: [],
  brandingFields: [],
  galleryFields: [],
  locationFields: [],
  newsFields: [],
  personalFields: ['contactDetailsHeaderShow'],
  promotionFields: [],
  socialFields: [],
  stylingFields: [],
  videoFields: [],
};

export const ADMIN_DISABLED_FIELDS = OWNER_DISABLED_FIELDS;
