import React, { FC } from 'react';
import { Popover, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Video from 'modules/core/components/Video';
import { videoModal as useStyles } from './styles';

interface IProps {
  youtubeUrl: string;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  open: boolean;
}

export const VideoDialog: FC<IProps> = (props) => {
  const { onClose, youtubeUrl, anchorEl, open } = props;
  const classes = useStyles();
  return (
    <Popover
      open={open}
      className={classes.videoWrapper}
      classes={{ paper: classes.paper }}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <IconButton onClick={onClose} className={classes.closeBtn}>
        <CloseIcon />
      </IconButton>
      <Video url={youtubeUrl} />
    </Popover>
  );
};
