import React, { useCallback, useState } from 'react';
import CssGreenButton from 'modules/core/components/buttons/roundGreenButton';
import { useStyles } from './styles';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { IReduxState } from 'store';
import { AccountProfile } from 'modules/auth/models/account-profile';
import { hubspotRequest } from 'modules/core/api/hubspot';
import { E_HUBSPOT_KIND, E_HUBSPOT_SOURCE } from 'modules/public/constants/enums';
import { IHubspotFormAttributes } from 'modules/core/graphql/mutation';

interface IContactMethods {
  profile: AccountProfile;
  next: (type: 'phone' | 'email') => void;
  err: (useRedirect: boolean) => void;
  hubspotRequest: (attributes: IHubspotFormAttributes) => Promise<any>;
}

const ContactMethods: React.FC<IContactMethods> = ({ profile, next, err, hubspotRequest: sendHubspotRequest }) => {
  const classes = useStyles();
  const [disabled, setDisabled] = useState(false);

  const handleReq = useCallback(
    async (data: any, type: 'phone' | 'email') => {
      try {
        setDisabled(true);
        await sendHubspotRequest({
          kind: E_HUBSPOT_KIND.Sales as string,
          source: E_HUBSPOT_SOURCE.Checkout as string,
          fullName: data.firstname,
          email: data.email,
          phone: data.phone,
          question: '',
        }).then((res) => {
          setDisabled(false);
          if (res && res.status) {
            next(type);
          }
        });
      } catch (error) {
        err(true);
      }
    },
    [setDisabled, next, err, sendHubspotRequest]
  );

  return (
    <div className={clsx(classes.content, classes.contactElem)}>
      <div className={classes.popupHead}>
        <Typography className={classes.popupTitle} variant="h4">
          50+ users
        </Typography>
        <Typography className={classes.popupDescription}>
          Let our dedicated customer support team get you up and running in no time.
        </Typography>
        <Typography className={classes.popupDescription}>Please select your preferred contact method.</Typography>
      </div>
      <Box className={classes.contactMethods}>
        <CssGreenButton
          disabled={disabled}
          name="buy_contact_email"
          onClick={() =>
            handleReq(
              {
                email: profile.email,
                firstname: `${profile.firstName} ${profile.lastName}`,
                company: profile.companyName,
              },
              'email'
            )
          }
        >
          EMAIL ME
        </CssGreenButton>
        <CssGreenButton
          disabled={disabled}
          name="buy_contact_phone"
          onClick={() =>
            handleReq(
              {
                email: profile.email,
                phone: profile.mobilePhone,
                firstname: `${profile.firstName} ${profile.lastName}`,
                company: profile.companyName,
              },
              'phone'
            )
          }
        >
          CALL ME
        </CssGreenButton>
      </Box>
    </div>
  );
};

export const Contact = connect(
  (state: IReduxState) => ({ profile: state.auth.profile }),
  { hubspotRequest }
)(ContactMethods);
