import { ICardStyle } from 'modules/editor/graphql/gql';
import { FontStyleOption } from 'modules/core/types';

export class CardStyleSection implements ICardStyle {
  public backgroundHexColour?: string;
  public backgroundImageShow?: boolean;
  public backgroundImageUrl?: string;
  public bodyFont?: string;
  public bodyFontHexColour?: string;
  public businessNameHexColour?: string;
  public bodyFontStyle?: FontStyleOption = 'Normal';
  public id!: number;
  public overlayHexColour?: string;
  public overlayShow?: boolean;
  public primaryLinksHexColour?: string;
  public secondaryIconsHexColour?: string;
  public titleFont?: string;
  public titleFontHexColour?: string;
  public taglineHexColour?: string;
  public titleFontStyle?: FontStyleOption = 'Normal';

  constructor(section?: ICardStyle) {
    if (section) {
      this.backgroundHexColour = section.backgroundHexColour;
      this.backgroundImageShow = section.backgroundImageShow;
      this.backgroundImageUrl = section.backgroundImageUrl;
      this.bodyFont = section.bodyFont || 'F37 Ginger, sans-serif';
      this.bodyFontHexColour = section.bodyFontHexColour;
      this.businessNameHexColour = section.businessNameHexColour;
      this.bodyFontStyle = section.bodyFontStyle || 'Normal';
      this.id = section.id;
      this.overlayHexColour = section.overlayHexColour;
      this.overlayShow = section.overlayShow;
      this.primaryLinksHexColour = section.primaryLinksHexColour;
      this.secondaryIconsHexColour = section.secondaryIconsHexColour;
      this.titleFont = section.titleFont || 'F37 Ginger, sans-serif';
      this.titleFontHexColour = section.titleFontHexColour;
      this.taglineHexColour = section.taglineHexColour;
      this.titleFontStyle = section.titleFontStyle || 'Normal';
    }
  }
}
