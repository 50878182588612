import { IAccountsForStatisticRequests } from 'modules/billing/graphql/gql';
import { ID } from 'modules/core/types';
import { UserProfile } from 'modules/auth/models/user-profile';

export class AccountForStatistic implements IAccountsForStatisticRequests {
  public id!: ID;
  public canPublishCard = false;
  public customerId!: string;
  public email = '';
  public firstName = '';
  public lastName = '';
  public mobilePhone = '';
  public user: UserProfile = new UserProfile({});

  constructor(request?: IAccountsForStatisticRequests) {
    if (request) {
      this.id = request.id as ID;
      this.canPublishCard = request.canPublishCard;
      this.user = new UserProfile(request.user);
      this.firstName = request.user.firstName;
      this.lastName = request.user.lastName;
      this.mobilePhone = request.user.mobilePhone;
      this.customerId = request.user!.customerId || '';
      this.email = request.user.email;
    }
  }
}
