import { useEffect, useState } from 'react';
import { SetState } from 'types/helpers';
import { useTypedSelector } from 'hooks/useTypedSelector';

// timeout value
const time = 10000;

/**
 * Stores if user already visited current card
 * @param {SetState<boolean>} setShow Set state action for show state value
 */
export function useStoreVisit(setShow: SetState<boolean>, gdprDialogOpen?: boolean): void {
  const id = useTypedSelector((state) => state.cardVersionReducer.publicVersion.id);
  const [timer, setTimer] = useState<number>();

  useEffect(() => {
    if (id < 0) {
      return;
    }

    if (timer && gdprDialogOpen) {
      localStorage.removeItem(`saveShown_${id}`);
      clearTimeout(timer);
      setTimer(undefined);
      return;
    }

    const isShown = isSaveModalShown(id);

    if (!isShown && !gdprDialogOpen && !timer) {
      const timerId = window.setTimeout(() => {
        if (!isSaveModalShown(id)) {
          setShow(true);
          localStorage.setItem(`saveShown_${id}`, 'shown');
        }
      }, time);

      setTimer(timerId);
    }

    return () => clearTimeout(timer);
  }, [id, setShow, timer, gdprDialogOpen]);
}

export function isSaveModalShown(cardVersionId: number): boolean {
  return localStorage.getItem(`saveShown_${cardVersionId}`) === 'shown';
};

export function setSaveModalAsShown(cardVersionId: number): void {
  localStorage.setItem(`saveShown_${cardVersionId}`, 'shown');
};
