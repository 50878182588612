import gql from 'graphql-tag';
import { apoloClient } from 'modules/core/constants/client';
import {
  IPayoutBatch,
  IResellerNetworkSummary,
  IResellerCommissionSummary,
  IReferrals,
  IReferralAttributes,
  IResellerNetworkAttributes,
  IResellerNetwork,
  IResellerCommissionBreakdown,
  ICommissionBreakdownAttributes,
} from 'modules/dashboard/graphql/gql';
import { PayoutBatch } from 'modules/dashboard/models/payout-batch';
import { ResellerNetworkSummary } from 'modules/dashboard/models/reseller-network-summary';
import { ResellerCommissionSummary } from 'modules/dashboard/models/reseller-commission-summary';
import { ResellerCommissionBreakdown } from 'modules/dashboard/models/reseller-commission-breakdown';
import { ResellerNetwork } from 'modules/dashboard/models/reseller-network';
import { NULL_PAYLOAD } from 'modules/editor/constants/messages';
import { Referrals } from 'modules/dashboard/models/referral';
import { ID } from 'modules/core/types';
import { NodeData } from 'modules/dashboard/components/ResellerGenealogy/interfaces'

export const getCommissionHistory = async (year?: number) => {
  try {
    const filteredYear = year || null;
    const { data } = await apoloClient.query<{ commissionHistory: IPayoutBatch[] }>({
      variables: { year: filteredYear },
      query: gql`
        query commissionHistory($year: Int) {
          commissionHistory(year: $year) {
            id
            currency
            amount
            directCommission
            networkCommission
            paidAtMonth
            paidAtYear
            paidAt
            paymentReference
          }
        }
      `,
    });
    if (data.commissionHistory) {
      return data.commissionHistory.map((pb) => new PayoutBatch(pb));
    } else {
      throw new Error(NULL_PAYLOAD);
    }
  } catch (error) {
    console.warn(error);
    return [];
  }
};

export const getResellerNetworkSummary = async (from: string, to: string) => {
  try {
    const { data } = await apoloClient.query<{ resellerNetworkSummary: IResellerNetworkSummary }>({
      variables: { fromDate: from, toDate: to},
      query: gql`
        query resellerNetworkSummary($fromDate: String!, $toDate: String!) {
          resellerNetworkSummary(attributes: { fromDate: $fromDate, toDate: $toDate }) {
            topEarners {
              id
              email
              mobilePhone
              fullName
              avatarUrl
              commissionAmount
              currency
              introducerCount
            }
            topIntroducers {
              id
              email
              mobilePhone
              fullName
              avatarUrl
              commissionAmount
              currency
              introducerCount
            }
          }
        }
      `,
    });
    if (data.resellerNetworkSummary) {
      return new ResellerNetworkSummary(data.resellerNetworkSummary);
    } else {
      throw new Error(NULL_PAYLOAD);
    }
  } catch (error) {
    console.warn(error);
    return new ResellerNetworkSummary();
  }
};

export const getResellerCommissionSummary = async (from: string, to: string) => {
  try {
    const { data } = await apoloClient.query<{ resellerCommissionSummary: IResellerCommissionSummary }>({
      variables: { fromDate: from, toDate: to},
      query: gql`
        query resellerCommissionSummary($fromDate: String!, $toDate: String!) {
          resellerCommissionSummary(attributes: { fromDate: $fromDate, toDate: $toDate }) {
            currency
            commission
            totalPending
            totalCleared
            totalEarnings
          }
        }
      `,
    });
    if (data.resellerCommissionSummary) {
      return new ResellerCommissionSummary(data.resellerCommissionSummary);
    } else {
      throw new Error(NULL_PAYLOAD);
    }
  } catch (error) {
    console.warn(error);
    return new ResellerCommissionSummary();
  }
};

export const getResellerCommissionBreakdown = async (attributes: ICommissionBreakdownAttributes): Promise<ResellerCommissionBreakdown> => {
  try {
    const { from, to, page, rowsPerPage } = attributes;
    const { data } = await apoloClient.query<{ resellerCommissionBreakdown: IResellerCommissionBreakdown }>({
      variables: { fromDate: from, toDate: to, page: page + 1, rowsPerPage },
      query: gql`
        query resellerCommissionBreakdown(
          $fromDate: String!
          $toDate: String!
          $page: Int!
          $rowsPerPage: Int
        ) {
          resellerCommissionBreakdown(
            attributes: {
              baseAttributes: { fromDate: $fromDate, toDate: $toDate }
              page: $page
              rowsPerPage: $rowsPerPage
            }
          ) {
            resellerPayouts {
              amount
              account {
                id
                fullName
                email
                mobilePhone
                avatarUrl
              }
              currency
              introducerName
              introducerLevel
              purchasedAt
              status
              type
            }
            totalPayoutCount
            pages
            page
            countForPage
          }
        }
      `,
    });
    if (data.resellerCommissionBreakdown) {
      return new ResellerCommissionBreakdown(data.resellerCommissionBreakdown);
    } else {
      throw new Error(NULL_PAYLOAD);
    }
  } catch (error) {
    console.warn(error);
    return new ResellerCommissionBreakdown();
  }
};

export const getResellerReferrals = async (attributes: IReferralAttributes): Promise<Referrals> => {
  const { from, to, page, rowsPerPage, sortBy, withStatus, withType } = attributes;
  const variables = { fromDate: from, toDate: to, page: page + 1, rowsPerPage, sortBy, withStatus, withType };
  try {
    const { data } = await apoloClient.query<{ resellerReferrals: IReferrals }>({
      variables: variables,
      query: gql`
        query resellerReferrals(
          $fromDate: String!
          $toDate: String!
          $page: Int!
          $rowsPerPage: Int
          $sortBy: SortByEnum
          $withStatus: ReferralStatusByEnum
          $withType: ReferralTypeByEnum
        ) {
          resellerReferrals(
            attributes: {
              baseAttributes: {
                fromDate: $fromDate,
                toDate: $toDate,
                page: $page,
                rowsPerPage: $rowsPerPage,
                sortBy: $sortBy
              }
              withStatus: $withStatus
              withType: $withType
            }
          ) {
            referrals {
              id
              fullName
              email
              mobilePhone
              status
              outreachType
              type
              sentAt
            }
            totalReferralCount
            pages
            page
            countForPage
          }
        }
      `,
    });

    if (data.resellerReferrals) {
      return new Referrals(data.resellerReferrals);
    } else {
      throw new Error(NULL_PAYLOAD);
    }
  } catch (error) {
    console.warn(error);
    return new Referrals();
  }
};

export const getResellerNetworkTreeLevel = async (resellerId: ID): Promise<NodeData[]> => {
  try {
    const { data } = await apoloClient.query<{ resellerNetworkTreeLevel: { resellers?: NodeData[] } }>({
      variables: { resellerId },
      query: gql`
        query resellerNetworkTreeLevel($resellerId: ID!) {
          resellerNetworkTreeLevel(resellerId: $resellerId) {
            resellers {
              id
              fullName
              email
              mobilePhone
              avatarUrl
              currency
              commissionAmount
              introducerCount
              expandable
              subscribed
            }
          }
        }
      `,
    });

    if (data.resellerNetworkTreeLevel.resellers) {
      // return data.resellerNetworkTreeLevel.resellers.map((node) => new AccountNetworkNode(node));
      return data.resellerNetworkTreeLevel.resellers;
    } else {
      throw new Error(NULL_PAYLOAD);
    }
  } catch (error) {
    console.warn(error);
    return [];
  }
};

export const getProfileAsNetworkNode = async (): Promise<NodeData> => {
  try {
    const { data } = await apoloClient.query<{ accountAsNetworkNode: NodeData }>({
      query: gql`
        query accountAsNetworkNode {
          accountAsNetworkNode {
            id
            fullName
            email
            mobilePhone
            avatarUrl
            currency
            commissionAmount
            introducerCount
            expandable
            subscribed
          }
        }
      `,
    });

    if (data.accountAsNetworkNode) {
      return data.accountAsNetworkNode;
    } else {
      throw new Error(NULL_PAYLOAD);
    }
  } catch (error) {
    console.warn(error);
    throw new Error(NULL_PAYLOAD);
  }
};

export const getResellerNetwork = async (attributes: IResellerNetworkAttributes): Promise<ResellerNetwork> => {
  const { page, rowsPerPage, searchTerm, level } = attributes;
  const variables = { page: page + 1, rowsPerPage, searchTerm, level };

  if (typeof level == 'string') {
    variables["level"] = undefined;
  }

  try {
    const { data } = await apoloClient.query<{ resellerNetwork: IResellerNetwork }>({
      variables: variables,
      query: gql`
        query resellerNetwork(
          $page: Int!
          $rowsPerPage: Int!
          $searchTerm: String
          $level: Int
        ) {
          resellerNetwork(
            attributes: {
              page: $page,
              rowsPerPage: $rowsPerPage,
              searchTerm: $searchTerm,
              level: $level,
            }
          ) {
            resellers {
              id
              fullName
              email
              mobilePhone
              avatarUrl
              currency
              commissionAmount
              introducerCount
              subscribed
            }
            memberCount
            levels
            totalResellerCount
            pages
            page
            countForPage
          }
        }
      `,
    });

    if (data.resellerNetwork) {
      return new ResellerNetwork(data.resellerNetwork);
    } else {
      throw new Error(NULL_PAYLOAD);
    }
  } catch (error) {
    console.warn(error);
    return new ResellerNetwork();
  }
};

