import { IAccountNetworkNode } from 'modules/dashboard/graphql/gql';

export class AccountNetworkNode implements IAccountNetworkNode {
  public id!: string;
  public fullName!: string;
  public email: string = '';
  public mobilePhone: string = '';
  public avatarUrl: string = '';
  public currency: string = '';
  public commissionAmount: number = 0;
  public introducerCount: number = 0;
  public expandable: boolean = false;
  public subscribed: boolean = false;

  constructor(data?: IAccountNetworkNode) {
    if (data) {
      this.id = data.id as string;
      this.fullName = data.fullName;
      this.email = data.email || '';
      this.mobilePhone = data.mobilePhone || '';
      this.avatarUrl = data.avatarUrl || '';
      this.currency = data.currency || '';
      this.commissionAmount = data.commissionAmount || 0;
      this.introducerCount = data.introducerCount || 0;
      this.expandable = data.expandable || false;
      this.subscribed = data.subscribed || false;
    }
  }
}
