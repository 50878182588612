import * as pallet from 'themes/colors';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/styles';

export const textStyle = (theme: Theme): CSSProperties => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: theme.typography.fontWeightRegular,
  letterSpacing: 0.1,
  opacity: 0.8,
  textTransform: 'uppercase',
});

export const headingStyle = (theme: Theme): CSSProperties => ({
  ...textStyle(theme),
  marginBottom: 12,
});

const icon = (theme: Theme): CSSProperties => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 36,
  height: 30,
  marginRight: 9,
  marginLeft: 4,
  borderRadius: '100%',
  border: '1px solid #7C7F83',
  overflow: 'hidden',
  zIndex: 1,
});

export const iconWrap2 = (theme: Theme): CSSProperties => ({
  ...icon(theme),
  '& div': {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    backgroundColor: 'white',
  },
  '& .MuiSvgIcon-root': {
    width: '1em',
    height: '.7em',
    fill: '#3B4046',
    zIndex: 3,
  },
});

export const iconWrap3Style = (theme: Theme): CSSProperties => ({
  ...icon(theme),
  '& .MuiSvgIcon-root': {
    width: '2.1em',
    height: '1.2em',
  },
});

export const fieldBlockStyle: CSSProperties = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  marginBottom: 40,
  '& .MuiFormControl-root': {
    '&:first-of-type': {
      // marginBottom: 32,
    },
    '&._mb32': {
      marginBottom: 32,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
};

export const inputWrapperStyle: CSSProperties = {
  marginBottom: 32,
  '& .MuiFilledInput-input': {
    padding: '18px 10px 18px 12px',
  },
  '& .MuiFilledInput-inputAdornedStart': {
    paddingLeft: 0,
  },
  '& input': {
    '&::placeholder': {
      color: 'white',
      opacity: 1,
    },
  },
};

const inputWrapperSocial: CSSProperties = {
  ...inputWrapperStyle,
  marginBottom: 27,
};

const uppercase = (theme: Theme): CSSProperties => ({ textTransform: 'uppercase' });
// const pointer = (theme: Theme): CSSProperties => ({ cursor: 'pointer' });
export const extendedIconStyle: CSSProperties = { color: pallet.limeade };

export const fabStyle: CSSProperties = {
  background: '#FFF',
  width: 25,
  height: 25,
  minHeight: 'unset',
  display: 'flex',
  marginRight: '10px',
  overflow: 'hidden',
  '& .MuiTouchRipple-root': {
    width: 25,
    height: 25,
    overflow: 'hidden',
  },
  '& .MuiSvgIcon-root': {
    width: '.8em',
    height: '.8em',
  },
};

export const sectionHeaderStyle = (theme: Theme): CSSProperties => ({
  fontSize: theme.typography.pxToRem(20),
  lineHeight: theme.typography.pxToRem(29),
  letterSpacing: 0.67,
  fontWeight: 300,
  fontFamily: 'F37 Ginger Light',
  [theme.breakpoints.between('xs', 'sm')]: {
    '&:not(._displaySm)': {
      display: 'none',
    },
  },
});

const item = (theme: Theme): CSSProperties => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(5),
  '&:last-of-type': {
    marginBottom: 0,
  },
});

export const clearBlockStyle = (theme: Theme): CSSProperties => ({
  [theme.breakpoints.between('xs', 'sm')]: {
    margin: 0,
  },
  [theme.breakpoints.up('sm')]: {
    margin: '0 0 0 auto',
  },
});

export const addingBlock = {
  display: 'flex',
  alignItems: 'center',
  marginTop: 5,
  '& > *': {
    marginBottom: 0,
  },
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: headingStyle(theme),
    sectionHeader: sectionHeaderStyle(theme),
    inpWrapper: inputWrapperStyle,
    inputWrapperSocial,
    inputWrapper0: {
      ...inputWrapperStyle,
      marginBottom: 0,
    },
    phone: {
      '& label': {
        fontSize: 14,
        fontFamily: 'F37 Ginger Light',
        fontWeight: 300,
        lineHeight: theme.typography.pxToRem(24),
      },
    },
    item: item(theme),
    icon: icon(theme),
    icon2: iconWrap2(theme),
    icon3: iconWrap3Style(theme),
    clipboardWrapper: {
      marginTop: theme.spacing(2),
    },
    clipboard: {
      margin: '0 .5rem',
    },
    disabledIcon: {
      backgroundColor: '#9fa2a5 !important',
    },
    extendedIcon: extendedIconStyle,
    fab: fabStyle,
    fieldBlock: fieldBlockStyle,
    fieldBlockSm: {
      ...fieldBlockStyle,
      marginBottom: 27,
    },
    uppercase: uppercase(theme),
    checkbox: {
      '& .MuiFormControlLabel-label': {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(17),
        letterSpacing: 0.09,
        opacity: 0.8,
      },
      '&._pt': {
        [theme.breakpoints.up('md')]: {
          paddingTop: 5,
        },
      },
    },
    box: {
      [theme.breakpoints.up('md')]: {
        marginBottom: 20,
      },
    },
    clearBlock: clearBlockStyle(theme),
    zipCode: {
      display: 'flex',
      flexDirection: 'column',
      width: '200px',
      marginBottom: 53,
    },
    days: {
      display: 'flex',
      position: 'relative',
    },
    line: {
      background: 'rgba(255,255,255,.6)',
      height: '25px',
      width: '1px',
      position: 'absolute',
      left: '50%',
      top: '25%',
    },
    clear: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '12.8px',
      height: '12.8px',
      color: pallet.limeade,
      fontSize: '.8rem',
    },
    clearWrap: {
      display: 'flex',
      width: '24px',
      height: '24px',
      justifyContent: 'center',
      alignItems: 'center',
      border: `1px solid #888b8f`,
      background: '#252b31',
      borderRadius: '50%',
      cursor: 'pointer',
      transition: 'background .25s ease, border-color .25s ease',
      '&:hover': {
        borderColor: `#6b6b6b`,
        background: '#6b6b6b',
        '&:active': {
          borderColor: `#000000`,
          background: '#000000',
        },
      },
    },
  })
);
