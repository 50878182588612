import { useMemo } from 'react';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { E_ACCOUNT_TYPE } from 'modules/auth/constants/enums';

export function useNeedBuy(): boolean {
  const profile = useTypedSelector((state) => state.auth.profile);
  const { type, canPublishCard } = profile;
  return useMemo<boolean>(() => {
    return type === E_ACCOUNT_TYPE.Owner || type === E_ACCOUNT_TYPE.Reseller ? !canPublishCard : false
  }, [type, canPublishCard]);
}
