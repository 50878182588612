import { ICardStatistics } from 'modules/dashboard/graphql/gql';

export class CardStatistic implements ICardStatistics {
  public countCardsShared = 0;
  public countCardsViewed = 0;
  public countContactRequests = 0;
  public returnVisits = 0;

  constructor(statistcs?: ICardStatistics) {
    if (statistcs) {
      this.countCardsShared = statistcs.countCardsShared;
      this.countCardsViewed = statistcs.countCardsViewed;
      this.countContactRequests = statistcs.countContactRequests;
      this.returnVisits = statistcs.returnVisits;
    }
  }
}
