import gql from 'graphql-tag';

export const DOMAIN = 'gpdigitalcards.com/';
export const URL_REGEXP = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}(:[0-9]{1,5})?(\/.*)?$/;
export const PROTOCOL_REGEXP = /http:\/\/|https:\/\//;

export const MAX_FILESIZE_BYTES = 5_000_000; // 5mb

export const COMPANY_SETTINGS_QUERY_TEXT = gql`
  query {
    companySetting {
      id
      companyId
      canEditAboutSection
      canEditOfferSection
      canEditVideoSection
      canEditGallerySection
      canEditLocationSection
      canEditPromotionSection
    }
  }
`;
