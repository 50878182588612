import gql from 'graphql-tag';
import { ISubscription, IAccountsForStatisticRequests } from 'modules/billing/graphql/gql';
import { Subscription } from 'modules/billing/models/subscription';
import { apoloClient } from 'modules/core/constants/client';
import { ID } from 'modules/core/types';
import { ICardStatistics, IContactRequests, IDailyVisit } from 'modules/dashboard/graphql/gql';
import { CardStatistic } from 'modules/dashboard/models/card-statistic';
import { ContactRequests } from 'modules/dashboard/models/contact-request';
import { DailyVisit } from 'modules/dashboard/models/daily-visit';
import { AccountForStatistic } from 'modules/dashboard/models/account-for-statistic';
import { NULL_PAYLOAD } from 'modules/editor/constants/messages';

export const getCardStatistics = async (from: string, to: string, accountId: ID) => {
  try {
    const { data } = await apoloClient.query<{ cardStatistics: ICardStatistics }>({
      variables: { fromDate: from, toDate: to, accountId },
      query: gql`
        query cardStatistics($fromDate: String!, $toDate: String!, $accountId: ID) {
          cardStatistics(attributes: { fromDate: $fromDate, toDate: $toDate, accountId: $accountId }) {
            countCardsShared
            countCardsViewed
            countContactRequests
            returnVisits
          }
        }
      `,
    });
    return new CardStatistic(data.cardStatistics);
  } catch (error) {
    console.warn(error);
    return new CardStatistic();
  }
};

export const getDailyVisits = async (from: string, to: string, accountId: ID): Promise<DailyVisit[]> => {
  try {
    const { data } = await apoloClient.query<{ dailyVisits: IDailyVisit[] }>({
      variables: { fromDate: from, toDate: to, accountId },
      query: gql`
        query dailyVisits($fromDate: String!, $toDate: String!, $accountId: ID) {
          dailyVisits(attributes: { fromDate: $fromDate, toDate: $toDate, accountId: $accountId }) {
            countViewed
            date
          }
        }
      `,
    });
    if (data.dailyVisits) {
      return data.dailyVisits.map((dv) => new DailyVisit(dv));
    } else {
      throw new Error(NULL_PAYLOAD);
    }
  } catch (error) {
    console.warn(error);
    return [];
  }
};

export const getAccountsForStatistics = async (): Promise<AccountForStatistic[]> => {
  try {
    const { data } = await apoloClient.query<{ accountsForStatistics: IAccountsForStatisticRequests[] }>({
      query: gql`
        query {
          accountsForStatistics {
            id
            canPublishCard
            user {
              id
              customerId
              email
              firstName
              lastName
              mobilePhone
            }
          }
        }
      `,
    });
    if (data.accountsForStatistics) {
      return data.accountsForStatistics.map((us) => new AccountForStatistic(us));
    } else {
      throw new Error(NULL_PAYLOAD);
    }
  } catch (error) {
    console.warn(error);
    return [];
  }
};

export const getMyStatistics = async (): Promise<AccountForStatistic> => {
  try {
    const { data } = await apoloClient.query<{ account: IAccountsForStatisticRequests }>({
      query: gql`
        query {
          account {
            id
            canPublishCard
            user {
              id
              customerId
              email
              firstName
              lastName
              mobilePhone
            }
          }
        }
      `,
    });
    return new AccountForStatistic(data.account);
  } catch (error) {
    console.warn(error);
    return new AccountForStatistic();
  }
};

export const getUserSubscriptionStatistics = async (): Promise<Subscription[]> => {
  try {
    const { data } = await apoloClient.query<{ userSubscriptions: ISubscription[] }>({
      query: gql`
        query {
          userSubscriptions {
            amount
            autoRenew
            currency
            currentPeriodEnd
            currentPeriodStart
            description
            id
            invoiceId
            invoiceNumber
            hostedInvoiceUrl
            slotNumber
            status
            subscriptionId
            user {
              customerId
              email
              firstName
              id
              lastName
              mobilePhone
            }
            account {
              id
              subscribed
              type
              accountName
              accountDesc
            }
          }
        }
      `,
    });
    if (data) {
      return data.userSubscriptions.map((s) => new Subscription(s));
    } else {
      throw new Error(NULL_PAYLOAD);
    }
  } catch (error) {
    console.warn(error);
    return [];
  }
};

export const getContactRequests = async (
  from: string,
  to: string,
  sort: string,
  accountId: ID,
  page: number = 0,
  rowsPerPage: number = 10
): Promise<ContactRequests> => {
  try {
    const { data } = await apoloClient.query<{ contactRequests: IContactRequests }>({
      variables: { fromDate: from, toDate: to, sortBy: sort, accountId, page: page + 1, rowsPerPage },
      query: gql`
        query contactRequests(
          $fromDate: String!
          $toDate: String!
          $sortBy: SortByEnum
          $accountId: ID
          $page: Int!
          $rowsPerPage: Int
        ) {
          contactRequests(
            attributes: {
              baseAttributes: { fromDate: $fromDate, toDate: $toDate, accountId: $accountId }
              sortBy: $sortBy
              page: $page
              rowsPerPage: $rowsPerPage
            }
          ) {
            contactRequests {
              id
              email
              lastViewed
              mobilePhone
              name
              preference
              status
              referrer
              creationType
            }
            countContactRequests
            countForPage
            page
            pages
          }
        }
      `,
    });
    if (data.contactRequests) {
      return new ContactRequests(data.contactRequests);
    } else {
      throw new Error(NULL_PAYLOAD);
    }
  } catch (error) {
    console.warn(error);
    return new ContactRequests();
  }
};
