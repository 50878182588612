import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';
import { useStyles } from './styles';

const WarnContent: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="100%">
      <WarningIcon color="primary" className={classes.sharedIcon} />
      <Typography variant="h5" className={classes.title}>
        Warning!
      </Typography>
      <Typography variant="subtitle1" align="center" display="block">
        {children}
      </Typography>
    </Box>
  );
};

export default WarnContent;
