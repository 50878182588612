import React, { createRef, useEffect } from 'react';
import { Typography, Hidden } from '@material-ui/core';
import DialogWrapper from 'modules/core/containers/DialogWrapper';
import CssGreenButton from 'modules/core/components/buttons/roundGreenButton';
import { PublishWarningDialogStyles as useStyles } from './styles';
import { DOMAIN } from 'modules/core/constants';

interface IProps {
  show: boolean;
  cardUrl?: string;
  onClose: () => void;
  onClickPublishCta: () => void;
  onClickChangeUrlCta: () => void;
}

const PublishWarningDialog: React.FC<IProps> = ({ show, cardUrl, onClose, onClickPublishCta, onClickChangeUrlCta }) => {
  const ref = createRef<any>();
  const classes = useStyles();

  const actions = [
    <CssGreenButton key="change" id="publish_warning_dialog_change_url" onClick={() => onClickChangeUrlCta()}>
      <Hidden mdUp>Change It</Hidden>
      <Hidden smDown>I’d Like To Change It</Hidden>
    </CssGreenButton>,
    <CssGreenButton key="happy" id="publish_warning_dialog_confirm" onClick={() => onClickPublishCta()}>
      I’m Happy
    </CssGreenButton>,
  ];

  useEffect(() => {
    if (show) {
      ref.current.show();
    } else {
      ref.current.hide();
    }
  }, [show, ref]);

  return (
    <DialogWrapper
      dialogId="publish_warning_dialog"
      ref={ref}
      titleText={`One last check - are you happy with your card url?`}
      actions={actions}
      onClose={onClose}
      actionsClasses={{ root: classes.actionsRoot }}
    >
      <div className={classes.text}>
        <Typography variant="h6" className={classes.heading6}>
          At the moment it’s <span id="publish_warning_dialog_domain">{DOMAIN}</span>
          <span id="publish_warning_dialog_card_url">{cardUrl ? cardUrl : ''}</span>
        </Typography>
        <Typography variant="body1">
          Once you’ve published your first card, you can’t change your url – so make sure you’re completely happy before
          you go ahead.
        </Typography>
        <Typography variant="body1">
          Your card url can not be changed in the future. If you are not happy with it, please change it in the styling
          section before publishing your first card.
        </Typography>
      </div>
    </DialogWrapper>
  );
};

export default PublishWarningDialog;
