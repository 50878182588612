import { IPromotionSection } from 'modules/editor/graphql/gql';

export class PromotionSection implements IPromotionSection {
  public linkText?: string;
  public linkUrl?: string;
  public linkUrlShow?: boolean;
  public promotionImageUrl?: string;
  public title?: string;
  public showSectionHeader?: boolean;

  constructor(section?: IPromotionSection) {
    if (section) {
      this.linkText = section.linkText;
      this.linkUrl = section.linkUrl;
      this.linkUrlShow = section.linkUrlShow;
      this.promotionImageUrl = section.promotionImageUrl;
      this.title = section.title;
      this.showSectionHeader = section.showSectionHeader;
    }
  }
}
