import { IVideoAtributes } from 'modules/editor/graphql/mutations';
import { ICardVersion } from 'modules/editor/graphql/gql';
import { IModel } from 'modules/core/interfaces/model';
import { clearObject } from 'modules/core/utils';
import { ObjectSchema, object, string } from 'yup';
import { URL_INVALID } from 'modules/editor/constants/messages';
import { URL_REGEXP } from 'modules/core/constants';

export class VideoAtributes implements IModel<IVideoAtributes> {
  public cardVersionId!: number;
  public linkUrl?: string = '';
  public title?: string = '';
  public showSectionHeader?: boolean = true;
  [props: string]: any;

  private schema: ObjectSchema<Partial<IVideoAtributes>> = object().shape({
    linkUrl: string().matches(URL_REGEXP, { excludeEmptyString: true, message: URL_INVALID }),
  });

  constructor(card?: ICardVersion) {
    if (card) {
      this.cardVersionId = card.id;
      this.linkUrl = card.videoSection.linkUrl || '';
      this.title = card.videoSection.title || '';
      // ensure that default value for showSectionHeader is true. Necessary for when the section does not yet exist in db.
      this.showSectionHeader = card.videoSection.showSectionHeader === false ? false : true;
    }
  }

  public asState(): IVideoAtributes {
    return {
      cardVersionId: this.cardVersionId,
      linkUrl: this.linkUrl,
      title: this.title,
      showSectionHeader: this.showSectionHeader,
    };
  }

  public nextState(state: VideoAtributes) {
    const atr = new VideoAtributes();
    atr.cardVersionId = state.cardVersionId;
    atr.linkUrl = state.linkUrl;
    atr.title = state.title;
    atr.showSectionHeader = state.showSectionHeader;
    return atr;
  }

  public clear(nullable: boolean) {
    const atr = new VideoAtributes();
    clearObject(atr, nullable);
    atr.cardVersionId = this.cardVersionId;
    return atr;
  }

  public toJSON(): IVideoAtributes {
    return {
      linkUrl: this.linkUrl ? this.linkUrl : null,
      title: this.title ? this.title : null,
      showSectionHeader: this.showSectionHeader,
      cardVersionId: this.cardVersionId,
    };
  }

  public validate(path: string) {
    return this.schema.validateSyncAt(path, this.asState());
  }
}
