import { ID } from 'modules/core/types';
import { IUser, IAccount } from 'modules/auth/interfaces/auth';
import { Account } from 'modules/auth/models/account';
import moment, { Moment } from 'moment';
import { E_ACCOUNT_TYPE } from 'modules/auth/constants/enums';

export class UserProfile implements IUser {
  public id: ID = 0;
  public firstName: string = '';
  public lastName: string = '';
  public email: string = '';
  public mobilePhone: string = '';
  public customerId?: string = '';
  public countryCode: string = '';
  public eligibleForDiscountUntil?: string | Moment;
  public currentAccount?: Account;
  public accounts?: Account[];
  public hasBeenSubscribed: boolean = false;

  // public multipleSubscription: boolean = false;

  constructor(data?: Partial<IUser>) {
    if (data) {
      this.id = data.id || 0;
      this.firstName = data.firstName || '';
      this.lastName = data.lastName || '';
      this.email = data.email || '';
      this.mobilePhone = data.mobilePhone || '';
      this.customerId = data.customerId as string;
      this.countryCode = data.countryCode || '';
      // this.multipleSubscription = !!data.multipleSubscription;
      this.eligibleForDiscountUntil = data.eligibleForDiscountUntil ? moment(data.eligibleForDiscountUntil, 'YYYY-MM-DD') : undefined;
      this.currentAccount = new Account(data.currentAccount);
      this.hasBeenSubscribed = data.hasBeenSubscribed || false;

      const { accounts } = data;
      if (Array.isArray(accounts)) {
        this.accounts = accounts.map((item) => new Account(item));
      }
    }
  }

  public getUserName(): string {
    let res = '';
    const { firstName, lastName, email } = this;

    if (firstName || lastName) {
      res = [firstName, lastName].filter((item) => Boolean(item)).join(' ');
    } else if (email) {
      res = email;
    }

    return res;
  }

  public resellerAccount(): IAccount | undefined {
    if (this.accounts) {
      return this.accounts.find((account) => account.type === E_ACCOUNT_TYPE.Reseller );
    }
  }
}
