import React, { useState, useCallback } from 'react';
import { Button, MenuItem, IconButton, Typography, Divider, Box } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Menu from 'modules/core/components/DropDown/Menu';
import { useStyles } from './styles';
import { IReduxState } from 'store';
import { connect } from 'react-redux';
import { IAccount, IAccountProfile } from 'modules/auth/interfaces/auth';
import clsx from 'clsx';
import { reloadProfile, switchAccount, createAccount } from 'modules/auth/thunks/auth';
import { Notification } from 'modules/core/components/Notification';
import { ReactComponent as Plus } from 'assets/svg/whitePlus.svg';
import { SvgIcon } from '@material-ui/core';
import { OverlayProgress } from 'modules/core/components/progress/OverlayProgress';
import { DASHBOARD_ROUTE } from 'routes/routes';

interface IAccountSwitcherMenu {
  profile: IAccountProfile;
  getAccounts: () => Promise<void>;
  switchAccount: (accountId: number, currentAccount?: IAccount) => Promise<void>;
  createAccount: (currentAccount?: IAccount) => Promise<void>;
}

const AccountSwitcherMenu: React.FC<IAccountSwitcherMenu> = ({
  getAccounts,
  switchAccount,
  createAccount,
  profile,
}) => {
  const accounts = profile.user.accounts;
  const currentAccount = profile.user.currentAccount;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const menuAnchor = React.createRef<any>();
  const [errors, setErrors] = useState<string[]>([]);
  const [showError, setShowError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const currentAccountName = profile.accountName;
  const dashboardUrl = window.location.origin + DASHBOARD_ROUTE.path

  function onCloseErrorNotification(): void {
    setShowError(false);
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    getAccounts();
    setAnchorEl(menuAnchor.current!.parentElement);
  };

  function handleCreateAccount() {
    setLoading(true)
    createAccount(currentAccount)
      .then(() => {window.location.href = dashboardUrl})
      .catch((errors) => {
        setErrors(errors);
        setLoading(false)
        setShowError(true);
      }
    )
  }

  const handleClose = useCallback(
    (selectable: boolean, account?: Partial<IAccount>) => {
      setAnchorEl(null);
      if (account && selectable) {
        setLoading(true)
        switchAccount(account.id as number, currentAccount)
          .then(() => {window.location.href = dashboardUrl})
          .catch((errors) => {
            setErrors(errors);
            setLoading(false)
            setShowError(true);
          }
        )
      }
    }, [switchAccount, dashboardUrl, currentAccount]
  );

  const DropdownItem: React.FC<{ account: Partial<IAccount>; selectable: boolean }> = ({ account, selectable }) => {
    const exClass = selectable ? '_selectable' : '_unselectable'

    return (
      <MenuItem className={classes.itemContainer} onClick={() => handleClose(selectable, account)}>
        <Box className={clsx(classes.item, exClass) }>
          <Typography className={classes.itemLine1}>{account.accountName}</Typography>
          <Typography className={classes.itemLine2}>{account.accountDesc}</Typography>
        </Box>
      </MenuItem>
    );
  };

  return (
    <div className={classes.root} ref={menuAnchor}>
      <Button onClick={handleMenu} className={classes.titleButton}>
        Select account: {currentAccountName}
      </Button>
      <div>
        <IconButton
          aria-label="current user accounts"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <KeyboardArrowDownIcon />
        </IconButton>
        <Menu
          className={classes.dropDown}
          classes={{ paper: classes.paper }}
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={open}
          onClose={() => handleClose(false)}
        >
          <div className={classes.head} onClick={() => handleClose(false)}>
            <div>
              <span>Select account</span>
              <IconButton
                aria-label="current user accounts"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => handleClose(false)}
                className={classes.buttonInner}
                color="inherit"
              >
                <KeyboardArrowDownIcon />
              </IconButton>
            </div>
          </div>
          <div className={classes.versions}>
            {accounts && accounts.map((a) => (
              <DropdownItem key={a.id} account={a} selectable={!!currentAccount && currentAccount.id !== a.id} />
            ))}
          </div>
          <Divider className={classes.hr} />
          <Button onClick={handleCreateAccount} className={classes.addCard}>
            <SvgIcon children={<Plus />} htmlColor={'#999999'} className={classes.addCardBtn} />
            <Typography className={classes.addCardText}>Create new card</Typography>
          </Button>
        </Menu>
      </div>
      <Notification
        id="create-account-error-message"
        onClose={onCloseErrorNotification}
        severity="error"
        text={errors[0]}
        isOpen={showError}
      />
      <OverlayProgress isProgress={loading} />
    </div>
  );
};

export default connect(
  (state: IReduxState) => ({
    profile: state.auth.profile,
  }),
  { getAccounts: reloadProfile, switchAccount, createAccount }
)(AccountSwitcherMenu);
