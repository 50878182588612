import { Box, Hidden } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { PreviewToolbar } from './PreviewSwitch';
import { PublishButton, MenuButton } from 'modules/core/components/buttons/PublishButton';
import { useStyles } from './styles';
import { BUY_ROUTE, RESELLER_INITIAL_PURCHASE_ROUTE_PATH } from 'routes/routes';
import { If, Then, Else } from 'react-if';
import { useNeedBuy } from 'hooks/useNeedBuy';
import CardVersionDropDown from 'modules/core/components/DropDown';
import { useMenuData } from 'modules/core/hooks/useMenuData';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { E_ACCOUNT_TYPE } from 'modules/auth/constants/enums';

export const CardToolbar: React.FC = () => {
  const classes = useStyles();
  const { menuItems } = useMenuData();
  const needBuy = useNeedBuy();
  const { path = '', title = '' } = menuItems.find((item) => item.isMatch) || {};
  const profile = useTypedSelector((state) => state.auth.profile);
  const isReseller = profile.type === E_ACCOUNT_TYPE.Reseller ;

  const buyPath = profile.type === E_ACCOUNT_TYPE.Reseller && !profile.hasBeenSubscribed
    ? RESELLER_INITIAL_PURCHASE_ROUTE_PATH
    : BUY_ROUTE.path;

  return (
    <>
      <Box display="flex" justifyContent="space-between" className={classes.root}>
        <Box>
          <Typography variant="h4" className={classes.title}>
            {path === '/editor' ? <CardVersionDropDown /> : title}
          </Typography>
        </Box>
        <Box display="flex">
          <Hidden mdDown>
            {!isReseller && <PreviewToolbar />}
          </Hidden>
          <Hidden mdDown>
            <If condition={needBuy}>
              <Then>
                <MenuButton id="buy_now_button_desktop" href={buyPath}>
                  Buy now
                </MenuButton>
              </Then>
              <Else>
                <PublishButton />
              </Else>
            </If>
          </Hidden>
        </Box>
      </Box>
    </>
  );
};
