import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { lightGreen, white, black } from 'themes/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cookieConsentWrap: {
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'space-between',
      color: white,
      backgroundColor: black,
      opacity: 0.8,
      position: 'fixed',
      width: '100%',
      fontSize: 12,
      bottom: 0,
      zIndex: 100,

      '& p': {
        padding: 10,
        paddingLeft: 40,
      },
      '& button': {
        maxWidth: 210,
      },

      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
        flexDirection: 'column',
        '& button': {
          maxWidth: '100%',
        },
        '& p': {
          padding: 20,
          paddingLeft: 40,
          paddingRight: 40,
          textAlign: 'justify',
        },
      },
    },
    greenLink: {
      color: lightGreen,
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    button: {
      borderRadius: 0,
      fontSize: 12,
      lineHeight: 1,
    },
  })
);
