import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrap: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 34,
      height: 29,
      borderRadius: '100%',
      border: '1px solid #7C7F83',
      overflow: 'hidden',
    },
  })
);
