import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { errorRed, gray8, gray4, lightGreen } from 'themes/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      position: 'relative',
    },
    dialogContainer: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    datePickerContainer: {
      display: 'flex',
      justifyContent: "space-between",
    },
    errorMessage: {
      color: errorRed,
      marginTop: 10,
      marginBottom: -10,
    },
    dialogContent: {
      overflowY: 'hidden',
    },
    dialogPaper: {
      maxWidth: 450,
    },
    dialogTitle: {
      '& *': {
        padding: '5px 10px 0',
      },
    },
    datePicker: {
      width: "100%",
      marginTop: 10,

      '& input, label': {
        cursor: 'pointer',
      },
    },
    datePickerSeparator: {
      alignSelf: "flex-end",
      minWidth: "40px",
      textAlign: "center",
    },

    [theme.breakpoints.down(480)]: {
      datePickerContainer: {
        flexWrap: 'wrap',
      },
      datePickerSeparator: {
        display: "none",
      },
      datePicker: {
        '&:not(:first-child)': {
          marginTop: 20,
        },
      },
    },

    formControl: {
      // minWidth: 270,
      width: '100%',
      margin: theme.spacing(0, 0, 1.6, 0),

      '& .MuiInputBase-root': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
      '& .MuiFilledInput-underline:before': {
        borderBottomColor: 'rgba(255,255,255,0.4)',
      },
      '&._green': {
        '& .MuiFilledInput-underline:before': {
          borderBottom: '1px solid #749D83',
        },
        '& .MuiFilledInput-underline:after': {
          borderBottomWidth: '2px',
          borderBottomColor: '#013D1F',
        },
        '& svg': {
          fill: '#749D83',
        },
      },
      '&._table': {
        width: '100%',
        marginBottom: 0,
        '& svg': {
          fill: '#76B741',
        },
      },
      '&._space-between': {
        [theme.breakpoints.up('sm')]: {
          '&:not(:first-child):not(:last-child)': {
            margin: theme.spacing(0, 2),
          },
        },
      },
    },
    label: {
      marginLeft: 16,
      marginBottom: 20,
      marginTop: 6,
      fontWeight: 300,
      fontFamily: 'F37 Ginger Light',
      fontSize: 12,
      top: 0,
      left: 0,
      position: 'absolute',

      '&.MuiInputLabel-shrink': {
        color: 'white',
        opacity: 0.6,
        marginBottom: 0,
        marginLeft: 0,
        transform: 'translate(16px, 6px) scale(.75)',
      },
      '&._green': {
        color: '#749D83',
        fontSize: 11,
        letterSpacing: 0.09,
        lineHeight: theme.typography.pxToRem(24),
        fontWeight: 300,
        fontFamily: 'F37 Ginger Light',
        '&.MuiInputLabel-shrink': {
          opacity: 1,
        },
      },
      '&._table': {
        display: 'none',
      },
      '&._editor': {
        fontSize: 14,

        '&.MuiInputLabel-shrink': {
          marginTop: 3,
        }
      },
    },
    labelShrink: {
      opacity: 0.6,
      marginBottom: 0,
      marginLeft: 0,
      transform: 'translate(16px, 6px) scale(.75)',
    },
    textField: {
      backgroundColor: 'rgba(255, 255, 255, 0.09)',
      width: '100%',
    },
    input: {
      padding: '21px 0 3px 16px',
      cursor: 'pointer',

      '&:before, :hover:before': {
        borderBottomColor: 'rgba(255,255,255,0.4) !important',
      },

      '& input': {
        cursor: 'pointer',
      },
    },
    inputIcon: {
      position: 'absolute',
      top: 'calc(50% - 12px)',
      right: 7,
      color: 'white',
      pointerEvents: 'none',
    },
    hr: {
      opacity: 0.1,
      color: gray4,
      margin: '10px 0',
      width: '100%',
    },
    li: {
      backgroundColor: '#424242',
      boxShadow: 'none',
      color: 'white',
      textTransform: 'none',
      padding: '12px 15px',
      margin: '0 -15px',
      cursor: 'pointer',
      justifyContent: 'left',
      transition: () => {
        const trans = ' 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'

        return ['background-color', 'box-shadow', 'border', 'color']
          .map(attr => attr + trans)
          .join(', ');
      },
      borderRadius: 4,

      '&._selected': {
        color: lightGreen,
      },

      '&:hover': {
        backgroundColor: gray8,
        boxShadow: 'none',
      },

      '& label': {
        justifyContent: 'left',
      },

      '&._table': {
        color: '#76B741',
      },
    },
  })
);
