import axios from 'axios';
import { HOST_URL } from 'modules/core/constants/config';

export const checkEmail = async (email: string) => {
  return axios.get(`${HOST_URL}/available_email`, {
    params: {
      email,
    },
    withCredentials: false,
  });
};

export const checkPhone = async (phone: string) => {
  return axios.get(`${HOST_URL}/available_phone`, {
    params: {
      phone,
    },
    withCredentials: false,
  });
};
