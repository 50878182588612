import vCards from 'vcards-js';
import { fullUrl } from 'utils'
import { ICardVersion } from 'modules/editor/graphql/gql';

export function createVCard(cardVer: ICardVersion) {
  if (cardVer.fullName && cardVer.card.urlSlug) {
    const vCard = vCards();
    const nameSplit = cardVer.fullName.split(/ (.*)/);
    const vCardMimeType = "text/vcard,";

    vCard.version = '3.0'
    vCard.firstName = nameSplit[0];
    vCard.lastName = nameSplit[1] || "";
    // vCard.nameSuffix = cardVer.qualifications || "";
    vCard.title = cardVer.jobTitle || "";
    // vCard.cellPhone = cardVer.mobilePhone || "";
    // vCard.workPhone = cardVer.secondPhoneNumber || "";
    // vCard.email = cardVer.email || "";
    vCard.organization = cardVer.businessName || "";
    // vCard.url = fullUrl(cardVer.card.urlSlug);
    vCard.workUrl = fullUrl(cardVer.card.urlSlug);

    if (cardVer.vCardImageBase64 && cardVer.vCardImageContentType) {
      vCard.photo.embedFromString(cardVer.vCardImageBase64, cardVer.vCardImageContentType);
    }

    return "data:" + vCardMimeType + encodeURIComponent(vCard.getFormattedString());
  }
};
