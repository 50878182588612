import { Typography, FormControlLabel, Button, colors } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { CssTextField } from 'modules/core/components/fields/CssTextField';
import { GreenCheckbox } from 'modules/core/components/fields/GreenCheckbox';
import React, { useState, useCallback, MouseEvent, useEffect, useMemo } from 'react';
import { DesktopColorPicker } from './desktop-picker';
import { pickerStyles } from './style';
import { string } from 'yup';

interface IColorPickerProps {
  id?: string;
  value?: string;
  title: string;
  label: string;
  onSelectColor?: (value: string, name: string) => void;
  defaultColor?: string;
  name?: string;
  disabled?: boolean;
}

const { common, ...otherColors } = colors;
const INVALID_MSG = 'Please enter correct HEX color.';
const colorsName = Object.keys(otherColors);

export const ColorPicker: React.FC<IColorPickerProps> = ({
  id,
  value,
  title,
  label,
  onSelectColor,
  name = '',
  disabled,
  defaultColor,
}) => {
  const schema = useMemo(() => string().matches(/^#([0-9a-f]{3}|[0-9a-f]{6}|[0-9a-f]{8})$/i), []);
  const defColor = useMemo(() => (typeof defaultColor === 'string' ? defaultColor.toLowerCase() : defaultColor), [
    defaultColor,
  ]);
  const classes = pickerStyles();
  const [isShowColorPicker, changeShowColorPicker] = useState(false);
  const [values, setValues] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [color, setColor] = useState(value);
  const [inputColor, setInputColor] = useState(value);
  const [timer, setTimer] = useState();

  useEffect(() => {
    const curColor = value ? value.toLowerCase() : value;
    setColor(curColor || defColor);
    setInputColor(curColor || defColor);
  }, [value, defColor]);

  const closeMenuChangeColor = () => {
    changeShowColorPicker(!isShowColorPicker);
    setValues(false);
  };

  const selectColor = useCallback(
    (selectedColor: string) => (evt: MouseEvent) => {
      clearTimeout(timer);
      evt.preventDefault();
      setError(undefined);
      setInputColor(selectedColor);
      setColor(selectedColor);
      if (onSelectColor) {
        const timerId = setTimeout(() => onSelectColor(selectedColor, name as string), 200);
        setTimer(timerId);
      }
      changeShowColorPicker(false);
      setValues(false);
    },
    [name, onSelectColor, timer, setTimer]
  );

  const handleManual = useCallback(
    (e: any) => {
      e.persist();
      setError(undefined);
      let colorValue: string = e.target.value.toLowerCase();
      if (!/^#/.test(colorValue) && colorValue.length) {
        colorValue = `#${colorValue}`;
      }
      if (colorValue.length > 9) {
        colorValue = colorValue.slice(0, 9);
      }
      setInputColor(colorValue);
      try {
        schema.validateSync(colorValue);
        if (onSelectColor) {
          setColor(colorValue);
          onSelectColor(colorValue, name as string);
        }
      } catch (error) {
        if (!colorValue) {
          setColor(defaultColor);
        } else {
          setError(INVALID_MSG);
        }
      }
    },
    [setError, onSelectColor, schema, defaultColor, name]
  );

  const handleBlur = useCallback(() => {
    if (!inputColor) {
      setInputColor(defaultColor);
      if (onSelectColor) {
        onSelectColor(defaultColor as string, name as string);
      }
    } else {
      if (onSelectColor) {
        onSelectColor(color as string, name as string);
      }
    }
  }, [setInputColor, onSelectColor, inputColor, defaultColor, color, name]);

  const percentToHex = (p: number) => {
    const intValue = Math.round((p / 100) * 255); // map percent to nearest integer (0 - 255)
    const hexValue = intValue.toString(16); // get hexadecimal representation
    return hexValue.padStart(2, '0').toUpperCase(); // format with leading 0 and upper case characters
  };

  const completePick = useCallback(
    (hex: string, rgbColor: any) => {
      const hexTransparency = percentToHex(rgbColor.a * 100);
      setError(undefined);
      setInputColor(hex + hexTransparency);
      setColor(hex + hexTransparency);
      if (onSelectColor) {
        onSelectColor(hex + hexTransparency, name as string);
      }
    },
    [onSelectColor, name]
  );

  const componentId = useMemo(() => Date.now().toString(), []);

  return (
    <div className={classes.wrapper} id={`color-picker-${id || componentId}}`}>
      <Typography className={classes.headTitle}>{title}</Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        {label}
      </Typography>
      <div className={classes.inputWrapper}>
        <CssTextField
          className={classes.input}
          value={inputColor}
          disabled={disabled}
          inputProps={{
            name: `color-picker-input-${name}`,
          }}
          onBlur={handleBlur}
          helperText={error}
          error={!!error}
          onChange={(e) => handleManual(e)}
        />
        <Button
          className={classes.inputButton}
          name={`color-picker-button-m-${name}`}
          disabled={disabled}
          style={{ background: color }}
          onClick={closeMenuChangeColor}
        >
          <KeyboardArrowDownIcon className={isShowColorPicker ? classes.anim : classes.chevron} />
        </Button>
        <DesktopColorPicker
          name={name}
          disabled={disabled}
          color={color as string}
          classes={classes}
          completePick={completePick}
        />
      </div>

      {isShowColorPicker && !values ? (
        <div className={classes.mainColorWrapper}>
          <div className={classes.BigColors}>
            {Object.values(otherColors).map((el, index) => (
              <div
                key={el['500']}
                className={classes.mainColors}
                style={{ background: el['500'] }}
                onClick={selectColor(el['500'])}
              >
                <div>{colorsName[index]}</div>
                <div>{el['500']}</div>
              </div>
            ))}
          </div>
        </div>
      ) : null}

      {isShowColorPicker && !values ? (
        <div className={classes.resent}>
          <div className={classes.color} style={{ background: '#25a59a' }} />
          <div className={classes.color} style={{ background: '#4dd0e1' }} />
          <div className={classes.color} style={{ background: '#6a1a9a' }} />
          <div className={classes.color} style={{ background: '#ffffff' }} />
        </div>
      ) : null}

      {values ? (
        <div className={classes.colorConatainer}>
          {Object.values(otherColors).map((el, index) => (
            <div key={index} className={classes.col}>
              {Object.keys(el).map((item, num) => {
                return (
                  <div
                    className={classes.test}
                    key={item}
                    style={num < 3 ? { background: el[item], color: '#000' } : { background: el[item], color: '#FFF' }}
                    onClick={selectColor(el[item])}
                  >
                    {el[item]}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      ) : null}

      <div>
        {isShowColorPicker ? (
          <FormControlLabel
            control={<GreenCheckbox checked={values} onChange={() => setValues(!values)} value="showTints" />}
            label="Show tints"
          />
        ) : null}
      </div>
    </div>
  );
};
