import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  imgContainer: {
    display: 'flex!important',
    width: '100%',
    maxHeight: '90vh',
    height: '100%',
    justifyContent: 'center',
    color: '#fff',
    '&._ios': {
      marginTop: '-120px',
    },
    '&._android': {
      marginLeft: '30px',
    },

    '& img': {
      width: '100%',
      objectFit: 'contain',
    },
    '&._bottom._android': {
      marginTop: '-160px',
    },
  },
  view: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& .slick-list': {
      height: '100%',
    },
    '& .slick-slider': {
      height: '100%',
    },
    '& .slick-track': {
      height: '100%',
    },
    '& .slick-next': {
      top: 'auto',
      bottom: '-10px',
      right: '5px',
      zIndex: 10,

      '&:before': {
        content: "''",
        width: '16px',
        height: '16px',
        position: 'absolute',
        top: 0,
        right: 0,
        borderTop: '3px solid #6DA34D',
        borderRight: '3px solid #6DA34D',
        transform: 'rotate(45deg)',
      },
    },
    '& .slick-prev': {
      top: 'auto',
      bottom: '-10px',
      left: '5px',
      zIndex: 10,

      '&:before': {
        content: "''",
        width: '16px',
        height: '16px',
        position: 'absolute',
        top: 0,
        left: 0,
        borderTop: '3px solid #6DA34D',
        borderLeft: '3px solid #6DA34D',
        transform: 'rotate(-45deg)',
      },
    },
    '& .slick-slide': {
      marginRight: '0',
      height: '100%',
      justifyContent: 'center',
      '& > div': {
        width: '100%',
      },
    },
    '& .slick-dots': {
      position: 'relative',
      color: '#76B83F',
      top: 0,
      textAlign: 'center',

      '& li': {
        width: '10px',
        height: '10px',
      },

      '& .slick-active button:before': {
        backgroundColor: '#6DA34D',
        border: 'none',
      },
      '& li button': {
        width: '10px',
      },
      '& li button:before': {
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        border: '2px #76B83F solid',
      },
    },
  },
  header: {
    color: '#013D1F',
  },
  subtitle: {
    color: '#749D83',
    fontSize: '10px',
    textTransform: 'uppercase',
  },
  text: {
    margin: '40px 20px 10px',
    color: '#749D83',
  },
  wide: {
    width: '100%',
  },
  card: {
    width: '100%',
    position: 'relative',
    background: 'white',
    borderRadius: '6px',
    padding: '15px',
    textAlign: 'center',
    boxShadow: '0 5px 15px 0 rgba(15,15,15,0.4)',
    fontSize: '16px',
    color: '#999999',
    marginBottom: '30px',
    paddingBottom: '30px',
  },

  close: {
    cursor: 'pointer',
    zIndex: 10,
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: '#013D1F',
    boxShadow: '0 5px 15px 0 rgba(15,15,15,0.4)',
    position: 'absolute',
    right: '15px',
    top: '15px',
    color: 'white',
    paddingTop: '3px',
  },
});
