import { Theme } from '@material-ui/core/styles';
import { white } from 'themes/colors';

export const styles = (theme: Theme) => ({
  root: {
    flex: 1,
  },
  subtitle: {
    [theme.breakpoints.between('xs', 'sm')]: {
      'text-align': 'center',
      'text-transform': 'uppercase',
      color: white,
      marginTop: theme.spacing(4),
    },
  },
});
