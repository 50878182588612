import React, { useEffect, useRef, useState, useMemo } from 'react';
// import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { Box, Typography, makeStyles, Link } from '@material-ui/core';
import { SaveAlt } from '@material-ui/icons';
import GetAppIcon from '@material-ui/icons/GetApp';
import DialogWrapper, { IDialogRef } from 'modules/core/containers/DialogWrapper';
import { isMobile } from 'modules/core/utils';
import { darkGreen, limeade } from 'themes/colors';
import { useStoreVisit } from 'modules/public/pages/CardPage/SaveInvite/useStoreVisit';
import CssGreenButton from 'modules/core/components/buttons/roundGreenButton';
import { SaveDialog } from 'modules/core/components/dialogs/SaveDialog';
import { createVCard } from 'modules/core/utils/vCard';
import { ICardVersion } from 'modules/editor/graphql/gql';

interface IShowInvite {
  cardVer: ICardVersion;
  gdprDialogOpen?: boolean;
}

export const SaveInvitse: React.FC<IShowInvite> = (props) => {
  const { cardVer, gdprDialogOpen } = props;
  const { card: { urlSlug: urlCard }, fullName: ownerName } = cardVer;
  const classes = useStyles();
  const ref = useRef<IDialogRef>(null);
  const refDialog = useRef<IDialogRef>(null);
  const [show, setShow] = useState(false);

  // const saveActionUsesVCard = isMobile();
  const saveActionUsesVCard = true;

  // const saveHandler = useCallback(() => {
  //   if (ref.current) {
  //     ref.current.hide();
  //   }
  //   if (refDialog.current) {
  //     refDialog.current.show();
  //   }
  // }, [ref, refDialog]);

  useStoreVisit(setShow, gdprDialogOpen);

  useEffect(() => {
    const { current } = ref;
    if (current) {
      show ? current.show() : current.hide();
    }
  }, [show, ref]);

  function closeHandler(): void {
    const { current } = ref;
    if (current) {
      current.hide();
    }
  }

  function savePrompt() {
    if (!isMobile()) {
      return (<SaveDialog ref={refDialog} icon={<SaveAlt className="icon" />} />);
    };
  };

  const vCardData: { href: string, downloadName: string } | undefined = useMemo(() => {
    if (saveActionUsesVCard) {
      const vCard = createVCard(cardVer);

      if (vCard) {
        const name = cardVer.fullName ? cardVer.fullName.replace(/ /g, '-') : 'GoPaperlessContact';
        const downloadName = `${name}.vcf`;

        return { href: vCard, downloadName }
      }
    }
  }, [saveActionUsesVCard, cardVer])

  return (
    <>
      <DialogWrapper
        ref={ref}
        dialogId="save_invite"
        actions={[
          saveActionUsesVCard && vCardData ? (
            <Link href={vCardData.href} className={classes.link} target="_blank" download={`${vCardData.downloadName}`} key="save-vcard">
              <CssGreenButton id="save_invite_save_btn" key="save">
                Save card to contacts
              </CssGreenButton>
            </Link>
          ) : (
            <CssGreenButton id="save_invite_save_btn" href={`/${urlCard}/save-card`} key="save">
              Save card
            </CssGreenButton>
          ),
        ]}
      >
        <Box className={classes.container}>
          <div className={classes.downIco}>
            <GetAppIcon color="inherit" fontSize="inherit" />
          </div>
          <Typography variant="h5" className={classes.header}>
            Save {ownerName}'s card
          </Typography>
          <Typography variant="subtitle1" className={classes.sub}>
            We recommend that you save this card to your {isMobile() ? "mobile" : "device"}'s contact records.
          </Typography>
          <Typography variant="subtitle1" className={classes.sub}>
            To view and share the card, just select the URL saved in your contact records.
          </Typography>
          <Typography variant="subtitle1" className={classes.sub}>
            Would you like to do this now?
          </Typography>
          <Typography className={classes.cancel} onClick={closeHandler}>
            No thanks
          </Typography>
        </Box>
      </DialogWrapper>
      {savePrompt()}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  downIco: {
    fontSize: 60,
    lineHeight: 1,
    color: '#fff',
    borderRadius: '50%',
    background: darkGreen,
    height: 120,
    width: 120,
    margin: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  header: {
    textAlign: 'center',
  },
  sub: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  cancel: {
    cursor: 'pointer',
    color: limeade,
  },
  link: {
    minWidth: 220,

    "&:hover, :visited": {
      textDecoration: 'none',
    },
  },
  actionBtn: {
    marginBottom: theme.spacing(2),
  },
}));
