import React from 'react';
import QRCode from 'qrcode'

export async function generateQRCode(url: string, setter: React.Dispatch<React.SetStateAction<any>>, scale?: number) {
  const qrReadyUrl = url + "?qr=1"
  const scaleVal = scale ? scale : 7;
  const opts = {
    // type: 'image/png',
    scale: scaleVal,
  };

  QRCode.toDataURL(qrReadyUrl, opts)
    .then(code => {
      setter(code)
    })
    .catch(err => {
      console.error("QRCode::Error : ", err)
    })
};
