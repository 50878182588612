import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import darkTheme from 'themes/darkTheme';

const CustomLayout: React.FC = ({ children }) => {
  return (
    <MuiThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className="App Site">{children}</div>
    </MuiThemeProvider>
  );
};

export default CustomLayout;
