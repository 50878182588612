import React, { createRef, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import DialogWrapper from 'modules/core/containers/DialogWrapper';
import { PublishWarningDialogStyles as useStyles } from './styles';

interface IProps {
  show: boolean;
  cardUrl?: string;
  fullName?: string;
  emailAddress?: string;
  mobileNumber?: string;
  onClose: () => void;
}

const PublishValidateDialog: React.FC<IProps> = ({ show, cardUrl, fullName, emailAddress, mobileNumber, onClose }) => {
  const ref = createRef<any>();
  const classes = useStyles();

  useEffect(() => {
    if (show) {
      ref.current.show();
    } else {
      ref.current.hide();
    }
  }, [show, ref]);

  return (
    <DialogWrapper
      dialogId="publish_validate_dialog"
      ref={ref}
      titleText={`You are almost there!`}
      onClose={onClose}
      actionsClasses={{ root: classes.actionsRoot }}
    >
      <div className={classes.text}>
        <Typography variant="h6" className={classes.heading6}>
          Some of required fields are not filled:
        </Typography>
        {!cardUrl ? <Typography variant="body1">Styling: Card URL</Typography> : null}
        {!fullName ? <Typography variant="body1">Personal details: FULL NAME</Typography> : null}
        {!emailAddress && !mobileNumber ? (
          <Typography variant="body1">Personal details: EMAIL ADDRESS, MOBILE NUMBER</Typography>
        ) : null}
      </div>
    </DialogWrapper>
  );
};

export default PublishValidateDialog;
