import { FormControlLabel } from '@material-ui/core';
import AuthorizationDialog from 'modules/auth/components/Authorization/AuthorizationDialog';
import { IUserForm } from 'modules/auth/interfaces/auth';
import CssGreenButton from 'modules/core/components/buttons/roundGreenButton';
import { GreenCheckbox } from 'modules/core/components/fields';
import { InputField } from 'modules/core/components/fields/InputField';
import React, { FormEvent, useCallback } from 'react';

import { useStyles } from './styles';

interface IStepProps {
  bottom: React.ReactElement;
  onSubmit: (data: Partial<IUserForm>) => void;
  filledValues: Partial<IUserForm>;
  onChange: (prop: keyof IUserForm, value: any) => void;
}

const SignupStep3: React.FC<IStepProps> = ({ onSubmit, bottom, filledValues, onChange }) => {
  const classes = useStyles();

  const handleChange = useCallback(
    (prop: keyof IUserForm) => (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(prop, event.target.value);
    },
    [onChange]
  );

  const handleAgreement = useCallback(() => {
    onChange('subscribe_gdpr_news', !filledValues.subscribe_gdpr_news);
  }, [filledValues, onChange]);

  const handleContinue = useCallback(
    (evt: FormEvent) => {
      evt.preventDefault();
      if (onSubmit) {
        onSubmit(filledValues);
      }
    },
    [onSubmit, filledValues]
  );

  return (
    <AuthorizationDialog
      title="Company name"
      subtitle="Not in a company? Click continue"
      dlgBody={
        <form noValidate onSubmit={handleContinue} className={classes.form}>
          <InputField
            classes={{ root: classes.textField }}
            name="sign_up_company_name"
            variant="filled"
            type="text"
            label="Company name"
            value={filledValues.company_name as string}
            onChange={handleChange('company_name')}
          />
          <FormControlLabel
            className={classes.agreement}
            control={
              <GreenCheckbox checked={filledValues.subscribe_gdpr_news} name="agreement" onChange={handleAgreement} />
            }
            label="I want to receive news, tips and offers from Go Paperless"
          />
        </form>
      }
      footer={
        <CssGreenButton id="sign_up_countinue_step_3" className={classes.button} onClick={handleContinue}>
          Continue
        </CssGreenButton>
      }
      bottom={bottom}
    />
  );
};

export default SignupStep3;
