import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Progress from 'modules/core/components/progress';

interface ICardLoadingProps {
  logoUrl?: string;
}

export const CardLoading: React.FC<ICardLoadingProps> = (props) => {
  const { logoUrl } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root)}>
      <div className={clsx(classes.logoWrapper)}>
        {logoUrl && <img src={logoUrl} alt="Logo" className={classes.logo} />}
      </div>

      <Progress />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    zIndex: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '40px 0',
    backgroundColor: '#333333',
  },
  logoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 200,
    heigth: 100,
    marginBottom: 100,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
    },
  },
  logo: {
    width: '100%',
    height: '100%',
    animation: "fadeInAnimation ease 0.5s",
    animationIterationCount: "1",
    animationFillMode: "forwards",
  },
}));
