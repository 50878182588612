import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CheckCircle from '@material-ui/icons/CheckCircle';
import React from 'react';
import { useStyles } from './styles';

interface IProps {
  message: string;
  title?: string;
  id?: string;
}

const SuccessContent: React.FC<IProps> = ({ message, title, id }) => {
  const classes = useStyles();

  return (
    <Box id={id} display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="100%">
      <CheckCircle color="primary" className={classes.sharedIcon} />
      <Typography variant="h5" className={classes.title}>
        {title || 'Success!'}
      </Typography>
      <Typography variant="subtitle1">{message}</Typography>
    </Box>
  );
};

export default SuccessContent;
