import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    titleButton: {
      flexGrow: 1,
      padding: 0,
      fontSize: 30,
      fontWeight: 700,
      letterSpacing: 1,
      lineHeight: theme.typography.pxToRem(43),
      textTransform: 'none',
      [theme.breakpoints.down('sm')]: {
        marginRight: 8,
        fontSize: 20,
        lineHeight: theme.typography.pxToRem(29),
        fontWeight: 700,
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    dropDown: {
      overflowY: 'auto',
    },
    paper: {
      top: '104px!important',
      width: '100%',
      maxWidth: 420,
      border: '1px solid rgba(255,255,255,0.15)',
      borderRadius: 4,
      backgroundColor: '#3B4046',
      boxShadow: '0 10px 35px 0 rgba(0,0,0,0.1)',
    },
    versions: {
      marginRight: 2,
      maxHeight: 200,
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    buttonInner: {
      padding: 0,
      margin: 0,
      '& .MuiIconButton-label': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 0,
        padding: 0,
      },
      transform: 'rotate(180deg)',
    },
    head: {
      maxWidth: 385,
      margin: '16px auto 0 auto',
      '&> :first-child': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      },
      '& li': {
        margin: 0,
        maxWidth: 385,
        fontWeight: 700,
      },
      '& span': {
        display: 'block',
        fontSize: 20,
        fontWeight: 300,
        fontFamily: 'F37 Ginger Light',
        lineHeight: theme.typography.pxToRem(24),
        color: 'white',
        marginBottom: 21,
        padding: '0 13px',
      },
      '& p': {
        fontWeight: 700,
        margin: '2px 0',
        opacity: 0.8,
        fontSize: 14,
        lineHeight: theme.typography.pxToRem(20),
      },
    },
    item: {
      padding: '9px 13px',
      width: '100%',
      maxWidth: 380,
      borderRadius: 4,
      margin: '0 auto',
      borderBottom: 0,
    },
  })
);
