import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import { E_USER_STATUS } from 'modules/users/interfaces/assigned-users';
import React, { useMemo } from 'react';
import { AssignedUserActions } from 'modules/users/components/UsersPage/AssignedUsers/AssignedUser/AssignedUserActions';
import { IAssignedUserProps } from 'modules/users/components/UsersPage/AssignedUsers/AssignedUser/AssignedUserRow';
import { cardBg } from 'themes/colors';
import { useTypedSelector } from 'hooks/useTypedSelector';

export const AssignedUserCard: React.FC<IAssignedUserProps> = (props) => {
  const {
    index,
    fields,
    user,
    expanded,
    panelState,
    onExpand,
    onToggleState,
    onDelete,
    onReassign,
    onCancel,
    onConfirmDelete,
    onConfirmReassign,
  } = props;
  const currentAccountId = useTypedSelector((state) => state.auth.profile.id);
  const classes = useStyles();
  const emailField = useMemo(() => fields.find((item) => item.key === 'email'), [fields]);
  const nameField = useMemo(() => fields.find((item) => item.key === 'name'), [fields]);
  const statusField = useMemo(() => fields.find((item) => item.key === 'status'), [fields]);

  return (
    <Card className={classes.root}>
      <Typography variant="body1" className={classes.cardRow}>
        {nameField!.getData!(user)}
      </Typography>
      <Typography variant="body1" className={classes.cardRow} color="textSecondary">
        {emailField!.getData!(user)}
      </Typography>
      {!expanded && <Divider className={classes.cardRow} />}
      <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.cardRow}>
        <Typography variant="body1" color="secondary">
          Status: {statusField!.getData!(user)}
        </Typography>
        {currentAccountId !== user.slot.accountId ? (
          <Fab size="small" color={expanded ? 'default' : 'secondary'} onClick={onExpand}>
            {expanded ? <ClearIcon /> : <AddIcon />}
          </Fab>
        ) : null}
      </Box>
      {expanded && (
        <>
          <Divider className={classes.cardRow} />
          <AssignedUserActions
            index={index}
            user={user}
            status={user.slot.status as E_USER_STATUS}
            panelState={panelState}
            onToggleState={onToggleState}
            onDelete={onDelete}
            onReassign={onReassign}
            onCancel={onCancel}
            onConfirmDelete={onConfirmDelete}
            onConfirmReassign={onConfirmReassign}
          />
        </>
      )}
    </Card>
  );
};

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: cardBg,
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
  cardRow: {
    marginBottom: theme.spacing(1),
    '&:last-child': {
      marginBottom: 0,
    },
  },
}));
