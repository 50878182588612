import gql from 'graphql-tag';
import { apoloClient } from '../constants/client';
import { ID } from '../types';

interface ICreateContactRequest {
  cardVersionId: number;
  preference: string;
  shareCardId?: ID;
  creationType?: string;
}

export async function createContactRequest(args: ICreateContactRequest) {
  const { cardVersionId, preference, shareCardId } = args;
  const creationType = args.creationType || "default";

  await apoloClient.mutate({
    variables: {
      cardVersionId,
      preference,
      shareCardId,
      creationType,
    },
    mutation: gql`
      mutation createContactRequest(
        $cardVersionId: ID!,
        $preference: String!,
        $shareCardId: Int,
        $creationType: String
      ) {
        createContactRequest(
          attributes: {
            cardVersionId: $cardVersionId,
            preference: $preference,
            shareCardId: $shareCardId,
            creationType: $creationType
          }
        ) {
          errors
          status
        }
      }
    `,
  });
}

interface ICreateContactRequestWithRawData {
  cardVersionId: number;
  preference: string;
  creationType: string;
  fullName: string;
  email?: string;
  mobilePhone?: string;
}

export async function createContactRequestWithRawData(args: ICreateContactRequestWithRawData) {
  const { cardVersionId, preference, fullName, email, mobilePhone } = args;
  const creationType = args.creationType || "default";

  await apoloClient.mutate({
    variables: {
      cardVersionId,
      preference,
      creationType,
      fullName,
      email,
      mobilePhone
    },
    mutation: gql`
      mutation createContactRequestWithRawData(
        $cardVersionId: ID!,
        $preference: String!,
        $creationType: String!,
        $fullName: String!,
        $email: String,
        $mobilePhone: String
      ) {
        createContactRequestWithRawData(
          attributes: {
            cardVersionId: $cardVersionId,
            preference: $preference,
            creationType: $creationType,
            fullName: $fullName,
            email: $email,
            mobilePhone: $mobilePhone
          }
        ) {
          errors
          status
        }
      }
    `,
  });
}
