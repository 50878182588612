import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import ConditionalWrap from 'conditional-wrap';
import React, { forwardRef, ReactNode, useMemo } from 'react';
import Slider, { Settings, CustomArrowProps, ResponsiveObject } from 'react-slick';
import PieBullet, { usePieBulletStyles } from 'modules/auth/containers/ResellerSignupV2/PieBullet';

interface ISliderResponsive {
  children: ReactNode;
  isMobile?: boolean;
  settings?: Partial<Settings & CustomArrowProps & ResponsiveObject>;
  className?: Record<string, string>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    adaptiveSlider: {
      '& .slick-track:focus': {
        outline: 'none !important',
        '&::before': {
          display: 'none',
        },
      },
      '& .slick-track': {
        display: 'flex !important',
        alignItems: 'stretch',
      },
      '& .slick-slide': {
        display: 'flex !important',
        height: 'auto',
        minHeight: 'auto',
        // justifyContent: 'space-between',
        justifyContent: 'center',
      },
      '& .slick-slide:focus': {
        outline: 'none!important',
      },
      '& .slick-dots': {
        top: 'auto',
        position: 'relative',
        left: 0,
        textAlign: 'center',
        transform: 'none',
        height: 'fit-content',
        '& button': {
          paddingLeft: 0,
          paddingRight: 0,
          width: 8,
        },
        '& .slick-dots': {
          top: 120,
          left: 0,
          textAlign: 'left',
          transform: 'none',
          height: 'fit-content',
          '& button': {
            paddingLeft: 0,
            paddingRight: 0,
            width: 8,
          },
          [theme.breakpoints.down('xs')]: {
            maxWidth: 185,
          },
          '& li': {
            width: 8,
            height: 8,
            marginRight: theme.spacing(2),
          },
        },
      },
      '& .slick-dots li button': {
        '&:before': {
          color: 'white',
          width: 8,
          height: 8,
          background: 'white',
          content: `' '`,
          borderRadius: '50%',
        },
      },
    },
  })
);

export default forwardRef<Slider, ISliderResponsive>((props, ref) => {
  const { isMobile, className, settings, children } = props;
  const classes = useStyles();
  const pieBulletStyles = usePieBulletStyles();
  const [selected, setSelected] = React.useState(0)

  const defaultSttings = useMemo<Settings>(
    () => ({
      dots: true,
      infinite: false,
      arrow: false,
      speed: 500,
      slidesToScroll: 1,
      arrows: false,
      autoplaySpeed: 3000,
      pauseOnDotsHover: true,
      // pauseOnFocus: true,
      // pauseOnHover: true,
      beforeChange: (oldIndex, newIndex) => setSelected(newIndex),
      customPaging: index => <span className={selected === index && pieBulletStyles.active}><i className={pieBulletStyles.pieBulletWrap} style={{marginLeft: isMobile ? -7 : -18}}>
        {selected === index && <PieBullet selected={selected} slices={[...(children || [])].length} colorFull={'#74BA35'} />}
      </i></span>,
    }),
    [selected]
  );

  return (
    <ConditionalWrap
      condition={isMobile}
      wrap={(child: React.ReactNode) => (
        <Slider ref={ref} {...defaultSttings} {...settings} className={clsx(classes.adaptiveSlider, className)}>
          {child}
        </Slider>
      )}
    >
      {children}
    </ConditionalWrap>
  );
});
