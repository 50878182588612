import React, { createRef, useEffect } from 'react';
import DialogWrapper from 'modules/core/containers/DialogWrapper';
import { CopyCardURL } from 'modules/core/components/CopyCardURL';

interface ICardPublishSuccessDialog {
  url: string;
  show: boolean;
  onClose: () => void;
}

export const CardPublishSuccessDialog: React.FC<ICardPublishSuccessDialog> = ({ url, show, onClose }) => {
  const ref = createRef<any>();

  useEffect(() => (show ? ref.current.show() : ref.current.hide()), [show, ref]);

  return (
    <DialogWrapper dialogId="publish_success" ref={ref} onClose={onClose}>
      <CopyCardURL url={url} />
    </DialogWrapper>
  );
};

export default CardPublishSuccessDialog;
