import { PhoneNumberType as PNT, PhoneNumberUtil } from 'google-libphonenumber';
const phoneUtil = PhoneNumberUtil.getInstance();

export function validateEmail(email: string): boolean {
  if (!email) {
    return false;
  }
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}

export function validatePhone(phone: string, allowNull?: boolean): boolean {
  try {
    if (allowNull && [null, undefined, "", "+"].includes(phone)) {
      return true;
    }

    if (phone.length < 11) {
      return false
    }

    const phoneObj = phoneUtil.parseAndKeepRawInput(phone);
    return phoneUtil.isValidNumber(phoneObj);
  } catch (e) {
    console.warn(e);
    return false;
  }
}

export function validatePhoneByType(phone: string): boolean {
  let isValid = false;

  try {
    const phoneObj = phoneUtil.parseAndKeepRawInput(phone);

    switch (phoneUtil.getNumberType(phoneObj)) {
      case PNT.FIXED_LINE:
        break;
      case PNT.MOBILE:
        isValid = true;
        break;
      case PNT.FIXED_LINE_OR_MOBILE:
        break;
      case PNT.TOLL_FREE:
        break;
      case PNT.PREMIUM_RATE:
        break;
      case PNT.SHARED_COST:
        break;
      case PNT.VOIP:
        break;
      case PNT.PERSONAL_NUMBER:
        break;
      case PNT.PAGER:
        break;
      case PNT.UAN:
        break;
      case PNT.UNKNOWN:
        break;
    }
  } catch (e) {
    console.warn(e);
  }
  return isValid;
}

interface ICardShare {
  name: string;
  email: string;
  phone?: string;
}

export function validateCardShare(data: ICardShare, isPhoneValid?: string): boolean {
  const { name, email, phone } = data;

  if (name && (email || phone)) {
    // the value should be ether empty or validated
    const validEmail = email ? validateEmail(email) : true;
    const validPhone = phone ? isPhoneValid === 'valid' || isPhoneValid === 'empty' : true;
    return validEmail && validPhone;
  }
  return false;
}
