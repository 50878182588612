import React from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import useStylesPage from 'modules/public/pages/main/pageStyles';
import CtaButtons, { ICtaButtons } from './CtaButtons';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import PieBullet, { usePieBulletStyles } from 'modules/auth/containers/ResellerSignupV2/PieBullet';

interface IProps {
  textOpacity: number;
  currentPoint: number;
}

interface IPoint {
  title: string;
  subtitle?: string;
  noBullet?: boolean;
}

const POINTS: IPoint[] = [
  {
    title: "A Greener Way To Connect, Engage & Grow",
    subtitle: "",
    noBullet: true,
  },
  {
    title: "Contactless marketing platform",
    subtitle: "",
  },
  {
    title: "Business card",
    subtitle: "",
  },
  {
    title: "Digital CV",
    subtitle: "",
  },
  {
    title: "Website alternative",
    subtitle: "",
  },
];

const LandingText: React.FC<IProps & ICtaButtons> = ({ textOpacity, currentPoint, ...ctaButtonsProps}) => {
  const classes = useLandingTextStyles();
  const pageStyles = useStylesPage();
  const pieBulletStyles = usePieBulletStyles();
  const transitionStyle = { transition: '0.3s' }
  const opacityStyle = { ...transitionStyle, opacity: textOpacity };
  const transparentStyle = { ...transitionStyle, opacity: 0 };
  const opaqueStyle = { ...transitionStyle, opacity: 1 };

  return (
    <div style={{ transition: '0.3s' }} className={clsx(classes.container, "LandingSection_Container_ResponsiveHeight")}>
      <Typography variant="h2" className={clsx(pageStyles.pageTitle, "LandingSection_LandingText_ResponsiveHeight")}>
        <div className={clsx("_smDownHide", classes.pointHeader, "LandingSection_PointHeader_ResponsiveHeight")}>
          { POINTS.map((point, index) => (
            <div key={index}>
              <div
                className={clsx("_smDownHide", classes.landingTextPointTitle)}
                style={currentPoint === index ? opaqueStyle : transparentStyle}
              >
                {point.title}
              </div>
              <div
                className={clsx("_smDownHide", classes.landingTextPointSubtitle)}
                style={currentPoint === index ? opaqueStyle : transparentStyle}
              >
                {point.subtitle}
              </div>
            </div>
          ))}
        </div>
        <div className="_mdUpHide" style={opacityStyle}>One digital card.</div>
        <div className="_mdUpHide" style={opacityStyle}>Infinite opportunities.</div>
      </Typography>
      <div className={pageStyles.keyPoints}>
        <ul>
          { POINTS.map((point, index) => (
            !point.noBullet && (
              <li key={index} className={clsx(pieBulletStyles.pieBulletWrapOuter, index === currentPoint && pieBulletStyles.active)}>
                <i className={pieBulletStyles.pieBulletWrap}>
                  <PieBullet selected={index === currentPoint ? currentPoint-1 : -1} slices={POINTS.length - 1} colorFull={'#74BA35'} />
                </i>
                {point.title}
              </li>
            )
          ))}
        </ul>
      </div>

      <Typography className={clsx(pageStyles.pageSubtitle, '_smDownHide', "LandingSection_PageSubtitle_ResponsiveHeight")} variant="h4">
        One digital card. Infinite opportunities.
      </Typography>
      <CtaButtons {...ctaButtonsProps} exClass="_mdDownHide" {...ctaButtonsProps} />
    </div>
  );
};

export default LandingText;


const useLandingTextStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      marginLeft: theme.spacing(2),
    },
    pointHeader: {
      position: 'relative',
      minHeight: 150,
      display: 'flex',
      alignItems: 'center',
      width: '100%',

      '& > *': {
        position: 'absolute',
        width: '100%',
      }
    },
    landingTextPointTitle: {
      marginBottom: theme.spacing(1),
      '@media (max-height: 650px)' : {
        fontSize: '67%'
      }
    },
    landingTextPointSubtitle: {
      fontSize: 30,
    },

    [theme.breakpoints.down('md')]: {
    },
  })
);
