import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { IFileReadResult, readFileAsBase64 } from 'modules/core/utils';
import { CropOverlay } from 'modules/core/components/fields/UploadImage/CropOverlay';
import { ImageInput, IImageInputProps } from './ImageInput';
import { makeStyles } from '@material-ui/core';
import { Notification } from 'modules/core/components/Notification';
import uuid from 'uuid';
import { MAX_FILESIZE_BYTES } from 'modules/core/constants';

interface IUploadImageProps extends IImageInputProps {
  title: string;
  hideTitle?: boolean;
  firstDescription?: string;
  secondDescription?: string;
  uploadImg: (result: IFileReadResult) => Promise<void>;
  crop?: boolean;
  skipCropForGif?: boolean;
}

const initialImg: IFileReadResult = {
  filename: '',
  base64data: '',
};

export const UploadImage: React.FC<IUploadImageProps> = (props) => {
  const { title, firstDescription, secondDescription, uploadImg, hideTitle, } = props;
  const classes = useStyles();
  const [pending, setPending] = useState<boolean>(false);
  const [imgData, setImgData] = useState<IFileReadResult>(initialImg);
  const [error, setError] = useState<string>("");

  async function onUpload(data: IFileReadResult): Promise<void> {
    setPending(true);
    await uploadImg(data);
    setPending(false);
  }

  function onCancel(): void {
    setImgData(initialImg);
  }

  function onCrop(cropedDatat: string): void {
    // no await, modal should close imediatly
    onUpload({ ...imgData, base64data: cropedDatat });
    onCancel();
  }

  async function onProcessFile(file: File): Promise<void> {
    if (file.size > MAX_FILESIZE_BYTES) {
      console.warn("File is too large. Bytes: ", file.size)

      setError("Selected file for " + title.toLowerCase() + " is too large. Max file size is 5MB.");
      return;
    }

    try {
      const data = await readFileAsBase64(file);
      onUpload(data);
    } catch (e) {
      setError("Selected file is too large. Please use another file.");
    }
  }

  function getInputProps(): IImageInputProps {
    const { size, disabled, alowClear, onClear, accept, name, imgUrl } = props;
    return { size, disabled, alowClear, onClear, accept, name, imgUrl };
  }

  const { base64data } = imgData;

  return (
    <div className={classes.wrapper}>
      {base64data && <CropOverlay onClose={onCancel} onSave={onCrop} src={base64data} />}
      <Typography className={classes.headTitle}>{!hideTitle && title}</Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        {firstDescription}
      </Typography>
      <Typography className={classes.subtitle2} variant="subtitle1">
        {secondDescription}
      </Typography>
      <ImageInput {...getInputProps()} pending={pending} onProcessFile={onProcessFile} />
      <Notification
        id={uuid()}
        isOpen={!!error}
        text={error}
        severity="error"
        timeout={10000}
        onClose={() => setError("")}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  headTitle: {
    color: '#c6c8ca',
    fontSize: 12,
    textTransform: 'uppercase',
  },
  subtitle: {
    color: '#919598',
    fontSize: 12,
    fontWeight: 300,
    fontFamily: 'F37 Ginger Light',
  },
  subtitle2: {
    color: '#919598',
    fontSize: 12,
    fontWeight: 300,
    fontFamily: 'F37 Ginger Light',
    marginBottom: theme.spacing(1),
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 400,
    margin: theme.spacing(3, 0),
  },
}));
