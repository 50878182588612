export const white = '#ffffff';
export const blueCharcoal = '#22262c';
export const blueCharcoal2 = '#262B31';
export const cardBg = '#2a3136';
export const tuna = '#464A4F';
export const brightGrey = '#51555A';
export const steelGrey = '#3C4046';
export const grey = '#939598';
export const darkGrey = '#262A30';
export const limeade = '#7ABC42';
export const darkGreen = '#013D1F';
export const initialGray = '#333333';
export const initialDarkGray = '#999999';
export const green = '#749D83';
export const lightGreen = '#76B83F';
export const darkHeader = '#21262c';
export const black = '#000000';
export const lightGrey = '#eaeaea';
export const darkGrey5 = '#555555';
export const almostBlack = '#252B31';
export const paleMintGreen = '#89A894';
export const gray8 = '#7C7F83';
export const crimsonRed = '#BF0000';
export const gray2 = '#222222';
export const gray4 = '#3B4046';
export const gray5 = '#4D5156';
export const errorRed = '#f44336';
export const darkGreyBg = blueCharcoal2;
export const yellow = '#FFB700';
export const cadetBlue = '#277d9c';
export const cadetGreen = '#81988C';
export const lightCadetGreen = '#E7EEEE';
export const teal = '#089086';
export const seablue = '#3C6BB2';
export const skyblue = '#709BE7';

export const mossGreen = '#1f4d2d';
export const slateGray = '#dee7e7';
export const cementGray = '#58595b';
export const lemonadeYellow = '#d1e4a5';
export const lawnGreen = '#338c4e';

