import React, { MouseEvent, useCallback, useState } from 'react';
import { IFieldDescriptor } from 'modules/core/interfaces/table';
import { IInviteUser, ISlotUser } from 'modules/users/interfaces/assigned-users';
import { reassignUser, removeAccount, toggleSlotState } from 'modules/users/thunks/assigned-users';
import { AssignedUserCard } from 'modules/users/components/UsersPage/AssignedUsers/AssignedUser/AssignedUserCard';
import { AssignedUserRow } from 'modules/users/components/UsersPage/AssignedUsers/AssignedUser/AssignedUserRow';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { useThunkDispatch } from 'hooks/useThunkDispatch';
import { Notification } from 'modules/core/components/Notification';

interface IProps {
  fields: Array<IFieldDescriptor<ISlotUser>>;
  user: ISlotUser;
  variant: 'row' | 'card';
  index: number;
  onShowDeleteDialog: () => void;
}

enum E_PANEL_STATE {
  NONE,
  DELETING,
  REASSIGNING,
}

export const AssignedUser: React.FC<IProps> = (props) => {
  const { user, fields, variant, index, onShowDeleteDialog } = props;
  const actionPending = useTypedSelector((state) => state.users.actionPending);
  const errors = useTypedSelector((state) => state.users.errors);
  const [expanded, setExpanded] = useState(false);
  const [panelState, setPanelState] = useState<E_PANEL_STATE>(E_PANEL_STATE.NONE);
  const thunkDispatch = useThunkDispatch();
  const [isReasigned, setIsReasigned] = useState<boolean>(false);

  const toggleRowActions = useCallback(
    (evt: MouseEvent) => {
      evt.preventDefault();
      setExpanded(!expanded);
      setPanelState(E_PANEL_STATE.NONE);
    },
    [expanded]
  );

  function onToggleState(): void {
    thunkDispatch(toggleSlotState(user.slot.id));
  }

  const handleDelete = useCallback((evt: MouseEvent) => {
    evt.preventDefault();
    setPanelState(E_PANEL_STATE.DELETING);
  }, []);

  const handleReassign = useCallback((evt: MouseEvent) => {
    evt.preventDefault();
    setPanelState(E_PANEL_STATE.REASSIGNING);
  }, []);

  const handleCancel = useCallback((evt: MouseEvent) => {
    evt.preventDefault();
    setPanelState(E_PANEL_STATE.NONE);
  }, []);

  async function confirmDelete(): Promise<void> {
    await thunkDispatch(removeAccount(user.slot.id));
    setExpanded(false);
    onShowDeleteDialog();
  }

  function onCloseAlert(): void {
    setIsReasigned(false);
  }

  async function confirmReassign(newUser: IInviteUser): Promise<void> {
    await thunkDispatch(reassignUser(user.slot.id, newUser));
    setPanelState(E_PANEL_STATE.NONE);
    setExpanded(false);
    setIsReasigned(true);
  }

  return (
    <>
      {variant === 'row' ? (
        <AssignedUserRow
          index={index}
          pending={actionPending}
          errors={errors}
          user={user}
          expanded={expanded}
          panelState={panelState}
          fields={fields}
          onExpand={toggleRowActions}
          onToggleState={onToggleState}
          onDelete={handleDelete}
          onReassign={handleReassign}
          onCancel={handleCancel}
          onConfirmDelete={confirmDelete}
          onConfirmReassign={confirmReassign}
        />
      ) : (
        <AssignedUserCard
          index={index}
          pending={actionPending}
          errors={errors}
          user={user}
          expanded={expanded}
          panelState={panelState}
          fields={fields}
          onExpand={toggleRowActions}
          onToggleState={onToggleState}
          onDelete={handleDelete}
          onReassign={handleReassign}
          onCancel={handleCancel}
          onConfirmDelete={confirmDelete}
          onConfirmReassign={confirmReassign}
        />
      )}
      <Notification
        id="asigned-user-status"
        onClose={onCloseAlert}
        severity="success"
        text="Reassigned successfully!"
        isOpen={isReasigned}
      />
    </>
  );
};
