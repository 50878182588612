import { useMemo } from 'react';
import { CSSProperties } from '@material-ui/styles';
import { ICardStyle } from 'modules/editor/graphql/gql';
import { initialDarkGray } from 'themes/colors';

/**
 * Creating styles of social link icons from user settings
 * @param cardStyle card style object
 */
export function useSocialLinkStyle(cardStyle: ICardStyle): CSSProperties {
  return useMemo(() => ({
    backgroundColor: cardStyle.secondaryIconsHexColour || initialDarkGray,
    color: '#ffffff',
  }), [cardStyle.secondaryIconsHexColour]);
}
