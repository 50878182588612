import { useMemo, CSSProperties } from 'react';
import { initialGray } from 'themes/colors';
import { ICardStyle } from 'modules/editor/graphql/gql';

// background-image property
function getImage(cardStyle: ICardStyle): string {
  const { backgroundImageShow, backgroundImageUrl, overlayShow, overlayHexColour } = cardStyle;
  const image = `url(${backgroundImageUrl})`;
  const gradient = `radial-gradient(50% 1000px at top, rgba(0,0,0,0.1), ${overlayHexColour || initialGray})`;
  const values: string[] = [overlayShow ? gradient : '', backgroundImageShow ? image : ''];
  // filter only existing values and reduce to valid background-image string
  return values.filter((item) => item).reduce((prev, cur) => (prev ? `${prev}, ${cur}` : cur), '');
}

/**
 * Creates CSS background properties for card
 */
export function useUserBackground(cardStyle: ICardStyle): CSSProperties {
  return useMemo(
    () => ({
      backgroundPosition: 'top center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundColor: cardStyle.backgroundHexColour || initialGray,
      backgroundImage: getImage(cardStyle),
    }),
    [cardStyle]
  );
}
