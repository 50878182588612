import React from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ISlotUser } from 'modules/users/interfaces/assigned-users';
import { IFieldDescriptor } from 'modules/core/interfaces/table';
import { AssignedUser } from 'modules/users/components/UsersPage/AssignedUsers/AssignedUser';

interface IProps {
  fields: Array<IFieldDescriptor<ISlotUser>>;
  users: ISlotUser[];
  onShowDeleteDialog: () => void;
}

export const AssignedUsersTable: React.FC<IProps> = (props) => {
  const { users, fields, onShowDeleteDialog } = props;
  const classes = useStyles();

  return (
    <Table className={classes.root}>
      <TableHead>
        <TableRow>
          {fields.map((field, idx) => (
            <TableCell key={idx} className={classes.cell}>
              {field.title}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map((user, idx) => (
          <AssignedUser
            onShowDeleteDialog={onShowDeleteDialog}
            key={idx}
            user={user}
            index={idx}
            fields={fields}
            variant="row"
          />
        ))}
      </TableBody>
    </Table>
  );
};

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  ...tableStyles(theme),
}));

export const tableStyles = (theme: Theme) => ({
  cell: {
    paddingLeft: 0,
    borderBottom: 'none',
  },
  cellBordered: {
    borderBottom: `1.5px solid ${theme.palette.divider}`,
  },
});
