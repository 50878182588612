import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import { cadetBlue, white } from 'themes/colors';
import { boldBorder } from 'themes/borders';
import clsx from 'clsx';

const PlayButton: React.FC<ButtonProps> = ({ children, ...restProps }) => {
  const classes = useStyles();

  return (
    <Button {...restProps} disableRipple classes={{ root: classes.root, label: classes.content }}>
      <span className={clsx(classes.playButtonWrapper, "_outlinedPlayButton")}>
        <PlayArrowRoundedIcon classes={{ root: clsx(classes.playButton) }} />
      </span>

      <span className={clsx(classes.label, "_playButtonLabel")}>{ children }</span>
    </Button>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: 'transparent',
      color: 'white',
      maxWidth: 250,
      width: '100%',
      borderRadius: 25,
      fontFamily: 'F37 Ginger',
      fontWeight: 700,
      lineHeight: '18px',
      padding: '13px 8px',
    },
    content: {
      display: 'flex',
      justifyContent: 'space-evenly',
    },
    playButtonWrapper: {
      position: 'absolute',
      left: 16,
    },
    playButton: {
      border: boldBorder,
      background: white,
      color: cadetBlue,
      fontSize: 48,
      marginRight: 8,
      marginTop: 2,
      boxShadow: '0 10px 20px 0 rgba(0,0,0,0.25)',
      borderRadius: '50%',
    },
    label: {
      marginLeft: 50,
      textTransform: 'uppercase',
    },

    [theme.breakpoints.down('sm')]: {
      content: {
        flexDirection: 'column',
      },
      playButtonWrapper: {
        position: 'relative',
        left: 0,
      },
      playButton: {
        maxWidth: 150,
      },
      label: {
        marginLeft: 0,
        marginTop: 8,
        fontSize: 14,
        letterSpacing: '0.2px',
        textTransform: 'initial',
      },
    },
  })
);

export default PlayButton;
