import { Box } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import Favicon192 from 'assets/favicon/android-chrome-192x192.png';
import img23 from 'assets/images/23.png';
import img24 from 'assets/images/24.png';
import img25 from 'assets/images/25.png';
import Android1 from 'assets/images/Android1.png';
import Android2 from 'assets/images/Android2.png';
import Android3 from 'assets/images/Android3.png';
import clsx from 'clsx';
import SliderResponsive from 'modules/core/components/carousel/responsiveSlider';
import { IResponsive } from 'modules/core/interfaces';
import { checkUserAgent, useUpdateHeadTag } from 'modules/core/utils';
import { getCardByUrl } from 'modules/editor/api/card-query';
import { ICardVersion } from 'modules/editor/graphql/gql';
import useStylesContainer from 'modules/public/pages/main/containerStyles';
import useStylesPage from 'modules/public/pages/main/pageStyles';
import React, { createRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Slider from 'react-slick';
import { IReduxState } from 'store';
import uuid from 'uuid';
import { useStyles } from './useStyles';
import { useManifest } from '../Card/functions';

const textsIOS = [
  'Tap the share button at the bottom of the browser',
  "On the bottom row of icons scroll over until you see 'Add a to homescreen' and tap this",
  "Confirm the name of this card then tap add to your home screen and you're done!",
];

const textsAndroid = [
  'Press the 3 dots at the top right of the browser window…',
  'Press “Add to Home screen”…',
  'That’s it. You’ll see the card has now been added to your homescreen!',
];

interface ISaveCardPageProps {
  card: ICardVersion;
  getCardByUrl: (id: string) => Promise<void>;
}

const SaveCardPage: React.FC<ISaveCardPageProps> = ({ card, getCardByUrl: getCard }) => {
  const classes = useStyles();
  const pageStyles = useStylesPage();
  const containerClasses = useStylesContainer();
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const headUpdater = useUpdateHeadTag('link');
  const metaUpdater = useUpdateHeadTag('meta');

  const manifestURL = useManifest(card.fullName as string, card.card.urlSlug as string, card.iconUrl512);

  const [values, setValues] = React.useState<IResponsive>({
    isMobile: true,
  });

  useEffect(() => {
    if (params.id) {
      getCard(params.id);
    }
  }, [params.id, getCard]);

  const [texts, setTexts] = useState<string[]>([]);
  const [images, setImages] = useState<any[]>([]);
  const [userAgentModifier, setUserAgentModifier] = useState<'android' | 'ios'>();

  const slider = createRef<Slider>();
  const sliderImg = createRef<Slider>();

  const handleClick = (e: any) => {
    if (slider.current !== null) {
      slider.current!.slickGoTo(e as number);
    }

    if (sliderImg.current !== null) {
      sliderImg.current!.slickGoTo(e as number);
    }
  };

  useEffect(() => {
    headUpdater(
      { rel: 'apple-touch-icon', sizes: '180x180' },
      { href: card.iconUrl512 ? `${card.iconUrl512}?${uuid()}` : Favicon192 }
    );
    headUpdater({ rel: 'manifest' }, { href: manifestURL });
    metaUpdater({ name: 'apple-mobile-web-app-title' }, { content: card.fullName });
  }, [manifestURL, headUpdater, metaUpdater, card]);

  useEffect(() => {
    const handler = () => {
      if (window.innerWidth <= 960) {
        setValues({ ...values, isMobile: true });
      } else {
        setValues({ ...values, isMobile: false });
      }
    };
    const userAgent = checkUserAgent();
    setUserAgentModifier(userAgent);
    if (userAgent === 'ios') {
      setTexts(textsIOS);
      setImages([img23, img24, img25]);
    } else {
      setTexts(textsAndroid);
      setImages([Android1, Android2, Android3]);
    }

    window.addEventListener('resize', handler);
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, [values]);

  return (
    <>
      <div className={clsx(pageStyles.page, classes.view, '_padding-sm-mobile')}>
        <SliderResponsive
          ref={sliderImg}
          isMobile={values.isMobile}
          settings={{ asNavFor: slider.current as Slider, dots: false, swipe: false, afterChange: handleClick }}
        >
          {images.map((item: any, idx: any) => {
            return (
              <div
                key={idx}
                className={clsx(classes.imgContainer, idx === 2 ? '_bottom' : '', `_${userAgentModifier}`)}
              >
                <img src={item} alt="App installation instructions" />
              </div>
            );
          })}
        </SliderResponsive>

        <Container className={clsx(containerClasses.container)}>
          <Box className={classes.card}>
            <div className={classes.close} onClick={() => history.goBack()}>
              <ClearIcon />
            </div>
            <SliderResponsive
              ref={slider}
              isMobile={values.isMobile}
              settings={{ asNavFor: sliderImg.current as Slider, dots: true, arrows: true, afterChange: handleClick }}
            >
              {texts.map((item: any, idx: any) => {
                return (
                  <div key={idx} className={classes.wide}>
                    <Typography variant="h6" className={classes.header}>
                      Save this card
                    </Typography>
                    <Typography variant="subtitle2" className={classes.subtitle}>{`${idx + 1} of 3`}</Typography>
                    <Typography variant="body1" className={classes.text}>
                      {item}
                    </Typography>
                  </div>
                );
              })}
            </SliderResponsive>
          </Box>
        </Container>
      </div>
    </>
  );
};

export default connect((state: IReduxState) => ({ card: state.cardVersionReducer.publicVersion }), { getCardByUrl })(
  SaveCardPage
);
