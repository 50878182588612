import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { white } from 'themes/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hidden: {
      visibility: 'hidden',
    },
    stepIconDef: {
      color: white,
      '&:disabled': {
        color: 'rgba(255, 255, 255, 0.26)',
      },
    },
    defStepper: {
      padding: theme.spacing(0),
      '& .MuiMobileStepper-dot': {
        width: 10,
        height: 10,
        backgroundColor: 'rgba(255, 255, 255, 0.26)',
        margin: '0 5px',
      },
      '& .MuiMobileStepper-dotActive': {
        backgroundColor: white,
      },
    },
  })
);
