import { IUser, IAccount } from 'modules/auth/interfaces/auth';
import { ISubscription } from 'modules/billing/graphql/gql';
import { ID } from 'modules/core/types';
import moment from 'moment';
import uuid from 'uuid';

export class Subscription implements ISubscription {
  public amount = 0;
  public autoRenew = false;
  public currency = '';
  public currentPeriodEnd = 0;
  public currentPeriodStart = 0;
  public description = '';
  public id: ID = -1;
  public hostedInvoiceUrl!: string;
  public invoiceNumber!: string;

  public invoiceId!: string;
  public planId!: string;
  public slotNumber: number = 0;
  public status: string = '';
  public subscriptionId!: string;
  public user: Partial<IUser> = {};
  public account: Partial<IAccount> = {};

  // tslint:disable-next-line: variable-name
  private _reactUUID = uuid();

  get renewStatus(): 'Off' | 'On' {
    return this.autoRenew ? 'On' : 'Off';
  }

  set renewStatus(val: 'Off' | 'On') {
    this.autoRenew = val === 'On' ? true : false;
  }

  get reactUUID(): string {
    return this._reactUUID;
  }

  get start() {
    return this.formatDate(this.currentPeriodStart);
  }

  get expiry() {
    return this.formatDate(this.currentPeriodEnd);
  }

  get ccy() {
    return this.currency.toUpperCase();
  }

  get formatAmount(): string {
    const intlNumber = new Intl.NumberFormat('en-US', { style: 'currency', currency: this.currency.toUpperCase() });
    return intlNumber.format(this.amount / 100);
  }

  get statusFormatted(): string {
    const status = this.status.replace(/_/, ' ');
    return status.charAt(0).toUpperCase() + status.slice(1);
  }

  constructor(subscription?: ISubscription) {
    if (subscription) {
      this.amount = subscription.amount;
      this.autoRenew = subscription.autoRenew;
      this.currency = subscription.currency;
      this.currentPeriodEnd = Number(subscription.currentPeriodEnd);
      this.currentPeriodStart = Number(subscription.currentPeriodStart);
      this.description = subscription.description || '';
      this.id = subscription.id;
      this.planId = subscription.planId || '';
      this.invoiceId = subscription.invoiceId;
      this.slotNumber = subscription.slotNumber;
      this.status = subscription.status;
      this.subscriptionId = subscription.subscriptionId;
      this.user = subscription.user;
      this.hostedInvoiceUrl = subscription.hostedInvoiceUrl;
      this.invoiceNumber = subscription.invoiceNumber;
      this.account = subscription.account;
    }
  }

  public nextState() {
    return new Subscription(this.toJSON());
  }

  public toJSON(): ISubscription {
    return {
      amount: this.amount,
      autoRenew: this.autoRenew,
      currency: this.currency,
      currentPeriodEnd: this.currentPeriodEnd,
      currentPeriodStart: this.currentPeriodStart,
      description: this.description,
      id: this.id,
      planId: this.planId,
      invoiceId: this.invoiceId,
      slotNumber: this.slotNumber,
      status: this.status,
      subscriptionId: this.subscriptionId,
      user: this.user,
      hostedInvoiceUrl: this.hostedInvoiceUrl,
      invoiceNumber: this.invoiceNumber,
      account: this.account,
    };
  }

  private formatDate(date: number) {
    const parseDate = moment.unix(date);
    const day = moment(parseDate).date();
    const stringMonth = moment(parseDate).format('MMM');
    const year = moment(parseDate).year();
    const newDate = `${day} ${stringMonth.slice(0, 3)}, ${year}`;
    return newDate;
  }
}
