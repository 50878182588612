import { Dispatch } from 'redux';
import axios from 'axios';
import { actions } from 'modules/core/reducers/generalReducer';
import { HOST_URL } from '../constants/config';

export interface IL10nData {
  currency: string;
  vat: boolean;
}

export function getCurrency() {
  return async (dispatch: Dispatch) => {
    try {
      const res = await axios.get<IL10nData>(`${HOST_URL}/currency`, { withCredentials: false });
      dispatch(actions.setL10nData(res.data));
    } catch (error) {
      console.warn(`Can't get data of l10n`);
    }
  };
}
