export enum E_AUTH_MODE {
  EMAIL,
  PHONE,
}

export enum E_ACCOUNT_TYPE {
  Member = 'Member',
  Admin = 'Admin',
  Reseller = 'Reseller',
  Owner = 'Owner',
  Unknown = 'Unknown',
}
