import Favicon192 from 'assets/favicon/android-chrome-192x192.png';
import Favicon512 from 'assets/favicon/android-chrome-512x512.png';
import { FontStyleOption, FontWeight } from 'modules/core/types';
import { E_CARD_STEPS } from 'modules/editor/constants/enums';
import { CSSProperties, useMemo } from 'react';
import { initialDarkGray } from 'themes/colors';
import { ICardVersion, ICardStyle } from 'modules/editor/graphql/gql';

export const fontWeight = (style?: FontStyleOption): FontWeight =>
  style ? (style.toLowerCase() as FontWeight) : 'normal';

/**
 * Creating styles of card links from user settings
 * @param cardStyle card style object
 */
export function useUserStylesOfLinks(cardStyle: ICardStyle): CSSProperties {
  return useMemo(() => ({ color: cardStyle.primaryLinksHexColour }), [cardStyle.primaryLinksHexColour]);
}

/**
 * Creating styles of card icons from user settings
 * @param cardStyle card style object
 */
export function useUserStyleOfIcon(cardStyle: ICardStyle): CSSProperties {
  return useMemo(() => ({ color: cardStyle.secondaryIconsHexColour }), [cardStyle.secondaryIconsHexColour]);
}

/**
 * Creating styles of card titles from user settings
 * @param cardStyle card style object
 */
export function useUserStyleOfTitles(cardStyle: ICardStyle): CSSProperties {
  return useMemo(
    () => ({
      color: cardStyle.titleFontHexColour as string,
      fontStyle: cardStyle.titleFontStyle ? cardStyle.titleFontStyle!.toLowerCase() : cardStyle.titleFontStyle,
      fontFamily: cardStyle.titleFont,
      fontWeight: fontWeight(cardStyle.titleFontStyle),
    }),
    [cardStyle.titleFontHexColour, cardStyle.titleFont, cardStyle.titleFontStyle]
  );
}

/**
 * Creating styles of card fonts/colors from user settings
 * @param cardStyle card style object
 */
export function useUserStyleOfBody(cardStyle: ICardStyle): CSSProperties {
  return useMemo(
    () => ({
      color: cardStyle.bodyFontHexColour || initialDarkGray,
      fontStyle: cardStyle.bodyFontStyle ? cardStyle.bodyFontStyle!.toLowerCase() : cardStyle.bodyFontStyle,
      fontFamily: cardStyle.bodyFont,
      fontWeight: fontWeight(cardStyle.bodyFontStyle),
    }),
    [cardStyle.bodyFontHexColour, cardStyle.bodyFont, cardStyle.bodyFontStyle]
  );
}

/**
 * Creating styles of card tagline from user settings
 * @param cardStyle card style object
 */
export function useUserStyleOfTag(cardStyle: ICardStyle): CSSProperties {
  return useMemo(
    () => ({
      color: cardStyle.taglineHexColour as string,
      fontStyle: cardStyle.bodyFontStyle ? cardStyle.bodyFontStyle!.toLowerCase() : cardStyle.bodyFontStyle,
      fontFamily: cardStyle.bodyFont,
      fontWeight: fontWeight(cardStyle.bodyFontStyle),
    }),
    [cardStyle.taglineHexColour, cardStyle.bodyFont, cardStyle.bodyFontStyle]
  );
}

/**
 * Creating styles of card style business name line from user settings
 * @param cardStyle card style object
 */
export function useUserStyleOfBusiness(cardStyle: ICardStyle): CSSProperties {
  return useMemo(
    () => ({
      color: cardStyle.businessNameHexColour as string,
      fontStyle: cardStyle.bodyFontStyle ? cardStyle.bodyFontStyle!.toLowerCase() : cardStyle.bodyFontStyle,
      fontFamily: cardStyle.bodyFont,
      fontWeight: fontWeight(cardStyle.bodyFontStyle),
    }),
    [cardStyle.businessNameHexColour, cardStyle.bodyFont, cardStyle.bodyFontStyle]
  );
}

/**
 * Return Record block is display or not
 * @param cardVer card object
 * @returns Record<E_CARD_STEP, boolean>
 */
export function useDisplayState(cardVer: ICardVersion): { [key: string]: boolean } {
  const { newsSection, videoSection, locationSection, aboutSection, promotionSection, gallerySection } = cardVer;
  return useMemo(
    () => ({
      [E_CARD_STEPS.ABOUT]: Boolean(
        aboutSection.description ||
          aboutSection.title ||
          aboutSection.websiteUrl ||
          aboutSection.aboutSectionLinks.length
      ),
      [E_CARD_STEPS.PROMOTION]: Boolean(
        promotionSection.linkText ||
          promotionSection.linkUrl ||
          promotionSection.linkUrlShow ||
          promotionSection.promotionImageUrl ||
          promotionSection.title
      ),
      [E_CARD_STEPS.NEWS]: Boolean(
        newsSection.linkText || newsSection.linkUrl || newsSection.newsImageUrl || newsSection.title
      ),
      [E_CARD_STEPS.VIDEO]: Boolean(videoSection.linkUrl || videoSection.title),
      [E_CARD_STEPS.GALLERY]: Boolean(gallerySection.title || gallerySection.gallerySectionImages.length),
      [E_CARD_STEPS.LOCATION]: Boolean(
        locationSection.city ||
          locationSection.country ||
          locationSection.postcode ||
          locationSection.state ||
          locationSection.streetAddress ||
          locationSection.streetAddress2 ||
          locationSection.openingTimes.length
      ),
    }),
    [newsSection, videoSection, locationSection, aboutSection, promotionSection, gallerySection]
  );
}

/**
 * Create url to manifest.json for add to page
 * @param fullName card holder name
 * @param cardUrl card url
 * @param icon512 card icon image
 */
export function useManifest(fullName: string, cardUrl: string, icon512?: string) {
  return useMemo(() => {
    const manifest = {
      start_url: `${window.location.protocol}//${window.location.hostname}/${cardUrl}`,
      name: fullName,
      short_name: fullName,
      icons: [
        {
          src: icon512 || Favicon192,
          sizes: '192x192',
          type: 'image',
        },
        {
          src: icon512 || Favicon512,
          sizes: '512x512',
          type: 'image',
        },
      ],
      theme_color: '#ffffff',
      background_color: '#ffffff',
      display: 'browser',
    };
    const stringManifest = JSON.stringify(manifest);
    const blob = new Blob([stringManifest], { type: 'application/json' });
    return URL.createObjectURL(blob);
  }, [cardUrl, icon512, fullName]);
}
