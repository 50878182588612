import { IResellerNetwork, IAccountNetworkNode } from 'modules/dashboard/graphql/gql';

export class ResellerNetwork implements IResellerNetwork {
  public resellers: IAccountNetworkNode[] = [];
  public memberCount = 0;
  public levels = 1;
  public totalResellerCount = 0;
  public pages = 0;
  public page = 0;
  public countForPage = 0;

  constructor(data?: IResellerNetwork) {
    if (data) {
      this.resellers = data.resellers || [];
      this.memberCount = data.memberCount;
      this.levels = data.levels;
      this.totalResellerCount = data.totalResellerCount;
      this.pages = data.pages;
      this.page = data.page;
      this.countForPage = data.countForPage;
    }
  }
}
