import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { lightGreen } from 'themes/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
    },
    filler: {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    clear: {
      position: 'absolute',
      right: 0,
      top: -30,
      textTransform: 'uppercase',
      fontSize: 12,
      color: lightGreen,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    clearIcon: {
      fontSize: 18,
      paddingBottom: 1,
    },
    disabled: {
      zIndex: 999,
    },
    root: {
      width: '100%',
      '& .Mui-error.MuiFilledInput-root': {
        backgroundColor: 'rgba(241,244,245,0.5)',
      },
      '&.dark': {
        '& .Mui-error.MuiFilledInput-root': {
          backgroundColor: '#393e44',
        },
      },
      '&._green': {
        '&> :last-child:after': {
          borderBottomColor: 'rgba(255,255,255,0.4)',
        },
        '&> :last-child::before': {
          borderBottomColor: 'rgba(255,255,255,0.4)',
        },
        '&> :last-child': {
          borderRadius: 0,
          background: 'rgba(255,255,255,0.1)',

          '& input': {
            color: 'white',
          },
        },
        '& textarea': {
          color: 'white',
          minHeight: 168,
          lineHeight: theme.typography.pxToRem(24),
        },
        '& label': {
          fontFamily: 'F37 Ginger Light',
          color: 'white',
          fontSize: 14,
          lineHeight: theme.typography.pxToRem(24),
          fontWeight: 200,
        },
        '& label.Mui-focused': {
          color: 'white',
        },
        '& input': {
          '&::placeholder': {
            color: 'white',
            opacity: 1,
          },
        },
        '& .MuiFormLabel-root': {
          fontSize: 14,
          fontWeight: 300,
          fontFamily: 'F37 Ginger Light',
          color: 'white',
        },
      },
    },
    disabledSecond: {
      '& input': {
        color: '#9fa2a5',
      },
    },
  })
);
