import React, { useState, useEffect, useCallback } from 'react';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from './Menu';
import { useStyles } from './styles';
import { IReduxState } from 'store';
import { connect } from 'react-redux';
import { getCardVersions } from 'modules/editor/api/card-query';
import { startSwitchCardVersion } from 'modules/editor/api/card-mutations';
import { ICardVersion } from 'modules/editor/graphql/gql';
import { actions } from 'modules/editor/reducers/cardReducer';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { getCurrentVersion } from 'modules/editor/api/card-query';
import { OverlayProgress } from '../progress/OverlayProgress';
import { useTypedSelector } from 'hooks/useTypedSelector';
import CardProcessingStatusDialog from 'modules/core/components/dialogs/CardProcessingStatusDialog';
import CardProcessingErrorDialog from 'modules/core/components/dialogs/CardProcessingErrorDialog';
import { E_ACCOUNT_TYPE } from 'modules/auth/constants/enums';

interface IMenuAppBar {
  versions: ICardVersion[];
  selectedVersion: Partial<ICardVersion>;
  showSwitchCardFlow: boolean;
  getCardVersions: () => Promise<void>;
  startSwitchCardVersion: (verId: number) => Promise<void>;
  setSelectedVersion: (ver: Partial<ICardVersion>) => void;
  setShowSwitchCardFlow: (isProcessing: boolean) => void;
  getCurrentVersion: () => Promise<ICardVersion>;
}

const MenuAppBar: React.FC<IMenuAppBar> = ({
  versions,
  selectedVersion,
  getCardVersions: getVersions,
  startSwitchCardVersion,
  setSelectedVersion,
  showSwitchCardFlow,
  setShowSwitchCardFlow,
  getCurrentVersion,
}) => {

  const profile = useTypedSelector((state) => state.auth.profile);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const menuAnchor = React.createRef<any>();

  const [showCardProcessingStatusModal, setShowCardProcessingStatusModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showErrorMessageModal, setShowErrorMessageModal] = useState(false);
  const closeErrorMessageModal = () => setShowErrorMessageModal(false);

  useEffect(() => {
    if (!versions.length) {
      getVersions();
    }
  }, [getVersions, versions]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(menuAnchor.current!.parentElement);
  };

  // Core processing logic

  useEffect(() => {
    if (showSwitchCardFlow) {
      // showing the cardProcessingModal will subscribe the user to card processing updates
      setShowCardProcessingStatusModal(true);
    }
  }, [showSwitchCardFlow, setShowCardProcessingStatusModal]);

  const handleProcessingError = useCallback((error?: string) => {
    const stringError = JSON.stringify(error) || "";

    setShowCardProcessingStatusModal(false);
    setErrorMessage(stringError);
    setShowErrorMessageModal(true);
    setShowSwitchCardFlow(false);
  }, [
    setShowCardProcessingStatusModal,
    setErrorMessage,
    setShowErrorMessageModal,
    setShowSwitchCardFlow,
  ]);

  const startSwitchCard = useCallback(async (cardVer: Partial<ICardVersion>) => {
    console.log("startSwitchCard called");
    setShowSwitchCardFlow(true);
    setSelectedVersion(cardVer);

    await startSwitchCardVersion(cardVer.id as number)
      .catch((error) => handleProcessingError(error));
  }, [
    setShowSwitchCardFlow,
    setSelectedVersion,
    startSwitchCardVersion,
    handleProcessingError,
  ]);

  const finishSwitchCard = useCallback(async (errorMessage?: string) => {
    if (errorMessage) {
      handleProcessingError(errorMessage);
    } else {
      console.log("switchCard started");
      await getCurrentVersion();
      await setSelectedVersion({});

      setShowCardProcessingStatusModal(false);
      setShowSwitchCardFlow(false);
      console.log("switchCard succeeded");
    };
  }, [
    getCurrentVersion,
    setShowSwitchCardFlow,
    handleProcessingError,
    setSelectedVersion,
  ]);


  // click handlers


  const handleDropDownClose = useCallback(
    async (selectable: boolean, cardVer?: Partial<ICardVersion>) => {
      setAnchorEl(null);

      if (cardVer && selectable) {
        await startSwitchCard(cardVer);
      }
    },
    [startSwitchCard]
  );

  const DropdownItem: React.FC<{ cardVer: Partial<ICardVersion>; selectable: boolean }> = ({ cardVer, selectable }) => {
    if (cardVer.publishedTime) {
      return (
        <MenuItem className={classes.item} onClick={() => handleDropDownClose(selectable, cardVer)}>
          Published&nbsp;{moment(cardVer.publishedTime).format('HH:mm - DD MMM YYYY')}
        </MenuItem>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={classes.root} ref={menuAnchor}>
      <Button onClick={handleMenu} className={classes.titleButton}>
        {versions.length > 1 ? 'Edit your ' + (profile.type !== E_ACCOUNT_TYPE.Reseller ? 'card' : 'details') : (profile.type !== E_ACCOUNT_TYPE.Reseller ? 'Create your card' : 'Add your details')}
      </Button>
      <div>
        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
          className="mobile-menu__dropdown-button"
        >
          <KeyboardArrowDownIcon />
        </IconButton>
        <Menu
          className={classes.dropDown}
          classes={{ paper: classes.paper }}
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={open}
          onClose={() => handleDropDownClose(false)}
        >
          <div className={classes.head} onClick={() => handleDropDownClose(false)}>
            <div>
              <span>Select version</span>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => handleDropDownClose(false)}
                className={classes.buttonInner}
                color="inherit"
              >
                <KeyboardArrowDownIcon />
              </IconButton>
            </div>
          </div>
          <div className={classes.versions}>
            <MenuItem className={classes.item} onClick={() => handleDropDownClose(false)}>
              Current draft
            </MenuItem>

            {versions.map((v) => (
              <DropdownItem key={v.id} cardVer={v} selectable={true} />
            ))}
          </div>
        </Menu>
      </div>

      <CardProcessingStatusDialog
        show={showCardProcessingStatusModal}
        onProcessingFinish={finishSwitchCard}
        cardVersionId={selectedVersion.id as number}
        processAction="switch"
      />

      <CardProcessingErrorDialog
        show={showErrorMessageModal}
        errorMessage={errorMessage}
        onClose={closeErrorMessageModal}
        dialogId="switch_version_error_message"
      />

      <OverlayProgress isProgress={showSwitchCardFlow} />
    </div>
  );
};

export default connect(
  (state: IReduxState) => ({
    versions: state.currentVersion.versions,
    selectedVersion: state.currentVersion.selectedVersion,
    showSwitchCardFlow: state.currentVersion.showSwitchCardFlow,
  }),
  {
    getCardVersions,
    startSwitchCardVersion,
    setSelectedVersion: actions.setSelectedVersion,
    setShowSwitchCardFlow: actions.setShowSwitchCardFlow,
    getCurrentVersion,
  }
)(MenuAppBar);
