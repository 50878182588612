import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    menu: {
      [theme.breakpoints.between('xs', 'sm')]: {
        position: 'sticky',
        top: 0,
        zIndex: 3,
      },
    },
  })
);

export const cwsStyles = (theme: Theme) => ({
  root: {
    padding: '32px 0',
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: theme.spacing(2),
    },
  },
  accordeonPanel: {
    '&.MuiExpansionPanelDetails-root': {
      padding: 0,
    },
  },
});
