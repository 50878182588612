import { useEffect, useState } from 'react';
import { ID } from 'modules/core/types';

/**
 * Creates global listner for external resourses.
 * @returns isLoading
 */
export function useIsLoading(resourceId: ID): boolean {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (resourceId > 0) {
      const timer = setTimeout(() => setIsLoading(false), 1500);
      return () => clearTimeout(timer);
    }
  }, [resourceId]);

  return isLoading;
}
