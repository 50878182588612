import React, { FC } from 'react';
import { constructLink } from 'modules/core/utils';
import { useStyles } from '../useStyles';

interface IProps {
  value: string;
  url?: string;
}

export const Description: FC<IProps> = (props) => {
  const { value, url } = props;
  const classes = useStyles();

  return (
    <>
      <div className={classes.gradient} />
      <div className={classes.imgDescr}>
        <span className={classes.descrConatiner}>
          {url ? (
            <a target="_blank" rel="noopener noreferrer nofollow" href={constructLink(url)} className={classes.link}>
              {value}
            </a>
          ) : (
            value
          )}
        </span>
      </div>
    </>
  );
};
