import { useState, useEffect } from 'react';
import { STRIPE_SECRET_KEY } from 'modules/core/constants/config';

type stripeValue = stripe.Stripe | null;

export function useStripe(): stripeValue {
  const [stripe, setStripe] = useState<stripeValue>(null);

  function onSetKey(set: (key: string) => stripe.Stripe): void {
    if (STRIPE_SECRET_KEY) {
      setStripe(set(STRIPE_SECRET_KEY));
    }
  }

  useEffect(() => {
    if (window.Stripe) {
      onSetKey(window.Stripe);
    } else {
      const node = document.querySelector('#stripe-js');
      if (node) {
        node.addEventListener('load', () => {
          if (window.Stripe) {
            onSetKey(window.Stripe);
          }
        });
      }
    }
  }, []);

  return stripe;
}
