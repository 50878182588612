import React, { useMemo, useCallback, useState } from 'react';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { InputAdornment, MenuItem, Button, Box, Menu } from '@material-ui/core';
import countryList from 'react-select-country-list';
import { InputField } from 'modules/core/components/fields';
import { useStyles } from './styles';
import ReactCountryFlag from 'react-country-flag';
import { countries } from 'modules/core/constants/countries'

interface ICountry {
  code: string;
  name: string;
}

interface IProps {
  name: string;
  value: string;
  onChange: (country: ICountry) => void;
  error: string;
}

export const SelectCountry: React.FC<IProps> = ({ value, name, error, onChange }) => {
  const classes = useStyles();
  const [isOpen, setOpen] = useState<null | HTMLElement>(null);
  const [country, setCountry] = useState<ICountry>({ code: value || '', name: countryList().getLabel(value) || '' });

  const flagsStyles = useMemo(
    () => ({
      fontSize: '1.5em',
      lineHeight: '2em',
    }),
    []
  );

  const COUNTRY_SELECT_OPTIONS = useMemo(
    () =>
      countries()
        .map((c) => ({
          name: c.label,
          label: (
            <>
              <ReactCountryFlag svg countryCode={c.value} styles={flagsStyles} aria-label={c.label} />
              <Box component="span" ml={2}>
                {c.label}
              </Box>
            </>
          ),
          value: c.value,
        })),
    [flagsStyles]
  );

  const itemClick = useCallback(
    (c: ICountry) => {
      setOpen(null);
      setCountry(c);
      if (onChange) {
        onChange(c);
      }
    },
    [setOpen, onChange]
  );

  return (
    <Box className={classes.wrapper}>
      <InputField
        placeholder="Country issued"
        label="Country issued"
        name={name}
        error={!!error}
        InputLabelProps={{
          style: {
            marginLeft: '75px',
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment className={classes.selectorAdoment} position="start">
              <Button name={`button_${name}`} className={classes.button} onClick={(e) => setOpen(e.currentTarget)}>
                <Box mx={1}>
                  {value ? (
                    <ReactCountryFlag svg countryCode={value} style={flagsStyles} />
                  ) : (
                    <Box width="21px" height="21px" />
                  )}
                </Box>
                {!!isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </Button>
              <Menu anchorEl={isOpen} open={!!isOpen} onExit={() => setOpen(null)} onClose={() => setOpen(null)}>
                {COUNTRY_SELECT_OPTIONS.map((c: any) => (
                  <MenuItem key={c.value} id={c.value} onClick={() => itemClick({ code: c.value, name: c.name })}>
                    {c.label}
                  </MenuItem>
                ))}
              </Menu>
            </InputAdornment>
          ),
        }}
        value={country.name}
        variant="filled"
        exClass="_white _buy"
        disabled={true}
      />
    </Box>
  );
};
