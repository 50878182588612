import React, { useState, useCallback, useEffect } from 'react';
import {
  Contact,
  FinishScreen,
  ProcessingPayment,
  SelectCount,
  SelectPlan,
  Summary,
  Wait,
} from 'modules/billing/components/Billing';
import { PayDataStripeInjected as PayData } from './PayData/StripeInjected';
import CssGreenButton from 'modules/core/components/buttons/roundGreenButton';
import CircularIndeterminate from 'modules/core/components/progress';
import { useHistory } from 'react-router';
import {
  createSubscription,
  getSummaryInfo,
  userSubscriptions,
  getPlans,
  handleInitialSubscriptionFail,
  updateSubscriptionStatus,
} from 'modules/billing/api/billing';
import { isArray } from 'util';
import { IPreviewSubscriptionSummary } from 'modules/billing/graphql/gql';
import { Plan } from 'modules/billing/models/plan';
import { SetState } from 'types/helpers';
import { MaxSteps } from '..';
import { reloadProfile } from 'modules/auth/thunks/auth';
import { useThunkDispatch } from 'hooks/useThunkDispatch';
import { actions } from 'modules/auth/reducers/authReducer';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import { useStyles } from 'modules/billing/components/Billing';
import { EDITOR_ROUTE } from 'routes/routes';
import { ICreateSubscriptionResponse } from 'modules/billing/utils';
import { useStripe } from 'modules/billing/components/BuyPage/useStripe';

interface IProps {
  success: boolean;
  isPAO: boolean;
  disabled: boolean;
  setProcessing: SetState<boolean>;
  setStep: SetState<number>;
  setMaxSteps: SetState<MaxSteps>;
  setPAO: SetState<boolean>;
  setSuccess: SetState<boolean>;
  step: number;
  setDisabled: SetState<boolean>;
  isProcessing: boolean;
}

export const Steps: React.FC<IProps> = (props) => {
  const {
    success,
    isPAO,
    disabled,
    setProcessing,
    setStep,
    setMaxSteps,
    setPAO,
    setSuccess,
    step,
    setDisabled,
    isProcessing,
  } = props;
  const history = useHistory();
  const [paymentMethodId, setPaymentMethodId] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [plan, setPlan] = useState<Plan | null>(null);
  const [summary, setSummary] = useState<IPreviewSubscriptionSummary | null>(null);
  const [contactType, setContactType] = useState<'phone' | 'email'>();
  const [errMsg, setErrMsg] = useState<string | undefined>();
  const [plans, setPlans] = useState<Plan[]>([]);
  const [redirect, setRedirect] = useState<boolean>(true);
  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();
  const classes = useStyles();
  const stripe = useStripe();

  const errHandler = useCallback(
    (useRedirect: boolean) => {
      setRedirect(useRedirect);
      setSuccess(false);
      setStep(4);
      setDisabled(false);
    },
    [setSuccess, setStep, setRedirect, setDisabled]
  );

  useEffect(() => {
    getPlans()
      .then((p) => setPlans(p))
      .catch(() => errHandler(true));
  }, [errHandler, setPlans]);

  const toRequisites = () => {
    if (!redirect) {
      setSummary(null);
      setPaymentMethodId('');
      setStep(2);
    }
  };

  const planHandler = (id: string) => {
    const fPlan = plans.find((p) => p.id === id) as Plan;
    setPlan(fPlan);
    setStep(1);
  };

  const paoHandler = (plainId: boolean) => {
    if (plainId) {
      setMaxSteps(3);
      setPAO(true);
      setStep(1);
    } else {
      setPAO(false);
      setMaxSteps(5);
    }
  };

  const countHandler = useCallback(
    (count: number) => {
      setQuantity(count);
      setStep(2);
    },
    [setQuantity, setStep]
  );

  const contactHandler = (type: 'phone' | 'email') => {
    setContactType(type);
    setSuccess(true);
    setStep(2);
  };

  const pmHandler = useCallback(
    async (pm: string) => {
      if (step === 2) {
        setPaymentMethodId(pm);
        setSummary(null);
        setStep(3);
        setDisabled(false);
        setProcessing(true);
        try {
          const summaryInf = await getSummaryInfo({
            paymentMethodId,
            planId: plan!.id,
            couponId: plan!.coupon ? plan!.coupon.id : undefined,
            quantity,
          });
          setSummary(summaryInf);
          setDisabled(false);
        } catch (error) {
          errHandler(false);
        }
        setProcessing(false);
      }
    },
    [setPaymentMethodId, setStep, errHandler, paymentMethodId, plan, quantity, step, setDisabled, setProcessing]
  );

  const confirmHandler = async () => {
    try {
      setRedirect(true);
      setDisabled(true);
      setProcessing(true);
      const res: ICreateSubscriptionResponse | undefined = await createSubscription({
        couponId: plan!.coupon ? plan!.coupon.id : undefined,
        paymentMethodId,
        planId: plan!.id,
        quantity,
      });

      if (res) {
        if (res.success) {
          finishPaymentAttempt(() => afterSuccessfulPayment(4))
        } else if (res.scaSecret) {
          stripe!
            .confirmCardPayment(res.scaSecret, {
              payment_method: paymentMethodId,
              setup_future_usage: "off_session",
            })
            .then((result) => {
              if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
                updateSubscriptionStatus(res.subscription!.subscriptionId)
                  .then(() => finishPaymentAttempt(() => afterSuccessfulPayment(4)));
              } else if (result.error) {
                if (res.subscription) {
                  handleInitialSubscriptionFail(res.subscription.subscriptionId);
                }

                finishPaymentAttempt(() => afterUnsuccessfulPayment(result.error!.message));
              }
            })
            .catch((error) => console.warn(error));
        }
      }
    } catch (error) {
      const errorMessage = isArray(error) && error[0];
      finishPaymentAttempt(() => afterUnsuccessfulPayment(errorMessage));
    }
  };

  const finishPaymentAttempt = (callback: () => any) => {
    Promise.resolve()
      .then(callback)
      .then(afterPaymentAttempt);
  };

  const afterSuccessfulPayment = (step: number) => {
    setSuccess(true);
    setProcessing(false);
    setDisabled(false);
    setStep(step);
  };

  const afterUnsuccessfulPayment = (error?: string) => {
    setErrMsg(error);
    setProcessing(false);
    errHandler(false);
  };

  const afterPaymentAttempt = async () => {
    try {
      const subs = await userSubscriptions();
      dispatch(actions.setSubscription(subs));
      thunkDispatch(reloadProfile());
    } catch (error) {
      console.warn(error);
    }
  };

  if (isPAO) {
    switch (step) {
      case 0:
        return (
          <Wait wait={!plans.length}>
            <SelectPlan
              selectedPlan={plan ? plan!.id : undefined}
              plans={plans}
              next={planHandler}
              pao={paoHandler}
              disabled={disabled}
            />
          </Wait>
        );
      case 1:
        return <Contact next={contactHandler} err={errHandler} />;
      case 2:
        return (
          <FinishScreen
            type={contactType}
            success={success}
            subtitle="We will contact you shortly"
            subtitleErr="Try again later"
            title={contactType === 'email' ? 'Email request received!' : 'Call request received!'}
            titleErr="Error!"
            timerCb={() => redirect && history.push('/editor')}
          />
        );
      default:
        return null;
    }
  } else {
    switch (step) {
      case 0:
        return (
          <Wait wait={!plans.length}>
            <SelectPlan
              selectedPlan={plan ? plan!.id : undefined}
              plans={plans}
              next={planHandler}
              pao={paoHandler}
              disabled={disabled}
            />
          </Wait>
        );
      case 1:
        return (
          <SelectCount
            vat={plan!.vat}
            ccy={plan!.currency}
            next={countHandler}
            planName={plan!.nickname as string}
            maxCount={plan!.metadata.upToMax as number}
            price={Number(plan!.amount)}
            selectedCount={quantity}
          />
        );
      case 2:
        return <PayData next={pmHandler} err={errHandler} disabled={disabled} />;
      case 3:
        return (
          <>
            {summary && !isProcessing ? (
              <Summary
                ccy={plan!.currency}
                disabled={disabled}
                count={quantity}
                percent={Number(summary!.vatPercentage)}
                total={Number(summary!.total)}
                vat={Number(summary!.vatAmount)}
                next={confirmHandler}
              />
            ) : (
              <>
                {isProcessing ? (
                  <ProcessingPayment />
                ) : (
                  <Box className={classes.centerSpinner}>
                    <CircularIndeterminate />
                  </Box>
                )}
              </>
            )}
          </>
        );
      case 4:
        return (
          <Box>
            <FinishScreen
              title="Success!"
              titleErr="Error!"
              subtitle="Your payment has been accepted"
              subtitleErr={errMsg || 'Try again later'}
              success={success}
              timerCb={() => toRequisites()}
            />
            {redirect && success ? (
              <Box justifyContent="center" display="flex">
                <CssGreenButton
                  id="sucess_buy_create_your_card_btn"
                  disabled={disabled}
                  onClick={() => history.push(EDITOR_ROUTE.path)}
                >
                  CREATE YOUR CARD
                </CssGreenButton>
              </Box>
            ) : null}
          </Box>
        );
      default:
        return null;
    }
  }
};
