import { Theme } from '@material-ui/core/styles';
import { brightGrey, darkGrey, grey, steelGrey, white } from 'themes/colors';

export const styles = (theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  menu: {
    borderRight: `1px solid ${brightGrey}`,
    marginLeft: '5%',
    paddingRight: '1rem',
    minWidth: '250px',
  },
  content: {
    flex: 1,
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 6),
    },
  },
});

export const menuItemStyles = () => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '1rem',
    cursor: 'pointer',
    color: grey,
    '&:hover': {
      color: white,
    },
    '&.active': {
      color: white,
    },
  },
  button: {
    boxShadow: '0px 1px 3px rgba(0,0,0,0.2)',
    height: 35,
    minWidth: 'unset',
    width: 35,
    outline: 'none',
    background: steelGrey,
    border: `1px solid ${brightGrey}`,
    padding: 0,
    '.active &': {
      backgroundColor: white,
      color: darkGrey,
    },
  },
});
