import React, { useRef, useEffect, lazy, Suspense } from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Container } from '@material-ui/core';
import clsx from 'clsx';
import {
  AboutSection,
  Header,
  LocationSection,
  NewsSection,
  PromotionSection,
  VideoSection,
  QRCodeSection,
} from 'modules/editor/components/CardSectionsView';
import CardWrapper from 'modules/editor/components/CardWrapper';
import {
  useDisplayState,
  useUserStyleOfBody,
  useUserStyleOfBusiness,
  useUserStyleOfIcon,
  useUserStyleOfTag,
  useUserStyleOfTitles,
  useUserStylesOfLinks,
} from '../functions';
import { useStyles } from './useStyles';
import { MainInfo } from 'modules/editor/components/CardSectionsView/MainInfo';
import { GallerySectionView } from 'modules/editor/components/CardSectionsView/GallerySectionView';
import { useUserBackground } from 'modules/editor/containers/Card/hooks/useUserBackground';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { ICardView } from 'modules/editor/interfaces';
import { E_CARD_STEPS, E_PREVIEW_TYPE } from 'modules/editor/constants/enums';

const Footer = lazy(() => import('modules/editor/components/CardSectionsView/Footer'));

const { BRANDING, STYLING, PERSONAL_DETAILS, SOCIAL_LINKS } = E_CARD_STEPS;

const mainSteps: E_CARD_STEPS[] = [BRANDING, STYLING, PERSONAL_DETAILS, SOCIAL_LINKS];

export const RetailCard: React.FC<ICardView> = (props) => {
  const { isPreview = false, previewType = E_PREVIEW_TYPE.NONE, modifier, cardVer } = props;
  const {
    newsSection,
    videoSection,
    locationSection,
    aboutSection,
    promotionSection,
    gallerySection,
    cardStyle,
    card
  } = cardVer;

  const classes = useStyles();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { step } = useTypedSelector((state) => state.editorSteps.currentStep);

  const backgroundStyle = useUserBackground(cardStyle);
  const linkStyle = useUserStylesOfLinks(cardStyle);
  const iconStyle = useUserStyleOfIcon(cardStyle);
  const titleStyle = useUserStyleOfTitles(cardStyle);
  const bodyStyle = useUserStyleOfBody(cardStyle);
  const tagStyle = useUserStyleOfTag(cardStyle);
  const businessStyle = useUserStyleOfBusiness(cardStyle);
  const displayState = useDisplayState(cardVer);

  // scroll to the top if one of the main steps
  useEffect(() => {
    if (mainSteps.includes(step)) {
      Promise.resolve().then(() => {
        if (wrapperRef.current) {
          wrapperRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
      });
    }
  }, [step, wrapperRef]);

  return (
    <>
      <div className={clsx(classes.wrapper, isPreview && 'preview', modifier)} style={backgroundStyle} ref={wrapperRef}>
        <Container fixed maxWidth="md" className={classes.contentWrapper}>
          <Header
            tagStyle={tagStyle}
            tagLine={cardVer.tagLine}
            businessName={cardVer.businessName}
            logoUrl={cardVer.logoUrl}
            businessStyle={businessStyle}
          />
          <CardWrapper className={classes.userCard}>
            <MainInfo
              previewType={previewType}
              iconStyle={iconStyle}
              titleStyle={titleStyle}
              bodyStyle={bodyStyle}
              isPreview={isPreview}
              cardVer={cardVer}
            />
          </CardWrapper>
          <AboutSection
            section={aboutSection}
            classes={classes}
            display={displayState[E_CARD_STEPS.ABOUT]}
            bodyStyle={bodyStyle}
            iconStyle={iconStyle}
            linkStyle={linkStyle}
            titleStyle={titleStyle}
            isPreview={isPreview}
            wrapperRef={wrapperRef}
          />
          <PromotionSection
            section={promotionSection}
            classes={classes}
            display={displayState[E_CARD_STEPS.PROMOTION]}
            bodyStyle={bodyStyle}
            iconStyle={iconStyle}
            linkStyle={linkStyle}
            titleStyle={titleStyle}
            isPreview={isPreview}
            wrapperRef={wrapperRef}
          />
          <NewsSection
            section={newsSection}
            classes={classes}
            display={displayState[E_CARD_STEPS.NEWS]}
            bodyStyle={bodyStyle}
            iconStyle={iconStyle}
            linkStyle={linkStyle}
            titleStyle={titleStyle}
            isPreview={isPreview}
            wrapperRef={wrapperRef}
          />
          <VideoSection
            section={videoSection}
            classes={classes}
            isPreview={isPreview}
            display={displayState[E_CARD_STEPS.VIDEO]}
            bodyStyle={bodyStyle}
            iconStyle={iconStyle}
            titleStyle={titleStyle}
            wrapperRef={wrapperRef}
          />
          <GallerySectionView
            section={gallerySection}
            display={displayState[E_CARD_STEPS.GALLERY]}
            bodyStyle={bodyStyle}
            iconStyle={iconStyle}
            titleStyle={titleStyle}
            wrapperRef={wrapperRef}
          />
          <LocationSection
            section={locationSection}
            display={displayState[E_CARD_STEPS.LOCATION]}
            bodyStyle={bodyStyle}
            iconStyle={iconStyle}
            linkStyle={linkStyle}
            titleStyle={titleStyle}
            classes={classes}
            isPreview={isPreview}
            wrapperRef={wrapperRef}
          />
        </Container>
        <div className={classes.footerSection} style={{ width: '100%' }}>
          <QRCodeSection classes={classes} slug={card.urlSlug as string} />
          {isPreview ? null : <Suspense fallback={null}><Footer /></Suspense>}
        </div>
      </div>
    </>
  );
};
