import clsx from 'clsx';
import AuthorizationDialog from 'modules/auth/components/Authorization/AuthorizationDialog';
import { IUserForm } from 'modules/auth/interfaces/auth';
import CssGreenButton from 'modules/core/components/buttons/roundGreenButton';
import { InputField, SelectField } from 'modules/core/components/fields';
import { validatePhone, validatePhoneByType } from 'modules/core/utils/validate';
import { PHONE_ERROR_INT_FORMAT, PHONE_ERROR_MOBILE_TYPE } from 'modules/editor/constants/messages';
import React, { FormEvent, useCallback, useEffect } from 'react';
import { countries } from 'modules/core/constants/countries'
import { useStyles } from './styles';

interface IStepProps {
  bottom: React.ReactElement;
  onSubmit: (e: any) => void;
  filledValues: any;
  onChange: any;
  isNextHidden: any;
  setErrors: any;
  errors?: any;
}

const SignupStep2: React.FC<IStepProps> = ({
  onSubmit,
  bottom,
  filledValues,
  isNextHidden,
  onChange,
  setErrors,
  errors,
}) => {
  const classes = useStyles();

  const validateByProp = useCallback(
    (prop: keyof IUserForm) => {
      if (prop === 'mobilePhone') {
        if (!validatePhone(filledValues[prop])) {
          setErrors(prop, PHONE_ERROR_INT_FORMAT);
        } else if (!validatePhoneByType(filledValues[prop])) {
          setErrors(prop, PHONE_ERROR_MOBILE_TYPE);
        } else {
          setErrors(prop, '');
        }
      }
      if (prop === 'email') {
        setErrors(prop, '');
      }
    },
    [setErrors, filledValues]
  );

  const handleChange = (prop: keyof IUserForm) => (event: React.ChangeEvent<HTMLInputElement>) => {
    let val = '';
    if (event) {
      if (typeof event === 'string') {
        val = event;
      } else if (event.target) {
        val = event.target.value;
      }
      onChange(prop, val);
    }
  };

  // const handlePaste = useCallback(
  //   (prop: keyof IUserForm) => (e: React.ClipboardEvent<HTMLInputElement>) => {
  //     e.preventDefault();
  //     const clipboardValue = e.clipboardData.getData('text');
  //     let processed = '';
  //     if (prop === 'mobilePhone') {
  //       processed = onPhonePaste(clipboardValue as string);
  //     } else {
  //       processed = clipboardValue;
  //     }
  //     onChange(prop, processed);
  //   },
  //   [onChange]
  // );

  const handleContinue = useCallback(
    async (evt: FormEvent) => {
      evt.preventDefault();
      if (onSubmit) {
        onSubmit(filledValues);
      }
    },
    [filledValues, onSubmit]
  );

  // useEffect(() => {
  //   if (filledValues.mobilePhone !== '') {
  //     validateByProp('mobilePhone');
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filledValues.mobilePhone]);

  useEffect(() => {
    if (filledValues.email !== '') {
      validateByProp('email');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filledValues.email]);

  return (
    <AuthorizationDialog
      title="Next, your contact details"
      subtitle="We will use this to verify your account"
      dlgBody={
        <form noValidate onSubmit={handleContinue} className={classes.form}>
          <div className={classes.textField}>
            <InputField
              classes={{ root: classes.textField }}
              variant="filled"
              type="text"
              label="Email address"
              name="sign_up_email"
              value={filledValues.email}
              onChange={handleChange('email')}
              error={errors.email}
            />
          </div>
          {/* <div className={classes.textField}>
            <MobileField
              classes={{ root: classes.fullWidth }}
              inputProps={{
                label: 'Mobile number',
                variant: 'filled',
                value: filledValues.mobilePhone,
                id: 'sign_up_mobile',
                error: !!errors.mobilePhone,
                helperText: errors.mobilePhone || '',
                onPaste: handlePaste('mobilePhone'),
              }}
              onChange={handleChange('mobilePhone')}
            />
          </div> */}
          <div className={clsx(classes.textField, '_select')}>
            <SelectField
              name="sign_up_country"
              value={filledValues.country}
              onChange={handleChange('country')}
              label="Country"
              options={countries()}
            />
          </div>
        </form>
      }
      footer={
        <CssGreenButton
          disabled={isNextHidden}
          id="sign_up_countinue_step_2"
          className={classes.button}
          onClick={handleContinue}
        >
          Continue
        </CssGreenButton>
      }
      bottom={bottom}
    />
  );
};

export default SignupStep2;
