import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';
import { IFieldDescriptor } from 'modules/core/interfaces/table';
import { E_PANEL_STATE, E_USER_STATUS, IInviteUser, ISlotUser } from 'modules/users/interfaces/assigned-users';
import React, { MouseEvent } from 'react';
import { AssignedUserActions } from 'modules/users/components/UsersPage/AssignedUsers/AssignedUser/AssignedUserActions';
import { tableStyles } from 'modules/users/components/UsersPage/AssignedUsers/AssignedUsersTable';
import { useTypedSelector } from 'hooks/useTypedSelector';

export interface IAssignedUserProps {
  index: number;
  pending: boolean;
  errors: string[];
  fields: Array<IFieldDescriptor<ISlotUser>>;
  user: ISlotUser;
  expanded: boolean;
  panelState: E_PANEL_STATE;
  onExpand: (evt: MouseEvent<any, any>) => void;
  onToggleState: (evt: MouseEvent) => void;
  onDelete: (evt: MouseEvent) => void;
  onReassign: (evt: MouseEvent) => void;
  onCancel: (evt: MouseEvent) => void;
  onConfirmDelete: (evt: MouseEvent) => void;
  onConfirmReassign: (newUser: IInviteUser) => void;
}

export const AssignedUserRow: React.FC<IAssignedUserProps> = (props) => {
  const {
    index,
    fields,
    user,
    expanded,
    panelState,
    onExpand,
    onToggleState,
    onDelete,
    onReassign,
    onCancel,
    onConfirmDelete,
    onConfirmReassign,
  } = props;
  const currentAccountId = useTypedSelector((state) => state.auth.profile.id);
  const classes = useStyles();

  return (
    <>
      <TableRow>
        {fields.map((field, idx) => (
          <TableCell
            key={idx}
            className={clsx(classes.cell, field.cssClass ? field.cssClass : '', expanded ? '' : classes.cellBordered)}
          >
            {field.isAction && currentAccountId !== user.slot.accountId ? (
              <Fab
                size="small"
                color={expanded ? 'default' : 'secondary'}
                id={`expand_user_btn_${index}`}
                onClick={onExpand}
              >
                {expanded ? <ClearIcon /> : <AddIcon />}
              </Fab>
            ) : (
              field.getData && field.getData(user)
            )}
          </TableCell>
        ))}
      </TableRow>
      {expanded ? (
        <TableRow>
          <TableCell colSpan={fields.length} className={clsx(classes.cell, classes.cellBordered)}>
            <AssignedUserActions
              index={index}
              user={user}
              status={user.slot.status as E_USER_STATUS}
              panelState={panelState}
              onToggleState={onToggleState}
              onDelete={onDelete}
              onReassign={onReassign}
              onCancel={onCancel}
              onConfirmDelete={onConfirmDelete}
              onConfirmReassign={onConfirmReassign}
            />
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
};

const useStyles = makeStyles((theme) => tableStyles(theme));
