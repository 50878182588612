import { ID } from 'modules/core/types';
import { IResellerPayout, IAccountNetworkNode } from 'modules/dashboard/graphql/gql';
import { AccountNetworkNode } from 'modules/dashboard/models/account-network-node';
import uuid from 'uuid';

export class ResellerPayout implements IResellerPayout {
  public id: ID = 0;
  public amount!: number;
  public account!: IAccountNetworkNode;
  public currency!: string;
  public introducerName!: string;
  public introducerLevel!: number;
  public purchasedAt!: string;
  public status!: string;
  public type!: string;

  constructor(data?: IResellerPayout) {
    if (data) {
      this.id = uuid();
      this.amount = data.amount;
      this.account = new AccountNetworkNode(data.account);
      this.currency = data.currency;
      this.introducerName = data.introducerName;
      this.introducerLevel = data.introducerLevel;
      this.purchasedAt = data.purchasedAt;
      this.status = data.status;
      this.type = data.type;
    }
  }
}
