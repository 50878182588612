import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const accordionStyles = makeStyles((theme: Theme) =>
  createStyles({
    expansionActive: {
      background: '#ffffff',
      color: '#22272c',
      borderRadius: 4,
      '&._green': {
        background: 'transparent',
        flexDirection: 'row-reverse',
        color: 'white',
        padding: 0,

        '&> :first-child': {
          '&> :first-child': {
            display: 'none',
          },
        },
        '&> :last-child': {
          padding: 0,
          marginRight: 0,
        },
      },
    },
    expansionCurrent: {
      background: '#3C4046',
      color: '#ffffff',
      border: '1px solid #6b6f73',
      borderRadius: 4,
      [theme.breakpoints.down('md')]: {
        padding: '0 15px',
        '&> :first-child': {
          display: 'flex',
          alignItems: 'center',
        },
      },

      '&._green': {
        background: 'transparent',
        flexDirection: 'row-reverse',
        border: 'none',
        padding: 0,
        margin: 0,
        borderRadius: 0,
        '&> :first-child': {
          margin: '21px 0',
          '&> :first-child': {
            display: 'none',
          },
        },
        '&> :last-child': {
          padding: 0,
          marginRight: 0,
        },
      },
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(14),
        LineHeight: theme.typography.pxToRem(50),
        letterSpacing: 0.47,
        fontWeight: 700,
      },
      '&._green': {
        margin: '0 15px',
        textTransform: 'unset',
        fontSize: theme.typography.pxToRem(20),
        lineHeight: theme.typography.pxToRem(29),
        fontWeight: theme.typography.fontWeightRegular,
        [theme.breakpoints.down('sm')]: {
          fontSize: theme.typography.pxToRem(16),
          lineHeight: theme.typography.pxToRem(23),
        },
      },
    },
    root: {
      width: '100%',
      margin: theme.spacing(1, 0),
      [theme.breakpoints.down('md')]: {
        '&:not(:last-child)': {
          marginTop: 0,
          marginBottom: 15,
        },
      },
      '&._green': {
        background: 'transparent',
        margin: 0,
        '&> :first-child': {
          background: 'transparent',
          borderTop: '1px dashed #33634B',
          borderRight: 'none',
          borderLeft: 'none',
          boxShadow: 'none',
          color: 'white',
        },
        '&:last-child': {
          '&> :first-child': {
            borderBottom: '1px dashed #33634B',
          },
        },
      },
      '&._green._without-border': {
        [theme.breakpoints.up('sm')]: {
          '&:last-child': {
            '&> :first-child': {
              borderBottom: 'none',
            },
          },
        },
      },
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      background: '#252b31',
      color: 'white',
      '&._green': {
        background: 'transparent',
        alignItems: 'flex-start',
        paddingLeft: theme.spacing(4.2),
        '&> :not(:last-child)': {
          marginBottom: 15,
          color: 'rgba(255, 255, 255, .7)',
        },
        '&> :last-child': {
          [theme.breakpoints.down('sm')]: {
            '& span': {
              textAlign: 'left',
            },
            '&::after': {
              top: '45%',
              transform: 'translateY(-50%)',
            },
          },
        },
      },
    },
  })
);

export const useIconStyles = makeStyles((theme: Theme) =>
  createStyles({
    minusIcoWhite: {
      display: 'flex',
      width: 23,
      height: 23,
      position: 'relative',
      background: 'white',
      borderRadius: '50%',
      color: theme.palette.background.default,
      '& svg': {
        fill: theme.palette.background.default,
        borderRadius: '50%',
        zIndex: 3,
      },
    },
    minusIcoGray: {
      color: '#424242',
    },
    plusIco: {
      display: 'flex',
      alignItems: 'center',
      width: 23,
      height: 23,
      position: 'relative',
      background: '#76B741',
      borderRadius: '50%',
      '& button': {
        padding: 0,
      },
      '& svg': {
        fill: theme.palette.background.default,
        borderRadius: '50%',
        zIndex: 3,
      },
    },
  })
);
