import { loadAuthToken, storeAuthToken } from 'modules/core/utils/api';
import { AccountProfile } from 'modules/auth/models/account-profile';
import { createSymbiote, Symbiotes } from 'redux-symbiote';
import { Subscription } from 'modules/billing/models/subscription';
import { Signup, ResellerSignup, ResellerForm } from 'modules/auth/models/signup';
import { ISignup, IResellerSignup, IInvitation } from 'modules/auth/interfaces/auth';
import { LocalStorageHelper } from 'helpers/localstore';
import { Invitation } from 'modules/auth/models/invitation';

export interface IAuthState {
  profile: AccountProfile;
  isAuthenticated: boolean;
  token: string | null;
  loadingProfile: boolean;
  pending: boolean;
  emailRequested: boolean;
  codeRequested: boolean;
  subscriptions: Subscription[];
  errorMessage: string | null;
  signup: ISignup;
  resellerSignup: IResellerSignup;
  invitation: IInvitation;
}

const prepareAuth = () => {
  const token = loadAuthToken();
  return {
    isAuthenticated: !!token,
    token,
  };
};

const initialState: IAuthState = {
  profile: new AccountProfile(),
  loadingProfile: false,
  emailRequested: false,
  codeRequested: false,
  pending: false,
  errorMessage: null,
  ...prepareAuth(),
  subscriptions: [],
  signup: new Signup(),
  resellerSignup: new ResellerSignup(),
  invitation: new Invitation(),
};

export interface IAuthActions extends Symbiotes<IAuthState> {
  setProfile: (state: IAuthState, profile: AccountProfile) => IAuthState;
  setAuthToken: (state: IAuthState, token: string) => IAuthState;
  requestEmail: (state: IAuthState, emailRequested: boolean) => IAuthState;
  requestCode: (state: IAuthState, codeRequested: boolean) => IAuthState;
  signout: (state: IAuthState) => IAuthState;
  setPending: (state: IAuthState, pending: boolean) => IAuthState;
  setErrorMessage: (state: IAuthState, message: string | null) => IAuthState;
  setLoadingProfile: (state: IAuthState, loading: boolean) => IAuthState;
  setSubscription: (state: IAuthState, subs: Subscription[]) => IAuthState;
  setSignupUserValues: (state: IAuthState, fieldName: string, fieldValue: any) => IAuthState;
  setSignupErrors: (state: IAuthState, fieldName: string, fieldValue: any) => IAuthState;
  setInvitation: (state: IAuthState, invitation: Invitation) => IAuthState;
  setResellerSignupUserValues: (state: IAuthState, fieldName: string, fieldValue: any) => IAuthState;
  setResellerSignupErrors: (state: IAuthState, fieldName: string, fieldValue: any) => IAuthState;
  setInitialResellerSignupUserValues: (state: IAuthState, userValues: ResellerForm) => IAuthState;
}

const symbiots: IAuthActions = {
  setProfile: (state, profile) => ({ ...state, profile }),
  setAuthToken: (state, token) => {
    storeAuthToken(token);
    return { ...state, token, isAuthenticated: !!token };
  },
  requestEmail: (state, emailRequested = true) => ({
    ...state,
    emailRequested,
  }),
  requestCode: (state, codeRequested = true) => ({ ...state, codeRequested }),
  signout: () => {
    storeAuthToken(null);
    LocalStorageHelper.clear();
    return { ...initialState, ...prepareAuth() };
  },
  setPending: (state, pending) => ({
    ...state,
    pending,
    errorMessage: pending ? '' : state.errorMessage,
  }),
  setErrorMessage: (state, errorMessage) => ({ ...state, errorMessage }),
  setLoadingProfile: (state, loading = false) => ({
    ...state,
    loadingProfile: loading,
  }),
  setSubscription: (state, subs) => ({ ...state, subscriptions: subs }),
  setSignupUserValues: (state, fieldName, fieldValue) => ({
    ...state,
    signup: {
      ...state.signup,
      values: {
        ...state.signup.values,
        [fieldName]: fieldValue,
      },
    },
  }),
  setSignupErrors: (state, fieldName, fieldValue) => ({
    ...state,
    signup: {
      ...state.signup,
      errors: {
        ...state.signup.errors,
        [fieldName]: fieldValue,
      },
    },
  }),
  setInvitation: (state, invitation) => ({
    ...state,
    invitation,
  }),
  setResellerSignupUserValues: (state, fieldName, fieldValue) => ({
    ...state,
    resellerSignup: {
      ...state.resellerSignup,
      userValues: {
        ...state.resellerSignup.userValues,
        [fieldName]: fieldValue,
      },
    },
  }),
  setResellerSignupErrors: (state, fieldName, fieldValue) => ({
    ...state,
    resellerSignup: {
      ...state.resellerSignup,
      errors: {
        ...state.resellerSignup.errors,
        [fieldName]: fieldValue,
      },
    },
  }),
  setInitialResellerSignupUserValues: (state, userValues) => ({
    ...state,
    resellerSignup: {
      ...state.resellerSignup,
      userValues,
    },
  }),
};

export const { actions, reducer } = createSymbiote<IAuthState, IAuthActions>(initialState, symbiots);
