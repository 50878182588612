import { E_ACCOUNT_TYPE } from 'modules/auth/constants/enums';
import { About } from 'modules/editor/components/CardSectionEditors/About';
import { Branding } from 'modules/editor/components/CardSectionEditors/Branding';
import { Gallery } from 'modules/editor/components/CardSectionEditors/Gallery';
import { Location } from 'modules/editor/components/CardSectionEditors/Location';
import { News } from 'modules/editor/components/CardSectionEditors/News';
import { PersonalDetails } from 'modules/editor/components/CardSectionEditors/PersonalDetails';
import { Promotion } from 'modules/editor/components/CardSectionEditors/Promotion';
import { SocialLinks } from 'modules/editor/components/CardSectionEditors/SocialLinks';
import { Styling } from 'modules/editor/components/CardSectionEditors/Styling';
import { Video } from 'modules/editor/components/CardSectionEditors/Video';
import { E_CARD_STEPS, E_STEP_TYPE } from 'modules/editor/constants/enums';
import { ICardStep, IMobileStep } from 'modules/editor/interfaces';
import {
  L_BRAND,
  L_GALLERY,
  L_LOCATION,
  L_NEWS,
  L_PERSONAL,
  L_SOCIAL,
  L_STYLING,
  L_USE_CARD_FOR_PROMO,
  L_VIDEO,
  L_YOUR_WEBSITE,
} from 'modules/public/pages/learning-center/consts';

export const PERSONAL_STEP: ICardStep = {
  id: 'editor_personal_step',
  title: 'Personal details',
  step: E_CARD_STEPS.PERSONAL_DETAILS,
  type: E_STEP_TYPE.REQUIRED,
  requiredFields: ['fullName', 'mobileNumber', 'emailAddress', 'cardUrl'],
  videoUrl: L_PERSONAL.src,
  hint: {
    [E_ACCOUNT_TYPE.Member]: {
      id: 'hint_for_member_personal',
      title: 'Personal details',
      texts: [
        'Welcome {{username}}!',
        'You’ve been invited to create a Go Paperless digital business card – and we’re here to guide you through the steps of customising it. Some elements have been filled in on your behalf – but you’ll need to upload your profile pictures, enter your personal details and choose a URL for your card on this page.',
        'The question mark icons are there to provide additional support at every stage. Just click on them for advice on completing your card.',
      ],
    },
    [E_ACCOUNT_TYPE.Owner]: {
      id: 'hint_for_owner_personal',
      title: 'Personal details',
      texts: [
        'Welcome {{username}}!',
        'It’s time to customise your card – and we’re here to guide you through the steps.',
        'The question mark icons are there to provide additional support throughout, should you need it. Just click on them for advice. Or if you’d like more detail, just watch our {{videolink}} video.',
        'On this page, all you need to do is enter your personal details and choose a url for your card.',
        'At every stage, you see how your card will look on desktop, tablet and mobile by clicking on the preview buttons. To access previous versions on your card click on the ‘Edit your card’ dropdown menu – but remember to publish changes before doing so or you’ll lose them.',
      ],
    },
    [E_ACCOUNT_TYPE.Reseller]: {
      id: 'hint_for_reseller_personal',
      title: 'Personal details',
      texts: [
        'Welcome {{username}}!',
        'It’s time to customise your reseller details – and we’re here to guide you through the steps.',
        'We’ve already completed most of the elements on your behalf, but you’ll need to upload a profile picture, enter your mobile number and choose a URL for your referrals. For additional tips and support, just click on the question mark icons.',
      ],
    },
  },
  helpModal: [
    {
      image: '',
      texsts: [
        'Click on the upload icon in the centre of image box to access your files, then find and upload your personal photo. Your image should be a minimum of 256px x 256 px.',
        'Use the cropping tool to crop the image into the right dimensions. Remember to leave some space around your face, as you would with a passport photo.',
      ],
      title: 'Profile Photo',
    },
    {
      image: '',
      texsts: [
        'Your mobile number is the number which contacts will access when you share your card – If you’d like to add your office landline or any other number to your card, use the ‘alternative number’ field.',
        'Click on the flag icon to find your country’s dialling code and enter your mobile number in international format  (for example +44 7123 456789, omitting the ‘0’ at the beginning. ',
      ],
      title: 'Mobile Numbers',
    },
    {
      image: '',
      texsts: [
        'Please choose an appropriate title for your URL – which will be prefixed with gpdigitalcards.com – as this is what you’ll be sharing with contacts.',
        'For example, if your card is personal, enter your name.',
        'If it’s a business card, use the name of your business.',
        'Please note that once you have saved and published your card, your unique card sharing link cannot be changed. You can only edit this field before you first publish your card.',
      ],
      title: 'Card URL Link',
    },
  ],
};

export const SOCIAL_STEP: ICardStep = {
  id: 'editor_social_step',
  title: 'Social links',
  step: E_CARD_STEPS.SOCIAL_LINKS,
  type: E_STEP_TYPE.REQUIRED,
  requiredFields: [],
  videoUrl: L_SOCIAL.src,
  hint: {
    [E_ACCOUNT_TYPE.Member]: {
      id: 'hint_for_member_social',
      title: 'Social links',
      texts: [
        'Your digital business card gives you the opportunity to promote your social channels. On this page, you can enter details of all your social media channels, linking directly to them from your icons on your card. However, your card will only display 5 links at any one time. Just check the Show box to select which social icons to display – you can swap them whenever you like.',
        'For more help on how to do it, just click on the question mark icon.',
      ],
    },
    [E_ACCOUNT_TYPE.Owner]: {
      id: 'hint_for_owner_social',
      title: 'Social links',
      texts: [
        'Your digital business card gives you the opportunity to promote your social channels. On this page, you can enter details of all your social media channels, linking directly to them from your icons on your card. However, your card will only display 5 links at any one time. Just check the Show box to select which social icons to display – you can swap them whenever you like.',
        'For more help on how to do it, just click on the question mark icon.',
      ],
    },
  },
  helpModal: [
    {
      image: '',
      texsts: [
        'Copy and paste the appropriate links into the fields provided.',
        'Tick the ‘Show’ button underneath your links and your icons will appear on your card.',
        'Simply un-tick to remove the links.',
        'Your card can have a maximum of 5 social links at one time.',
      ],
      title: 'Social links',
    },
  ],
};

export const BRANDING_STEP: ICardStep = {
  id: 'editor_branding_step',
  title: 'Branding',
  step: E_CARD_STEPS.BRANDING,
  type: E_STEP_TYPE.REQUIRED,
  requiredFields: [],
  videoUrl: L_BRAND.src,
  hint: {
    [E_ACCOUNT_TYPE.Owner]: {
      id: 'hint_for_owner_branding',
      title: 'Branding',
      texts: [
        'Your Go Paperless card is a visual representation of your brand. On this page, you can choose a background colour, background image and overlay colour for your business card – experiment with the different combinations to create the desired effect, using the checkboxes to turn them on and off.',
        'This is also where you’ll add your business logo and icon.',
        'Remember, if you’re looking for additional support, just click on the question mark icons.',
      ],
    },
  },
  helpModal: [
    {
      image: '',
      texsts: [
        'Choose a background colour, upload a background image and add an overlay colour – use the checkboxes to turn the elements on and off, experimenting with the different effects to create your desired look. Remember, the overlay is always your top layer with background colour at the bottom and your image in between. ',
        'To upload an image, click on the upload icon in the image box to access your files, then find and upload your background image. Remember, the image will be used across devices with small and large screens, so use a high resolution. Block colours or patterns are very effective. ',
      ],
      title: 'Branding',
    },
    {
      image: '',
      texsts: [
        'Your logo will appear at the top of your card.',
        'Click on the upload icon to access your files, then find and upload your logo image. Then use the cropping tool to cut the image into your desired dimensions.',
      ],
      title: 'Logo',
    },
    {
      image: '',
      texsts: [
        'Your icon is the image that represents your card – like the apps on your mobile home screen or the image at the beginning of a weblink.',
        'We’d recommend using your brand logo if it’s a business or your profile image for a personal card. The image should be square and 512px x 512px, so that we can guarantee your icon won’t look  pixelated or blurred.',
        'Click on the upload icon in the image box to access your files, then find and upload your image.',
        'Use the cropping tool to crop the image into the right dimensions.',
      ],
      title: 'Icon',
    },
  ],
};

export const STYLING_STEP: ICardStep = {
  id: 'editor_styling_step',
  title: 'Styling',
  step: E_CARD_STEPS.STYLING,
  type: E_STEP_TYPE.REQUIRED,
  requiredFields: [],
  videoUrl: L_STYLING.src,
  hint: {
    [E_ACCOUNT_TYPE.Owner]: {
      id: 'hint_for_owner_styling',
      title: 'Styling',
      texts: [
        'On this page, you get to pick font styles and colours for your card.',
        'Need extra help? Just click on the question mark icons for support – or watch our {{videolink}} video.',
      ],
    },
  },
  helpModal: [
    {
      image: '',
      texsts: [
        'This information will show when users view your card on devices with small screens and there isn’t enough space to show the logo image you uploaded in the branding section.',
        'We also use it to personalise your experience and identify you to anyone you invite to join your Go Paperless account/organisation.',
      ],
      title: 'Business Name',
    },
    {
      image: '',
      texsts: [
        'Add a tagline – normally your business slogan or catch phrase. Then click on the coloured square to adjust the font colour.',
        'Use the drop down menu in the typography section to select your font for titles and headings. Choose from bold or normal by ticking the appropriate box.',
      ],
      title: 'Tagline',
    },
    {
      image: '',
      texsts: [
        'Click on the coloured squares to adjust the colours of your main and secondary icons – these include your save and share buttons, social links and all other text-based links on the card.',
      ],
      title: 'Template Colours',
    },
  ],
};

export const ABOUT_STEP: ICardStep = {
  id: 'editor_about_step',
  title: 'About',
  step: E_CARD_STEPS.ABOUT,
  type: E_STEP_TYPE.ADVANCED,
  requiredFields: [],
  videoUrl: L_YOUR_WEBSITE.src,
  hint: {
    [E_ACCOUNT_TYPE.Owner]: {
      id: 'hint_for_owner_about',
      title: 'Advanced blocks',
      texts: [
        'The following sections are optional. In them, you’ll get the chance to add details about your business, including links to offers and promotions as well as news, photographs and videos – giving you the flexibility to showcase your business in any way you choose. At every stage, use the question mark icons for additional help and support.',
        'Once you’ve completed these sections, you’ll be able to preview and purchase/publish your card.',
      ],
    },
  },
  helpModal: [
    {
      image: '',
      texsts: [
        'Let the world know what you do.',
        'Enter your website’s URL in the field provided.',
        'Choose a title and add a brief description to showcase your business, products or services.',
      ],
      title: 'About',
    },
    {
      image: '',
      texsts: [
        'Here you can add links to your website or upload files including pdfs, images, audio, video and word documents, in order to help communicate to the world what you do.',
        'Add as many links and files as you wish.',
        'Bear in mind that you have separate sections in which to add details of your latest promotions and news – as well as videos, photographs and location/opening hours.',
      ],
      title: 'Links',
    },
  ],
};

export const PROMOTION_STEP: ICardStep = {
  id: 'editor_promotion_step',
  title: 'Promotion',
  step: E_CARD_STEPS.PROMOTION,
  type: E_STEP_TYPE.ADVANCED,
  requiredFields: [],
  videoUrl: L_USE_CARD_FOR_PROMO.src,
  hint: {},
  helpModal: [
    {
      image: '',
      texsts: [
        'Running an offer or promotion?',
        'Copy and paste the URL link from your website in the field provided.',
        'Add a title to drive engagement. Then upload an image by clicking the upload icon in the promotional image box.',
      ],
      title: 'Promotion',
    },
  ],
};

export const NEWS_STEP: ICardStep = {
  id: 'editor_news_step',
  title: 'News',
  step: E_CARD_STEPS.NEWS,
  type: E_STEP_TYPE.ADVANCED,
  requiredFields: [],
  hint: {},
  videoUrl: L_NEWS.src,
  helpModal: [
    {
      image: '',
      texsts: [
        'Want to shout about your latest news?',
        'Link to a blog/news page or an article or video by entering the URL link in the field provided.',
        // eslint-disable-next-line no-multi-str
        'Don’t forget to add a title and upload a complementary image by \
        clicking the upload icon in the news image box',
      ],
      title: 'News',
    },
  ],
};

export const VIDEO_STEP: ICardStep = {
  id: 'editor_video_step',
  title: 'Video',
  step: E_CARD_STEPS.VIDEO,
  type: E_STEP_TYPE.ADVANCED,
  requiredFields: [],
  hint: {},
  videoUrl: L_VIDEO.src,
  helpModal: [
    {
      image: '',
      texsts: ['Showcase your brand with a video.', 'Add your YouTube URL (to a single video rather than your YouTube channel) as well as a title for your video, which will display and play within your card.'],
      title: 'Video',
    },
  ],
};

export const GALLERY_STEP: ICardStep = {
  id: 'editor_gallery_step',
  title: 'Gallery',
  step: E_CARD_STEPS.GALLERY,
  type: E_STEP_TYPE.ADVANCED,
  requiredFields: [],
  hint: {},
  videoUrl: L_GALLERY.src,
  helpModal: [
    {
      image: 'Gallery',
      texsts: [
        'Showcase products, services or examples of your work in your Gallery. Add as many images as you like.',
        'Select photographs or images by clicking the upload icon in the image box.',
        'Don’t forget to add a description, which will appear beneath your image. And make sure you populate the URL field to make your image clickable from the Gallery.',
      ],
      title: 'Gallery',
    },
  ],
};

export const LOCATION_STEP: ICardStep = {
  id: 'editor_location_step',
  title: 'Location',
  step: E_CARD_STEPS.LOCATION,
  type: E_STEP_TYPE.ADVANCED,
  requiredFields: [],
  hint: {},
  videoUrl: L_LOCATION.src,
  helpModal: [
    {
      image: '',
      texsts: [
        'Show the world where and when they can find you using Google Maps.',
        'Enter your business’s address in the location fields. The map will update automatically as you add extra address lines.',
        'You can also showcase your business hours – simply use the drop down menu to select the days you’re open and use the 24-hour slider to add your opening hours.',
      ],
      title: 'Location',
    },
  ],
};

export const REQUIRED_STEP_ITEMS = [PERSONAL_STEP, SOCIAL_STEP, BRANDING_STEP, STYLING_STEP];
export const OPTIONAL_STEPS_ITEMS = [ABOUT_STEP, PROMOTION_STEP, NEWS_STEP, VIDEO_STEP, GALLERY_STEP, LOCATION_STEP];
export const ALL_STEPS = [...REQUIRED_STEP_ITEMS, ...OPTIONAL_STEPS_ITEMS];

export const STEPS: IMobileStep[] = [
  {
    COMPONENT: PersonalDetails,
    ...PERSONAL_STEP,
  },
  {
    COMPONENT: SocialLinks,
    ...SOCIAL_STEP,
  },
  {
    COMPONENT: Branding,
    ...BRANDING_STEP,
  },
  {
    COMPONENT: Styling,
    ...STYLING_STEP,
  },
];

export const OPTIONAL_STEPS: IMobileStep[] = [
  {
    COMPONENT: About,
    ...ABOUT_STEP,
  },
  {
    COMPONENT: Promotion,
    ...PROMOTION_STEP,
  },
  {
    COMPONENT: News,
    ...NEWS_STEP,
  },
  {
    COMPONENT: Video,
    ...VIDEO_STEP,
  },
  {
    COMPONENT: Gallery,
    ...GALLERY_STEP,
  },
  {
    COMPONENT: Location,
    ...LOCATION_STEP,
  },
];
