import Cookies from 'js-cookie';
import AuthorizationDialog from 'modules/auth/components/Authorization/AuthorizationDialog';
import { authenticateByEmail, getProfile } from 'modules/auth/thunks/auth';
import ErrorContent from 'modules/core/components/dialogs/ErrorContent';
import Progress from 'modules/core/components/progress';
import { useDefaultManifest } from 'modules/core/utils';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { DASHBOARD_ROUTE, HOME_ROUTE } from 'routes/routes';
import { IReduxState } from 'store';

interface IReducers {
  authenticate: (email: string, token: string, throughReseller?: string) => Promise<void>;
  loadProfile: () => Promise<void>;
  auth: boolean;
}

const SigninAuth: React.FC<RouteComponentProps & IReducers> = ({
  history,
  authenticate,
  auth,
  loadProfile,
  location: { search },
}) => {
  const [isError, setIsError] = useState(false);
  useDefaultManifest();
  useEffect(() => {
    const query = new URLSearchParams(search);
    const email = query.get('email');
    const token = query.get('authenticate_email_token');
    const throughReseller = Cookies.get('throughReseller');
    if (email && token) {
      if (auth) {
        history.push(DASHBOARD_ROUTE.path);
      } else {
        authenticate(email, token, throughReseller)
          .then(() => {
            history.push(DASHBOARD_ROUTE.path);
          })
          // tslint:disable-next-line: no-empty
          .catch(() => {
            setIsError(true);
          });
      }
    } else {
      history.push(HOME_ROUTE.path);
    }
  }, [search, history, authenticate, loadProfile, auth]);

  if (isError) {
    return <AuthorizationDialog dlgBody={<ErrorContent />} exClass={'_auth _withoutError'} />;
  } else {
    return <AuthorizationDialog dlgBody={<Progress />} exClass={'_auth'} />;
  }
};

const mapReducers = {
  authenticate: authenticateByEmail,
  loadProfile: getProfile,
};

export default connect(
  (state: IReduxState) => ({ auth: state.auth.isAuthenticated }),
  mapReducers
)(withRouter(SigninAuth));
