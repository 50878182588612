import { Subscription } from 'modules/billing/models/subscription';
import { E_ACCOUNT_TYPE } from 'modules/auth/constants/enums';
import { ID } from 'modules/core/types';
import { IAccountProfile } from 'modules/auth/interfaces/auth';
import { UserProfile } from 'modules/auth/models/user-profile';
import moment, { Moment } from 'moment';

export class AccountProfile implements IAccountProfile {
  public id: ID = 0;
  public user: UserProfile = new UserProfile({});
  public canPublishCard: boolean = false;
  public activeSubscriptions: Subscription[] = [];
  public subscribed: boolean = false;
  public type: E_ACCOUNT_TYPE = E_ACCOUNT_TYPE.Unknown;
  public canChangeUrl: boolean = false;
  public hasBeenSubscribed: boolean = false;
  // public multipleSubscription: boolean = false;

  // tslint:disable-next-line: variable-name
  public companyName?: string = '';
  public accountName: string = '';
  public accountDesc: string = '';

  public firstName: string = '';
  public lastName: string = '';
  public email: string = '';
  public mobilePhone: string = '';
  public customerId?: string = '';
  public countryCode: string = '';
  public eligibleForDiscountUntil?: Moment;

  public canEditAboutSection: boolean = false;
  public canEditOfferSection: boolean = false;
  public canEditVideoSection: boolean = false;
  public canEditGallerySection: boolean = false;
  public canEditLocationSection: boolean = false;
  public canEditPromotionSection: boolean = false;

  constructor(data?: Partial<IAccountProfile>) {
    if (data) {
      this.id = data.id || 0;
      this.user = new UserProfile(data.user);
      this.canPublishCard = !!data.canPublishCard;

      const { activeSubscriptions } = data;
      if (Array.isArray(activeSubscriptions)) {
        this.activeSubscriptions = activeSubscriptions.map((item) => new Subscription(item));
      }

      this.subscribed = !!data.subscribed;
      this.type = data.type || E_ACCOUNT_TYPE.Unknown;
      this.canChangeUrl = !!data.canChangeUrl;
      this.hasBeenSubscribed = !!data.hasBeenSubscribed;
      // this.multipleSubscription = !!data.multipleSubscription;
      this.companyName = data.companyName || '';
      this.accountName = data.accountName || '';
      this.accountDesc = data.accountDesc || '';

      this.firstName = data.user!.firstName || '';
      this.lastName = data.user!.lastName || '';
      this.email = data.user!.email || '';
      this.mobilePhone = data.user!.mobilePhone || '';
      this.customerId = data.user!.customerId || ('' as string);
      this.countryCode = data.user!.countryCode || '';
      // this.multipleSubscription = !!data.user!.multipleSubscription;
      this.eligibleForDiscountUntil = data.user!.eligibleForDiscountUntil
        ? moment(data.user!.eligibleForDiscountUntil, 'YYYY-MM-DD')
        : undefined;
      this.canEditAboutSection = data.canEditAboutSection as boolean;
      this.canEditOfferSection = data.canEditOfferSection as boolean;
      this.canEditVideoSection = data.canEditVideoSection as boolean;
      this.canEditGallerySection = data.canEditGallerySection as boolean;
      this.canEditLocationSection = data.canEditLocationSection as boolean;
      this.canEditPromotionSection = data.canEditPromotionSection as boolean;
    }
  }

  public canSeeDashboard(): boolean {
    return this.canPublishCard || this.subscribed;
  }

  public canSeeBilling(): boolean {
    return this.user.hasBeenSubscribed;
  }

  public canSeeUsers(): boolean {
    return this.subscribed && this.type === E_ACCOUNT_TYPE.Owner;
  }

  public canSeeResellerResourceCentre(): boolean {
    return this.type === E_ACCOUNT_TYPE.Reseller;
  }

  public canSeeResellerDashboardTools(): boolean {
    return this.subscribed && this.type === E_ACCOUNT_TYPE.Reseller;
  }

  public getUserName(): string {
    return this.user.getUserName();
  }

  public needBuy(): boolean {
    return this.type === E_ACCOUNT_TYPE.Owner || this.type === E_ACCOUNT_TYPE.Reseller ? !this.canPublishCard : false;
  }

  public isReseller(): boolean {
    return this.type === E_ACCOUNT_TYPE.Reseller;
  }

  public isOwner(): boolean {
    return this.type === E_ACCOUNT_TYPE.Owner;
  }

  public isMember(): boolean {
    return this.type === E_ACCOUNT_TYPE.Member;
  }
}
