import React from 'react';
import { Box, Typography } from '@material-ui/core';
import Amount from './Amount';
import { useStyles } from './styles';
import CssGreenButton from 'modules/core/components/buttons/roundGreenButton';
import clsx from 'clsx';

interface ISummary {
  count: number;
  total: number;
  vat: number;
  ccy: string;
  percent: number;
  disabled: boolean;
  next: () => void;
}

export const Summary: React.FC<ISummary> = ({ count, total, percent, vat, ccy, disabled, next }) => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <div className={classes.popupHead}>
        <Typography className={classes.popupTitle} variant="h4">
          Summary
        </Typography>
        <Typography className={classes.popupDescription}>Purchasing {count} cards</Typography>
      </div>
      <div className={classes.popupContent}>
        <div className={classes.summaryItem}>
          <div>X{count} cards</div>
          <Box className={clsx(classes.radioTextLargeLighter, '_summary')}>
            <Amount id="summary_count" ccy={ccy} amount={total - vat} />
          </Box>
        </div>
        {percent ? (
          <div className={classes.summaryItem}>
            <div>VAT @{percent || 0}%</div>
            <Box className={clsx(classes.radioTextLargeLighter, '_summary')}>
              <Amount id="summary_vat" ccy={ccy} amount={vat} />
            </Box>
          </div>
        ) : null}
        <div className={classes.summaryItem}>
          <Box fontWeight="bold">TOTAL</Box>
          <Box id="total_amount" className={clsx(classes.radioTextLarge, '_summary')}>
            <Amount id="summary_total" ccy={ccy} amount={total} />
          </Box>
        </div>
      </div>
      <CssGreenButton name="step_summary_next" disabled={disabled} onClick={next}>
        BUY NOW
      </CssGreenButton>
    </div>
  );
};
