import {
  IBrandingSection,
  IPersonalDetailsSection,
  ISocialLinksSection,
  IStylingSection,
} from 'modules/editor/graphql/results';
import { CardVersion } from 'modules/editor/models/card-version';
import { createSymbiote, Symbiotes } from 'redux-symbiote';
import {
  ICardVersion,
  IAboutSection,
  IGallerySection,
  IPromotionSection,
  ILocationSection,
  INewsSection,
  IVideoSection,
} from '../graphql/gql';

export interface ICardVersionRS {
  currentVer: ICardVersion;
  loading: boolean;
  showPublishFlow: boolean;
  showSwitchCardFlow: boolean;
  versions: ICardVersion[];
  selectedVersion: Partial<ICardVersion>;
}

const STATE: ICardVersionRS = {
  currentVer: new CardVersion(),
  loading: true,
  showPublishFlow: false,
  showSwitchCardFlow: false,
  versions: [],
  selectedVersion: {},
};

export interface ICardVersionRA extends Symbiotes<ICardVersionRS> {
  setCardVersion(state: ICardVersionRS, cardVer: ICardVersion): ICardVersionRS;
  setAboutSection(state: ICardVersionRS, aboutSec: IAboutSection, draft: boolean): ICardVersionRS;
  setBrandingSection(state: ICardVersionRS, brandSec: IBrandingSection, draft: boolean): ICardVersionRS;
  setGallerySection(state: ICardVersionRS, gallerySec: IGallerySection, draft: boolean): ICardVersionRS;
  setLocationSection(state: ICardVersionRS, locSec: ILocationSection, draft: boolean): ICardVersionRS;
  setNewsSection(state: ICardVersionRS, newsSec: INewsSection, draft: boolean): ICardVersionRS;
  setPersonalSection(state: ICardVersionRS, persSec: IPersonalDetailsSection, draft: boolean): ICardVersionRS;
  setPromotionSection(state: ICardVersionRS, promoSec: IPromotionSection, draft: boolean): ICardVersionRS;
  setSocialSection(state: ICardVersionRS, socielSec: ISocialLinksSection, draft: boolean): ICardVersionRS;
  setStylingSection(state: ICardVersionRS, styleSec: IStylingSection, draft: boolean): ICardVersionRS;
  setVideoSection(state: ICardVersionRS, videoSec: IVideoSection, draft: boolean): ICardVersionRS;
  setLoadStatusStart(state: ICardVersionRS): ICardVersionRS;
  setLoadStatusFinish(state: ICardVersionRS): ICardVersionRS;
  setVersions(state: ICardVersionRS, versions: ICardVersion[]): ICardVersionRS;
  setSelectedVersion(state: ICardVersionRS, version: Partial<ICardVersion>): ICardVersionRS;
  filterImgItem(state: ICardVersionRS, index: number): ICardVersionRS;
  clearNewsImgItem(state: ICardVersionRS): ICardVersionRS;
  clearPromotionImgItem(state: ICardVersionRS): ICardVersionRS;
  setCardVersionId(state: ICardVersionRS, obj: Partial<ICardVersion>): ICardVersionRS;
  setDraft(state: ICardVersionRS, draft: boolean): ICardVersionRS;
  clearCardState(state: ICardVersionRS): ICardVersionRS;
  setShowPublishFlow(state: ICardVersionRS, isProcessing: boolean): ICardVersionRS;
  setShowSwitchCardFlow(state: ICardVersionRS, isProcessing: boolean): ICardVersionRS;
}

export const { actions, reducer } = createSymbiote<ICardVersionRS, ICardVersionRA>(STATE, {
  setCardVersion: (state: ICardVersionRS, cardVer: ICardVersion) => {
    return {
      ...state,
      currentVer: cardVer,
    };
  },
  setAboutSection: (state: ICardVersionRS, aboutSection: IAboutSection, draft: boolean) => ({
    ...state,
    currentVer: {
      ...state.currentVer,
      draft,
      aboutSection,
    },
  }),
  setBrandingSection: (state: ICardVersionRS, payload: IBrandingSection, draft: boolean) => {
    const { iconUrl16, iconUrl32, iconUrl512, logoUrl, ...style } = payload;
    const common: any = { iconUrl16, iconUrl32, iconUrl512, logoUrl };
    Object.keys(common).forEach((key) => common[key] === undefined && delete common[key]);

    return {
      ...state,
      currentVer: {
        ...state.currentVer,
        draft,
        cardStyle: {
          ...state.currentVer.cardStyle,
          ...style,
        },
        ...common,
      },
    };
  },
  setGallerySection: (state: ICardVersionRS, gallerySection: IGallerySection, draft: boolean) => ({
    ...state,
    currentVer: {
      ...state.currentVer,
      draft,
      gallerySection,
    },
  }),
  setLocationSection: (state: ICardVersionRS, locationSection: ILocationSection, draft: boolean) => ({
    ...state,
    currentVer: {
      ...state.currentVer,
      draft,
      locationSection,
    },
  }),
  setNewsSection: (state: ICardVersionRS, newsSection: INewsSection, draft: boolean) => ({
    ...state,
    currentVer: {
      ...state.currentVer,
      draft,
      newsSection,
    },
  }),
  setPersonalSection: (state: ICardVersionRS, persSec: IPersonalDetailsSection, draft: boolean) => ({
    ...state,
    currentVer: {
      ...state.currentVer,
      draft,
      email: persSec.emailAddress,
      fullName: persSec.fullName,
      jobTitle: persSec.jobTitle,
      qualifications: persSec.qualifications,
      mobilePhone: persSec.mobileNumber,
      secondPhoneNumber: persSec.secondPhoneNumber,
      avatarUrl: persSec.profilePhotoUrl,
      contactDetailsHeaderShow: persSec.contactDetailsHeaderShow,
      card: {
        ...state.currentVer.card,
        urlSlug: persSec.cardUrl,
      },
    },
  }),
  setPromotionSection: (state: ICardVersionRS, promotionSection: IPromotionSection, draft: boolean) => ({
    ...state,
    currentVer: {
      ...state.currentVer,
      draft,
      promotionSection,
    },
  }),
  setSocialSection: (state: ICardVersionRS, socialSec: ISocialLinksSection, draft: boolean) => ({
    ...state,
    currentVer: {
      ...state.currentVer,
      draft,
      ...socialSec,
    },
  }),
  setStylingSection: (state: ICardVersionRS, styleSec: IStylingSection, draft: boolean) => ({
    ...state,
    currentVer: {
      ...state.currentVer,
      draft,
      cardStyle: {
        ...state.currentVer.cardStyle,
        bodyFont: styleSec.bodyFont,
        bodyFontHexColour: styleSec.bodyFontHexColour,
        businessNameHexColour: styleSec.businessNameHexColour,
        bodyFontStyle: styleSec.bodyFontStyle,
        secondaryIconsHexColour: styleSec.secondaryIconsHexColour,
        titleFont: styleSec.titleFont,
        titleFontHexColour: styleSec.titleFontHexColour,
        taglineHexColour: styleSec.taglineHexColour,
        titleFontStyle: styleSec.titleFontStyle,
        primaryLinksHexColour: styleSec.primaryLinksHexColour,
      },
      tagLine: styleSec.tagLine,
      businessName: styleSec.businessName,
    },
  }),
  setVideoSection: (state: ICardVersionRS, videoSection: IVideoSection, draft: boolean) => ({
    ...state,
    currentVer: {
      ...state.currentVer,
      draft,
      videoSection,
    },
  }),
  setLoadStatusStart: (state: ICardVersionRS) => ({ ...state, loading: true }),
  setLoadStatusFinish: (state: ICardVersionRS) => ({
    ...state,
    loading: false,
  }),
  setVersions: (state: ICardVersionRS, versions: ICardVersion[]) => ({
    ...state,
    versions,
  }),
  setSelectedVersion: (state: ICardVersionRS, selectedVersion: Partial<ICardVersion>) => ({
    ...state,
    selectedVersion,
  }),
  filterImgItem: (state: ICardVersionRS, index: number) => ({
    ...state,
    currentVer: {
      ...state.currentVer,
      gallerySection: {
        ...state.currentVer.gallerySection,
        gallerySectionImages: state.currentVer.gallerySection.gallerySectionImages.filter(
          (_, i: number) => i !== index
        ),
      },
    },
  }),
  clearNewsImgItem: (state: ICardVersionRS) => ({
    ...state,
    currentVer: {
      ...state.currentVer,
      newsSection: {
        ...state.currentVer.newsSection,
        newsImageUrl: undefined,
      },
    },
  }),
  clearPromotionImgItem: (state: ICardVersionRS) => ({
    ...state,
    currentVer: {
      ...state.currentVer,
      promotionSection: {
        ...state.currentVer.promotionSection,
        promotionImageUrl: undefined,
      },
    },
  }),
  setCardVersionId: (state: ICardVersionRS, obj: Partial<ICardVersion>) => {
    const { gallerySection, aboutSection, locationSection } = state.currentVer;

    gallerySection.gallerySectionImages.forEach((gi, i) => (gi.id = obj.gallerySection!.gallerySectionImages[i].id));

    aboutSection.aboutSectionLinks.forEach((as, i) => (as.id = obj.aboutSection!.aboutSectionLinks[i].id));

    locationSection.openingTimes.forEach((ot, i) => (ot.id = obj.locationSection!.openingTimes[i].id));

    return {
      ...state,
      currentVer: {
        ...state.currentVer,
        id: obj.id as number,
        currentForCardId: obj.currentForCardId as number,
        draft: obj.draft || false,
        cardStyle: {
          ...state.currentVer.cardStyle,
          id: obj.cardStyle!.id,
        },
        aboutSection: {
          ...aboutSection,
          id: obj.aboutSection!.id,
        },
        gallerySection: {
          ...gallerySection,
          id: obj.gallerySection!.id,
        },
        locationSection: {
          ...locationSection,
          id: obj.locationSection!.id,
        },
      },
    };
  },
  setDraft: (state: ICardVersionRS, draft: boolean) => ({
    ...state,
    currentVer: {
      ...state.currentVer,
      draft,
    },
  }),
  clearCardState: (state: ICardVersionRS) => ({
    ...STATE,
  }),
  setShowPublishFlow: (state: ICardVersionRS, isProcessing: boolean) => ({
    ...state,
    currentVer: {
      ...state.currentVer,
      isProcessing: isProcessing ? state.currentVer.isProcessing : false,
    },
    showPublishFlow: isProcessing,
  }),
  setShowSwitchCardFlow: (state: ICardVersionRS, isProcessing: boolean) => ({
    ...state,
    currentVer: {
      ...state.currentVer,
      isProcessing: isProcessing ? state.currentVer.isProcessing : false,
    },
    showSwitchCardFlow: isProcessing,
  }),
});
