import { IBrandingSectionAtributes } from 'modules/editor/graphql/mutations';
import { ICardVersion } from 'modules/editor/graphql/gql';
import { IModel } from 'modules/core/interfaces/model';
import { clearObject } from 'modules/core/utils';
import { initialGray } from 'themes/colors';

export class BrandingAtributes implements IBrandingSectionAtributes, IModel<IBrandingSectionAtributes> {
  public cardVersionId!: number;
  public cardStyleId!: number;
  public backgroundHexColour?: string | null = initialGray;
  public backgroundImageShow?: boolean = false;
  public backgroundImageBase64?: string | null;
  public backgroundImageName?: string | null;
  public overlayShow?: boolean = false;
  public overlayHexColour?: string | null = initialGray;
  public logoBase64?: string;
  public logoName?: string | null;
  public iconBase64?: string | null;
  public iconName?: string | null;
  [field: string]: any;

  constructor(card?: ICardVersion) {
    if (card) {
      this.cardStyleId = card.cardStyle.id;
      this.cardVersionId = card.id;
      this.backgroundHexColour = card.cardStyle.backgroundHexColour || initialGray;
      this.backgroundImageShow = card.cardStyle.backgroundImageShow;
      this.overlayHexColour = card.cardStyle.overlayHexColour || initialGray;
      this.overlayShow = card.cardStyle.overlayShow;
    }
  }

  public nextState(state: IBrandingSectionAtributes) {
    const atr = new BrandingAtributes();
    atr.cardVersionId = state.cardVersionId;
    atr.cardStyleId = state.cardStyleId;
    atr.backgroundHexColour = state.backgroundHexColour;
    atr.backgroundImageShow = state.backgroundImageShow;
    atr.backgroundImageBase64 = state.backgroundImageBase64;
    atr.backgroundImageName = state.backgroundImageName;
    atr.overlayShow = state.overlayShow;
    atr.overlayHexColour = state.overlayHexColour;
    atr.logoBase64 = state.logoBase64;
    atr.logoName = state.logoName;
    atr.iconBase64 = state.iconBase64;
    atr.iconName = state.iconName;
    return atr;
  }

  public asState(): IBrandingSectionAtributes {
    return {
      cardVersionId: this.cardVersionId,
      cardStyleId: this.cardStyleId,
      backgroundHexColour: this.backgroundHexColour || null,
      backgroundImageShow: this.backgroundImageShow,
      backgroundImageBase64: this.backgroundImageBase64,
      backgroundImageName: this.backgroundImageName,
      overlayShow: this.overlayShow,
      overlayHexColour: this.overlayHexColour || null,
      logoBase64: this.logoBase64,
      logoName: this.logoName,
      iconBase64: this.iconBase64,
      iconName: this.iconName,
    };
  }

  public clear(nullable: boolean) {
    const atr = new BrandingAtributes();
    clearObject(atr, nullable);
    atr.cardStyleId = this.cardStyleId;
    atr.cardVersionId = this.cardVersionId;
    return atr;
  }

  public toJSON(): IBrandingSectionAtributes {
    return this.asState();
  }
}
