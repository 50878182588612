import ProtectedLayout from 'layouts/ProtectedLayout';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { IReduxState } from 'store';
import CustomLayout from 'layouts/CustomLayout';
import { IRouteProps } from 'modules/core/interfaces';

const ProtectedRoute: React.FC<IRouteProps & { auth: any }> = ({
  auth,
  isCustomLayout,
  children,
  isProfileFiltered,
  authFailureRedirectPath,
  ...rest
}) => {
  const chooseLayout = () => {
    if (isCustomLayout) {
      return <CustomLayout>{children}</CustomLayout>;
    } else {
      return <ProtectedLayout>{children}</ProtectedLayout>;
    }
  };

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return auth.isAuthenticated && isProfileFiltered ? (
          chooseLayout()
        ) : (
          <Redirect
            to={{
              pathname: authFailureRedirectPath || '/',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

const stateToProps = (state: IReduxState) => ({ auth: state.auth });

export default connect(stateToProps)(ProtectedRoute);
