import { IResellerCommissionSummary } from 'modules/dashboard/graphql/gql';

export class ResellerCommissionSummary implements IResellerCommissionSummary {
  public currency!: string;
  public commission!: number;
  public totalPending!: number;
  public totalCleared!: number;
  public totalEarnings!: number;

  constructor(data?: IResellerCommissionSummary) {
    if (data) {
      this.currency = data.currency;
      this.commission = data.commission;
      this.totalPending = data.totalPending;
      this.totalCleared = data.totalCleared;
      this.totalEarnings = data.totalEarnings;
    }
  }
}
