import { Box, Hidden, Typography } from '@material-ui/core';
import { BackToEditor, PreviewToolbar } from 'modules/core/components/CardToolbar/PreviewSwitch';
import Header from 'modules/core/components/Header';
import { useDefaultManifest } from 'modules/core/utils';
import { getCurrentVersion } from 'modules/editor/api/card-query';
import { CardView } from 'modules/editor/containers/Card/CardView';
import { E_PREVIEW_TYPE } from 'modules/editor/constants/enums';
import { ICardVersion } from 'modules/editor/graphql/gql';
import { actions } from 'modules/editor/reducers/cardReducer';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IReduxState } from 'store';

interface IDesktopPreview {
  active: 'mobile' | 'tablet' | 'desktop';
  cardVer: ICardVersion;
  isLoad: boolean;
  getCurrentVersion: () => Promise<void>;
  startLoad: () => void;
  finishLoad: () => void;
}

export function CreatePreview(width: number, previewType: E_PREVIEW_TYPE, modifier: string) {
  const DesktopPreview: React.FC<IDesktopPreview> = ({
    cardVer,
    getCurrentVersion: getCurrentCard,
    startLoad,
    finishLoad,
    isLoad,
  }) => {
    useDefaultManifest();
    useEffect(() => {
      if (!cardVer.id) {
        startLoad();
        getCurrentCard().then(() => finishLoad());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardVer]);

    return (
      <div className="App Site">
        <div className="Site-content">
          <div className="App-header _white">
            <Header exClass="_dark" isPublic={false}>
              <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                <Box>
                  <Typography variant="h4" noWrap>
                    <Box fontWeight="bold">Preview card</Box>
                  </Typography>
                </Box>
                <Hidden smDown>
                  <Box display="flex">
                    <PreviewToolbar />
                    <BackToEditor />
                  </Box>
                </Hidden>
              </Box>
            </Header>
            <div style={{ display: 'flex', justifyContent: 'center', padding: '60px 0' }}>
              <div style={{ maxWidth: width, minWidth: width, boxShadow: '0 0 31px 0px black' }}>
                {!isLoad ? (
                  <CardView cardVer={cardVer} previewType={previewType} isPreview={true} modifier={modifier} />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return connect(
    (state: IReduxState) => ({
      cardVer: state.currentVersion.currentVer,
      isLoad: state.currentVersion.loading,
    }),
    {
      getCurrentVersion,
      startLoad: actions.setLoadStatusStart,
      finishLoad: actions.setLoadStatusFinish,
    }
  )(DesktopPreview);
}
