import { withStyles, WithStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useCallback, MouseEvent } from 'react';
import { ISidemenuItem } from '.';
import { menuItemStyles } from './styles';
import { Button, Box } from '@material-ui/core';

interface IProps {
  item: ISidemenuItem;
  isActive: boolean;
  onClick: (item: ISidemenuItem) => void;
}

const SideMenuItem: React.FC<WithStyles<typeof menuItemStyles> & IProps> = ({ classes, item, isActive, onClick }) => {
  const clickHandler = useCallback(
    (evt: MouseEvent) => {
      evt.stopPropagation();
      onClick(item);
    },
    [item, onClick]
  );

  return (
    <div className={clsx(classes.root, isActive ? 'active' : '')} onClick={clickHandler}>
      <Button className={classes.button} id={`side_menu_item_btn_${item.id || ''}`}>
        {item.letter}
      </Button>
      <Box ml={1} id={`side_menu_item_title_${item.id || ''}`}>
        {item.title}
      </Box>
    </div>
  );
};
export default withStyles(menuItemStyles)(SideMenuItem);
