import { Link } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SuccessContent from 'modules/core/components/dialogs/SuccessContent';
import WarnContent from 'modules/core/components/dialogs/WarnContent';
import CircularIndeterminate from 'modules/core/components/progress';
import DialogWrapper from 'modules/core/containers/DialogWrapper';
import { InviteUserForm } from 'modules/users/components/UsersPage/InviteUserForm';
import { IInviteUser } from 'modules/users/interfaces/assigned-users';
import { inviteUser } from 'modules/users/thunks/assigned-users';
import React, { useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { EDITOR_ROUTE } from 'routes/routes';
import { IReduxState } from 'store';

import { styles } from './styles';

interface IStore {
  errors: string[];
  pending: boolean;
  actionPending: boolean;
  unoccupiedSlots: number;
  havaPublishedCard: boolean;
}

interface IInviteUserProps {
  setActiveItem?: (item: number) => void;
}

const mapStore = ({ users: { errors, actionPending, pending, unoccupiedSlots }, auth }: IReduxState) => ({
  errors,
  pending,
  actionPending,
  unoccupiedSlots,
  havaPublishedCard: !auth.profile.canChangeUrl,
});

interface IReducers {
  invite: (user: IInviteUser) => Promise<boolean>;
}

const mapReducers = {
  invite: inviteUser,
};

const InviteUsers: React.FC<WithStyles<typeof styles> & IStore & IReducers & IInviteUserProps> = ({
  classes,
  pending,
  unoccupiedSlots,
  invite,
  havaPublishedCard,
  setActiveItem = () => undefined,
}) => {
  const warnDialogRef = useRef<any>();
  const successDialogRef = useRef<any>();

  const handleInvite = useCallback(
    async (user: IInviteUser) => {
      if (havaPublishedCard) {
        const result = await invite(user);
        if (result) {
          successDialogRef.current.show();
        }
      } else {
        warnDialogRef.current.show();
      }
    },
    [invite, havaPublishedCard]
  );

  const closeWarn = useCallback(() => warnDialogRef.current.hide(), []);
  const closeSuccess = useCallback(() => {
    successDialogRef.current.hide();
    setActiveItem(0);
  }, [setActiveItem]);

  return (
    <div className={classes.root}>
      <Hidden smDown>
        <Typography variant="h5">Invite users</Typography>
      </Hidden>
      {pending ? (
        <CircularIndeterminate />
      ) : unoccupiedSlots > 0 ? (
        <>
          <Typography variant="subtitle2" color="textSecondary" className={classes.subtitle}>
            You have {unoccupiedSlots} more cards to assign
          </Typography>
          <Hidden mdUp>
            <Typography variant="subtitle2" color="textSecondary" align="center">
              Enter recipient details
            </Typography>
          </Hidden>
          <InviteUserForm buttonTitle="Send invite" onSubmit={handleInvite} />
        </>
      ) : (
        <Typography variant="subtitle2" color="textSecondary" className={classes.subtitle}>
          You have no more cards to assign
        </Typography>
      )}
      <DialogWrapper ref={warnDialogRef} onClose={closeWarn} dialogId="user_invite_warn">
        <WarnContent>
          You need to publish your card before inviting users. <Link href={EDITOR_ROUTE.path}>Click here</Link> to go to
          the editor and publish your first card.
        </WarnContent>
      </DialogWrapper>
      <DialogWrapper ref={successDialogRef} onClose={closeSuccess} dialogId="user_invite_success">
        <SuccessContent message="User successfully invited!" />
      </DialogWrapper>
    </div>
  );
};

export default connect(mapStore, mapReducers)(withStyles(styles)(InviteUsers));
