import React, { useState, useRef, useMemo } from 'react';
import { Typography, Collapse } from '@material-ui/core';
import { E_PREVIEW_TYPE } from 'modules/editor/constants/enums';
import { ICardVersion } from 'modules/editor/graphql/gql';
import { Actions } from 'modules/editor/components/CardSectionsView/MainInfo/Actions';
import { Avatar } from 'modules/editor/components/CardSectionsView/MainInfo/Avatar';
import Clear from '@material-ui/icons/Clear';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import clsx from 'clsx';
import { ReactComponent as LogoSvg } from 'assets/svg/logo.svg';
import { useClasses } from './styles';
import { getPreviewClass } from 'modules/core/utils';

interface IProps {
  isPreview?: boolean;
  previewType: E_PREVIEW_TYPE;
  cardVer: ICardVersion;
}

export const ContactDetailsHeader: React.FC<IProps> = (props) => {
  const { isPreview, previewType, cardVer } = props;
  const classes = useClasses();
  const disabled = !!isPreview;
  const previewClass = useMemo(() => getPreviewClass(previewType), [previewType]);

  const containerRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const toggle = () => setOpen(!open);

  const actions = <Actions
    cardVer={cardVer}
    disabled={disabled}
    previewType={previewType}
    // includeShareBtn
    exClass={classes.actions}
  />;

  return (
    <div className={clsx(classes.container, previewClass)} ref={containerRef}>
      <div className={classes.infoContainer}>
        <div className={ classes.info } onClick={toggle}>
          <LogoSvg className={clsx(classes.mdUp, classes.logo)} />
          <div className={clsx(classes.mdUp, classes.vr)}></div>
          <Avatar url={cardVer.avatarUrl} avatarClass={classes.avatar} />
          <Typography className={classes.name} >{cardVer.fullName}</Typography>
          <span className={clsx(classes.dropdownButton, classes.mdDown)} onClick={toggle}>
            { open ? <Clear /> : <ArrowDropDownIcon /> }
          </span>
        </div>
        <div className={clsx(classes.mdUp, classes.actionsContainer)}>
          {actions}
        </div>
        <Collapse in={open} className={classes.mdDown}>
          <hr className={classes.line} />
          <div className={classes.actionsContainer}>
            {actions}
          </div>
        </Collapse>
      </div>
    </div>
  );
};
