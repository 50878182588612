import React from 'react';
import { RoundButton } from 'modules/editor/components/CardSectionsView/MainInfo/RoundButton';
import { ICardModal } from 'modules/editor/components/CardSectionsView/MainInfo/Actions/useButtons';
import { Redo } from '@material-ui/icons';
import { useButtonStyle } from 'modules/editor/components/CardSectionsView/MainInfo/hooks/useButtonStyle';
import { ICardVersion } from 'modules/editor/graphql/gql';
import { ShareCardDialog } from 'modules/core/components/dialogs/ShareCardDialog';

interface IProps {
  disabled: boolean;
  cardVer: ICardVersion;
}

export const Button: React.FC<IProps> = (props) => {
  const { disabled, cardVer } = props;
  const { currentForCardId, cardStyle } = cardVer;
  const cardUrl = cardVer.card.urlSlug as string;
  const buttonStyle = useButtonStyle(cardStyle);
  const modal: ICardModal = (ref) => <ShareCardDialog cardId={currentForCardId} cardUrl={cardUrl} ref={ref} />;

  return (
    <div id="card_share_button">
      <RoundButton
        size="lg"
        icon={<Redo className="icon" />}
        buttonStyle={buttonStyle}
        disabled={disabled}
        modal={modal}
      />
    </div>
  );
};