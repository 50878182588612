import React, { FC, useState, ChangeEvent } from 'react';
import { useStyles } from '../../../styles';
import { Box, Typography } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { isDisabled } from 'modules/editor/components/CardSectionEditors/hooks';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { LinkUpload, InputField } from 'modules/core/components/fields';
import { NOT_ALLOWED_EXT } from 'modules/editor/constants/options';
import { AboutSectionLinkAtributes } from 'modules/editor/models';
import { IFileReadResult } from 'modules/core/utils';
import { URL_REGEXP } from 'modules/core/constants';
import { URL_INVALID } from 'modules/editor/constants/messages';

interface IProps {
  data: AboutSectionLinkAtributes;
  count: number;
  onDelete: () => void;
  getFileError: (fileName: string) => void;
  onFocus: () => void;
  uploadFile: (res: IFileReadResult) => void;
  changeUrl: (value: string) => void;
  onChangeText: (value: string) => void;
  save: () => void;
}

export const LinkItem: FC<IProps> = (props) => {
  const { data, count, onDelete, getFileError, onFocus, uploadFile, changeUrl, onChangeText, save } = props;
  const { id, displayLink, linkText = '', linkUrl, fileName } = data;

  const { item, heading, clearWrap, clear } = useStyles();
  const fields = useTypedSelector((state) => state.editorSteps.aboutFields);
  const [urlError, setUrlError] = useState<string>('');
  const [fileError, setFileError] = useState<string>('');

  function onSave() {
    // checks required fileds
    const saveAvalible = Boolean(linkText && (linkUrl || fileName || id));
    if (saveAvalible) {
      save();
    }
  }

  function onBlur(field: 'linkUrl' | 'linkText'): void {
    if (field === 'linkUrl') {
      if (!urlError && !fileError) {
        onSave();
      }
    } else {
      onSave();
    }
  }

  function validateUrl(value: string): void {
    if (URL_REGEXP.test(value)) {
      setUrlError('');
    } else {
      setUrlError(URL_INVALID);
    }
  }

  function onChangeUrl(event: ChangeEvent<HTMLInputElement>) {
    const {
      target: { value },
    } = event;
    changeUrl(value);
    validateUrl(value);
  }

  function onUpload(file: IFileReadResult): void {
    setUrlError('');
    setFileError('');
    uploadFile(file);
  }

  function onFileError(error: { fileName: string; err: string }) {
    const { err, fileName: nameError } = error;
    console.error("Upload error: ", err)
    setFileError(err);
    getFileError(nameError);
  }

  return (
    <div className={item} id={`editor_about_item_${id}`}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography className={heading}>Link {count < 10 ? `0${count}` : `${count}`}</Typography>
        </Box>
        <Box className={clearWrap} onClick={onDelete} id={`about-delete-item-${id}`}>
          <Box className={clear}>
            <Clear fontSize="inherit" color="inherit" />
          </Box>
        </Box>
      </Box>
      <LinkUpload
        className="_mb32"
        name={`about-linkUrl-${id}`}
        placeholder="Enter URL or upload file"
        value={displayLink}
        disabled={isDisabled(fields, 'linkUrl')}
        maxFileSize={25}
        error={urlError || fileError}
        disabledFileExt={NOT_ALLOWED_EXT}
        readFileErr={onFileError}
        onChange={onChangeUrl}
        onBlur={() => onBlur('linkUrl')}
        onFocus={onFocus}
        uploadImg={onUpload}
        acceptedTypes="audio/*, video/*, image/*, .pdf, .doc, .docx"
      />
      <InputField
        name={`about-linkText-${id}`}
        type="text"
        label="Enter link text"
        onChange={(e) => onChangeText(e.target.value)}
        disabled={isDisabled(fields, 'linkText')}
        onBlur={() => onBlur('linkText')}
        value={linkText}
        variant="filled"
      />
    </div>
  );
};
