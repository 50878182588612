export const UPDATE_PERSONAL_DETAILS_SCHEME = {
  $cardVersionId: 'ID!',
  $profilePhotoBase64: 'String',
  $profilePhotoName: 'String',
  $fullName: 'String',
  $jobTitle: 'String',
  $qualifications: 'String',
  $mobileNumber: 'String',
  $secondPhoneNumber: 'String',
  $emailAddress: 'String',
  $avatarCropX: 'Int',
  $avatarCropY: 'Int',
  $avatarCropH: 'Int',
  $avatarCropW: 'Int',
  $cardUrl: 'String',
  $contactDetailsHeaderShow: 'Boolean',
};
