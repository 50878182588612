import { Popover } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import { desktopPickerStyles } from './style';
import './styles.scss';

interface IDesktopPickerProps {
  name?: string;
  color: string;
  classes: Record<any, string>;
  disabled?: boolean;
  completePick(color: string, rgbColor: any): void;
}

export const DesktopColorPicker: React.FC<IDesktopPickerProps> = ({ color, completePick, classes, disabled, name }) => {
  const popoverStyles = desktopPickerStyles({});
  const [anchorEl, setAnchorEl] = useState(null);

  const ts = Date.now();

  const handleClick = (event: any) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const hadleColorPick = useCallback((clr: ColorResult) => completePick(clr.hex, clr.rgb), [completePick]);

  const open = Boolean(anchorEl);
  const id = open ? `picker-popover-${ts}` : undefined;

  return (
    <React.Fragment>
      <Button
        className={classes.pickButton}
        disabled={disabled}
        style={{ background: color }}
        onClick={handleClick}
        name={`color-picker-button-d-${name}`}
        children={''}
      />
      <Popover
        className={clsx(popoverStyles.popover)}
        classes={{ paper: popoverStyles.paper }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <SketchPicker color={color} onChangeComplete={hadleColorPick} />
      </Popover>
    </React.Fragment>
  );
};
