import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { lightGreen, white } from 'themes/colors';

interface IProps {
  className?: string;
}

const ScrollDown: React.FC<IProps> = ({ className }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, className, "_smDownHide")}>
      <div className={clsx(classes.arrow, className)}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <span className={clsx(classes.text, className)}>Scroll Down</span>
    </div>
  );
};

export default ScrollDown;


const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    bottom: 0,
    left: 92,
    transform: 'translate(-50%,-50%)',
    color: lightGreen,
    minWidth: 120,

    '&._white': {
      color: white,
    },
  },

  text: {
    fontSize: 14,
    lineHeight: theme.typography.pxToRem(26),
    fontFamily: 'F37 Ginger Light',

    '&._homepageLanding': {
      marginLeft: theme.spacing(3),
    },
  },

  arrow: {
    '& span': {
      display: 'block',
      width: 10,
      height: 10,
      borderBottom: `2px solid ${lightGreen}`,
      borderRight: `2px solid ${lightGreen}`,
      transform: 'rotate(45deg)',
      margin: -4,
      animation: 'scrollDownAnimation 2s infinite',
    },
    '& span:nth-child(2)': {
      animationDelay: '-0.2s',
    },
    '& span:nth-child(3)': {
      animationDelay: '-0.4s',
    },

    '&._white span': {
      borderColor: white,
    },
  },
}));
