import React, { createRef, useEffect, useState } from 'react';
import DialogWrapper from 'modules/core/containers/DialogWrapper';
import { IProcessingStatus } from 'modules/editor/graphql/subscriptions';
import { CardProcessingStatus } from 'modules/editor/models/card-processing-status';
import ProgressSpinner from 'modules/core/components/progress';
import { useCardProcessingDialogStyles } from './styles';
import clsx from 'clsx';
import CssGreenButton from 'modules/core/components/buttons/roundGreenButton';
import useMessageOnTimeout from 'modules/core/hooks/useMessageOnTimeout';
import { CONTACT_ROUTE } from 'routes/routes'
import { Link } from '@material-ui/core';

// subscription
import { useSubscription } from '@apollo/client'
import { cardProcessingStatusSubscription } from 'modules/editor/api/card-subscriptions';
import { ICardProcessingStatusVariables } from 'modules/editor/graphql/subscriptions';

interface ICardProcessingStatusDialog {
  show: boolean;
  onProcessingFinish: (error?: string) => void;
  cardVersionId: number;
  processAction?: "publish" | "switch";
}

const SUBSCRIPTION_NETWORK_TIMEOUT = 45000;

// const FINISHING_UP_PROCESSING_STATUS: IProcessingStatus = {
//   id: 0,
//   isFinished: false,
//   isProcessing: false,
//   isError: false,
//   message: "Loading card"
// };

export const CardProcessingStatusDialog: React.FC<ICardProcessingStatusDialog> = ({
  show,
  onProcessingFinish,
  cardVersionId,
  processAction
}) => {
  const ref = createRef<any>();
  const capitalize = (string: string) => string.replace(/\b\w/g, l => l.toUpperCase());
  const action = capitalize(processAction || "process");
  const classes = useCardProcessingDialogStyles();

  useEffect(() => show ? ref.current.show() : ref.current.hide(), [show, ref]);


  // graphql subscription logic


  const [processingStatus, setProcessingStatus] = useState<CardProcessingStatus>(new CardProcessingStatus());
  const [isFinishingUp, setIsFinishingUp] = useState<boolean>(false);

  const { isProcessing, isFinished, isError, message } = processingStatus;
  const isProcessingFinished = isFinished && !isProcessing;
  const messageEllipses = !message || isProcessingFinished || isError ? "" : "...";
  const formattedMessage = !isError && message + messageEllipses;

  // available values from useSubscription are { data, loading, error }
  const { data, error } = useSubscription<{
    cardProcessingStatus: { processingStatus: IProcessingStatus }},
    ICardProcessingStatusVariables
  >(
    cardProcessingStatusSubscription, {
      variables: { cardVersionId },
      skip: !cardVersionId || !show,
      // shouldResubscribe: true,
      // skip: !cardVersionId || !show || isFinishingUp,
    }
  );

  if (error) {
    console.log("[CardProcessingStatusDialog]: error:", error);
  }

  useEffect(() => {
    if (show) {
      if (data && isFinishingUp) {
        console.log("setting processingStatus to be blank: ");

        setProcessingStatus(new CardProcessingStatus());
        data.cardProcessingStatus.processingStatus = new CardProcessingStatus();
      }

      const rawData = data && data.cardProcessingStatus.processingStatus;

      if (rawData) {
        if (rawData.isFinished && !processingStatus.id) {
          console.log("Invalid update for processingStatus");
          return
        }

        console.log("setting Processing Status! ");

        const nextProcessingStatus = new CardProcessingStatus(rawData);
        setProcessingStatus(nextProcessingStatus);

        if (nextProcessingStatus.isFinished && !isFinishingUp) {
          const errorMessage = isError ? message : "";
          isError && console.error("Card processing error!", errorMessage)

          console.log("starting to finish up...");
          setIsFinishingUp(true);
          onProcessingFinish(errorMessage);
        }
      }
    }

    if (!show && isFinishingUp) {
      console.log("finish finishing up...");

      setProcessingStatus(new CardProcessingStatus());
      setIsFinishingUp(false)
    }
  }, [data, setProcessingStatus, isFinishingUp, setIsFinishingUp, onProcessingFinish, isError, message, show, processingStatus.id]);

  const { MessageOnTimeout } = useMessageOnTimeout({
    timeout: SUBSCRIPTION_NETWORK_TIMEOUT,
    startTimeout: show,
    stopTimeout: !show,
  });

  return (
    <DialogWrapper
      dialogId="processing_dialog"
      ref={ref}
      hideCloseIcon={true}
      dialogClasses={{ paper: classes.dialogPaper }}
    >
      <div className={classes.container}>
        <h2 className={classes.title}>{ action }ing your card</h2>

        <ProgressSpinner />

        <p className={classes.message}>{ formattedMessage }</p>

        <MessageOnTimeout>
          <div className={clsx(classes.message)}>
            <p className={classes.errorMessage}>
              Looks like the server is taking too long to respond.
              This can be caused by either poor connectivity or an error with our servers.
            </p>
            <p className={classes.errorMessage}>
              Refresh the page to reconnect to the server. Else,&nbsp;
              <Link id={`link_to_${CONTACT_ROUTE.path}`} className={classes.contactLink} href={CONTACT_ROUTE.path} target="_blank">
                contact customer service
              </Link>
              &nbsp;if issues continue.
            </p>

            <CssGreenButton className={classes.refreshButton} onClick={() => window.location.reload()}>
              Refresh
            </CssGreenButton>
          </div>
        </MessageOnTimeout>
      </div>
    </DialogWrapper>
  );
};

export default CardProcessingStatusDialog;
