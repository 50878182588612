import { CheckCircle } from '@material-ui/icons';
import React from 'react';

import { Clipboard, useClipboard } from './clipboard';
import { useStyles } from './styles';
import { useMediaQuery } from '@material-ui/core';

interface ICopyCardURLProps {
  url: string;
}

export const CopyCardURL: React.FC<ICopyCardURLProps> = ({ url }) => {
  const classes = useStyles();
  const { error, success, onSuccessCopy, onErrorCopy } = useClipboard();
  // remove items from landscape mode
  const matchLandscape = useMediaQuery('(min-height: 420px)');

  return (
    <div className={classes.container}>
      {matchLandscape && (
        <div className={classes.ico}>
          <CheckCircle color="primary" fontSize="inherit" />
        </div>
      )}
      <h2 className={classes.header}>Success!</h2>
      <div className={classes.contentContainer}>
        <div>Your updates have been published to {url}</div>
      </div>
      <div className={classes.contentContainer}>
        <Clipboard id="publish-clipboard-url" url={url} onSuccess={onSuccessCopy} onError={onErrorCopy}>
          <span className={classes.copyText}>Copy URL to clipboard</span>
        </Clipboard>
      </div>
      <div>
        {success ? <span className={classes.success}>Copied!</span> : <span>&nbsp;</span>}
        {error ? <span className={classes.error}>URL was not copied. Please try again.</span> : <span>&nbsp;</span>}
      </div>
    </div>
  );
};

export * from './clipboard';
