import { Hidden, Typography } from '@material-ui/core';
import { Edit, ShoppingCart, Visibility } from '@material-ui/icons';
import React from 'react';
import { When } from 'react-if';
import { useHistory } from 'react-router-dom';
import { BUY_ROUTE, EDITOR_ROUTE, PREVIEW_ROUTE } from 'routes/routes';
import { FabPreviewButton, PublishButton } from '../buttons/PublishButton';
import { useStyles } from './styles';
import { useNeedBuy } from 'hooks/useNeedBuy';

/**
 * Mobile action bar with buttons: Edit, Preview, Buy Now, Publish
 */
export const MobActionBar: React.FC = () => {
  const needBuy = useNeedBuy();
  const { actionContainer, previewMobile, previewMobileText } = useStyles();
  const history = useHistory();
  const {
    location: { pathname },
  } = history;

  return (
    <Hidden lgUp={true}>
      <div className={actionContainer}>
        <div className={previewMobile}>
          <FabPreviewButton isActive={pathname === EDITOR_ROUTE.path} onClick={() => history.push(EDITOR_ROUTE.path)}>
            <Edit color="inherit" />
          </FabPreviewButton>
          <Typography className={previewMobileText}>Edit</Typography>
        </div>

        <div className={previewMobile}>
          <FabPreviewButton isActive={pathname === PREVIEW_ROUTE.path} onClick={() => history.push(PREVIEW_ROUTE.path)}>
            <Visibility color="inherit" />
          </FabPreviewButton>
          <Typography className={previewMobileText}>Preview</Typography>
        </div>

        <div className={previewMobile}>
          <PublishButton isMobile={true} />
          <Typography className={previewMobileText}>Publish</Typography>
        </div>

        <When condition={needBuy}>
          <div className={previewMobile}>
            <FabPreviewButton href={BUY_ROUTE.path}>
              <ShoppingCart color="inherit" />
            </FabPreviewButton>
            <Typography className={previewMobileText}>Buy now</Typography>
          </div>
        </When>
      </div>
    </Hidden>
  );
};
