import { DesktopWindows, Tablet, PhoneAndroid, Close } from '@material-ui/icons';
import { useStyles, StyledButton, BackStyledButton } from './styles';
import { withRouter, useHistory } from 'react-router';
import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';

interface IPreviewButton {
  url: string;
  history: any;
  id?: string;
}

const PREVIEW_BUTTONS = [
  {
    id: 'desktop_preview_button',
    url: '/editor/preview-desktop',
    icon: <DesktopWindows color="inherit" fontSize="inherit" />,
  },
  {
    id: 'tabled_preview_button',
    url: '/editor/preview-tablet',
    icon: <Tablet color="inherit" fontSize="inherit" />,
  },
  {
    id: 'mobile_preview_button',
    url: '/editor/preview-mobile',
    icon: <PhoneAndroid color="inherit" fontSize="inherit" />,
  },
];

const PreviewBtn: React.FC<IPreviewButton> = ({ children, url, history, id }) => {
  const clickHandler = useCallback(() => history.push(url), [history, url]);

  return (
    <StyledButton id={id} className={url === history.location.pathname ? 'active' : undefined} onClick={clickHandler}>
      {children}
    </StyledButton>
  );
};

export const PreviewButton: any = withRouter(PreviewBtn as any);
/**
 * Preview toolbar with toogle view type buttons (tablet/mobile/desktop)
 */
export const PreviewToolbar: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.previewToolbar}>
      {PREVIEW_BUTTONS.map((pb, i) => (
        <div className={classes.previewBtnContainer} key={i}>
          <PreviewButton id={pb.id} url={pb.url}>
            {pb.icon}
          </PreviewButton>
        </div>
      ))}
    </div>
  );
};

export const BackToEditor: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const clickHandler = useCallback(() => history.push('/editor'), [history]);
  return (
    <Box display="flex" alignItems="center">
      <div className={classes.devider} />
      <div className={classes.backText} onClick={clickHandler}>
        Back to editor
      </div>
      <BackStyledButton id="preview_back_to_editor_button" onClick={clickHandler}>
        <Close />
      </BackStyledButton>
    </Box>
  );
};
