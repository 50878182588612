import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import React, { useState, useEffect, useMemo } from 'react';
import radioButtonStyles from './styles/radioButtonsStyles';
import clsx from 'clsx';

export interface IRadioProps {
  value?: any;
  name?: string;
  label: string[];
  onSelect: (value: any, name?: string) => void;
  disabled?: boolean;
  exClass?: string;
  exContainerClass?: string;
}

export const RadioButtonGroup: React.FC<IRadioProps> = ({
  label,
  value,
  onSelect,
  disabled,
  name,
  exClass,
  exContainerClass
}) => {
  const classes = radioButtonStyles();
  const [val, setVal] = useState(value);

  useEffect(() => {
    if (val !== value) {
      setVal(value);
    }
  }, [val, value, setVal]);
  const componentId = useMemo(() => Date.now().toString(16), []);

  const buttons = label.map((item, index) => {
    return (
      <FormControlLabel
        id={`radio-btn-${name || componentId}-${item}`}
        disabled={disabled}
        name={name}
        onChange={(e: any) => {
          setVal(e.currentTarget.value);
          onSelect(item, name);
        }}
        key={index}
        className={clsx(classes.radioButton, exClass)}
        value={item}
        control={<Radio />}
        label={item}
        labelPlacement="end"
      />
    );
  });

  return (
    <div className={clsx(classes.container, exContainerClass)}>
      <RadioGroup name={name} aria-label="position" row={true} value={val}>
        {buttons}
      </RadioGroup>
    </div>
  );
};
