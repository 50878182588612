import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import clsx from 'clsx';
import { IResponsive } from 'modules/core/interfaces';
import { useDefaultManifest } from 'modules/core/utils';
import React, { useEffect, useState, lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { IReduxState } from 'store';

import useStylesPage from 'modules/public/pages/main/pageStyles';

import Testimonials, { ITestimonialContentData } from './Testimonials';

import DanielBrooks from 'assets/images/testimonials/Daniel-Brooks.jpg';
import JohnClifford from 'assets/images/testimonials/John-Clifford.jpg';
import SairaLaghari from 'assets/images/testimonials/Saira-Laghari.jpg';
import SamiYusuf from 'assets/images/testimonials/Sami-Yusuf.jpg';

import LandingSection from './LandingSection'; // keep this inline loaded because it's the first thing users see.
const WhyYoullLove = lazy(() => import('./WhyYoullLove'));
const HowItWorks = lazy(() => import('./HowItWorks'));
const PartnerWithWLT = lazy(() => import('./PartnerWithWLT'));
const SummarySection = lazy(() => import('./SummarySection'));
const ResellerSection = lazy(() => import('./ResellerSection'));


const TestimonialContent: ITestimonialContentData[] = [
  {
    name: "Saira Laghari",
    company: "Wedding Planner",
    review: "Since having my Go Paperless digital card, I’ve expanded my database of engaged contacts – and I’m ready to hit the ground running.",
    image: SairaLaghari,
  },
  {
    name: "Sami Yusuf",
    company: "Architect",
    review: "Personal recommendations mean new business for architects. And that’s the single biggest benefit I’ve experienced since switching to Go Paperless.",
    image: SamiYusuf,
  },
  {
    name: "Daniel Brooks",
    company: "Film Production & Technology Graduate",
    review: "Traditional CVs don’t impress producers. Go Paperless allows me to showcase my skills and qualifications in a way that makes an instant impact – I’ve even included my showreel.",
    image: DanielBrooks,
  },
  {
    name: "John Clifford",
    company: "Barber",
    review: "There’s no need to spend big on a website. Since using Go Paperless, I’ve boosted repeat business, increased new bookings and enjoyed greater uptake on special offers and promotions.",
    image: JohnClifford,
  },
];

const MainPage: React.FC<{ isAuth: boolean }> = ({ isAuth }) => {
  useDefaultManifest();
  const pageStyles = useStylesPage();

  const [values, setValues] = useState<IResponsive>({
    isMobile: window.innerWidth <= 960 ? true : false,
    activeSlide: 0,
  });

  useEffect(() => {
    const handler = () => {
      if (window.innerWidth <= 960) {
        setValues({ ...values, isMobile: true });
      } else {
        setValues({ ...values, isMobile: false });
      }
    };

    window.addEventListener('resize', handler);
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, [values]);

  return (
    <div className={clsx(pageStyles.page, '_padding-sm')}>
      <Helmet>
        <title>A Greener Way To Connect, Engage & Grow | Go Paperless Digital Cards</title>
        <meta
          name="description"
          content="Your Go Paperless digital card doubles as business card, marketing platform, digital CV or even website – making it easy to stand out from the crowd. One digital card. Infinite opportunities."
        />
      </Helmet>

      <LandingSection isAuth={isAuth} />

      <Suspense fallback={null}>
        <WhyYoullLove />
      </Suspense>

      <Suspense fallback={null}>
        <HowItWorks />
      </Suspense>

      <Suspense fallback={null}>
        <PartnerWithWLT />
      </Suspense>

      <Suspense fallback={null}>
        <SummarySection />
      </Suspense>

      <Suspense fallback={null}>
        <ResellerSection />
      </Suspense>

      <Testimonials responsiveValues={values} items={TestimonialContent} />
    </div>
  );
};

export default connect((state: IReduxState) => ({ isAuth: state.auth.isAuthenticated }))(MainPage);
