import React, { FC } from 'react';
import { Email, Error } from '@material-ui/icons';
import DialogWrapper from 'modules/core/containers/DialogWrapper';
import { useStyles } from 'modules/editor/containers/Card/GdprDialog/useStyles';
import { IGdprState } from '..';
import { Typography } from '@material-ui/core';
import { useDialogRef } from '../hooks/useDialogRef';

interface IProps {
  values: IGdprState;
  onClose: () => void;
}

export const EmailDialog: FC<IProps> = (props) => {
  const { values, onClose } = props;

  const ref = useDialogRef();
  const classes = useStyles();

  return (
    <DialogWrapper ref={ref} dialogId="email_req_recieved" onClose={onClose}>
      <div className={classes.container}>
        <div className={classes.icon}>{values.error ? <Error /> : <Email />}</div>
        <Typography variant="h5" className={classes.SuccessHeader}>
          {values.error ? 'Error!' : 'Thanks for opting in.'}
        </Typography>
        <Typography align="center" variant="body1">
          {values.error ? 'Please try again or refresh the page.' : 'Your preferences have been saved.'}
        </Typography>
      </div>
    </DialogWrapper>
  );
};
