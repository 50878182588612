import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStylesContainer = makeStyles((theme: Theme) =>
  createStyles({
    exploreMore: {
      [theme.breakpoints.down('xs')]: {
        maxWidth: 'none',
        width: '100%',
      },
    },
    containerColumn: {
      display: 'flex!important',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: 'calc(50% - 20px)',
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(10),
        width: '100%',
        marginRight: 0,
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        minHeight: '100%',
        marginRight: 20,
        '& button': {
          minWidth: '100%',
          margin: '0 auto',
        },
        '& a.MuiButton-root': {
          minWidth: '100%',
        },
      },
      '&:focus': {
        outline: 'none!important',
      },
      '&:not(:last-child)': {
        marginRight: 60,
      },
      '&> :first-child': {
        width: '100%',
        marginRight: 0,
      },
      '&.noJustify': {
        justifyContent: 'normal',
      },
    },
    containerForm: {
      display: 'flex',
      flexDirection: 'column',
    },
    containerAccordion: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 100,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 93,
      },
    },
    containerLinkLarge: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: 90,
        paddingTop: 10,
      },

      '& .slick-track': {
        display: 'flex',
      },
      '&>:first-child': {
        fontSize: 160,
        lineHeight: 0.75,
        [theme.breakpoints.down('md')]: {
          fontSize: 120,
          lineHeight: 0.75,
          position: 'relative',
          marginBottom: 10,
        },
        [theme.breakpoints.down('sm')]: {
          height: 155,
        },
      },
      '&>:last-child': {
        fontSize: 38,
        lineHeight: theme.typography.pxToRem(50),
        minHeight: 160,
        paddingTop: 30,
        fontFamily: 'F37 Ginger',
        fontWeight: 400,
        [theme.breakpoints.down('md')]: {
          bottom: 44,
          fontSize: 34,
          minHeight: 'unset',
          marginBottom: 0,
          position: 'absolute',
          fontFamily: 'F37 Ginger',
          lineHeight: 1,
          fontWeight: 400,
        },
        [theme.breakpoints.down('sm')]: {
          bottom: 0,
        },
      },
    },
    containerImgs: {
      marginBottom: 60,
      '&._marginMd': {
        marginBottom: 95,
        [theme.breakpoints.down('md')]: {
          marginBottom: 58,
        },
      },
      '&._marginLg': {
        marginBottom: 120,
        [theme.breakpoints.down('md')]: {
          display: 'flex!important',
          marginBottom: theme.spacing(6),
          justifyContent: 'flex-start!important',
          '&._reverse': {
            justifyContent: 'flex-end!important',
          },
        },
        [theme.breakpoints.down('sm')]: {
          marginBottom: 0,
        },
      },
      [theme.breakpoints.down('md')]: {
        display: 'flex!important',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'flex-start!important',
        marginBottom: theme.spacing(6),
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: 0,
      },
      '&._reverse': {
        [theme.breakpoints.down('md')]: {
          display: 'flex!important',
          flexDirection: 'column-reverse',
          justifyContent: 'flex-end!important',
        },
      },
      '&:focus': {
        outline: 'none!important',
      },
    },
    containerNumbers: {
      marginBottom: 155,
      // width: "50%",
      maxWidth: "60%",

      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        marginBottom: 92,
        maxWidth: "100%",
        textAlign: 'center',
        // minHeight: "100vh",
      },
    },
    containerAdvantages: {
      flexDirection: 'column',
      alignItems: 'flex-start',

      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    containerLinks: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    containerLink: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      width: 'calc(50% - 60px)',
      '&._about': {
        width: '100%',
      },
      '&:not(:last-child)': {
        marginRight: 72,
      },

      [theme.breakpoints.down('xs')]: {
        width: '100%',

        '&:not(:last-child)': {
          marginRight: 0,
          marginBottom: 35,
        },
      },
    },
    containerContent: {
      marginBottom: 159,
      '&:last-child': {
        marginBottom: 0,
      },
      '&._home': {
        [theme.breakpoints.up('lg')]: {
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          minWidth: 520,
        },

        [theme.breakpoints.down('md')]: {
          marginTop: 0,
          marginBottom: 40,
          minHeight: 'calc(100vh - 100px)',
        },

        [theme.breakpoints.down('xs')]: {
          marginBottom: 40,
          '&> :last-child': {
            width: '100%',
            maxWidth: '100%',
          },
        },
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
      },
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
      maxWidth: 1233,
      width: '100%',
      alignItems: 'stretch',
      zIndex: 2,
      '&._column': {
        flexDirection: 'column',
      },
      '&._landing': {
        '& > :last-child': {
          maxWidth: 210,
          [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            marginTop: 50,
          },
        },
      },
      '&._dashboard': {
        '&:not(:last-child)': {
          marginBottom: 50,
        },
      },

      '&._billing': {
        justifyContent: 'flex-start',
        flexWrap: 'nowrap',
        '& >:first-child': {
          '& >:first-child': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          },
        },
        [theme.breakpoints.down('sm')]: {
          flexWrap: 'wrap',
        },

        '&> *': {
          width: '100%',
          maxWidth: 509,
          marginRight: 100,
          [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            marginRight: 0,

            '&:not(:last-child)': {
              marginBottom: 100,
            },
          },
        },
      },
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        '&._nowrap': {
          flexWrap: 'nowrap',
        },
      },

      '&._mobile-carusel': {
        [theme.breakpoints.down('md')]: {
          display: 'block',
        },
      },

      '&._with-img': {
        alignItems: 'flex-end',
      },

      '&._sticky-scroll': {
        alignItems: 'flex-start',

        '& $containerNumbers': {
          position: 'sticky',
          top: 0,
        },
      },
    },
  })
);

export default useStylesContainer;
