import React, { useCallback, useMemo, ChangeEvent } from 'react';
import { InputField } from 'modules/core/components/fields';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { IFileReadResult, readFileAsBase64 } from 'modules/core/utils';
import { linkUploadStyles } from './styles';
import clsx from 'clsx';

const MB = 1024 ** 2;

export interface IUploadLinkProps {
  name?: string;
  value?: string;
  maxFileSize?: number;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  uploadImg?: (result: IFileReadResult) => void;
  readFileErr?: (err: { fileName: string; err: string }) => void;
  disabledFileExt?: string[];
  placeholder: string;
  error?: string;
  acceptedTypes?: string;
  disabled?: boolean;
  className?: any;
}

export const LinkUpload: React.FC<IUploadLinkProps> = ({
  name,
  onChange,
  onBlur,
  value,
  placeholder,
  uploadImg,
  disabled,
  disabledFileExt,
  error,
  readFileErr,
  maxFileSize = Infinity,
  acceptedTypes = 'image/*',
  onFocus,
  className,
}) => {
  const classes = linkUploadStyles();
  const handleSelectFile = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement> & any) => {
      const file: File = e.target.files[0];
      if (file.size > MB * maxFileSize && readFileErr) {
        readFileErr({ fileName: file.name, err: `Please select a file not exceeding ${maxFileSize} mb` });
        return;
      }
      if (disabledFileExt) {
        const foundExt = disabledFileExt.find((ext) => file.name.search(ext) > -1);
        if (foundExt && readFileErr) {
          readFileErr({
            fileName: file.name,
            err: `Files with the ${foundExt} extension are not allowed.`,
          });
          return;
        }
      }
      try {
        const result = await readFileAsBase64(file);
        if (uploadImg) {
          uploadImg(result);
        }
      } catch (error) {
        if (readFileErr) {
          readFileErr({
            fileName: file.name,
            err: error,
          });
        }
      }
    },
    [uploadImg, readFileErr, maxFileSize, disabledFileExt]
  );
  const componentId = useMemo(() => Date.now().toString(16), []);
  return (
    <div className={classes.inputWrapper}>
      <InputField
        name={`link-upload-input-${name}`}
        type="text"
        exClass={clsx(classes.uploadInpStyle, "_mb32")}
        label={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        value={value}
        disabled={disabled}
        variant="filled"
        error={!!error}
      />
      <div className={classes.uploadWrapper}>
        <input
          id={componentId}
          name={`contained-button-file-${name}`}
          accept={acceptedTypes}
          className={classes.input}
          multiple
          type="file"
          onChange={handleSelectFile}
        />
        {!disabled ? (
          <label htmlFor={componentId} id={`link-upload-file-${componentId}`}>
            <CloudUploadIcon className={classes.icon} />
          </label>
        ) : null}
      </div>
    </div>
  );
};
