import React, { useState, useEffect } from 'react';
import { generateQRCode } from 'modules/core/utils/qrCode';
import { Container } from '@material-ui/core';
import { useFooterStyles } from './styles';
import { useTypedSelector } from 'hooks/useTypedSelector';

interface IQRCodeSectionProps {
  classes: Record<string, string>;
  slug: string | undefined;
}

export const QRCodeSection: React.FC<IQRCodeSectionProps> = ({ classes, slug }) => {
  const footerClasses = useFooterStyles();
  const [QRCode, setQRCode] = useState<string | null>(null);
  const canChangeUrl = useTypedSelector((state) => state.auth.profile.canChangeUrl);
  const origin = window.location.origin;
  const url = !canChangeUrl && slug ? `${origin}/${slug}` : origin;

  useEffect(() => {
    generateQRCode(url, setQRCode)
  }, [url])

  if (QRCode) {
    return (
      <div className={footerClasses.darken}>
        <Container fixed maxWidth="md" style={{ maxWidth: '730px', zIndex: 2 }}>
          <div className={classes.qrCodeContainer}>
            <p className={classes.qrCodeCta}>SCAN THIS CARD</p>
            <img src={QRCode} alt="QRCode for the card" className={classes.qrCode} />
          </div>
        </Container>
      </div>
    );
  } else {
    return (
      <></>
    )
  }
};
