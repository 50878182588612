import React, { useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import Amount from 'modules/billing/components/Billing/Amount';
import { useStyles } from 'modules/billing/components/Billing/styles';
import { PayData } from 'modules/billing/components/BuyPage/Steps/PayData';
import { findCurrency } from 'currency-formatter';
import { injectStripe } from 'react-stripe-elements';
import clsx from 'clsx';

interface ISummaryAndCardDetails {
  ccy: string;
  price: number;
  discountAmount: number;
  discountPercentage: number;
  vatAmount: number | null;
  vatPercentage: number | null;
  total: number;
  disabled: boolean;
  lifetime?: boolean;
  purchaseHandler: (pm: string) => void;
  errHandler: (useRedirect: boolean, error?: any) => void;
}

export const SummaryAndCardDetails = injectStripe<ISummaryAndCardDetails>((props) => {
  const {
    ccy,
    price,
    discountAmount,
    discountPercentage,
    vatAmount,
    vatPercentage,
    total,
    disabled,
    lifetime = false,
    purchaseHandler,
    errHandler,
    stripe,
  } = props;
  const classes = useStyles();
  const ctaText = useMemo(() => {
    const symbol = ccy.toUpperCase() === 'USD' ? 'US$' : findCurrency(ccy.toUpperCase()).symbol;
    return `PAY ${symbol + (total / 100).toFixed(2)}`;
  }, [ccy, total]);

  return (
    <div className={clsx(classes.content, '_resellerPack')}>
      <div className={classes.purchaseSummary}>
        <div className={classes.popupHead}>
          <Typography className={classes.popupTitle} variant="h4">
            Purchase summary
          </Typography>
          {/* <Typography className={classes.popupDescription}>Summary</Typography> */}
        </div>
        <div className={clsx(classes.popupContent, '_resellerPack')}>
          <div className={classes.summaryItem}>
            <div>
              {!lifetime && <div style={{whiteSpace: 'nowrap'}}>Standard Reseller Membership</div>}
              {lifetime && <div style={{whiteSpace: 'nowrap'}}>Lifetime Reseller Membership</div>}
              {!lifetime && <div className={classes.starterPackDesc}>
                Includes your reseller starter pack, annual reseller, personal & promotional digital card subscriptions
              </div>}
              {lifetime && <div className={classes.starterPackDesc}>
                Includes your reseller starter pack, reseller portal, personal & promotional digital cards...
              </div>}
            </div>
            <Box className={clsx(classes.radioTextLargeLighter, '_resellerPack')}>
              <Amount id="summary_count" ccy={ccy} amount={price} />
            </Box>
          </div>
          {discountAmount ? (
          <div className={classes.summaryItem}>
            <div>Discount @{discountPercentage}%</div>
            <Box className={clsx(classes.radioTextLargeLighter, '_resellerPack')}>
              <Amount id="summary_discount" ccy={ccy} amount={discountAmount} negative />
            </Box>
          </div>
          ) : null}
          {vatPercentage ? (
            <div className={classes.summaryItem}>
              <div>VAT @{vatPercentage || 0}%</div>
              <Box className={clsx(classes.radioTextLargeLighter, '_resellerPack')}>
                <Amount id="summary_vat" ccy={ccy} amount={vatAmount || 0} />
              </Box>
            </div>
          ) : null}
          <div className={classes.summaryItem}>
            <Box fontWeight="bold">TOTAL</Box>
            <Box id="total_amount" className={clsx(classes.radioTextLarge, '_resellerPack')}>
              <Amount id="summary_total" ccy={ccy} amount={total} />
            </Box>
          </div>
        </div>
      </div>
      <PayData next={purchaseHandler} err={errHandler} disabled={disabled} ctaText={ctaText} stripe={stripe}/>
    </div>
  );
});
