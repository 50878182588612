import React, { useMemo, useEffect, useCallback } from 'react';
import { TextFieldProps } from '@material-ui/core/TextField';
import { PHONE_COUNTRIES } from 'modules/core/constants/config';
import { PREFERRED_COUNTRIES } from 'modules/core/constants/config';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useStyles } from './styles';
import clsx from 'clsx';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { Clear } from '@material-ui/icons';

const phoneUtil = PhoneNumberUtil.getInstance();

export type InputValidState = 'valid' | 'invalid' | 'empty';

interface IProps {
  onChange: (value?: string) => void;
  inputProps: TextFieldProps;
  disabled?: boolean;
  onBlur?: () => void;
  error?: string;
  exClass?: string;
  variant?: string;
  getValid?: (value: InputValidState) => void;
  clear?: () => void;
}

export const MobileField: React.FC<IProps> = (props) => {
  const { onChange, inputProps, onBlur, disabled, exClass, variant, getValid, clear } = props;
  const classes = useStyles();

  const validatePhoneNumber = useCallback((value: string) => {
    const phoneObj = phoneUtil.parseAndKeepRawInput(value);
    return phoneUtil.isValidNumber(phoneObj);
  }, [])

  const isValidNumber = useMemo(() => {
    try {
      return typeof inputProps.value === 'string' ? validatePhoneNumber(inputProps.value) : false;
    } catch (e) {
      return false;
    }
  }, [inputProps.value, validatePhoneNumber]);

  // pick country code if authorized from profile else from card
  const profileCountryCode = useTypedSelector((state) => state.auth.profile.countryCode);
  const publicUser = useTypedSelector((state) => state.cardVersionReducer.publicVersion.card.account);
  const apiCountryCode = useTypedSelector((state) => state.general.country_code);

  // pick country code from any source
  const defaultCountry = useMemo((): string => {
    // there is some updateState bug with MuiPhoneNumber component
    // when defaultCountry is truthy, it sometimes sets the initial input value to a blank string
    // hence, we must initialize the component with a blank defaultCountry if an initial input value is present
    //
    // Update Feb 2021: Setting defaultCountry all the time to fix component reset issue, seen on ReferralManager

    const code: string = profileCountryCode || (publicUser && publicUser.countryCode) || apiCountryCode || "GB";
    const res = code.toLowerCase();

    return res;
  }, [profileCountryCode, publicUser, apiCountryCode]);

  useEffect(() => {
    const value = inputProps.value;
    if (getValid && typeof value === 'string') {
      try {
        getValid(validatePhoneNumber(value) ? 'valid' : 'invalid');
      } catch (e) {
        // throws error when there is only country code there
        getValid('empty');
      }
    }
  }, [inputProps.value, getValid, validatePhoneNumber]);

  // to return only plus and digits
  function onNewValue(value: string): string | undefined {
    const matches = value.match(/\+?\d*/g);
    if (matches) {
      let newValue: string | undefined = matches.reduce((prev, cur) => prev + cur, '');

      // if newValue is only "+", then the user has probably tried to clear the MuiPhoneNumber input field...
      newValue = newValue === "+" ? undefined : newValue;
      onChange(newValue);
      return newValue;
    }
    return '';
  }

  const showReset = useMemo((() => (clear && isValidNumber)), [clear, isValidNumber]);
  const resetField = useCallback(() => (clear && clear()), [clear]);

  return (
    <div className={classes.container}>
      <div className={clsx(classes.filler, disabled ? classes.disabled : undefined)} />
      {showReset && (
        <span className={clsx(classes.clear, "MobileField-clear")} onClick={resetField}>
          Reset <Clear fontSize="inherit" className={classes.clearIcon}/>
        </span>
      )}
      <MuiPhoneNumber
        className={clsx(
          classes.root,
          disabled ? classes.disabledSecond : undefined,
          exClass === undefined ? '' : `${exClass}`
        )}
        defaultCountry={defaultCountry}
        disableAreaCodes={true}
        onlyCountries={PHONE_COUNTRIES}
        preferredCountries={PREFERRED_COUNTRIES}
        onChange={onNewValue}
        onBlur={onBlur}
        variant={variant}
        {...inputProps}
      />
    </div>
  );
};
