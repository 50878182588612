import { INewsAtributes } from 'modules/editor/graphql/mutations';
import { ICardVersion } from 'modules/editor/graphql/gql';
import { IModel } from 'modules/core/interfaces/model';
import { clearObject } from 'modules/core/utils';
import { ObjectSchema, string, object } from 'yup';
import { URL_INVALID } from 'modules/editor/constants/messages';
import { URL_REGEXP } from 'modules/core/constants';

export class NewsAtributes implements IModel<INewsAtributes> {
  public cardVersionId!: number;
  public linkText?: string = '';
  public linkUrl?: string = '';
  public linkUrlShow?: boolean = false;
  public title?: string = '';
  public showSectionHeader?: boolean = true;
  public newsImageBase64?: string;
  public newsImageName?: string;
  [props: string]: any;

  private schema: ObjectSchema<Partial<INewsAtributes>> = object().shape({
    linkUrl: string().matches(URL_REGEXP, { excludeEmptyString: false, message: URL_INVALID }),
  });

  constructor(card?: ICardVersion) {
    if (card) {
      this.cardVersionId = card.id;
      this.linkText = card.newsSection.linkText || '';
      this.linkUrl = card.newsSection.linkUrl || '';
      this.linkUrlShow = card.newsSection.linkUrlShow;
      this.title = card.newsSection.title || '';
      // ensure that default value for showSectionHeader is true. Necessary for when the section does not yet exist in db.
      this.showSectionHeader = card.newsSection.showSectionHeader === false ? false : true;
    }
  }

  public asState(): INewsAtributes {
    const state: any = {};
    Object.keys(this).forEach((key) => (state[key] = this[key]));
    return state;
  }

  public nextState(state: NewsAtributes) {
    const atr = new NewsAtributes();
    Object.keys(atr).forEach((key) => (atr[key] = state[key]));
    return atr;
  }

  public clear(nullable: boolean) {
    const atr = new NewsAtributes();
    clearObject(atr, nullable);
    atr.cardVersionId = this.cardVersionId;
    atr.schema = this.schema;
    return atr;
  }

  public toJSON(): INewsAtributes {
    return {
      cardVersionId: this.cardVersionId,
      linkText: this.linkText ? this.linkText : null,
      linkUrl: this.linkUrl ? this.linkUrl : null,
      title: this.title ? this.title : null,
      showSectionHeader: this.showSectionHeader,
      linkUrlShow: this.linkUrlShow,
      newsImageBase64: this.newsImageBase64,
      newsImageName: this.newsImageName,
    };
  }

  public validate(path: string) {
    return this.schema.validateSyncAt(path, this.asState());
  }
}
