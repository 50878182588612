import { ID } from 'modules/core/types';
import { IInvitation } from 'modules/auth/interfaces/auth';
import { UserProfile } from 'modules/auth/models/user-profile';

export class Invitation implements IInvitation {
  public id: ID = 0;
  public firstName: string = '';
  public lastName: string = '';
  public fullName: string = '';
  public inviteToken: string = '';
  public email: string = '';
  public mobilePhone?: string = '';
  public status: string = '';
  public user: UserProfile = new UserProfile({});

  constructor(data?: Partial<IInvitation>) {
    if (data) {
      this.id = data.id || 0;
      this.firstName = data.firstName || '';
      this.lastName = data.lastName || '';
      this.fullName = data.fullName || '';
      this.inviteToken = data.inviteToken || '';
      this.email = data.email || '';
      this.mobilePhone = data.mobilePhone || '';
      this.status = data.status || '';
      this.user = new UserProfile(data.user);
    }
  }

  public initialUserValues(): object {
    const firstName = this.user.firstName || this.firstName;
    const lastName = this.user.lastName || this.lastName;
    const email = this.user.email || this.email;
    const mobilePhone = this.user.mobilePhone || this.mobilePhone;
    const country = this.user.countryCode;
    const accepted_nda_on = false;
    const accepted_privacy_terms_on = false;
    const accepted_reseller_agreement_on = false;

    return {
      firstName,
      lastName,
      email,
      mobilePhone,
      country,
      accepted_nda_on,
      accepted_privacy_terms_on,
      accepted_reseller_agreement_on,
    }
  }
}
