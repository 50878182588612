import { IAssignedUsers, IInviteUser, IInvitePayload } from 'modules/users/interfaces/assigned-users';
import { createSymbiote, Symbiotes } from 'redux-symbiote';
import { ID } from 'modules/core/types';

interface ISlotStatusPayload {
  slotId: ID;
  status: string;
}

interface IReassignPayload {
  slotId: ID;
  user: IInviteUser;
  status: string;
}

export interface IAssignedUsersState extends IAssignedUsers {
  pending: boolean;
  actionPending: boolean;
  errors: string[];
}

const initialState: IAssignedUsersState = {
  assignedUsers: [],
  occupiedSlots: 0,
  totalSlots: 0,
  unoccupiedSlots: 0,
  pending: false,
  actionPending: false,
  errors: [],
};

export interface IAssignedUsersActions extends Symbiotes<IAssignedUsersState> {
  setUsers: (state: IAssignedUsersState, users: IAssignedUsers) => IAssignedUsersState;
  setSlotStatus: (state: IAssignedUsersState, payload: ISlotStatusPayload) => IAssignedUsersState;
  inviteUser: (state: IAssignedUsersState, payload: IInvitePayload) => IAssignedUsersState;
  removeAccount: (state: IAssignedUsersState, slotId: ID) => IAssignedUsersState;
  reassignUser: (state: IAssignedUsersState, payload: IReassignPayload) => IAssignedUsersState;
  setErrors: (state: IAssignedUsersState, errors?: string[]) => IAssignedUsersState;
  setPending: (state: IAssignedUsersState, pending?: boolean) => IAssignedUsersState;
  setActionPending: (state: IAssignedUsersState, actionPending?: boolean) => IAssignedUsersState;
}

const symbiotes: IAssignedUsersActions = {
  setUsers: (state, users) => ({ ...state, ...users }),

  setSlotStatus: (state, payload: ISlotStatusPayload) => ({
    ...state,
    assignedUsers: state.assignedUsers.map((item) =>
      item.slot.id === payload.slotId ? { ...item, slot: { ...item.slot, status: payload.status } } : item
    ),
  }),

  inviteUser: (state, payload: IInvitePayload) => ({
    ...state,
    assignedUsers: state.assignedUsers.concat([
      {
        ...payload.user,
        slot: { id: -1, status: payload.status, subscriptionId: -1, accountId: -1 },
      },
    ]),
    occupiedSlots: state.occupiedSlots + 1,
    unoccupiedSlots: state.unoccupiedSlots - 1,
  }),

  removeAccount: (state, slotId: ID) => ({
    ...state,
    assignedUsers: state.assignedUsers.filter((item) => item.slot.id !== slotId),
    occupiedSlots: state.occupiedSlots - 1,
    unoccupiedSlots: state.unoccupiedSlots + 1,
  }),

  reassignUser: (state, payload: IReassignPayload) => ({
    ...state,
    assignedUsers: state.assignedUsers.map((slotUser) =>
      slotUser.slot.id === payload.slotId
        ? {
            ...slotUser,
            slot: { ...slotUser.slot, status: payload.status },
            ...payload.user,
          }
        : slotUser
    ),
  }),

  setErrors: (state, errors: string[] = []) => ({ ...state, errors }),

  setPending: (state, pending = false) => ({ ...state, pending }),
  setActionPending: (state, actionPending = false) => ({
    ...state,
    actionPending,
  }),
};

export const { actions, reducer } = createSymbiote<IAssignedUsersState, IAssignedUsersActions>(initialState, symbiotes);
