import React, { FormEvent, useCallback, useMemo, useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import { ReactComponent as DartSvg } from 'assets/svg/dart.svg';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import { authenticateByEmail, confirmSignup } from 'modules/auth/thunks/auth';
import CssGreenButton from 'modules/core/components/buttons/roundGreenButton';
import { InputField } from 'modules/core/components/fields';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { DASHBOARD_ROUTE } from 'routes/routes';
import { IReduxState } from 'store';
import AuthorizationDialog from './AuthorizationDialog';
import { useStyles } from './styles';
import { Notification } from 'modules/core/components/Notification';

interface IProps {
  resendEmail: (evt: FormEvent | MouseEvent, isNeedRedirect: boolean) => Promise<void>;
  authenticate: (email: string, token: string, throughReseller?: string) => Promise<void>;
  authenticateAfterRegister: (token: string) => Promise<void>;
  email: string;
  isAfterRegister?: boolean;
}
interface IStore {
  pending: boolean;
}

const resendMessages = [
  '',
  'Remember to check your spam folder.',
  'Does your email have a typo? Refresh the page to start again.',
  "Still having trouble? Don't hesitate to contact us.",
];

const AuthPinSent: React.FC<RouteComponentProps & IStore & IProps> = ({
  history,
  pending,
  resendEmail,
  email,
  authenticate,
  authenticateAfterRegister,
  isAfterRegister,
}) => {
  const classes = useStyles();
  const [pin, setPin] = useState<string>('');
  const [displayResendMessage, setDisplayResendMessage] = useState<boolean>(false);
  const [resendMessageSeverity, setResendMessageSeverity] = useState<'success' | 'error'>('success');
  const [resendMessageText, setResendMessageText] = useState<string>('Check your spam folder.');
  const [resendMessagesIndex, setResendMessagesIndex] = useState<number>(0);
  const [timeoutId, setTimeoutId] = useState<any>();
  const handlePinChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let { value } = event.target;
      value = value.replace(/\D/g, '');
      if (value.length <= 6) {
        setPin(value);
      }
    },
    [setPin]
  );

  const showResendMessage = useCallback((text: string, error: boolean = false) => {
    setResendMessageText(text);
    setResendMessageSeverity(error ? 'error' : 'success');
    setDisplayResendMessage(true);
    !error && setResendMessagesIndex((resendMessagesIndex + 1) % resendMessages.length);
    const id = setTimeout(() => setDisplayResendMessage(false), 5000);
    setTimeoutId(id);
    }, [
      setResendMessageText,
      setResendMessageSeverity,
      setDisplayResendMessage,
      setResendMessagesIndex,
      setTimeoutId,
      resendMessagesIndex,
    ]
  );

  const closeResendMessage = () => {
    setDisplayResendMessage(false);
    clearTimeout(timeoutId);
  };

  const reqEmail = useCallback(
    async (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      try {
        await resendEmail(evt, true);
        showResendMessage(`PIN resent. ${resendMessages[resendMessagesIndex]}`);
      } catch (e) {
        console.warn(e);
        showResendMessage("Error! PIN was not resent. Please contact us.", true);
      }
    },
    [resendEmail, resendMessagesIndex, showResendMessage]
  );

  const throughReseller = useMemo(() => Cookies.get('throughReseller'), []);

  const onSubmitPin = useCallback(
    async (e: FormEvent | MouseEvent) => {
      e.preventDefault();
      if (isAfterRegister) {
        await authenticateAfterRegister(pin);
        history.push(DASHBOARD_ROUTE.path);
      } else {
        try {
          await authenticate(email, pin, throughReseller);
          history.push(DASHBOARD_ROUTE.path);
        } catch (err) {
          // setIsError(true);
        }
      }
    },
    [email, pin, authenticate, authenticateAfterRegister, history, isAfterRegister, throughReseller]
  );

  return (
    <>
      <AuthorizationDialog
        titleLogo={
          <div className={classes.dartWrapper}>
            <DartSvg className={classes.dart} />
          </div>
        }
        centered={true}
        title="We emailed you a PIN"
        dlgBody={
          <form noValidate onSubmit={(e) => onSubmitPin(e)} className={classes.form}>
            <InputField
              classes={{ root: classes.textField }}
              inputProps={{
                className: classes.spacedInput,
              }}
              name="sign_in_pin_input"
              variant="filled"
              label="Enter PIN"
              value={pin}
              onChange={handlePinChange}
              disabled={pending}
            />
          </form>
        }
        footer={
          <>
            <CssGreenButton
              id="sign_in_pin_submit_button"
              className={clsx(classes.button, classes.submitPinButton)}
              onClick={(e) => onSubmitPin(e)}
              disabled={pin.length < 6}
            >
              Submit
            </CssGreenButton>
            <Typography variant="body2">Haven’t received your PIN?</Typography>
            <Button
              id="sign_in_email_try_again"
              variant="text"
              onClick={reqEmail}
              className={classes.modeButton}
              disabled={pending}
            >
              Try again
            </Button>
          </>
        }
      />
      <Notification
        id="auth-try-again-notification"
        text={resendMessageText}
        isOpen={displayResendMessage}
        severity={resendMessageSeverity}
        onClose={closeResendMessage}
      />
    </>
  );
};

const mapState = (state: IReduxState) => ({
  pending: state.auth.pending,
});

const mapReducers = {
  authenticate: authenticateByEmail,
  authenticateAfterRegister: confirmSignup,
  // loadProfile: getProfile,
};

export default connect(
  mapState,
  mapReducers
)(withRouter(AuthPinSent));
