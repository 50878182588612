import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    title: {
      flexGrow: 1,
      color: '#FFF',
      alignItems: 'center',
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        fontSize: 20,
        fontWeight: 700,
        lineHeight: theme.typography.pxToRem(29),
        marginTop: 2,
      },
    },
    link: {
      textTransform: 'uppercase',
      textDecoration: 'none',
      color: 'white',
    },
  })
);
