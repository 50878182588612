import { actions } from 'modules/auth/reducers/authReducer';
import { actions as stepsActions } from 'modules/editor/reducers/stepsReducer';
import { E_ACCOUNT_TYPE } from 'modules/auth/constants/enums';
import { AccountProfile } from 'modules/auth/models/account-profile';
import { IAccountProfile } from 'modules/auth/interfaces/auth';
import { Action } from 'redux-symbiote/types';
import { ThunkDispatch } from 'redux-thunk';

// Extract permissions
function filteredPermissions(accountData: IAccountProfile) {
  const truePermissions = [];
  const falsePermissions = [];

  if (accountData.canEditAboutSection) {
    truePermissions.push('editor_about_step');
  } else {
    falsePermissions.push('aboutFields');
  }

  if (accountData.canEditOfferSection) {
    truePermissions.push('editor_news_step');
  } else {
    falsePermissions.push('newsFields');
  }

  if (accountData.canEditVideoSection) {
    truePermissions.push('editor_video_step');
  } else {
    falsePermissions.push('videoFields');
  }

  if (accountData.canEditGallerySection) {
    truePermissions.push('editor_gallery_step');
  } else {
    falsePermissions.push('galleryFields');
  }

  if (accountData.canEditLocationSection) {
    truePermissions.push('editor_location_step');
  } else {
    falsePermissions.push('locationFields');
  }

  if (accountData.canEditPromotionSection) {
    truePermissions.push('editor_promotion_step');
  } else {
    falsePermissions.push('promotionFields');
  }

  return [truePermissions, falsePermissions] as const;
}

export async function setAccountProfile(dispatch: ThunkDispatch<{}, {}, Action<any>>, accountData: IAccountProfile) {
  const profile = new AccountProfile(accountData);
  await dispatch(actions.setProfile(profile));
  const [truePermissions, falsePermissions] = filteredPermissions(accountData);

  switch (accountData.type) {
    case E_ACCOUNT_TYPE.Admin:
      dispatch(stepsActions.disableAdminFields(accountData.canChangeUrl));
      break;
    case E_ACCOUNT_TYPE.Member:
      dispatch(stepsActions.disableMemberFields(accountData.canChangeUrl, truePermissions, falsePermissions));
      break;
    case E_ACCOUNT_TYPE.Reseller:
      dispatch(stepsActions.disableResalerFields(accountData.canChangeUrl));
      break;
    case E_ACCOUNT_TYPE.Owner:
      dispatch(stepsActions.disableOwnerFields(accountData.canChangeUrl));
      break;
    case E_ACCOUNT_TYPE.Unknown:
      dispatch(stepsActions.disableOwnerFields(accountData.canChangeUrl));
      break;
  }

  return profile;
}

export const identifyWithSIB = (accountData: IAccountProfile) => {
  const profile = new AccountProfile(accountData);
  (window as any).sendinblue.identify(profile.email);
};
