import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { lightGreen, white } from 'themes/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    authorWrap: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 800,
      width: '100%',
      alignItems: 'center',
      padding: theme.spacing(0, 3),
    },
    stars: {
      display: 'flex',
      marginBottom: theme.spacing(3),

      '& svg': {
        fill: lightGreen,
        width: 32,
        height: 32,
      },
    },
    author: {
      display: 'flex',
      alignItems: 'center',
    },
    authorImg: {
      width: 64,
      height: 64,
      borderRadius: "50%",
      border: '3px solid #FFFFFF',
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.1)',
      overflow: 'hidden',
      marginBottom: theme.spacing(2),

      '& img': {
        width: '100%',
        objectFit: 'cover',
      },
      '&._impact': {
        width: 100,
        height: 100,
        border: '3px solid #FFFFFF',
        borderRadius: '50%',
        '& img': {
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        },
      },
    },
    authorDescription: {
      textAlign: 'center',
      color: lightGreen,
      lineHeight: theme.typography.pxToRem(30),
    },
    name: {
      fontSize: 20,
    },
    position: {
      fontSize: 20,
      fontFamily: 'F37 Ginger Light',
    },
    reviewText: {
      fontSize: 20,
      lineHeight: theme.typography.pxToRem(30),
      fontWeight: 300,
      fontFamily: 'F37 Ginger Light',
      fontStyle: 'italic',
      color: white,
      marginBottom: theme.spacing(3),
    },

    [theme.breakpoints.down('sm')]: {
      authorWrap: {
        '& p': {
          fontSize: 16,
        },
      },
    },
  })
);
