import { updateUserTimeZone, updateUserThroughCampaign } from 'modules/auth/api/auth';
import { actions } from 'modules/auth/reducers/authReducer';
import { getProfile } from 'modules/auth/thunks/auth';
import { userSubscriptions } from 'modules/billing/api/billing';
import { Subscription } from 'modules/billing/models/subscription';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { IReduxState } from 'store';
import jstz from 'jstimezonedetect';
import { SALES_CAMPAIGN_DOMAIN } from 'modules/core/constants/config';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router';

interface IProfileLoader {
  token: string | null;
  loadProfile: () => Promise<void>;
  setSubscriptions: (subs: Subscription[]) => AnyAction;
}

const mapState = ({ auth: { token } }: IReduxState) => ({
  token,
});

const mapActions = {
  loadProfile: getProfile,
  setSubscriptions: actions.setSubscription,
};

const ProfileLoader: React.FC<IProfileLoader> = ({ token, loadProfile, setSubscriptions }) => {
  const { search } = useLocation();

  useEffect(() => {
    if (token) {
      loadProfile();
      userSubscriptions()
        .then((subs) => setSubscriptions(subs))
        .then(() => updateUserTimeZone(jstz.determine().name()))
        .catch(() => setSubscriptions([]));
    }
  }, [token, loadProfile, setSubscriptions]);

  useEffect(() => {
    if (document.referrer.length === 0 || document.referrer.match(SALES_CAMPAIGN_DOMAIN)) {
      const searchParams = new URLSearchParams(search);
      const throughCampaign = searchParams.get('landingpage');

      if (throughCampaign) {
        Cookies.remove('throughReseller');
        Cookies.set('throughCampaign', throughCampaign);

        if (token) {
          updateUserThroughCampaign(throughCampaign).catch(console.warn);
        }
      }
    }
  }, [search, token]);

  return null;
};

export default connect(mapState, mapActions)(ProfileLoader);
