import uuid from 'uuid';

export const loadAuthToken = () => {
  return localStorage.getItem('gopaperless_bearer');
};

export const storeAuthToken = (token: string | null) => {
  if (token) {
    localStorage.setItem('gopaperless_bearer', token);
  } else {
    localStorage.removeItem('gopaperless_bearer');
  }
};

export const loadVisitorKey = () => {
  let key = localStorage.getItem('gopaperless_visitor_key');

  if (!key) {
    key = "gplvis_" + uuid();
    storeVisitorKey(key);
  };

  return key;
};

export const storeVisitorKey = (key: string | null) => {
  if (key) {
    localStorage.setItem('gopaperless_visitor_key', key);
  } else {
    throw new Error("No visitor key was provided!");
  };
};

// Intended to encode a user's auth token for use with websockets, to
// prevent raw tokens from being printed in logs.
//
// Endodes string to base64
//
export const encodedAuthToken = () => {
  const rawToken = loadAuthToken() as string;

  return btoa(rawToken);
};
