import { Box, Typography, FormControlLabel } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import { InputField, UploadImage, GreenCheckbox } from 'modules/core/components/fields';
import { OverlayProgress } from 'modules/core/components/progress/OverlayProgress';
import { Validation } from 'modules/core/types';
import { IFileReadResult } from 'modules/core/utils';
import { updateNews } from 'modules/editor/api/card-mutations';
import { ICardVersion } from 'modules/editor/graphql/gql';
import { INewsAtributes } from 'modules/editor/graphql/mutations';
import { NewsAtributes } from 'modules/editor/models/atributes/news-atributes';
import { NewsSection } from 'modules/editor/models/sections/news-section';
import { actions } from 'modules/editor/reducers/cardReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IReduxState } from 'store';
import Clear from 'modules/editor/components/CardSectionEditors/Clear';
import { isDisabled, updateField } from './hooks';

interface INews {
  updateNews: (atr: INewsAtributes, fields: string[]) => Promise<NewsSection | undefined>;
  clearNewsImgItem: () => void;
  card: ICardVersion;
  classes: any;
  fields: string[];
}

const FIELDS = ['linkUrl', 'linkText', 'linkUrlShow', 'newsImageUrl', 'title', 'showSectionHeader'];

export const News = connect(
  (state: IReduxState) => ({ card: state.currentVersion.currentVer, fields: state.editorSteps.newsFields }),
  { updateNews, clearNewsImgItem: () => (dispatch: Dispatch) => dispatch(actions.clearNewsImgItem()) }
)((props: INews) => {
  const { updateNews: saveData, card, classes, fields, clearNewsImgItem } = props;
  const [news, setNews] = useState<NewsAtributes>(new NewsAtributes(card));
  const [overlay, setOverlay] = useState(false);
  const [errors, setErrors] = useState<Validation<INewsAtributes>>({});

  useEffect(() => {
    news.cardVersionId = card.id;
    setNews(news.nextState(news));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card.id]);

  const handleBlur = useCallback(
    (field: keyof INewsAtributes) => {
      if (!errors[field]) {
        saveData(
          {
            cardVersionId: news.cardVersionId,
            [field]: news[field] || null,
          },
          FIELDS
        );
      }
    },
    [saveData, errors, news]
  );

  const updateShowSectionHeader = useCallback(() => {
    news.showSectionHeader = !news.showSectionHeader;
    const atr = news.nextState(news);
    setNews(atr);
    saveData(
      {
        cardVersionId: atr.cardVersionId,
        showSectionHeader: atr.showSectionHeader,
      },
      FIELDS
    );
  }, [setNews, saveData, news]);

  const uploadImg = useCallback(
    async (res: IFileReadResult) => {
      news.newsImageBase64 = res.base64data;
      news.newsImageName = res.filename;
      const atr = news.nextState(news);
      setNews(atr);
      setOverlay(true);
      try {
        await saveData(
          {
            cardVersionId: atr.cardVersionId,
            newsImageBase64: atr.newsImageBase64,
            newsImageName: atr.newsImageName,
          },
          FIELDS
        );
        news.newsImageBase64 = undefined;
        news.newsImageName = undefined;
        setNews(news);
      } catch (error) {
        console.warn(error);
      }
      setOverlay(false);
    },
    [saveData, setNews, news]
  );

  const onClear = useCallback(() => {
    clearNewsImgItem();
    const atr = { ...news.clear(true).toJSON() };
    setNews(news.clear(false));
    saveData(atr, FIELDS);
  }, [news, saveData, clearNewsImgItem]);

  const clearImg = useCallback(async () => {
    try {
      news.newsImageBase64 = undefined;
      news.newsImageName = undefined;
      const atr = news.nextState(news);
      setNews(atr);
      await saveData(
        {
          cardVersionId: atr.cardVersionId,
          newsImageBase64: null,
          newsImageName: null,
        },
        FIELDS
      );
    } catch (error) {
      console.warn(error);
    }
  }, [setNews, saveData, news]);

  return (
    <>
      <div className={classes.fieldBlock}>
        <Box display="flex" alignItems="center" mb={2}>
          <Typography className={classes.sectionHeader}>News</Typography>
          {fields.length === 5 ? null : (
            <Box className={classes.clearBlock}>
              <Clear id="news_editor_clear" ico={<ClearIcon fontSize="inherit" />} onConfirm={() => onClear()}>
                Clear section
              </Clear>
            </Box>
          )}
        </Box>
        <Typography className={classes.heading}>Title</Typography>
        <InputField
          name="news-title"
          type="text"
          label="Enter title"
          disabled={isDisabled(fields, 'title')}
          onChange={(e) => updateField({ model: news, prop: 'title', val: e.target.value, setState: setNews })}
          onBlur={() => handleBlur('title')}
          value={news.title as string}
          variant="filled"
        />
        <FormControlLabel
          className={classes.checkbox}
          control={
            <GreenCheckbox
              name="news-showSectionHeader"
              checked={news.showSectionHeader}
              disabled={isDisabled(fields, 'showSectionHeader')}
              value={news.showSectionHeader}
              onChange={updateShowSectionHeader}
            />
          }
          label="Show section heading"
        />
        <UploadImage
          name="news-newsImage-upload"
          imgUrl={card.newsSection.newsImageUrl}
          disabled={isDisabled(fields, 'newsImageName')}
          size="large"
          title="News image"
          firstDescription="Minimum 1200px wide"
          uploadImg={(res) => uploadImg(res)}
          alowClear={true}
          onClear={() => clearImg()}
        />
      </div>
      <div className={classes.fieldBlock}>
        <Typography className={classes.heading}>News link</Typography>
        <div className={classes.inpWrapper}>
          <InputField
            name="news-linkUrl"
            type="text"
            placeholder="Enter URL"
            disabled={isDisabled(fields, 'linkUrl')}
            onChange={(e) => {
              updateField({
                model: news,
                prop: 'linkUrl',
                val: e.target.value,
                setState: setNews,
                setErrors,
                errors,
              });
            }}
            onBlur={() => handleBlur('linkUrl')}
            error={!!errors.linkUrl}
            value={news.linkUrl as string}
            variant="filled"
            InputProps={{
              startAdornment: (
                <div className={classes.icon2}>
                  <div className={isDisabled(fields, 'linkUrl') ? classes.disabledIcon : undefined}>
                    <RecordVoiceOverIcon />
                  </div>
                </div>
              ),
            }}
          />
        </div>
        <InputField
          name="news-linkText"
          type="text"
          label="Enter link text"
          disabled={isDisabled(fields, 'linkText')}
          onChange={(e) => updateField({ model: news, prop: 'linkText', val: e.target.value, setState: setNews })}
          onBlur={() => handleBlur('linkText')}
          value={news.linkText as string}
          variant="filled"
        />
      </div>
      <OverlayProgress isProgress={overlay} />
    </>
  );
});
