import { makeStyles } from '@material-ui/core';
import { lightGreen, darkGreen } from 'themes/colors'

export const useClasses = makeStyles((theme) => ({
  info: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: theme.spacing(1, 3, 2.5),
  },
  infoContainer: {
    justifyContent: 'space-between',
    marginBottom: -4,
    maxWidth: 1240,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  logo: {
    height: 40,
    width: 40,
    fill: lightGreen,
  },
  vr: {
    borderLeft: "1px solid #eee",
    height: 50,
    margin: '0 20px',
  },
  avatar: {
    position: 'relative',
    width: 52,
    height: 48,
    backgroundColor: 'gray',
    border: '2px solid white',
    borderRadius: '13px',
    overflow: 'hidden',
    // boxShadow: '0 4px 17px 0 #BBBBE2',
    minWidth: 52,
  },
  name: {
    color: darkGreen,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(3),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 'bold',
  },
  dropdownButton: {
    color: lightGreen,
    cursor: 'pointer',
    marginTop: 6,
    position: 'absolute',
    right: 20,
  },
  line: {
    margin: '0px 10px 5px',
    border: "1px solid #eee",
  },
  actionsContainer: {
    "& > div": {
      paddingBottom: 8,
    },
  },
  actions: {
    "& .MainInfo-actionText": {
      fontSize: 10,
      color: lightGreen,
    },
  },

  mdDown: {
    display: 'block',

    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  mdUp: {
    display: 'none',

    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },

  container: {
    backgroundColor: "#fff",
    paddingTop: 8,

    [theme.breakpoints.up('md')]: {
      '& $info': {
        margin: theme.spacing(0, 3, 1.5),
      },
      '& $infoContainer': {
        display: 'flex',
      },
      '& $avatar': {
        width: 63,
        height: 59,
      },
      '& $name': {
        fontSize: 20,
      },
      '& $actionsContainer': {
        marginRight: theme.spacing(3),
      },
      '& $actions': {
        "& .MainInfo-Action": {
          padding: 0,
          margin: 0,

          "& .MainInfo-iconWrapper": {
            width: 40,
            height: 40,
            marginBottom: -4,
          },
          "& .icon": {
            width: '0.7em',
            height: '0.7em',
          },
        },
      },
    // },

    // [theme.breakpoints.up('md')]: {
      '&.preview.tablet, &.preview.mobile': {
        '& $mdUp': {
          display: 'none',
        },
        '& $mdDown': {
          display: 'block',
        },

        '& $avatar': {
          width: 52,
          height: 48,
        },
        '& $name': {
          fontSize: 16,
        },
        '& $actionsContainer': {
          marginRight: 0,
        },
        '& $info': {
          margin: theme.spacing(1, 3, 2.5),
        },
        '& $infoContainer': {
          display: 'block',
        },
      },
      '&.preview.tablet': {
        '& $actions': {
          "& .MainInfo-Action": {
            padding: 0,
            margin: "0 13px",

            "& .MainInfo-iconWrapper": {
              width: 50,
              height: 50,
              marginBottom: 0,
            },
            "& .icon": {
              width: '0.8em',
              height: '0.8em',
            },
          },
        },
      },
    },
  },
}));
