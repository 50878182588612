import React, { CSSProperties } from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from 'modules/editor/components/CardSectionsView/MainInfo/useStyles';
import { useSocialButtons } from 'modules/editor/components/CardSectionsView/MainInfo/hooks/useSocialButtons';
import { ICardVersion } from 'modules/editor/graphql/gql';
import { RoundButton } from 'modules/editor/components/CardSectionsView/MainInfo/RoundButton';
import { useSocialLinkStyle } from 'modules/editor/components/CardSectionsView/MainInfo/hooks/useSocialLinkStyle';

interface IProps {
  cardVer: ICardVersion;
  disabled: boolean;
  iconStyle: CSSProperties;
}

export const Socials: React.FC<IProps> = (props) => {
  const { cardVer, disabled, iconStyle } = props;
  const { cardStyle } = cardVer;
  const classes = useStyles();
  const socialBtns = useSocialButtons(cardVer);
  const socialLinkStyle = useSocialLinkStyle(cardStyle);

  return (
    <Box className={classes.social}>
      {socialBtns.map((btnProps) => {
        const { id, icon, url } = btnProps;
        return (
          <div key={id} id={id}>
            <RoundButton
              size="sm"
              icon={icon}
              url={url}
              buttonStyle={socialLinkStyle}
              bodyStyle={iconStyle}
              disabled={disabled}
            />
          </div>
        );
      })}
    </Box>
  );
};
