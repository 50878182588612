import { SvgIcon } from '@material-ui/core';
import { ReactComponent as NewsIcon } from 'assets/svg/news.svg';
import { constructLink } from 'modules/core/utils';
import CardWrapper from 'modules/editor/components/CardWrapper';
import { LinkImage } from 'modules/editor/components/LinkImage';
import { PreviewLink } from 'modules/editor/components/PreviewLink';
import { INewsSection } from 'modules/editor/graphql/gql';
import React, { CSSProperties, useMemo, RefObject } from 'react';
import { When } from 'react-if';
import { useScroll } from 'modules/editor/components/CardSectionsView/hooks/useScroll';
import { E_CARD_STEPS } from 'modules/editor/constants/enums';

interface INewsProps {
  section: INewsSection;
  display: boolean;
  classes: Record<string, string>;
  bodyStyle: CSSProperties;
  titleStyle: CSSProperties;
  iconStyle: CSSProperties;
  linkStyle: CSSProperties;
  isPreview?: boolean;
  wrapperRef: RefObject<HTMLDivElement>;
}

/**
 * News section block view for card. Display image and link for news.
 * @param props INewsProps
 */
export const NewsSection: React.FC<INewsProps> = ({
  display,
  section,
  classes,
  bodyStyle,
  iconStyle,
  linkStyle,
  titleStyle,
  isPreview,
  wrapperRef,
}) => {
  const ref = useScroll(E_CARD_STEPS.NEWS, wrapperRef, display);
  const link = useMemo(() => (section.linkUrl ? constructLink(section.linkUrl as string) : undefined), [
    section.linkUrl,
  ]);
  const firstLine = section.showSectionHeader ? <span>News</span> : undefined;

  return (
    <When condition={display}>
      <CardWrapper
        {...section}
        ref={ref}
        bodyStyle={bodyStyle}
        titleStyle={titleStyle}
        firstLine={firstLine}
        icon={<SvgIcon children={<NewsIcon />} htmlColor={'#ffffff'} className="section-icon" style={iconStyle} />}
      >
        <LinkImage
          disabled={isPreview}
          imageAlt="News"
          image={section.newsImageUrl}
          url={link}
          className={classes.image}
        />
        <PreviewLink
          disable={isPreview}
          display={link && section.linkText ? true : false}
          url={link}
          className={classes.findMore}
          style={linkStyle}
        >
          {section.linkText}
        </PreviewLink>
      </CardWrapper>
    </When>
  );
};
