import React, { useState, useEffect, useCallback } from 'react';
import { TextField, TextFieldProps, InputAdornment, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles as useInputFieldStyles } from 'modules/core/components/fields/InputField/styles';
import { useStyles } from 'modules/core/components/fields/SearchField/styles';
import { ReactComponent as GlassIcon } from 'assets/svg/glass.svg';
import { SvgIcon } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { lightGreen } from 'themes/colors';

type Props = TextFieldProps & {
  exClass?: string;
  onSearchSubmit: (searchTerm: string) => Promise<void>;
};

export const SearchField: React.FC<Props> = (props) => {
  const { exClass, onSearchSubmit, inputProps, ...restProps } = props;
  const classes = useStyles();
  const inputFieldClasses = useInputFieldStyles();

  const [query, setQuery] = useState<string>("");
  const isQueryBlank = query === "";

  const onChange = (e: any) => setQuery(e.target.value);
  const onSubmit = useCallback((e: any) => onSearchSubmit(query), [onSearchSubmit, query]);
  const onCancel = useCallback(() => {
    setQuery("");
    onSearchSubmit("");
  }, [onSearchSubmit]);

  useEffect(() => {
    if (inputProps) {
      setQuery(inputProps.value);
    };
  }, [inputProps]);

  const handleKeyUp = useCallback(
    (e) => {
      if (e.charCode === 13 || e.key === "Enter") {
        onSubmit(e);
      };

      if (e.charCode === 27 || e.key === "Escape") {
        onCancel();
      };

      if (inputProps && inputProps.onKeyUp) {
        inputProps.onKeyUp(e);
      };
    },
    [inputProps, onSubmit, onCancel]
  );

  return <TextField
    {...restProps}
    className={clsx(
      inputFieldClasses.margin,
      inputFieldClasses.textField,
      classes.searchField,
      exClass
    )}
    value={query}
    onChange={onChange}
    onSubmit={onSubmit}
    onKeyDown={handleKeyUp}
    variant="standard"
    InputProps={{
      classes: { root: classes.root },
      disableUnderline: true,
      endAdornment: (
        <InputAdornment position="end" classes={{ root: classes.glassIcon }}>
          <IconButton
            aria-label="submit network index search form"
            onClick={isQueryBlank ? onSubmit : onCancel}
            classes={{ root: classes.iconButtonRoot }}
          >
            { isQueryBlank ? <SvgIcon children={<GlassIcon /> } /> : <Close style={{ color: lightGreen }} /> }
          </IconButton>
        </InputAdornment>
      ),
    }}
  />;
};
