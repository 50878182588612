import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrap: {
      position: 'fixed',
      top: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      // padding: '40px 0',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      background: '#013D1F',
      '& svg': {
        fill: 'white',
        color: 'white',
      },
      zIndex: 5,
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 30,
      maxWidth: 1145,
    },
    title: {
      textAlign: 'center',
      color: 'white',
    },
    subtitle: {
      paddingTop: 20,
      color: 'white',
      textAlign: 'center',
      fontSize: 16,
      '&._logo': {
        paddingTop: 0,
      },
      '& a': {
        color: '#76B741',
      },
    },
    name: {
      fontSize: 32,
      letterSpacing: 1,
      lineHeight: theme.typography.pxToRem(40),
      color: 'white',
    },
    coloredName: {
      fontSize: 32,
      letterSpacing: 1,
      lineHeight: theme.typography.pxToRem(40),
      color: '#76B741',
      '& p': {
        margin: 0,
      },
    },
    logo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 100,
      '& svg': {
        width: 60,
        heidth: 60,
        fill: '#76B741',
      },
    },
    logoText: {
      display: 'flex',
      marginBottom: 20,
    },
  })
);
