import React from 'react';
import { CardLoading } from 'modules/editor/containers/Card/CardView/CardLoading';
import { E_ACCOUNT_TYPE } from 'modules/auth/constants/enums';
import { ICardView } from 'modules/editor/interfaces';
import { RetailCard } from 'modules/editor/containers/Card/RetailCard';
import ResellerCard from 'modules/editor/containers/Card/ResellerCard';

export const CardView: React.FC<ICardView> = (props) => {
  // const { isPreview = false, previewType = E_PREVIEW_TYPE.NONE, modifier, cardVer, isLoading } = props;
  const { isPreview = false, cardVer, isLoading } = props;
  const { logoUrl, card } = cardVer;
  const type = (card.account && card.account.type) || E_ACCOUNT_TYPE.Owner;

  return (
    <>
      { type === E_ACCOUNT_TYPE.Reseller ? (
        <ResellerCard {...props} />
      ) : (
        <RetailCard {...props} />

      )}
      {!isPreview && isLoading && <CardLoading logoUrl={logoUrl} />}
    </>
  );
};
