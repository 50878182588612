import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hr: {
      maxWidth: 1230,
      width: '100%',
      height: 1,
      background: ' rgba(255,255,255,0.25)',
      margin: '100px auto',
      '&._marginLg': {
        margin: '120px auto 100px auto',
        [theme.breakpoints.down('md')]: {
          background: 'transparent',
          margin: '60px auto',
        },
      },
      '&:last-child': {
        marginBottom: 0,
        [theme.breakpoints.down('md')]: {
          margin: '60px auto',
        },
      },
      [theme.breakpoints.down('md')]: {
        background: 'transparent',
        margin: '31px auto',
      },
    },
  })
);
