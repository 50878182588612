import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import CssGreenButton from 'modules/core/components/buttons/roundGreenButton';
import { SelectCountry } from 'modules/core/components/fields/SelectCountry';
import React, { useCallback, useState, FC } from 'react';
import { ReactStripeElements } from 'react-stripe-elements';
import { useStyles } from 'modules/billing/components/Billing/styles';
import { StripeInput } from './StripeInput';
import { InputField } from 'modules/core/components/fields';

export const STRIPE_STATUS_SYMBOL = Symbol('stripeStatus');

const INVALID_COUNTRY_MSG = 'Please select country!';
const INVALID_CARD_MSG = 'Please enter all card details!';
const INVALID_HOLDER_MSG = 'Please enter your name!';

export interface IPayDataState {
  complete: boolean;
  empty: boolean;
  cardError: string;
  name: string;
  country: string;
}

interface IProps {
  disabled?: boolean;
  ctaText?: string;
  next: (pm: string) => void;
  err: (useRedirect: boolean, error?: any) => void;
  stripe?: ReactStripeElements.StripeProps;
}

export const PayData: FC<IProps> = ((props) => {
  const { next, err, stripe, ctaText } = props;
  const classes = useStyles();
  const [wait, setWait] = useState<boolean>(false);
  const [name, setName] = useState('');
  const [country, setCountry] = useState('');
  const [validation, setValidation] = useState<IPayDataState>({
    complete: false,
    empty: true,
    cardError: '',
    name: '',
    country: '',
  });
  const continueText = ctaText || "CONTINUE";
  const changeCountry = useCallback(
    (code: string) => {
      setCountry(code);
      setValidation({ ...validation, country: '' });
    },
    [setCountry, setValidation, validation]
  );

  const changeName = useCallback(
    (holder: string) => {
      setName(holder);
      setValidation({ ...validation, name: '' });
    },
    [setName, setValidation, validation]
  );

  const validate = useCallback(() => {
    const { cardError, empty, complete } = validation;
    const isValid = !cardError && !!name && !!country && !empty && complete;
    const validationState = { ...validation };
    if (!name) {
      validationState.name = INVALID_HOLDER_MSG;
    }
    if (!country) {
      validationState.country = INVALID_COUNTRY_MSG;
    }
    if (empty) {
      validationState.cardError = INVALID_CARD_MSG;
    }
    if (!complete) {
      validationState.cardError = INVALID_CARD_MSG;
    }
    setValidation(validationState);
    return isValid;
  }, [setValidation, validation, country, name]);

  const handleClick = useCallback(async () => {
    (window as any)[STRIPE_STATUS_SYMBOL] = true;
    const isValid = validate();
    console.warn(isValid);
    if (isValid) {
      setWait(true);
      const { error, paymentMethod } = await stripe!.createPaymentMethod('card', {
        billing_details: { name: name.toUpperCase(), address: { country } },
      });
      if (!error && paymentMethod) {
        (window as any)[STRIPE_STATUS_SYMBOL] = false;
        next(paymentMethod.id)
      } else {
        err(false, error);
        setWait(false);
        (window as any)[STRIPE_STATUS_SYMBOL] = false;
      }
    }
  }, [stripe, name, validate, next, err, country]);

  return (
    <div className={clsx(classes.content)}>
      <div className={classes.popupHead}>
        <Typography className={classes.popupTitle} variant="h4">
          Enter payment details
        </Typography>
        <Typography className={classes.popupDescription}>Subscription purchase</Typography>
      </div>
      <div className={clsx(classes.popupContent, '_col')}>
        <div style={{ marginBottom: '14px' }}>
          <StripeInput
            label="Card details"
            required={true}
            error={!!validation.cardError}
            labelErrorMessage={validation.cardError}
            disabled={wait}
            setValidation={setValidation}
          />
        </div>
        <SelectCountry
          error={validation.country}
          name="buy_select_country"
          value={country}
          onChange={(c) => changeCountry(c.code)}
        />
        <InputField
          variant="filled"
          InputLabelProps={{ shrink: true }}
          label="Name on card"
          placeholder="Name on card"
          name="buy_holder_name"
          value={name}
          error={!!validation.name}
          exClass="_white _buy"
          inputProps={{
            autoComplete: 'off',
          }}
          disabled={wait}
          onChange={(e: any) => changeName(e.target.value)}
        />
      </div>
      <div style={{ marginTop: 10 }}>
      </div>
      <CssGreenButton name="step_requisites_next" disabled={wait} onClick={() => handleClick()}>
        {continueText}
      </CssGreenButton>
    </div>
  );
});
