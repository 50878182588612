export const UPDATE_STYLING_SCHEME = {
  $cardVersionId: 'ID!',
  $cardStyleId: 'ID!',
  $businessName: 'String',
  $businessNameHexColour: 'String',
  $tagLine: 'String',
  $titleFont: 'String',
  $titleFontStyle: 'String',
  $titleFontHexColour: 'String',
  $bodyFont: 'String',
  $bodyFontStyle: 'String',
  $bodyFontHexColour: 'String',
  $taglineHexColour: 'String',
  $primaryLinksHexColour: 'String',
  $secondaryIconsHexColour: 'String',
};
