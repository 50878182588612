import React from 'react';
import { Link } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import RoutedLink from 'modules/core/components/RoutedLink';
import { SIGNIN_ROUTE, SIGNUP_ROUTE } from 'routes/routes';
import CssGreenButton from 'modules/core/components/buttons/roundGreenButton';
import { lightGreen, white } from 'themes/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      height: 'fit-content',
      width: 'fit-content',
      marginTop: 2,

      '&._dark': {
        width: '100%',
        justifyContent: 'center',
      },
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      padding: '0',
      textTransform: 'uppercase',

      '& a': {
        color: lightGreen,
        fontSize: 14,
        lineHeight: 1,
        letterSpacing: '0.2px',
        fontFamily: 'F37 Ginger',
        fontWeight: 700,
        whiteSpace: 'nowrap',
        '&:hover': {
          textDecoration: 'none',
          color: white,
        },
      },

      '&._dark': {
        '&._darkHide': {
          display: 'none',
        },

        '& a': {
          color: '#76B741',
          fontSize: 16,
        },

        '& $button': {
          margin: theme.spacing(3, 0),
          backgroundColor: lightGreen,
          color: white,
          minWidth: 160,
          fontSize: 14,

          '&:hover': {
            backgroundColor: lightGreen,
          },
        },
      },

      '&:hover': {
        textDecoration: 'none',
      },
    },
    button: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      backgroundColor: white,
      color: lightGreen,
      minWidth: 110,

      '&:hover': {
        color: white,
        backgroundColor: lightGreen,
        // boxShadow: `0 0 5px 4px ${lightGreen}`,
      },
    },
    divider: {
      width: 0.8,
      height: 18,
      color: 'white',
      backgroundColor: 'rgba(0,0,0,0.4)',
      margin: '0 8px',
      '&._dark': {
        margin: '0 18px',
        opacity: 0.5,
      },
    },
  })
);

const SignLinks: React.FC<{ theme?: any }> = ({ theme }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, theme)}>
      <div className={clsx(classes.link, theme, "_darkHide")}>
        <Link id="signup_link" to={SIGNUP_ROUTE.path} component={RoutedLink}>Sign up</Link>
      </div>
      <div className={clsx(classes.link, theme)}>
        <CssGreenButton href={SIGNIN_ROUTE.path} id="signin_link" className={clsx(classes.button, "_outlinedButton")}>Sign in</CssGreenButton>
      </div>
    </div>
  );
};

export default SignLinks;
