import { Box, Container, withStyles } from '@material-ui/core';
import { ReactComponent as Logo } from 'assets/images/GP_icon.svg';
import clsx from 'clsx';
import { E_ACCOUNT_TYPE } from 'modules/auth/constants/enums';
import { ICardVersion } from 'modules/editor/graphql/gql';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { IReduxState } from 'store';
import { EDITOR_ROUTE, LANDING_ROUTE, PRIVACY_ROUTE, DASHBOARD_ROUTE, TERMS_ROUTE } from 'routes/routes';
import { If, Else, Then } from 'react-if';
import { useShowOfferPage } from 'hooks/useShowOfferPage';
import CssGreenButton from 'modules/core/components/buttons/roundGreenButton';

import { useFooterStyles as useStyles } from './styles';

const GetButton = withStyles({
  root: {
    cursor: 'pointer',
    textTransform: 'uppercase',
    marginTop: '30px',
    marginBottom: '70px',
    zIndex: 4,
  },
})(CssGreenButton);

interface IFooterProps {
  isAuthenticated: boolean;
  isMine: boolean;
  card: ICardVersion;
}
/**
 * Footer for using in card
 * @param props IFooterProps
 */
export const Footer: React.FC<IFooterProps> = ({ isAuthenticated, card, isMine }) => {
  const classes = useStyles();
  const showOfferPage = useShowOfferPage();

  const url = useMemo(() => {
    if (card.card.account!.type === E_ACCOUNT_TYPE.Reseller) {
      return LANDING_ROUTE.path;
    } else if (showOfferPage) {
      return LANDING_ROUTE.path;
    } else if (isAuthenticated) {
      return EDITOR_ROUTE.path;
    } else {
      return LANDING_ROUTE.path;
    }
  }, [isAuthenticated, card, showOfferPage]);

  return (
    <div className={classes.darken}>
      <Container fixed maxWidth="md" style={{ maxWidth: '730px', zIndex: 2 }}>
        <div className={clsx(classes.footer)}>
          <div className={classes.copyright}>
            <Logo className={classes.logo} />
            <Box my={1}>POWERED BY GO PAPERLESS</Box>
            <Box mb={1}>
              ©{(new Date()).getUTCFullYear()} Go Paperless Ltd. All rights reserved. -{' '}
              <a href={PRIVACY_ROUTE.path} target="_blank" rel="noopener noreferrer" className={classes.link}>
                Privacy
              </a>
              <span> & </span>
              <a href={TERMS_ROUTE.path} target="_blank" rel="noopener noreferrer" className={classes.link}>
                T&Cs
              </a>
            </Box>
            <If condition={isMine}>
              <Then>
                <a href={DASHBOARD_ROUTE.path} target="_blank" rel="noopener noreferrer" className={classes.link}>
                  <GetButton>View my dashboard</GetButton>
                </a>
              </Then>
              <Else>
                <a href={url} target="_blank" rel="noopener noreferrer" className={classes.link}>
                  <GetButton className="_outlinedButton">
                    Try for free
                  </GetButton>
                </a>
              </Else>
            </If>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default connect((state: IReduxState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isMine: state.auth.profile.id === state.cardVersionReducer.publicVersion.card!.account!.id,
  card: state.cardVersionReducer.publicVersion,
}))(Footer);
