import React, { useEffect, useCallback, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from 'modules/billing/components/Billing';
import { STRIPE_STATUS_SYMBOL } from './Steps/PayData';
import { Stepper } from 'modules/core/components/Stepper';
import { useDefaultManifest } from 'modules/core/utils';
import useStylesPage from 'modules/public/pages/main/pageStyles';
import { Steps } from './Steps';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { Link } from 'modules/core/components/Link';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { useStripe } from './useStripe';
import { useHistory } from 'react-router';
import { EDITOR_ROUTE, SIGNIN_ROUTE, RESELLER_INITIAL_PURCHASE_ROUTE_PATH } from 'routes/routes';
import { E_ACCOUNT_TYPE } from 'modules/auth/constants/enums';

export type MaxSteps = 3 | 5;

export const BuyPage: React.FC = () => {
  const { profile, isAuthenticated } = useTypedSelector((state) => state.auth);
  const classes = useStyles();
  const pageStyles = useStylesPage();
  const [step, setStep] = useState<number>(0);
  const [success, setSuccess] = useState<boolean>(true);
  const [isPAO, setPAO] = useState<boolean>(false);
  const [maxSteps, setMaxSteps] = useState<MaxSteps>(5);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isProcessing, setProcessing] = useState<boolean>(false);
  const stripe = useStripe();
  const history = useHistory();
  useDefaultManifest();

  useEffect(() => {
    if (profile.id) {
      if (profile.subscribed && step <= 1) {
        history.push(EDITOR_ROUTE.path)
      }

      // redirect to reseller starter pack purchase page if account is reseller and has not made initial purchase yet
      if (profile.type === E_ACCOUNT_TYPE.Reseller && !profile.hasBeenSubscribed) {
        history.push(RESELLER_INITIAL_PURCHASE_ROUTE_PATH)
      }
    }
  }, [profile, history, step]);

  const handler = useCallback(
    (s: number) => {
      console.warn(s);
      if (!isProcessing && !(window as any)[STRIPE_STATUS_SYMBOL]) {
        if (s === 0) {
          setPAO(false);
          setMaxSteps(5);
        }
        if (s < step) {
          setStep(s);
        }
      }
    },
    [isProcessing, setStep, setPAO, setMaxSteps, step]
  );

  return (
    <div className={clsx(pageStyles.page, '_padding-sm-mobile')}>
      <Container className={classes.billingWrap}>
        <div className={classes.billingElem}>
          <StripeProvider stripe={stripe}>
            <Elements>
              <Steps
                success={success}
                isPAO={isPAO}
                disabled={disabled}
                setProcessing={setProcessing}
                setStep={setStep}
                setMaxSteps={setMaxSteps}
                setPAO={setPAO}
                setSuccess={setSuccess}
                step={step}
                setDisabled={setDisabled}
                isProcessing={isProcessing}
              />
            </Elements>
          </StripeProvider>
        </div>
        <Box mt={3}>
          <Stepper
            disabledBack={step === 0}
            disabledNext={true}
            steps={maxSteps}
            activeStep={step}
            onChange={handler}
          />
        </Box>
        {!isAuthenticated && (
          <Link className={clsx(classes.Link, step === 0 ? '' : '_hidden')} to={SIGNIN_ROUTE.path}>
            Already have an account? Sign in
          </Link>
        )}
      </Container>
    </div>
  );
};
