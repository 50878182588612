import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { initialDarkGray, lightGreen, brightGrey } from 'themes/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      display: 'flex',
      alignItems: 'center',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    titleButton: {
      flexGrow: 1,
      padding: 0,
      fontSize: 12,
      letterSpacing: 0.4,
      color: initialDarkGray,
      lineHeight: theme.typography.pxToRem(43),
      textTransform: 'none',

      display: 'block',
      whiteSpace: 'nowrap',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& *': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
      maxWidth: '200px',
      [theme.breakpoints.up(400)]: {
        maxWidth: 270,
      },
      [theme.breakpoints.up(640)]: {
        maxWidth: 510,
      },
    },
    dropDown: {
      overflowY: 'auto',
    },
    paper: {
      top: '10px!important',
      marginLeft: -16,
      width: '100%',
      maxWidth: 420,
      border: '1px solid rgba(255,255,255,0.15)',
      borderRadius: 4,
      backgroundColor: '#3B4046',
      boxShadow: '0 10px 35px 0 rgba(0,0,0,0.1)',
      [theme.breakpoints.down(510)]: {
        maxWidth: 360,
      },
      [theme.breakpoints.down(450)]: {
        maxWidth: 300,
        marginLeft: 0,
      },
    },
    versions: {
      marginRight: 2,
      maxHeight: 200,
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    buttonInner: {
      padding: 0,
      margin: 0,
      '& .MuiIconButton-label': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 0,
        padding: 0,
      },
      transform: 'rotate(180deg)',
      [theme.breakpoints.down(510)]: {
        marginRight: 10,
      },
    },
    head: {
      maxWidth: 385,
      margin: '16px auto 0 auto',
      '&> :first-child': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      },
      '& li': {
        margin: 0,
        maxWidth: 385,
        fontWeight: 700,
      },
      '& span': {
        display: 'block',
        fontSize: 20,
        fontWeight: 300,
        fontFamily: 'F37 Ginger Light',
        lineHeight: theme.typography.pxToRem(24),
        color: 'white',
        marginBottom: 21,
        padding: '0 13px',
      },
      '& p': {
        fontWeight: 700,
        margin: '2px 0',
        opacity: 0.8,
        fontSize: 14,
        lineHeight: theme.typography.pxToRem(20),
      },
    },
    itemContainer: {
      padding: '9px 13px',
      width: '100%',
      maxWidth: 380,
      borderRadius: 4,
      margin: '0 auto',
      borderBottom: 0,
    },
    item: {
      '&._unselectable': {
        color: lightGreen,
      },
      // '&:hover > p': {
      //   transition: '.2s ease',
      //   color: lightGreen,
      // },
    },
    itemLine1: {
      display: 'block',
      fontSize: 16,
      marginBottom: 2,
      maxWidth: 365,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.down(510)]: {
        maxWidth: 315,
      },
      [theme.breakpoints.down(450)]: {
        maxWidth: 285,
      },
    },
    itemLine2: {
      display: 'block',
      textTransform: 'uppercase',
      fontSize: 10,
      fontFamily: 'F37 Ginger Light'
    },
    addCard: {
      textTransform: 'uppercase',
      margin: theme.spacing(0, 'auto'),
      padding: "10px 12px",
      display: 'flex',
      alignItems: 'flex-start',
      textAlign: 'left',
      width: "100%",
      maxWidth: 380,

      "& *": {
        display: "inline-block",
      },
    },
    addCardBtn: {
      marginRight: theme.spacing(1),
      minWidth: 24,
      verticalAlign: 'bottom',
    },
    addCardText: {
      fontSize: 12,
      letterSpacing: 1.2,
    },
    hr: {
      color: brightGrey,
      height: 1,
      margin: theme.spacing(1, 0),
      width: "100%",
    }
  })
);
