import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { green, darkGreen, lightGreen } from 'themes/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    nextBtn: {
      padding: 0,
      width: 20,
      height: 20,
      '& svg': {
        fill: 'white',
        width: 20,
        height: 20,
      },
      '&.Mui-disabled': {
        '& svg': {
          opacity: 0.5,
        },
      },
    },
    button: {
      margin: theme.spacing(3, 0, 1, 0),
      width: '100%',
      maxWidth: 'initial',
      border: '3px solid #e6e6e6',
      '&.Mui-disabled': {
        color: 'white'
      }
    },
    submitPinButton: {
      marginBottom: theme.spacing(3),
    },
    form: {
      width: '100%',
    },
    body: {
      flexGrow: 1,
      marginTop: theme.spacing(3),
      '&._auth': {
        justifyContent: 'center',
        marginTop: 0,
      },
      '&._withoutError': {
        '& > :last-child': {
          display: 'none',
        },
      },
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(3, 4),
      margin: '30px 0',
      width: 400,
      minHeight: 450,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2, 2),
      },
    },
    footer: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: '100%',
      margin: theme.spacing(0, 0, 4, 0),
      '&._select': {
        '& .MuiInputLabel-shrink': {
          color: '#749D83!important',
          zIndex: 10,
        },
        '& .MuiFilledInput-root:hover': {
          backgroundColor: 'rgba(241,244,245,0.5)',
        },
      },
      '&._mt10': {
        marginTop: 10,
      },
      '&._mb16': {
        marginBottom: 16,
      },
    },
    spacedInput: {
      letterSpacing: 4,
    },
    modeButton: {
      color: theme.palette.text.secondary,
      fontWeight: 600,
    },
    dartWrapper: {
      position: 'relative',
      width: 80,
      height: 72,
      margin: '30px 0 45px',
    },
    dart: {
      color: green,
      position: 'absolute',
      right: 0,
      top: '-190%',
      width: '1200%',
    },
    whiteText: {
      color: 'white',
      textAlign: 'center',
    },
    fullWidth: {
      width: '100%',
      maxWidth: 'initial',
    },
    stepper: {
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      minWidth: 200,
      margin: '0 auto',
    },
    btns: {
      display: 'flex',
    },
    step: {
      display: 'block',
      margin: '0 5px',
      width: 10,
      height: 10,
      borderRadius: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
      transition: 'color .25s ease',
      '&.done': {
        backgroundColor: 'white',
      },
    },
    agreement: {
      marginTop: theme.spacing(4),
      marginRight: 0,
      '& .MuiFormControlLabel-label': {
        marginLeft: 6,
        color: '#749D83',
        fontFamily: 'F37 Ginger Light',
        fontSize: '0.9rem',
      },
    },
    label: {
      color: darkGreen,
    },
    labelLink: {
      color: lightGreen,
      textDecoration: 'underscore',
    },
  })
);
