import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import SaleSvg from 'assets/svg/sale.svg';
import { findCurrency } from 'currency-formatter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sale: {
      background: `url(${SaleSvg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
      width: 60,
      height: 60,
    },
    saleText: {
      textAlign: 'center',
      margin: 'auto',
      width: '40px',
      height: '33px',
      position: 'relative',
      top: '13px',
      color: '#fff',
    },
  })
);

interface ISaleComponent {
  saleCount: number;
  ccy?: string;
  currency?: string;
}

const Sale: React.FC<ISaleComponent> = ({ saleCount, ccy, currency }) => {
  const classes = useStyles();
  const sale = saleCount / 100;
  const ccyObj = useMemo(() => ccy ? findCurrency(ccy.toUpperCase()) : { symbol: currency }, [ccy, currency]);
  return (
    <Box className={classes.sale}>
      <Box className={classes.saleText}>
        <Box fontWeight="bold">
          {ccyObj.symbol}
          {sale}
        </Box>
        <Box fontSize="0.6rem" textAlign="center">
          OFF
        </Box>
      </Box>
    </Box>
  );
};

export default Sale;
