import moment, { Moment } from 'moment';
import { IContactRequests, IContactRequest } from 'modules/dashboard/graphql/gql';

export class ContactRequests implements IContactRequests {
  public contactRequests: ContactRequest[] = [];
  public countContactRequests = 0;
  public countForPage = 0;
  public page = 0;
  public pages = 0;

  constructor(requests?: IContactRequests) {
    if (requests) {
      this.contactRequests = requests.contactRequests.map((r) => new ContactRequest(r));
      this.countContactRequests = requests.countContactRequests;
      this.countForPage = requests.countForPage;
      this.page = requests.page;
      this.pages = requests.pages;
    }
  }
}

export class ContactRequest implements IContactRequest {
  public id!: string;
  public email!: string;
  public lastViewed!: Moment;
  public mobilePhone!: string;
  public name!: string;
  public preference!: string;
  public status!: string;
  public referrer: string = '';
  public creationType: string = 'default';

  constructor(request?: IContactRequest) {
    if (request) {
      this.id = request.id as string;
      this.email = request.email;
      this.lastViewed = moment(request.lastViewed, 'YYYY-MM-DD HH:mm:ss');
      this.mobilePhone = request.mobilePhone;
      this.name = request.name;
      this.preference = request.preference;
      this.status = request.status;
      this.referrer = request.referrer;
      this.creationType = request.creationType;
    }
  }
}
