import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as pallet from 'themes/colors';

export const linkUploadStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    input: {
      display: 'none',
    },
    uploadInpStyle: {
      '& input': {
        padding: '27px 52px 10px 12px',
      },
    },
    icon: {
      color: pallet.limeade,
      background: '#FFF',
      padding: '5px',
      borderRadius: '50%',
    },
    inputWrapper: {
      position: 'relative',
      display: 'flex',
    },
    uploadWrapper: {
      position: 'absolute',
      right: 15,
      top: 15,
      width: 25,
      height: 25,
      display: 'flex',
      alignItems: 'center',

      '& label': {
        display: 'flex',
      },
      '& .MuiSvgIcon-root': {
        width: 25,
        height: 25,
      },
    },
    inputField: {
      paddingRight: '25px',
    },
  })
);
