import React, { useEffect, useMemo } from 'react';
import './styles.css';
import $ from "jquery";
import { E_PREVIEW_TYPE } from 'modules/editor/constants/enums';
import { getPreviewClass } from 'modules/core/utils';

interface IProps {
  previewType?: E_PREVIEW_TYPE;
}

const LandingPage: React.FC<IProps> = ({ previewType, children }) => {
  const previewClass = useMemo(() => getPreviewClass(previewType), [previewType]);

  useEffect(() => {
    const jQuery = $;

    var milliseconds = 3500
      , index = 1
      , max = 6
      // eslint-disable-next-line
      , sth = !1
      , timeout = setInterval(function() {
        slideshow()
      }, milliseconds);
    function slideshow() {
      // if (1 != sth) {
      // if (true) {
        // eslint-disable-next-line
      clearTimeout(timeout),
      sth = !0;
      var u = jQuery(".benefits li.benefit-" + index);
      ++index > max && (index = 1);
      var i = jQuery(".benefits li.benefit-" + index);
      var iDataScreen = parseInt(jQuery(i).attr("data-screen") || "1");
      var uDataScreen = parseInt(jQuery(u).attr("data-screen") || "1");
      var speed = 0;
      // eslint-disable-next-line
      jQuery(i).addClass("active"),
      uDataScreen > iDataScreen ? speed = uDataScreen - iDataScreen : speed = iDataScreen - uDataScreen,
      jQuery(".benefits .gallery ul").removeClass("speed-1 speed-2 speed-3 speed-4 speed-5").addClass("speed-" + speed),
      setTimeout(function() {
        // eslint-disable-next-line
        jQuery(u).removeClass("active"),
        jQuery(".benefits .gallery ul").removeClass("featured-1 featured-2 featured-3 featured-4 featured-5 featured-6").addClass("featured-" + jQuery(i).attr("data-screen")),
        // eslint-disable-next-line
        sth = !1
      }, 50),
      timeout = setInterval(function() {
        slideshow()
      }, milliseconds)
      // }
    }

    jQuery(document).ready(function() {
      // eslint-disable-next-line
      jQuery(".external").each(function() {
        jQuery(this).attr("target", "_blank")
      // eslint-disable-next-line
      }),
      jQuery("img.svg").each(function() {
        var e = jQuery(this)
          , t = e.attr("id")
          , r = e.attr("class")
          , a = e.attr("src") || "";
        jQuery.get(a, function(a) {
          var s = jQuery(a).find("svg");
      // eslint-disable-next-line
          void 0 !== t && (s = s.attr("id", t)),
          void 0 !== r && (s = s.attr("class", r + " replaced-svg")),
          s = s.removeAttr("xmlns:a"),
          e.replaceWith(s)
        }, "xml")
      }),
      jQuery(".hero .more").click(function(e) {
      // eslint-disable-next-line
        e.preventDefault(),
        jQuery("html, body").animate({
          scrollTop: Math.round(jQuery(".hero").outerHeight() as number)
        }, 1e3)
      });
      // }),
      // jQuery(document).on("load resize scroll", function() {
      //   var scrollTop = jQuery(document)!.scrollTop();
      //   jQuery(".hero").length
      //     && !jQuery("#more").hasClass("mobile")
      //     && scrollTop !== undefined
      //     && jQuery(".hero .background").css("margin-top", parseInt(scrollTop.toString() || '0') / 1.5 + "px")
      // });

      if (jQuery(".benefits .info li .padding").hover(function() {
        // eslint-disable-next-line
        if (clearTimeout(timeout), !jQuery(this).parent().hasClass("active")) {
          var e = jQuery(".benefits li.active")
        , r = jQuery(this).parent();
        var speed = 0;
        var rDataScreen = parseInt(jQuery(r).attr("data-screen") || "1");
        var eDataScreen = parseInt(jQuery(e).attr("data-screen") || "1");
        // eslint-disable-next-line
        index = parseInt(jQuery(this).parent().attr("data-screen") || ""),
        jQuery(r).addClass("active"),
        parseInt(jQuery(e).attr("data-screen") || "0") > parseInt(jQuery(r).attr("data-screen") || "0") ? speed = eDataScreen - rDataScreen : speed = rDataScreen - eDataScreen,
        jQuery(".benefits .gallery ul").removeClass("speed-1 speed-2 speed-3 speed-4 speed-5").addClass("speed-" + speed),
        setTimeout(function() {
          // eslint-disable-next-line
          jQuery(e).removeClass("active"),
          jQuery(".benefits .gallery ul").removeClass("featured-1 featured-2 featured-3 featured-4 featured-5 featured-6").addClass("featured-" + jQuery(r).attr("data-screen"))
        }, 50)
      }
    },
    function() {
      timeout = setInterval(
        function() {
          slideshow()
        }, milliseconds / 4
      )
    // eslint-disable-next-line
    }),
      jQuery(".video a").click(function(e) {
        var windowWidth = jQuery(window).width() || 0;
        // eslint-disable-next-line
        e.preventDefault(),
        windowWidth > 500 ? (jQuery(".lightbox .modal").append('<iframe src="' + jQuery(this).attr("href") + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen allowtransparency="true"></iframe>'),
          setTimeout(function() {
            jQuery(".lightbox").addClass("open")
          }, 150)) : window.open(jQuery(this).attr("href"))
      }),
      jQuery(".lightbox .close").click(function(e) {
        e.preventDefault()
      }),
      jQuery(".lightbox").click(function() {
      // eslint-disable-next-line
        jQuery(".lightbox").removeClass("open"),
        setTimeout(function() {
          jQuery(".lightbox .modal iframe").remove()
        }, 150)
      }).children().children().click(function(e) {
        e.stopPropagation()
      // })
      }),
      jQuery(".registration input").focus(function() {
      // eslint-disable-next-line
        jQuery(this).closest("p").find(".wpcf7-not-valid-tip").fadeOut(),
        jQuery(this).closest("p").hasClass("completed") || jQuery(this).closest("p").addClass("completed")
      }),
      jQuery(".registration input").blur(function() {
        var val = jQuery(this).val() || "";

        if (typeof val !== "string") {
          val = ""
        }

        // eslint-disable-next-line
        0 == jQuery.trim(val).length && jQuery(this).closest("p").removeClass("completed")
      }),
      jQuery('.registration input[name="register_date"]').length) {
        // var u = new Date
        //   , i = u.getMonth() + 1
        //   , o = u.getDate();
        //   // t = u.getFullYear() + "-" + (i < 10 ? "0" : "") + i + "-" + (o < 10 ? "0" : "") + o;
        // jQuery('.registration input[name="register_date"]').val(t)
      }
    });

    return () => {
      clearTimeout(timeout);
    }
  }, []);

  return (
    <div className={`LandingPage ${previewClass}`}>
      { children }
    </div>
  )
};

export default LandingPage;
