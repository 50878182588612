import React from 'react';
import { CopyCardURL } from 'modules/core/components/CopyCardURL';
import { connect } from 'react-redux';
import { IReduxState } from 'store';
import CssGreenButton from 'modules/core/components/buttons/roundGreenButton';
import { useHistory } from 'react-router-dom';
import { useDefaultManifest } from 'modules/core/utils';
import { makeStyles } from '@material-ui/core';
import * as banner from 'assets/images/banner.png';

interface IMobileSuccessPublishProps {
  url: string;
}

const MobilePreviewSuccessPage: React.FC<IMobileSuccessPublishProps> = ({ url }) => {
  useDefaultManifest();
  const history = useHistory();
  const classes = useStyles();
  return (
    <div className={classes.pageWrap}>
      <div className={classes.copyWrapper}>
        <CopyCardURL url={url} />
        <div className={classes.buttonWrapper}>
          <CssGreenButton onClick={() => history.push('/editor')}>Editor</CssGreenButton>
        </div>
      </div>
    </div>
  );
};

export const MobilePreviewSuccess = connect((state: IReduxState) => ({
  url: `${window.location.host}/${state.currentVersion.currentVer.card.urlSlug}`,
}))(MobilePreviewSuccessPage);

const useStyles = makeStyles((theme) => ({
  pageWrap: {
    height: '100vh',
    padding: '1rem',
    background: `url(${banner})`,
  },
  copyWrapper: {
    background: '#fff',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    position: 'relative',
    padding: '2rem',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    bottom: '3rem',
    width: '100%',
  },
}));
