import gql from 'graphql-tag';

export const cardProcessingStatusSubscription = gql`
  subscription cardProcessingStatus($cardVersionId: ID!) {
    cardProcessingStatus(cardVersionId: $cardVersionId) {
      processingStatus {
        id
        isProcessing
        isFinished
        isError
        message
      }
    }
  }
`;
