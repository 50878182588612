import React from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import ReactPlayer from 'react-player';
import { useStyles } from './styles';
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import clsx from 'clsx';

interface IVideoProps {
  url: string;
  preview?: any;
  modifier?: string;
}

const Video: React.FC<IVideoProps> = ({ url, preview, modifier }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, preview ? '_preview' : '')}>
      <CardMedia title="Contemplative Reptile" className={classes.media}>
        <ReactPlayer
          light={preview}
          controls={true}
          url={url}
          className={clsx(classes.player, modifier ? modifier : '')}
          playIcon={<PlayCircleFilledWhiteOutlinedIcon />}
        />
      </CardMedia>
    </Card>
  );
};

export default Video;
