import { ID } from 'modules/core/types';
import { IReferrals, IReferral } from 'modules/dashboard/graphql/gql';

export class Referrals implements IReferrals {
  public referrals: Referral[] = [];
  public totalReferralCount = 0;
  public countForPage = 0;
  public page = 0;
  public pages = 0;

  constructor(data?: IReferrals) {
    if (data) {
      this.referrals = data.referrals.map((r) => new Referral(r));
      this.totalReferralCount = data.totalReferralCount;
      this.countForPage = data.countForPage;
      this.page = data.page;
      this.pages = data.pages;
    }
  }
}

export class Referral implements IReferral {
  public id?: ID = 0;
  public email: string = "";
  public mobilePhone: string = "";
  public fullName!: string;
  public status!: string;
  public outreachType!: string;
  public type?: string = '';
  public sentAt?: string = '-';

  constructor(referral?: IReferral) {
    if (referral) {
      this.id = referral.id as string;
      this.email = referral.email || "";
      this.mobilePhone = referral.mobilePhone || "";
      this.fullName = referral.fullName;
      this.status = referral.status;
      this.outreachType = referral.outreachType;
      this.type = referral.type;
      this.sentAt = referral.sentAt;
    }
  }
}
