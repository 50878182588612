import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      // minWidth: 630,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      background: 'transparent',
      backgroundPosition: 'center',
      '&._preview': {
        minWidth: 'unset',
        width: 320,
        height: 180,
        [theme.breakpoints.down('sm')]: {
          width: 192,
          height: 108,
        },
        [theme.breakpoints.down('xs')]: {
          width: '100%',
          height: 'unset',
          marginBottom: 23,
        },
      },
      '& svg': {
        fill: 'white',
        width: 60,
        height: 60,
        zIndex: 2,
      },
      [theme.breakpoints.down('md')]: {
        minWidth: 'unset',
        width: '100%',
        // height: '100vh',
      },
    },
    player: {
      display: 'flex',
      width: '100%!important',
      height: '100%!important',
      minHeight: 320,
      '&._learning': {
        minHeight: 180,
        '& > *': {
          minHeight: 180,
        },
        '& iframe': {
          minHeight: 180,
        },
      },
      '& > *': {
        display: 'flex',
        height: '100%!important',
        minHeight: 320,
      },
      '& iframe': {
        height: '80vh',
        width: '80vw',
        [theme.breakpoints.down('md')]: {
          height: '100%!important',
          minHeight: 320,  
          width: '100%',
        }
      },
    },
    media: {
      width: '100%',
    },
    '._preview': {
      background: 'transparent',
    },
    button: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      zIndex: 2,
      transform: 'translate(-50%, -50%)',
      '&:before': {
        position: 'absolute',
        zIndex: 1,
        top: '50%',
        left: '50%',
        width: 60,
        height: 60,
        background: 'rgba(0,0,0,0.4)',
        transform: 'translate(-50%, -50%)',
        borderRadius: '50%',
        content: `' '`,
      },
    },
    paper: {
      background: 'transparent',
      minWidth: 630,
    },
  })
);
