export const buildGqlScheme = (scheme: { [key: string]: string }, data: object): string => {
  const res: string[] = [];
  if (scheme && data) {
    Object.keys(data).forEach((key: string) => {
      const schemeItem = scheme[`$${key}`];
      if (schemeItem) {
        res.push(`$${key}: ${schemeItem}`);
      }
    });
  }
  return res.join(', ');
};

export const buildGqlAttributes = (data: object): string => {
  if (data) {
    return Object.keys(data)
      .map((key) => `${key}: $${key}`)
      .join(', ');
  }
  return '';
};

export const buildGqlRequest = (fields: string[]): string => {
  if (fields && Array.isArray(fields)) {
    return fields.join(', ');
  }
  return '';
};
