import React, { useRef, useEffect } from 'react';
import { IDialogRef } from 'modules/core/containers/DialogWrapper';

export function useDialogRef(): React.RefObject<IDialogRef> {
  const ref = useRef<IDialogRef>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.show();
    }
  }, [ref]);
  return ref;
}
