import { Map as ImutableMap } from 'immutable';
import { createStepMap } from 'modules/core/utils';
import { E_CARD_STEPS, E_STEP_TYPE } from 'modules/editor/constants/enums';
import {
  ADMIN_DISABLED_FIELDS,
  MEMBER_DISABLED_FIELDS,
  OWNER_DISABLED_FIELDS,
  RESALER_DISABLED_FIELDS,
} from 'modules/editor/constants/fields';
import {
  ABOUT_STEP,
  ALL_STEPS,
  GALLERY_STEP,
  LOCATION_STEP,
  NEWS_STEP,
  OPTIONAL_STEPS_ITEMS,
  PERSONAL_STEP,
  PROMOTION_STEP,
  REQUIRED_STEP_ITEMS,
  VIDEO_STEP,
} from 'modules/editor/constants/steps';
import { ICardStep } from 'modules/editor/interfaces';
import { createSymbiote, Symbiotes } from 'redux-symbiote';
import { setEditorWalkthroughAsFinished, isEditorWalkthroughFinished } from 'modules/editor/utils';
import { E_ACCOUNT_TYPE } from 'modules/auth/constants/enums';

export interface ICardStepsRS {
  stepMap: Map<E_CARD_STEPS, ICardStep>;
  currentStep: ICardStep;
  nextStep: ICardStep;

  stepSizes: ImutableMap<E_CARD_STEPS, number>;
  curPreviewScroll: number;

  isFirstEdit: boolean;

  stepDisabledStateMap: ImutableMap<E_CARD_STEPS, boolean>;
  stepFilledOutStateMap: ImutableMap<E_CARD_STEPS, boolean>;
  hintsStateMap: ImutableMap<E_CARD_STEPS, boolean>;
  stepIsOpenStateMap: ImutableMap<E_CARD_STEPS, boolean>;

  allowSteps: ICardStep[];
  allowOptionalSteps: ICardStep[];

  brandingFields: string[];
  stylingFields: string[];
  personalFields: string[];
  socialFields: string[];
  aboutFields: string[];
  promotionFields: string[];
  newsFields: string[];
  videoFields: string[];
  galleryFields: string[];
  locationFields: string[];
}

export interface ICardStepsRA extends Symbiotes<ICardStepsRS> {
  setStep(state: ICardStepsRS, step: E_CARD_STEPS): ICardStepsRS;
  closeHint(state: ICardStepsRS, step: E_CARD_STEPS): ICardStepsRS;
  setStepIsOpen(state: ICardStepsRS, step: E_CARD_STEPS, value: boolean): ICardStepsRS;
  doneNextStep(state: ICardStepsRS, step: E_CARD_STEPS): ICardStepsRS;
  afterPublish(state: ICardStepsRS): ICardStepsRS;
  disableAdminFields(state: ICardStepsRS, isFirstEdit: boolean): ICardStepsRS;
  disableMemberFields(
    state: ICardStepsRS,
    isFirstEdit: boolean,
    truePermissions: string[],
    falsePermissions: string[]
  ): ICardStepsRS;
  disableResalerFields(state: ICardStepsRS, isFirstEdit: boolean): ICardStepsRS;
  disableOwnerFields(state: ICardStepsRS, isFirstEdit: boolean): ICardStepsRS;
  updateStepSize(state: ICardStepsRS, step: E_CARD_STEPS, height: number): ICardStepsRS;
  updateScroll(state: ICardStepsRS, step: E_CARD_STEPS, height: number): ICardStepsRS;
  clear(state: ICardStepsRS): ICardStepsRS;
}

function calcPreviewScoll(state: ICardStepsRS, step: E_CARD_STEPS) {
  if (
    step === E_CARD_STEPS.PERSONAL_DETAILS ||
    step === E_CARD_STEPS.SOCIAL_LINKS ||
    step === E_CARD_STEPS.BRANDING ||
    step === E_CARD_STEPS.STYLING
  ) {
    return 0;
  }
  if (state.stepSizes.get(step) !== 0) {
    let s = ALL_STEPS[0];
    let scroll = 0;
    let i = 0;
    while (s.step !== step) {
      s = ALL_STEPS[i];
      if (s.step !== step && i !== ALL_STEPS.length - 1) {
        scroll += (state.stepSizes.get(s.step) as number) + 30; // 30px margin bootom of section
      }
      i++;
    }
    return scroll;
  } else {
    return state.curPreviewScroll;
  }
}

function setStep(state: ICardStepsRS, step: E_CARD_STEPS) {
  const currentStep = state.stepMap.get(step) as ICardStep;
  const allSteps = [...state.allowSteps, ...state.allowOptionalSteps];
  const currentStepIndex = allSteps.findIndex((s) => s.step === currentStep!.step);
  const nextStep = allSteps[currentStepIndex + 1] || allSteps[allSteps.length - 1];
  let stepFilledOutStateMap = state.stepFilledOutStateMap;

  if (state.isFirstEdit) {
    stepFilledOutStateMap = stepFilledOutStateMap.set(step, true);

    if (currentStep.type === E_STEP_TYPE.ADVANCED) {
      setEditorWalkthroughAsFinished();
    }
  }

  return {
    ...state,
    currentStep,
    stepFilledOutStateMap,
    nextStep,
    curPreviewScroll: calcPreviewScoll(state, step),
  };
}

const defaultInitialStep = ALL_STEPS[0];
const defaultNextStep = ALL_STEPS[1];

function initImutableMap(steps: ICardStep[], value: boolean) {
  let map = ImutableMap<E_CARD_STEPS, boolean>();
  steps.forEach((s) => (map = map.set(s.step, value)));
  return map;
}

function generateMapIsOpen(state: ICardStepsRS, step: E_CARD_STEPS, value: boolean) {
  const allSteps = [...state.allowSteps, ...state.allowOptionalSteps];
  let stepIsOpenStateMap = ImutableMap<E_CARD_STEPS, boolean>();
  allSteps.forEach((s) => stepIsOpenStateMap.set(s.step, false));
  stepIsOpenStateMap = stepIsOpenStateMap.set(step, value);
  return stepIsOpenStateMap;
}

function initHeightMap(): ImutableMap<E_CARD_STEPS, number> {
  let heightMap = ImutableMap<E_CARD_STEPS, number>();
  ALL_STEPS.forEach((s) => (heightMap = heightMap.set(s.step, 0)));
  return heightMap;
}

function initFilledOutMap(steps: ICardStep[], isFirstEdit: boolean): ImutableMap<E_CARD_STEPS, boolean> {
  let stepFilledOutMap = ImutableMap<E_CARD_STEPS, boolean>();

  steps.forEach((s) => (stepFilledOutMap = stepFilledOutMap.set(s.step, !isFirstEdit)));

  if (isFirstEdit) {
    stepFilledOutMap = stepFilledOutMap.set(defaultInitialStep.step, true);
  }

  return stepFilledOutMap;
}

const STATE: ICardStepsRS = {
  stepMap: createStepMap(ALL_STEPS),
  currentStep: defaultInitialStep,
  nextStep: defaultNextStep,

  stepSizes: initHeightMap(),
  curPreviewScroll: 0,

  isFirstEdit: false,

  stepDisabledStateMap: ImutableMap(),
  stepFilledOutStateMap: ImutableMap(),
  hintsStateMap: ImutableMap(),
  stepIsOpenStateMap: ImutableMap(),

  allowSteps: REQUIRED_STEP_ITEMS,
  allowOptionalSteps: OPTIONAL_STEPS_ITEMS,

  aboutFields: [],
  brandingFields: [],
  galleryFields: [],
  locationFields: [],
  newsFields: [],
  personalFields: [],
  promotionFields: [],
  socialFields: [],
  stylingFields: [],
  videoFields: [],
};

export const { actions, reducer } = createSymbiote<ICardStepsRS, ICardStepsRA>(STATE, {
  setStep: (state: ICardStepsRS, step: E_CARD_STEPS) => setStep(state, step),
  disableResalerFields: (state: ICardStepsRS, isFirstEdit: boolean) => {
    const allSteps = [PERSONAL_STEP];
    const stepMap = createStepMap(allSteps);
    const isFE = isFirstEdit && !isEditorWalkthroughFinished(E_ACCOUNT_TYPE.Reseller);
    const stepDisabledStateMap = initImutableMap(allSteps, false);

    return {
      ...state,
      ...RESALER_DISABLED_FIELDS,
      stepMap,
      isFirstEdit: isFE,
      hintsStateMap: initImutableMap(allSteps, isFE),
      stepFilledOutStateMap: initFilledOutMap(allSteps, isFE),
      stepIsOpenStateMap: initImutableMap(allSteps, false),
      currentStep: isFE ? allSteps[0] : state.currentStep,
      nextStep: allSteps[0],
      stepDisabledStateMap,
      allowSteps: allSteps,
      allowOptionalSteps: [],
    };
  },
  disableMemberFields: (
    state: ICardStepsRS,
    isFirstEdit: boolean,
    truePermissions: string[],
    falsePermissions: string[]
  ) => {
    const memberEnabledFields = ALL_STEPS.filter((value) => truePermissions.includes(value.id));
    const allSteps = [PERSONAL_STEP, ...memberEnabledFields];
    const stepMap = createStepMap(allSteps);
    const isFE = isFirstEdit && !isEditorWalkthroughFinished(E_ACCOUNT_TYPE.Member);
    const stepDisabledStateMap = initImutableMap(allSteps, false);

    falsePermissions.push('socialFields', 'brandingFields', 'stylingFields');
    const disbledSectionsList = Object.entries(MEMBER_DISABLED_FIELDS).filter((val) =>
      falsePermissions.includes(val[0])
    );
    const disabledFields = Object.fromEntries(disbledSectionsList);

    return {
      ...state,
      ...disabledFields,
      stepMap,
      isFirstEdit: isFE,
      currentStep: isFE ? allSteps[0] : state.currentStep,
      nextStep: allSteps[0],
      stepDisabledStateMap,
      stepFilledOutStateMap: initFilledOutMap(allSteps, isFE),
      hintsStateMap: initImutableMap(allSteps, isFE),
      stepIsOpenStateMap: initImutableMap(allSteps, false),
      allowSteps: [PERSONAL_STEP],
      allowOptionalSteps: [...memberEnabledFields],
      step: E_CARD_STEPS.PERSONAL_DETAILS,
    };
  },
  disableOwnerFields: (state: ICardStepsRS, isFirstEdit: boolean) => {
    const allSteps = [...REQUIRED_STEP_ITEMS, ...OPTIONAL_STEPS_ITEMS];
    const stepMap = createStepMap(allSteps);
    const isFE = isFirstEdit && !isEditorWalkthroughFinished(E_ACCOUNT_TYPE.Owner);
    const stepDisabledStateMap = initImutableMap(allSteps, false);
    const currentStep = isFE ? allSteps[0] : state.currentStep;

    return {
      ...state,
      ...OWNER_DISABLED_FIELDS,
      stepMap,
      isFirstEdit: isFE,
      currentStep,
      nextStep: allSteps[1],
      stepDisabledStateMap,
      stepFilledOutStateMap: initFilledOutMap(allSteps, isFE),
      hintsStateMap: initImutableMap(allSteps, isFE),
      stepIsOpenStateMap: initImutableMap(allSteps, false),
      allowSteps: REQUIRED_STEP_ITEMS,
      allowOptionalSteps: [ABOUT_STEP, PROMOTION_STEP, NEWS_STEP, VIDEO_STEP, GALLERY_STEP, LOCATION_STEP],
    };
  },
  disableAdminFields: (state: ICardStepsRS, isFirstEdit: boolean) => {
    const allSteps = [...REQUIRED_STEP_ITEMS, ...OPTIONAL_STEPS_ITEMS];
    const map = createStepMap(allSteps);
    const isFE = isFirstEdit && !isEditorWalkthroughFinished(E_ACCOUNT_TYPE.Admin);
    const stepDisabledStateMap = initImutableMap(allSteps, false);

    return {
      ...state,
      ...ADMIN_DISABLED_FIELDS,
      stepMap: map,
      isFirstEdit: isFE,
      currentStep: isFE ? allSteps[0] : state.currentStep,
      nextStep: allSteps[1],
      stepDisabledStateMap,
      stepFilledOutStateMap: initFilledOutMap(allSteps, isFE),
      hintsStateMap: initImutableMap(allSteps, !isFE),
      stepIsOpenStateMap: initImutableMap(allSteps, false),
      allowSteps: REQUIRED_STEP_ITEMS,
      allowOptionalSteps: OPTIONAL_STEPS_ITEMS,
    };
  },
  afterPublish: (state: ICardStepsRS) => {
    const allSteps = [...state.allowSteps, ...state.allowOptionalSteps];

    return {
      ...state,
      isFirstEdit: false,
      stepFilledOutStateMap: initImutableMap(allSteps, false),
      stepDisabledStateMap: initImutableMap(allSteps, false),
      hintsStateMap: initImutableMap(allSteps, false),
    };
  },
  setStepIsOpen: (state: ICardStepsRS, step: E_CARD_STEPS, value: boolean) => {
    const setStepRes = setStep(state, step);
    return {
      ...setStepRes,
      stepIsOpenStateMap: generateMapIsOpen(state, step, value),
    };
  },
  closeHint: (state: ICardStepsRS, step: E_CARD_STEPS) => {
    let hintsStateMap = state.hintsStateMap.set(step, false);
    if (state.stepMap.get(step)!.type === E_STEP_TYPE.ADVANCED) {
      hintsStateMap = hintsStateMap.merge(initImutableMap(state.allowOptionalSteps, false));
    }
    return {
      ...state,
      hintsStateMap,
    };
  },
  doneNextStep: (state: ICardStepsRS, step: E_CARD_STEPS) => {
    const stepFilledOutStateMap = state.stepFilledOutStateMap.set(state.currentStep.step, true);
    const setStepRes = setStep(state, step);
    return {
      ...setStepRes,
      stepFilledOutStateMap,
      stepIsOpenStateMap: generateMapIsOpen(state, step, true),
    };
  },
  updateStepSize: (state: ICardStepsRS, step: E_CARD_STEPS, height: number) => {
    const nState: ICardStepsRS = {
      ...state,
      stepSizes: state.stepSizes.set(step, height),
    };
    return {
      ...nState,
      curPreviewScroll: state.curPreviewScroll + 1,
    };
  },
  updateScroll: (state: ICardStepsRS, step: E_CARD_STEPS, height: number) => {
    const nState: ICardStepsRS = {
      ...state,
      stepSizes: state.stepSizes.set(step, height),
    };
    console.warn('up scroll');
    return {
      ...nState,
      curPreviewScroll: calcPreviewScoll(nState, step),
    };
  },
  clear: () => ({ ...STATE }),
});
