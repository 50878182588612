import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const mobilePreveiwStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100vw',
      height: '100vh',
      overflow: 'auto',
    },
  })
);
