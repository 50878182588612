import { ILocationAtributes, ILocationOpeningTimeAttributes } from 'modules/editor/graphql/mutations';
import { ICardVersion, ILocationSectionOpeningTime } from 'modules/editor/graphql/gql';
import { IModel } from 'modules/core/interfaces/model';
import { clearObject } from 'modules/core/utils';
import uuid from 'uuid';

export class LocationAtributes implements ILocationAtributes, IModel<ILocationAtributes> {
  public cardVersionId!: number;
  public city?: string = '';
  public country?: string = '';
  public postcode?: string = '';
  public state?: string = '';
  public streetAddress?: string = '';
  public streetAddress2?: string = '';
  public display12h: boolean = false;
  public openingTimesAttributes: LocationOpeningTime[] = [];
  public id!: number;
  public lat?: string | null;
  public lng?: string | null;
  [props: string]: any;

  constructor(card?: ICardVersion) {
    if (card) {
      this.cardVersionId = card.id;
      this.city = card.locationSection.city || '';
      this.country = card.locationSection.country || '';
      this.postcode = card.locationSection.postcode || '';
      this.state = card.locationSection.state || '';
      this.streetAddress = card.locationSection.streetAddress || '';
      this.streetAddress2 = card.locationSection.streetAddress2 || '';
      this.display12h = card.locationSection.display12h || false;
      this.openingTimesAttributes = card.locationSection.openingTimes.map((ot) => new LocationOpeningTime(ot));
      this.id = card.locationSection.id as number;
      this.lat = card.locationSection.lat || null;
      this.lng = card.locationSection.lng || null;
      if (!this.openingTimesAttributes.length) {
        this.openingTimesAttributes.push(new LocationOpeningTime());
      }
    }
  }

  public nextState(state: LocationAtributes): LocationAtributes {
    const locAtr = new LocationAtributes();
    locAtr.cardVersionId = state.cardVersionId;
    locAtr.city = state.city;
    locAtr.country = state.country;
    locAtr.id = state.id;
    locAtr.postcode = state.postcode;
    locAtr.state = state.state;
    locAtr.streetAddress = state.streetAddress;
    locAtr.streetAddress2 = state.streetAddress2;
    locAtr.lat = state.lat;
    locAtr.lng = state.lng;
    locAtr.display12h = state.display12h;
    locAtr.openingTimesAttributes = state.openingTimesAttributes.map((ot) => ot.nextState(ot));
    return locAtr;
  }

  public asState(): ILocationAtributes {
    return {
      cardVersionId: this.cardVersionId,
      city: this.city,
      country: this.country,
      postcode: this.postcode,
      state: this.state,
      streetAddress: this.streetAddress,
      streetAddress2: this.streetAddress2,
      id: this.id,
      lat: this.lat,
      lng: this.lng,
      display12h: this.display12h,
      openingTimesAttributes: this.openingTimesAttributes.map((ot) => ot.asState()),
    };
  }

  public clear(nullable: boolean) {
    const atr = new LocationAtributes();
    clearObject(atr, nullable);
    atr.id = this.id;
    atr.cardVersionId = this.cardVersionId;
    if (nullable) {
      this.openingTimesAttributes.forEach((ot) => (ot._destroy = true));
      atr.openingTimesAttributes = this.openingTimesAttributes;
    } else {
      atr.openingTimesAttributes = [];
    }
    return atr;
  }

  public toJSON(): ILocationAtributes {
    return {
      cardVersionId: this.cardVersionId,
      city: this.city ? this.city : null,
      country: this.country ? this.country : null,
      postcode: this.postcode ? this.postcode : null,
      state: this.state ? this.state : null,
      id: this.id,
      lat: this.lat ? this.lat : null,
      lng: this.lng ? this.lng : null,
      streetAddress: this.streetAddress ? this.streetAddress : null,
      streetAddress2: this.streetAddress2 ? this.streetAddress2 : null,
      display12h: this.display12h ? this.display12h : null,
      openingTimesAttributes: this.openingTimesAttributes.map((o) => o.toJSON()),
    };
  }
}

export class LocationOpeningTime implements ILocationOpeningTimeAttributes, IModel<ILocationOpeningTimeAttributes> {
  // tslint:disable-next-line: variable-name
  public _destroy?: boolean;
  // tslint:disable-next-line: variable-name
  public _reactUID = uuid();
  public fromDay?: string = '';
  public toDay?: string = '';
  public id?: number;
  public fromHour?: number;
  public toHour?: number;
  [props: string]: any;

  constructor(atributes?: ILocationSectionOpeningTime) {
    if (atributes) {
      this.fromDay = atributes.fromDay || '';
      this.toDay = atributes.toDay || '';
      this.id = atributes.id;
      this.fromHour = atributes.fromHour as number;
      this.toHour = atributes.toHour as number;
      this._reactUID = uuid();
    }
  }

  public asState(): ILocationOpeningTimeAttributes {
    return {
      _destroy: this._destroy,
      fromDay: this.fromDay,
      id: this.id,
      toDay: this.toDay,
      fromHour: this.fromHour,
      toHour: this.toHour,
    };
  }

  public nextState(state: LocationOpeningTime) {
    const opTime = new LocationOpeningTime();
    opTime.toDay = state.toDay;
    opTime.fromDay = state.fromDay;
    opTime._destroy = state._destroy;
    opTime.id = state.id;
    opTime.fromHour = state.fromHour;
    opTime.toHour = state.toHour;
    opTime._reactUID = state._reactUID;
    return opTime;
  }

  public clear(nullable: boolean) {
    const atr = new LocationOpeningTime();
    clearObject(atr, nullable);
    return atr;
  }

  public toJSON(): ILocationOpeningTimeAttributes {
    return {
      id: this.id,
      _destroy: this._destroy,
      fromDay: this.fromDay,
      toDay: this.toDay,
      fromHour: this.hoursToJSON(this.fromHour, '0'),
      toHour: this.hoursToJSON(this.toHour, '24'),
    };
  }

  private hoursToJSON(hour: number | undefined, def: string): string | undefined {
    if (hour) {
      return hour.toString();
    } else if (this.id || this.toDay || this.fromDay) {
      return def;
    } else {
      return undefined;
    }
  }
}
