import React from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from 'modules/editor/components/CardSectionsView/MainInfo/useStyles';
import { ICardVersion } from 'modules/editor/graphql/gql';
import { useUserStyleOfBody } from 'modules/editor/containers/Card/functions';
import { Button } from 'modules/editor/components/CardSectionsView/MainInfo/ShareButton/Button';

interface IProps {
  cardVer: ICardVersion;
  disabled: boolean;
}

export const ShareButton: React.FC<IProps> = (props) => {
  const { cardVer, disabled } = props;
  const { cardStyle } = cardVer;
  const classes = useStyles();
  const bodyStyle = useUserStyleOfBody(cardStyle);

  return (
    <Box>
      <Box position="absolute" className={classes.share}>
        <Box position="absolute" className={classes.text} style={bodyStyle}>
          Share card
        </Box>
        <Button disabled={disabled} cardVer={cardVer} />
      </Box>
    </Box>
  );
};
