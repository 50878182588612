import React from 'react';
import { useStyles } from 'modules/public/pages/homepage/styles';
import usePageStyles from 'modules/public/pages/main/styles';
import Review from 'modules/public/components/review';
import { Container } from '@material-ui/core';
import useStylesContainer from 'modules/public/pages/main/containerStyles';
import clsx from 'clsx';
import SliderResponsive from 'modules/core/components/carousel/responsiveSlider';
// import Carousel from 'modules/core/components/carousel';
import { IResponsive } from 'modules/core/interfaces';

export interface ITestimonialContentData {
  name: string;
  company: string;
  review: string;
  image: any;
}

interface IProps {
  responsiveValues?: IResponsive;
  items: ITestimonialContentData[];
}

const Testimonials: React.FC<IProps> = ({ responsiveValues, items=[] }) => {
  const classes = useStyles();
  const pageStyles = usePageStyles();
  const containerClasses = useStylesContainer();

  return (
    <>
      <Container className={clsx(containerClasses.container, classes.container)}>
        <div className={clsx(classes.items, "_testimonials")}>
          <div className={clsx(pageStyles.advantagesWrap, "_testimonialsWrap")}>
            <SliderResponsive
              isMobile
              settings={{
                dots: true,
                infinite: true,
                arrows: false,
                slidesToScroll: 1, // how many slides to scroll at once
                autoplay: true,
                autoplaySpeed: 3000,
              }}
            >
              { items.map((item, index) => {
                return (
                  <Review
                    key={index}
                    text={item.review}
                    starsCount={5}
                    img={item.image}
                    name={item.name}
                    position={item.company}
                  />
                )
              })}
            </SliderResponsive>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Testimonials;
