import React, { forwardRef } from 'react';
import DialogWrapper, { IDialogRef } from 'modules/core/containers/DialogWrapper';
import useStyles from 'modules/editor/components/CardSectionsView/MainInfo/RoundButton/styles';
import { Typography, Box } from '@material-ui/core';
import clsx from 'clsx';
import { usePlatform } from 'hooks/usePlatform';

interface IProps {
  icon: JSX.Element;
}

/**
 * Modal that apears when users tries to save card on desktop
 */
export const SaveDialog = forwardRef<IDialogRef, IProps>((props, ref) => {
  const { icon } = props;
  const classes = useStyles();
  const platform = usePlatform();

  const text = `Press ${platform === 'mac' ? 'CMD + D' : 'CTRL + D'} to add a bookmark`;

  return (
    <DialogWrapper dialogId="save" ref={ref}>
      <div>
        <Box my={4}>
          <div className={classes.card}>
            <Box className={clsx(classes.iconWrapper, classes.biggerIcon)}>{icon}</Box>
          </div>
          <Typography variant="h5">{text}</Typography>
        </Box>
      </div>
    </DialogWrapper>
  );
});
