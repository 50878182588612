import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Link } from '@material-ui/core';
import { CheckRounded } from '@material-ui/icons';
import { reloadProfile } from 'modules/auth/thunks/auth';
import { FabPreviewButton, MenuButton } from 'modules/core/components/buttons/PublishButton/MenuButton';
import { startCardPublish } from 'modules/editor/api/card-mutations';
import { currentVersionIds, getCardVersions } from 'modules/editor/api/card-query';
import { E_CARD_STEPS } from 'modules/editor/constants/enums';
import { ICardVersion } from 'modules/editor/graphql/gql';
import { actions } from 'modules/editor/reducers/stepsReducer';
import { actions as cardActions } from 'modules/editor/reducers/cardReducer';
import { Else, If, Then } from 'react-if';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BUY_ROUTE, EDITOR_ROUTE, SUCCESS_PUBLISH } from 'routes/routes';
import { IReduxState } from 'store';

import ValidatePublishDialog from 'modules/core/components/dialogs/PublishValidateDialog';
import InitialPublishWarningDialog from 'modules/core/components/dialogs/PublishWarningDialog';
import CardProcessingStatusDialog from 'modules/core/components/dialogs/CardProcessingStatusDialog';
import CardPublishSuccessDialog from 'modules/core/components/dialogs/CardPublishSuccessDialog';
import CardProcessingErrorDialog from 'modules/core/components/dialogs/CardProcessingErrorDialog';
import DialogWrapper from 'modules/core/containers/DialogWrapper';
import WarnContent from '../../dialogs/WarnContent';
import { OverlayProgress } from 'modules/core/components/progress/OverlayProgress';

export { MenuButton, FabPreviewButton } from 'modules/core/components/buttons/PublishButton/MenuButton';

interface IPublishButtonProps {
  isMobile?: boolean;
  needBuy: boolean;
  url: string;
  currentCardVersion: ICardVersion;
  canChangeUrl: boolean;
  showPublishFlow: boolean;
  startCardPublish: (cardVersionId: number) => Promise<void>;
  reloadProfile: () => Promise<void>;
  getCardVersions: () => Promise<void>;
  changeStep: (step: E_CARD_STEPS) => void;
  setStepIsOpen: (step: E_CARD_STEPS, value: boolean) => void;
  currentVersionIds: () => Promise<void>;
  updateStepsState: () => void;
  setShowPublishFlow: (isProcessing: boolean) => void;
}

/**
 * Incapsulate card publication, implements polimorfism for view in mobile/desktop
 * @param props IPublishButtonProps
 */
const PublishBtn: React.FC<IPublishButtonProps> = ({
  isMobile,
  needBuy,
  url,
  canChangeUrl,
  startCardPublish,
  getCardVersions: getVersions,
  reloadProfile: profileReload,
  currentCardVersion,
  changeStep,
  setStepIsOpen,
  currentVersionIds: curVerIds,
  updateStepsState,
  showPublishFlow,
  setShowPublishFlow,
}) => {
  const needBuyWarnRef = useRef<any>();
  const [showPublishWarning, setShowPublishWarning] = useState(false);
  const [showPublishRequiredDialog, setShowPublishRequiredDialog] = useState(false);
  const [isSuccessPublish, setSuccessPublish] = useState(false);
  const [showCardProcessingStatusModal, setShowCardProcessingStatusModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showErrorMessageModal, setShowErrorMessageModal] = useState(false);
  const closeErrorMessageModal = () => setShowErrorMessageModal(false);
  const history = useHistory();


  // validators


  const isRequiredFieldsEmpty = useCallback(() => {
    if (
      !currentCardVersion.card.urlSlug ||
      !currentCardVersion.fullName ||
      (!currentCardVersion.mobilePhone && !currentCardVersion.email)
    ) {
      setShowPublishRequiredDialog(true);
      return true;
    }
    return false;
  }, [currentCardVersion]);


  // Core processing logic

  useEffect(() => {
    if (showPublishFlow) {
      // showing the cardProcessingModal will subscribe the user to card processing updates
      setShowCardProcessingStatusModal(true);
    }
  }, [showPublishFlow, setShowCardProcessingStatusModal]);

  const handleProcessingError = useCallback((error?: string) => {
    const stringError = JSON.stringify(error) || "";

    setShowCardProcessingStatusModal(false);
    setErrorMessage(stringError);
    setShowErrorMessageModal(true);
    setShowPublishFlow(false);
  }, [
    setShowCardProcessingStatusModal,
    setErrorMessage,
    setShowErrorMessageModal,
    setShowPublishFlow,
  ]);

  const startPublish = useCallback(async () => {
    console.log("startPublish called");
    setShowPublishFlow(true);

    await startCardPublish(currentCardVersion.id)
      .catch((error) => handleProcessingError(error));
  }, [
    setShowPublishFlow,
    startCardPublish,
    currentCardVersion,
    handleProcessingError,
  ]);

  const [isFinishingCardProcessing, setIsFinishingCardProcessing] = useState<boolean>(false);

  const finishPublish = useCallback(async (errorMessage?: string) => {
    if (isFinishingCardProcessing) { return };

    setIsFinishingCardProcessing(true);

    if (errorMessage) {
      handleProcessingError(errorMessage);
    } else {
      console.log("finishPublish started");

      await getVersions();
      await curVerIds();
      await profileReload();
      await updateStepsState();
      setShowCardProcessingStatusModal(false);
      setShowPublishFlow(false);

      isMobile ? history.push(SUCCESS_PUBLISH.path) : setSuccessPublish(true);
      console.log("finishPublish succeeded");
    };

    setIsFinishingCardProcessing(false);
  }, [
    getVersions,
    curVerIds,
    profileReload,
    setShowPublishFlow,
    setSuccessPublish,
    history,
    isMobile,
    updateStepsState,
    handleProcessingError,
    isFinishingCardProcessing,
    setIsFinishingCardProcessing,
  ]);


  // click handlers


  const handleStartPublish = useCallback(async () => {
    if (isRequiredFieldsEmpty()) {
      return;
    }
    if (needBuy) {
      needBuyWarnRef.current.show();
      return;
    }
    canChangeUrl ? setShowPublishWarning(true) : startPublish();
  }, [startPublish, canChangeUrl, isRequiredFieldsEmpty, needBuyWarnRef, needBuy]);

  const handleInitialWarningStartPublish = useCallback(async () => {
    setShowPublishWarning(false);
    await startPublish();
  }, [setShowPublishWarning, startPublish]);

  const handleInitialWarningChangeUrl = useCallback(() => {
    setShowPublishWarning(false);
    changeStep(E_CARD_STEPS.PERSONAL_DETAILS);

    if (isMobile) {
      setStepIsOpen(E_CARD_STEPS.PERSONAL_DETAILS, true)
      history.push(EDITOR_ROUTE.path);
    }
  }, [changeStep, setStepIsOpen, isMobile, history]);

  const closeWarn = useCallback(() => needBuyWarnRef.current.hide(), []);

  return (
    <>
      <If condition={!!isMobile}>
        <Then>
          <FabPreviewButton
            id="publish_button_mobile"
            disabled={!currentCardVersion.draft || showPublishFlow}
            onClick={handleStartPublish}
          >
            <CheckRounded color="inherit" />
          </FabPreviewButton>
        </Then>
        <Else>
          <MenuButton
            disabled={!currentCardVersion.draft || showPublishFlow}
            id="publish_button_desktop"
            onClick={handleStartPublish}
          >
            Publish
          </MenuButton>
        </Else>
      </If>

      <InitialPublishWarningDialog
        show={showPublishWarning}
        cardUrl={currentCardVersion.card.urlSlug}
        onClose={() => setShowPublishWarning(false)}
        onClickPublishCta={handleInitialWarningStartPublish}
        onClickChangeUrlCta={handleInitialWarningChangeUrl}
      />
      <ValidatePublishDialog
        show={showPublishRequiredDialog}
        cardUrl={currentCardVersion.card.urlSlug}
        fullName={currentCardVersion.fullName}
        emailAddress={currentCardVersion.email}
        mobileNumber={currentCardVersion.mobilePhone}
        onClose={() => setShowPublishRequiredDialog(false)}
      />

      <CardProcessingStatusDialog
        show={showCardProcessingStatusModal}
        onProcessingFinish={finishPublish}
        cardVersionId={currentCardVersion.id}
        processAction="publish"
      />

      <CardPublishSuccessDialog onClose={() => setSuccessPublish(false)} url={url} show={isSuccessPublish} />
      <CardProcessingErrorDialog
        show={showErrorMessageModal}
        errorMessage={errorMessage}
        onClose={closeErrorMessageModal}
        dialogId="publish_error_message"
      />

      <DialogWrapper ref={needBuyWarnRef} onClose={closeWarn} dialogId="publish_need_buy">
        <WarnContent>
          You must purchase your card before you can publish it. Click <Link href={BUY_ROUTE.path}>Click here</Link> to
          Buy Now
        </WarnContent>
      </DialogWrapper>

      <OverlayProgress isProgress={showPublishFlow} />
    </>
  );
};

export const PublishButton = connect(
  (state: IReduxState) => ({
    currentCardVersion: state.currentVersion.currentVer,
    canChangeUrl: state.auth.profile.canChangeUrl,
    step: state.editorSteps.currentStep.step,
    url: `${window.location.host}/${state.currentVersion.currentVer.card.urlSlug}`,
    needBuy: state.auth.profile.needBuy(),
    showPublishFlow: state.currentVersion.showPublishFlow,
  }),
  {
    startCardPublish,
    getCardVersions,
    changeStep: actions.setStep,
    setStepIsOpen: actions.setStepIsOpen,
    reloadProfile,
    currentVersionIds,
    updateStepsState: actions.afterPublish,
    setShowPublishFlow: cardActions.setShowPublishFlow,
  }
)(PublishBtn);
