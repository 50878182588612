import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const CssGreenButton = withStyles({
  root: {
    background: '#76B83F',
    color: 'white',
    maxWidth: 250,
    width: '100%',
    borderRadius: 25,
    fontFamily: 'F37 Ginger',
    fontWeight: 700,
    padding: '13px 8px',
    boxShadow: '0 10px 20px 0 rgba(0,0,0,0.25)',
    lineHeight: '18px',
    border: '3px solid #e6e6e6',
    '&:hover': {
      background: '#81c944',
    },
  },
})(Button);

export default CssGreenButton;
