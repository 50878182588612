import React from 'react';
import { Typography } from '@material-ui/core';
import { Container } from '@material-ui/core';

export const NotFoundPage = () => {
  return (
    <Container>
      <Typography style={{ color: 'white' }} variant="h5">
        Page not found.
      </Typography>
    </Container>
  );
};
