import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

interface IMessageOnTimeout {
  children: JSX.Element;
};

interface IUseMessageOnTimeoutArgs {
  timeout: number;
  startTimeout?: boolean;
  stopTimeout?: boolean;
}

interface IUseMessageOnTimeout {
  MessageOnTimeout: React.FC<IMessageOnTimeout>;
  setShowMessage: (value: React.SetStateAction<boolean>) => void;
}

export function useMessageOnTimeout({ timeout, startTimeout, stopTimeout }: IUseMessageOnTimeoutArgs): IUseMessageOnTimeout {
  const [timeoutId, setTimeoutId] = useState<any>(null);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    console.log("[timeoutId, startTimeout, stopTimeout]: ", [timeoutId, startTimeout, stopTimeout]);

    if (stopTimeout && timeoutId) {
      clearTimeout(timeoutId);
      setShowMessage(false);
      setTimeoutId(null);
      return
    }

    if (startTimeout && !timeoutId) {
      const id = setTimeout(() => {
        setShowMessage(true);
      }, timeout);

      setTimeoutId(id);
    }
  }, [stopTimeout, startTimeout, setTimeoutId, setShowMessage, timeout, timeoutId]);

  const MessageOnTimeout: React.FC<IMessageOnTimeout> = ({ children }) => {
    return showMessage ? <div className={classes.children}>{ children }</div> : null;
  };

  return { MessageOnTimeout, setShowMessage };
};

const useStyles = makeStyles({
  children: {
    // animation: "fadeInAnimation ease 1s",
    // animationIterationCount: "1",
    // animationFillMode: "forwards",
  },
});

export default useMessageOnTimeout;
