import { useMemo } from 'react';
import { IAppRoute } from 'routes/routes';
import { useRouteMatch } from 'react-router';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { ROUTES } from 'routes';

interface IData {
  path: string;
  subtitle: string;
}

interface IDefaultRoute extends IData {
  subtitleColored: string;
  isProtected: boolean;
}

interface IMenuItem extends IData {
  isMatch: boolean;
  title: string;
  icon?: string;
  target?: string;
}

type MenuItems = IMenuItem[];

interface IMenuData {
  menuItems: MenuItems;
  activeRoute: IAppRoute | IDefaultRoute;
}

// returns menu items and active route
export function useMenuData(): IMenuData {
  const profile = useTypedSelector((state) => state.auth.profile);
  const match = useRouteMatch();

  return useMemo<IMenuData>(() => {
    const defaultActiveRoute: IDefaultRoute = { path: '', isProtected: false, subtitle: '', subtitleColored: '' };
    if (match) {
      const { isExact, path: matchPath } = match;
      const route = isExact && ROUTES.find((item) => matchPath === item.path);
      const isProtected = !!route && !!route.isProtected;

      const menuItemIsVisible = (item: IAppRoute): boolean => {
        if (!!item.title && !!item.isProtected === isProtected) {
          if (typeof item.profileFilter === 'function') {
            return item.profileFilter(profile);
          }
          return true;
        }
        return false;
      };
      const items: MenuItems = ROUTES.filter(menuItemIsVisible).map(({ path, title = '', subtitle = '', icon, target }) => ({
        path,
        title,
        subtitle,
        icon,
        target,
        isMatch: !!matchPath.match(path),
      }));

      return {
        menuItems: items,
        activeRoute: route ? route : defaultActiveRoute,
      };
    }
    return {
      menuItems: [],
      activeRoute: defaultActiveRoute,
    };
  }, [match, profile]);
}
