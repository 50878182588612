import AuthorizationDialog from 'modules/auth/components/Authorization/AuthorizationDialog';
import ErrorMessages from 'modules/core/components/ErrorMessages';
import React, { useEffect, useMemo } from 'react';
import { useLocation, useHistory, Redirect } from 'react-router';
import { HOME_ROUTE, INVITE_CONFIRM_NOT_FOUND_ROUTE, RESELLER_SIGN_UP, SIGNIN_ROUTE } from 'routes/routes';
import { acceptResellerInvite } from 'modules/users/thunks/assigned-users';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { useThunkDispatch } from 'hooks/useThunkDispatch';

export const ResellerInviteValidator: React.FC = () => {
  const errors = useTypedSelector((state) => state.users.errors);
  const { profile, isAuthenticated, invitation } = useTypedSelector((state) => state.auth);
  const thunkDispatch = useThunkDispatch();
  const { search } = useLocation();
  const history = useHistory();

  const invitationId = useMemo(() => {
    const query = new URLSearchParams(search);
    return query.get('invitation_id');
  }, [search]);

  useEffect(() => {
    if (invitationId) {
      thunkDispatch(acceptResellerInvite(invitationId))
        .then((result) => {
          if (!result) {
            setTimeout(() => history.push(INVITE_CONFIRM_NOT_FOUND_ROUTE.path), 3000)
          }
        })
        .catch(console.warn);
    } else {
      history.push(HOME_ROUTE.path);
    }
  }, [invitationId, history, thunkDispatch]);

  const continuePath = useMemo(() => {
    if (!invitation.id) {
      return "";
    }

    if (isAuthenticated) {
      if (!profile.id) {
        return "";
      }
    }

    if (invitation.id && invitation.status === "accepted") {
      return SIGNIN_ROUTE.path
    }

    const validNoAuth = !isAuthenticated && invitation.id;
    const validWithAuth = isAuthenticated && invitation.user.id === profile.user.id;

    if (validNoAuth || validWithAuth) {
      return RESELLER_SIGN_UP.path;
    }

    setTimeout(() => history.push(INVITE_CONFIRM_NOT_FOUND_ROUTE.path), 3000);
    return "";
  }, [isAuthenticated, profile, invitation, history]);

  return continuePath ? (
    <Redirect to={continuePath} />
  ) : (
    <AuthorizationDialog
      title="Access your account"
      subtitle="Checking authorization..."
      dlgBody={<ErrorMessages messages={errors} />}
    />
  );
};
