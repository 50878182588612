import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import banner from 'assets/images/banner.png';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imgs: {
      position: 'absolute',
      zIndex: -2,
      borderRadius: '100%',
      width: '100%',
      overflow: 'hidden',

      '&:after': {
        position: 'absolute',
        left: 0,
        width: '100%',
        height: '100%',
        content: `" "`,
        boxShadow: 'inset 0px 0px 40px 60px #013D1F',
        borderRadius: '100%',
        background: 'radial-gradient(circle, rgba(1,61,31,0) 0%, #013D1F 100%)',
      },
      '& img': {
        zIndex: -3,
        width: '98%',
        objectFit: 'cover',
        borderRadius: '100%',
        opacity: 0.4,
      },
    },
    imgsTop: {
      top: -400,
      left: '50%',
      transform: 'translateX(-50%)',
      maxWidth: 1280,
      zIndex: -1,
      '&._topLarge': {
        top: '-18%',
        [theme.breakpoints.down('md')]: {
          top: '-10%',
        },
        [theme.breakpoints.down('sm')]: {
          top: '0',
        },
      },
      '&._topMd': {
        top: '-15%',
        [theme.breakpoints.down('md')]: {
          top: '-10%',
        },
        [theme.breakpoints.down('sm')]: {
          top: '0%',
        },
      },

      [theme.breakpoints.down('md')]: {
        top: 0,
        borderRadius: 0,

        '&:after': {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          content: `" "`,
          boxShadow: 'none',
          borderRadius: 0,
          background: 'linear-gradient(to top, #013D1F 0%, rgba(1,61,31,0) 100%)',
        },
        '& img': {
          zIndex: -3,
          width: '100%',
          objectFit: 'cover',
          borderRadius: 0,
          opacity: 0.8,
        },
      },
    },
    imgsRight: {
      position: 'absolute',
      top: '200%',
      right: '-50%',
      maxWidth: '75%',
      background: 'radial-gradient(circle, rgba(1,61,31,0) 0%, #013D1F 77%)',

      '& img': {
        opacity: 0.8,
      },

      '&:after': {
        background: 'radial-gradient(circle, rgba(1,61,31,0) 0%, #013D1F 77%)',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    imgsLeft: {
      top: '400px',
      left: '-40%',
      maxWidth: '75%',
      '&:after': {
        background: 'radial-gradient(circle, rgba(1,61,31,0) 0%, #013D1F 77%)',
      },
      '& img': {
        opacity: 0.8,
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    root: {
      zIndex: -10,

      [theme.breakpoints.down('md')]: {
        position: 'sticky',
        top: 0,
        // marginBottom: '100vh',
      },

      '&._not-found': {
        '&> :not(:first-child)': {
          display: 'none',
        },
      },
    },
  })
);

interface IImgsBackground {
  exClass?: string;
}

const ImgsBackground: React.FC<IImgsBackground> = ({ exClass }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, exClass)} id="imgBackgroundGreen">
      <div className={clsx(classes.imgs, classes.imgsTop, exClass)}>
        <img src={banner} alt="" />
      </div>
        <div className={clsx(classes.imgs, classes.imgsLeft, '_mdDownHide')}>
          <img src={banner} alt="" />
        </div>
        {/*<div className={clsx(classes.imgs, classes.imgsRight)}>
          <img src={banner} alt="" />
        </div>*/}
    </div>
  );
};

export default ImgsBackground;
