import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { limeade, brightGrey } from 'themes/colors';

export const clearStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: theme.typography.pxToRem(12),
      height: '18px',
      [theme.breakpoints.between('xs', 'sm')]: {
        color: limeade,
        margin: '1rem auto',
      },
    },
    confirmBtn: {
      color: limeade,
      display: 'flex',
      textTransform: 'uppercase',
      '&::after': {
        content: '""',
        display: 'block',
        background: brightGrey,
        width: '1px',
        height: '80%',
        margin: theme.spacing(0, 1),
      },
    },
    cancelBtn: {
      color: limeade,
      textTransform: 'uppercase',
    },
    uppercase: {
      textTransform: 'uppercase',
    },
    pointer: {
      cursor: 'pointer',
      transition: 'color .25s ease',
      '&:hover': {
        color: 'rgb(174, 228, 127)',
        '&:active': {
          color: 'rgb(96, 134, 63)',
        },
      },
    },
    icon: {
      fontSize: theme.typography.pxToRem(18),
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      paddingBottom: 2,
    },
  })
);
