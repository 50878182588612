import React from 'react';
import { makeStyles, Hidden } from '@material-ui/core';
import { CancelRounded, CheckCircleRounded } from '@material-ui/icons';
import CssGreenButton from 'modules/core/components/buttons/roundGreenButton';

interface IProps {
  onClose: () => void;
  onCrop: () => void;
}

/**
 * Apply and cancel buttons for crop overlay. Desktop and mobile versions.
 */
export const Controls: React.FC<IProps> = (props) => {
  const { onClose, onCrop } = props;
  const classes = useStyles();

  return (
    <div className={classes.actionsWrapper}>
      <Hidden mdUp>
        <button onClick={onClose} className={classes.transparentButton}>
          <CancelRounded color="primary" className={classes.cancelIcon} />
          <span>Cancel</span>
        </button>
        <span className={classes.mobileDivider} />
        <button onClick={onCrop} className={classes.transparentButton}>
          <span>Apply</span>
          <CheckCircleRounded color="primary" className={classes.applyIcon} />
        </button>
      </Hidden>
      <Hidden smDown>
        <button className={classes.cancelButton} onClick={onClose}>
          CANCEL
        </button>
        <CssGreenButton id="cropper-apply-button" className={classes.applyButton} onClick={onCrop}>
          APPLY
        </CssGreenButton>
      </Hidden>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1,
    '& button + button': {
      marginLeft: 50,
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      flex: 0,
    },
  },
  transparentButton: {
    backgroundColor: 'transparent',
    border: 0,
    outline: 0,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    '& span': {
      color: '#9c9c9c',
      textTransform: 'uppercase',
      fontSize: '13px',
    },
  },
  cancelIcon: {
    background: '#fff',
    fill: '#76B83F',
    borderRadius: '50%',
    marginRight: 7,
  },
  mobileDivider: {
    width: '1px',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    height: '30px',
  },
  applyIcon: {
    background: '#fff',
    fill: '#76B83F',
    borderRadius: '50%',
    marginLeft: 7,
  },
  cancelButton: {
    backgroundColor: 'transparent',
    color: '#fff',
    border: 0,
    fontWeight: 400,
    fontSize: '16px',
    textTransform: 'uppercase',
    letterSpacing: '0.9px',
    cursor: 'pointer',
    lineHeight: '30px',
    outline: 'none',
  },
  applyButton: {
    '&#cropper-apply-button': {
      maxWidth: '200px',
    },
  },
}));
