import React, { FC } from 'react';
import { Link as MuiLink } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

export interface ILinkProps {
  to: string;
  className?: string;
  noRoute?: boolean;
  target?: '_blank';
  downloadName?: string;
  onClick?: () => void;
  linkStyle?: object;
}

export const Link: FC<ILinkProps> = (props) => {
  const { to, children, className = '', target, noRoute, downloadName, onClick, linkStyle={ textDecoration: 'none' } } = props;

  function getProps() {
    if (downloadName) {
      return {
        href: to,
        download: downloadName,
        target: '_blank',
      };
    };

    if (noRoute) {
      if (target === '_blank') {
        return {
          href: to,
          target: '_blank',
        };
      };

      return {
        href: to,
      };
    };

    return {
      component: RouterLink,
      to,
    };
  }

  return (
    <MuiLink className={className} onClick={onClick} {...getProps()} style={linkStyle}>
      {children}
    </MuiLink>
  );
};
