import { Box, Typography, FormControlLabel } from '@material-ui/core';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import ClearIcon from '@material-ui/icons/Clear';
import { updateGallery } from 'modules/editor/api/card-mutations';
import { InputField, UploadImage, GreenCheckbox } from 'modules/core/components/fields';
import { OverlayProgress } from 'modules/core/components/progress/OverlayProgress';
import { IFileReadResult } from 'modules/core/utils';
import { IGallerySection, ICardVersion } from 'modules/editor/graphql/gql';
import { GalleryAtributes, GalleryImageAtributes } from 'modules/editor/models/atributes/gallery-atributes';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { actions } from 'modules/editor/reducers/cardReducer';
import Clear from 'modules/editor/components/CardSectionEditors/Clear';
import { isDisabled } from 'modules/editor/components/CardSectionEditors/hooks';
import { useStyles } from './useStyles';
import { useStyles as useEditorStyles } from '../styles';
import { AddingBlock } from '../AddingBlock';
import { Validation } from 'modules/core/types';
import { IReduxState } from 'store';

interface IGallery {
  card: ICardVersion;
  fields: string[];
  updateGallery: (galleryAtr: GalleryAtributes, withoutImg?: boolean) => Promise<IGallerySection | undefined>;
  filterImgItem: (index: number) => void;
}

export const Gallery = connect(
  (state: IReduxState) => ({
    card: state.currentVersion.currentVer,
    fields: state.editorSteps.brandingFields,
  }),
  {
    updateGallery,
    filterImgItem: () => actions.filterImgItem
  }
)(
  (props: IGallery) => {
    const { updateGallery: saveData, filterImgItem, card, fields } = props;

    const [gallery, setGallery] = useState<GalleryAtributes>(new GalleryAtributes(card));
    const [overlay, setOverlay] = useState(false);
    const [addErr, setAddErr] = useState(false);
    const [errors, setErrors] = useState<Validation<GalleryAtributes>>({});

    const classes = useStyles();
    const editorClasses = useEditorStyles();

    const imgs = useMemo(() => {
      return card.gallerySection.gallerySectionImages.map((gimg) => gimg.galleryImageUrl);
    }, [card.gallerySection]);

    useEffect(() => {
      if (gallery.cardVersionId !== card.id) {

        setGallery(gallery.nextState(new GalleryAtributes(card)));
      }
    }, [card, gallery, setGallery]);

    const addImage = useCallback(() => {
      if (gallery.gallerySectionImagesAttributes.length - imgs.length < 1) {
        gallery.gallerySectionImagesAttributes.push(new GalleryImageAtributes());
        setGallery(gallery.nextState(gallery));
      } else {
        setAddErr(true);
        setTimeout(() => setAddErr(false), 2000);
      }
    }, [setGallery, gallery, imgs]);

    const updateDescr = useCallback(
      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        gallery.title = e.target.value;
        setGallery(gallery.nextState(gallery));
      },
      [setGallery, gallery]
    );

    const updateShowSectionHeader = useCallback(() => {
      gallery.showSectionHeader = !gallery.showSectionHeader;
      setGallery(gallery.nextState(gallery));
      saveData(gallery);
    }, [setGallery, saveData, gallery]);

    const handleChangeImageDescription = useCallback(
      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, indx: number) => {
        gallery.gallerySectionImagesAttributes[indx].description = e.target.value;
        setGallery(gallery.nextState(gallery));
      },
      [setGallery, gallery]
    );

    const handleChangeImageLinkUrl = useCallback(
      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, indx: number) => {
        const prop = linkUrlPropWithId(gallery.gallerySectionImagesAttributes[indx]);
        gallery.gallerySectionImagesAttributes[indx].linkUrl = e.target.value;
        try {
          gallery.gallerySectionImagesAttributes[indx].validate('linkUrl');
          setErrors({ ...errors, [prop]: undefined });
        } catch (error) {
          setErrors({ ...errors, [prop]: error.message });
        }
        setGallery(gallery.nextState(gallery));
      },
      [setGallery, gallery, errors]
    );

    const linkUrlPropWithId = (galleryImage: GalleryImageAtributes) => {
      return 'LinkUrl_' + galleryImage._reactUID;
    };

    const uploadImgLogo = useCallback(
      async (res: IFileReadResult, indx: number) => {
        const gimg = gallery.gallerySectionImagesAttributes[indx];
        gimg.galleryImageName = res.filename;
        gimg.galleryImageBase64 = res.base64data;
        const newGallery = gallery.nextState(gallery);
        setGallery(newGallery);
        setOverlay(true);
        try {
          const updRes = await saveData(newGallery);
          if (updRes) {
            gimg.id = updRes.gallerySectionImages[indx].id;
          }
        } catch (error) {
          console.warn(error);
        } finally {
          gimg.galleryImageBase64 = undefined;
          gimg.galleryImageName = undefined;
          setGallery(gallery.nextState(gallery));
          setOverlay(false);
        }
      },
      [saveData, gallery]
    );

    const clearImg = useCallback(
      async (indx: number) => {
        filterImgItem(indx);
        const img = gallery.gallerySectionImagesAttributes[indx];
        img._destroy = true;
        const g = gallery.nextState(gallery);
        setGallery(g);
        await saveData(g);
        gallery.gallerySectionImagesAttributes = gallery.gallerySectionImagesAttributes.filter(
          (gimg) => gimg.id !== img.id
        );
        setGallery(gallery.nextState(gallery));
      },
      [setGallery, gallery, saveData, filterImgItem]
    );

    const clearSection = useCallback(async () => {
      setOverlay(true);
      setGallery(gallery.clear(false));
      await saveData(gallery.clear(true));
      setOverlay(false);
    }, [setGallery, saveData, gallery]);

    const saveGallerySectionImage = useCallback(
      async (index: number) => {
        if (gallery.gallerySectionImagesAttributes[index].id) {
          saveData(gallery, true);
        }
      },
      [gallery, saveData]
    );

    return (
      <>
        <Box display="flex" alignItems="center" mb={2}>
          <Typography className={classes.sectionHeader}>Gallery</Typography>
          {fields.length === 3 ? null : (
            <Box className={classes.clearBlock}>
              <Clear id="gallery-editor-clear" ico={<ClearIcon fontSize="inherit" />} onConfirm={() => clearSection()}>
                Clear section
              </Clear>
            </Box>
          )}
        </Box>
        <Box className={classes.fieldBlock}>
          <Typography className={classes.heading}>Title</Typography>
          <div className={classes.inpWrapper}>
            <InputField
              name="gallery-title"
              type="text"
              placeholder="Enter title"
              onChange={(e) => updateDescr(e)}
              value={gallery.title as string}
              variant="filled"
              onBlur={() => saveData(gallery)}
              disabled={isDisabled(fields, 'title')}
              InputProps={{
                startAdornment: (
                  <div className={classes.icon2}>
                    <div className={isDisabled(fields, 'title') ? classes.disabledIcon : undefined}>
                      <CameraAltIcon />
                    </div>
                  </div>
                ),
              }}
            />
          </div>
          <FormControlLabel
            style={{ marginTop: -32 }}
            className={editorClasses.checkbox}
            control={
              <GreenCheckbox
                name="gallery-showSectionHeader"
                checked={gallery.showSectionHeader}
                disabled={isDisabled(fields, 'showSectionHeader')}
                value={gallery.showSectionHeader}
                onChange={updateShowSectionHeader}
              />
            }
            label="Show section heading"
          />
        </Box>
        <Box mt={-4} className={classes.fieldBlock}>
          {gallery.gallerySectionImagesAttributes.map((el, indx) => (
            <div key={`uid-${el._reactUID}`} id={`editor_gallery_item_${indx}`}>
              <UploadImage
                name={`gallery-galleryImage-upload-${indx}`}
                imgUrl={imgs[indx]}
                size="large"
                alowClear={true}
                disabled={isDisabled(fields, 'galleryImageName')}
                onClear={() => clearImg(indx)}
                title={`image ${indx + 1 < 10 ? `0${indx + 1}` : `${indx + 1}`}`}
                secondDescription="Minimum 1200 wide"
                uploadImg={(e) => uploadImgLogo(e, indx)}
              />
              <div className={classes.galleryImageInputWrapper}>
                <InputField
                  name={`gallery-description-${indx}`}
                  type="text"
                  label="Enter image description"
                  onChange={(e) => handleChangeImageDescription(e, indx)}
                  onBlur={() => saveGallerySectionImage(indx)}
                  value={el.description as string}
                  disabled={isDisabled(fields, 'description')}
                  variant="filled"
                />
              </div>
              <div className={classes.galleryImageInputWrapper}>
                <InputField
                  name={`gallery-link-url-${indx}`}
                  type="text"
                  label="Enter URL"
                  onChange={(e) => handleChangeImageLinkUrl(e, indx)}
                  onBlur={() => saveGallerySectionImage(indx)}
                  value={el.linkUrl as string}
                  disabled={isDisabled(fields, 'linkUrl')}
                  variant="filled"
                  error={!!errors[linkUrlPropWithId(el)]}
                />
              </div>
            </div>
          ))}
        </Box>
        {addErr && <Box color="#f44336">Please fill in the previous slot</Box>}
        {!isDisabled(fields, 'galleryImageName') && <AddingBlock action={addImage} id="gallery" name="image" />}
        <OverlayProgress isProgress={overlay} />
      </>
    );
  }
);
