import Slider from '@material-ui/core/Slider';
import { sliderStyles } from './style';
import React, { useState, useCallback, useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { formatOpeningHour } from 'modules/core/utils/dateTime';

const CustomSlider = withStyles({
  root: {
    '& .MuiSlider-mark': {
      height: '7px',
      top: '11px',
    },
    '& .MuiSlider-track': {
      color: '#76b83e',
    },
    '& .MuiSlider-markActive': {
      backgroundColor: '#76b83e',
    },
    '& .MuiSlider-thumb': {
      color: '#76b83e',
    },
    '& .MuiSlider-markLabel': {
      top: 28,
      fontSize: 10,
    },
  },
})(Slider);

type SliderValue = [number, number];

interface ISliderProps {
  id?: string;
  values?: SliderValue;
  disabled?: boolean;
  onChange?: (val: SliderValue) => void;
  display12h?: boolean;
}

const RangeSlider: React.FC<ISliderProps> = ({ disabled, onChange, values, id, display12h }) => {
  const [start, end] = values || [];
  const classes = sliderStyles();

  const [discreteValues, setDiscreteValues] = useState<SliderValue>([(start || 0) * 2, (end || 24) * 2]);
  const [prettyValues, setPrettyValues] = useState<SliderValue>([start || 0, end || 24]);

  const sliderValToTimeVal = (sliderVal: SliderValue) => sliderVal.map(x => x / 2) as SliderValue;
  const setTimeValue = (val: SliderValue) => {
    setDiscreteValues(val);
    setPrettyValues(sliderValToTimeVal(val));
  };

  const marks: any = useMemo(
    () => {
      const markers = [...Array(25).keys()];
      const generateLabel = (x: number) => (x === 0 || x === 24) ? "00:00" : undefined;

      return markers.map((x) => {
        return {
          value: x * 2,
          label: generateLabel(x),
        };
      });
    },
  []);

  const commitChange = useCallback(() => {
    if (onChange) {
      onChange(prettyValues);
    }
  }, [onChange, prettyValues]);

  const componentId = useMemo(() => Date.now().toString(), []);

  const formattedCurrentValue = useMemo(() => {
    const value0 = formatOpeningHour(prettyValues[0], { display12h });
    const value1 = formatOpeningHour(prettyValues[1], { display12h });

    return `${value0.join("")}-${value1.join("")}`;
  }, [prettyValues, display12h]);

  return (
    <div className={classes.root} id={`container-${id || componentId}`}>
      <span className={classes.setTime} id={`values-${id}`}>
        {formattedCurrentValue}
      </span>
      <CustomSlider
        id={id || componentId}
        marks={marks}
        min={0}
        max={48}
        color="primary"
        disabled={disabled}
        value={discreteValues}
        onChange={(_, val) => setTimeValue(val as SliderValue)}
        onChangeCommitted={() => commitChange()}
        aria-labelledby="range-slider"
      />
    </div>
  );
};

export default RangeSlider;
