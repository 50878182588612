import { useMemo } from 'react';
import { findCurrency } from 'currency-formatter';

export const useCurrencySymbol = (ccy?: string) => {
  return useMemo(() => {
    if (typeof ccy !== 'string') return;

    return ccy.toUpperCase() === 'USD' ? 'US$' : findCurrency(ccy.toUpperCase()).symbol;
  }, [ccy])
};

export const useFormattedAmount = (ccy?: string, cents?: number) => {
  return useMemo(() => {
    if (ccy && cents) {
      let negativeSymbol = "";
      let amount = cents;
      const currencySymbol = ccy.toUpperCase() === 'USD' ? 'US$' : findCurrency(ccy.toUpperCase()).symbol;

      if (amount < 0) {
        negativeSymbol = '-';
        amount = amount * -1;
      }

      if (currencySymbol) {
        amount = amount / 100;
        const [n, d] = amount ? amount.toFixed(2).split('.') : ["0", "00"];
        return `${negativeSymbol}${currencySymbol}${n}.${d}`;
      }
    }

    return '-';
  }, [ccy, cents])
};
