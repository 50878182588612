import { URL_REGEXP } from 'modules/core/constants';
import { IModel } from 'modules/core/interfaces/model';
import { clearObject } from 'modules/core/utils';
import { validatePhone } from 'modules/core/utils/validate';
import { INCORECT_PHONE, URL_INVALID } from 'modules/editor/constants/messages';
import { ICardVersion } from 'modules/editor/graphql/gql';
import { ISocialLinksAttributes } from 'modules/editor/graphql/mutations';
import { object, ObjectSchema, string } from 'yup';

const yupParams = { message: URL_INVALID, excludeEmptyString: true };

export class SocialLinksAtributes implements IModel<ISocialLinksAttributes> {
  public cardVersionId!: number;
  public facebookUrl?: string = '';
  public facebookUrlShow?: boolean = false;
  public instagramUrl?: string = '';
  public instagramUrlShow?: boolean = false;
  public linkedInUrl?: string = '';
  public linkedInUrlShow?: boolean = false;
  public snapchatUrl?: string = '';
  public snapchatUrlShow?: boolean = false;
  public twitterUrl?: string = '';
  public twitterUrlShow?: boolean = false;
  public whatsappUrl?: string = '';
  public whatsappUrlShow?: boolean = false;
  public youtubeUrl?: string = '';
  public youtubeUrlShow?: boolean = false;
  public blogUrl?: string = '';
  public blogUrlShow?: boolean = false;
  public podcastUrl?: string = '';
  public podcastUrlShow?: boolean = false;
  public pinterestUrl?: string = '';
  public pinterestUrlShow?: boolean = false;
  [props: string]: any;

  private schema: ObjectSchema<Partial<ISocialLinksAttributes>> = object().shape({
    facebookUrl: string().matches(URL_REGEXP, yupParams),
    linkedInUrl: string().matches(URL_REGEXP, yupParams),
    twitterUrl: string().matches(URL_REGEXP, yupParams),
    youtubeUrl: string().matches(URL_REGEXP, yupParams),
    instagramUrl: string().matches(URL_REGEXP, yupParams),
    snapchatUrl: string().matches(URL_REGEXP, yupParams),
    blogUrl: string().matches(URL_REGEXP, yupParams),
    podcastUrl: string().matches(URL_REGEXP, yupParams),
    pinterestUrl: string().matches(URL_REGEXP, yupParams),
    whatsappUrl: string().test('Whats App phone', INCORECT_PHONE, (phone: string) =>
      phone ? validatePhone(phone) : true
    ),
  });

  constructor(card?: ICardVersion) {
    if (card) {
      this.cardVersionId = card.id;
      this.facebookUrl = card.facebookUrl || '';
      this.facebookUrlShow = card.facebookUrlShow;
      this.instagramUrl = card.instagramUrl || '';
      this.instagramUrlShow = card.instagramUrlShow;
      this.linkedInUrl = card.linkedInUrl || '';
      this.linkedInUrlShow = card.linkedInUrlShow;
      this.twitterUrl = card.twitterUrl || '';
      this.twitterUrlShow = card.twitterUrlShow;
      this.whatsappUrl = card.whatsappUrl || '';
      this.whatsappUrlShow = card.whatsappUrlShow;
      this.youtubeUrl = card.youtubeUrl || '';
      this.youtubeUrlShow = card.youtubeUrlShow;
      this.snapchatUrl = card.snapchatUrl || '';
      this.snapchatUrlShow = card.snapchatUrlShow;
      this.blogUrl = card.blogUrl || '';
      this.blogUrlShow = card.blogUrlShow;
      this.podcastUrl = card.podcastUrl || '';
      this.podcastUrlShow = card.podcastUrlShow;
      this.pinterestUrl = card.pinterestUrl || '';
      this.pinterestUrlShow = card.pinterestUrlShow;
    }
  }

  public asState(): ISocialLinksAttributes {
    const state: any = {};
    Object.keys(this).forEach((key) => (state[key] = this[key]));
    return state;
  }

  public nextState(state: SocialLinksAtributes) {
    const atr = new SocialLinksAtributes();
    Object.keys(atr).forEach((key) => (atr[key] = state[key]));
    return atr;
  }

  public clear(nullable: boolean) {
    const atr = new SocialLinksAtributes();
    clearObject(atr, nullable);
    atr.cardVersionId = this.cardVersionId;
    atr.schema = this.schema;
    return atr;
  }

  public toJSON(): ISocialLinksAttributes {
    return {
      cardVersionId: this.cardVersionId,
      facebookUrl: this.facebookUrl ? this.facebookUrl : null,
      facebookUrlShow: this.facebookUrlShow,
      linkedInUrl: this.linkedInUrl ? this.linkedInUrl : null,
      linkedInUrlShow: this.linkedInUrlShow,
      twitterUrl: this.twitterUrl ? this.twitterUrl : null,
      twitterUrlShow: this.twitterUrlShow,
      whatsappUrl: this.whatsappUrl === '+' ? null : this.whatsappUrl,
      whatsappUrlShow: this.whatsappUrlShow,
      youtubeUrl: this.youtubeUrl ? this.youtubeUrl : null,
      youtubeUrlShow: this.youtubeUrlShow,
      instagramUrl: this.instagramUrl ? this.instagramUrl : null,
      instagramUrlShow: this.instagramUrlShow,
      snapchatUrl: this.snapchatUrl ? this.snapchatUrl : null,
      snapchatUrlShow: this.snapchatUrlShow,
      podcastUrl: this.podcastUrl ? this.podcastUrl : null,
      podcastUrlShow: this.podcastUrlShow,
      pinterestUrl: this.pinterestUrl ? this.pinterestUrl : null,
      pinterestUrlShow: this.pinterestUrlShow,
      blogUrl: this.blogUrl ? this.blogUrl : null,
      blogUrlShow: this.blogUrlShow,
    };
  }

  public validate(path: string) {
    return this.schema.validateSyncAt(path, this.asState());
  }
}
