import { IGalleryAtributes, IGalleryImageAttributes } from 'modules/editor/graphql/mutations';
import { ICardVersion, IGalleryImage } from 'modules/editor/graphql/gql';
import { IModel } from 'modules/core/interfaces/model';
import { clearObject } from 'modules/core/utils';
import { v1 as uuid } from 'uuid';
import { ObjectSchema, string, object } from 'yup';
import { URL_INVALID } from 'modules/editor/constants/messages';
import { URL_REGEXP } from 'modules/core/constants';

export class GalleryAtributes implements IGalleryAtributes, IModel<IGalleryAtributes> {
  public cardVersionId!: number;
  public title?: string = '';
  public showSectionHeader?: boolean = true;
  public id?: number;
  public gallerySectionImagesAttributes: GalleryImageAtributes[] = [];
  [props: string]: any;

  constructor(card?: ICardVersion) {
    if (card) {
      this.cardVersionId = card.id;
      this.title = card.gallerySection.title || '';
      // ensure that default value for showSectionHeader is true. Necessary for when the section does not yet exist in db.
      this.showSectionHeader = card.gallerySection.showSectionHeader === false ? false : true;
      this.id = card.gallerySection.id;
      this.gallerySectionImagesAttributes = card.gallerySection.gallerySectionImages.map(
        (img) => new GalleryImageAtributes(img)
      );
      if (!this.gallerySectionImagesAttributes.length) {
        this.gallerySectionImagesAttributes.push(new GalleryImageAtributes());
      }
    }
  }

  public nextState(state: GalleryAtributes) {
    const atr = new GalleryAtributes();
    atr.cardVersionId = state.cardVersionId as number;
    atr.title = state.title as string;
    atr.showSectionHeader = state.showSectionHeader;
    atr.id = state.id as number;
    atr.gallerySectionImagesAttributes = state.gallerySectionImagesAttributes.map((gimg) => gimg.fromState(gimg));
    return atr;
  }

  public asState(): IGalleryAtributes {
    return {
      cardVersionId: this.cardVersionId,
      title: this.title,
      showSectionHeader: this.showSectionHeader,
      id: this.id,
      gallerySectionImagesAttributes: this.gallerySectionImagesAttributes.map((gimg) => gimg.asState()),
    };
  }

  public withoutImages() {
    return {
      cardVersionId: this.cardVersionId,
      title: this.title,
      showSectionHeader: this.showSectionHeader,
      id: this.id,
      gallerySectionImagesAttributes: this.gallerySectionImagesAttributes.map((gimg) => gimg.withoutImages()),
    };
  }

  public clear(nullable: boolean) {
    const atr = new GalleryAtributes();
    clearObject(atr, nullable);
    atr.id = this.id;
    atr.cardVersionId = this.cardVersionId;
    if (nullable) {
      this.gallerySectionImagesAttributes.forEach((gimg) => (gimg._destroy = true));
      atr.gallerySectionImagesAttributes = this.gallerySectionImagesAttributes;
    } else {
      atr.gallerySectionImagesAttributes = [];
    }
    return atr;
  }

  public toJSON(): IGalleryAtributes {
    return {
      cardVersionId: this.cardVersionId,
      id: this.id,
      title: this.title || null,
      showSectionHeader: this.showSectionHeader,
      gallerySectionImagesAttributes: this.gallerySectionImagesAttributes.map((i) => i.toJSON()),
    };
  }
}

export class GalleryImageAtributes implements IGalleryImageAttributes {
  // tslint:disable-next-line: variable-name
  public _reactUID = uuid();
  // tslint:disable-next-line: variable-name
  public _destroy?: boolean;
  public description?: string = '';
  public linkUrl?: string = '';
  public id?: number;
  public galleryImageBase64?: string;
  public galleryImageName?: string;

  private schema: ObjectSchema<Partial<IGalleryImageAttributes>> = object().shape({
    linkUrl: string().matches(URL_REGEXP, { excludeEmptyString: false, message: URL_INVALID }),
  });

  constructor(gallery?: IGalleryImage) {
    if (gallery) {
      this._reactUID = uuid();
      this.description = gallery.description || '';
      this.linkUrl = gallery.linkUrl || '';
      this.id = gallery.id;
    }
  }

  public asState(): IGalleryImageAttributes {
    return {
      _destroy: this._destroy,
      description: this.description,
      linkUrl: this.linkUrl,
      id: this.id,
      galleryImageBase64: this.galleryImageBase64,
      galleryImageName: this.galleryImageName,
    };
  }

  public fromState(state: GalleryImageAtributes) {
    const gimg = new GalleryImageAtributes();
    gimg._reactUID = state._reactUID;
    gimg._destroy = state._destroy;
    gimg.description = state.description;
    gimg.linkUrl = state.linkUrl;
    gimg.galleryImageBase64 = state.galleryImageBase64;
    gimg.galleryImageName = state.galleryImageName;
    gimg.id = state.id;
    return gimg;
  }

  public withoutImages() {
    return {
      _destroy: this._destroy,
      description: this.description,
      linkUrl: this.linkUrl,
      id: this.id,
    };
  }

  public toJSON(): IGalleryImageAttributes {
    return {
      _destroy: this._destroy,
      description: this.description || null,
      linkUrl: this.linkUrl || null,
      galleryImageBase64: this.galleryImageBase64,
      galleryImageName: this.galleryImageName,
      id: this.id,
    };
  }

  public validate(path: string) {
    return this.schema.validateSyncAt(path, this.asState());
  }
}
