import React, { useCallback, useEffect, useState, useMemo } from 'react';
import Cookies from 'js-cookie';
import { GdprDialog } from 'modules/editor/containers/Card/GdprDialog';
import { NotFound, INotFoundProps } from 'modules/core/components/NotFound';
import { getCardByUrl } from 'modules/editor/api/card-query';
import { CardView } from 'modules/editor/containers/Card/CardView';
import { ROUTES } from 'routes';
import { Button } from '@material-ui/core';
import { Link } from 'modules/core/components/Link';
import { useSearchParams } from 'modules/editor/containers/Card/hooks/useSearchParams';
import { useSigninProps } from 'modules/core/components/NotFound/hooks/useSignInProps';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { useThunkDispatch } from 'hooks/useThunkDispatch';
import { useIsMine } from 'modules/public/pages/CardPage/useIsMine';
import { SaveInvitse } from 'modules/public/pages/CardPage/SaveInvite';
import { useIsLoading } from 'modules/editor/containers/Card/CardView/useIsLoading';
import { E_ACCOUNT_TYPE } from 'modules/auth/constants/enums';
import { useHistory } from 'react-router';
import { CardHead } from 'modules/editor/components/CardHead';

export const CardPage: React.FC = () => {
  const history = useHistory();
  const cardVer = useTypedSelector((state) => state.cardVersionReducer.publicVersion);
  console.log("cardVer: ", cardVer);
  const isMine = useIsMine();
  const {
    currentForCardId,
    throughReseller,
    card: { account, gdprPopupEnabled },
  } = cardVer;
  const canShowPopups = account ? account.type !== E_ACCOUNT_TYPE.Reseller : false;
  const isReseller = account ? account.type === E_ACCOUNT_TYPE.Reseller : false;

  const params = useSearchParams();
  const signInProps = useSigninProps();
  const thunkDispatch = useThunkDispatch();
  const isLoading = useIsLoading(cardVer.id);
  const [notFoundError, setNotFoundError] = useState<string>('');
  const [gdprDialogOpen, setGdprDialogOpen] = useState<boolean>(false);

  // if user came from sharing link to his own card
  const isSameUserSharing = isMine && !!params.id;

  const getCard = useCallback((): void => {
    const { name } = params;
    const isAppRoute = ROUTES.findIndex((r) => r.path === name) > -1;
    if (!isAppRoute) {
      thunkDispatch(getCardByUrl(name))
        .then(() => setNotFoundError(''))
        .catch((error: Error) => setNotFoundError(error.message));
    }
  }, [params, thunkDispatch, setNotFoundError]);

  useEffect(getCard, [getCard]);

  useEffect(() => {
    if (params.id) {
      Cookies.set('shareCardId', params.id);
    }

    if (params.name) {
      Cookies.set('cardUrl', params.name);
    }

    if (throughReseller) {
      Cookies.remove('throughCampaign');
      Cookies.set('throughReseller', throughReseller);

      if (isReseller) {
        history.push('/');
      }
    }

  }, [throughReseller, params.id, isReseller, history, params.name]);

  const notFoundProps = useMemo<INotFoundProps>(() => {
    // props for wrong card
    if (notFoundError === 'Card not found') {
      return {
        heading: 'Sorry, this card isn’t available',
        subHeading: 'The link you followed may be broken, or the card may have been removed',
        action: <Link to="/"> Go to the Homepage</Link>,
      };
    }
    if (isSameUserSharing) {
      return signInProps;
    }
    // props for connection trouble
    return {
      heading: 'Oops, there was a loading error',
      subHeading: (
        <>
          You are likely seeing this page due to a slow or faulty internet connection.
          <br />
          Please check your internet connection before you refresh the page.
        </>
      ),
      action: <Button onClick={getCard}>Refresh</Button>,
    };
  }, [getCard, notFoundError, signInProps, isSameUserSharing]);

  if (notFoundError || isSameUserSharing) {
    return <NotFound {...notFoundProps} />;
  }
  return (
    <>
      <CardHead
        fullName={cardVer.fullName as string}
        icon512={cardVer.iconUrl512}
        card_url={cardVer.card.urlSlug as string}
        icon16={cardVer.iconUrl16}
        icon32={cardVer.iconUrl32}
      />

      <CardView cardVer={cardVer} isLoading={isLoading} />
      <GdprDialog
        cardId={currentForCardId}
        isLoading={isLoading}
        setOpen={setGdprDialogOpen}
        canShowForCard={canShowPopups}
        gdprPopupEnabled={!!cardVer.card.gdprPopupEnabled}
      />
      {!isMine && canShowPopups && <SaveInvitse cardVer={cardVer} gdprDialogOpen={gdprDialogOpen} />}
    </>
  );
};
