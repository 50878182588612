import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { paleMintGreen, white } from 'themes/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3, 0),
      color: white,
      textAlign: 'center',
    },
    items: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      margin: theme.spacing(2, 0, 5),

      '&._testimonials': {
        justifyContent: 'center',
        alignItems: 'center',
      },

      '&._flexColumn': {
        flexDirection: 'column',
      },

      '&._howItWorks': {
        position: 'relative,',
        justifyContent: 'start',
      },
    },
    item: {
      width: '100%',
      backgroundColor: paleMintGreen,
      borderRadius: 5,
      color: white,
      margin: theme.spacing(1, 1, 0),
      padding: theme.spacing(2, 2, 0),
      maxHeight: 'calc(100vh - 24px)',

      '&._howItWorks': {
        // maxHeight: 'calc(100vh)',
        height: 'calc(100vh - 48px)',
        maxHeight: 'unset',
        padding: theme.spacing(2),
        margin: theme.spacing(0),
        // opacity: 0.15,
        transition: '0.1s',
      },
    },
    itemEnterViewContainer: {
      position: 'sticky',
      top: 0,
      padding: theme.spacing(3, 0),
      height: 'calc(100vh)',
      opacity: 0,

      '&:first-child': {
        opacity: 1,
      },

      '&.entered': {
        opacity: 1,
      },

      '&.exited': {
        opacity: 0,
      },
    },
    title: {
      margin: theme.spacing(0, 0, 2),
      fontSize: "1.3rem",
      fontWeight: 'bold',
    },
    subtitle: {
      marginBottom: theme.spacing(1),
      fontSize: "1.1rem",
      fontFamily: 'F37 Ginger Light',
      fontWeight: 500,
    },
    imageWrapper: {
      padding: theme.spacing(3, 18, 0),
      // width: '100%',
      height: 'auto',
      width: 'auto',
      maxWidth: '100%',
      maxHeight: '100%',

      '&._howItWorks': {
        padding: theme.spacing(1, 18, 0),
      },
    },
    image: {
      height: 'auto',
      width: 'calc(100vh - 200px)',

      '&._howItWorks': {
        width: 'calc(100vh - 250px)',
        '@media (max-height: 650px)': {
          height: 'calc(100vh - 190px)',
          width: 'auto',
        }
      },
    },
    ctaContainer: {
      width: '100%',
    },
    titleHowItWorks: {
      marginBottom: theme.spacing(2),
      fontSize: "3rem",
      '@media (max-height: 650px)': {
        fontSize: 30,
      }
    },
    subtitleHowItWorks: {
      marginBottom: theme.spacing(6),
      '@media (max-height: 650px)': {
        fontSize: 20,
      }
    },

    [theme.breakpoints.down('md')]: {
    },

    [theme.breakpoints.down('sm')]: {
      imageWrapper: {
        padding: theme.spacing(3, 0),

        '&._howItWorks': {
          padding: theme.spacing(3, 0),
          height: 'calc(100% - 120px);',
          width: '100%',
        },
      },
      image: {
        height: 'auto',
        width: '100%',

        '&._howItWorks': {
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        },
      },
      items: {
        flexDirection: 'column',

        '&._testimonials': {
          margin: theme.spacing(6, 0, 0),
        },

        '&._howItWorks': {
          marginTop: theme.spacing(-2),
        },
      },
      item: {
        margin: theme.spacing(2, 0),

        '&._howItWorks': {
          // display: 'flex',
          // flexDirection: 'column',
          // justifyContent: 'space-between',
          height: 'calc(100vh - 16px)',
        },
      },
      itemEnterViewContainer: {
        padding: theme.spacing(1, 0),
      },
      titleHowItWorks: {
        fontSize: 30,
      },
      subtitleHowItWorks: {
        fontSize: 24,
        marginBottom: theme.spacing(2),
      },
    },
    [theme.breakpoints.down('xs')]: {
      imageWrapper: {
        '&._howItWorks': {
          height: 'calc(100% - 160px);',
        },
      },
    },
  })
);
