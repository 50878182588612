import { Box, FormControlLabel, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';
import { ColorPicker, GreenCheckbox, UploadImage } from 'modules/core/components/fields';
import { IFileReadResult } from 'modules/core/utils';
import { updateBranding } from 'modules/editor/api/card-mutations';
import { ICardVersion } from 'modules/editor/graphql/gql';
import { IBrandingSectionAtributes } from 'modules/editor/graphql/mutations';
import { BrandingAtributes } from 'modules/editor/models/atributes/branding-atributes';
import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { IReduxState } from 'store';
import { initialGray } from 'themes/colors';
import Clear from 'modules/editor/components/CardSectionEditors/Clear';
import { isDisabled } from 'modules/editor/components/CardSectionEditors/hooks';
import { OverlayProgress } from 'modules/core/components/progress/OverlayProgress';

interface IBrandingProps {
  card: ICardVersion;
  classes: any;
  fields: string[];
  updateBranding: (brandAtr: IBrandingSectionAtributes, sectionFields: string[]) => Promise<void>;
}

export const Branding = connect(
  (state: IReduxState) => ({
    card: state.currentVersion.currentVer,
    fields: state.editorSteps.brandingFields
  }),
  { updateBranding }
)((props: IBrandingProps) => {
  const { card, updateBranding: saveData, classes, fields } = props;
  const [overlay, setOverlay] = useState(false);

  const branding = useMemo(() => new BrandingAtributes(card), [card]);

  const colorPickBackground = useCallback(
    (field: string) => (color: string) => {
      const data: IBrandingSectionAtributes = {
        cardVersionId: branding.cardVersionId,
        cardStyleId: branding.cardStyleId,
        [field]: color || null,
      };
      saveData(data, [field]);
    },
    [saveData, branding]
  );

  const uploadImg = useCallback(
    (prefix: string) => async (res: IFileReadResult, cropParams?: any): Promise<void> => {
      const additionalParams = cropParams ? { ...cropParams } : {};
      const data: IBrandingSectionAtributes = {
        cardVersionId: branding.cardVersionId,
        cardStyleId: branding.cardStyleId,
        [`${prefix}Name`]: res.filename,
        [`${prefix}Base64`]: res.base64data,
        ...additionalParams,
      };
      if (prefix === 'backgroundImage') {
        data.backgroundImageShow = true;
      }
      setOverlay(true);
      try {
        if (prefix === 'icon') {
          await saveData(data, [`${prefix}Url16`, `${prefix}Url32`, `${prefix}Url512`]);
        } else {
          await saveData(
            data,
            prefix === 'backgroundImage' ? [`${prefix}Url`, 'backgroundImageShow'] : [`${prefix}Url`]
          );
        }
        branding[`${prefix}Name`] = undefined;
        branding[`${prefix}Base64`] = undefined;
      } catch (error) {
        console.warn(error);
      }
      setOverlay(false);
    },
    [branding, saveData]
  );

  const onInverse = useCallback(
    (field: string) => () => {
      const data: IBrandingSectionAtributes = {
        cardVersionId: branding.cardVersionId,
        cardStyleId: branding.cardStyleId,
        [field]: !branding[field],
      };
      saveData(data, [field]);
    },
    [branding, saveData]
  );

  const onClear = useCallback(() => {
    const data: IBrandingSectionAtributes = {
      ...branding.clear(true).toJSON(),
    };

    saveData(data, [
      'backgroundHexColour',
      'backgroundImageShow',
      'backgroundImageUrl',
      'cardId',
      'cardStyleId',
      'cardVersionId',
      'iconUrl16',
      'iconUrl32',
      'iconUrl512',
      'logoUrl',
      'overlayHexColour',
      'overlayShow',
    ]);
  }, [branding, saveData]);

  const clearImg = useCallback(
    async (prefix: string) => {
      const data: IBrandingSectionAtributes = {
        cardVersionId: branding.cardVersionId,
        cardStyleId: branding.cardStyleId,
        [`${prefix}Name`]: null,
        [`${prefix}Base64`]: null,
      };
      if (prefix === 'backgroundImage') {
        data.backgroundImageShow = false;
        data.overlayShow = false;
      }
      try {
        if (prefix === 'icon') {
          await saveData(data, [`${prefix}Url16`, `${prefix}Url32`, `${prefix}Url512`]);
        } else {
          await saveData(
            data,
            prefix === 'backgroundImage' ? [`${prefix}Url`, 'backgroundImageShow', 'overlayShow'] : [`${prefix}Url`]
          );
        }
        branding[`${prefix}Name`] = undefined;
        branding[`${prefix}Base64`] = undefined;
      } catch (error) {
        console.warn(error);
      }
    },
    [branding, saveData]
  );

  return (
    <>
      <Box display="flex" alignItems="center" className={classes.box}>
        <Typography className={classes.sectionHeader}>Branding</Typography>
        {fields.length === 7 ? null : (
          <Box className={classes.clearBlock}>
            <Clear id="branding-editor-clear" ico={<ClearIcon fontSize="inherit" />} onConfirm={onClear}>
              Clear section
            </Clear>
          </Box>
        )}
      </Box>
      <ColorPicker
        name="branding-backgroundHexColour"
        id="branding-backgroundHexColour"
        value={branding.backgroundHexColour as string}
        defaultColor={initialGray}
        disabled={isDisabled(fields, 'backgroundHexColour')}
        title="BACKGROUND"
        label="Background colour"
        onSelectColor={colorPickBackground('backgroundHexColour')}
      />
      <Box pt={7}>
        <FormControlLabel
          className={clsx(classes.checkbox, '_pt')}
          control={
            <GreenCheckbox
              name="branding-showBackground"
              disabled={isDisabled(fields, 'showBackground') || !card.cardStyle.backgroundImageUrl}
              checked={branding.backgroundImageShow}
              value="showBackground"
              onChange={onInverse('backgroundImageShow')}
            />
          }
          label="Show background image"
        />
        <Box mt={-3}>
          <UploadImage
            name="branding-backgroundImage-upload"
            imgUrl={card.cardStyle.backgroundImageUrl}
            disabled={isDisabled(fields, 'backgroundImage')}
            size="large"
            title={'Background Image'}
            hideTitle
            firstDescription="Used on loading screen and card."
            secondDescription="Minimum 1200px wide"
            uploadImg={uploadImg('backgroundImage')}
            alowClear={true}
            onClear={() => clearImg('backgroundImage')}
            accept="image/png, image/jpeg"
          />
        </Box>
      </Box>
      <Box mt={-2}>
        <FormControlLabel
          className={classes.checkbox}
          control={
            <GreenCheckbox
              name="branding-overlayShow"
              checked={branding.overlayShow}
              disabled={isDisabled(fields, 'overlayShow')}
              value="overlayShow"
              onChange={onInverse('overlayShow')}
            />
          }
          label="Show background overlay"
        />
        <ColorPicker
          id="branding-overlayHexColour"
          name="branding-overlayHexColour"
          value={branding.overlayHexColour as string}
          disabled={isDisabled(fields, 'overlayHexColour')}
          defaultColor={initialGray}
          title={''}
          label="Select colour"
          onSelectColor={colorPickBackground('overlayHexColour')}
        />
      </Box>
      <Box py={4}>
        <UploadImage
          name="branding-logo-upload"
          imgUrl={card.logoUrl}
          disabled={isDisabled(fields, 'logo')}
          size="large"
          title="Logo"
          firstDescription="Used on loading screen, messages and menu."
          secondDescription="Minimum 400px width"
          uploadImg={uploadImg('logo')}
          alowClear={true}
          onClear={() => clearImg('logo')}
          crop
          skipCropForGif
        />
      </Box>
      <UploadImage
        name="branding-icon-upload"
        disabled={isDisabled(fields, 'icon')}
        imgUrl={card.iconUrl512}
        size="small"
        title="Icon"
        firstDescription="Used on contact record, favicon and menu."
        secondDescription="Minimum 512px width & height"
        uploadImg={uploadImg('icon')}
        alowClear={true}
        onClear={() => clearImg('icon')}
        crop
      />
      <OverlayProgress isProgress={overlay} />
    </>
  );
});
