import { IconButton, MobileStepper } from '@material-ui/core';
import { ArrowBackIosRounded, ArrowForwardIosRounded } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useCallback } from 'react';

import { useStyles } from './styles';

interface IStepperProps {
  steps: number;
  activeStep: number;
  disabledBack?: boolean;
  disabledNext?: boolean;
  hiddenNext?: boolean;
  hiddenBack?: boolean;
  onChange: (step: number) => void;
  classes?: {
    stepper: string;
    stepIcon: string;
  };
}

export const Stepper: React.FC<IStepperProps> = ({
  steps,
  activeStep,
  classes,
  disabledBack,
  disabledNext,
  hiddenNext,
  hiddenBack,
  onChange,
}) => {
  const stepperClasses = useStyles();

  const clickDot = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.persist();
      const target: HTMLDListElement = e.target as any;
      if (new RegExp(/.-dot.?/).test(target.className)) {
        for (let i = 0; i < target.parentElement!.children.length; i++) {
          const element = target.parentElement!.children.item(i);
          if (element === e.target) {
            onChange(i);
          }
        }
      }
    },
    [onChange]
  );

  const clickNext = useCallback(() => {
    if (activeStep < steps - 1) {
      onChange(activeStep + 1);
    }
  }, [activeStep, steps, onChange]);

  const clickPrev = useCallback(() => {
    if (activeStep !== 0) {
      onChange(activeStep - 1);
    }
  }, [onChange, activeStep]);

  return (
    <MobileStepper
      variant="dots"
      steps={steps}
      activeStep={activeStep}
      className={clsx(classes ? classes.stepper : stepperClasses.defStepper)}
      position="static"
      onClick={clickDot}
      backButton={
        <IconButton
          disabled={disabledBack}
          onClick={clickPrev}
          className={clsx(
            classes ? classes.stepIcon : stepperClasses.stepIconDef,
            hiddenBack ? stepperClasses.hidden : undefined
          )}
        >
          <ArrowBackIosRounded />
        </IconButton>
      }
      nextButton={
        <IconButton
          disabled={disabledNext}
          onClick={clickNext}
          className={clsx(
            classes ? classes.stepIcon : stepperClasses.stepIconDef,
            hiddenNext ? stepperClasses.hidden : undefined
          )}
        >
          <ArrowForwardIosRounded />
        </IconButton>
      }
    />
  );
};
