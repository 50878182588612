import { Box } from '@material-ui/core';
import { Button } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useStyles } from './styles';
import clsx from 'clsx';
import { IconButton } from '@material-ui/core';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';

// https://material-ui.com/components/steppers/
// src/components/dialogs/HelpDialog.tsx check realisation here

interface IProps {
  step: number;
  steps: number;
  handleClick: (e: any) => void;
  isNextHidden?: boolean;
  isPrevHidden?: boolean;
}
/**
 * Stepper component
 * @param param0
 * @deprecated
 */
const SignupStepper: React.FC<IProps> = ({ step, steps, handleClick, isNextHidden, isPrevHidden }) => {
  const styles = useStyles();
  const defineBulletsAction = (idx: number, targetStep: number) => {
    if (idx < targetStep) {
      return 'prev';
    } else if (idx > targetStep) {
      return 'next';
    } else {
      return 'curr';
    }
  };

  const handleContinue = useCallback(
    (e) => {
      if (handleClick) {
        handleClick(e);
      }
    },
    [handleClick]
  );

  return (
    <Box className={styles.stepper} display="flex">
      <IconButton
        className={styles.nextBtn}
        disabled={isPrevHidden}
        onClick={handleContinue}
        data-step={step - 1}
        data-action="prev"
      >
        <ArrowBackIosRoundedIcon />
      </IconButton>
      <div className={styles.btns}>
        {Array(steps)
          .fill('')
          .map((_, idx) => (
            <div key={idx} className={clsx(styles.step, idx === step ? 'done' : '')}>
              <Button onClick={handleContinue} data-step={idx} data-action={defineBulletsAction(idx, step)}>
                {''}
              </Button>
            </div>
          ))}
      </div>
      <IconButton
        className={styles.nextBtn}
        disabled={isNextHidden}
        onClick={handleContinue}
        data-step={step + 1}
        data-action="next"
      >
        <ArrowForwardIosRoundedIcon />
      </IconButton>
    </Box>
  );
};

export default SignupStepper;
