import { IVideoSection } from 'modules/editor/graphql/gql';

export class VideoSection implements IVideoSection {
  public linkUrl?: string;
  public title?: string;
  public showSectionHeader?: boolean;

  constructor(section?: IVideoSection) {
    if (section) {
      this.linkUrl = section.linkUrl;
      this.title = section.title;
      this.showSectionHeader = section.showSectionHeader;
    }
  }
}
