import React, { useMemo, useState, useEffect, useReducer, ChangeEvent, forwardRef, useCallback } from 'react';
import CssGreenButton from 'modules/core/components/buttons/roundGreenButton';
import DialogWrapper, { IDialogRef } from 'modules/core/containers/DialogWrapper';
import { validateCardShare } from 'modules/core/utils/validate';
import { shareCard } from 'modules/editor/api/card-mutations';
import clsx from 'clsx';
import { useStyles, useShareCardStyles } from '../styles';
import SuccessContent from '../SuccessContent';
import { Notification } from 'modules/core/components/Notification';
import {
  contactDetailsReducer as reducer,
  initalContactDetailsState as initialState,
  ContactDetailsField as Field,
} from 'modules/core/reducers/contactDetailsReducer';
import Typography from '@material-ui/core/Typography';
import ShareCardByEmail from './ShareCardByEmail';
import ShareCardByMobile from './ShareCardByMobile';
import { Clipboard, useClipboard } from 'modules/core/components/CopyCardURL';
import { fullUrl } from 'modules/core/utils';

export type IFormType = "email" | "mobile";

interface IProps {
  cardId: number;
  cardUrl: string;
  formTypeOnOpen?: IFormType;
};

export const ShareCardDialog = forwardRef<IDialogRef, IProps>((props, ref) => {
  const { cardId, cardUrl, formTypeOnOpen } = props;

  const classes = useShareCardStyles();

  const { fullWidth, paper } = useStyles();
  const [shared, setShared] = useState(false);
  const isPhoneValid = 'empty';
  const [values, dispatch] = useReducer(reducer, initialState);
  const { name, email, phone } = values;
  const [isError, setIsError] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  // Clipboard

  const fullCardUrlWithProtocol = fullUrl(cardUrl);
  const { success: copySuccess, error: copyError, onErrorCopy, onSuccessCopy } = useClipboard();
  const [copyButtonText, setCopyButtonText] = useState<string>("Copy to clipboard");

  useEffect(() => {
    if (copySuccess) {
      setCopyButtonText("Copied!");
    } else if (copyError) {
      setCopyButtonText("Error!");
    } else {
      setCopyButtonText("Copy to clipboard");
    };
  }, [copySuccess, copyError, setCopyButtonText]);


  // Form switching
  const defaultFormType = formTypeOnOpen || "email";
  const [formType, setFormType] = useState<IFormType>(defaultFormType)

  const switchFormType = useCallback((type: IFormType) => {
    setFormType(type);
  }, [setFormType]);

  const form = useMemo(() => {
    const onChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: Field) => {
      const {
        target: { value },
      } = event;
      dispatch({ type, value });
    };

    switch (formType) {
      case "mobile":
        return <ShareCardByMobile cardUrl={cardUrl} />;
      default:
        return <ShareCardByEmail values={values} onChange={onChange} />;
    }
  }, [formType, cardUrl, values, dispatch]);


  // Form submission

  const onReset = () => dispatch({ type: 'reset' });

  useEffect(() => {
    if (shared) {
      setTimeout(() => {
        onReset();
        setShared(false);
      }, 2000);
    }
  }, [shared, setShared]);

  const toggleOpen = () => setOpen(!open);

  const handleEnter = () => {
    toggleOpen();
    setFormType(defaultFormType)
  }

  const handleClose = () => {
    onReset();
    setShared(false);
    toggleOpen();
  };

  const sharingEnabled = useMemo((): boolean => {
    return validateCardShare({ name, email, phone }, isPhoneValid);
  }, [name, email, phone, isPhoneValid]);

  const handleShare = useCallback(
    async () => {
      try {
        await shareCard({
          cardId,
          ...values,
          // phone should always blank now...
          phone: '',
        });
        setShared(true);
      } catch (error) {
        setIsError(true);
      }
    },
    [cardId, values, setShared, setIsError]
  );

  const action = useMemo(() => {
    switch (formType) {
      case "mobile":
        return (
          <Clipboard
            id="dashboard-clipboard_url"
            url={fullCardUrlWithProtocol}
            onError={onErrorCopy}
            onSuccess={onSuccessCopy}
            hideIcon
            className="_center"
          >
            <CssGreenButton>{ copyButtonText }</CssGreenButton>
          </Clipboard>
        );
      default:
        return !shared && sharingEnabled && (
          <CssGreenButton id="share_form_button" onClick={handleShare}>
            Share card
          </CssGreenButton>
        );
    };
  }, [formType, onErrorCopy, onSuccessCopy, fullCardUrlWithProtocol, handleShare, shared, sharingEnabled, copyButtonText])

  return (
    <DialogWrapper
      ref={ref}
      onEnter={handleEnter}
      onClose={handleClose}
      actions={action}
      dialogId="share"
      dialogClasses={{ paper: clsx(fullWidth, paper, "_shareCardDialog") }}
    >
      {shared ? (
        <SuccessContent id="share_success" message="Your card has been shared" />
      ) : (
        <>
          <Typography id={`share_dialog_title`} variant="h5" className={classes.title}>Share card</Typography>
          <div className={classes.formSwitcher}>
            <div
              className={clsx(classes.formSwitcherOption, formType === "email" && "_active")}
              onClick={() => switchFormType("email")}
            >
              <div className={classes.formSwitcherText}>By email</div>
            </div>
            <div className={classes.formSwitcherSeparator}></div>
            <div
              className={clsx(classes.formSwitcherOption, formType === "mobile" && "_active")}
              onClick={() => switchFormType("mobile")}
            >
              <div className={classes.formSwitcherText}>By mobile</div>
            </div>
          </div>

          { form }
        </>
      )}
      <Notification
        id="shareError"
        isOpen={isError}
        onClose={() => setIsError(false)}
        severity="error"
        text="We were not able to share your card due to an error. Please try again or refresh the page"
      />
    </DialogWrapper>
  );
});
