import { apoloClient } from 'modules/core/constants/client';
import gql from 'graphql-tag';
import { Dispatch } from 'redux';
import { IHubspotFormAttributes } from 'modules/core/graphql/mutation';

export function hubspotRequest(attributes: IHubspotFormAttributes) {
  return async (dispatch: Dispatch) => {
    try {
      // eslint-disable-next-line
      const { errors, data } = await apoloClient.mutate({
        variables: {
          ...attributes,
        },
        mutation: gql`
          mutation createHubspotRedirect(
            $kind: String!
            $source: String!
            $fullName: String!
            $email: String
            $phone: String
            $question: String
          ) {
            createHubspotRedirect(
              attributes: {
                kind: $kind
                source: $source
                fullName: $fullName
                email: $email
                phone: $phone
                question: $question
              }
            ) {
              errors
              status
            }
          }
        `,
      });
      if (errors && errors.length) {
        return { status: false, errors };
      }
      if (data && data.createHubspotRedirect.status === 'ok') {
        return { status: true, data: data.createHubspotRedirect };
      } else {
        return { status: false, data: data.createHubspotRedirect };
      }
    } catch (error) {
      console.warn(error);
    }
  };
}
