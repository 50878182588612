import { useDefaultManifest } from 'modules/core/utils';
import { getCurrentVersion, queryByText } from 'modules/editor/api/card-query';
import { CardView } from 'modules/editor/containers/Card/CardView';
import { E_PREVIEW_TYPE } from 'modules/editor/constants/enums';
import { ICardVersion } from 'modules/editor/graphql/gql';
import { actions } from 'modules/editor/reducers/cardReducer';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IReduxState } from 'store';
import resellerHomeMobile from 'assets/images/gp-mobile-landing-reseller.png';
import { mobilePreveiwStyles as useStyles } from './styles';
import { E_ACCOUNT_TYPE } from 'modules/auth/constants/enums';
import { useTypedSelector } from 'hooks/useTypedSelector';

interface IMobilePreview {
  card: ICardVersion;
  getCurrentVersion: () => Promise<void>;
  startLoad: () => void;
  finishLoad: () => void;
}

const MobilePreviewComponent: React.FC<IMobilePreview> = ({
  card,
  getCurrentVersion: getCurVer,
  startLoad,
  finishLoad,
}) => {
  const profile = useTypedSelector((state) => state.auth.profile);
  const isReseller = profile.type === E_ACCOUNT_TYPE.Reseller;

  const classes = useStyles();
  useDefaultManifest();
  useEffect(() => {
    if (!card.id) {
      startLoad();
      getCurVer().then(finishLoad);
    }
  }, [startLoad, getCurVer, finishLoad, card.id]);

  return (
    <div className={classes.wrapper}>
      {isReseller && <img src={resellerHomeMobile} alt="" style={{maxWidth: '100%', marginTop: 10}} />}
      {!isReseller && <CardView previewType={E_PREVIEW_TYPE.MOBILE_DEVICE} cardVer={card} isPreview={true} />}
    </div>
  );
};

export const MobilePreview = connect(
  (state: IReduxState) => ({
    card: state.currentVersion.currentVer,
  }),
  {
    getCurrentVersion,
    queryByText,
    startLoad: actions.setLoadStatusStart,
    finishLoad: actions.setLoadStatusFinish,
  }
)(MobilePreviewComponent);
