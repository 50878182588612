import { Theme } from '@material-ui/core/styles';
import { lightGreen } from 'themes/colors';

export const styles = (theme: Theme) => ({
  root: {
    flex: 1,
    width: '100%',
    '& .MuiFab-root': {
      width: 30,
      height: 30,
      minHeight: 30,
    },
  },
  spinner: {
    alignContent: 'center',
  },
  statusCell: {
    color: lightGreen,
  },
  subtitle: {
    [theme.breakpoints.between('xs', 'sm')]: {
      'text-align': 'center',
      'text-transform': 'uppercase',
      marginTop: theme.spacing(4),
    },
  },
});
