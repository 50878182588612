import { ID } from 'modules/core/types';
import { IProcessingStatus } from 'modules/editor/graphql/subscriptions';

export class CardProcessingStatus implements IProcessingStatus {
  public id!: ID;
  public isProcessing: boolean = false;
  public isFinished: boolean = false;
  public isError: boolean = false;
  public message = "";

  constructor(statusData?: IProcessingStatus) {
    if (statusData) {
      this.id = statusData.id;
      this.isProcessing = statusData.isProcessing;
      this.isFinished = statusData.isFinished;
      this.isError = statusData.isError;
      this.message = statusData.message || "";
    }
  }

  public toJSON(): IProcessingStatus {
    return {
      id: this.id,
      isProcessing: this.isProcessing,
      isFinished: this.isFinished,
      isError: this.isError,
      message: this.message,
    };
  }

  public isEqual(processingStatus: IProcessingStatus): boolean {
    return this.id === processingStatus.id &&
      this.isProcessing === processingStatus.isProcessing &&
      this.isFinished === processingStatus.isFinished &&
      this.isError === processingStatus.isError &&
      this.message === processingStatus.message;
  }
}
