import React, { ReactNode } from 'react';
import { Typography, Collapse, Link, IconButton } from '@material-ui/core';
import { useStyles } from 'modules/editor/containers/Card/GdprDialog/useStyles';
import { PRIVACY_ROUTE } from 'routes/routes';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';

interface IProps {
  actions: JSX.Element[];
  children?: ReactNode;
  enteringDetails?: boolean;
}

export const GdprOptInMessage: React.FC<IProps> = ({ actions, children, enteringDetails }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const enterDetailsText = enteringDetails ? "enter your details and " : "";

  return (
    <>
      <div className={classes.text}>
        <Typography variant="h6" className={classes.header}>
          I would like to use my digital business card to keep you up to date.
        </Typography>
        { actions.length === 1 ? (
          <Typography variant="body1">
            To confirm that you’re happy for me to do so, please click the button below.
          </Typography>
        ) : (
          <Typography variant="body1">
            To confirm that you’re happy for me to do so, please {enterDetailsText}select one of the buttons to choose your preferred contact method.
          </Typography>
        )}

        <div className={classes.gdprNoticeContainer} onClick={handleExpandClick}>
          <Typography variant="h6" className={classes.header}>
            Go Paperless – Privacy & GDPR Notice
          </Typography>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>

        <Collapse in={expanded} timeout={'auto'}>
          <Typography variant="body1">
            Protecting your personal information is paramount.
          </Typography>
          <Typography variant="body1">
            We work to strict GDPR guidelines to ensure that it is safe and protected at all times.
          </Typography>
          <Typography variant="body1">We will never share your data without permission. </Typography>
          <Typography variant="body1"> You can choose to be forgotten at anytime. </Typography>
          <Typography variant="body1">
            For more information, check out our privacy policy <Link href={PRIVACY_ROUTE.path}
              className={classes.privacyLink} target="_blank" rel="noopener noreferrer"> here</Link>.
          </Typography>
        </Collapse>

        {children}
      </div>
    </>
  );
};
