import { lazy } from 'react';
import { SigninAuth, SignupConfirm } from 'modules/auth/containers/AuthPage';
import { InviteConfirmation } from 'modules/auth/containers/AuthPage/inviteConfirmation';
import { SignUp } from 'modules/auth/containers/Signup';
import { AccountProfile } from 'modules/auth/models/account-profile';
import { BillingPage } from 'modules/billing/components/BillingPage';
import { BuyMorePage } from 'modules/billing/components/BuyMorePage';
import { BuyPage } from 'modules/billing/components/BuyPage';
import { InitialResellerPurchasePage } from 'modules/billing/components/InitialResellerPurchasePage';
import { E_PREVIEW_TYPE } from 'modules/editor/constants/enums';
import { CreatePreview, MobilePreview } from 'modules/editor/containers/Preview';
import { MobilePreviewSuccess } from 'modules/editor/containers/Preview/MobilePublishSuccess';
import { UsersPage } from 'modules/users/components/UsersPage';
import { InviteNotFound } from 'modules/auth/containers/AuthPage/inviteConfirmation/InviteNotFound';
import { ResellerInviteValidator } from 'modules/auth/containers/AuthPage/resellerInviteValidator';
import { ResellerZendeskSso } from 'modules/auth/containers/ResellerZendeskSso';
import { CardPage } from 'modules/public/pages/CardPage';

import MainPage from 'modules/public/pages/homepage'; // not lazy loading the whole page because otherwise only the footer would show up first.

const LoginPage = lazy(() => import('modules/auth/containers/Signin'));
const DashboardType = lazy(() => import('modules/dashboard/components/DashboardType'));
const Accordion = lazy(() => import('modules/editor/containers/Editors'));
const ResellerSignUp = lazy(() => import('modules/auth/containers/ResellerSignup'));
const ResellerResourceCentre = lazy(() => import('modules/dashboard/components/ResellerResourceCentre'));
const ResellerReferralManager = lazy(() => import('modules/dashboard/components/ResellerReferralManager'));
const ResellerStarterPack = lazy(() => import('modules/dashboard/components/ResellerStarterPack'));
const ResellerGenealogy = lazy(() => import('modules/dashboard/components/ResellerGenealogy'));
const ResellerCommissionHistory = lazy(() => import('modules/dashboard/components/ResellerCommissionHistory'));
const ResellerNetwork = lazy(() => import('modules/dashboard/components/ResellerNetwork'));
const BlogLoadingPage = lazy(() => import('modules/public/components/blogLoadingPage'));
const AboutPage = lazy(() => import('modules/public/pages/aboutV2'));
const HowItWorksPage = lazy(() => import('modules/public/pages/HowItWorksPage'));
const ImpactPage = lazy(() => import('modules/public/pages/impact'));
const BusinessPage = lazy(() => import('modules/public/pages/business'));
const OfferPage = lazy(() => import('modules/public/pages/offer'));
const LearningPage = lazy(() => import('modules/public/pages/learning-center'));
const PricingPage = lazy(() => import('modules/public/pages/pricing'));
const ResellerAgreementPage = lazy(() => import('modules/public/pages/reseller-agreement'));

export interface INavItem {
  path: string;
  title: string;
  subtitle?: string;
  subtitleColored?: string;
  icon?: any;
  resellerOnly?: boolean;
}

export interface IAppRoute extends Partial<INavItem> {
  path: string;
  component: any;
  exact?: boolean;
  isProtected?: boolean;
  isCustom?: boolean;
  target?: string;
  profileFilter?: (profile: AccountProfile) => boolean;
  authFailureRedirectPath?: string;
  customNavbar?: boolean;
  customBackground?: boolean;
}

export const FOOTER_ITEMS: INavItem[] = [
  { title: 'Terms of use', path: '/terms' },
  { title: 'Privacy policy', path: '/privacy' },
  { title: 'Contact us', path: '/contact' },
  { title: 'Reseller agreement', path: '/reseller-agreement', resellerOnly: true },
];

export const HOME_ROUTE: IAppRoute = {
  path: '/',
  exact: true,
  component: MainPage,
  title: 'Home',
  subtitleColored: 'go',
  subtitle: 'paperless',
  customNavbar: true,
  customBackground: true,
};

export const LANDING_ROUTE: IAppRoute = {
  path: '/offer',
  exact: true,
  component: OfferPage,
  title: '',
  subtitleColored: '1/3 off&nbsp;',
  subtitle: 'every card you buy',
};

export const ABOUT_ROUTE: IAppRoute = {
  path: '/about',
  exact: true,
  component: AboutPage,
  title: 'About Us',
  subtitleColored: '',
  subtitle: 'About Go Paperless',
};

export const HOW_IT_WORKS_ROUTE: IAppRoute = {
  path: '/how-it-works',
  exact: true,
  component: HowItWorksPage,
  title: 'How It Works',
  subtitleColored: '',
  subtitle: 'How It Works',
};

// NOTE: depreciated endpoint. should just redirect to existing page.
export const IMPACT_ROUTE: IAppRoute = {
  path: '/impact',
  exact: true,
  component: ImpactPage,
  title: '',
  subtitleColored: '',
  subtitle: 'Impact',
};

export const PRICING_ROUTE: IAppRoute = {
  path: '/pricing',
  exact: true,
  component: PricingPage,
  subtitleColored: '',
  title: 'Pricing',
  subtitle: 'Pricing',
};

export const LEARNING_ROUTE: IAppRoute = {
  path: '/learning',
  exact: true,
  component: LearningPage,
  subtitleColored: '',
  title: 'Learning centre',
  subtitle: 'Learning centre',
};

export const BUSINESS_ROUTE: IAppRoute = {
  path: '/business',
  exact: true,
  component: BusinessPage,
  subtitleColored: '',
  title: '',
  subtitle: 'Cards for 50 or more employees',
};

export const FAQ_ROUTE: IAppRoute = {
  path: '/faqs',
  exact: true,
  component: () => {
    window.location.href = 'https://support.gpdigitalcards.com/hc/en-gb/categories/360003723998-FAQs';
    return null;
  },
  title: 'FAQs',
  subtitleColored: '',
  subtitle: 'Frequently asked questions',
};

export const TERMS_ROUTE: IAppRoute = {
  path: '/terms',
  exact: true,
  component: () => {
    window.location.href = 'https://support.gpdigitalcards.com/hc/en-gb/sections/360006057098-Terms-of-use';
    return null;
  },
  title: '',
  subtitleColored: '',
  subtitle: 'Terms of use',
};

export const PRIVACY_ROUTE: IAppRoute = {
  path: '/privacy',
  exact: true,
  component: () => {
    window.location.href = 'https://support.gpdigitalcards.com/hc/en-gb/sections/360005959097-Privacy-policy';
    return null;
  },
  title: '',
  subtitleColored: '',
  subtitle: 'Privacy policy',
};

export const RESELLER_AGREEMENT_ROUTE: IAppRoute = {
  path: '/reseller-agreement',
  exact: true,
  component: ResellerAgreementPage,
  title: '',
  subtitleColored: '',
  subtitle: 'Reseller agreement',
};

export const CONTACT_ROUTE: IAppRoute = {
  path: '/contact',
  exact: true,
  component: () => {
    window.location.href = 'https://support.gpdigitalcards.com/hc/en-gb/requests/new';
    return null;
  },
  title: '',
  subtitleColored: '',
  subtitle: 'Contact Go Paperless',
};

export const EDITOR_ROUTE: IAppRoute = {
  path: '/editor',
  exact: true,
  component: Accordion,
  isProtected: true,
  subtitleColored: '',
  title: 'Editor',
};

export const BUY_ROUTE: IAppRoute = {
  path: '/buy',
  exact: true,
  component: BuyPage,
  title: '',
  subtitleColored: '',
  subtitle: 'Buy now',
};

export const BUY_MORE_ROUTE: IAppRoute = {
  path: '/buy-more',
  exact: true,
  component: BuyMorePage,
  title: '',
  subtitle: 'Buy more',
  subtitleColored: '',
};

export const DASHBOARD_ROUTE: IAppRoute = {
  path: '/dashboard',
  exact: true,
  component: DashboardType,
  isProtected: true,
  subtitleColored: '',
  isCustom: true,
  title: 'Dashboard',
  profileFilter: (profile: AccountProfile) => profile.canSeeDashboard(),
  authFailureRedirectPath: EDITOR_ROUTE.path,
};

export const PREVIEW_ROUTE: IAppRoute = {
  path: '/editor/preview',
  exact: true,
  component: MobilePreview,
  isProtected: true,
};

export const SUCCESS_PUBLISH: IAppRoute = {
  path: '/editor/success',
  exact: true,
  component: MobilePreviewSuccess,
  isProtected: true,
  isCustom: true,
};

export const PREVIEW_DESKTOP_DEVICE: IAppRoute = {
  path: '/editor/preview-desktop',
  exact: true,
  component: CreatePreview(1200, E_PREVIEW_TYPE.AS_DESKTOP, '_desktop'),
  isProtected: true,
  subtitle: 'Preview card',
  isCustom: true,
};

export const PREVIEW_TABLET_DEVICE: IAppRoute = {
  path: '/editor/preview-tablet',
  exact: true,
  component: CreatePreview(768, E_PREVIEW_TYPE.AS_TABLET, '_tablet'),
  isProtected: true,
  subtitle: 'Preview card',
  isCustom: true,
};

export const PREVIEW_MOBILE_DEVICE: IAppRoute = {
  path: '/editor/preview-mobile',
  exact: true,
  component: CreatePreview(375, E_PREVIEW_TYPE.AS_MOBILE, '_mobile'),
  isProtected: true,
  subtitle: 'Preview card',
  isCustom: true,
};

export const BILLING_ROUTE: IAppRoute = {
  path: '/billing',
  exact: true,
  component: BillingPage,
  isProtected: true,
  subtitleColored: '',
  isCustom: true,
  title: 'Billing',
  subtitle: 'Billing',
  profileFilter: (profile: AccountProfile) => profile.canSeeBilling(),
};

export const USERS_ROUTE: IAppRoute = {
  path: '/users',
  exact: true,
  isProtected: true,
  isCustom: true,
  title: 'Users',
  subtitleColored: '',
  component: UsersPage,
  profileFilter: (profile: AccountProfile) => profile.canSeeUsers(),
  authFailureRedirectPath: EDITOR_ROUTE.path,
};

export const SIGNIN_ROUTE: IAppRoute = {
  path: '/signin',
  exact: true,
  component: LoginPage,
  subtitle: 'Sign in',
};

export const SIGNIN_AUTH_ROUTE: IAppRoute = {
  path: '/signin/auth',
  exact: true,
  component: SigninAuth,
  subtitle: 'Authorization',
};

export const SIGNUP_CONFIRM_ROUTE: IAppRoute = {
  path: '/signup/confirm',
  exact: true,
  component: SignupConfirm,
  subtitle: 'Authorization',
};

export const INVITE_CONFIRM_ROUTE: IAppRoute = {
  path: '/invite_user',
  exact: true,
  subtitle: 'Invite confirmation',
  component: InviteConfirmation,
};

export const INVITE_CONFIRM_NOT_FOUND_ROUTE: IAppRoute = {
  path: '/invite_user/not_found',
  subtitle: 'Invite confirmation',
  component: InviteNotFound,
  isCustom: true,
  isProtected: true,
};

export const RESELLER_INVITE_CONFIRM_ROUTE: IAppRoute = {
  path: '/invite_reseller',
  exact: true,
  subtitle: 'Invite confirmation',
  component: ResellerInviteValidator,
};

export const RESELLER_SIGN_UP: IAppRoute = {
  path: '/onboarding',
  exact: true,
  component: ResellerSignUp,
  title: '',
  subtitleColored: '',
  subtitle: 'Reseller sign up',
  isCustom: true
};

export const RESELLER_INITIAL_PURCHASE_ROUTE_PATH = '/purchase-starter-pack';
export const RESELLER_INITIAL_PURCHASE_ROUTE: IAppRoute = {
  path: '/purchase-starter-pack/:resellerPlan?',
  exact: true,
  component: InitialResellerPurchasePage,
  title: '',
  subtitle: 'Purchase reseller starter pack',
  subtitleColored: '',
};

export const RESELLER_RESOURCE_CENTRE_URL: IAppRoute = {
  path: '/reseller-resource-centre',
  isProtected: true,
  isCustom: true,
  exact: true,
  component: ResellerResourceCentre,
  title: 'Resource centre',
  subtitleColored: '',
  profileFilter: (profile: AccountProfile) => profile.canSeeResellerResourceCentre(),
};

export const RESELLER_REFERRAL_MANAGER: IAppRoute = {
  path: '/referral-manager',
  isProtected: true,
  exact: true,
  component: ResellerReferralManager,
  title: 'Referral manager',
  subtitleColored: '',
  isCustom: true,
  profileFilter: (profile: AccountProfile) => profile.canSeeResellerDashboardTools(),
  authFailureRedirectPath: EDITOR_ROUTE.path,
};

export const RESELLER_STARTER_PACK: IAppRoute = {
  path: '/starter-pack',
  isProtected: true,
  exact: true,
  component: ResellerStarterPack,
  title: 'Starter Pack',
  subtitleColored: '',
  isCustom: true,
  profileFilter: (profile: AccountProfile) => profile.canSeeResellerDashboardTools(),
};

export const RESELLER_ZENDESK_SSO: IAppRoute = {
  path: '/sso/reseller',
  exact: true,
  component: ResellerZendeskSso,
  subtitle: 'Single sign-on',
  subtitleColored: '',
};

export const RESELLER_GENEALOGY_ROUTE: IAppRoute = {
  path: '/genealogy',
  isProtected: true,
  exact: true,
  component: ResellerGenealogy,
  title: 'Genealogy',
  subtitleColored: '',
  isCustom: true,
  profileFilter: (profile: AccountProfile) => profile.canSeeResellerDashboardTools(),
  authFailureRedirectPath: EDITOR_ROUTE.path,
};

export const RESELLER_COMMISSION_HISTORY_ROUTE: IAppRoute = {
  path: '/commission-history',
  isProtected: true,
  exact: true,
  component: ResellerCommissionHistory,
  title: 'Commission history',
  subtitleColored: '',
  isCustom: true,
  profileFilter: (profile: AccountProfile) => profile.canSeeResellerDashboardTools(),
  authFailureRedirectPath: EDITOR_ROUTE.path,
};

export const RESELLER_NETWORK_ROUTE: IAppRoute = {
  path: '/network',
  isProtected: true,
  exact: true,
  component: ResellerNetwork,
  title: 'Network',
  subtitleColored: '',
  isCustom: true,
  profileFilter: (profile: AccountProfile) => profile.canSeeResellerDashboardTools(),
  authFailureRedirectPath: EDITOR_ROUTE.path,
};

export const SIGNUP_ROUTE: IAppRoute = { path: '/signup', exact: true, component: SignUp, subtitle: 'Sign up' };

export const BLOG_ROUTE: IAppRoute = {
  path: '/blog',
  exact: false,
  component: () => {
    window.location.href = window.location.origin + "/blog";
    return BlogLoadingPage;
  },
  title: 'Blog',
  subtitleColored: 'go',
  subtitle: 'paperless',
  isCustom: true,
};

export const CARD_ROUTE: IAppRoute = { path: '/:id', exact: true, component: CardPage, isCustom: true };
