import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { limeade } from 'themes/colors';
import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      width: 22,
      height: 22,
    },
    icon: {
      borderRadius: 5,
      width: 22,
      height: 22,
      border: `1px solid ${limeade}`,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: theme.palette.type === 'light' ? '#fff' : '#21262C',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: '#21262C',
        border: '1px solid #64666a',
      },
    },
    checkedIcon: {
      backgroundColor: limeade,
      border: 'none',
      '&:before': {
        display: 'block',
        width: 22,
        height: 22,
        backgroundImage:
          "url(\"data:image/svg+xml,%3Csvg width='22px' height='22px' viewBox='0 0 22 22' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M8.8,15.9049909 L5.3,12.4049909 C5.11514633,12.2178428 4.86304993,12.1125091 4.6,12.1125091 C4.33695007,12.1125091 4.08485367,12.2178428 3.9,12.4049909 C3.51,12.7949909 3.51,13.4149909 3.9,13.8049909 L8.09,17.9949909 C8.48,18.3849909 9.11,18.3849909 9.5,17.9949909 L20.1,7.40499087 C20.49,7.01499087 20.49,6.39499087 20.1,6.00499087 C19.9151463,5.81784283 19.6630499,5.71250913 19.4,5.71250913 C19.1369501,5.71250913 18.8848537,5.81784283 18.7,6.00499087 L8.8,15.9049909 Z' id='path-1'%3E%3C/path%3E%3C/defs%3E%3Cg id='icon/action/done_22px' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cg fill-rule='nonzero'%3E%3C/g%3E%3Cg id='✱-/-Color-/-Icons-/-Black-/-Inactive' mask='url(%23mask-2)' fill='%23ffffff'%3E%3Crect id='Rectangle' x='0' y='0' width='22' height='22'%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")",
        backgroundPosition: '-1px -1px',
        backgroundRepeat: 'no-repeat',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: limeade,
      },
    },
  })
);

export const GreenCheckbox = withStyles({
  root: {
    // color: limeade,
    // '&$checked': {
    //   color: limeade,
    //   background: '#fffff',
    // },
  },
  checked: {},
})((props: CheckboxProps) => {
  const classes = useStyles();
  return (
    <Checkbox
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      color="default"
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  );
});
