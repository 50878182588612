import { useHistory } from 'react-router';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { useEffect } from 'react';

// if user is authenticated then redirect to editor
export function useRedirectToEditor(): void {
  const isAuth = useTypedSelector((state) => state.auth.isAuthenticated);
  const isPending = useTypedSelector((state) => state.auth.pending);
  const history = useHistory();

  useEffect(() => {
    if (isAuth && !isPending) {
      history.push('/editor');
    }
  }, [isAuth, history, isPending]);
};
