/* eslint-disable no-multi-str */
import uuid from 'uuid';
import { CDN_URL } from 'modules/core/constants/config';

const video1 = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/What you need to build your card.jpg`;
const video2 = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/How to create your Go Paperless account.jpg`;
const video4 = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/How to optimise your card.jpg`;
const video5 = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/How to share your card.jpg`;
const video6 = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/How to enter your details.jpg`;
const video7 = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/How to enter social links.jpg`;
const video8 = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/How to brand you card.jpg`;
const video9 = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/How to style your card.jpg`;
const video10 = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/How to showcase your website.jpg`;
const video11 = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/How to use the card for promotion.jpg`;
const video12 = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/How to keep your network informed.jpg`;
const video13 = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/How to add a video.jpg`;
const video14 = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/How to create a gallery.jpg`;
const video15 = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/How to add your location and trading hours.jpg`;
const video16 = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/One digital card Infinite opportunities.jpg`;
const video17 = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/Make an impact. Make a greener planet..jpg`;
const video18 = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/Create. Share. Track. Capture. Grow..jpg`;
const video19 = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/Revolutionise Your Marketing.jpg`;
const video20 = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/Strengthen Customer Connections.jpg`;
const video21 = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/Supercharge Your Retail Brand.jpg`;
const video22 = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/Promote Products Effectively.jpg`;
const welcomeToTheEditor = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/Welcome to the editor.jpg`;
const replicating = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/Replicating your brand on your card.jpg`;
const exploreDashboard = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/Explore the Dashboard.jpg`;
const monitorTeam = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/How to manage and monitor your team.jpg`;
const qrCode = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/How to add cards to your account and switch between them.jpg`;
const accountSwitcher = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/How to use your gopaperless QR code.jpg`;
const saveCardMobile = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/How to save your card to your mobile.jpg`;
const saveCardDesktop = `${CDN_URL}/assets/images/LRC_VideoHoldingImages/How to save your card to your desktop or laptop.jpg`;

export const nutshellVideoUrl = "https://youtu.be/zSnpcV8OKg4";
export const nutshellVideoUrlV2 = "https://youtu.be/NE9GuDqMR8U";
export const embeddedNutshellVideoUrl = "https://www.youtube.com/embed/zSnpcV8OKg4?autoplay=1&loop=1&playlist=zSnpcV8OKg4";
export const embeddedNutshellVideoUrlV2 = "https://www.youtube.com/embed/NE9GuDqMR8U?autoplay=1&loop=1&playlist=NE9GuDqMR8U";
export const embeddedResellerNutshellVideoUrl = "https://www.youtube.com/embed/GGgSDn0TAKU?autoplay=1&loop=1&playlist=GGgSDn0TAKU";
export const editorVideoUrl = "https://youtu.be/bvXLTQok1VM";
export const replicatingBrandVideoUrl =  'https://youtu.be/k1VA7oT8A78';
export const embeddedReplicatingBrandVideoUrl =  'https://www.youtube.com/embed/k1VA7oT8A78?autoplay=1&loop=1&playlist=k1VA7oT8A78';

export enum E_LEARNING_SORT_TYPE {
  DEFAULT,
  ABC,
}

export const SORT = [
  {
    label: 'Default',
    value: E_LEARNING_SORT_TYPE.DEFAULT,
  },
  {
    label: 'A-Z',
    value: E_LEARNING_SORT_TYPE.ABC,
  },
];

export interface ILearningVideo {
  title: string;
  description: string;
  time: string;
  src: string;
  img: any;
  key: string;
}

export interface ICategory {
  title: string;
  videos: ILearningVideo[];
};

export const ONE_DIGITAL_CARD_INFINITE_OPPORTUNITIES: ILearningVideo = {
  title: 'One digital card. Infinite opportunities.',
  description: 'Discover how our digital card helps you boost sales, increase brand awareness or land your dream job.',
  time: '56 sec',
  src: 'https://youtu.be/F8XpJ0UnJlg',
  img: video16,
  key: uuid(),
};

export const MAKE_AN_IMPACT_MAKE_A_GREENER_PLANET: ILearningVideo = {
  title: 'Make an impact. Make a greener planet.',
  description: 'Welcome to Go Paperless, where one digital card helps you increase sales, boost awareness or land your dream job. Find out more.',
  time: '1 min 2 sec',
  src: nutshellVideoUrlV2,
  img: video17,
  key: uuid(),
};

export const CREATE_SHARE_TRACK_CAPTURE_GROW: ILearningVideo = {
  title: 'Create. Share. Track. Capture. Grow.',
  description: 'Welcome to Go Paperless, where one digital card helps you increase sales, boost awareness or land your dream job. Find out more.',
  time: '1 min 14 sec',
  src: 'https://youtu.be/xUhv_t27uYc',
  img: video18,
  key: uuid(),
};

export const L_REPLICATING_YOUR_BRAND: ILearningVideo = {
  title: 'Replicating your brand on your card',
  src: replicatingBrandVideoUrl,
  description: 'Learn how to replicate your logo and brand guidelines on your GP card in this short tutorial.',
  img: replicating,
  time: '1 min 23 sec',
  key: uuid(),
};

export const CATEGORY_OVERVIEW: ICategory = {
  title: 'Overview',
  videos: [
    ONE_DIGITAL_CARD_INFINITE_OPPORTUNITIES,
    MAKE_AN_IMPACT_MAKE_A_GREENER_PLANET,
    CREATE_SHARE_TRACK_CAPTURE_GROW,
    L_REPLICATING_YOUR_BRAND,
  ]
};

export const L_CRETE_ACCOUNT_ITEM: ILearningVideo = {
  title: 'How to create your Go Paperless account',
  description: 'It’s quick and easy to create your Go Paperless account – this short tutorial shows you how.',
  time: '1 min 49 sec',
  src: 'https://youtu.be/aHPz2m43VOM',
  img: video2,
  key: uuid(),
};

export const L_WELCOME_TO_THE_EDITOR: ILearningVideo = {
  title: 'Welcome to the editor',
  description: 'Learn how to use the GP Editor to create a digital business card that wows contacts and boosts your business.',
  time: '2 min 8 sec',
  src: editorVideoUrl,
  img: welcomeToTheEditor,
  key: uuid(),
};

export const L_WHAT_YOUR_NEED_ITEM: ILearningVideo = {
  title: 'What you need to build your card',
  description: 'This short tutorial tells you exactly what you’ll need to build your digital card.',
  time: '1 min 59 sec',
  src: 'https://youtu.be/qCUTC-mIwk0',
  img: video1,
  key: uuid(),
};

export const CATEGORY_GETTING_STARTED: ICategory = {
  title: 'Getting Started',
  videos: [
    L_CRETE_ACCOUNT_ITEM,
    L_WELCOME_TO_THE_EDITOR,
    L_WHAT_YOUR_NEED_ITEM,
  ]
};

export const L_PERSONAL: ILearningVideo = {
  title: 'How to enter your details',
  src: 'https://youtu.be/7A1CTEPY7iM?',
  description: 'Learn how to upload your details – including your photograph – to your card.',
  img: video6,
  time: '1 min 45 sec',
  key: uuid(),
};

export const L_SOCIAL: ILearningVideo = {
  title: 'How to enter social links',
  src: 'https://youtu.be/3DH3kmx0Y4k',
  description: 'Find out how to drive traffic from your digital card to your social channels with the help of our tutorial.',
  img: video7,
  time: '1 min 11 sec',
  key: uuid(),
};

export const L_BRAND: ILearningVideo = {
  title: 'How to brand your card',
  src: 'https://youtu.be/VzsCjgc0P8k',
  description: 'Personalising your card with your branding is easy with the help of this short tutorial.',
  img: video8,
  time: '2 min 30 sec',
  key: uuid(),
};

export const L_STYLING: ILearningVideo = {
  title: 'How to style your card',
  src: 'https://youtu.be/KXenZa5Ytek',
  description: 'Watch this short tutorial which covers colours, fonts and your unique url – to learn how to style your card.',
  img: video9,
  time: '1 min 23 sec',
  key: uuid(),
};

export const L_YOUR_WEBSITE: ILearningVideo = {
  title: 'How to showcase your website',
  src: 'https://youtu.be/lJWPbWG8XJ8',
  description: 'Discover how to complete the About section of your digital card with this short tutorial.',
  img: video10,
  time: '1 min 26 sec',
  key: uuid(),
};

export const L_USE_CARD_FOR_PROMO: ILearningVideo = {
  title: 'How to use the card for promotion',
  src: 'https://youtu.be/ONLgEvxPqCA',
  description: 'Learn how to add your latest offers and promotions to your digital card with this short tutorial.',
  img: video11,
  time: '1 min 29 sec',
  key: uuid(),
};

export const L_NEWS: ILearningVideo = {
  title: 'How to keep your network informed',
  src: 'https://youtu.be/5k54_1wFoqw',
  description: 'Watch this short tutorial to learn how to keep your contacts up to date with all your latest news.',
  img: video12,
  time: '1 min 29 sec',
  key: uuid(),
};

export const L_VIDEO: ILearningVideo = {
  title: 'How to add a video',
  src: 'https://youtu.be/wxlGzKjnpgI',
  description: 'This short tutorial shows you how to add a video to your digital card – impressing contacts and showcasing your brand.',
  img: video13,
  time: '1 min 1 sec',
  key: uuid(),
};

export const L_GALLERY: ILearningVideo = {
  title: 'How to create a gallery',
  src: 'https://youtu.be/ADlw9A09sCY',
  description: 'Find out how to showcase products, services and examples of your work in this short tutorial.',
  img: video14,
  time: '1 min 26 sec',
  key: uuid(),
};

export const L_LOCATION: ILearningVideo = {
  title: 'How to add your location and trading hours',
  src: 'https://youtu.be/YBwzqoG1jNo',
  description: 'Add your location and business hours to your digital card with the help of this short tutorial.',
  img: video15,
  time: '1 min 29 sec',
  key: uuid(),
};

export const CATEGORY_CREATING_YOUR_CARD: ICategory = {
  title: 'Creating Your Card',
  videos: [
    L_PERSONAL,
    L_SOCIAL,
    L_BRAND,
    L_STYLING,
    L_YOUR_WEBSITE,
    L_USE_CARD_FOR_PROMO,
    L_NEWS,
    L_VIDEO,
    L_GALLERY,
    L_LOCATION,
  ]
};

export const L_SHARE_CARD: ILearningVideo = {
  title: 'How to share your card',
  src: 'https://youtu.be/__NfZGx1wrk',
  description: 'Learn how to share your digital card with contacts by text or email with the help of this short tutorial.',
  time: '1 min 44 sec',
  img: video5,
  key: uuid(),
};

export const L_SAVE_CARD_MOBILE: ILearningVideo = {
  title: 'How to save your card to your mobile',
  src: 'https://youtu.be/Nm62z7dUVY4',
  description: 'Learn how to save a digital card to the contacts of your iPhone or android.',
  time: '1 min 29 sec',
  img: saveCardMobile,
  key: uuid(),
};

export const L_SAVE_CARD_DESKTOP: ILearningVideo = {
  title: 'How to save your card to your desktop or laptop',
  description: 'Learn how to save a digital card to the contacts of your desktop or laptop.',
  time: '1 min 50 sec',
  src: 'https://youtu.be/dHfNX-qYzGw',
  img: saveCardDesktop,
  key: uuid(),
};

export const L_QR_CODE: ILearningVideo = {
  title: 'How to use your Go Paperless QR Code',
  src: 'https://youtu.be/Hh0IqKZdZ9E',
  description: 'Discover how to share your GP card using your QR code – via marketing material or direct from your mobile phone.',
  img: accountSwitcher,
  time: '1 min 40 sec',
  key: uuid(),
};

export const CATEGORY_SAVING_AND_SHARING_YOUR_CARD: ICategory = {
  title: 'Saving And Sharing Your Card',
  videos: [
    L_SHARE_CARD,
    L_SAVE_CARD_MOBILE,
    L_SAVE_CARD_DESKTOP,
    L_QR_CODE,
  ]
};

export const L_EXPLORE_THE_DASHBOARD: ILearningVideo = {
  title: 'Explore the dashboard',
  description: 'Discover how the GP Dashboard gives you real-time insights into your business.',
  time: '1 min 21 sec',
  src: 'https://youtu.be/Q3dOD44aIQc',
  img: exploreDashboard,
  key: uuid(),
};

export const REVOLUTIONISE_YOUR_MARKETING: ILearningVideo = {
  title: 'Revolutionise Your Marketing',
  description: 'It’s time to revolutionise your marketing. Watch this short video to learn how Go Paperless will help.',
  time: '59 sec',
  src: 'https://youtu.be/Vdb-BupRv9w',
  img: video19,
  key: uuid(),
};

export const STRENGTHEN_CUSTOMER_CONNECTIONS: ILearningVideo = {
  title: 'Strengthen Customer Connections',
  description: 'Strengthen customer connections and relationships with Go Paperless – this short video explains how.',
  time: '59 sec',
  src: 'https://youtu.be/xtK0e55ZzFg',
  img: video20,
  key: uuid(),
};

export const SUPERCHARGE_YOUR_RETAIL_BRAND: ILearningVideo = {
  title: 'Supercharge Your Retail Brand',
  description: 'Looking for strategies to supercharge your retail brand and differentiate your business from the competition? Check out this short video.',
  time: '59 sec',
  src: 'https://youtu.be/4oxvKzXy2vg',
  img: video21,
  key: uuid(),
};

export const PROMOTE_PRODUCTS_EFFECTIVELY: ILearningVideo = {
  title: 'Promote Products Effectively',
  description: 'Promote products effectively with Go Paperless digital cards. This short video shows you how.',
  time: '59 sec',
  src: 'https://youtu.be/d_Ytf0crRss',
  img: video22,
  key: uuid(),
};

export const L_OPTIMISE_CARD: ILearningVideo = {
  title: 'How to optimise your card',
  src: 'https://youtu.be/ZZCM-heSN5s',
  description: 'Watch this short tutorial to learn how to use your digital card to maximise engagement with your brand.',
  time: '2 min 14 sec',
  img: video4,
  key: uuid(),
};

export const L_MONITOR_YOUR_TEAM: ILearningVideo = {
  title: 'How to manage and monitor your team',
  description: 'Learn how to add a digital card to your account and switch seamlessly between cards.',
  time: '2 min 16 sec',
  src: 'https://youtu.be/KTo-tOcXQlQ',
  img: monitorTeam,
  key: uuid(),
};

export const L_ACCOUNT_SWITCHER: ILearningVideo = {
  title: 'How to add cards to your account and switch between them',
  src: 'https://youtu.be/f5X9UEFV-7E',
  description: 'Learn how to add a digital card to your account and switch seamlessly between cards.',
  img: qrCode,
  time: '1 min 26 sec',
  key: uuid(),
};

export const CATEGORY_GROWING_YOUR_BUSINESS: ICategory = {
  title: 'Growing Your Business',
  videos: [
    L_EXPLORE_THE_DASHBOARD,
    REVOLUTIONISE_YOUR_MARKETING,
    STRENGTHEN_CUSTOMER_CONNECTIONS,
    SUPERCHARGE_YOUR_RETAIL_BRAND,
    PROMOTE_PRODUCTS_EFFECTIVELY,
    L_OPTIMISE_CARD,
    L_MONITOR_YOUR_TEAM,
    L_ACCOUNT_SWITCHER,
  ]
};

export const CATEGORY_ALL: ICategory = {
  title: 'All',
  videos: [
    ...CATEGORY_OVERVIEW.videos,
    ...CATEGORY_GETTING_STARTED.videos,
    ...CATEGORY_CREATING_YOUR_CARD.videos,
    ...CATEGORY_SAVING_AND_SHARING_YOUR_CARD.videos,
    ...CATEGORY_GROWING_YOUR_BUSINESS.videos,
  ]
};

export const CATEGORIES_LIST: any = {
  'CATEGORY_ALL': CATEGORY_ALL,
  'CATEGORY_OVERVIEW': CATEGORY_OVERVIEW,
  'CATEGORY_GETTING_STARTED': CATEGORY_GETTING_STARTED,
  'CATEGORY_CREATING_YOUR_CARD': CATEGORY_CREATING_YOUR_CARD,
  'CATEGORY_SAVING_AND_SHARING_YOUR_CARD': CATEGORY_SAVING_AND_SHARING_YOUR_CARD,
  'CATEGORY_GROWING_YOUR_BUSINESS': CATEGORY_GROWING_YOUR_BUSINESS,
};