import React, { FC, CSSProperties } from 'react';
import { useStyles } from 'modules/editor/components/CardSectionsView/MainInfo/useStyles';
import { ICardVersion } from 'modules/editor/graphql/gql';
import { Avatar } from 'modules/editor/components/CardSectionsView/MainInfo/Avatar';

interface IProps {
  titleStyle: CSSProperties;
  bodyStyle: CSSProperties;
  cardVer: ICardVersion;
}

export const Names: FC<IProps> = (props) => {
  const { titleStyle, bodyStyle, cardVer } = props;
  const classes = useStyles();

  return (
    <>
      <Avatar url={cardVer.avatarUrl} avatarClass={classes.avatar} />
      <div className={classes.name} style={titleStyle} id="card_fullName">
        {cardVer.fullName}
      </div>
      { cardVer.qualifications && (
        <div className={classes.qualifications} style={bodyStyle} id="card_qualifications">
          {cardVer.qualifications}
        </div>
      )}
      { cardVer.jobTitle && (
        <div className={classes.job} style={bodyStyle} id="card_jobTitle">
          {cardVer.jobTitle}
        </div>
      )}
    </>
  );
};
