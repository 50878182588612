import React from 'react';
import { Box } from '@material-ui/core';
import CircularIndeterminate from 'modules/core/components/progress';

export const Wait: React.FC<{ wait: boolean; children: JSX.Element | null }> = ({ wait, children }) =>
  wait ? (
    <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
      <CircularIndeterminate />
    </Box>
  ) : (
    children
  );
