import { IAboutSection, IAboutSectionLink } from 'modules/editor/graphql/gql';

export class AboutSection implements IAboutSection {
  public description?: string;
  public id!: number;
  public title?: string;
  public websiteUrl?: string;
  public websiteUrlShow: boolean = false;
  public aboutSectionLinks: IAboutSectionLink[] = [];

  constructor(section?: IAboutSection) {
    if (section) {
      this.description = section.description;
      this.id = section.id;
      this.title = section.title;
      this.websiteUrl = section.websiteUrl;
      this.websiteUrlShow = section.websiteUrlShow as boolean;
      this.aboutSectionLinks = section.aboutSectionLinks
        ? section.aboutSectionLinks.map((link) => new AboutSectionLink(link))
        : [];
    }
  }
}

export class AboutSectionLink implements IAboutSectionLink {
  public id: number;
  public fileUrl: string;
  public linkUrl: string;
  public linkText: string;

  constructor(link: IAboutSectionLink) {
    this.id = link.id;
    this.fileUrl = link.fileUrl;
    this.linkUrl = link.linkUrl;
    this.linkText = link.linkText;
  }
}
