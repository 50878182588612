import React, { useRef } from 'react';
import { Avatar, Button, makeStyles } from '@material-ui/core';
import { Clear, CloudUpload } from '@material-ui/icons';
import { Box } from '@material-ui/core';
import CircularIndeterminate from 'modules/core/components/progress';
import clsx from 'clsx';
import { grey } from '@material-ui/core/colors';
import { blueCharcoal, limeade } from 'themes/colors';

export interface IImageInputProps {
  size: string;
  alowClear?: boolean;
  disabled?: boolean;
  onClear?: () => void;
  accept?: string;
  name?: string;
  imgUrl?: string;
}

interface IProps extends IImageInputProps {
  pending: boolean;
  onProcessFile: (file: File) => Promise<void>;
}

export const ImageInput: React.FC<IProps> = (props) => {
  const { name, size, disabled, alowClear, onClear, accept = 'image/*', imgUrl, pending, onProcessFile } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();

  async function onInput(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const { files } = event.currentTarget;
    if (files) {
      const file = files[0];
      await onProcessFile(file);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  }

  const inputId = `contained-button-file-${name}`;

  return (
    <>
      <input accept={accept} className={classes.input} id={inputId} type="file" onChange={onInput} ref={inputRef} />
      <Box position="relative" zIndex="0" className={size === 'small' ? classes.small : classes.large}>
        <div className={size === 'small' ? classes.small : classes.large}>
          {!disabled && (
            <Box
              className={clsx(classes.clearWrap, size === 'small' && 'small')}
              display={alowClear && imgUrl ? 'flex' : 'none'}
              onClick={() => onClear && onClear()}
            >
              <Box className={classes.clear}>
                <Clear fontSize="inherit" color="inherit" />
              </Box>
            </Box>
          )}
          <label htmlFor={inputId} style={disabled ? { display: 'contents' } : {}}>
            <Button
              variant="contained"
              component="span"
              disabled={disabled}
              className={classes.button}
              style={{
                backgroundImage: imgUrl ? `url(${imgUrl})` : '',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              {pending ? (
                <CircularIndeterminate />
              ) : (
                <>
                  {!disabled && (
                    <Avatar className={classes.whiteAvatar}>
                      <CloudUpload />
                    </Avatar>
                  )}
                </>
              )}
            </Button>
          </label>
        </div>
      </Box>
    </>
  );
};

const useStyles = makeStyles({
  input: {
    display: 'none',
  },
  small: {
    position: 'relative',
    background: grey[900],
    border: '1px solid #454444',
    borderRadius: 4,
    height: 160,
    width: 160,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  large: {
    position: 'relative',
    background: grey[900],
    border: '1px solid #454444',
    borderRadius: 4,
    height: 200,
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  clearWrap: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    width: '24px',
    height: '24px',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${blueCharcoal}`,
    background: '#060606',
    borderRadius: '50%',
    zIndex: 50,
    cursor: 'pointer',
    transition: 'border-color .25s ease, background .25s ease',
    '&:hover': {
      borderColor: `#6b6b6b`,
      background: '#6b6b6b',
    },
    '&:active': {
      borderColor: `#000000`,
      background: '#000000',
    },
  },
  clear: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '12.8px',
    height: '12.8px',
    color: limeade,
    fontSize: '.8rem',
  },
  button: {
    width: '100%',
    height: '100%',
  },
  whiteAvatar: {
    backgroundColor: '#ffffff',
    color: limeade,
    margin: 10,
  },
});
