import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { green } from 'themes/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popup: {},
    paper: {
      display: 'flex',
      width: 400,
      height: 450,
      background: 'white',
      padding: '23px 29px 30px 30px',
      boxShadow: '0 10px 40px 0 rgba(0,0,0,0.5)',
      borderRadius: 4,
      overflow: 'hidden',
    },
    content: {
      position: 'relative',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      opacity: 1,
      visibility: 'visible',
      transform: 'translateX(0)',
      transition: 'transform .25s ease',

      '&._hidden': {
        position: 'absolute',
        opacity: 0,
        visibility: 'hidden',
        transform: 'translateX(100%)',
      },
      '& button': {
        maxWidth: 320,
      },
      '&._resellerPack': {
        maxWidth: 300,

        '& button': {
          width: 270,
        },
      },
    },
    billingWrap: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    billingElem: {
      display: 'flex',
      maxWidth: '1000px',
      minWidth: 360,
      height: 450,
      background: 'white',
      padding: '23px 29px 30px 30px',
      boxShadow: '0 10px 40px 0 rgba(0,0,0,0.5)',
      borderRadius: 4,
      justifyContent: 'center',
      overflow: 'hidden',
      '& >:first-child': {
        height: '100%',
      },
      '&._auto-height': {
        height: 'auto',
        minHeight: 450,
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
    },
    contactMethods: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    contactElem: {
      width: '300px',
      '& button': {
        marginBottom: 25,
      },
    },
    popupWrap: {
      position: 'absolute',
      width: '100%',
      height: '120%',
    },
    smallFields: {
      display: 'flex',
      margin: '20px 0',
      '& > *': {
        width: 'calc(50% - 13px)',
      },
      '& >:last-child': {},
      '& > :not(:last-child)': {
        marginRight: 20,
      },
    },
    cardIcon: {
      position: 'relative',

      '& svg': {
        fill: '#abaaaa',
      },
      '&:after': {
        display: 'inline',
        verticalAlign: 'middle',
        position: 'absolute',
        height: 16,
        width: 16,
        backgroundColor: '#606060',
        top: -4,
        right: -5,
        color: 'white',
        fontSize: 8,
        lineHeight: 2,
        fontWeight: 700,
        borderRadius: '50%',
        textAlign: 'center',
        content: `'123'`,
      },
    },
    Link: {
      marginTop: 23,
      color: 'white',
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(23),
      '&._hidden': {
        display: 'none',
      },
    },
    popupHead: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    formControl: {
      display: 'flex',
      width: '100%',
      '&> :first-child': {
        flexDirection: 'row',
        justifyContent: 'center'
      },
    },
    radioWrap: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: 150,
      '& label': {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 23,
        marginRight: 0,
        marginLeft: 0,
      },
      '&:not(:last-child)': {
        borderRight: '1px solid #D8D8D8',
      },
    },
    radioButton: {
      '& .MuiIconButton-root': {
        padding: '4px',
      },
      '& .MuiRadio-colorSecondary.Mui-checked ': {
        color: '#76B83F',
      },
      '& .MuiRadio-root': {
        color: '#76B83F',
      },
      '& .MuiFormControlLabel-label': {
        color: '#013D1F',
        fontSize: theme.typography.pxToRem(11),
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: theme.typography.pxToRem(24),
        opacity: 0.6,
        textTransform: 'uppercase',
      },
      '& .MuiIconButton-colorSecondary:hover': {
        backgroundColor: 'rgba(118, 184, 63, 0.2)',
      },
      '&:not(:last-child)': {
        '& .MuiFormControlLabel-root': {
          borderRight: '1px solid rgba(255,255,255,0.2)',
          marginLeft: '0',
        },
      },
    },
    popupContent: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxHeight: '285px',
      overflowY: 'hidden',
      '&._col': {
        flexDirection: 'column',
      },
      '&._resellerPack': {
        maxHeight: 'unset',
      },
    },
    radioTextLarge: {
      fontWeight: 700,
      fontSize: 24,
      letterSpacing: 0.3,
      color: '#013D1F',
      lineHeight: theme.typography.pxToRem(17),
      textAlign: 'center',
      marginBottom: theme.spacing(1),

      '& sup': {
        display: 'inline-block',
        fontSize: 12,
        color: '#013D1F',
        lineHeight: theme.typography.pxToRem(17),
        marginLeft: -5,
      },
      '&._summary': {
        paddingTop: theme.spacing(1),
      },
      '&._resellerPack': {
        minWidth: 110,
        paddingTop: theme.spacing(1),
      },
    },
    radioTextLargeLighter: {
      fontWeight: 300,
      fontSize: 24,
      letterSpacing: 0.3,
      color: '#013D1F',
      lineHeight: theme.typography.pxToRem(17),
      textAlign: 'center',
      marginBottom: theme.spacing(1),

      '& sup': {
        display: 'inline-block',
        fontSize: 12,
        color: '#013D1F',
        lineHeight: theme.typography.pxToRem(17),
        marginLeft: -5,
      },
      '&._summary': {
        paddingTop: theme.spacing(1),
      },
      '&._resellerPack': {
        minWidth: 110,
        paddingTop: theme.spacing(1),
      },
    },
    radioTextSmall: {
      fontWeight: 300,
      fontFamily: 'F37 Ginger Light',
      fontSize: 14,
      letterSpacing: 0.3,
      color: '#013D1F',
      lineHeight: theme.typography.pxToRem(17),
      textAlign: 'center',
    },
    discountUntilText: {
      fontWeight: 300,
      fontFamily: 'F37 Ginger Light',
      fontSize: 12,
      letterSpacing: 0.3,
      color: '#013D1F',
      lineHeight: theme.typography.pxToRem(17),
      textAlign: 'center',
      maxWidth: 300,
      marginBottom: theme.spacing(3),
    },
    popupTitle: {
      fontWeight: 700,
      fontSize: 24,
      letterSpacing: 0.3,
      color: '#013D1F',
      lineHeight: theme.typography.pxToRem(35),
      textAlign: 'center',
    },
    popupDescription: {
      fontFamily: 'F37 Ginger Light',
      fontWeight: 300,
      fontSize: 16,
      letterSpacing: 0.3,
      color: '#749D83',
      lineHeight: theme.typography.pxToRem(23),
      textAlign: 'center',

      '&:not(:last-child)': {
        marginBottom: 29,
      },
    },
    validationMsg: {
      fontFamily: 'F37 Ginger Light',
      fontWeight: 300,
      fontSize: 16,
      letterSpacing: 0.3,
      color: '#eb1c26',
      lineHeight: theme.typography.pxToRem(23),
      textAlign: 'center',
    },
    popupContentText: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',

      '& >:nth-last-child(2)': {
        marginBottom: 29,
      },
    },
    summaryItem: {
      borderBottom: '1px solid #e0e0e0',
      padding: theme.spacing(2, 0),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    ico: {
      fontSize: '7rem',
    },
    centerSpinner: {
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    fnishContent: {
      height: 'calc(100% - 50px)',
    },
    button: {
      width: '100%',
      maxWidth: 'none',
    },
    whiteText: {
      color: 'white',
    },
    starterPackDesc: {
      // maxWidth: 200,
      marginRight: 60,
      fontFamily: 'F37 Ginger Light',
      color: green,
    },
    purchaseSummary: {
      marginBottom: theme.spacing(3),
    },
  })
);
