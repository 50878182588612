import moment from 'moment';
import { IDailyVisit } from 'modules/dashboard/graphql/gql';

export class DailyVisit implements IDailyVisit {
  public countViewed = 0;
  public date = moment();

  constructor(visit?: IDailyVisit) {
    if (visit) {
      this.countViewed = visit.countViewed;
      this.date = moment(visit.date, 'YYYYMMDD');
    }
  }
}
