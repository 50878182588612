import { ICardVersion } from 'modules/editor/graphql/gql';
import { CardVersion } from 'modules/editor/models/card-version';
import { createSymbiote, Symbiotes } from 'redux-symbiote';
import { darkHeader, brightGrey } from 'themes/colors';

export interface IPublicCardRS {
  publicVersion: ICardVersion;
}

export interface IPublicCardRA extends Symbiotes<IPublicCardRS> {
  setPublicVersion(state: IPublicCardRS, ver: ICardVersion): IPublicCardRS;
}

export const { actions, reducer } = createSymbiote<IPublicCardRS, IPublicCardRA>(
  {
    publicVersion: new CardVersion({
      id: -1,
      cardStyle: {
        id: -1,
        backgroundHexColour: darkHeader,
        backgroundImageUrl: '',
        backgroundImageShow: false,
        primaryLinksHexColour: brightGrey,
        secondaryIconsHexColour: 'gray',
        bodyFontStyle: 'Normal',
        titleFontStyle: 'Normal',
      },
    }),
  },
  {
    setPublicVersion: (state: IPublicCardRS, ver: ICardVersion) => ({
      publicVersion: ver,
    }),
  }
);
