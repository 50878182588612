import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ico: {
      fontSize: 120,
      lineHeight: 1,
    },
    header: {
      color: '#0D3E1F',
      margin: 0,
      fontSize: 20,
      marginBottom: 6,
    },
    container: {
      width: '585px',
      padding: theme.spacing(2, 2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: '#8CA994',
      [theme.breakpoints.between('xs', 'sm')]: {
        width: '264px',
      },
    },
    contentContainer: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 4,
    },
    success: {
      color: '#7ABC42',
    },
    error: {
      color: '#f44336',
    },
    copyText: {
      color: '#7ABC42',
      margin: '0 .5rem',
    },
  })
);

export const clipboardStyles = makeStyles((theme: Theme) =>
  createStyles({
    clipboard: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',

      '&._center': {
        width: '100%',
        justifyContent: 'center',
      },
    },
    copy: {
      cursor: 'pointer',
      color: '#7ABC42',
      marginRight: -4,
    },
  })
);
