import React, { useMemo, useState } from 'react';
import { Typography } from '@material-ui/core';
import { SelectField } from 'modules/core/components/fields';
import CssGreenButton from 'modules/core/components/buttons/roundGreenButton';
import Amount from './Amount';
import { useStyles } from './styles';

interface ISelectCount {
  planName: string;
  vat: boolean;
  selectedCount: number;
  ccy: string;
  price: number;
  maxCount: number;
  disabled?: boolean;
  discountUntil?: string;
  next: (selectedCount: number) => void;
}

export const SelectCount: React.FC<ISelectCount> = ({
  price,
  maxCount,
  selectedCount,
  ccy,
  planName,
  vat,
  disabled,
  next,
  discountUntil,
}) => {
  const [count, setCount] = useState(selectedCount || 1);
  const classes = useStyles();
  const options = useMemo(() => {
    const opt = [];
    for (let i = 1; i <= maxCount; i++) {
      opt.push({ label: i, value: i });
    }
    return opt;
  }, [maxCount]);
  const countRange = maxCount && maxCount > 1 ? `1-${maxCount}` : '';

  return (
    <div className={classes.content}>
      <div className={classes.popupHead}>
        <Typography className={classes.popupTitle} variant="h4">
          How many cards?
        </Typography>
        <Typography className={classes.popupDescription}>Select amount {countRange}</Typography>
        <SelectField
          exClass="_green"
          name="buy_card_count"
          value={count}
          onChange={(val) => setCount(Number(val))}
          label="Number of cards"
          options={options}
        />
      </div>
      <div className={classes.popupContentText}>
        <Typography className={classes.radioTextLarge}>
          <Amount ccy={ccy} amount={price * count} vatDisplay={vat} />
        </Typography>
        <Typography className={classes.radioTextSmall}>
          {count} {`${count > 1 ? 'users' : 'user'}`}
        </Typography>
        <CssGreenButton name="step_count_next" disabled={disabled} onClick={() => next(count)}>
          CONTINUE
        </CssGreenButton>
      </div>
    </div>
  );
};
