export const UPDATE_NEWS_SCHEME = {
  $cardVersionId: 'ID!',
  $linkText: 'String',
  $linkUrl: 'String',
  $linkUrlShow: 'Boolean',
  $title: 'String',
  $showSectionHeader: 'Boolean',
  $newsImageBase64: 'String',
  $newsImageName: 'String',
};
