import React from 'react';
import { useStyles } from './styles';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { ReactComponent as Selector } from 'assets/svg/selector.svg';
import { SvgIcon } from '@material-ui/core';
import { Else, If, Then } from 'react-if';
import { useUserName } from 'modules/core/hooks/useUserName';
import ProfileMenu from 'modules/core/components/Header/ProfileMenu';
import SignLinks from './signLinks';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { actions as cardActions } from 'modules/editor/reducers/cardReducer';
import { actions as stepActions } from 'modules/editor/reducers/stepsReducer';
import { actions } from 'modules/auth/reducers/authReducer';
import AccountSwitcherMenu from 'modules/core/components/accounts/AccountSwitcherMenu';

export const PreHeaderMenu: React.FC = () => {
  const classes = useStyles();
  const { pending, isAuthenticated } = useTypedSelector((state) => state.auth);
  const userName = useUserName();

  const history = useHistory();
  const dispatch = useDispatch();
  const { signout } = actions;

  const logout = () => {
    if (signout) {
      dispatch(signout());
      dispatch(cardActions.clearCardState());
      dispatch(stepActions.clear);
      history.push('/');
    }
  };

  return (
    <div className={classes.preHeaderContainer}>
      <div className={classes.preHeaderRow}>
        <SvgIcon children={<Selector />} htmlColor={'#999999'} className="icon" onClick={() => history.push('/')} style={{cursor: 'pointer'}} />
        <span className="divider" />
        <div className={classes.links} id="preHeader">
          <AccountSwitcherMenu />
          <If condition={isAuthenticated}>
            <Then>
              <ProfileMenu
                userName={userName}
                isAuthenticated={isAuthenticated}
                signout={logout}
                pending={pending}
                isPublic={false}
              />
            </Then>
            <Else>
              <SignLinks />
            </Else>
          </If>
        </div>
      </div>
    </div>
  );
};

export default PreHeaderMenu;
