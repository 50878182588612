import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { loadAuthToken } from 'modules/core/utils/api';
import { isArray } from 'util';
import { HOST_URL } from 'modules/core/constants/config';

const makeUrl = (path: string): string => {
  const parts = [HOST_URL];
  if (path) {
    parts.push(path.trim().replace(/(^\/+)|(\/+$)/g, ''));
  }
  return parts.join('/');
};

export const axiosErrors = (e: AxiosError) => {
  let { message } = e;
  const { response } = e;
  if (response) {
    const { data, statusText } = response;
    if (data) {
      if (isArray(data)) {
        message = data.join('\n');
      } else if (typeof data === 'string') {
        message = data;
      }
    } else {
      message = statusText;
    }
  }
  /*
  if (e.response && e.response.data !== undefined && e.response.data.error !== undefined) {
    text = e.response.data.error;
  } else if (e.response && e.response.data !== undefined && e.response.data.errors !== undefined) {
    const data = e.response.data.errors;
    text = Object.keys(data)
      .map((key) => `${key}: ${data[key].join(', ')}`)
      .join('\n');
  }
  */
  return message || 'Error';
};

const refreshAuthToken = () => {
  const token = loadAuthToken();
  const headers: any = {};
  if (token) {
    headers.Authorization = `bearer ${token}`;
  }
  Axios.defaults.headers.common = headers;
};

export const axiosGet = (path: string, options: Partial<AxiosRequestConfig> = {}) => {
  const url = makeUrl(path);
  return (params: any) => {
    refreshAuthToken();
    return Axios.get(url, { params, ...options });
  };
};

export const axiosPost = (path: string, options: Partial<AxiosRequestConfig> = {}) => {
  const url = makeUrl(path);
  return (body: any, reqOptions = {}) => {
    refreshAuthToken();
    return Axios.post(url, body, { ...options, ...reqOptions });
  };
};
export const axiosPut = (path: string, options: Partial<AxiosRequestConfig> = {}) => {
  const url = makeUrl(path);
  return (body: any) => {
    refreshAuthToken();
    return Axios.put(url, body, { ...options });
  };
};
export const axiosDelete = (path: string, options: Partial<AxiosRequestConfig> = {}) => {
  const url = makeUrl(path);
  return (params: any) => {
    refreshAuthToken();
    return Axios.delete(url, { params, ...options });
  };
};
