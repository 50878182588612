import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { darkHeader } from 'themes/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
      color: 'white',
      fontFamily: 'F37 Ginger',
    },

    wrap: {
      '& .logo': {
        fill: 'white!important',
      },
    },
    header: {
      flexGrow: 1,
    },
    titleHeader: {
      flexGrow: 1,
      color: '#FFF',
      alignItems: 'center',
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        height: 56
      },
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
      '&._flex-start': {
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
          justifyContent: 'center',
          flexDirection: 'row',
        },
      },
      '&._card': {
        flexWrap: 'wrap',
        marginTop: 33,
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'row',
        },
      },
    },
    divider: {
      margin: theme.spacing(0, 1),
      width: 1,
      height: 24,
      backgroundColor: '#555555',

      [theme.breakpoints.down(410)]: {
        '&._DashboardLink': {
          display: 'none',
        },
      },
    },
    selectWrap: {
      maxWidth: 200,
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    blockTitle: {
      fontSize: 20,
      color: 'white',
      lineHeight: theme.typography.pxToRem(29),
      [theme.breakpoints.down('sm')]: {
        display: 'none',
        '&._visible': {
          display: 'block',
          opacity: 0.8,
          margin: '0 auto',
          textTransform: 'uppercase',
          fontSize: 12,
          lineHeight: theme.typography.pxToRem(17),
        },
      },
    },
    url: {
      fontSize: 20,
      color: 'white',
      fontFamily: 'F37 Ginger Light',
      lineHeight: theme.typography.pxToRem(29),
      [theme.breakpoints.between('xs', 'sm')]: {
        display: 'block',
        margin: '0 auto',
        fontSize: 16,
        lineHeight: theme.typography.pxToRem(24),
        letterSpacing: 0.2,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: 'calc(100vw - 28px)',
        minHeight: '24px',
        textAlign: 'center',
        paddingRight: '10px',
      },
    },
    clipboardContainer: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      '&:not(:first-child)': {
        marginLeft: 10,
      },
      [theme.breakpoints.down('sm')]: {
        // flexDirection: 'column',

        '&:last-child ._linkText': {
          marginRight: 20,
        },
      },
    },
    titleWrap: {
      display: 'flex',
      flexDirection: 'column',
    },
    blockSubtitle: {
      fontSize: 12,
      lineHeight: theme.typography.pxToRem(17),
      letterSpacing: 0.1,
      textTransform: 'uppercase',
      opacity: 0.8,
      [theme.breakpoints.down('sm')]: {
        '&._visible': {
          display: 'none',
        },
        opacity: 0.8,
        marginBottom: 27,
        textAlign: 'center',
        '&._mb24': {
          marginBottom: 24,
        },
      },
    },
    card: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: 'white',
      borderRadius: '4px',
      padding: '38px 60px 41px 40px',
      maxHeight: 120,
      width: 'calc(25% - 15px)',

      '&._reseller': {
        padding: '38px 20px 41px 20px',
      },
      '&:not(:last-child)': {
        marginRight: 20,
        [theme.breakpoints.down('md')]: {
          marginRight: 15,
          '&:nth-child(2n)': {
            marginRight: 0,
          },
        },
      },
      [theme.breakpoints.down('md')]: {
        width: 'calc(50% - 8px)',
        marginBottom: 15,
        '&:not(:nth-last-child(2n))': {
          marginRight: 15,
        },
        '&:last-child': {
          marginRight: 0,
        },
        '&._reseller': {
          padding: '30px 25px 18px 25px',
        },
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        padding: '10px 25px 18px 25px',
      },
      [theme.breakpoints.down(480)]: {
        '&._reseller': {
          width: '100%',
          '&:not(:last-child)': {
            marginRight: 0,
          },
          '&:not(:nth-last-child(2n))': {
            marginRight: 0,
          },
        },
      },
    },
    cardText: {
      color: darkHeader,
      fontSize: 14,
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 600,
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        '&._reseller': {
          fontSize: 12,
        },
      },
    },
    cardTextBreak: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    cardNumber: {
      color: darkHeader,
      fontSize: 36,
      lineHeight: theme.typography.pxToRem(52),
      fontWeight: 600,
      marginRight: 30,
      whiteSpace: 'nowrap',
      '&._reseller': {
        fontSize: 32,
        marginRight: 10,
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        '&._reseller': {
          fontSize: 30,
        },
      },
    },
    urlTitle: {
      borderBottom: '2px solid #393e43',
      marginBottom: theme.spacing(6),
      paddingBottom: theme.spacing(3),
    },
    clipboard: {
      margin: '0 .5rem',
      color: '#7ABC42',
    },
    qrCodeText: {
      margin: '0 .5rem',
      display: 'inline-block',
    },
    dashboardLinkAction: {
      color: '#7ABC42',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
    buttonRow: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
    },
    shareCardMobileWrapper: {
      width: '100%',
      marginTop: 20,
      minWidth: 330,

      [theme.breakpoints.down(360)]: {
        minWidth: 270,
      },
    },
    shareCardText: {
      // display: 'inline-block',
      display: 'flex',
      alignItems: 'center',
    },
    payoutBatch: {
    },
    payoutBatches: {
      width: "100%",
      marginBottom: 0,
      textAlign: 'center',
      borderBottom: "1px solid #000000",
      borderTop: "1px solid #30343a",
      paddingTop: 20,
      paddingBottom: 10,

      '& .swiper-slide': {
        fontSize: 20,

        '&.swiper-slide-active': {
          fontSize: 28,
          marginRight: 0,
        },
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: 2,
      },
    },
    payoutBatchesSwipeContainer: {
      WebkitMask: "linear-gradient(90deg, #252b3100 0%, #252b3199 13%, #252b31ff 25%, #252b31ff 75%, #252b3199 87%, #252b3100 100%)",
    },
    payoutBatchDate: {
      fontSize: 10,
      letterSpacing: 0.1,
      textTransform: 'uppercase',
      opacity: 0.6,
    },
    payoutBatchAmount: {
    },
    [theme.breakpoints.up('md')]: {
      mdUpHide: {
        display: 'none',
      },
    },
    [theme.breakpoints.down('sm')]: {
      smDownHide: {
        display: 'none',
      },
    },
  })
);

export default useStyles;
