import Typography from '@material-ui/core/Typography';
import StarIcon from '@material-ui/icons/Star';
import React from 'react';
import { useStyles } from './styles';
import clsx from 'clsx';

interface IReviewProps {
  text: string;
  name: string;
  position: string;
  img: string;
  starsCount: number;
}

const Review: React.FC<IReviewProps> = ({ text, name, position, img, starsCount }) => {
  const classes = useStyles();
  const stars = [...Array(starsCount)].map((e, i) => {
    return <StarIcon key={i} />;
  });

  return (
    <div className={classes.authorWrap}>
      <div className={classes.stars}>{stars}</div>
      <Typography className={classes.reviewText} align="center">{text}</Typography>
      <div className={classes.author}>
        <div className={classes.authorImg}>
          <img src={img} alt="" />
        </div>
      </div>
      <div className={clsx(classes.authorDescription, '_authorDescription')}>
        <Typography className={clsx(classes.name, '_authorName')}>{name}</Typography>
        <Typography className={classes.position}>{position}</Typography>
      </div>
    </div>
  );
};

export default Review;
