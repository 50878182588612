import { Box, IconButton, Link, MenuItem, Popover, Hidden } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';
import RoutedLink from 'modules/core/components/RoutedLink';
import React, { useCallback, useState } from 'react';
import { EDITOR_ROUTE, SIGNIN_ROUTE } from 'routes/routes';
import { useStyles } from './styles';
import CircularIndeterminate from 'modules/core/components/progress';

interface IProfileMenuComponent {
  isAuthenticated?: boolean;
  pending: boolean;
  userName: string;
  isPublic: boolean;
  signout: () => void;
}

const ProfileMenu: React.FC<IProfileMenuComponent> = ({ isAuthenticated, userName, signout, pending, isPublic }) => {
  const classes = useStyles();
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const handleClose = () => {
    setAnchor(null);
  };

  const logout = useCallback(() => {
    if (signout) {
      signout();
    }
    handleClose();
  }, [signout]);

  if (isAuthenticated) {
    return (
      <Hidden mdDown>
        <Box display="flex" alignItems="center" className={clsx(classes.profileMenu, isPublic ? 'isPublic' : '')}>
          {pending ? (
            <CircularIndeterminate size={20} my={0} />
          ) : (
            <Link
              id="user_name"
              component={RoutedLink}
              to={EDITOR_ROUTE.path}
              className={clsx(classes.link, classes.linkName)}
            >
              {userName}
            </Link>
          )}
          <IconButton
            className={classes.button}
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            id="profile_drop_menu"
            color="inherit"
            onClick={(evt) => setAnchor(evt.currentTarget)}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
          <Popover
            id="user-menu"
            anchorEl={anchor}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(anchor)}
            onClose={handleClose}
          >
            <MenuItem onClick={logout} id="log_out_menu_item">
              Log out
            </MenuItem>
          </Popover>
        </Box>
      </Hidden>
    );
  } else {
    return (
      <Link component={RoutedLink} to={SIGNIN_ROUTE.path} className={clsx(classes.link, '_bold')}>
        SIGN IN
      </Link>
    );
  }
};

export default ProfileMenu;
