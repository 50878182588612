import React from 'react';
import { Box } from '@material-ui/core';
import { useButtons } from 'modules/editor/components/CardSectionsView/MainInfo/Actions/useButtons';
import { ICardVersion } from 'modules/editor/graphql/gql';
import { useStyles } from 'modules/editor/components/CardSectionsView/MainInfo/useStyles';
import clsx from 'clsx';
import { E_PREVIEW_TYPE } from 'modules/editor/constants/enums';
import { RoundButton } from 'modules/editor/components/CardSectionsView/MainInfo/RoundButton';
import { useButtonStyle } from 'modules/editor/components/CardSectionsView/MainInfo/hooks/useButtonStyle';
import { useUserStyleOfBody } from 'modules/editor/containers/Card/functions';

interface IProps {
  cardVer: ICardVersion;
  previewType: E_PREVIEW_TYPE;
  disabled: boolean;
  includeShareBtn?: boolean;
  exClass?: any;
}

export const Actions: React.FC<IProps> = (props) => {
  const { cardVer, previewType, disabled, includeShareBtn, exClass } = props;
  const { email, mobilePhone, secondPhoneNumber, cardStyle, currentForCardId } = cardVer;
  const mainBtns = useButtons({ cardVer, email, mobilePhone, secondPhoneNumber, includeShareBtn, currentForCardId });
  const classes = useStyles();
  const buttonStyle = useButtonStyle(cardStyle);
  const bodyStyle = useUserStyleOfBody(cardStyle);

  return (
    <Box py={2} display="flex" justifyContent="center" className={clsx(classes.actionsContainer, exClass)}>
      {mainBtns.map((btnProps, i) => {
        const { id, ...restProps } = btnProps;
        return (
          <div key={i} className={clsx(classes.buttonWrapper, previewType, 'MainInfo-Action')} id={id}>
            <RoundButton
              {...restProps}
              url={btnProps.url}
              size="lg"
              buttonStyle={buttonStyle}
              bodyStyle={bodyStyle}
              disabled={disabled}
            />
          </div>
        );
      })}
    </Box>
  );
};
