import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '100%',
      margin: theme.spacing(0, 0, 1.6, 0),
      '& .MuiInputBase-root': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
      '& .MuiFilledInput-underline:before': {
        borderBottomColor: 'rgba(255,255,255,0.4)',
      },
      '&._green': {
        '& .MuiFilledInput-underline:before': {
          borderBottom: '1px solid #749D83',
        },
        '& .MuiFilledInput-underline:after': {
          borderBottomWidth: '2px',
          borderBottomColor: '#013D1F',
        },
        '& svg': {
          fill: '#749D83',
        },
      },
      '&._table': {
        width: '100%',
        marginBottom: 0,
        '& svg': {
          fill: '#76B741',
        },
      },
      '&._space-between': {
        [theme.breakpoints.up('sm')]: {
          '&:not(:first-child):not(:last-child)': {
            margin: theme.spacing(0, 2),
          },
        },
      },
    },
    label: {
      marginLeft: 16,
      marginBottom: 20,
      fontWeight: 300,
      fontFamily: 'F37 Ginger Light',

      '&.MuiInputLabel-shrink': {
        color: 'white',
        opacity: 0.6,
        marginBottom: 0,
        marginLeft: 0,
        transform: 'translate(16px, 6px) scale(.75)',
      },
      '&._green': {
        color: '#749D83',
        fontSize: 11,
        letterSpacing: 0.09,
        lineHeight: theme.typography.pxToRem(24),
        fontWeight: 300,
        fontFamily: 'F37 Ginger Light',
        '&.MuiInputLabel-shrink': {
          opacity: 1,
        },
      },
      '&._table': {
        display: 'none',
      },
      '&._editor': {
        fontSize: 14,

        '&.MuiInputLabel-shrink': {
          marginTop: 3,
        }
      },
    },
    labelShrink: {
      opacity: 0.6,
      marginBottom: 0,
      marginLeft: 0,
      transform: 'translate(16px, 6px) scale(.75)',
    },
    li: {
      '&._table': {
        color: '#76B741',
      },
    },
    select: {
      '& .MuiFilledInput-input': {
        padding: '27px 0 10px 16px',
      },
      '&._green': {
        fontFamily: 'F37 Ginger Light',
        fontSize: 14,
        backgroundColor: 'rgba(255,255,255,0.1)',
        lineHeight: theme.typography.pxToRem(24),
        fontWeight: 300,
        color: '#013D1F',
        '& .MuiFilledInput-input': {
          fontFamily: 'F37 Ginger Light',
          fontSize: 14,
          lineHeight: theme.typography.pxToRem(24),
          fontWeight: 300,
          color: '#013D1F',
        },
      },
      '&._green._bold': {
        '& .MuiFilledInput-input': {
          paddingTop: 17,
          paddingBottom: 14,
          color: 'white',
          fontWeight: 700,
        },
      },
      '&._table': {
        '&.MuiFilledInput-underline::after': {
          display: 'none',
        },
        '&.MuiFilledInput-underline::before': {
          display: 'none',
        },
        backgroundColor: 'transparent',
        borderLeft: '1px solid #7C7F83',
        [theme.breakpoints.down('sm')]: {
          borderLeft: 'none',
        },
        '& .MuiFilledInput-input': {
          fontFamily: 'F37 Ginger Light',
          fontSize: 14,
          lineHeight: theme.typography.pxToRem(24),
          fontWeight: 300,
          color: '#76B741',
          padding: '5px 12px 5px 16px',
          [theme.breakpoints.down('sm')]: {
            padding: '15px 0 5px 0',
          },
        },
      },
    },
  })
);
