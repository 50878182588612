import { Box, Container, Link } from '@material-ui/core';
import clsx from 'clsx';
import { actions } from 'modules/auth/reducers/authReducer';
import { reloadProfile } from 'modules/auth/thunks/auth';
import {
  getPlans,
  getSummaryInfo,
  createSubscription,
  userSubscriptions,
  handleInitialSubscriptionFail,
  updateSubscriptionStatus,
} from 'modules/billing/api/billing';
import {
  Contact,
  FinishScreen,
  ProcessingPayment,
  SelectCount,
  Summary,
  useStyles,
} from 'modules/billing/components/Billing';
import { IPreviewSubscriptionSummary } from 'modules/billing/graphql/gql';
import { Plan } from 'modules/billing/models/plan';
import { Subscription } from 'modules/billing/models/subscription';
import CircularIndeterminate from 'modules/core/components/progress';
import { Stepper } from 'modules/core/components/Stepper';
import { useDefaultManifest } from 'modules/core/utils';
import useStylesPage from 'modules/public/pages/main/pageStyles';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { AnyAction } from 'redux';
import {
  EDITOR_ROUTE,
  SIGNIN_ROUTE,
  RESELLER_INITIAL_PURCHASE_ROUTE_PATH,
  USERS_ROUTE,
  BUY_ROUTE
} from 'routes/routes';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { useStripe } from 'modules/billing/components/BuyPage/useStripe';
import { PayDataStripeInjected as PayData } from 'modules/billing/components/BuyPage/Steps/PayData/StripeInjected';
import { E_ACCOUNT_TYPE } from 'modules/auth/constants/enums';

interface IBuyMoreComponent {
  setSubscriptions: (subs: Subscription[]) => AnyAction;
  reloadProfile: () => Promise<void>;
}

const BuyMoreComponent: React.FC<IBuyMoreComponent> = ({ setSubscriptions, reloadProfile: profileReload }) => {
  useDefaultManifest();

  const history = useHistory();
  const pageStyles = useStylesPage();
  const classes = useStyles();
  const stripe = useStripe();

  const profile = useTypedSelector((state) => state.auth.profile);
  const [success, setSuccess] = useState(false);
  const [step, setStep] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [isPAO, setPAO] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [plan, setPlan] = useState<Plan | null>(null);
  const [summary, setSummary] = useState<IPreviewSubscriptionSummary | null>(null);
  const [contactType, setContactType] = useState<'phone' | 'email'>();
  const [disabled, setDisabled] = useState(false);
  const [isProcessing, setProcessing] = useState<boolean>(false);
  const [useRetryCardPaymentFlow, setUseRetryCardPaymentFlow] = useState(false);
  const [paymentMethodId, setPaymentMethodId] = useState('');

  const discountUntil = profile.eligibleForDiscountUntil && profile.eligibleForDiscountUntil.format('DD MMM YYYY');
  const stepCount = useRetryCardPaymentFlow ? 4 : 3;

  useEffect(() => {
    // resellers should not be able to access this page
    // redirect to reseller based on subscription status/history
    //
    if (profile.id && profile.type === E_ACCOUNT_TYPE.Reseller) {
      if (profile.subscribed) {
        history.push(EDITOR_ROUTE.path)
      } else if (!profile.hasBeenSubscribed) {
        history.push(RESELLER_INITIAL_PURCHASE_ROUTE_PATH)
      } else {
        history.push(BUY_ROUTE.path)
      };
    };
  }, [profile, history]);

  const errHandler = useCallback((error?: any) => {
    if (typeof error === 'string') {
      setErrorMessage(error)
    } else if (error) {
      console.log(error);
      setErrorMessage(error[0])
    }

    setSuccess(false);
    setDisabled(false);
    setStep(stepCount - 1);
  }, [setSuccess, setStep, setErrorMessage, stepCount]);

  const finishScreenRedirect = useCallback(() => {
    if (!isPAO && !success) {
      setPaymentMethodId('');
      setUseRetryCardPaymentFlow(true)
      setStep(1)
    } else {
      history.push(USERS_ROUTE.path);
    };
  },
  [isPAO, success, setStep, history]);

  useEffect(() => {
    if (profile.id) {
      getPlans()
        .then(async (plans) => {
          const subscriptions = await userSubscriptions();
          const subscription = (subscriptions.length && subscriptions.find((x) => x.account.id === profile.id)) || null; // TODO - Extend first subscribe!!!
          if (subscription) {
            const fPlan = plans.find((p: Plan) => p.id === subscription.planId) || new Plan();
            setPlan(fPlan);
          }
        })
        .then()
        .catch(() => errHandler());
    }
  }, [errHandler, profile]);

  const handler = useCallback(
    (s: number) => {
      if (!isProcessing && step !== 2) {
        if (s === 0) {
          setPAO(false);
        }
        if (s < step) {
          setStep(s);
        }
      }
    },
    [isProcessing, setStep, setPAO, step]
  );

  const countHandler = useCallback(
    async (count: number) => {
      setQuantity(count);
      setSummary(null);
      setDisabled(true);
      try {
        const summaryInf = await getSummaryInfo({
          planId: plan!.id,
          quantity: count,
          couponId: plan!.coupon.id,
        });
        setSummary(summaryInf);
        setStep(1);
      } catch (error) {
        errHandler();
      } finally {
        setDisabled(false);
      }
    },
    [setQuantity, errHandler, setStep, setSummary, plan]
  );

  const loadSummaryInfo = useCallback(
    async (count: number, pm?: string) => {
      try {
        setDisabled(true);
        setProcessing(true);
        setStep(step + 1);

        const summaryInf = await getSummaryInfo({
          paymentMethodId: pm,
          planId: plan!.id,
          quantity: count,
          couponId: plan!.coupon.id,
        });

        setSummary(summaryInf);
      } catch (error) {
        errHandler();
      } finally {
        setDisabled(false);
        setProcessing(false);
      };
    },
    [setDisabled, setProcessing, setSummary, setStep, errHandler, step, plan]
  );

  const pmHandler = useCallback(
    async (pm: string) => {
      if (useRetryCardPaymentFlow && step === 1) {
        setDisabled(true);
        setProcessing(true);

        setPaymentMethodId(pm);
        setSummary(null);

        await loadSummaryInfo(quantity, pm);

        setDisabled(false);
        setProcessing(false);
      }
    },
    [setPaymentMethodId, loadSummaryInfo, setSummary, quantity, step, useRetryCardPaymentFlow]
  );

  const confirmHandler = async () => {
    try {
      setDisabled(true);
      setProcessing(true);
      const res = await createSubscription({
        couponId: plan!.coupon ? plan!.coupon.id : undefined,
        paymentMethodId,
        planId: plan!.id,
        quantity,
      });

      if (res) {
        if (res.success) {
          finishPaymentAttempt(afterSuccessfulPayment)
        } else if (res.scaSecret) {
          stripe!
            .confirmCardPayment(res.scaSecret, {
              payment_method: paymentMethodId,
              setup_future_usage: "off_session",
            })
            .then((result) => {
              if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
                updateSubscriptionStatus(res.subscription!.subscriptionId)
                  .then(() => finishPaymentAttempt(afterSuccessfulPayment));
              } else if (result.error) {
                if (res.subscription) {
                  handleInitialSubscriptionFail(res.subscription.subscriptionId);
                }

                finishPaymentAttempt(() => afterUnsuccessfulPayment(result.error!.message));
              }
            })
            .catch((error) => console.warn(error));
        }
      }
    } catch (error) {
      finishPaymentAttempt(() => afterUnsuccessfulPayment(error));
    }
  };

  const finishPaymentAttempt = (callback: () => any) => {
    Promise.resolve()
      .then(callback)
      .then(afterPaymentAttempt);
  };

  const afterSuccessfulPayment = async () => {
    setSuccess(true);
    setStep(stepCount - 1);

    const subs = await userSubscriptions();
    setSubscriptions(subs);
  };

  const afterUnsuccessfulPayment = (error?: any) => {
    setSuccess(false);
    errHandler(error);
  };

  const afterPaymentAttempt = async () => {
    try {
      await profileReload();
      setDisabled(false);
      setProcessing(false);
    } catch (error) {
      console.warn(error);
    }
  };

  const contactHandler = useCallback(
    (type: 'phone' | 'email') => {
      setContactType(type);
      setSuccess(true);
      setStep(stepCount - 1);
    },
    [setContactType, stepCount]
  );

  const SelectCountStep: React.FC = () => {
    return (
      <>
        {plan ? (
          <SelectCount
            vat={plan!.vat}
            ccy={plan!.currency}
            next={countHandler}
            planName={plan!.nickname as string}
            maxCount={Number(plan!.metadata.upToMax)}
            price={Number(plan!.amount)}
            selectedCount={quantity}
            disabled={disabled}
            discountUntil={discountUntil}
          />
        ) : (
          <Box className={classes.centerSpinner}>
            <CircularIndeterminate />
          </Box>
        )}
      </>
    );
  };

  const SummaryStep: React.FC = () => {
    return (
      <>
        {isProcessing ? (
          <ProcessingPayment />
        ) : (
          <Summary
            ccy={plan!.currency}
            disabled={disabled}
            count={quantity}
            percent={Number(summary!.vatPercentage)}
            total={Number(summary!.total)}
            vat={Number(summary!.vatAmount)}
            next={confirmHandler}
          />
        )}
      </>
    );
  };

  const CardPaymentFinishScreen: React.FC = () => {
    return (
      <FinishScreen
        title="Success!"
        titleErr="Error!"
        subtitle="Your payment has been accepted"
        subtitleErr={errorMessage || "Try again later"}
        success={success}
        timerCb={finishScreenRedirect}
      />
    );
  };

  const PoaFinishScreen: React.FC = () => {
    return (
      <FinishScreen
        type={contactType}
        success={success}
        subtitle="We will contact you shortly"
        subtitleErr="Try again later"
        title={contactType === 'email' ? 'Email request received!' : 'Call request received!'}
        titleErr="Error!"
      />
    );
  };

  const Flow: React.FC = () => {
    if (isPAO) {
      switch (step) {
        case 0:
          return <SelectCountStep />;
        case 1:
          return <Contact next={contactHandler} err={errHandler} />;
        case 2:
          return <PoaFinishScreen />;
        default:
          return null;
      }
    } else if (useRetryCardPaymentFlow) {
      switch (step) {
        case 0:
          return <SelectCountStep />;
        case 1:
          return <PayData next={pmHandler} err={errHandler} disabled={disabled} />;
        case 2:
          return <SummaryStep />;
        case 3:
          return <CardPaymentFinishScreen />;
        default:
          return null;
      }
    } else {
      switch (step) {
        case 0:
          return <SelectCountStep />;
        case 1:
          return <SummaryStep />;
        case 2:
          return <CardPaymentFinishScreen />;
        default:
          return null;
      }
    }
  };

  return (
    <div className={clsx(pageStyles.page, '_padding-sm-mobile')}>
      <Container className={classes.billingWrap}>
        <div className={classes.billingElem}>
          <StripeProvider stripe={stripe}>
            <Elements>
              <Flow />
             </Elements>
          </StripeProvider>
        </div>
        <Box mt={3}>
          <Stepper disabledBack={step === 0} disabledNext={true} steps={stepCount} activeStep={step} onChange={handler} />
        </Box>
        <Link className={clsx(classes.Link, step === 0 ? '' : '_hidden')} href={SIGNIN_ROUTE.path}>
          Already have an account? Sign in
        </Link>
      </Container>
    </div>
  );
};

export const BuyMorePage = connect(null, { setSubscriptions: actions.setSubscription, reloadProfile })(
  BuyMoreComponent
);
