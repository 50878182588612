import PublicLayout from 'layouts/PublicLayout';
import CustomLayout from 'layouts/CustomLayout';
import React from 'react';
import { Route } from 'react-router';
import { IRouteProps } from 'modules/core/interfaces';

const PublicRoute: React.FC<IRouteProps> = ({ children, isCustomLayout, title, customNavbar, customBackground, ...rest }) => {
  if (isCustomLayout) {
    return <Route {...rest} render={() => <CustomLayout>{children}</CustomLayout>} />;
  } else {
    return (<Route {...rest} render={() => (
              <PublicLayout
                title={title}
                customNavbar={customNavbar}
                customBackground={customBackground}
              >
                {children}
              </PublicLayout>
            )} />);
  }
};

export default PublicRoute;
