import React, { useMemo } from 'react';
import { IPlan } from 'modules/billing/graphql/gql';
import { useStyles } from './styles';
import { RadioGroup, FormControl, Box } from '@material-ui/core';
import PlanItem from './PlanItem';
import Sale from './Sale';

interface IPlanForm {
  displayPlans: IPlan[];
  value?: string;
  setPlan: (val: string) => void;
}

const PlanForm: React.FC<IPlanForm> = ({ displayPlans, setPlan, value }) => {
  const classes = useStyles();
  const planWithCoupon = useMemo(() => displayPlans.find((plan) => plan.coupon && plan.coupon.id), [displayPlans]);
  return (
    <Box position="relative">
      <FormControl component="fieldset" className={classes.formControl}>
        <RadioGroup
          defaultValue={displayPlans.length ? displayPlans[0].id : ''}
          aria-label=""
          value={value}
          name="value"
          onChange={(event) => setPlan(event.target.value as string)}
        >
          {displayPlans.map((plan, i) => (
            <PlanItem name={`plan_item_${i}`} key={plan.id} plan={plan} />
          ))}
        </RadioGroup>
      </FormControl>
      {planWithCoupon ? (
        <Box id="buy_sale" top="70px" left="120px" position="absolute">
          <Sale ccy={planWithCoupon.coupon.currency as string} saleCount={planWithCoupon.coupon.amountOff as number} />
        </Box>
      ) : null}
    </Box>
  );
};

export default PlanForm;
