import React, { forwardRef } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import clsx from 'clsx';
import { Box } from '@material-ui/core';

interface IProps {
  children?: any;
  title?: string;
  firstLine?: JSX.Element;
  className?: any;
  icon?: any;
  bodyStyle?: any;
  titleStyle?: any;
}

const CardWrapper = forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const { className, title, firstLine, children, icon, bodyStyle, titleStyle } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.card, className)} ref={ref}>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Box display="flex" flexDirection="column" className={classes.headWrap}>
          <div className={classes.title} style={bodyStyle}>
            {firstLine}
          </div>
          <div className={classes.title2} style={titleStyle}>
            {title}
          </div>
        </Box>
        <div className={classes.icon}>{icon}</div>
      </Box>
      <Box>{children}</Box>
    </div>
  );
});

export default CardWrapper;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      position: 'relative',
      background: 'white',
      borderRadius: '6px',
      padding: '15px',
      textAlign: 'left',
      boxShadow: '0 5px 15px 0 rgba(15,15,15,0.4)',
      fontSize: '16px',
      color: '#999999',
      marginBottom: '30px',
    },
    title: {
      fontSize: '10px',
      textTransform: 'uppercase',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    title2: {
      fontSize: '16px',
      color: '#000000',
      fontFamily: 'F37 Ginger',
      fontWeight: 'bold',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    icon: {
      color: '#000000',

      '& .section-icon': {
        border: '1px solid',
        borderRadius: '50%',
        fontSize: '2em',
        padding: '2px',
      },
    },
    headWrap: {
      maxWidth: 'calc(100% - 35px)',
      width: '100%',
    },
  })
);
