import React, { useMemo, CSSProperties, RefObject } from 'react';
import { IAboutSection } from 'modules/editor/graphql/gql';
import CardWrapper from 'modules/editor/components/CardWrapper';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as AboutIcon } from 'assets/svg/about.svg';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import { Box } from '@material-ui/core';
import { constructLink } from 'modules/core/utils';
import { When } from 'react-if';
import { useScroll } from 'modules/editor/components/CardSectionsView/hooks/useScroll';
import { E_CARD_STEPS } from 'modules/editor/constants/enums';

interface IAboutProps {
  section: IAboutSection;
  classes: Record<string, string>;
  display: boolean;
  bodyStyle: CSSProperties;
  linkStyle: CSSProperties;
  iconStyle: CSSProperties;
  titleStyle: CSSProperties;
  isPreview?: boolean;
  wrapperRef: RefObject<HTMLDivElement>;
}

/**
 * About section block view for card
 * @param props IAboutProps
 */
export const AboutSection: React.FC<IAboutProps> = (props) => {
  const { section, classes, display, bodyStyle, iconStyle, linkStyle, titleStyle, isPreview, wrapperRef } = props;
  const ref = useScroll(E_CARD_STEPS.ABOUT, wrapperRef, display);

  const linkWebSite = useMemo(() => constructLink(section!.websiteUrl as string), [section]);
  const otherLinks = useMemo(
    () =>
      section.aboutSectionLinks.map((l) => ({
        url: constructLink(l.linkUrl || l.fileUrl),
        text: l.linkText,
        isFile: Boolean(l.fileUrl),
        id: l.id,
      })),
    [section]
  );
  return (
    <When condition={display}>
      <CardWrapper
        {...section}
        ref={ref}
        firstLine={
          section!.websiteUrl ? (
            <a
              style={titleStyle}
              className={classes.title}
              href={linkWebSite as string}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => isPreview && e.preventDefault()}
            >
              {section!.websiteUrl as string}
            </a>
          ) : (
            <div />
          )
        }
        icon={<SvgIcon children={<AboutIcon />} htmlColor={'#ffffff'} className="section-icon" style={iconStyle} />}
        bodyStyle={bodyStyle}
        titleStyle={titleStyle}
      >
        <div style={bodyStyle} className={classes.descriptionBlock}>
          {section.description}
        </div>
        <Box className={classes.wider} display={`${section.aboutSectionLinks.length ? 'block' : 'none'}`}>
          {otherLinks.map((url) => (
            <Box key={`${url.id}_${url.text}`}>
              <When condition={!!url.text}>
                <div className={classes.aboutLink}>
                  <a
                    style={linkStyle}
                    href={url.url}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    onClick={(e) => isPreview && e.preventDefault()}
                  >
                    {url.text}
                  </a>
                  <When condition={url.isFile}>
                    <a
                      className={classes.aboutFile}
                      href={url.url}
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                      onClick={(e) => isPreview && e.preventDefault()}
                    >
                      <InsertDriveFile style={iconStyle} />
                    </a>
                  </When>
                </div>
              </When>
            </Box>
          ))}
        </Box>
      </CardWrapper>
    </When>
  );
};
