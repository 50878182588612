import { makeStyles, createStyles, Theme, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const INACTIVE_ICON_COLOR = '#AFB1B3';
const INACTIVE_BG_COLOR = '#373C41';
const INACTIVE_BORDER_COLOR = '#494D52';

const ACTIVE_BG_COLOR = '#FFFFFF';
const ACTIVE_ICON_COLOR = '#21262C';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    previewToolbar: {
      display: 'flex',
    },
    previewBtnContainer: {
      marginRight: '15px',
    },
    backText: {
      color: '#9DA7B8',
      textTransform: 'uppercase',
      fontFamily: 'F37 Ginger',
      fontWeight: 'lighter',
      marginRight: '15px',
      cursor: 'pointer',
    },
    devider: {
      width: '1px',
      height: '40px',
      marginRight: '36px',
      marginLeft: '15px',
      backgroundColor: 'rgba(255,255,255,0.2)',
    },
  })
);

const STYLES_BTN = {
  border: `1px solid ${INACTIVE_BORDER_COLOR}`,
  borderRadius: '50%',
  width: '50px',
  height: '50px',
  fontSize: '1.5rem',
  minWidth: 'auto',
  boxSizing: 'border-box',
};

export const StyledButton = withStyles({
  root: {
    ...(STYLES_BTN as any),
    backgroundColor: INACTIVE_BG_COLOR,
    color: INACTIVE_ICON_COLOR,
    transition: 'background-color .25s ease, color .25s ease, border-color .25s ease',
    '&:hover': {
      borderColor: 'transparent',
      backgroundColor: ACTIVE_BG_COLOR,
      color: ACTIVE_ICON_COLOR,
    },
    '&.active': {
      border: 'none',
      backgroundColor: ACTIVE_BG_COLOR,
      color: ACTIVE_ICON_COLOR,
    },
  },
})(Button);

export const BackStyledButton = withStyles({
  root: {
    ...(STYLES_BTN as any),
    backgroundColor: '#21262C',
    color: '#FFF',
  },
})(Button);
