import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Amount from 'modules/billing/components/Billing/Amount';
import CssGreenButton from 'modules/core/components/buttons/roundGreenButton';
import { SelectField } from 'modules/core/components/fields';
import { IPlan } from 'modules/billing/graphql/gql';
import React, { useCallback, useMemo, useState } from 'react';

import PlanForm from './PlanForm';
import { useStyles } from './styles';

export const PLAN_OPTION_MONTHLY = {
  label: 'Monthly',
  value: 0,
};

export const PLAN_OPTION_ANNUAL = {
  label: 'Annual',
  value: 1,
};

interface ISelectPlan {
  pao: (planId: boolean) => void;
  next: (planId: string) => void;
  disabled?: boolean;
  plans: IPlan[];
  selectedPlan?: string;
}

export const SelectPlan: React.FC<ISelectPlan> = ({ next, plans, pao, disabled, selectedPlan }) => {
  const classes = useStyles();

  const monthlyPlans = useMemo(() => plans.filter((p) => p.interval === 'month').reverse(), [plans]);
  const annualPlans = useMemo(() => plans.filter((p) => p.interval === 'year').reverse(), [plans]);

  const findTab = () => (selectedPlan ? (monthlyPlans.findIndex((ap) => ap.id === selectedPlan) > -1 ? 0 : 1) : 0);
  const [tabState, setTabState] = useState(findTab());
  const [plan, setPlan] = useState(plans.find((p) => p.id === selectedPlan) || monthlyPlans[0]);

  const plan_options = (() => {
    const opts = [];

    if (!!monthlyPlans.length) {
      opts.push(PLAN_OPTION_MONTHLY);
    }
    if (!!annualPlans.length) {
      opts.push(PLAN_OPTION_ANNUAL);
    }

    return opts.map((x, i) => {
      x.value = i
      return x;
    });
  })

  const setTab = useCallback(
    (val: number) => {
      if (val === 0) {
        setPlan(monthlyPlans[0]);
      }
      if (val === 1) {
        setPlan(annualPlans[0]);
      }
      setTabState(val);
    },
    [setPlan, setTabState, annualPlans, monthlyPlans]
  );

  const handleClick = useCallback(() => {
    const selectedPlanId = plan || monthlyPlans[0] || annualPlans[0];
    const isPAO = !!plans.find((p: IPlan) => selectedPlanId.id === p.id && p.metadata.poa);

    if (isPAO) {
      pao(true);
      next(selectedPlanId.id);
    } else {
      pao(false);
      next(selectedPlanId.id);
    }
  }, [plans, plan, monthlyPlans, annualPlans, pao, next]);

  const handleSelectPlan = useCallback(
    (id: string) => {
      const fPlan = plans.find((p) => p.id === id);
      setPlan(fPlan as IPlan);
    },
    [setPlan, plans]
  );

  const SwitchPlans: React.FC = () => {
    switch (tabState) {
      case 0:
        return <PlanForm displayPlans={monthlyPlans} setPlan={handleSelectPlan} value={plan ? plan.id : undefined} />;
      case 1:
        return <PlanForm displayPlans={annualPlans} setPlan={handleSelectPlan} value={plan ? plan.id : undefined} />;
      default:
        return <PlanForm displayPlans={annualPlans} setPlan={handleSelectPlan} value={plan ? plan.id : undefined} />;
    }
  };

  return (
    <div className={clsx(classes.fnishContent)}>
      <div className={classes.popupHead}>
        <Typography className={classes.popupTitle} variant="h4">
          What plan suits you?
        </Typography>
        <Typography className={classes.popupDescription}>
          {plan && plan.amountDecimal && (
            <Box fontWeight="bold" component="span" color="#013D1F">
              RRP <Amount inline={true} amount={Number(plan.amountDecimal)} ccy={plan.currency} />
            </Box>
          )}
        </Typography>
      </div>
      <div className={classes.popupContent}>
        <Box mb={2} mt={3}>
          <SelectField
            value={tabState}
            exClass="_green"
            name="plan_length"
            label="Plan length"
            options={plan_options()}
            onChange={(val) => setTab(val)}
          />
        </Box>
        <Box mb={3}>
          <SwitchPlans />
        </Box>
      </div>
      <Box width="100%">
        <CssGreenButton
          name="step_plan_next"
          className={classes.button}
          disabled={disabled}
          onClick={() => handleClick()}
        >
          SELECT PLAN
        </CssGreenButton>
      </Box>
    </div>
  );
};
