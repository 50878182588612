import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { InviteUserForm } from 'modules/users/components/UsersPage/InviteUserForm';
import { E_PANEL_STATE, E_USER_STATUS, IInviteUser, ISlotUser } from 'modules/users/interfaces/assigned-users';
import React, { MouseEvent } from 'react';
import { connect } from 'react-redux';
import { IReduxState } from 'store';
import { ID } from 'modules/core/types';
import { lightGreen } from 'themes/colors';

interface IProps {
  user: ISlotUser;
  index: number;
  status: E_USER_STATUS;
  panelState: E_PANEL_STATE;
  onToggleState: (evt: MouseEvent) => void;
  onDelete: (evt: MouseEvent) => void;
  onReassign: (evt: MouseEvent) => void;
  onCancel: (evt: MouseEvent) => void;
  onConfirmDelete: (evt: MouseEvent) => void;
  onConfirmReassign: (newUser: IInviteUser) => void;
}

interface IStore {
  currentAccountId: ID;
}

const AssignedUserActionsComponent: React.FC<IProps & IStore> = ({
  user,
  currentAccountId,
  index,
  status,
  panelState,
  onToggleState,
  onDelete,
  onReassign,
  onCancel,
  onConfirmDelete,
  onConfirmReassign,
}) => {
  const classes = useStyles();
  return {
    [E_PANEL_STATE.NONE]: (
      <Box display="flex">
        {user.slot.accountId !== currentAccountId && user.slot.status !== E_USER_STATUS.INVITED ? (
          <>
            <Button className={classes.actionsButton} onClick={onToggleState} id={`user_status_btn_${index}`}>
              {status === E_USER_STATUS.ACTIVE ? 'Disable' : 'Enable'}
            </Button>
            <Divider orientation="vertical" className={classes.actionsDivider} component="div" />
          </>
        ) : null}

        <Button className={classes.actionsButton} onClick={onDelete} id={`user_delete_btn_${index}`}>
          Delete
        </Button>
        <Divider orientation="vertical" className={classes.actionsDivider} component="div" />
        <Button className={classes.actionsButton} onClick={onReassign} id={`user_reassign_btn_${index}`}>
          Reassign
        </Button>
      </Box>
    ),
    [E_PANEL_STATE.DELETING]: (
      <>
        <Typography variant="body1">This action can't be undone.</Typography>
        <Box display="flex" marginTop={3}>
          <Button className={classes.actionsButton} onClick={onConfirmDelete} id={`user_confirm_delete_${index}`}>
            Delete user
          </Button>
          <Divider orientation="vertical" className={classes.actionsDivider} component="div" />
          <Button className={classes.actionsButton} onClick={onCancel} id={`user_cancel_delete_${index}`}>
            Cancel
          </Button>
        </Box>
      </>
    ),
    [E_PANEL_STATE.REASSIGNING]: (
      <>
        <Typography variant="body2">Reassign card</Typography>
        <Typography variant="subtitle2" color="textSecondary">
          All associated contacts will now be linked to the new user
        </Typography>
        <InviteUserForm buttonTitle="REASSIGN CARD" onSubmit={onConfirmReassign} />
      </>
    ),
  }[panelState];
};

export const AssignedUserActions = connect((state: IReduxState) => ({
  currentAccountId: state.auth.profile.id,
}))(AssignedUserActionsComponent);

export const useStyles = makeStyles({
  actionsButton: {
    color: lightGreen,
    'text-transform': 'none',
  },
  actionsDivider: {
    height: 'inherit',
  },
});
