import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { brightGrey } from 'themes/colors';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    line: {
      height: 1,
      borderBottom: `1px dashed ${brightGrey}`,
    },
    wrap: {
      margin: theme.spacing(2, 0),
      '&._menu': {
        marginTop: 25,
        marginBottom: 28,
        fontSize: 12,
        letterSpacing: 1.2,
        lineHeight: theme.typography.pxToRem(17),
      },
      '&._mobile-menu': {
        marginTop: 41,
        fontSize: 12,
        letterSpacing: 1.2,
        lineHeight: theme.typography.pxToRem(17),
        marginBottom: 40,
      },
    },
  })
);

interface IDividerProps {
  text: string;
  exClass?: any;
  type: 'center' | 'left' | 'right';
}

const Divider: React.FC<IDividerProps> = ({ text, type, exClass }) => {
  const classes = useStyles();
  switch (type) {
    case 'center':
      return (
        <Box display="flex" alignItems="center" className={clsx(classes.wrap, exClass)}>
          <Box width="100%" className={clsx(classes.line, exClass)} />
          <Box mx={2} color={brightGrey}>
            {text}
          </Box>
          <Box width="100%" className={classes.line} />
        </Box>
      );
    case 'left':
      return (
        <Box display="flex" alignItems="center" className={clsx(classes.wrap, exClass)}>
          <Box mr={2} color={brightGrey}>
            {text}
          </Box>
          <Box width="100%" className={classes.line} />
        </Box>
      );
    case 'right':
      return (
        <Box display="flex" alignItems="center" className={clsx(classes.wrap, exClass)}>
          <Box width="100%" className={classes.line} />
          <Box ml={2} color={brightGrey}>
            {text}
          </Box>
        </Box>
      );
  }
};

export default Divider;
