import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { blueCharcoal, darkGreen } from 'themes/colors';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  textField: {
    marginBottom: 50,
  },
  fullWidth: {
    width: '100%',
  },
  contactDetailsDialogSubHeader: {
    fontSize: 14,
    marginBottom: 8,
  },
  line: {
    margin: '30px 0',
    border: '1px rgba(0,0,0,0.1) solid',
  },
  container: {
    margin: '60px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'rgba(1,61,31,0.5)',

    '& h5': {
      textAlign: 'center',
      color: '#013D1F',
    },
  },
  title: {
    textAlign: 'center',
    fontSize: '20px',
  },
  subtitle: {
    textAlign: 'center',
  },
  text: {
    '& p': {
      lineHeight: 1.2,
      fontSize: '14px',
      paddingBottom: '20px',
      color: 'rgba(1,61,31,0.5)',
    },
    '& .contactDetailsDialogText': {
      paddingBottom: 4,
      fontFamily: "F37 Ginger Light",
    },
    '&._contactDetailsDialog': {
      [theme.breakpoints.down('sm')]: {
        marginTop: -15
      },
    },
  },
  header: {
    fontSize: '14px',
    marginBottom: '20px',
  },
  SuccessHeader: {
    textAlign: 'center',
    fontSize: '24px',
    marginBottom: 10,
  },
  icon: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    backgroundColor: darkGreen,
    color: 'white',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',

    '& svg': {
      width: '60px',
      height: '60px',
    },
  },
  actionsRoot: {
    justifyContent: 'space-between',
    marginTop: -24,
  },
  actionButton: {
    flex: 1,
    maxWidth: 'initial',
    '&+&': {
      marginLeft: 15,
    },
  },
  privacyLink: {
    textDecoration: "none",
    "&:hover": {
      color: 'rgba(1,61,31,0.5)',
      textDecoration: "none",
    },
    "&:visited": {
      color: blueCharcoal,
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginTop: -22,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  gdprNoticeContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));
