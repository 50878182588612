import Dialog from '@material-ui/core/Dialog';
import { MuiThemeProvider } from '@material-ui/core/styles';
import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import greenTheme from 'themes/greenTheme';
import DialogActions from './DialogActions';
import DialogContent from './DialogContent';
import DialogTitle from './DialogTitle';

interface IDialogWrapper {
  dialogId?: string;
  children?: React.ReactNode;
  titleText?: string;
  subtitleText?: string;
  subtitle2Text?: string;
  actions?: React.ReactNode;
  onClose?: (evt: any, reason?: any) => void;
  onEnter?: (evt: any, reason?: any) => void;
  dialogClasses?: any;
  titleClasses?: {
    closeButton?: string | undefined;
    [prop: string]: string | undefined;
  };
  contentClasses?: any;
  actionsClasses?: any;
  className?: string;
  hideCloseIcon?: boolean;
}

export interface IDialogRef {
  show: () => void;
  hide: () => void;
}

export default forwardRef<IDialogRef, IDialogWrapper>(
  (
    {
      titleText,
      subtitleText,
      subtitle2Text,
      children,
      actions,
      onClose,
      onEnter,
      hideCloseIcon,
      dialogClasses,
      titleClasses,
      contentClasses,
      actionsClasses,
      dialogId,
      className,
    },
    ref
  ) => {
    const [internalOpen, setOpen] = useState(false);

    useImperativeHandle(
      ref,
      () => {
        return {
          show: () => {
            setOpen(true);
          },
          hide: () => {
            setOpen(false);
          },
        };
      },
      [setOpen]
    );

    const handleClose = useCallback(
      (event, reason?) => {
        setOpen(false);
        if (onClose && typeof onClose === 'function') {
          onClose(event, reason);
        }
      },
      [setOpen, onClose]
    );

    return (
      <MuiThemeProvider theme={greenTheme}>
        <Dialog
          onClose={onClose}
          onEnter={onEnter}
          aria-labelledby="customized-dialog-title"
          open={internalOpen}
          className={className}
          classes={{ ...dialogClasses }}
        >
          <DialogTitle
            id="customized-dialog-title"
            dialogId={dialogId}
            onClose={handleClose}
            hideCloseIcon={hideCloseIcon}
            subtitle={subtitleText}
            subtitle2={subtitle2Text}
            classes={{ ...titleClasses }}
          >
            {titleText}
          </DialogTitle>
          <DialogContent classes={{ ...contentClasses }}>{children}</DialogContent>
          {actions ? <DialogActions classes={{ ...actionsClasses }}>{actions}</DialogActions> : null}
        </Dialog>
      </MuiThemeProvider>
    );
  }
);
