import Typography from '@material-ui/core/Typography';
import { Phone, Email, CheckCircle, ReportProblem } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import { useStyles } from './styles';

interface IFinishScreen {
  success: boolean;
  title: string;
  titleErr: string;
  subtitle: string;
  subtitleErr: string;
  type?: 'email' | 'phone';
  timerCb?: () => void;
  /**
   * 3000 ms by default
   */
  time?: number;
}

interface IFinish {
  children: JSX.Element;
  finishTitle: string;
  finishSubtitle: string;
}

export const FinishScreen: React.FC<IFinishScreen> = ({
  success,
  type,
  title,
  titleErr,
  subtitle,
  subtitleErr,
  timerCb,
  time = 3000,
}) => {
  const classes = useStyles();
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      if (timerCb) {
        setTimeout(() => timerCb(), time);
      }
    }
  }, [timerCb, time]);

  const Finish: React.FC<IFinish> = ({ children, finishSubtitle, finishTitle }) => {
    return (
      <div id="finish_screen" className={clsx(classes.content, classes.contactElem, classes.fnishContent)}>
        <div className={classes.popupHead}>
          <div className={classes.ico}>{children}</div>
          <Typography className={classes.popupTitle} variant="h4">
            {finishTitle}
          </Typography>
          <Typography className={classes.popupDescription}>{finishSubtitle}</Typography>
        </div>
      </div>
    );
  };

  if (success) {
    switch (type) {
      case 'email': {
        return (
          <Finish finishTitle={title} finishSubtitle={subtitle}>
            <Email color="primary" fontSize="inherit" />
          </Finish>
        );
      }
      case 'phone': {
        return (
          <Finish finishTitle={title} finishSubtitle={subtitle}>
            <Phone color="primary" fontSize="inherit" />
          </Finish>
        );
      }
      default: {
        return (
          <Finish finishTitle={title} finishSubtitle={subtitle}>
            <CheckCircle color="primary" fontSize="inherit" />
          </Finish>
        );
      }
    }
  } else {
    return (
      <Finish finishTitle={titleErr} finishSubtitle={subtitleErr}>
        <ReportProblem color="primary" fontSize="inherit" />
      </Finish>
    );
  }
};
