import { makeStyles } from '@material-ui/core';
import {
  sectionHeaderStyle,
  clearBlockStyle,
  fieldBlockStyle,
  headingStyle,
  inputWrapperStyle,
  iconWrap2,
  addingBlock,
  fabStyle,
  extendedIconStyle,
} from '../../styles';

export const useStyles = makeStyles((theme) => ({
  sectionHeader: sectionHeaderStyle(theme),
  clearBlock: clearBlockStyle(theme),
  fieldBlock: fieldBlockStyle,
  heading: headingStyle(theme),
  inpWrapper: inputWrapperStyle,
  galleryImageInputWrapper: {
    marginBottom: theme.spacing(3),
  },
  icon2: iconWrap2(theme),
  disabledIcon: {
    backgroundColor: '#9fa2a5',
  },
  addingBlock,
  fab: fabStyle,
  extendedIcon: extendedIconStyle,
}));
