import React, { ReactNode } from 'react';
import { Hidden, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Else, If, Then } from 'react-if';
import ReactMarkdown from 'react-markdown';
import { HeaderMenu } from './HeaderMenu';
import { PreHeaderMenu } from './PreHeaderMenu';
import { useStyles } from './styles';
import { MobActionBar } from './MobActionBar';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { useRouteMatch } from 'react-router';
import { useMenuData } from 'modules/core/hooks/useMenuData';
import PayoutSummary from 'modules/dashboard/components/ResellerDashboard/PayoutSummary';
import { ReactComponent as LogoSvg } from 'assets/svg/logo.svg';

interface IAppHeader {
  isPublic: boolean;
  pageText?: string;
  pageColoredText?: string;
  exClass?: string;
  showPayoutSummary?: boolean;
}

const replaceCurrency = (currency: string, str?: string) => (str ? str.replace('{currency}', currency) : '');

const Header: React.FC<IAppHeader> = (props) => {
  const { pageText, pageColoredText, exClass, children, isPublic, showPayoutSummary } = props;
  const currency = useTypedSelector((state) => state.general.currency);
  const classes = useStyles();
  const { activeRoute } = useMenuData();
  const matchEditor = useRouteMatch('/editor');

  return (
    <div className={clsx(classes.headerWrap, exClass)}>
      {!isPublic && <PreHeaderMenu />}
      <HeaderMenu
        activePath={activeRoute.path}
        isPublic={isPublic}
        title={activeRoute.subtitle || pageText}
        exClass={exClass}
      />
      {!isPublic && matchEditor && <MobActionBar />}
      <Hidden mdDown={true}>
        <div
          className={clsx(classes.headerRow, exClass, '_dark_secondHeaderRow')}
          id={'dark_secondHeaderRow'}
        >
          <div className={clsx(classes.subtitle, exClass)}>
            <span className="divider" />
            <If condition={!!children}>
              <Then>
                <div className={"_darkHeaderRowInfo"}>
                  <div className={clsx("_darkLayoutLogo")}>
                    <LogoSvg id="dark_layout_gopaperless_logo" className="_logo" />
                  </div>
                  <span className="_divider" />
                  <div style={{ width: '100%' }}>
                    {children}
                  </div>
                </div>
              </Then>
              <Else>
                <Typography variant="h3" className={classes.coloredName}>
                  <ReactMarkdown
                    escapeHtml={false}
                    renderers={{
                      link: (linkProps: { href: string; children: ReactNode }) => {
                        return (
                          <a href={linkProps.href} className={classes.link}>
                            {linkProps.children}
                          </a>
                        );
                      },
                      break: () => {
                        return <br />;
                      },
                    }}
                    source={replaceCurrency(currency, activeRoute.subtitleColored) || pageColoredText}
                  />
                </Typography>
                <Typography variant="h3" className={classes.name}>
                  {activeRoute.subtitle || pageText}
                </Typography>
              </Else>
            </If>
          </div>
        </div>
      </Hidden>
      { !!showPayoutSummary && <PayoutSummary /> }
    </div>
  );
};

export default Header;
