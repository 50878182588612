export enum E_HUBSPOT_KIND {
  Service = 'service',
  Sales = 'sales',
  Reseller = 'reseller',
}

export enum E_HUBSPOT_SOURCE {
  Business = 'GOPAPERLESS.EU/BUSINESS',
  Checkout = 'GOPAPERLESS_CHECKOUT',
  Website = 'Website',
}
