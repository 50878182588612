import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import { IUser } from 'modules/auth/interfaces/auth';
import { ColorPicker, InputField, RadioButtonGroup as RadioGroup, SelectField } from 'modules/core/components/fields';
import { Validation } from 'modules/core/types';
import { updateStyling } from 'modules/editor/api/card-mutations';
import { FONT_VARIANTS, STYLE_OPTIONS } from 'modules/editor/constants/options';
import { ICardVersion } from 'modules/editor/graphql/gql';
import { IStylingAtributes } from 'modules/editor/graphql/mutations';
import { StylingAtributes } from 'modules/editor/models/atributes/styling-atributes';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { IReduxState } from 'store';
import { black, initialDarkGray, white } from 'themes/colors';
import Clear from 'modules/editor/components/CardSectionEditors/Clear';
import { isDisabled, updateField } from '../hooks';
import { useStyles } from './useStyles';

const STYLING_FIELDS: string[] = [
  'bodyFont',
  'bodyFontHexColour',
  'bodyFontStyle',
  'businessName',
  'cardId',
  'cardStyleId',
  'cardVersionId',
  'taglineHexColour',
  'primaryLinksHexColour',
  'secondaryIconsHexColour',
  'tagLine',
  'titleFont',
  'titleFontHexColour',
  'titleFontStyle',
  'businessNameHexColour',
];

interface IStyling {
  card: ICardVersion;
  fields: string[];
  profile: Partial<IUser>;
  updateStyling: (stylingAtr: IStylingAtributes, sectionFields: string[]) => void;
}

export const Styling = connect(
  (state: IReduxState) => ({
    card: state.currentVersion.currentVer,
    fields: state.editorSteps.stylingFields,
    profile: state.auth.profile,
  }),
  { updateStyling }
)((props: IStyling) => {
  const { card, updateStyling: saveChanges, profile, fields } = props;
  const [styling, setStyling] = useState<StylingAtributes>(
    new StylingAtributes({ ...card })
  );
  const [errors, setErrors] = useState<Validation<IStylingAtributes>>({});
  const classes = useStyles();


  useEffect(() => {
    setStyling(styling.nextState(new StylingAtributes({ ...card })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card]);

  const updateAndSave = useCallback(
    async (prop: string, val: string) => {
      styling[prop] = val;
      const atr = styling.nextState(styling);
      setStyling(atr);
      const data: IStylingAtributes = {
        cardVersionId: styling.cardVersionId,
        cardStyleId: styling.cardStyleId,
        [prop]: atr[prop],
      };
      try {
        await saveChanges(data, STYLING_FIELDS);
      } catch (error) {
        if (typeof error === 'string') {
          setErrors({ ...errors, [prop]: error });
        }
      }
    },
    [setStyling, saveChanges, setErrors, styling, errors]
  );

  const sortFonts = useMemo(() => STYLE_OPTIONS.sort((a, b) => a.label.localeCompare(b.label)), []);

  const typographySelectItemStyle = (item: typeof STYLE_OPTIONS[0]) => { return { fontFamily: item.value }; };

  const update = useCallback(
    async (prop: keyof IStylingAtributes) => {
      if (!errors[prop]) {
        const data: IStylingAtributes = {
          cardVersionId: styling.cardVersionId,
          cardStyleId: styling.cardStyleId,
          businessName: styling.businessName,
          [prop]: styling[prop] || null,
        };
        try {
          await saveChanges(data, STYLING_FIELDS);
        } catch (error) {
          if (error.graphQLErrors && error.graphQLErrors.length) {
            setErrors({ ...errors, [prop]: error.graphQLErrors[0].message });
          }
        }
      }
    },
    [saveChanges, styling, setErrors, errors]
  );
  const onClear = useCallback(() => {
    const data: IStylingAtributes = {
      ...styling.clear(true, fields).toJSON(),
      businessName: profile.companyName,
    };
    const cleared = styling.clear(false);
    cleared.titleFontStyle = 'Normal';
    cleared.bodyFontStyle = 'Normal';
    cleared.businessName = profile.companyName;
    setStyling(cleared);
    saveChanges(data, STYLING_FIELDS);
  }, [styling, fields, saveChanges, profile]);

  return (
    <>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography className={classes.sectionHeader}>Styling</Typography>
        {fields.length === 11 ? null : (
          <Box className={classes.clearBlock}>
            <Clear id="styling_editor_clear" ico={<ClearIcon fontSize="inherit" />} onConfirm={() => onClear()}>
              Clear section
            </Clear>
          </Box>
        )}
      </Box>
      <Box className={classes.fieldBlock}>
        <Typography className={classes.heading}>Business name</Typography>
        <InputField
          className="_mb32"
          name="styling-businessName"
          label="Enter business name"
          disabled={isDisabled(fields, 'businessName')}
          onChange={(e) =>
            updateField({ model: styling, prop: 'businessName', setState: setStyling, val: e.target.value })
          }
          value={styling.businessName as string}
          variant="filled"
          onBlur={() => update('businessName')}
        />
        <ColorPicker
          id="styling-businessNameHexColour"
          value={styling.businessNameHexColour as string}
          defaultColor={white}
          disabled={isDisabled(fields, 'businessNameHexColour')}
          title="Business Name Colour"
          label="Select colour"
          onSelectColor={(color: string, name: string) => updateAndSave(name, color)}
          name="businessNameHexColour"
        />
      </Box>
      <Box mb={2}>
        <Typography className={classes.sectionHeader}>Tagline</Typography>
      </Box>
      <Box className={classes.fieldBlock}>
        <Typography className={classes.heading}>Message below logo</Typography>
        <InputField
          className="_mb32"
          name="styling-tagLine"
          disabled={isDisabled(fields, 'tagLine')}
          label="Enter message"
          onChange={(e) => updateField({ model: styling, prop: 'tagLine', setState: setStyling, val: e.target.value })}
          value={styling.tagLine as string}
          variant="filled"
          onBlur={() => update('tagLine')}
        />

        <ColorPicker
          id="styling-taglineHexColour"
          value={styling.taglineHexColour as string}
          defaultColor={white}
          disabled={isDisabled(fields, 'taglineHexColour')}
          title="Tagline Colour"
          label="Select colour"
          onSelectColor={(color: string, name: string) => updateAndSave(name, color)}
          name="taglineHexColour"
        />
      </Box>
      <Box mb={2}>
        <Typography className={classes.sectionHeader}>Typography</Typography>
      </Box>
      <Box className={classes.fieldsBlock}>
        <Typography className={classes.heading}>Titles and heading</Typography>
        <SelectField
          name="styling-titleFont"
          value={styling.titleFont}
          disabled={isDisabled(fields, 'titleFont')}
          onChange={(font: string) => updateAndSave('titleFont', font)}
          label="Select font"
          options={sortFonts}
          itemStyle={typographySelectItemStyle}
        />
        <RadioGroup
          name="styling-titleFontStyle"
          disabled={isDisabled(fields, 'titleFontStyle')}
          value={styling.titleFontStyle}
          onSelect={(e: string) => updateAndSave('titleFontStyle', e)}
          label={FONT_VARIANTS}
        />
        <ColorPicker
          id="styling-titleFontHexColour"
          disabled={isDisabled(fields, 'titleFontHexColour')}
          value={styling.titleFontHexColour}
          defaultColor={black}
          title=""
          label="Select colour"
          onSelectColor={(color: string, name: string) => updateAndSave(name, color)}
          name="titleFontHexColour"
        />
      </Box>
      <Box className={classes.fieldsBlock}>
        <Typography className={classes.heading}>Body copy</Typography>
        <SelectField
          name="styling-bodyFont"
          value={styling.bodyFont}
          disabled={isDisabled(fields, 'bodyFont')}
          onChange={(font: string) => updateAndSave('bodyFont', font)}
          label="Select font"
          options={sortFonts}
          itemStyle={typographySelectItemStyle}
        />
        <RadioGroup
          name="styling-bodyFontStyle"
          disabled={isDisabled(fields, 'bodyFontStyle')}
          value={styling.bodyFontStyle}
          onSelect={(e) => updateAndSave('bodyFontStyle', e)}
          label={FONT_VARIANTS}
        />
        <ColorPicker
          id="styling-bodyFontHexColour"
          value={styling.bodyFontHexColour}
          disabled={isDisabled(fields, 'bodyFontHexColour')}
          defaultColor={initialDarkGray}
          title=""
          label="Colour"
          onSelectColor={(color: string, name: string) => updateAndSave(name, color)}
          name="bodyFontHexColour"
        />
      </Box>
      <Box mb={2}>
        <Typography className={classes.sectionHeader}>Template colours</Typography>
      </Box>
      <ColorPicker
        id="styling-primaryLinksHexColour"
        value={styling.primaryLinksHexColour}
        defaultColor={initialDarkGray}
        disabled={isDisabled(fields, 'primaryLinksHexColour')}
        title="Contact buttons &#38; hyperlinks"
        label="Select colour"
        onSelectColor={(color: string, name: string) => updateAndSave(name, color)}
        name="primaryLinksHexColour"
      />
      <Box mt={3}>
        <ColorPicker
          id="styling-secondaryIconsHexColour"
          value={styling.secondaryIconsHexColour}
          defaultColor={initialDarkGray}
          disabled={isDisabled(fields, 'secondaryIconsHexColour')}
          title="Secondary &#38; social icons"
          label="Select colour"
          onSelectColor={(color: string, name: string) => updateAndSave(name, color)}
          name="secondaryIconsHexColour"
        />
      </Box>
    </>
  );
});
