import { Box } from '@material-ui/core';
import { useStyles } from 'modules/billing/components/Billing';
import CircularIndeterminate from 'modules/core/components/progress';
import React from 'react';

export const ProcessingPayment = () => {
  const classes = useStyles();
  return (
    <Box className={classes.centerSpinner} flexDirection="column">
      <CircularIndeterminate />
      <Box textAlign="center">
        <Box>We’re just processing your payment</Box>
        <Box>Please don’t refresh or navigate away from the page</Box>
      </Box>
    </Box>
  );
};
