import React from 'react';

interface ILinkImageProps {
  url?: string;
  image?: string;
  disabled?: boolean;
  imageAlt?: string;
  className?: string;
}

export const LinkImage: React.FC<ILinkImageProps> = ({ image, url, imageAlt, disabled, className }) => {
  return (
    <>
      {image ? (
        <>
          {url ? (
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer nofollow"
              onClick={(e) => disabled && e.preventDefault()}
            >
              <img src={image} className={className} alt={imageAlt} />
            </a>
          ) : (
            <img src={image} className={className} alt={imageAlt} />
          )}
        </>
      ) : null}
    </>
  );
};
