import { Box, Container, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { actions } from 'modules/auth/reducers/authReducer';
import { reloadProfile } from 'modules/auth/thunks/auth';
import {
  userSubscriptions,
  prepareInitialResellerSubscription,
  createInitialResellerSubscription,
  handleInitialSubscriptionFail,
  updateSubscriptionStatus,
} from 'modules/billing/api/billing';
import {
  FinishScreen,
  ProcessingPayment,
  useStyles,
} from 'modules/billing/components/Billing';
import { SummaryAndCardDetails } from 'modules/billing/components/InitialResellerPurchasePage/SummaryAndCardDetails';
import { Plan, Price, Coupon } from 'modules/billing/models/plan';
import { Subscription } from 'modules/billing/models/subscription';
import CircularIndeterminate from 'modules/core/components/progress';
import { useDefaultManifest } from 'modules/core/utils';
import useStylesPage from 'modules/public/pages/main/pageStyles';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { AnyAction } from 'redux';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { useStripe } from 'modules/billing/components/BuyPage/useStripe';
import CssGreenButton from 'modules/core/components/buttons/roundGreenButton';
import { EDITOR_ROUTE } from 'routes/routes';
import { E_ACCOUNT_TYPE } from 'modules/auth/constants/enums';
import { Else, If, Then } from 'react-if';
import { ICreateResellerSubscriptionResponse } from 'modules/billing/utils';

interface IInitialResellerPurchaseComponent {
  setSubscriptions: (subs: Subscription[]) => AnyAction;
  reloadProfile: () => Promise<void>;
}

const InitialResellerPurchaseComponent: React.FC<IInitialResellerPurchaseComponent> = ({ setSubscriptions, reloadProfile: profileReload }) => {
  useDefaultManifest();

  const params = useParams<{ resellerPlan: string }>();
  const lifetime: boolean = params.resellerPlan === 'lifetime' ? true : false;
  const history = useHistory();
  const { profile, isAuthenticated } = useTypedSelector((state) => state.auth);

  const [disabled, setDisabled] = useState(false);
  const [isProcessing, setProcessing] = useState<boolean>(false);
  const [success, setSuccess] = useState(false);
  const [step, setStep] = useState(0);
  const [errMsg, setErrMsg] = useState('');
  const [redirect, setRedirect] = useState<boolean>(true);

  const pageStyles = useStylesPage();
  const classes = useStyles();
  const stripe = useStripe();

  const [resellerPlan, setResellerPlan] = useState<Plan | null>(null);
  const [resellerStarterPack, setResellerStarterPack] = useState<Price | null>(null);
  // const [retailPlan, setRetailPlan] = useState<Plan>(new Plan());
  const [resellerCoupon, setResellerCoupon] = useState<Coupon>(new Coupon());
  const [discountAmount, setDiscountAmount] = useState<number>(0);
  const [vatAmount, setVatAmount] = useState<number | null>(null);
  const [vatPercentage, setVatPercentage] = useState<number | null>(null);
  const [total, setTotal] = useState<number>(0);
  const [allow, setAllow] = useState<boolean | null>(null);

  useMemo(() => {
    if (!isAuthenticated) {
      return false;
    }

    if (!profile.id) {
      return false;
    }

    if (step > 0) {
      return false;
    }

    const canUserPurchase = profile.type === E_ACCOUNT_TYPE.Reseller && !profile.hasBeenSubscribed;
    canUserPurchase ? setAllow(true) : history.push(EDITOR_ROUTE.path);
  }, [step, profile.id, profile.type, profile.hasBeenSubscribed, isAuthenticated, history]);

  const toRequisites = () => {
    if (!redirect) {
      setStep(0);
    }
  };

  const errHandler = useCallback(
    (useRedirect: boolean, error?: any) => {
      setRedirect(useRedirect);
      setSuccess(false);
      setDisabled(false);

      if (typeof error === 'string') {
        setErrMsg(error)
      } else if(error) {
        console.log(error);
        setErrMsg(error[0])
      }
    },
    [setSuccess, setRedirect, setDisabled, setErrMsg]
  );

  useEffect(() => {
    if (isAuthenticated) {
      prepareInitialResellerSubscription(lifetime)
        .then(async (data) => {
          const resellerPlan = new Plan(data.resellerPlan);
          setResellerPlan(resellerPlan);

          const resellerStarterPack = new Price(data.resellerStarterPack);
          setResellerStarterPack(resellerStarterPack);

          // setRetailPlan(new Plan(data.retailPlan));
          setResellerCoupon(new Coupon(data.resellerCoupon));

          setDiscountAmount(Number(data.discountAmount));

          setVatAmount(Number(data.vatAmount) || null);
          setVatPercentage(Number(data.vatPercentage) || null);

          setTotal(Number(data.total));
        })
        .then()
        .catch(() => errHandler(false));
    }
  }, [errHandler, isAuthenticated, lifetime]);

  const purchaseHandler = async (pm: string) => {
    try {
      setRedirect(true);
      setDisabled(true);
      setProcessing(true);

      setStep(1);
      const res: ICreateResellerSubscriptionResponse | undefined = await createInitialResellerSubscription({
        paymentMethodId: pm,
        resellerPlanId: resellerPlan!.id,
        starterPackPriceId: resellerStarterPack!.id,
        resellerCouponId: resellerCoupon.id,
      });

      if (res) {
        if (res.success) {
          finishPaymentAttempt(afterSuccessfulPayment)
        } else if (res.scaSecret) {
          stripe!
            .confirmCardPayment(res.scaSecret, {
              payment_method: pm,
              setup_future_usage: "off_session",
            })
            .then((result) => {
              if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
                handleScaSuccess(
                  res.resellerSubscription!.subscriptionId,
                  res.retailSubscription!.subscriptionId
                ).then(() => finishPaymentAttempt(afterSuccessfulPayment));
              } else if (result.error) {
                handleScaFailure(
                  res.resellerSubscription!.subscriptionId,
                  res.retailSubscription!.subscriptionId
                ).then(() => finishPaymentAttempt(() => afterUnsuccessfulPayment(result.error!.message)));
              }
            })
            .catch((error) => console.warn(error));
        }
      }
    } catch (error) {
      finishPaymentAttempt(() => afterUnsuccessfulPayment(error));
    }
  };

  const handleScaSuccess = async (resellerSubscriptionId: string, retailSubscriptionId: string) => {
    const updateStatuses = () => {
      updateSubscriptionStatus(resellerSubscriptionId);
      updateSubscriptionStatus(retailSubscriptionId);
    };

    return await updateStatuses();
  };

  const handleScaFailure = async (resellerSubscriptionId: string, retailSubscriptionId: string) => {
    return await handleInitialSubscriptionFail(resellerSubscriptionId, retailSubscriptionId)
  };

  const finishPaymentAttempt = (callback: () => any) => {
    Promise.resolve()
      .then(callback)
      .then(afterPaymentAttempt);
  };

  const afterPaymentAttempt = async () => {
    await profileReload()
      .then(() => setDisabled(false))
      .then(() => setProcessing(false))
      .catch((error) => console.warn(error)) ;
  };

  const afterSuccessfulPayment = async () => {
    setSuccess(true);
    setRedirect(true)
    const subs = await userSubscriptions();
    setSubscriptions(subs);
  };

  const afterUnsuccessfulPayment = (error?: any) => {
    setSuccess(false);
    setRedirect(false)
    errHandler(false, error);
  };

  const onFinish = async () => {
    await profileReload();
    history.push(EDITOR_ROUTE.path);
  };

  const Flow: React.FC = () => {
    switch (step) {
      case 0:
        return (
          <>
            {resellerPlan && resellerStarterPack ? (
              <SummaryAndCardDetails
                ccy={resellerPlan!.currency}
                price={Number(resellerPlan!.amount) + Number(resellerStarterPack!.unitAmount)}
                discountAmount={discountAmount}
                discountPercentage={Number(resellerCoupon.percentOff)}
                vatAmount={vatAmount}
                vatPercentage={vatPercentage}
                total={total}
                lifetime={lifetime}
                disabled={disabled}
                purchaseHandler={purchaseHandler}
                errHandler={errHandler}
              />
            ) : (
              <Box className={classes.centerSpinner}>
                <CircularIndeterminate />
              </Box>
            )}
          </>
        );
      case 1:
        return (
          <>
            {isProcessing ? (
              <ProcessingPayment />
            ) : (
              <Box className={clsx(classes.content, '_resellerPack')}>
                <FinishScreen
                  title="Success!"
                  titleErr="Error!"
                  subtitle="Your payment has been accepted and your reseller portal is now ready."
                  subtitleErr={errMsg || 'Try again later'}
                  success={success}
                  timerCb={() => toRequisites()}
                />
                {redirect && success ? (
                  <>
                    <Typography className={classes.popupDescription} style={{marginTop: 24}}>
                    {!lifetime && <>Please note you have subscriptions for your reseller membership, your personal digital card and your demo digital card. All renew annually and appear as separate items on your card statement</>}
                    </Typography>
                    <Box justifyContent="center" display="flex">
                      <CssGreenButton
                        id="sucess_buy_create_your_card_btn"
                        disabled={disabled}
                        onClick={onFinish}
                      >
                        Go to portal
                      </CssGreenButton>
                    </Box>
                  </>
                ) : null}
              </Box>
            )}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className={clsx(pageStyles.page, '_padding-sm-mobile-extended-body')}>
      <Container className={classes.billingWrap}>
        <div className={clsx(classes.billingElem, '_auto-height')}>
          <If condition={allow === true}>
            <Then>
              <StripeProvider stripe={stripe}>
                <Elements>
                  <Flow />
                </Elements>
              </StripeProvider>
            </Then>
            <Else>
              <CircularIndeterminate />
            </Else>
          </If>
        </div>
      </Container>
    </div>
  );
};

export const InitialResellerPurchasePage = connect(null, { setSubscriptions: actions.setSubscription, reloadProfile })(
  InitialResellerPurchaseComponent
);
