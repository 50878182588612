import { Box, Typography } from '@material-ui/core';
import CssGreenButton from 'modules/core/components/buttons/roundGreenButton';
import ErrorMessages from 'modules/core/components/ErrorMessages';
import { InputField } from 'modules/core/components/fields';
import CircularIndeterminate from 'modules/core/components/progress';
import { validateEmail } from 'modules/core/utils/validate';
import { IInviteUser } from 'modules/users/interfaces/assigned-users';
import { actions } from 'modules/users/reducers/assigned-users';
import React, { FormEvent, useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useStyles } from './styles';
import { useTypedSelector } from 'hooks/useTypedSelector';

interface IProps {
  buttonTitle: string;
  onSubmit: (data: IInviteUser) => void;
}

export const InviteUserForm: React.FC<IProps> = (props) => {
  const { buttonTitle, onSubmit } = props;
  const actionPending = useTypedSelector((state) => state.users.actionPending);
  const errors = useTypedSelector((state) => state.users.errors);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [email, setEmail] = useState<string>('');
  const [fullName, setFullName] = useState<string>('');

  const isValid = useMemo(() => Boolean(validateEmail(email) && fullName && fullName.length > 1), [email, fullName]);

  useEffect(() => {
    dispatch(actions.setErrors([]));
  }, [email, fullName, dispatch]);

  const handleSubmit = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (isValid) {
      onSubmit({ fullName, email });
    }
  };

  return (
    <div className={classes.root}>
      <form noValidate onSubmit={handleSubmit}>
        <Box className={classes.fieldBlock}>
          <Typography className={classes.heading}>FULL NAME</Typography>
          <InputField
            name="recipient_full_name"
            variant="filled"
            type="text"
            label="Recipient name"
            value={fullName}
            onChange={(event) => setFullName(event.currentTarget.value)}
          />
        </Box>
        <Box className={classes.fieldBlock}>
          <Typography className={classes.heading}>EMAIL ADDRESS</Typography>
          <InputField
            name="recipient_email"
            variant="filled"
            type="text"
            label="Recipient email"
            value={email}
            onChange={(event) => setEmail(event.currentTarget.value)}
          />
        </Box>
        {errors && errors.length ? (
          <Box style={{ marginBottom: 50 }}>
            <ErrorMessages messages={errors} align="left" />
          </Box>
        ) : null}
        <CssGreenButton
          name="send_user_invite_btn"
          type="submit"
          disabled={!isValid || actionPending}
          className={classes.submitButton}
        >
          {actionPending ? <CircularIndeterminate size={24} my={0} color="inherit" /> : buttonTitle}
        </CssGreenButton>
      </form>
    </div>
  );
};
