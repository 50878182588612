import React, { lazy, Suspense } from 'react';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CardToolbar } from 'modules/core/components/CardToolbar';
import Header from 'modules/core/components/Header';
import darkTheme from 'themes/darkTheme';
import { useStyles } from './styles';
import clsx from 'clsx';

const Footer = lazy(() => import('modules/core/components/Footer'));

const ProtectedLayout: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className="App Site">
        <div className="Site-content">
          <div className={clsx('App-header _white', classes.menu)}>
            <Header exClass="_dark" isPublic={false}>
              <CardToolbar />
            </Header>
          </div>
          <div className="main">
            <main className={classes.root}>{children}</main>
          </div>
        </div>
        
        <Suspense fallback={null}>
          <Footer exClass="_dark" isWltHidden={true} />
        </Suspense>
      </div>
    </MuiThemeProvider>
  );
};

export default ProtectedLayout;
