import Favicon16 from 'assets/favicon/favicon-16x16.png';
import Favicon32 from 'assets/favicon/favicon-32x32.png';
import { PROTOCOL_REGEXP } from 'modules/core/constants';
import { Weekdays } from 'modules/core/types';
import { E_CARD_STEPS } from 'modules/editor/constants/enums';
import { ICardStep } from 'modules/editor/interfaces';
import { useCallback, useEffect } from 'react';
import { E_PREVIEW_TYPE } from 'modules/editor/constants/enums';
import { MAX_FILESIZE_BYTES } from 'modules/core/constants';

export function clearObject(atr: { [prop: string]: any }, nullable: boolean) {
  Object.keys(atr).forEach((key) => {
    const type = typeof atr[key];
    switch (type) {
      case 'boolean': {
        atr[key] = false;
        break;
      }
      case 'string': {
        atr[key] = nullable ? null : '';
        break;
      }
      default: {
        atr[key] = nullable ? null : undefined;
      }
    }
  });
}

export interface IFileReadResult {
  filename: string;
  base64data: string;
}

export function readFileAsBase64(file: File): Promise<IFileReadResult> {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.readAsDataURL(file);
    reader.onload = () => {
      const { result } = reader;
      if (result && typeof result === 'string') {
        // note: file size usually increases by about 33% after convertion to base64
        if (result.length > (MAX_FILESIZE_BYTES * 2)) {
          const message = "Uploaded file is too large as Base64.";

          console.log(message);
          // throw new Error(message);
        }

        resolve({
          base64data: result,
          filename: file.name,
        });
      }
    };
    reader.onerror = reject;
  });
}

export const shortWeekday = (day: Weekdays) => (day ? `${day.slice(0, 3)}` : '');

export function isMobile(): boolean {
  const agent = navigator.userAgent || navigator.vendor;
  return (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      agent
    ) ||
    // eslint-disable-next-line
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      agent.substr(0, 4)
    )
  );
}

export function checkUserAgent() {
  const agent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(agent)) {
    return 'android';
  }

  if (/iPad|iPhone|iPod/.test(agent) && !window.MSStream) {
    return 'ios';
  }

  return 'android';
}

export function checkUserAgentInDepth() {
  const agent = navigator.userAgent || navigator.vendor;

  if (/SAMSUNG/i.test(agent)) {
    return 'samsung-mobile';
  }

  if (/android/i.test(agent) && /firefox/i.test(agent)) {
    return 'firefox-android';
  }

  return checkUserAgent();
}

export const constructLink = (link: string) => (PROTOCOL_REGEXP.test(link) ? link : `//${link}`);

export function createStepMap(steps: ICardStep[]) {
  const map = new Map<E_CARD_STEPS, ICardStep>();
  steps.forEach((step) => map.set(step.step, step));
  return map;
}

interface ILinkTag {
  rel: string;
  type: string;
  sizes: string;
  href: string;
  [key: string]: string;
}

export function useUpdateHeadTag(tagName: string) {
  return useCallback(
    (findLink: Partial<ILinkTag>, up: Partial<ILinkTag>) => {
      const atributes = Object.keys(findLink).map((key) => `[${key}="${findLink[key]}"]`);
      const fLink = document.querySelector(`${tagName}${atributes.join('')}`);
      if (fLink) {
        fLink.remove();
      }
      const newLink = document.createElement(tagName);
      const newAtributes = { ...findLink, ...up };
      Object.keys(newAtributes).map((key) => newLink.setAttribute(key, newAtributes[key] as string));
      document.head.appendChild(newLink);
    },
    [tagName]
  );
}

export function useDefaultManifest() {
  const headUpdater = useUpdateHeadTag('link');
  useEffect(() => {
    headUpdater({ rel: 'icon', type: 'image/png', sizes: '32x32' }, { href: Favicon32 });
    headUpdater({ rel: 'icon', type: 'image/png', sizes: '16x16' }, { href: Favicon16 });
  }, [headUpdater]);
  // return null;
}

export function restoreArrayOrder(
  array1: Array<{ [prop: string]: any }>,
  array2: Array<{ [prop: string]: any }>,
  prop: string
): any[] {
  return array1
    .map((a1i) => array2.find((a2i) => a1i[prop] === a2i[prop]))
    .filter((item) => !!item)
    .concat(
      array2
        .map((a2i) => (array1.findIndex((a1i) => a1i[prop] === a2i[prop]) === -1 ? a2i : null))
        .filter((a2i) => !!a2i)
    );
}

export const getPreviewClass = (type?: E_PREVIEW_TYPE) => {
  switch (type) {
    case E_PREVIEW_TYPE.AS_MOBILE_EMULATOR:
      return "preview mobile editor";
    case E_PREVIEW_TYPE.AS_MOBILE:
    case E_PREVIEW_TYPE.MOBILE_DEVICE:
      return "preview mobile";
    case E_PREVIEW_TYPE.AS_TABLET:
      return "preview tablet";
    case E_PREVIEW_TYPE.AS_DESKTOP:
      return "preview desktop";
    default:
      return '';
  };
};

export const fullUrl = (url: string) => `${window.location.origin}/${url}`;
