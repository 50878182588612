import { useMemo } from 'react';
import { CSSProperties } from '@material-ui/styles';
import { ICardStyle } from 'modules/editor/graphql/gql';
import { initialDarkGray } from 'themes/colors';

// Creating styles of card buttons from user settings
export function useButtonStyle(cardStyle: ICardStyle): CSSProperties {
  return useMemo(() => ({ backgroundColor: cardStyle.primaryLinksHexColour || initialDarkGray }), [
    cardStyle.primaryLinksHexColour,
  ]);
}
