export enum E_CARD_STEPS {
  BRANDING = 'BRANDING',
  STYLING = 'STYLING',
  PERSONAL_DETAILS = 'PERSONAL_DETAILS',
  SOCIAL_LINKS = 'SOCIAL_LINKS',
  ABOUT = 'ABOUT',
  PROMOTION = 'PROMOTION',
  NEWS = 'NEWS',
  VIDEO = 'VIDEO',
  GALLERY = 'GALLERY',
  LOCATION = 'LOCATION',
}

export enum E_STEP_TYPE {
  REQUIRED,
  ADVANCED,
}

export enum E_PREVIEW_TYPE {
  AS_MOBILE = 'as_mobile',
  AS_DESKTOP = 'as_desctop',
  AS_TABLET = 'as_tablet',
  AS_MOBILE_EMULATOR = 'as_mobile_emulator',
  MOBILE_DEVICE = 'mobile_device',
  NONE = 'none',
}

export type FontStyleOption = 'Bold' | 'Normal' | 'Light';
